/**
 * ☃ dateDiff "Snowman Carl" (http://stackoverflow.com/questions/13903897)
 * Returns a detail object about the difference between two dates
 *
 * When providing custom units, provide them in descending order (eg week,day,hour; not hour,day,week)
 *
 * @param {Date} dateStart - date to compare to
 * @param {Date|string} [dateEnd=new Date()] - second date, can be used as unit param instead
 */
export function dateDiff(dateStart: Date, dateEnd: Date | string = new Date()): string {
  if (typeof dateEnd === 'string') dateEnd = new Date();
  const delta: number = Math.abs(dateEnd.getTime() - dateStart.getTime());

  return timeConversion(delta);
}

export function timeConversion(timeDelta: number) {
  const seconds = (timeDelta / 1000).toFixed();

  const minutes = (timeDelta / (1000 * 60)).toFixed();

  const hours = (timeDelta / (1000 * 60 * 60)).toFixed();

  const days = (timeDelta / (1000 * 60 * 60 * 24)).toFixed();

  if (parseFloat(seconds) < 60) {
    return `${seconds} сек.`;
  }
  if (parseFloat(minutes) < 60) {
    return `${minutes} м.`;
  }
  if (parseFloat(hours) < 24) {
    return `${hours} ч.`;
  }
  if (!Number.isNaN(parseFloat(days))) {
    return `${days} д.`;
  }

  return null;
}
