@if (taskComment) {
  @if (speechBubbleTopLeft) {
    <div class="speech-bubble-icon"></div>
  }
  <div
    class="comment"
    (click)="toggleOpen()"
    [ngClass]="{
      '--clickable': isTruncated,
      '--speech-bubble-bottom-left': speechBubbleBottomLeft,
      '--bg-white': whiteBg,
      '--small-text': smallText,
      '--small-padding': smallPadding,
      '--box-shadow': boxShadow,
      '--bold-text': boldText,
    }"
  >
    <svg
      class="comment-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66671 12.6693H5.33337C2.66671 12.6693 1.33337 12.0026 1.33337 8.66927V5.33594C1.33337 2.66927 2.66671 1.33594 5.33337 1.33594H10.6667C13.3334 1.33594 14.6667 2.66927 14.6667 5.33594V8.66927C14.6667 11.3359 13.3334 12.6693 10.6667 12.6693H10.3334C10.1267 12.6693 9.92671 12.7693 9.80004 12.9359L8.80004 14.2693C8.36004 14.8559 7.64004 14.8559 7.20004 14.2693L6.20004 12.9359C6.09337 12.7893 5.84671 12.6693 5.66671 12.6693Z"
        fill="#007CF0"
        stroke="#007CF0"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66663 5.33594H11.3333"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66663 8.66406H8.66663"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <div class="comment-text-container">
      <div class="comment-text-container-inner">
        <span #taskCommentText class="comment-text">
          {{ taskComment }}
        </span>
        <ng-container *ngIf="isCopyable && !isTruncated">
          <button
            type="button"
            title="Скопировать комментарий"
            (click)="copyTextToClipboard(taskComment)"
            class="comment-copy"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M14.8768 19.625L6.50217 19.625C5.67376 19.625 5.0022 18.9534 5.0022 18.125L5.0022 9.74998C5.0022 8.92157 5.67376 8.25001 6.50217 8.25001L14.8768 8.25C15.7052 8.25 16.3768 8.92156 16.3768 9.74998L16.3768 18.125C16.3768 18.9534 15.7053 19.625 14.8768 19.625Z"
                stroke="#007CF0"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M8.25037 8.25V6.49997C8.25037 5.67156 8.92193 5 9.75034 5H18.125C18.9534 5 19.625 5.67156 19.625 6.49997V14.875C19.625 15.7034 18.9534 16.375 18.125 16.375H16.3751"
                stroke="#007CF0"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </ng-container>
      </div>
      <ng-container *ngIf="isTruncated">
        <svg
          class="comment-truncated-icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#007CF0"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </ng-container>
    </div>
  </div>
} @else {
  <ng-container *ngIf="importantForClient; else noImportantForClient">
    <div
      class="comment"
      (click)="toggleOpen()"
      [ngClass]="{
        '--clickable': isTruncated,
        '--speech-bubble-bottom-left': speechBubbleBottomLeft,
        '--bg-white': whiteBg,
        '--small-text': smallText,
      }"
    >
      <svg
        class="comment-icon"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="14" height="14" rx="4" fill="#007CF0" />
        <circle cx="7.9999" cy="10.85" r="0.85" fill="white" />
        <path d="M8 5V8" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      </svg>

      <div class="comment-text-container">
        <div class="comment-text-container-inner">
          <span #taskCommentText class="comment-text">
            {{ importantForClient }}
          </span>
          <ng-container *ngIf="isCopyable && !isTruncated">
            <button
              type="button"
              title='Скопировать "Важно для клиента"'
              (click)="copyTextToClipboard(importantForClient)"
              class="comment-copy"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M14.8768 19.625L6.50217 19.625C5.67376 19.625 5.0022 18.9534 5.0022 18.125L5.0022 9.74998C5.0022 8.92157 5.67376 8.25001 6.50217 8.25001L14.8768 8.25C15.7052 8.25 16.3768 8.92156 16.3768 9.74998L16.3768 18.125C16.3768 18.9534 15.7053 19.625 14.8768 19.625Z"
                  stroke="#007CF0"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M8.25037 8.25V6.49997C8.25037 5.67156 8.92193 5 9.75034 5H18.125C18.9534 5 19.625 5.67156 19.625 6.49997V14.875C19.625 15.7034 18.9534 16.375 18.125 16.375H16.3751"
                  stroke="#007CF0"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </ng-container>
        </div>
        <ng-container *ngIf="isTruncated">
          <svg
            class="comment-truncated-icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 12L10 8L6 4"
              stroke="#007CF0"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #noImportantForClient>
    <div
      class="comment --disabled"
      [ngClass]="{
        '--speech-bubble-bottom-left': speechBubbleBottomLeft,
        '--bg-white': whiteBg,
        '--small-text': smallText,
        '--small-padding': smallPadding,
      }"
    >
      <img class="comment-icon" src="assets/icons/deal-view/message-text-disabled.svg" alt="" srcset="" />
      <div class="comment-text-container">
        <span #taskCommentText class="comment-text">Не заполнено</span>
      </div>
    </div>
  </ng-template>
}

<ng-container *ngIf="isOpened">
  <div class="comment-full-box">
    <ng-container *ngIf="taskComment; else importantForClientTemplate">
      <div class="comment-full-box-header">
        <span>Комментарий</span>
        <div class="comment-full-box-actions">
          <ng-container *ngIf="isCopyable">
            <button
              type="button"
              title="Скопировать комментарий"
              class="comment-copy"
              (click)="copyTextToClipboard(taskComment)"
              src="assets/icons/deal-view/copy.svg"
              alt=""
              srcset=""
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M14.8768 19.625L6.50217 19.625C5.67376 19.625 5.0022 18.9534 5.0022 18.125L5.0022 9.74998C5.0022 8.92157 5.67376 8.25001 6.50217 8.25001L14.8768 8.25C15.7052 8.25 16.3768 8.92156 16.3768 9.74998L16.3768 18.125C16.3768 18.9534 15.7053 19.625 14.8768 19.625Z"
                  stroke="#007CF0"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M8.25037 8.25V6.49997C8.25037 5.67156 8.92193 5 9.75034 5H18.125C18.9534 5 19.625 5.67156 19.625 6.49997V14.875C19.625 15.7034 18.9534 16.375 18.125 16.375H16.3751"
                  stroke="#007CF0"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </ng-container>
          <button title="Закрыть комментарий" type="button" (click)="toggleOpen()">
            <img class="close-icon" src="assets/icons/deal-view/close-mini.svg" alt="" srcset="" />
          </button>
        </div>
      </div>
      <span>{{ taskComment }}</span>
    </ng-container>
    <ng-template #importantForClientTemplate>
      <div class="comment-full-box-header">
        <span>Важно для клиента</span>
        <div class="comment-full-box-actions">
          <ng-container *ngIf="isCopyable">
            <button
              type="button"
              title="Скопировать комментарий"
              class="comment-copy"
              (click)="copyTextToClipboard(importantForClient)"
              src="assets/icons/deal-view/copy.svg"
              alt=""
              srcset=""
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M14.8768 19.625L6.50217 19.625C5.67376 19.625 5.0022 18.9534 5.0022 18.125L5.0022 9.74998C5.0022 8.92157 5.67376 8.25001 6.50217 8.25001L14.8768 8.25C15.7052 8.25 16.3768 8.92156 16.3768 9.74998L16.3768 18.125C16.3768 18.9534 15.7053 19.625 14.8768 19.625Z"
                  stroke="#007CF0"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M8.25037 8.25V6.49997C8.25037 5.67156 8.92193 5 9.75034 5H18.125C18.9534 5 19.625 5.67156 19.625 6.49997V14.875C19.625 15.7034 18.9534 16.375 18.125 16.375H16.3751"
                  stroke="#007CF0"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </ng-container>
          <button title='Закрыть "Важно для клиента"' type="button" (click)="toggleOpen()">
            <img class="close-icon" src="assets/icons/deal-view/close-mini.svg" alt="" srcset="" />
          </button>
        </div>
      </div>
      <span>{{ importantForClient }}</span>
    </ng-template>
  </div>
</ng-container>
