<div class="recommendation-wrapper">
  @for (message of recommendation().messages; track message) {
    @if (!message.isDeleted && !message.isSent) {
      <div class="recommendation-message">
        <div class="recommendation-title">
          Готовое сообщение
        </div>
        <div class="delete-message" (click)="deleteMessage(message)" title="Удалить">
          <img class="icon" src="assets/icons/content-creator/trash.svg" />
        </div>
        <div class="message-text">{{ message.text }}</div>
        <div class="message-controls">
          <button class="btn-edit" (click)="editMessage(message)" title="Редактировать">
            <img class="icon" src="assets/icons/content-creator/edit.svg" />
          </button>
          <button class="btn-send" (click)="sendMessage(message)" title="Отправить">
            <img class="icon" src="assets/icons/content-creator/send-message.svg" />
          </button>
        </div>
      </div>
    }
  }
</div>
