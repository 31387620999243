import { Injectable } from '@angular/core';
import { RequestTour } from '@api-clients/crm-api-client/models/request-tour';
import { TranslateService } from '@ngx-translate/core';
import { CountryShortView, RegionShortView } from '@api-clients/crm-api-client';
import { SearchRequest } from '@api-clients/crm-api-client/dist/models';
import { formatDate } from '@angular/common';
import { first } from 'rxjs/operators';

const COMMA = ',';

const TRANSLATION_KEYS = {
  from: 'COMMON.PREPOSITIONS.FROM',
  to: 'COMMON.PREPOSITIONS.TO',
  daysShortened: 'COMMON.DAYS.SHORTENED',
  adultsShortened: 'COMMON.TOURISTS.ADULTS.SHORTENED',
  childrenShortened: 'COMMON.TOURISTS.CHILDREN.SHORTENED',
  meals: 'PAGES.DEALS.CLIENT_CARD.SEARCH_REQUEST.MEALS',
};

@Injectable({
  providedIn: 'root',
})
export class SearchRequestService {
  private dateFormatLocale = 'ru-RU';
  public dateFormat = 'dd.MM.YY, HH:mm';
  public dateToFormat = 'd MMMM';
  public dateFromFormat = 'd';
  public tours: RequestTour[];
  public translatedValues: any;

  constructor(private translateService: TranslateService) {
    this.translateService
      .get([
        TRANSLATION_KEYS.from,
        TRANSLATION_KEYS.to,
        TRANSLATION_KEYS.daysShortened,
        TRANSLATION_KEYS.adultsShortened,
        TRANSLATION_KEYS.childrenShortened,
        TRANSLATION_KEYS.meals,
      ])
      .pipe(first())
      .subscribe(res => {
        this.translatedValues = res;
      });
  }

  public generateDestination(country: CountryShortView, regions: Array<RegionShortView>): string {
    const regionNames = regions.map(region => region.name);
    return `${this.checkCountryWordEnd(country.name)} ${regionNames.length ? '(' : ''}
        ${regionNames ? regionNames.join(' , ') : ''} ${regionNames.length ? ')' : ''}`;
  }

  public checkCountryWordEnd(city: string): string {
    const countryNameAsArray = city.split('');
    switch (countryNameAsArray[countryNameAsArray.length - 1]) {
      case 'я':
        countryNameAsArray[countryNameAsArray.length - 1] = 'ю';
        break;
      case 'а':
        countryNameAsArray[countryNameAsArray.length - 1] = 'у';
        break;
      default:
        break;
    }
    return countryNameAsArray.join('');
  }

  public generateSearchItemLink(searchRequest: SearchRequest): string {
    const departCity = searchRequest.departCity?.nameFrom ?? '';
    const destination = this.generateDestination(searchRequest?.country, searchRequest?.regions);

    const dateFrom = formatDate(searchRequest?.dateFrom, this.dateFromFormat, this.dateFormatLocale);
    const dateTo = formatDate(searchRequest?.dateTo, this.dateToFormat, this.dateFormatLocale);
    const dates = searchRequest?.dateFrom !== searchRequest.dateTo ? `${dateFrom}-${dateTo}` : dateTo;

    const nightsTextPostfix = `${this.translatedValues?.[TRANSLATION_KEYS.daysShortened]}` || '';
    const nights =
      searchRequest.nightsFrom !== searchRequest.nightsTo
        ? `(${searchRequest?.nightsFrom + 1}-${searchRequest?.nightsTo + 1} ${nightsTextPostfix})`
        : `(${searchRequest?.nightsFrom + 1} ${nightsTextPostfix})`;

    const adults = `${searchRequest?.adult} ${this.translatedValues?.[TRANSLATION_KEYS.adultsShortened] || ''}`;
    const children: string = searchRequest?.child
      ? `${searchRequest?.child} ${
          this.translatedValues?.[TRANSLATION_KEYS.childrenShortened] || ''
        } (${searchRequest.childAges.join()})`
      : '';

    let meals = '';
    if (searchRequest.meals.length) {
      let idx = 0;
      for (const meal of searchRequest.meals) {
        meals = meals.concat(meal.name);
        if (idx !== searchRequest.meals.length - 1) {
          idx += 1;
          meals = meals.concat(', ');
        }
      }
    }

    return `${departCity !== '' ? this.translatedValues[TRANSLATION_KEYS.from] : ''}
      ${departCity} ${departCity ? ',' : ''} ${this.translatedValues[TRANSLATION_KEYS.to]}
      ${destination}, ${dates} ${nights}${COMMA} ${adults} ${children} ${
      meals !== '' ? this.translatedValues[TRANSLATION_KEYS.meals] : ''
    } ${meals}`;
  }
}
