<div class="next-stage-step" *ngIf="isLoaded; else loading">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <h3>{{TRANSLATE_PATH + "TITLE" + ((isAfterCall && isNewClient) ? '_'+isAfterCallId:'') | translate}}</h3>
    <p>{{TRANSLATE_PATH + "TIP" | translate}}</p>

    <div class="stage-list">
      <div *ngFor="let stage of stages" class="stage-item"  (click)="form.controls['dealStage'].setValue(stage)">
        <div class="custom-checkbox">
          <input class="checkbox" type="checkbox" [id]="stage" [value]="stage" [hidden]="true">
          <img  alt="checkbox-checked" *ngIf="form.controls['dealStage'].value === stage"
                src="assets/icons/deal-view/checkbox-checked.svg" class="checkbox-svg">
          <img alt="checkbox" *ngIf="form.controls['dealStage'].value !== stage"
               src="assets/icons/deal-view/checkbox.svg" class="checkbox-svg">
        </div>
        <div class="stage-label-wrapper">
          <label
            class="stage-label"
            [for]="stage"
          >
            <span
              [style.--label-color-var]="getStageColor(stage)"
              class="rectangle"></span>

              <div class="stage-label-inner">
                {{ 'PAGES.DEALS.SALES_FUNNEL.STAGES.' + stage | translate }}
                <span class="current-stage"
                      *ngIf="currentStage === stage">
              <img src="assets/icons/deal-view/current-stage.svg" alt="current-stage">
                  {{TRANSLATE_PATH + "CLIENT_STAGE" | translate}}
              </span>
            </div>

          </label>
          <div class="hint" *ngIf="form.controls['dealStage'].value === stage">
            <img src="assets/icons/deal-view/help.svg" alt="help">
            <span>{{TRANSLATE_PATH + "HINTS."+form.controls['dealStage'].value | translate}}</span>
          </div>
          <div class="send-instructions"
               *ngIf="form.controls['dealStage'].value === sendInstructionsStage
              && stage === sendInstructionsStage
              "
          >
            <div class="checkbox">
              <app-checkbox
                [checked]="form.controls['sendBookingInstruction'].value"
                (change)="form.controls['sendBookingInstruction'].setValue($event)"
              >
              </app-checkbox>
              <span>{{TRANSLATE_PATH + "SEND_INSTRUCTION.CHECKBOX" | translate}}</span>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="task-type-list">
      <p>{{TRANSLATE_PATH + "TASK" | translate}}</p>
      <div class="modal-chip" *ngFor="let type of crmTaskTypes"
           (click)="setTaskType(type)"
           [ngClass]="{'--active': form.controls['type'].value === type,
           '--disabled': getIsDisabledTypes()}"
      >
        {{ 'PAGES.DEALS.CRM_TASK_TYPES.' + type | translate}}
      </div>
    </div>

    <form [formGroup]="form">
      <app-ui-datetimepicker [dateControl]="meetingDateControl"
                             [timeControl]="meetingTimeControl"
                             labelTranslatePath="{{TRANSLATE_PATH + 'DEADLINE'}}"
                             [isDisabled]="getIsDisabledForm()"
                             (openPopup)="openDatetimepickerPopup()">
      </app-ui-datetimepicker>
    </form>



    <textarea formControlName="managerComment"
              class="comment-field" placeholder="{{TRANSLATE_PATH + 'COMMENT_PLACEHOLDER' | translate}}"></textarea>

    <div class="fixed-container">
      <app-datetimepicker
        *ngIf="datetimePopup"
        [taskTitle]="form.controls['type'].value"
        [meetingDateTime]="form.controls['meetingDate'].value"
        (taskDateEvent)="setMeetingDateTime($event)"
        (manualClose)="closeDatetimepickerPopup($event); $event.stopPropagation()"
        class="timepicker"
      ></app-datetimepicker>
    </div>

    <div class="nav-buttons">
      <button class="btn secondary ghost"
              (click)="goPrev()">{{"COMMON.NAV_BUTTONS.PREVIOUS" | translate}}</button>
      <button  class="btn secondary emphasis"
               [disabled]="form?.invalid || getIsDisabledForm()"
               type="submit">{{"COMMON.NAV_BUTTONS.DONE" | translate}}</button>
    </div>

  </form>
</div>

<ng-template #loading>
  <app-preloader></app-preloader>
</ng-template>
