
<ng-container *ngIf="(dayItem.items | filterChatItems:activeFiltersEnum) as filteredItems">
  <ng-container *ngIf="filteredItems.length">
    <ng-content ></ng-content>
  </ng-container>

  <div class="chat-time-line">
    <ng-container *ngFor="let item of filteredItems; let i = index">
      <app-chat-time-line-item
        (maximized)="maximized.emit($event)"
        [chatTimeLineItem]="item"
        [isLastTimeLineItem]="isFirstMessage(item, filteredItems) && isLastDay"
        [isLastOfGroup]="item.isLastOfGroup"
        [isFirstOfGroup]="item.isFirstOfGroup"
      >
      </app-chat-time-line-item>
    </ng-container>

  </div>
</ng-container>
