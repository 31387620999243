import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TourSelectionTabs } from '../../modules/tour-selection/tour-selection.model';
import * as TourSelectionActions from './tour-selection.actions';
import { TourSelectionState } from './tour-selection.reducer';
import { selectCurrentTab, selectIsTourSelectionOpen } from './tour-selection.selectors';

@Injectable({
  providedIn: 'root',
})
export class TourSelectionFacade {
  isTourSelectionOpen$: Observable<boolean> = this.store.select(selectIsTourSelectionOpen);
  currentTab$: Observable<TourSelectionTabs> = this.store.select(selectCurrentTab);

  constructor(private store: Store<TourSelectionState>) {}

  openTourSelection() {
    this.store.dispatch(TourSelectionActions.openTourSelection());
  }

  closeTourSelection(): void {
    this.store.dispatch(TourSelectionActions.closeTourSelection());
  }

  setCurrentTab(tab: TourSelectionTabs): void {
    this.store.dispatch(TourSelectionActions.setCurrentTab({ tab }));
  }
}
