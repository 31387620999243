import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-found-any-tour-packages',
  templateUrl: './no-found-any-tour-packages.component.html',
  styleUrls: ['./no-found-any-tour-packages.component.scss'],
})
export class NoFoundAnyTourPackagesComponent implements OnInit {
  ngOnInit(): void {}
}
