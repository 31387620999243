@if (departCities$ | async; as departCities) {
  <div class="depart-cities-container">
    <div class="depart-cities">
      @for (departCity of departCities; track departCity) {
        <div
          [class.active]="departCityId === departCity.departCityId"
          (click)="changeDepartCity(departCity.departCityId)"
          class="depart-city">
          {{ departCity.departCityName }}
        </div>
      }
    </div>
    <div class="tours-info">
      Все цены указаны за 2 взрослых, только не комби, только не GDS.
    </div>
  </div>
}

@if (departCityId) {
  <div class="main-container">
    <app-hot-offers-filters-variants [departCityId]="departCityId" />
  </div>
}
