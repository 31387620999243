import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  HotOffersApplyFilterEvent,
  HotOffersFilterType,
  HotOffersFilterValue,
} from '../../../../hot-offers.model';
import { HotOffersService } from '../../../../hot-offers.service';
import {
  HotOffersActiveFlushAllComponent,
} from '../filter-active-flush-all/hot-offers-active-flush-all.component';
import {
  HotOffersActiveFilterValueComponent,
} from '../filter-active-value/hot-offers-active-filter-value.component';
import { HotOffersFilterCalendarComponent } from '../filter-calendar/hot-offers-filter-calendar.component';
import { HotOffersFilterComponent } from '../filter/hot-offers-filter.component';
import { HotOffersShortOffersModalComponent } from '../short-offers/hot-offers-short-offers-modal.component';

@Component({
  selector: 'app-hot-offers-filters-variants',
  templateUrl: './hot-offers-filters-variants.component.html',
  styleUrls: ['./hot-offers-filters-variants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HotOffersFilterCalendarComponent,
    AsyncPipe,
    JsonPipe,
    HotOffersActiveFilterValueComponent,
    HotOffersFilterComponent,
    HotOffersActiveFlushAllComponent,
    HotOffersShortOffersModalComponent
],
})
export class HotOffersFiltersVariantsComponent {
  @Input() departCityId!: number;

  hasActiveFilters$ = this.hotOffersService.hasActiveFilters$;

  filterType = HotOffersFilterType;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private readonly hotOffersService: HotOffersService,
  ) {
  }

  get filterByCountries$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.filtersByType$(this.departCityId, HotOffersFilterType.COUNTRIES);
  }

  get filterByNights$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.filtersByType$(this.departCityId, HotOffersFilterType.NIGHTS);
  }

  get filterByDepartDates$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.filtersByType$(this.departCityId, HotOffersFilterType.DEPART_DATE);
  }

  get filterByRatings$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.filtersByType$(this.departCityId, HotOffersFilterType.BOOKING_RATING);
  }

  get filterByHotelStars$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.filtersByType$(this.departCityId, HotOffersFilterType.HOTEL_STARS);
  }

  get activeFiltersByCountries$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.activeFiltersByType$(this.departCityId, HotOffersFilterType.COUNTRIES);
  }

  get activeFiltersByNights$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.activeFiltersByType$(this.departCityId, HotOffersFilterType.NIGHTS);
  }

  get activeFiltersByHotelRating$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.activeFiltersByType$(this.departCityId, HotOffersFilterType.BOOKING_RATING);
  }

  get activeFiltersByHotelStars$(): Observable<HotOffersFilterValue[]> {
    return this.hotOffersService.activeFiltersByType$(this.departCityId, HotOffersFilterType.HOTEL_STARS);
  }

  get activeFiltersByDepartDates$(): Observable<HotOffersFilterValue> {
    return this.hotOffersService.activeFiltersByType$(this.departCityId, HotOffersFilterType.DEPART_DATE)
      .pipe(
        filter(values => values.length),
        switchMap((values: HotOffersFilterValue[]) => {
          const dateFrom = values[0].value;
          const dateTo = values[values.length - 1].value;
          let labelFilter = '';

          if (dateFrom === dateTo) {
            labelFilter = this.datePipe.transform(new Date(dateFrom), 'd LLLL');
          } else {
            const dateFromMonth = new Date(dateFrom).getMonth();
            const dateToMonth = new Date(dateTo).getMonth();

            let labelFilterFrom: string;
            let labelFilterTo: string;

            if (dateFromMonth === dateToMonth) {
              labelFilterFrom = this.datePipe.transform(new Date(dateFrom), 'd');
              labelFilterTo = this.datePipe.transform(new Date(dateTo), 'd LLLL');
            } else {
              labelFilterFrom = this.datePipe.transform(new Date(dateFrom), 'd LLLL');
              labelFilterTo = this.datePipe.transform(new Date(dateTo), 'd LLLL');
            }

            labelFilter = `${labelFilterFrom} - ${labelFilterTo}`;
          }

          const newValue: HotOffersFilterValue = {
            type: HotOffersFilterType.DEPART_DATE,
            label: values[0].label,
            labelFilter,
            value: undefined,
            count: 0,
            isChecked: true,
          };

          return of(newValue);
        }),
      );
  }

  flushFilters() {
    this.hotOffersService.flushFilters(this.departCityId);
    this.cdRef.detectChanges();
  }

  removeFilter(filterValue: HotOffersFilterValue) {
    const event: HotOffersApplyFilterEvent = {
      eventType: 'HotOffersApplyFilterEvent',
      type: filterValue.type,
      value: filterValue.value,
      departCityId: this.departCityId,
      isChecked: false,
    };

    this.hotOffersService.applyFilter(event);
    this.cdRef.detectChanges();
  }

  removeDepartDatesFilter() {
    this.hotOffersService.removeDepartDatesFilter(this.departCityId);
    this.cdRef.detectChanges();
  }
}
