import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { AppConfig } from './environments/environment';

if (AppConfig.production) {
  Sentry.init({
    dsn: 'https://62ee44a898d52811f4067ce6cb29c718@o4507214646607872.ingest.de.sentry.io/4507214649426000',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/gera-bff\.ht\.kz\/api/],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: false,
  })
  .catch(err => console.error(err));
