import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { SalesFunnelTaskPopupComponent } from './sales-funnel-task-popup.component';
import { SharedModule } from '../../../../../../shared/shared.module';
import { DatetimepickerModule } from '../datetimepicker/datetimepicker.module';
import { CenterChildDirective } from './directives/center-child.directive';
import { FormComponentsModule } from '../../../../../form-components/form-components.module';

@NgModule({
  declarations: [SalesFunnelTaskPopupComponent, CenterChildDirective],
  imports: [
    CommonModule,
    NgClickOutsideDirective,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    DatetimepickerModule,
    FormComponentsModule,
  ],
  exports: [SalesFunnelTaskPopupComponent],
  providers: [CenterChildDirective],
})
export class SalesFunnelTaskPopupModule {}
