import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotelTagListRequest } from '@api-clients/api-client';
import { TopHotelListRequest } from '@api-clients/api-client/models';
import { Subscription } from 'rxjs';
import { AutoFocusDirective } from '../../../../../../../../../../../../shared/directives/auto-focus.directive';
import { SearchHotelStarsComponent } from '../../../../../../../../../../../search/components/hotel/hotel-stars/search-hotel-stars.component';
import { InitSearchRequest } from '../../../../../../../../../../../search/services/search/websocket-tours.model';
import { SearchResultFiltersService } from '../../../../services/search-result-filters.service';
import { SearchHotelTagsService } from '../../../../../../services/search-hotel-tags.service';

export interface CheckboxItem {
  id: number;
  name: string;
  selected: boolean;
}

@Component({
  selector: 'app-favorite-hotels-map-filter-tags',
  templateUrl: './favorite-hotels-map-filter-tags.component.html',
  styleUrl: './favorite-hotels-map-filter-tags.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, SearchHotelStarsComponent, FormsModule, AutoFocusDirective],
})
export class FavoriteHotelsMapFilterTagsComponent implements OnInit, OnDestroy {
  @Input() initSearchRequest: InitSearchRequest;
  @Input() selectedIds: number[] = [];

  closed = output<void>();

  loadHotelIdsByTags = signal<boolean>(false);

  searchQuery = signal<string>('');
  items = signal<CheckboxItem[]>([]);
  visibleItems = computed(() => {
    const items = this.items();
    const searchText = this.searchQuery();

    return items.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
  });

  disabled = signal<boolean>(false);
  tagsPriorities = [];

  private maxTagsCount = 3;
  private loadTagsSubscription: Subscription;
  private loadHotelIdsByTagsSubscription: Subscription;

  constructor(
    private readonly tagService: SearchHotelTagsService,
    private readonly mapService: SearchResultFiltersService,
  ) {}

  onCheckboxChange(tagId: number, checked: boolean) {
    if (checked) {
      this.tagsPriorities.push(tagId);
    } else {
      this.tagsPriorities.splice(
        this.tagsPriorities.findIndex(tagPriorityId => tagPriorityId === tagId),
        1,
      );
    }

    const selectedIdsCount = this.visibleItems().filter(item => item.selected).length;

    this.disabled.set(selectedIdsCount === this.maxTagsCount);
  }

  ngOnInit() {
    const request: HotelTagListRequest = {
      countryId: this.initSearchRequest.params.params.countryId,
    };

    this.loadTagsSubscription = this.tagService.loadTags$(request).subscribe(tags => {
      this.tagsPriorities = this.selectedIds;

      this.items.set(
        tags.map(tag => {
          return {
            id: tag.id,
            name: tag.name,
            selected: this.selectedIds.includes(tag.id),
          };
        }),
      );
    });
  }

  ngOnDestroy() {
    this.loadTagsSubscription?.unsubscribe();
    this.loadHotelIdsByTagsSubscription?.unsubscribe();
  }

  apply(): void {
    const selectedIds = this.visibleItems()
      .filter(item => item.selected)
      .map(item => item.id);

    const request: TopHotelListRequest = {
      countryId: this.initSearchRequest.params.params.countryId,
      tagIds: selectedIds,
      // @ts-ignore
      expand: ['tags'],
      disableFiltration: true,
    };

    if (selectedIds.length) {
      this.loadHotelIdsByTags.set(true);
      this.loadHotelIdsByTagsSubscription = this.tagService.loadHotelIdsByTags$(request).subscribe(() => {
        this.mapService.applyTags(selectedIds);
        this.loadHotelIdsByTags.set(false);
        this.closed.emit();
      });
    } else {
      this.mapService.applyTags([]);
      this.tagService.flushTagsByHotels();
      this.closed.emit();
    }
  }

  reset(): void {
    this.disabled.set(false);
    this.mapService.applyTags([]);
    this.tagService.flushTagsByHotels();
    this.closed.emit();
  }

  onSearchUpdated(sq: string) {
    this.searchQuery.set(sq);
  }

  getTagImg(id: number): string {
    const index = this.tagsPriorities.indexOf(id) + 1;

    return `assets/icons/favorite-hotels/tag-${index}.svg`;
  }
}
