<div class="message-with-image">
  <span itemInView (inView)="showImage()">
    <ng-container *ngIf="isLoaded; else loading">
      <img [src]="mediaSource"
           [style.height.px]="height"
           [style.width.px]="width"
           alt="image"
           class="whatsapp-image">
    </ng-container>
    <ng-template #loading>
       <div [style.height.px]="height"
            [style.width.px]="width"></div>
    </ng-template>
  </span>
</div>
<div *ngIf="text" class='text-under-image'>
  {{ text }}
  <br>
  <a [href]="mediaSource">Скачать изображение</a>
</div>
<span *ngIf="!text">
    <a [href]="mediaSource" class="image-download">
      <img *ngIf="isFromMe"
           src="./assets/icons/whatsapp/download-blue.svg" alt="Скачать">
      <img *ngIf="!isFromMe"
           src="./assets/icons/whatsapp/download-light.svg" alt="Скачать">
    </a>
  </span>

