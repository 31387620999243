import { Component, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { MarkAsNotNewService } from '../../services/mark-as-not-new/mark-as-not-new.service';
import { DealsListFacade } from '../../../../../../+state/deals-list/deals-list.facade';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { DEAL_VIEW_OPENED } from '../../../../../../core/services/amplitude/amplitudeEvents';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-client-card-name',
  templateUrl: './client-card-name.component.html',
  styleUrls: ['./client-card-name.component.scss'],
})
export class ClientCardNameComponent {
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_LISTING;
  @Input() name: string;
  @Input() cardId: number;
  @Input() phone: string;
  @Input() phoneCode: string;
  @Input() isNew: boolean;
  @Input() dealId: number;

  constructor(
    private amplitudeTrackService: AmplitudeTrackService,
    private markAsNotNewService: MarkAsNotNewService,
    private dealListFacade: DealsListFacade,
  ) {}

  dealViewOpen() {
    this.dealListFacade.setLastSelectedListItemId(this.dealId);

    this.markAsNotNewService
      .markAsNotNew({
        dealId: this.dealId,
      })
      .pipe(first())
      .subscribe();

    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, { screenType: this.screenType });
  }
}
