import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CrmCardListPostSaleTourPackage } from '@api-clients/crm-api-client/dist/models';
import { TourPackagePaymentInfoResponse } from '@api-clients/api-client';

@Component({
  selector: 'app-tour-short-info',
  templateUrl: './tour-short-info.component.html',
  styleUrls: ['./tour-short-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourShortInfoComponent implements OnInit {
  @Input() tourPackage: CrmCardListPostSaleTourPackage;
  public isHavePayInfo = false;
  public paidPercentage = 0;
  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.tourPackage.paymentInfo) {
      this.isHavePayInfo = true;
      this.paidPercentage = this.calculatePaidPercentage(this.tourPackage.paymentInfo);
      this.cdRef.detectChanges();
    }
  }

  calculatePaidPercentage(paymentInfo: TourPackagePaymentInfoResponse): number {
    if (paymentInfo.totalCost === 0) {
      return 0;
    }

    return (paymentInfo.paidAmount / paymentInfo.totalCost) * 100;
  }
}
