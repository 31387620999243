import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  output,
  OutputRefSubscription,
  signal,
  viewChild,
} from '@angular/core';
import { PopupService } from '../../../../../../../../shared/services/popup-service.service';
import {
  FavoriteHotelsDefaultSortDirection,
  FavoriteHotelsSortDirection as SortDirection,
} from '../../favorites-hotels.model';
import { FavoritesHotelsSortsDropdownComponent } from './dropdown/favorite-hotels-sorts-dropdown.component';
import { FavoriteHotelsSortPipe } from './favorite-hotels-sort.pipe';

@Component({
  selector: 'app-favorites-hotels-sorts',
  templateUrl: './favorite-hotels-sorts.component.html',
  styleUrl: './favorite-hotels-sorts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FavoriteHotelsSortPipe],
})
export class FavoritesHotelsSortsComponent implements OnDestroy {
  sort = signal<SortDirection>(FavoriteHotelsDefaultSortDirection);
  selectedSort = output<SortDirection>();
  dropdownRef = viewChild<ElementRef>('dropdown');

  private componentRef: ComponentRef<FavoritesHotelsSortsDropdownComponent>;
  private componentSelectedSubscription: OutputRefSubscription;

  constructor(private readonly popupService: PopupService) {}

  ngOnDestroy() {
    this.destroyComponent();
  }

  showVariants() {
    this.destroyComponent();

    this.componentRef = this.popupService.showPopup(
      FavoritesHotelsSortsDropdownComponent,
      {},
      {
        anchorElement: this.dropdownRef(),
        transparentBackdrop: true,
      },
    );
    this.componentRef.instance.activeSort = this.sort();
    this.componentSelectedSubscription = this.componentRef.instance.selected.subscribe(sort => {
      this.sort.update(() => sort);
      this.selectedSort.emit(sort);
      this.popupService.closeAllModals();
    });
  }

  private destroyComponent(): void {
    this.componentRef?.destroy();
    this.componentSelectedSubscription?.unsubscribe();
  }
}
