<div class="tour-container"
     [dndDraggable]="draggableData"
     [dndDisableIf]="tourState === stateVariants.notFound && tourSelectMode() ===tourSelectModeVariants.drop"
     (dndCanceled)="onDragCanceled()"
     (dndStart)="onDragStart()"
     (dndEnd)="onDragEnd()"
     (click)="showCalendar()">
  <div dndHandle class="tour-container-dnd">
    <div dndHandle class="tour-left-aside">
      @if (tourSelectMode() === tourSelectModeVariants.drop) {
        <div class="tour-draggable-hand"
             [class.not-allowed]="tourState === stateVariants.notFound">
          <img src="assets/icons/search/tour-draggable.svg">
        </div>
      }

      <div class="tour-information">
        <div class="tour-information-main">
          <span class="tour-depart-date">{{ tour().date | date:'dd.MM' }} на {{ tourDays() }} дн.</span>
          <span class="tour-operator">{{ operatorCode() }}</span>
          @if (isSplit()) {
            <app-search-tour-label-split-rooms />
          }
        </div>

        <div class="tour-information-ext">
          @if (airlines()) {
            <span class="tour-airlines">{{ airlines() }}, </span>
          }
          <span class="tour-meal">{{ mealName() }}</span>
          @if (tour().isGDS) {
            <app-search-tour-label-gds />
          }
        </div>
      </div>
    </div>
    <div class="tour-price">
      <div class="discount-container">
        <span *ngIf="tour().discount" class="discount-percent">-{{ tour().discount }}%</span>
      </div>

      <ng-container [ngSwitch]="tourState">
        <ng-container *ngSwitchCase="stateVariants.notRecalc">
          <div>
            <div class="tour-price-value"></div>
            <div class="tour-price-brand-value not-recalc">
              {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="stateVariants.notFound">
          <div>
            <div class="tour-not-found">Тур не найден</div>
            <div class="tour-price-brand-value not-found">
              {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="stateVariants.recalcInProgress">
          <img class="search-tours-loader-icon" src="assets/icons/search/hotel-search-tours-loader.svg">
        </ng-container>

        <ng-container *ngSwitchCase="stateVariants.recalced">
          <div>
            <div class="tour-price-value">
              {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
            </div>
            <div class="tour-price-brand-value recalced">
              {{ recalcPrice() | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="stateVariants.error">
          <div>
            <div class="tour-not-found">Ошибка при пересчете</div>
            <div class="tour-price-brand-value not-found">
              {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
