import { Pipe, PipeTransform } from '@angular/core';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { DealListService } from '../../../services/deal-list.service';

@Pipe({
  name: 'dealFilterByTouch',
  pure: false,
})
export class DealFilterByTouchPipe implements PipeTransform {
  constructor(private dealListService: DealListService) {}

  transform(deals: CrmCardViewItem[]): {
    touchedTodayDeals: CrmCardViewItem[];
    untouchedDeals: CrmCardViewItem[];
  } {
    if (!deals) {
      return { touchedTodayDeals: [], untouchedDeals: [] };
    }

    const touchedTodayDeals = deals.filter(deal => this.dealListService.isTouchedTodayDeal(deal));
    const untouchedDeals = deals.filter(deal => !this.dealListService.isTouchedTodayDeal(deal));

    return { touchedTodayDeals, untouchedDeals };
  }
}
