import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchFormParamsDepartCity } from '@api-clients/api-client';
import { Subscription } from 'rxjs';
import { PopupService } from '../../../../../../../../../shared/services/popup-service.service';
import { FavoriteHotelsDepartCitiesDropdownComponent } from './dropdown/favorite-hotels-depart-cities-dropdown.component';

@Component({
  selector: 'app-favorite-hotels-search-form-depart-cities',
  templateUrl: './favorite-hotels-search-form-depart-cities.component.html',
  styleUrls: ['./favorite-hotels-search-form-depart-cities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FavoriteHotelsSearchFormDepartCitiesComponent),
      multi: true,
    },
  ],
})
export class FavoriteHotelsSearchFormDepartCitiesComponent implements ControlValueAccessor, OnDestroy {
  @Input() departCities: SearchFormParamsDepartCity[] = [];
  activeDepartCity: SearchFormParamsDepartCity;

  @ViewChild('departCities', { read: ElementRef }) departCitiesRef: ElementRef;

  selectedCityId: number | null = null;
  private componentRef: ComponentRef<FavoriteHotelsDepartCitiesDropdownComponent>;
  private componentRefSelected: Subscription;

  constructor(private readonly cdRef: ChangeDetectorRef, private readonly popupService: PopupService) {}

  ngOnDestroy() {
    this.destroyDropdownComponent();
  }

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.selectedCityId = value;
    this.populateActiveDepartCityId(this.selectedCityId);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  openDropdown() {
    this.destroyDropdownComponent();

    this.componentRef = this.popupService.showPopup(
      FavoriteHotelsDepartCitiesDropdownComponent,
      {},
      {
        anchorElement: this.departCitiesRef,
      },
    );

    this.componentRef.instance.activeDepartCityId = this.selectedCityId;
    this.componentRef.instance.departCities = this.departCities;
    this.componentRefSelected = this.componentRef.instance.selected.subscribe(departCity => {
      this.selectedCityId = departCity.id;
      this.populateActiveDepartCityId(this.selectedCityId);
      this.onChange(departCity.id);
      this.popupService.closeAllModals();
    });
  }

  private populateActiveDepartCityId(departCityId: number): void {
    this.activeDepartCity = this.departCities.find(v => v.id === departCityId);
    this.cdRef.detectChanges();
  }

  private destroyDropdownComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.componentRefSelected?.unsubscribe();
  }
}
