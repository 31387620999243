import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-favorite-hotels-search-form-stars',
  template: `
    <div class="item-container">
      <div class="checkbox-icon">
        <img [attr.src]="selected
            ? 'assets/icons/hot-offers/filters-checkbox-on.svg'
            : 'assets/icons/hot-offers/filters-checkbox-off.svg'">
      </div>
      <div class="value">
        {{ value }}
        <img src="assets/icons/hot-offers/hotel-stars-full.svg">
      </div>
    </div>
  `,
  styles: `
      .item-container {
          box-shadow: 0 1px 1px 0 rgba(33, 48, 78, 0.1215686275);
          background: #FFFFFF;
          padding: 4px 8px 4px 8px;
          border-radius: 16px;
          color: #21304E;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          display: flex;
          gap: 8px;
          align-items: center;
          cursor: pointer;
          user-select: none;

          .value {
              margin-bottom: -2px;
              font-size: 13px;
              line-height: 16px;
              font-weight: 500;
          }

          .checkbox-icon {
              display: flex;
              align-items: center;
          }
      }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FavoriteHotelsSearchFormStarsComponent {
  @Input() value: number;
  @Input() selected: boolean;
}
