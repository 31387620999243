import { workerConfigInitialState, WorkerConfigState } from './wokrer-config.state';
import { WorkerConfigActions, WorkerConfigActionTypes } from './wokrer-config.actions';

export const USER_FEATURE_KEY = 'worker-config';

export function workerConfigReducer(
  state: WorkerConfigState = workerConfigInitialState,
  action: WorkerConfigActions,
): WorkerConfigState {
  switch (action.type) {
    case WorkerConfigActionTypes.LoadedConfig: {
      return {
        ...state,
        isWhatsappNotificationsEnabled: action?.payload?.isWhatsappNotificationsEnabled,
      };
    }
    case WorkerConfigActionTypes.LoadedConfigError: {
      return {
        ...state,
      };
    }

    case WorkerConfigActionTypes.ToggleAsideChatsScrollBar: {
      return {
        ...state,
        isAsideChatsScrollBarEnabled: !state.isAsideChatsScrollBarEnabled,
      };
    }

    case WorkerConfigActionTypes.SetAsideChatsScrollBar: {
      return {
        ...state,
        isAsideChatsScrollBarEnabled: action.payload,
      };
    }
    default:
      return state;
  }
}
