import { CalendarOptions } from '@fullcalendar/core';
import ruLocale from '@fullcalendar/core/locales/ru';
import { PhoneItem } from '@api-clients/crm-api-client/models/phone-item';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

export const INITIAL_CALENDAR_OPTIONS: CalendarOptions = {
  locale: ruLocale,
  initialView: 'timeGridDay',
  headerToolbar: false,
  footerToolbar: {
    left: '',
    center: '',
    right: 'closeBtn',
  },
  titleFormat: { day: '2-digit', month: '2-digit' },
  dayHeaders: false,
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
  },
  nowIndicator: true,
  selectable: true,
  selectMirror: true,
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin]
};
export interface TimelineTask {
  id: string;
  title: string;
  clientName?: string;
  phone?: PhoneItem;
  intent?: string;
  comment?: string;
  start: Date;
  end: Date;
  allDay: boolean;
}

export enum TimePeriods {
  urgent = 'urgent',
  after15Min = 'after15Min',
  after30Min = 'after30Min',
  afterHour = 'afterHour',
  today = 'today',
  tomorrow = 'tomorrow',
  untilEndOfWeek = 'untilEndOfWeek',
  afterWeek = 'afterWeek',
  afterMonth = 'afterMonth',
}
