import { Action } from '@ngrx/store';
import {
  CrmCardViewItem,
  WhatsappContactListItem,
  WhatsappGetInstanceResponse,
  WhatsappNewMessage,
} from '@api-clients/crm-api-client';
import { ChatPayload, SelectedChatPayload } from './whatsapp-contacts.interfaces';

export enum WhatsappContactsActionTypes {
  LoadContacts = '[Whatsapp Contacts] Load Whatsapp contacts',
  LoadInstance = '[Whatsapp Contacts] Load Whatsapp contacts',
  LoadedContacts = '[Whatsapp Contacts] Whatsapp contacts loaded',
  LoadedContactsError = '[Whatsapp Contacts] Whatsapp contacts loaded error',
  LoadedInstance = '[Whatsapp Contacts] Whatsapp instance loaded',
  LoadedInstanceError = '[Whatsapp Contacts] Whatsapp instance loaded error',
  ClearContactUnreadMessagesByContactId = '[Whatsapp Contacts] Clear contact unread messages by contact id',
  CountChatsWithUnreadMessages = '[Whatsapp Contacts] Count chats with unread messages',
  UpdateUnreadChatsCount = '[Whatsapp Contacts] Update unread chats count',
  SetSelectedChat = '[Whatsapp Contacts] Set Selected Chat',
  NewMessages = '[Whatsapp Contacts] Got a new messages',
  NewContacts = '[Whatsapp Contacts] Got a new contacts',
  LoadChat = '[Whatsapp Contacts] Load chat',
  LoadDealViewToChat = '[Whatsapp Contacts] Load DealView to chat',
  UpdateMessagesInChat = '[Whatsapp Contacts] Update Messages in Chat',
}

export class LoadContacts implements Action {
  readonly type = WhatsappContactsActionTypes.LoadContacts;
}
export class LoadInstance implements Action {
  readonly type = WhatsappContactsActionTypes.LoadInstance;
}
export class LoadedContacts implements Action {
  readonly type = WhatsappContactsActionTypes.LoadedContacts;
  constructor(public payload: WhatsappContactListItem[]) {}
}
export class LoadedContactsError implements Action {
  readonly type = WhatsappContactsActionTypes.LoadedContactsError;
  constructor(public payload: any) {}
}
export class LoadedInstance implements Action {
  readonly type = WhatsappContactsActionTypes.LoadedInstance;
  constructor(public payload: WhatsappGetInstanceResponse) {}
}
export class LoadedInstanceError implements Action {
  readonly type = WhatsappContactsActionTypes.LoadedInstanceError;
  constructor(public payload: any) {}
}
export class ClearContactUnreadMessagesByContactId implements Action {
  readonly type = WhatsappContactsActionTypes.ClearContactUnreadMessagesByContactId;
  constructor(public payload: string) {}
}
export class NewMessages implements Action {
  readonly type = WhatsappContactsActionTypes.NewMessages;
  constructor(public payload: WhatsappNewMessage[]) {}
}
export class NewContacts implements Action {
  readonly type = WhatsappContactsActionTypes.NewContacts;
  constructor(public payload: WhatsappContactListItem[]) {}
}

export class CountChatsWithUnreadMessages implements Action {
  readonly type = WhatsappContactsActionTypes.CountChatsWithUnreadMessages;
}

export class UpdateUnreadChatsCount implements Action {
  readonly type = WhatsappContactsActionTypes.UpdateUnreadChatsCount;
  constructor(public payload: { unreadChatCount: number }) {}
}

export class SetSelectedChat implements Action {
  readonly type = WhatsappContactsActionTypes.SetSelectedChat;
  constructor(public payload: SelectedChatPayload) {}
}

export class LoadChat implements Action {
  readonly type = WhatsappContactsActionTypes.LoadChat;
  constructor(public payload: ChatPayload) {}
}

export class LoadDealViewToChat implements Action {
  readonly type = WhatsappContactsActionTypes.LoadDealViewToChat;
  constructor(public payload: CrmCardViewItem) {}
}
export class UpdateMessagesInChat implements Action {
  readonly type = WhatsappContactsActionTypes.UpdateMessagesInChat;
  constructor(public payload: WhatsappNewMessage[]) {}
}

export type WhatsappContactsAction =
  | LoadContacts
  | LoadedContacts
  | LoadedContactsError
  | LoadedInstance
  | LoadedInstanceError
  | ClearContactUnreadMessagesByContactId
  | CountChatsWithUnreadMessages
  | UpdateUnreadChatsCount
  | SetSelectedChat
  | NewMessages
  | NewContacts
  | LoadChat
  | LoadDealViewToChat
  | UpdateMessagesInChat;
