import { NgClass, NgForOf, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SharedUtilityModule } from '../../../../../../../../libs/shared/utility/src';
import { CheckboxRoundedComponent } from '../../../../ui-components/checkbox-rounded/checkbox-rounded.component';
import { ImageItem } from '../../interfaces/chat-timeline.interface';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgForOf, SharedUtilityModule, CheckboxRoundedComponent, NgIf],
})
export class ImageViewerComponent implements AfterViewInit, AfterViewChecked {
  @Input() images: ImageItem[];
  @Input() selectedImages: Set<ImageItem>;
  @Input() currentImageIdx = 0;
  @Output() imagesSelected = new EventEmitter<Set<ImageItem>>();
  @Output() closed = new EventEmitter();

  @ViewChild('slider', { static: true }) slider!: ElementRef;

  private needsScrollPreviewToCenter = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    // При нажатии на esc закрывать просмотрщик
    if (event.key === 'Escape') {
      this.closed.emit();
    }
    // Стрелки влево и вправо для переключения изображений
    if (event.key === 'ArrowLeft') {
      this.previousImage();
    }
    if (event.key === 'ArrowRight') {
      this.nextImage();
    }
    // Выбирать и отменять выбор изображения по пробелу
    if (event.key === ' ') {
      const currentImage = this.images[this.currentImageIdx];
      const isSelected = this.isImageSelected(currentImage);
      this.selectImage(!isSelected, currentImage);
    }

    // Событие не должно всплывать
    event.stopPropagation();
    event.preventDefault();
  }

  ngAfterViewInit(): void {
    this.scrollToActiveImage(false);
  }

  ngAfterViewChecked() {
    if (this.needsScrollPreviewToCenter) {
      this.scrollToActiveImage();
      this.needsScrollPreviewToCenter = false;
    }
  }

  get isSelectionEnabled(): boolean {
    return !!this.selectedImages;
  }

  get currentImage(): ImageItem {
    return this.images[this.currentImageIdx];
  }

  isImageSelected(image: ImageItem) {
    return this.selectedImages?.has(image);
  }

  selectImage(isSelected: boolean, image: ImageItem) {
    if (!this.selectedImages) {
      return;
    }
    if (isSelected) {
      this.selectedImages.add(image);
    } else {
      this.selectedImages.delete(image);
    }
    this.imagesSelected.emit(this.selectedImages);
  }

  private scrollToActiveImage(isSmooth = true) {
    const slider = this.slider.nativeElement;
    const currentImage = slider.querySelector('.loading.current');
    if (slider && currentImage) {
      const sliderRect = slider.getBoundingClientRect();
      // Вычисление корректного значения scrollLeft
      const scrollLeft =
        (currentImage.offsetLeft as number) + currentImage.offsetWidth / 2 - sliderRect.width / 2;

      slider.scrollTo({
        left: scrollLeft,
        behavior: isSmooth ? 'smooth' : 'auto',
      });
    }
  }

  setActiveImage(index: number) {
    this.currentImageIdx = index;
    this.needsScrollPreviewToCenter = true;
  }

  previousImage() {
    if (this.currentImageIdx > 0) {
      this.currentImageIdx--;
    }
    this.needsScrollPreviewToCenter = true;
  }

  nextImage() {
    if (this.currentImageIdx < this.images.length - 1) {
      this.currentImageIdx++;
    }
    this.needsScrollPreviewToCenter = true;
  }
}
