import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HotOfferListItemCountry } from '../../../../hot-offers.model';
import { HotOffersService } from '../../../../hot-offers.service';
import { HotOffersHotelsComponent } from '../hotels/hot-offers-hotels.component';
import { HotsOfferCountryToursCount } from './tours-count/hot-offers-country-tours-count.component';

@Component({
  selector: 'app-hot-offers-countries',
  templateUrl: './hot-offers-countries.component.html',
  styleUrls: ['./hot-offers-countries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    HotOffersHotelsComponent,
    HotsOfferCountryToursCount
],
})
export class HotOffersCountriesComponent {
  @Input() departCityId!: number;

  constructor(
    private readonly hotOffersService: HotOffersService,
  ) {
  }

  get countries$(): Observable<HotOfferListItemCountry[]> {
    return this.hotOffersService.countriesByDepartCityId$(this.departCityId);
  }
}
