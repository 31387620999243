import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TourPackage } from '@api-clients/crm-api-client/models';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class TourPackageListService {
  constructor(private http: HttpClient) {}

  getListByCrmId(crmCardId: number | undefined, onlyNotFinished: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('crmCardId', `${crmCardId}`);
    queryParams = queryParams.append('onlyNotFinished', `${onlyNotFinished}`);
    return this.http
      .get<ApiResponse<TourPackage>>(`${AppConfig.apiUrl}/tour-package/list-by-crm-id`, {
        params: queryParams,
      })
      .pipe(apiResponsePipe);
  }
}
