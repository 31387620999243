import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZenmodePaginateComponent } from './components/zenmode-paginate/zenmode-paginate.component';

@NgModule({
  declarations: [ZenmodePaginateComponent],
  exports: [ZenmodePaginateComponent],
  imports: [CommonModule],
})
export class ZenmodePaginateModule {}
