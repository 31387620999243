import { CrmTaskType } from '@api-clients/crm-api-client';

export enum CurrentStageEnum {
  missed = 'missed',
  coldTouch = 'coldTouch',
  new = 'new',
  other = 'other',
  meeting = 'meeting',
  sendOffer = 'sendOffer',
  feedback = 'feedback',
  finishingTouch = 'finishingTouch',
  readyForBooking = 'readyForBooking',
  waitConfirm = 'waitConfirm',
  waitPayment = 'waitPayment',
  waitDocs = 'waitDocs',
  waitFlight = 'waitFlight',
  onRest = 'onRest',
  review = 'review',
  autoTouch = 'autoTouch',
}

export type Stage = string | CurrentStageEnum;

export const stageColorMap: Record<CurrentStageEnum, string> = {
  [CurrentStageEnum.missed]: '#FDA033', // Пропущено
  [CurrentStageEnum.coldTouch]: '#3396F3', // Холодные касания
  [CurrentStageEnum.new]: '#3396F3', // Новый
  [CurrentStageEnum.sendOffer]: '#ED6980', // Отправить подборку
  [CurrentStageEnum.feedback]: '#FC8800', // Обратная связь
  [CurrentStageEnum.meeting]: '#9BD029', // Встреча
  [CurrentStageEnum.finishingTouch]: '#C85DC8', // Завершающий этап
  [CurrentStageEnum.autoTouch]: '#3396F3', // Автокасания
  [CurrentStageEnum.readyForBooking]: '#FF6347', // Готов к бронированию
  [CurrentStageEnum.waitConfirm]: '#48C262', // Ожидание подтверждения
  [CurrentStageEnum.waitPayment]: '#48C262', // Ожидание оплаты
  [CurrentStageEnum.waitDocs]: '#48C262', // Ожидание документов
  [CurrentStageEnum.waitFlight]: '#48C262', // Ожидание рейса
  [CurrentStageEnum.onRest]: '#48C262', // На отдыхе
  [CurrentStageEnum.review]: '#48C262',
  [CurrentStageEnum.other]: '#FFFFFF',
};

export const hintBeforeTaskAssigned = {
  id: 'hintBeforeTaskAssigned',
  icon: 'assets/icons/hint/cursor.svg',
  langKey: 'PAGES.DEALS.SALES_FUNNEL.TOOLTIP.HINTS.SELECT_STAGE',
};

export const hintAfterTaskAssigned = {
  id: 'hintAfterTaskAssigned',
  icon: 'assets/icons/hint/return.svg',
  langKey: 'PAGES.DEALS.SALES_FUNNEL.TOOLTIP.HINTS.RETURN_TO_STAGE',
};

export interface Hint {
  icon: string;
  langKey: string;
}

export interface TaskCreatedEvent {
  sendBookingInstructions: boolean;
  taskType: CrmTaskType;
}
