import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'monthYearFormat',
  standalone: true
})
export class MonthYearFormatPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    const date = new Date(value);
    const monthKey = `COMMON.MONTH.${date.getMonth()}`;

    // Получаем переведенное имя месяца
    const translatedMonth = this.translate.instant(monthKey);

    return `${translatedMonth}, ${date.getFullYear()}`;
  }
}
