import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'uniqueValue',
})
export class UniqueValuePipe implements PipeTransform {
  transform(values: any, property: string): any {
    if (values) {
      return _.uniqBy(values, property);
    }
    return values;
  }
}
