import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { WhatsappScheduledMessage } from '@api-clients/crm-api-client';

const MESSAGE_MAX_HEIGHT = 70;

@Component({
  selector: 'app-chat-timeline-item-scheduled-message',
  templateUrl: './chat-timeline-item-scheduled-message.component.html',
  styleUrls: ['./chat-timeline-item-scheduled-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTimelineItemScheduledMessageComponent implements AfterViewInit {
  @Input() scheduledMessage: WhatsappScheduledMessage;
  @ViewChild('messageText') messageTextElRef: ElementRef;
  public showMore = false;
  public hideToggle = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.messageTextElRef) {
      this.hideToggle = this.messageTextElRef.nativeElement.offsetHeight < MESSAGE_MAX_HEIGHT;
      this.cdRef.detectChanges();
    }
  }
}
