import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ChatTimeLineDayItem,
  ChatTimeLineItem,
  ChatTimelineItemTypeEnum,
  FilterListEnum,
} from '../../interfaces/chat-timeline.interface';
import { ChatTimelineFilterService } from '../chat-time-line-filters/services/chat-timeline-filter.service';

@Component({
  selector: 'app-chat-timeline-day',
  templateUrl: './chat-timeline-day.component.html',
  styleUrls: ['./chat-timeline-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTimelineDayComponent implements OnInit, OnDestroy {
  @Input() dayItem: ChatTimeLineDayItem;
  @Input() isLastDay: boolean;
  @Output() maximized: EventEmitter<string> = new EventEmitter();
  public activeFilters: string[] = [];
  public existTimelineItemTypes: string[] = [];
  public isShowDate = true;
  public chatTimelineFiltersSub: Subscription;
  constructor(
    private chatTimelineFilterService: ChatTimelineFilterService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.dayItem.items.forEach(item => {
      this.existTimelineItemTypes.push(item.type);
    });
    this.chatTimelineFiltersSub = this.chatTimelineFilterService.chatTimelineFilters$.subscribe(filters => {
      this.activeFilters = [...filters];
      this.isShowDate = this.getIsShowDate();
      this.cdRef.detectChanges();
    });
  }

  get activeFiltersEnum(): FilterListEnum[] {
    return this.activeFilters.map(filter => FilterListEnum[filter as keyof typeof FilterListEnum]);
  }

  public getIsShowDate() {
    if (this.activeFilters.length === 0 || this.activeFilters.includes(FilterListEnum.all)) {
      return true;
    }
    let result = false;
    this.existTimelineItemTypes.forEach(item => {
      if (this.activeFilters.includes(item)) {
        result = true;
      }
    });
    return result;
  }

  ngOnDestroy(): void {
    if (this.chatTimelineFiltersSub) {
      this.chatTimelineFiltersSub.unsubscribe();
    }
  }
  isFirstMessage(item: ChatTimeLineItem, items: ChatTimeLineItem[]): boolean {
    const messages = items.filter(i => i.type === ChatTimelineItemTypeEnum.message);
    return messages.length > 0 && item === messages[0];
  }
}
