import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCenterChild]',
})
export class CenterChildDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // get parent and child elements
    const parent = this.el.nativeElement.parentElement;
    const child = this.el.nativeElement;
    const childWidth = child.clientWidth;

    // check if there is enough space on the left side
    const rightSpace = window.innerWidth - parent.getBoundingClientRect().right;
    if (rightSpace >= childWidth) {
      this.renderer.addClass(child, 'left');
      return;
    }

    // check if there is enough space on the right side
    const leftSpace = parent.getBoundingClientRect().left;
    if (leftSpace >= childWidth) {
      this.renderer.addClass(child, 'right');
      return;
    }

    // otherwise, center the child element
    this.renderer.addClass(child, 'center');
  }
}
