import { Injectable } from '@angular/core';
import { ElectronService } from '../core/services';

import { ComplimentNotifyEvent } from '../models/ipcEvent';

@Injectable({
  providedIn: 'root',
})
export class ComplimentService {
  constructor(private electronService: ElectronService) {}

  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private ucfirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getManCompliment(): string {
    const texts: string[] = [
      'Не ссы и херачь на 20-ку',
      'Береги бороду смолоду',
      'Тестостерона много не бывает!',
      'Жизнь за Нерзула!',
      'Нужно больше золота!',
      'Хорошо смеётся тот, у кого большая пушка!',
      'Держи голову в холоде, а яйки в тепле (с) Кристина И.',
    ];
    return texts[this.getRandomInt(0, texts.length - 1)];
  }

  getCompliment(): string {
    const compliments: string[] = [
      'ты лучшая на свете',
      'ты свет наших очей',
      'ты самый сладкий наш сон',
      'ты наш мир',
      'ты наша душа',
      'ты прекраснее всех',
      'ты такая красивая',
      'ты такая миленькая',
    ];
    const pronouns: string[] = ['наш', 'наша', 'наше'];
    const names: (string | number)[][] = [
      ['солнышко', '3', '0'],
      ['зайка', '2', '0'],
      ['котенок', '1', '0'],
      ['прелесть', '2', '1'],
      ['радость', '2', '1'],
      ['счастье', '3', '1'],
      ['ангелочек', '1', '0'],
      ['принцесса', '2', '0'],
      ['красота', '2', '1'],
      ['нежность', '2', '1'],
      ['рыбонька', '2', '0'],
      ['вишенка', '2', '1'],
      ['клубничка', '2', '1'],
      ['няшка', '2', '0'],
    ];
    const verbs: string[] = [
      'мы ценим тебя',
      'мы хотим поцеловать тебя',
      'мы хотим тебя обнять',
      'ты лучший менеджер на свете',
      'любуемся твоей неземной красотой',
      'такая красивая прелесть работает у нас',
      'улыбнись и освети весь мир',
      'желаем тебе хорошего дня',
      'пусть у тебя все будет замечательно',
      'счастья, здоровья тебе',
      'долгих лет жизни тебе',
      'желаем тебе заработать миллионы миллионов',
      'пусть каждый день приносит тебе улыбку',
      'why so serious? @Joker',
      'dont worry, be happy',
    ];
    const name = names[this.getRandomInt(0, names.length - 1)];
    const compliment = compliments[this.getRandomInt(0, compliments.length - 1)];
    const pronoun =
      this.getRandomInt(0, 1) || name[2] === '1' ? ` ${pronouns[parseInt(<string>name[1], 10) - 1]}` : '';
    const verb = this.getRandomInt(0, 1)
      ? `${this.ucfirst(verbs[this.getRandomInt(0, verbs.length - 1)])}!`
      : '';
    return `${this.ucfirst(name[0] as string) + pronoun}, ${compliment}! ${verb}`;
  }

  getFinalCompliment(gender: 1 | 2): string {
    return gender === 1 ? this.getManCompliment() : this.getCompliment();
  }

  sendCompliment(gender: 1 | 2): void {
    const compliment = this.getFinalCompliment(gender);
    const eventData: ComplimentNotifyEvent = {
      event: 'create-compliment',
      notification: {
        text: compliment,
      },
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
  }
}
