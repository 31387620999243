import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TourPackageReviewItem } from '@api-clients/crm-api-client';
import { LastReviewModalComponent } from '../../../../../ui-components/last-review-modal/last-review-modal.component';

@Injectable({
  providedIn: 'root',
})
export class LastReviewModalService {
  constructor(public matDialog: MatDialog) {}

  showModal(review: TourPackageReviewItem) {
    this.matDialog.open(LastReviewModalComponent, {
      id: 'last-review-modal',
      disableClose: false,
      panelClass: 'last-review-modal-panel',
      width: '730px',
      height: 'auto',
      hasBackdrop: true,
      data: {
        review,
      },
    });
  }
}
