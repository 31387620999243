import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AudioModule } from './audio/audio.module';
import { DealsItemComponent } from './deals-item.component';
import { DealsItemSearchOptionsComponent } from './deals-item-search-options/deals-item-search-options.component';
import { LastActionCallComponent } from './last-action-call/last-action-call.component';
import { LastActionDateTimeHelperPopupComponent } from './last-action-date-time-helper-popup/last-action-date-time-helper-popup.component';
import { LastActionOfferComponent } from './last-action-offer/last-action-offer.component';
import { LastActionSmsComponent } from './last-action-sms/last-action-sms.component';
import { LastActionWhatsappMessageComponent } from './last-action-whatsapp-message/last-action-whatsapp-message.component';
import { ClientCardInfoModule } from '../../../../deal-view/modules/client-card-info/client-card-info.module';
import { DealsItemQuestionnaireModule } from './deals-item-questionnaire/deals-item-questionnaire.module';

@NgModule({
  declarations: [
    DealsItemComponent,
    DealsItemSearchOptionsComponent,
    LastActionCallComponent,
    LastActionDateTimeHelperPopupComponent,
    LastActionOfferComponent,
    LastActionSmsComponent,
    LastActionWhatsappMessageComponent,
  ],
  imports: [
    AudioModule,
    CommonModule,
    TranslateModule,
    ClientCardInfoModule,
    DealsItemQuestionnaireModule,
  ],
  exports: [DealsItemComponent],
})
export class DealsItemModule {}
