<div *ngIf="taskList" class="content rounded-border">
  <h3 class="title task-list-title">{{ 'PAGES.TASK_LIST.TITLE' | translate }} {{ workerName }}</h3>
  <div class="task-list-content">
    <div class="task-list-table">
      <div class="task-list-table-header rounded-border">
        <span>
          <span class="table-header-icon"><img alt="" src="./assets/icons/task-list/user.svg" /></span>
          <span class="table-header-title">{{ 'PAGES.TASK_LIST.CLIENT_NAME' | translate }} </span>
        </span>
        <span>
          <span class="table-header-icon"><img alt="" src="./assets/icons/task-list/status.svg" /></span>
          <span class="table-header-title">{{ 'PAGES.TASK_LIST.STATUS' | translate }} </span>
        </span>
        <span>
          <span class="table-header-icon"><img alt="" src="./assets/icons/task-list/stage.svg" /></span>
          <span class="table-header-title">{{ 'PAGES.TASK_LIST.DEAL_STAGE' | translate }} </span>
        </span>
        <span>
          <span class="table-header-icon"
            ><img alt="" src="./assets/icons/task-list/next-actions.svg"
          /></span>
          <span class="table-header-title">{{ 'PAGES.TASK_LIST.NEXT_ACTION' | translate }} </span>
        </span>
        <span>
          <span class="table-header-icon"><img alt="" src="./assets/icons/task-list/date.svg" /></span>
          <span class="table-header-title">{{ 'PAGES.TASK_LIST.INTENT' | translate }} </span>
        </span>
        <span>
          <span class="table-header-icon"><img alt="" src="./assets/icons/task-list/date.svg" /></span>
          <span class="table-header-title">{{ 'PAGES.TASK_LIST.DATE' | translate }} </span>
        </span>
      </div>
      <div class="task-list-table-body">
        <div *ngFor="let task of taskList" [attr.task-id]="task?.task?.id" class="task-list-table-row rounded-border">
          <span class="name">
            <a [routerLink]="['/deals/view', task?.card?.id]">
              <ng-container *ngIf="task?.card?.name; else phone">
                {{ task?.card?.name }}
              </ng-container>
              <ng-template #phone>
                <ng-container *ngFor="let firstPhone of task?.card.phones; let first = first">
                  <span *ngIf="first">
                    {{ firstPhone.code }}
                    <input
                      class="hidden-phone"
                      type="text"
                      [hiddenInput]="true"
                      [mask]="getSecretMask(firstPhone.code)"
                      [readOnly]="true"
                      [ngModel]="firstPhone?.phone"
                    />
                  </span>
                </ng-container>
              </ng-template>
            </a>
          </span>
          <span *ngIf="!task?.card?.isReturnTourist" class="status">
            <span class="--new">
              {{ 'PAGES.DEALS.CLIENT_CARD.STATUS.NEW' | translate }}
            </span>
          </span>
          <span *ngIf="task?.card?.isReturnTourist" class="status">
            <img alt="" src="./assets/icons/task-list/crown.svg" />
            <span class="--returnable">
              {{ 'PAGES.DEALS.CLIENT_CARD.STATUS.RETURNABLE' | translate }}
            </span>
          </span>
          <span class="deal-stage">{{ 'PAGES.DEALS.STAGES.' + task?.deal?.stage | translate }}</span>
          <span class="next-action">{{
            'PAGES.TASK_LIST.NEXT_ACTIONS.' + task?.task?.type | translate
          }}</span>
          <span *ngIf="task?.task?.intent" class="intent">{{
            'PAGES.DEALS.INTENT_TYPES.' + task?.task?.intent | translate
          }}</span>
          <span class="date">{{
            task?.task?.date + ' ' + task?.task?.time | date: DATE_FORMAT | lowercase
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isLoading">
  <app-preloader></app-preloader>
</ng-container>
