import { Action } from '@ngrx/store';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { CrmCardListPostSaleItem } from '@api-clients/crm-api-client/models/crm-card-list-post-sale-item';
import { DealChangedEventDetail, CrmTaskItem } from '@api-clients/crm-api-client';
import { SortType } from '../../modules/deals/modules/deals-list/deal-list';
import {
  CardIdPayload,
  DealIdPayload,
  SelectedZenmodeCard,
  SelectedZenmodeCardTouchedPayload,
  UpdateLastWhatsappMessageEvent,
} from './deals.interface';

export enum DealsActionTypes {
  LoadDeals = '[Deals] Load deals',
  DealsLoaded = '[Deals] Deals loaded',
  SortDeals = '[Deals] Sort deals',
  SortDealsSuccess = '[Deals] Sort deals success',
  DealsChangeSortType = '[Deals] Deals change sort type',
  DealsPostSaleLoaded = '[Deals] Deals post sale loaded',
  DealsPostSaleDropdownOpen = '[Deals] Deals post sale dropdown open',
  DealsPostSaleDropdownClose = '[Deals] Deals post sale dropdown close',
  DealsLoadError = '[Deals] Deals load error',
  DealChanged = '[Deals] Deal changed',
  DealChangedSuccess = '[Deals] Deal Changed Success',
  DealSelectedByZenmodeCard = '[Deals] Deal selected by zenmode card id',
  DealsSelectNextCard = '[Deals] Deals select next card',
  DealsSelectPreviousCard = '[Deals] Deals select previous card',
  ToggleZenmode = '[Deals] Toggle zenmode',
  UpdateLastWhatsappMessage = '[Deals] Update last whatsapp message',
  CombinedDealsLoaded = '[Deals] Combined deals loaded',
  GenerateZenmodeList = '[Deals] Generate Zenmode List',
  ShowZenModeIntro = '[Deals] Show Zen Mode Intro',
  AddViewedCard = '[Deals] Add Viewed Card',
  NavigateNextCard = '[Deals] Navigate Next Card',
  NavigatePreviousCard = '[Deals] Navigate Previous Card',
  SelectedZenmodeCardTouched = '[Deals] Selected Zenmode Card Touched',
  MarkDealAsNotNew = '[Deals] Mark Deal As Not New',
  AddSkippedCard = '[Deals] Add Skipped Card',
  RemoveSkippedCard = '[Deals] Remove Skipped Card',
  RemoveViewedCard = '[Deals] Remove Viewed Card',
  RemoveShadowTime = '[Deals] Remove Shadow Time',
  AddShadowTime = '[Deals] Add Shadow Time',
  NewMessageReceived = '[Deals] New Message Received',
  UpdateUntouchedCards = '[Deals] Update Untouched Cards',
  AddToZenmodeList = '[Deals] Add To Zenmode List',
  MarkedAsNotNew = '[Deals] Marked As Not New',
}

export class LoadDeals implements Action {
  readonly type = DealsActionTypes.LoadDeals;
}

export class DealsLoaded implements Action {
  readonly type = DealsActionTypes.DealsLoaded;
  constructor(public payload: CrmCardViewItem[]) {}
}

export class SortDeals implements Action {
  readonly type = DealsActionTypes.SortDeals;
}

export class SortDealsSuccess implements Action {
  readonly type = DealsActionTypes.SortDealsSuccess;
  constructor(public payload: CrmCardViewItem[]) {}
}

export class DealsChangeSortType implements Action {
  readonly type = DealsActionTypes.DealsChangeSortType;
  constructor(public payload: SortType) {}
}

export class DealsPostSaleLoaded implements Action {
  readonly type = DealsActionTypes.DealsPostSaleLoaded;
  constructor(public payload: CrmCardListPostSaleItem[]) {}
}

export class DealsPostSaleDropdownOpen implements Action {
  readonly type = DealsActionTypes.DealsPostSaleDropdownOpen;
}

export class DealsPostSaleDropdownClose implements Action {
  readonly type = DealsActionTypes.DealsPostSaleDropdownClose;
}

export class GenerateZenmodeList implements Action {
  readonly type = DealsActionTypes.GenerateZenmodeList;
}

export class DealsLoadedError implements Action {
  readonly type = DealsActionTypes.DealsLoadError;
  constructor(public payload: any) {}
}

export class DealChanged implements Action {
  readonly type = DealsActionTypes.DealChanged;
  constructor(public payload: DealChangedEventDetail) {}
}

export class NewMessageReceived implements Action {
  readonly type = DealsActionTypes.NewMessageReceived;
  constructor(public payload: CardIdPayload) {}
}

export class DealSelectedByZenmodeCard implements Action {
  readonly type = DealsActionTypes.DealSelectedByZenmodeCard;
  constructor(public payload: SelectedZenmodeCard) {}
}

export class DealsSelectNextCard implements Action {
  readonly type = DealsActionTypes.DealsSelectNextCard;
  constructor(public payload: { isSkip: boolean; isTouched?: boolean; nextTask?: CrmTaskItem }) {}
}

export class DealsSelectPreviousCard implements Action {
  readonly type = DealsActionTypes.DealsSelectPreviousCard;
  constructor(public payload: boolean) {}
}

export class ToggleZenmode implements Action {
  readonly type = DealsActionTypes.ToggleZenmode;
  constructor(public payload: boolean) {}
}

export class UpdateLastWhatsappMessage implements Action {
  readonly type = DealsActionTypes.UpdateLastWhatsappMessage;
  constructor(public payload: UpdateLastWhatsappMessageEvent) {}
}

export class DealChangedSuccess implements Action {
  readonly type = DealsActionTypes.DealChangedSuccess;
  constructor(public payload: { dealId: number }) {}
}

export class ShowZenModeIntro implements Action {
  readonly type = DealsActionTypes.ShowZenModeIntro;
  constructor(public payload: { isManual: boolean }) {}
}

export class CombinedDealsLoaded implements Action {
  readonly type = DealsActionTypes.CombinedDealsLoaded;
  constructor(public payload: { deals: CrmCardViewItem[]; postSaleDeals: CrmCardListPostSaleItem[] }) {}
}

export class AddViewedCard implements Action {
  readonly type = DealsActionTypes.AddViewedCard;
  constructor(public payload: SelectedZenmodeCard) {}
}

export class AddSkippedCard implements Action {
  readonly type = DealsActionTypes.AddSkippedCard;
  constructor(public payload: SelectedZenmodeCard) {}
}

export class RemoveSkippedCard implements Action {
  readonly type = DealsActionTypes.RemoveSkippedCard;
  constructor(public payload: CardIdPayload) {}
}

export class RemoveViewedCard implements Action {
  readonly type = DealsActionTypes.RemoveViewedCard;
  constructor(public payload: CardIdPayload) {}
}

export class RemoveShadowTime implements Action {
  readonly type = DealsActionTypes.RemoveShadowTime;
  constructor(public payload: CardIdPayload) {}
}

export class NavigateNextCard implements Action {
  readonly type = DealsActionTypes.NavigateNextCard;
}

export class NavigatePreviousCard implements Action {
  readonly type = DealsActionTypes.NavigatePreviousCard;
}

export class SelectedZenmodeCardTouched implements Action {
  readonly type = DealsActionTypes.SelectedZenmodeCardTouched;
  constructor(public payload: SelectedZenmodeCardTouchedPayload) {}
}
export class MarkDealAsNotNew implements Action {
  readonly type = DealsActionTypes.MarkDealAsNotNew;
  constructor(public payload: DealIdPayload) {}
}

export class AddShadowTime implements Action {
  readonly type = DealsActionTypes.AddShadowTime;
  constructor(public payload: { dealId: number; nextTask: CrmTaskItem }) {}
}

export class UpdateUntouchedCards implements Action {
  readonly type = DealsActionTypes.UpdateUntouchedCards;
  constructor(public payload: CrmCardViewItem[]) {}
}

export class AddToZenmodeList implements Action {
  readonly type = DealsActionTypes.AddToZenmodeList;
  constructor(public payload: CardIdPayload) {}
}

export class MarkedAsNotNew implements Action {
  readonly type = DealsActionTypes.MarkedAsNotNew;
  constructor(public payload: DealIdPayload) {}
}

export type DealsAction =
  | LoadDeals
  | DealsLoaded
  | DealsPostSaleLoaded
  | DealsPostSaleDropdownOpen
  | DealsPostSaleDropdownClose
  | DealsLoadedError
  | SortDeals
  | SortDealsSuccess
  | DealsChangeSortType
  | DealChanged
  | DealSelectedByZenmodeCard
  | DealsSelectNextCard
  | DealsSelectPreviousCard
  | UpdateLastWhatsappMessage
  | DealChangedSuccess
  | CombinedDealsLoaded
  | ShowZenModeIntro
  | AddViewedCard
  | NavigateNextCard
  | NavigatePreviousCard
  | SelectedZenmodeCardTouched
  | MarkDealAsNotNew
  | AddSkippedCard
  | AddShadowTime
  | RemoveSkippedCard
  | NewMessageReceived
  | UpdateUntouchedCards
  | RemoveViewedCard
  | AddToZenmodeList
  | RemoveShadowTime
  | MarkedAsNotNew
  | GenerateZenmodeList
  | ToggleZenmode;
