<div class="tour-info-content">
  <div class="row">
    <img src="../../../../../../../../../../../../../assets/icons/post-sale/destination.svg" alt="destination">
    <span>{{tour.country.name}}, {{tour.region.name}}</span>
  </div>
  <div class="row">
    <img src="../../../../../../../../../../../../../assets/icons/post-sale/calendar.svg" alt="dates">
    <span>{{tour.checkInDate | date: 'd.MM'}} - {{tour.checkOutDate | date: 'd.MM'}}</span>
  </div>
  <div class="row">
    <img src="../../../../../../../../../../../../../assets/icons/post-sale/hotel.svg" alt="hotel">
    <span>{{tour.hotel.name}} {{tour.hotel.hotelClass}}</span>
  </div>
  <div class="row">
    <img src="../../../../../../../../../../../../../assets/icons/post-sale/days.svg" alt="days">
    <span>{{tour.days}} {{dayMeasure}}</span>
  </div>
</div>
