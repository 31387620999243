<div class="client-name-info">
  <div class="client-name">
    <a [routerLink]="['/deals/view', cardId]" (click)="dealViewOpen()">
      <ng-container *ngIf="name; else clientPhone">
        {{ name | libTruncate: 30 }}
      </ng-container>
      <ng-template #clientPhone>
        <app-client-phone
        [phoneCode]="phoneCode"
        [phone]="phone"
        [isClickable]="true"
        >
        </app-client-phone>
      </ng-template>
    </a>
  </div>
</div>
