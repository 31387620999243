import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ManagerSearchHotel, ManagerSearchHotelTemplateList, ToursCalendar } from '@api-clients/api-client';
import { SearchFormParams, SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { WorkerStateService } from '../../../../../../../../core/services';
import { PluralizePipe } from '../../../../../../../../helpers/pipes/plural/pluralize.pipe';
import { PopupService } from '../../../../../../../../shared/services/popup-service.service';
import { SlideToggleModule } from '../../../../../../../../ui-components/slide-toggle/slide-toggle.module';
import { SearchHotel } from '../../../../../../../search/search.model';
import { FavoriteHotelsSearchFormParams } from '../../favorites-hotels.model';
import { SearchHotelTagsService } from '../../services/search-hotel-tags.service';
import { SearchToursProgressService } from '../../services/search-tours-progress.service';
import { FavoriteHotelsSearchFormCalendarComponent } from './calendar/favorite-hotels-search-form-calendar.component';
import {
  CheckboxItem,
  FavoriteHotelsSearchFormCheckboxListComponent,
} from './checkbox-list/favorite-hotels-search-form-checkbox-list.component';
import { FavoriteHotelsSearchFormCountriesComponent } from './countries/favorite-hotels-search-form-countries.component';
import { FavoriteHotelsSearchFormDaysComponent } from './days/favorite-hotels-search-form-days.component';
import { FavoriteHotelsSearchFormDepartCitiesComponent } from './depart-cities/favorite-hotels-search-form-depart-cities.component';
import { FavoriteHotelsSearchFormNotGdsComponent } from './not-gds/favorite-hotels-search-form-not-gds.component';
import { SearchFormService } from './search-form.service';
import { FavoriteHotelsSearchFormStarsListComponent } from './stars-list/favorite-hotels-search-form-stars-list.component';
import { FavoriteHotelsSearchFormTemplatesComponent } from './templates/favorite-hotels-search-form-templates.component';
import { FavoriteHotelsSearchFormTouristComponent } from './tourists/favorite-hotels-search-form-tourists.component';

@Component({
  selector: 'app-favorite-search-form',
  templateUrl: './favorite-hotels-search-form.component.html',
  styleUrls: ['./favorite-hotels-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FavoriteHotelsSearchFormTouristComponent,
    FavoriteHotelsSearchFormCalendarComponent,
    FavoriteHotelsSearchFormDaysComponent,
    FavoriteHotelsSearchFormStarsListComponent,
    FavoriteHotelsSearchFormCheckboxListComponent,
    ReactiveFormsModule,
    FavoriteHotelsSearchFormDepartCitiesComponent,
    NgIf,
    FavoriteHotelsSearchFormCountriesComponent,
    FavoriteHotelsSearchFormTemplatesComponent,
    PluralizePipe,
    AsyncPipe,
    SlideToggleModule,
    FavoriteHotelsSearchFormNotGdsComponent,
  ],
  providers: [PopupService],
})
export class FavoriteHotelsSearchFormComponent implements OnInit, OnDestroy {
  formParams = input.required<SearchFormParams>();
  defaultCountryId = input<number>();

  searchInProgress$ = this.searchToursState.searchInProgress$;
  loadingHotelsList$ = this.searchFormService.loadingHotelsList$;

  close = output<void>();
  submit = output<void>();

  form: FormGroup;
  mealsVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().meals.map(meal => {
      const item: CheckboxItem = { id: meal.id, name: meal.name, selected: false };

      return item;
    });
  });
  operatorsVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().tourOperators.map(operator => {
      const item: CheckboxItem = { id: operator.id, name: operator.name, selected: false };

      return item;
    });
  });
  airlinesVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().airlines.map(airline => {
      const item: CheckboxItem = { id: airline.code, name: airline.name, selected: false };

      return item;
    });
  });

  countries = signal<SearchFormParamsCountry[]>([]);
  regions = signal<SearchFormParamsRegion[]>([]);
  priceCalendars = signal<ToursCalendar | undefined>(undefined);
  availableNightsInDirection = signal<number[]>([]);
  hotelTemplateList = signal<ManagerSearchHotelTemplateList>(undefined);

  private allHotels = signal<ManagerSearchHotel[]>([]);

  private destroyRef = inject(DestroyRef);

  private previousFormValue: FavoriteHotelsSearchFormParams;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly searchFormService: SearchFormService,
    private readonly tagsService: SearchHotelTagsService,
    private readonly workerState: WorkerStateService,
    private readonly searchToursState: SearchToursProgressService,
  ) {}

  ngOnInit() {
    this.createForm();
    this.registerFormEvents();
    this.populateForm();
  }

  ngOnDestroy() {}

  searchTours(): void {
    if (!this.form.valid) {
      return;
    }
    const searchParams: FavoriteHotelsSearchFormParams = this.form.value;
    this.searchFormService.saveLastSearchParams(searchParams);

    this.searchFormService.startSearch(searchParams);
    this.submit.emit();
  }

  closeForm(): void {
    this.close.emit();
  }

  private createForm(): void {
    const dateFrom = new Date(this.formParams().defaultValues.dateFrom);
    const dateTo = new Date(this.formParams().defaultValues.dateFrom);
    dateTo.setDate(dateTo.getDate() + 3);

    // setHours нужен, чтобы убрать часовые пояса
    dateFrom.setHours(0);
    dateTo.setHours(0);

    this.form = this.fb.group({
      tourists: [{ adults: 2, childAges: [], splitRooms: false }],
      days: [
        {
          from: this.formParams().defaultValues.nights.from + 1,
          to: this.formParams().defaultValues.nights.to + 1,
        },
      ],
      dates: [
        {
          from: dateFrom,
          to: dateTo,
        },
      ],
      direction: {
        countryId: null,
        regionIds: [],
      },
      departCityId: new FormControl<number | null>(null, {
        validators: [Validators.required],
      }),
      mealIds: new FormControl<number[]>([]),
      operatorIds: new FormControl<number[]>([]),
      airlineIds: new FormControl<string[]>([]),
      hotelIds: new FormControl<number[]>([]),
      stars: new FormControl<number[]>([]),
      templateId: new FormControl<number>(0),
      notGDS: [true],
      combined: ['0'],
    });
  }

  private populateForm(): void {
    const lastFormParam = this.searchFormService.getLastSearchParams();
    if (lastFormParam) {
      this.form.setValue(lastFormParam);
    } else {
      const departCityId = this.workerState.currentDepartCityId;
      const countryId = this.defaultCountryId()
        ? this.defaultCountryId()
        : this.formParams().defaultValues.countryId;
      const direction = {
        countryId,
        regionIds: [],
      };
      const defaultFormValue = this.form.value;
      const formValue = { ...defaultFormValue, ...{ departCityId, direction } };
      this.form.setValue(formValue);
    }
  }

  private updateCountries(departCityId: number): void {
    const countryIds = [];
    this.formParams().directions.map(direction => {
      if (direction.departCityId === departCityId) {
        countryIds.push(direction.countryId);
      }
    });

    const countries = this.formParams().countries.filter(country => countryIds.indexOf(country.id) !== -1);
    const regions = this.formParams().regions.filter(region => countryIds.indexOf(region.countryId) !== -1);

    this.countries.update(() => countries);
    this.regions.update(() => regions);
  }

  private updateFormHotels(formValue: FavoriteHotelsSearchFormParams) {
    const hotels = this.getFormHotels(formValue);
    let hotelIds = hotels.map(v => v.id);

    hotelIds = formValue.templateId === 0 ? [] : hotelIds;
    this.form.get('hotelIds').setValue(hotelIds, { emitEvent: false });

    const searchHotels: SearchHotel[] = hotels.map(hotel => {
      return {
        id: hotel.id,
        stars: hotel.stars,
        name: hotel.name,
        photo: hotel.photo,
        region: hotel.regionName,
        salesCount: hotel.salesCount,
        bookingRating: hotel.bookingRating,
        bookingReviewsCount: hotel.bookingReviewsCount,
        latitude: hotel.latitude,
        longitude: hotel.longitude,
        beachType: hotel.beachType,
        seaLine: hotel.seaLine,
        conversionOnlyAdults: hotel.conversionOnlyAdults,
        conversionWithChildren: hotel.conversionWithChildren,
      };
    });
    this.searchFormService.setHotelsOnSearch(searchHotels);
  }

  private getFormHotels(formValue: FavoriteHotelsSearchFormParams): ManagerSearchHotel[] {
    const starsList: number[] = formValue.stars.map(stars => Number(stars));
    const regionIds: number[] = formValue.direction.regionIds;
    const templateId = formValue.templateId;
    const hotelsInTemplate = this.getHotelIdsByTemplateId(templateId);

    return this.allHotels().filter(hotel => {
      if (hotelsInTemplate.length) {
        if (!hotelsInTemplate.includes(hotel.id)) {
          return false;
        }
      }

      if (starsList.length) {
        if (starsList.indexOf(Number(hotel.stars)) === -1) {
          return false;
        }
      }
      if (regionIds.length) {
        if (regionIds.indexOf(hotel.regionId) === -1) {
          return false;
        }
      }
      return true;
    });
  }

  private getHotelIdsByTemplateId(templateId: number): number[] {
    if (templateId === 0) {
      return [];
    }

    const allTemplates = this.hotelTemplateList().basicTemplates.concat(
      this.hotelTemplateList().managerTemplates,
    );

    return allTemplates.find(template => template.id === templateId).hotelsList.map(hotel => hotel.id);
  }

  private updatePriceCalendar(
    departCityId: number,
    countryId: number,
    daysFrom: number,
    daysTo: number,
  ): void {
    const nightsFrom = daysFrom - 1;
    const nightsTo = daysTo - 1;
    this.searchFormService
      .loadFormCalendar$(departCityId, countryId, nightsFrom, nightsTo)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(priceCalendar => {
        this.priceCalendars.update(() => priceCalendar);
      });
  }

  private updateAvailableNightsInDirection(departCityId: number, countryId: number): void {
    if (departCityId && countryId) {
      const directionOptions = this.formParams().directions.find(
        direction => direction.departCityId === departCityId && direction.countryId === countryId,
      );
      if (directionOptions) {
        this.availableNightsInDirection.set(directionOptions.nights);
      }
    }
  }

  private preloadTags(countryId: number): void {
    this.tagsService.loadTags$({ countryId }).subscribe();
  }

  private registerFormEvents(): void {
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((value: FavoriteHotelsSearchFormParams) => {
          const departCityId = value.departCityId;
          const countryId = value.direction.countryId;

          if (this.previousFormValue) {
            if (this.previousFormValue.departCityId !== departCityId) {
              this.updateCountries(departCityId);
              this.updateAvailableNightsInDirection(departCityId, countryId);
            }
            if (this.previousFormValue.direction.countryId !== countryId) {
              this.preloadTags(countryId);
            }
            if (
              this.previousFormValue.departCityId !== departCityId ||
              this.previousFormValue.direction.countryId !== countryId
            ) {
              this.updatePriceCalendar(departCityId, countryId, value.days.from, value.days.to);
            }
          } else {
            this.updateCountries(departCityId);
            this.updateAvailableNightsInDirection(departCityId, countryId);
            this.updatePriceCalendar(departCityId, countryId, value.days.from, value.days.to);
            this.preloadTags(countryId);
          }
        }),
        switchMap((value: FavoriteHotelsSearchFormParams) => {
          const countryId = value.direction.countryId;
          if (this.previousFormValue?.direction?.countryId !== countryId) {
            const sources = [
              this.searchFormService.loadHotelsList$(countryId),
              this.searchFormService.loadHotelsTemplates$(countryId),
              of(value),
            ];
            return forkJoin(sources).pipe(takeUntilDestroyed(this.destroyRef));
          } else {
            return of([this.allHotels(), this.hotelTemplateList(), value]);
          }
        }),
      )
      .subscribe((response: any) => {
        this.allHotels.set(response[0]);
        this.hotelTemplateList.set(response[1]);

        const formValue: FavoriteHotelsSearchFormParams = response[2];

        const countryId = formValue.direction.countryId;

        if (this.previousFormValue?.direction?.countryId !== countryId) {
          const selectedCountry = this.formParams().countries.find(country => country.id === countryId);
          this.searchFormService.setSelectedCountry(selectedCountry);
        }

        if (
          this.previousFormValue?.direction?.countryId !== countryId ||
          this.previousFormValue?.stars.sort().join('-') !== formValue.stars.sort().join('-') ||
          this.previousFormValue.direction?.regionIds?.sort().join('-') !==
            formValue.direction?.regionIds?.sort().join('-')
        ) {
          this.updateFormHotels(formValue);
        }

        this.previousFormValue = formValue;
        this.cdRef.detectChanges();
      });
  }
}
