import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { SharedModule } from '../../../../../../shared/shared.module';
import { SharedUtilityModule } from '../../../../../../../../../../libs/shared/utility/src';
import { SlideToggleModule } from '../../../../../../ui-components/slide-toggle/slide-toggle.module';
import { DealsItemQuestionnaireModule } from '../../../deals-list/modules/deals-list-content/deals-item/deals-item-questionnaire/deals-item-questionnaire.module';
import { FormComponentsModule } from '../../../../../form-components/form-components.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    NgClickOutsideDirective,
    SharedModule,
    SharedUtilityModule,
    SlideToggleModule,
    FullCalendarModule,
    DealsItemQuestionnaireModule,
    FormComponentsModule,
  ],
  exports: [],
})
export class ActionsPopupMenuModule {}
