import { Component, Input, OnInit } from '@angular/core';
import { SearchRequest } from '@api-clients/crm-api-client/dist/models';
import { SearchRequestService } from '../../../../../deals/modules/deal-view/services/search-request.service';

/**
 * @deprecated
 * Раньше выводились поиски в чате, теперь их заменили на непосредственно отправленные подборки туров
 */
@Component({
  selector: 'app-chat-timeline-item-search-history',
  templateUrl: './chat-timeline-item-search-history.component.html',
  styleUrls: ['./chat-timeline-item-search-history.component.scss'],
})
export class ChatTimelineItemSearchHistoryComponent implements OnInit {
  @Input() searchRequest: SearchRequest;
  public searchRequestText: string;
  constructor(private searchRequestService: SearchRequestService) {}

  ngOnInit(): void {
    if (this.searchRequest) {
      this.searchRequestText = this.searchRequestService.generateSearchItemLink(this.searchRequest);
    }
  }
}
