import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatChatMessage',
})
export class FormatChatMessagePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    let formattedText = value;

    // Жирный (*text*)
    formattedText = formattedText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>');

    // Курсив (_text_)
    formattedText = formattedText.replace(/_([^_]+)_/g, '<em>$1</em>');

    // Зачеркнутый (~text~)
    formattedText = formattedText.replace(/~([^~]+)~/g, '<s>$1</s>');

    // Моноширинный (`text`)
    formattedText = formattedText.replace(/`([^`]+)`/g, '<code>$1</code>');

    return formattedText;
  }
}
