<form (ngSubmit)="onSubmit()" *ngIf="offices && smsTemplates" [formGroup]="sendSmsForm" class="sms-popup">
  <div class="popup-header">
    <h4 class="header-title">{{ 'MODALS.SMS.TITLE' | translate }}</h4>
    <button (click)="closePopup()" class="close-button" type="button">
      <img alt="" src="./assets/icons/close-icon.svg" />
    </button>
  </div>
  <div class="popup-body">
    <div class="form-group">
      <app-phone-input-with-code
        [parentForm]="sendSmsForm"
        [concatenateCountryCode]="true"
        [translatableLabel]="'MODALS.SMS.PHONE'"
        [formControlNameValue]="'mobilePhone'"
        [formControlItem]="mobilePhoneControl"
        [phoneItemWithCode]="data.phone"
        [ngClass]="{'has-error': mobilePhoneControl.invalid && mobilePhoneControl.touched}"
        (countryCode)="onCountryCodeChanged($event)"
        (phoneValue)="onPhoneValueChanged($event)"

      ></app-phone-input-with-code>
      <app-form-error [parentForm]="sendSmsForm" [assignedFormControl]="sendSmsForm.get('mobilePhone')"></app-form-error>
    </div>
    <div class="form-group">
      <label class="form-label" for="message-textarea">{{ 'MODALS.SMS.MESSAGE' | translate }}</label>
      <textarea
        [placeholder]="messagePlaceholder"
        class="form-control"
        formControlName="text"
        id="message-textarea"
      ></textarea>
      <app-form-error [parentForm]="sendSmsForm" [assignedFormControl]="sendSmsForm.get('text')"></app-form-error>
    </div>
    <div class="select-group">
      <div *ngIf="isCallOperator" class="office-select">
        <app-select
          *ngIf="offices"
          [optionLabel]="'name'"
          [options]="offices"
          [placeholder]="officesPlaceholder"
          [(ngModel)]="selectedOffice"
          [hash]="'office'"
          [ngModelOptions]="{ standalone: true }"
        ></app-select>
      </div>
      <div class="sms-templates-select">
        <app-sms-templates
          (ngModelChange)="onSmsTypeChange($event)"
          *ngIf="smsTemplates"
          [(ngModel)]="smsType"
          [ngModelOptions]="{ standalone: true }"
          [options]="smsTemplates"
          [placeholder]="smsTemplatesPlaceholder"
        ></app-sms-templates>
      </div>
    </div>
  </div>
  <div class="popup-footer">
    <button
      [disabled]="!phoneValue.length || !textFieldValue.length"
      class="btn --blue"
      type="submit"
    >
      {{ 'MODALS.SMS.SEND' | translate }}
    </button>
    <button
      (click)="sendWhatsappMessage()"
      [disabled]="!phoneValue.length || !textFieldValue.length"
      class="whatsapp-btn btn --green"
      type="button"
    >
      <img src="./assets/icons/whatsapp.svg" alt="">
      <span>{{ 'MODALS.SMS.SEND_WHATSAPP' | translate }}</span>
    </button>
  </div>
</form>
