import { Component, Input, OnInit } from '@angular/core';
import { SmsItem } from '@api-clients/crm-api-client/models/sms-item';

@Component({
  selector: 'app-last-action-sms',
  templateUrl: './last-action-sms.component.html',
  styleUrls: ['./last-action-sms.component.scss'],
})
export class LastActionSmsComponent implements OnInit {
  @Input() lastActionItem: SmsItem;
  @Input() actionsDateFormat: string;

  ngOnInit(): void {}
}
