<p *ngIf="isStars; else defaultName"
   class="hotel-stars">
  <img *ngFor="let star of stars"
       [attr.src]="star ? 'assets/icons/search/hotel-stars-full.svg'
                        : 'assets/icons/search/hotel-stars-empty.svg'" />
</p>

<ng-template #defaultName>
  <p class="default-value">{{ value }}</p>
</ng-template>
