import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageStatusComponent } from './components/message-status.component';

@NgModule({
  declarations: [MessageStatusComponent],
  imports: [CommonModule],
  exports: [MessageStatusComponent],
})
export class MessageStatusModule {}
