import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChatTimeline from './chat-timeline.reducer';

export const selectChatTimelineState = createFeatureSelector<fromChatTimeline.ChatTimelineState>(
  fromChatTimeline.chatTimelineFeatureKey,
);

export const selectCurrentReplyMessage = createSelector(
  selectChatTimelineState,
  state => state.currentReplyMessage,
);

export const selectHasScheduledMessages = createSelector(
  selectChatTimelineState,
  chatState => chatState.scheduledMessages.length > 0,
);

export const selectScheduledMessages = createSelector(
  selectChatTimelineState,
  chatState => chatState.scheduledMessages,
);

export const selectIsSchedulingMessage = createSelector(
  selectChatTimelineState,
  chatState => chatState.isSchedulingMessage,
);

export const selectIsScheduleMessageOverlayOpen = createSelector(
  selectChatTimelineState,
  chatState => chatState.isScheduleMessageOverlayOpen,
);

export const selectIsScheduledMessagesListOpen = createSelector(
  selectChatTimelineState,
  chatState => chatState.isScheduledMessagesListOpen,
);
