<div class="recall-step" *ngIf="isLoaded; else loading">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <h3>{{TRANSLATE_PATH + "TITLE" | translate}}</h3>
    <p>{{TRANSLATE_PATH + "TIP" | translate}}</p>

    <div class="datetime-chips">
      <div class="modal-chip"
              (click)="datetimepicker.selectTimeOnTimegrid(period)"
              *ngFor="let period of TIME_PERIODS_ARRAYS">
         {{ 'MODALS.ACTIONS.TIME_PERIOD.' + period | translate }}
      </div>
    </div>

    <form [formGroup]="form">
      <app-ui-datetimepicker [dateControl]="meetingDateControl"
                             [timeControl]="meetingTimeControl"
                             labelTranslatePath="{{TRANSLATE_PATH + 'DEADLINE'}}"
                             [isDisabled]="getIsDisabledForm()"
                             (openPopup)="openDatetimepickerPopup()">
      </app-ui-datetimepicker>
    </form>



    <textarea formControlName="managerComment"
              class="comment-field" placeholder="{{TRANSLATE_PATH + 'COMMENT_PLACEHOLDER' | translate}}"></textarea>

    <div class="fixed-container">
      <app-datetimepicker
        #datetimepicker
        [hidden]="!datetimePopup"
        [meetingDateTime]="form?.controls['meetingDate']?.value"
        (taskDateEvent)="setMeetingDateTime($event)"
        (manualClose)="closeDatetimepickerPopup($event); $event.stopPropagation()"
        class="timepicker"
      ></app-datetimepicker>

    </div>

    <div class="nav-buttons">
      <button class="btn secondary ghost"
              *ngIf="!isPostSale"
              (click)="goPrev()">{{"COMMON.NAV_BUTTONS.PREVIOUS" | translate}}</button>
      <button  class="btn secondary emphasis"
               [disabled]="form?.invalid || getIsDisabledForm()"
               type="submit">{{"COMMON.NAV_BUTTONS.DONE" | translate}}</button>
    </div>

  </form>
</div>


<ng-template #loading>
  <app-preloader></app-preloader>
</ng-template>
