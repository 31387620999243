import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatMessagesFacade } from 'app/+state/chat-messages/chat-messages.facade';
import { WHATSAPP_MESSAGE_DELETE_CONFIRM } from 'app/core/services/amplitude/amplitudeEvents';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';

@Component({
  selector: 'app-delete-message-confirm',
  templateUrl: './delete-message-confirm.component.html',
  styleUrls: ['./delete-message-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DeleteMessageConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      messageId: string;
    },
    public dialogRef: MatDialogRef<DeleteMessageConfirmComponent>,
    private chatMessagesFacade: ChatMessagesFacade,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  public deleteMessageConfirm(): void {
    this.chatMessagesFacade.deleteMessage(this.data.messageId);
    this.trackDeleteMessageConfirmClick();
    this.closeModal();
  }

  private trackDeleteMessageConfirmClick(): void {
    this.amplitudeTrackService.trackEvent(WHATSAPP_MESSAGE_DELETE_CONFIRM);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
