<div class="container">
  <button class="btn"
          [ngClass]="{'--pressed': isModalVisible,
           'highlighted': isButtonHighlighted}"
          (click)="toggleCallModal()"
  >
    <img src="assets/icons/deal-view/call.svg" alt="call">
    Позвонить</button>

  <div class="modal" [ngClass]="{'--visible': isModalVisible}">
    <div class="arrow"></div>
    <h1>Выберите номер</h1>
    <div class="modal-content">
      <app-click2call-phones
        [clientName]="clientName"
      [phoneItems]="phones"
      [parentForm]="parentForm"
      (closeCallModal)="closeCallModal()"
      >

      </app-click2call-phones>

    </div>
    <button class="close" (click)="closeCallModal()">
      <img src="assets/icons/deal-view/close-modal.svg" alt="close">
    </button>
  </div>

</div>

<div class="overlay"
     *ngIf="isModalVisible" (click)="closeCallModal()"
></div>
