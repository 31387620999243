import { Component, Input } from '@angular/core';
import { Passport } from '@api-clients/crm-api-client/models/passport';

@Component({
  selector: 'app-tour-package-info-tourists',
  templateUrl: './tour-package-info-tourists.component.html',
  styleUrls: ['./tour-package-info-tourists.component.scss'],
})
export class TourPackageInfoTouristsComponent {
  @Input() passports: Passport[];
}
