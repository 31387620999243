import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  CrmCardItem,
  CrmCardViewItem,
  CrmTaskItem,
  CrmTaskType,
  DealItem,
  SearchRequest,
  WhatsappMessage,
  WhatsappMessageStatusList,
  WhatsappMessageTypeList,
} from '@api-clients/crm-api-client';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { DealsListFacade } from '../../../../../../../+state/deals-list/deals-list.facade';
import { AmplitudeTrackService } from '../../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../../core/services/amplitude/amplitudeEventData';
import {
  BOOKING_HISTORY_OPEN,
  DEAL_TASK_EDIT_CLICK,
  DEAL_VIEW_OPENED,
  MANAGER_OFFERS_LIST_CLICK,
} from '../../../../../../../core/services/amplitude/amplitudeEvents';
import { dateDiff } from '../../../../../../../helpers/dateDiff';
import { PopupService } from '../../../../../../../shared/services/popup-service.service';
import { ReservationsModalService } from '../../../../client-reservations/services/reservations-modal.service';
import { CreateTaskModalPayload } from '../../../../deal-view/models/create-task-modal.model';
import { StepsId } from '../../../../deal-view/modules/create-task-modal/create-task-modal/create-task-modal';
import { CreateTaskModalComponent } from '../../../../deal-view/modules/create-task-modal/create-task-modal/create-task-modal.component';
import { CreateTaskService } from '../../../../deal-view/modules/create-task-modal/services/create-task.service';
import { CurrentStageEnum } from '../../../../deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import { MarkAsNotNewService } from '../../../../deal-view/services/mark-as-not-new/mark-as-not-new.service';
import { ManagerOffersModalService } from '../../../../manager-offers/services/manager-offers-modal.service';
import { NeedConnectType } from '../../../deal-list';

export enum ReturnTouristStatusList {
  NEW = 'new',
  GOLD = 'gold',
  DIAMOND = 'diamond',
}

// TODO: REFACTOR ts, html, css!

@Component({
  selector: 'app-deals-list-item-new',
  templateUrl: './deals-list-item-new.component.html',
  styleUrls: ['./deals-list-item-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsListItemNewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() crmCardViewItem: CrmCardViewItem;

  public readonly messageTypes = WhatsappMessageTypeList;
  public crmCard: CrmCardItem;
  public deal: DealItem;
  public hasAvatar = false;
  public inWorkSince: string;
  public clientHasMobileApp = false;
  public returnTouristStatusList = ReturnTouristStatusList;
  public isReturnStatusPopupOpened = false;
  public isPhoneHover = false;
  public message: WhatsappMessage | null;
  public messageStatusIconPath: string;
  public messageStatusIconAltText: string;
  public isMessageFromMe: boolean;
  public messageAuthorName: string;
  public messageTextFirstLine: string;
  public formattedWhatsappMessageDate: string;
  public hasNewMessages = false;

  public searchOption: SearchRequest;
  public formattedDepartureDate: string;
  public formattedTouristsCount: string;
  public formattedNightsCount: string;
  public isNearbyDates = false;

  public nextTask: CrmTaskItem;
  public formattedTaskDate: string;
  public formattedTaskTime: string;

  public isLastCallMissed = false;
  public formattedMissedCallDateTime: string;

  public hasManagerOffers = false;
  public hasViewedReadManagerOffers = false;

  private readonly screenType: ScreenTypes = ScreenTypes.DEAL_LISTING;
  private readonly statusIconMap: Partial<{
    [key in WhatsappMessageStatusList]: { path: string; alt: string };
  }> = {
    [WhatsappMessageStatusList.Read]: {
      path: './assets/icons/whatsapp/message-status/read.svg',
      alt: 'Прочитано',
    },
    [WhatsappMessageStatusList.Sent]: {
      path: './assets/icons/whatsapp/message-status/sent.svg',
      alt: 'Отправлено',
    },
    [WhatsappMessageStatusList.Received]: {
      path: './assets/icons/whatsapp/message-status/received.svg',
      alt: 'Доставлено',
    },
    [WhatsappMessageStatusList.InFlight]: {
      path: './assets/icons/whatsapp/message-status/in-flight.svg',
      alt: 'Отправляется',
    },
    [WhatsappMessageStatusList.Unknown]: {
      path: './assets/icons/whatsapp/message-status/in-flight.svg',
      alt: 'Неизвестно',
    },
  };
  public readonly taskTypeIconMap = {
    [CrmTaskType.Calling]: 'assets/icons/task-type/deals-list/call.svg',
    [CrmTaskType.Meeting]: 'assets/icons/task-type/deals-list/meeting.svg',
    [CrmTaskType.Urgent]: 'assets/icons/task-type/deals-list/urgent.svg',
    [CrmTaskType.Callback]: 'assets/icons/task-type/deals-list/call_back.svg',
    [CrmTaskType.ReturnClient]: 'assets/icons/task-type/deals-list/return_client.svg',
    [CrmTaskType.Whatsapp]: 'assets/icons/task-type/deals-list/message.svg',
  };
  private messageCurrentStatus: WhatsappMessageStatusList = WhatsappMessageStatusList.Unknown;
  private destroy$ = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private dealListFacade: DealsListFacade,
    private markAsNotNewService: MarkAsNotNewService,
    private amplitudeTrackService: AmplitudeTrackService,
    private reservationsModalService: ReservationsModalService,
    private popupService: PopupService,
    private createTaskService: CreateTaskService,
    private managerOffersModalService: ManagerOffersModalService,
  ) {}

  ngOnInit(): void {
    this.setupSubscriptions();
    this.initializeComponent();
  }

  // TODO: Decompose changes into chunks to optimize rendering if performance issues arise.
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.crmCardViewItem &&
      changes.crmCardViewItem.currentValue !== changes.crmCardViewItem.previousValue
    ) {
      this.initializeComponent();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public dealViewOpen() {
    this.dealListFacade.setLastSelectedListItemId(this.deal.id);

    this.markAsNotNewService
      .markAsNotNew({
        dealId: this.deal.id,
      })
      .pipe(first())
      .subscribe();

    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, { screenType: this.screenType });
  }

  public showReservationsList(e: Event, crmCardId: number) {
    e.preventDefault();
    e.stopPropagation();
    this.amplitudeTrackService.trackEvent(BOOKING_HISTORY_OPEN, {
      screenType: this.screenType,
    });
    this.reservationsModalService.showReservationsModal(crmCardId);
  }

  public showManagerOffersModal(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.amplitudeTrackService.trackEvent(MANAGER_OFFERS_LIST_CLICK, {
      'Screen type': this.screenType,
    });
    this.managerOffersModalService.showManagerOffersModal(this.deal.id, this.deal.crmId);
  }

  public openCreateTaskModal(task: CrmTaskItem): void {
    this.amplitudeTrackService.trackEvent(DEAL_TASK_EDIT_CLICK, {
      'Screen type': this.screenType,
    });
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.NEXT_STAGE);
    this.createTaskService.setCheckedStage(this.deal.stage as CurrentStageEnum);
    this.createTaskService.setCheckedTaskType(task.type);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCard?.id,
      dealId: this.deal?.id,
      dealStage: this.deal?.stage,
      phone: `${this.crmCard?.phones[0]?.code}${this.crmCard?.phones[0]?.phone}`,
      dealStatus: this.deal?.status,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload, place: this.screenType });
  }

  public toggleReturnTypeOverlay(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.isReturnStatusPopupOpened = !this.isReturnStatusPopupOpened;
  }

  public togglePhoneHover(): void {
    this.isPhoneHover = !this.isPhoneHover;
  }

  private setupSubscriptions() {
    this.popupService.popupOpenSource.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (!status.isOpen && status.closedByBackdropClick) {
        this.createTaskService.reset();
      }
    });
  }

  private initializeComponent() {
    this.extractBasicData();
    this.processComponentProps();
  }

  private extractBasicData() {
    this.crmCard = this.crmCardViewItem.card;
    this.deal = this.crmCardViewItem.deal;
  }

  private processComponentProps() {
    this.processHasAvatar();
    this.processClientHasMobileApp();
    this.processInWorkSince();
    this.processLastWhatsappMessage();
    this.processHasNewMessages();
    this.processSearchOption();
    this.processNextTask();
    this.processMissedCall();
    this.processManagerOffers();
    this.processHasViewedManagerOffers();
  }

  private processHasAvatar() {
    this.hasAvatar = !!this.crmCard?.avatar;
  }

  private processClientHasMobileApp() {
    this.clientHasMobileApp = !!this.crmCard?.hasMobileApplication;
  }

  private processInWorkSince(): void {
    this.inWorkSince =
      this.getDaysInWorkFromExperimentalData() ?? this.calculateDaysSinceStartWork(this.getStartWorkDate());
  }

  private getDaysInWorkFromExperimentalData(): string | null {
    const daysInWork = this.crmCardViewItem.experimentalData?.daysAtWork;
    return daysInWork ? daysInWork.toString() + ' д.' : null;
  }

  private getStartWorkDate(): string | null {
    return this.deal?.startWorkDate || this.deal?.createDate || null;
  }

  private calculateDaysSinceStartWork(startWorkDate: string | null): string | null {
    return startWorkDate ? dateDiff(new Date(startWorkDate)) : null;
  }

  private processLastWhatsappMessage() {
    this.message = this.crmCardViewItem?.lastWhatsappMessage;
    if (this.message) {
      this.messageTextFirstLine = this.getMessageFirstLine();
      this.isMessageFromMe = this.getIsMessageFromMe();
      this.messageAuthorName = this.getMessageAuthorName();
      this.messageCurrentStatus = this.getMessageStatus();
      this.formattedWhatsappMessageDate = this.getFormattedWhatsappMessageDate(this.message.receivedAt);
      this.processMessageStatusIconProps();
    }
  }

  private processHasNewMessages() {
    this.hasNewMessages = this.deal?.conversationStatus === 1;
  }

  private processSearchOption() {
    this.searchOption = this.crmCardViewItem.lastSearchRequest;
    if (this.searchOption) {
      this.isNearbyDates = this.searchOption?.isNearbyDates;
      this.formattedDepartureDate = this.getFormattedDepartureDate(this.searchOption?.dateFrom);
      this.formattedTouristsCount = this.getFormattedTouristsCount(this.searchOption);
      this.formattedNightsCount = this.getFormattedNightsCount(this.searchOption);
    }
  }

  private processNextTask() {
    this.nextTask = this.getNextTask();
    if (this.nextTask) {
      const formattedDateTime = this.getFormattedDateTime(this.nextTask.date, this.nextTask.time);
      this.formattedTaskDate = formattedDateTime.date;
      this.formattedTaskTime = formattedDateTime.time;
    }
  }

  private getMessageFirstLine(): string {
    return this.message?.text.split('\n')[0];
  }

  private getIsMessageFromMe(): boolean {
    return !!this.message?.isFromMe;
  }

  private getMessageAuthorName() {
    return this.isMessageFromMe ? 'Вы:' : '';
  }

  private getMessageStatus(): WhatsappMessageStatusList {
    return this.message?.status;
  }

  private getFormattedWhatsappMessageDate(messageReceived: string): string {
    const messageReceivedDate = new Date(messageReceived);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    if (messageReceivedDate >= today) {
      // Форматируем как "HH:mm"
      return messageReceivedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    if (messageReceivedDate >= yesterday) {
      // Дата равна "вчера"
      return 'вчера';
    }
    if (messageReceivedDate >= lastWeek) {
      // День недели, если дата за последнюю неделю
      return messageReceivedDate.toLocaleDateString('ru-RU', { weekday: 'short' });
    }
    // Форматируем как "dd/MM/yyyy" для остальных случаев
    return messageReceivedDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  private processMessageStatusIconProps() {
    const statusInfo =
      this.statusIconMap[this.messageCurrentStatus] || this.statusIconMap[WhatsappMessageStatusList.Unknown];
    this.messageStatusIconPath = statusInfo.path;
    this.messageStatusIconAltText = statusInfo.alt;
  }

  private getFormattedDepartureDate(dateStr: string): string {
    if (!dateStr) {
      return '';
    }

    const dateParts = dateStr.split('-');
    if (dateParts.length !== 3) {
      return '';
    }

    const day = parseInt(dateParts[2], 10).toString();
    const month = parseInt(dateParts[1], 10).toString().padStart(2, '0');

    return `${day}.${month}`;
  }

  private getFormattedTouristsCount(searchOption: SearchRequest): string {
    if (!searchOption) {
      return '';
    }

    const { adult, child, childAges } = searchOption;

    let result = `${adult} взр`;

    if (child) {
      result += `+${child} дет`;
      if (childAges) {
        result += ` (${childAges})`;
      }
    }

    return result;
  }

  private getFormattedNightsCount(searchOption: SearchRequest): string {
    if (!searchOption) {
      return '';
    }

    const { nightsFrom } = searchOption;

    return `(${nightsFrom} ночей)`;
  }

  private getNextTask() {
    const shouldFindUrgentOrMissed = this.crmCardViewItem?.needConnectType === NeedConnectType.urgentOrMissed;

    const urgentOrMissedTask = shouldFindUrgentOrMissed
      ? this.crmCardViewItem?.actualTasks.find(
          task => task.type === CrmTaskType.Urgent || task.type === CrmTaskType.Missed,
        )
      : null;

    return urgentOrMissedTask || this.crmCardViewItem?.nextTask;
  }

  private getFormattedDateTime(date: string, time: string) {
    const dateTime = `${date}T${time}`;
    const formattedDate = this.datePipe.transform(dateTime, 'd MMM')?.replace('.', '') || '';
    const formattedTime = this.datePipe.transform(dateTime, 'HH:mm') || '';
    return { date: formattedDate, time: formattedTime };
  }

  private processMissedCall() {
    const lastCall = this.crmCardViewItem.calls?.[0];
    if (lastCall) {
      this.isLastCallMissed = !lastCall.answer;
      if (this.isLastCallMissed) {
        this.formattedMissedCallDateTime = this.getFormattedMissedCallDateTime(lastCall.dateTime);
      }
    }
  }

  // implement if need
  private getFormattedMissedCallDateTime(datetime: string): string {
    return datetime;
  }

  private processManagerOffers(): void {
    this.hasManagerOffers = !!this.crmCardViewItem?.managerOffersStat?.count;
  }

  private processHasViewedManagerOffers(): void {
    this.hasViewedReadManagerOffers = !!this.crmCardViewItem?.managerOffersStat?.viewedCount;
  }
}
