<div class="whatsapp-client-card" *ngIf="currentDealViewItem && currentDealViewItem.deal; else emptyDeal">
  <app-deals-item
    [crmCardListItem]="currentDealViewItem"
    [attr.data-deal-id]="currentDealViewItem.deal.id"
    [attr.data-card-id]="currentDealViewItem.card.id"
    [isWhatsappClientCard]="true"
    [isWhatsappClientCardFromDealView]="isFromDealView"
    [isModal]="isModal"
    [isShowCardInfo]="isShowCardInfo"
    (mouseenter)="showClientCard()"
    (mouseleave)="hideClientCard()"
  ></app-deals-item>
  <div class="hide-reveal"
       [ngClass]="{'modal-hide-reveal':isModal}" >
    <span *ngIf="isShowCardInfo; else expandIcon">
      Меньше информации
      <img src="/assets/icons/whatsapp/arrow-up.svg"
           class="hide"
           alt="hide"/></span>

    <ng-template #expandIcon>
      <span>Больше информации
        <img src="/assets/icons/whatsapp/arrow-up.svg"
             alt="expand"
        />
      </span>
    </ng-template>
  </div>
</div>

<ng-template #emptyDeal>
  <div *ngIf="currentDealViewItem.card as card"
       class="info-card is-whatsapp-client-card">
    <div  class="info-card-header">
      <app-client-card-info [card]="card"
                            [dealInfo]="currentDealViewItem.deal"
                            [crmCardViewItem]="currentDealViewItem"
                            [isWhatsappClientCard] = "true"
                            [isModal]="isModal"

      ></app-client-card-info>
    </div>
  </div>
</ng-template>
