import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-request-stars',
  templateUrl: './search-request-stars.component.html',
  styleUrls: ['./search-request-stars.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SearchRequestStarsComponent {
  @Input() stars: number;

  get filledStars(): number[] {
    return Array(this.stars).fill(0);
  }

  get unfilledStars(): number[] {
    return Array(5 - this.stars).fill(0);
  }
}
