import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-search-result-label-combi',
  template: `<span class="is-combi">комби</span>`,
  styles: `
    .is-combi {
      text-transform: uppercase;
      font-size: 9px;
      border-radius: 8px;
      padding: 2px 4px;
      background-color: #ff7a00;
      color: #fff;
      font-weight: 600;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchResultLabelCombiComponent {}
