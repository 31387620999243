import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { PhoneItem } from '@api-clients/crm-api-client';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import {
  PHONE_NUMBER_ADDED,
  PHONE_NUMBER_REMOVED,
} from '../../../../../../core/services/amplitude/amplitudeEvents';
import { PhoneInputWithCodeModule } from 'app/ui-components/phone-input-with-code/phone-input-with-code.module';
import { FormErrorModule } from 'app/ui-components/form-error/form-error.module';

const PHONES_AMOUNT_LIMIT = 7;

export enum MultiplePhoneInputModes {
  dealViewMode = 'dealViewMode',
  dealCreateMode = 'dealCreateMode',
}
const HIDE_PHONES_LANG_KEY = 'PAGES.DEALS.PHONES.MORE_PHONES.HIDE';
const SHOW_PHONES_LANG_KEY = 'PAGES.DEALS.PHONES.MORE_PHONES.SHOW';

@UntilDestroy()
@Component({
  selector: 'app-multiple-phone-input',
  templateUrl: './multiple-phone-input.component.html',
  styleUrls: ['./multiple-phone-input.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, PhoneInputWithCodeModule, FormErrorModule],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          maxHeight: '1000px',
          opacity: 1,
          visibility: 'visible',
        }),
      ),
      state(
        'closed',
        style({
          maxHeight: '0',
          opacity: 0,
          visibility: 'hidden',
        }),
      ),
      transition('open <=> closed', [animate('0.5s')]),
    ]),
  ],
})
export class MultiplePhoneInputComponent implements OnInit {
  @Input() selectedPhoneItem: PhoneItem;

  @Input() whatsappPhone: PhoneItem;
  @Input() readonly screenType: ScreenTypes;
  @Input() cardId: number;
  @Input() phonesWithCodes: PhoneItem[];
  @Input() parentForm: UntypedFormGroup;
  @Input() mode: MultiplePhoneInputModes;
  @Output() countryCodesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() phonesWithCountryCode: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() selectedPhoneChanged: EventEmitter<PhoneItem> = new EventEmitter<PhoneItem>();
  public firstPhone: PhoneItem;
  public readonly MODES = MultiplePhoneInputModes;
  public countryCodes: string[] = [];
  public phoneValues: string[];
  public phoneAmountLimit: number = PHONES_AMOUNT_LIMIT;

  public isShowMorePhones = false;
  public HIDE_PHONES = '';
  public SHOW_PHONES = '';
  constructor(
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private amplitudeTrackService: AmplitudeTrackService,
    private translate: TranslateService,
  ) {
    this.HIDE_PHONES = this.translate.instant(HIDE_PHONES_LANG_KEY);
    this.SHOW_PHONES = this.translate.instant(SHOW_PHONES_LANG_KEY);
  }

  ngOnInit(): void {
    this.firstPhone = this.phonesWithCodes?.[0];

    if (this.phones.length === 0) {
      this.addPhone();
    }
    this.phones.valueChanges.subscribe(() => {
      this.cdRef.markForCheck();
    });
  }

  public get phones(): UntypedFormArray {
    return this.parentForm.get('phones') as UntypedFormArray;
  }

  public get formPhonesValues(): string[] {
    return (this.parentForm.get('phones').value as string[]) || [];
  }

  public addPhone(): void {
    this.amplitudeTrackService.trackEvent(PHONE_NUMBER_ADDED, { screenType: ScreenTypes.ADD_DEAL });
    this.phones.push(this.fb.control(''));
    this.isShowMorePhones = true;
  }

  public getIsHiddenPhoneControl(phoneNumber): boolean {
    if (!this.isDealViewMode) {
      return false;
    }

    if (this.selectedPhoneItem) {
      return this.selectedPhoneItem.phone === phoneNumber;
    }
    return false;
  }

  public removePhone(index: number): void {
    this.amplitudeTrackService.trackEvent(PHONE_NUMBER_REMOVED, { screenType: this.screenType });
    this.phones.removeAt(index);
    this.countryCodes.splice(index, 1);
    this.phoneValues = this.prefixWithCountryCode(this.formPhonesValues);
    this.phones.markAsDirty();
    this.emitPhonesWithCountryCode(this.phoneValues);
  }

  onPhoneValueChange(value, idx): void {
    this.phoneValues = this.formPhonesValues;
    this.phoneValues[idx] = value;
    this.phoneValues = this.prefixWithCountryCode(this.phoneValues);
    this.emitPhonesWithCountryCode(this.phoneValues);
  }

  onCountryCodeChange($event: string, idx: number) {
    this.countryCodes[idx] = $event;
    this.emitPhonesWithCountryCode(this.phoneValues);
  }

  prefixWithCountryCode(phones: string[]) {
    return phones.map((phone, idx) => {
      const code = this.countryCodes && this.countryCodes[idx] ? this.countryCodes[idx] : null;
      return code && phone ? `${code}${phone}` : '';
    });
  }

  setInitialPhoneValuesWithCode(phone: AbstractControl): PhoneItem {
    if (this.phonesWithCodes) {
      return this.phonesWithCodes.find(item => item.phone === phone.value);
    }
  }

  emitPhonesWithCountryCode(phones: string[]): void {
    this.phonesWithCountryCode.emit(phones);
  }

  onPhoneSelected($event: PhoneItem) {
    this.selectedPhoneItem = $event;
    this.selectedPhoneChanged.emit($event);
    this.cdRef.markForCheck();
  }

  toggleMorePhones() {
    this.isShowMorePhones = !this.isShowMorePhones;
  }

  get isDealViewMode() {
    return this.mode === this.MODES.dealViewMode;
  }
}
