function addLeadingZero(timeDigits: number) {
  return (timeDigits < 10 ? '0' : '') + timeDigits;
}

export function getHoursWithLeadingZero(date: Date) {
  return addLeadingZero(date.getHours());
}

export function getMinutesWithLeadingZero(date: Date) {
  return addLeadingZero(date.getMinutes());
}

export function getSecondsWithLeadingZero(date: Date) {
  return addLeadingZero(date.getSeconds());
}

export function getMonthWithLeadingZero(date: Date) {
  return addLeadingZero(date.getMonth() + 1);
}

export function getFormattedTime(date: Date): string {
  const hours = getHoursWithLeadingZero(date);
  const minutes = getMinutesWithLeadingZero(date);
  return `${hours}:${minutes}`;
}

// 15.01.2021
export function getFormattedDate(date: Date): string {
  return `${date.getDate()}.${getMonthWithLeadingZero(date)}.${date.getFullYear()}`;
}

// 2021-1-15
export function getAPIFormattedDate(date: Date): string {
  if (!date) {
    return '';
  }
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getFormattedTimeWithSec(date: Date): string {
  return `${getFormattedTime(date)}:${getSecondsWithLeadingZero(date)}`;
}
