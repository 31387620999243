import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateTaskModalComponent } from './create-task-modal/create-task-modal.component';
import { ChooseActionStepComponent } from './create-task-modal/components/choose-action-step/choose-action-step.component';
import { ProgressBarComponent } from './create-task-modal/components/progress-bar/progress-bar.component';
import { NextStageStepComponent } from './create-task-modal/components/next-stage-step/next-stage-step.component';
import { SharedModule } from '../../../../../../shared/shared.module';
import { FormComponentsModule } from '../../../../../form-components/form-components.module';
import { DatetimepickerModule } from '../datetimepicker/datetimepicker.module';
import { ResultStepComponent } from './create-task-modal/components/result-step/result-step.component';
import { ReassignStepComponent } from './create-task-modal/components/reassign-step/reassign-step.component';
import { CompleteDealStepComponent } from './create-task-modal/components/complete-deal-step/complete-deal-step.component';
import { CompleteDealReasonListComponent } from './create-task-modal/components/complete-deal-step/components/complete-deal-reason-list/complete-deal-reason-list.component';
import { NoFoundAnyTourPackagesComponent } from './create-task-modal/components/complete-deal-step/components/no-found-any-tour-packages/no-found-any-tour-packages.component';
import { RecallStepComponent } from './create-task-modal/components/recall-step/recall-step.component';
import { UiDatetimepickerModule } from '../../../../../../ui-components/ui-datetimepicker/ui-datetimepicker.module';
import { NonConversionReasonListComponent } from './create-task-modal/components/complete-deal-step/components/non-conversion-reason-list/non-conversion-reason-list.component';

@NgModule({
  declarations: [
    CreateTaskModalComponent,
    ChooseActionStepComponent,
    ProgressBarComponent,
    NextStageStepComponent,
    ResultStepComponent,
    ReassignStepComponent,
    CompleteDealStepComponent,
    CompleteDealReasonListComponent,
    NoFoundAnyTourPackagesComponent,
    RecallStepComponent,
    NonConversionReasonListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FormComponentsModule,
    DatetimepickerModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    UiDatetimepickerModule,
  ],
})
export class CreateTaskModalModule {}
