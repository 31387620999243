/* eslint-disable no-console */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DealCreateResponse, FindDealResponse } from '@api-clients/crm-api-client';
import { DealCreateRequest } from '@api-clients/crm-api-client/dist/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { ConfigsService, ElectronService, WorkerStateService } from '../../../../core/services';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import {
  getAPIFormattedDate,
  getFormattedDate,
  getFormattedTime,
  getFormattedTimeWithSec,
} from '../../../../helpers/time';
import { DealSource, Worker } from '../../../../models';
import { Notify, NotifyService, NotifyTypeEnum } from '../../../../shared/notify/services/notify.service';
import { WorkersListService } from '../../../layout/components/workers-list/services/workers-list.service';
import {
  ActionsPopupHelperService,
} from '../deal-view/components/actions-popup-menu/services/actions-popup-helper.service';
import { DateTimeValue } from '../deal-view/modules/datetimepicker/datetimepicker.component';
import { CurrentStageEnum } from '../deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import { DealViewService } from '../deal-view/services/deal-view.service';
import { ManagerListService } from '../deal-view/services/manager-list.service';
import { MultiplePhoneInputModes } from './components/multiple-phone-input/multiple-phone-input.component';
import { DealCreateService } from './services/deal-create.service';
import { DealParamsService } from './services/deal-params.service';

const FORM_PLACE = 'mainManualForm';
const TASK_PLACE = 'manual';
const CALL_ID = null;
const OWN_INTENT_KEY = 'own';
const LANG_KEYS = {
  intentTypes: 'PAGES.DEALS.INTENT_TYPES',
  actionTypes: 'PAGES.DEALS.ADD_DEAL_CRM_TASK_TYPES',
  clientReadinessTypes: 'PAGES.DEALS.CLIENT_READINESS',
  stages: 'PAGES.DEALS.SALES_FUNNEL.ADD_DEAL_STAGES',
};
const MINIMAL_PHONE_LENGTH = 10;

function hasValidElements(control: AbstractControl): ValidationErrors | null {
  const array = control as UntypedFormArray;

  if (array.controls.length === 0) {
    return { noElements: true };
  }

  for (const ctrl of array.controls) {
    if (!ctrl.value) {
      return { emptyElement: true };
    }
  }

  return null;
}

@UntilDestroy()
@Component({
  selector: 'app-add-deal',
  templateUrl: './add-deal.component.html',
  styleUrls: ['./add-deal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDealComponent implements OnInit {
  public readonly MULTIPLE_PHONE_INPUT_MODES = MultiplePhoneInputModes;
  public modalData = null;
  public form: UntypedFormGroup;
  public screenType: ScreenTypes = ScreenTypes.ADD_DEAL;
  public readonly placeholderSourceField = 'Не выбрано';
  public readonly placeholderIntent = 'Выбрать намерение';
  public readonly placeholderAction = 'Выбрать действие';
  public readonly placeholderCity = 'Выбрать город';
  public readonly placeholderOwnIntent = 'Свой вариант';
  public placeholderManager = 'Выберите менеджера';
  public readonly stagesPlaceholder = 'Этап в воронке продаж';

  public meetingDateTime: Date;
  public managersPopup: boolean;
  public datetimePopup: boolean;

  public intentTypes: { key: string; value: unknown }[];
  public actionTypes: { key: string; value: unknown }[];
  public stages: { key: string; value: unknown }[];
  public clientReadinessTypes: { key: number; value: unknown }[];
  public citiesList: { key: number; value: string }[];
  public managerDefaultId: number;
  public showOwnIntent: boolean;
  public taskTitle: string;
  public searchURL: string;
  public isLoading: boolean;
  public isSearching = false;
  public phonesWithCountryCodes: string[];
  public dealSources$: Observable<DealSource[]>;
  public managersList$: Observable<Worker[]>;
  public selectedManager: Worker;
  public selectedManagerId: number;
  public selectedCityId: number;
  public currentCityId: number;
  private currentWorker;
  public adminUrl: string;
  public alreadyExistingDeal: FindDealResponse = null;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private fb: UntypedFormBuilder,
    private dealCreateService: DealCreateService,
    private dealViewService: DealViewService,
    private workerStateService: WorkerStateService,
    private workersListService: WorkersListService,
    private dialogRef: MatDialogRef<AddDealComponent>,
    private translate: TranslateService,
    private translatePipe: TranslatePipe,
    private cdRef: ChangeDetectorRef,
    private electron: ElectronService,
    private dealParamsService: DealParamsService,
    private configsService: ConfigsService,
    private notifyService: NotifyService,
    private router: Router,
    private actionsPopupHelperService: ActionsPopupHelperService,
    private managerListService: ManagerListService,
  ) {
    this.modalData = data;
    this.managersPopup = false;
    this.isSearching = false;
    this.intentTypes = [];
    this.actionTypes = [];
    this.stages = [];
    this.clientReadinessTypes = [];
    this.citiesList = [];
    this.showOwnIntent = false;
    this.adminUrl = this.configsService.adminUrl;
    this.configsService.cities.forEach(city => {
      this.citiesList.push({ key: city.id, value: city.name });
    });
    this.translate
      .get([LANG_KEYS.intentTypes, LANG_KEYS.actionTypes, LANG_KEYS.clientReadinessTypes, LANG_KEYS.stages])
      .pipe(untilDestroyed(this))
      .subscribe(types => {
        for (const [key, value] of Object.entries(types[LANG_KEYS.intentTypes])) {
          this.intentTypes.push({ key, value });
        }
        for (const [key, value] of Object.entries(types[LANG_KEYS.actionTypes])) {
          this.actionTypes.push({ key, value });
        }
        for (const [key, value] of Object.entries(types[LANG_KEYS.clientReadinessTypes])) {
          const keyNumber = Number(key);
          this.clientReadinessTypes.push({ key: keyNumber, value });
        }

        for (const [key, value] of Object.entries(types[LANG_KEYS.stages])) {
          this.stages.push({ key, value });
        }
      });
  }

  ngOnInit(): void {
    const { workerId } = this.workerStateService;
    this.selectedManagerId = workerId;

    this.currentCityId = this.workerStateService.currentCityId;
    this.dealSources$ = this.configsService.dealSources$;

    this.currentWorker = this.workerStateService.currentWorkerValue;
    this.selectManager(this.currentWorker);
    this.selectedCityId = this.currentWorker?.cityId;
    this.managerDefaultId = this.currentWorker?.id;
    const currentCityId = this.currentWorker?.cityId;
    this.form = this.fb.group({
      managerId: [workerId, Validators.required],
      stage: [CurrentStageEnum.new, Validators.required],
      sourceId: [0, Validators.required],
      addUserId: [workerId],
      roistatVisitId: [null],
      place: [FORM_PLACE, Validators.required],
      card: this.fb.group(
        {
          name: [this?.modalData?.name || '', Validators.required],
          email: [''],
          phones: this.fb.array(this?.modalData?.phone ? [this?.modalData?.phone] : [], hasValidElements),
        },
        Validators.required,
      ),
      task: this.fb.group(
        {
          cityId: [currentCityId, Validators.required],
          callId: [CALL_ID],
          phone: [''],
          place: [TASK_PLACE],
          type: ['', Validators.required],
          managerComment: [''],
          assignedManagerId: [0, Validators.required],
          marketingSourceId: [null],
          isDelayed: [0],
          allowAssignToAnyManager: [0],
          lastTaskId: [null],
          lastTaskResult: [null],
          duration: [0, Validators.required],
          intent: ['', Validators.required],
          ownIntent: [null],
          meetingDate: ['', Validators.required],
          meetingTime: ['', Validators.required],
          clientReadiness: [null],
        },
        Validators.required,
      ),
    });

    const DATE_TIME_VALUE: DateTimeValue = {
      date: new Date(),
      dateValue: getFormattedDate(new Date()),
      timeValue: getFormattedTime(new Date()),
      duration: this.actionsPopupHelperService.getDuration(this.getTask.get('type').value),
    };
    this.setMeetingDateTime(DATE_TIME_VALUE);

    this.phones.valueChanges
      .pipe(debounceTime(1000))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onPhonesChange();
        this.isSearching = false;
        this.cdRef.detectChanges();
      });

    this.managersList$ = this.managerListService
      .getManagersList(this.selectedCityId)
      .pipe(untilDestroyed(this));
  }

  get getCard() {
    return this.form.get('card') as UntypedFormGroup;
  }

  get getTask() {
    return this.form.get('task') as UntypedFormGroup;
  }

  get phones(): UntypedFormArray {
    return this.getCard.get('phones') as UntypedFormArray;
  }

  selectManager(manager: Worker): void {
    this.selectedManager = manager;
    this.selectedManagerId = manager.id;
  }

  onManagerIDChange(event: any): void {
    if (event) {
      this.selectedManagerId = event.id;
      this.getTask.get('assignedManagerId').patchValue(this.selectedManagerId);
    }
  }

  onIntentChange(event: any): void {
    this.taskTitle = event.value;
    this.showOwnIntent = event.key === OWN_INTENT_KEY;
    if (!this.showOwnIntent) {
      this.getTask.get('ownIntent').reset();
    }
  }

  onCityIdChange(event: any): void {
    if (event.key === this.selectedCityId) {
      return;
    }
    this.selectedCityId = event.key;
    this.managersList$ = this.managerListService
      .getManagersList(this.selectedCityId)
      .pipe(untilDestroyed(this));
  }

  public setMeetingDateTime(data: DateTimeValue) {
    this.meetingDateTime = data.date;
    this.patchMeetingDateTimeFormValues(data.dateValue, data.timeValue, data.duration);
  }

  patchMeetingDateTimeFormValues(meetingDate: string, meetingTime?: string, duration?: number): void {
    this.getTask.patchValue({
      meetingDate,
      meetingTime,
      duration,
    });
  }

  removeEmptyPhoneControls(phones: string[]) {
    return phones.filter(phone => phone !== '');
  }

  getFirstPhoneNumber(phones): string {
    const { 0: phone } = phones;
    return phone;
  }

  onSubmit(): void {
    console.log('onSubmit', this.form);
    if (this.form.invalid) {
      this.isLoading = false;
      this.cdRef.detectChanges();
      return;
    }

    if (!this.form.get('task.phone').value && this.phonesWithCountryCodes) {
      this.form.get('task.phone').patchValue(this.getFirstPhoneNumber(this.phonesWithCountryCodes));
    }

    if (this.form.get('sourceId').invalid) {
      this.form.get('sourceId').markAsTouched();
    }

    this.isLoading = true;

    const dealCreateRequest: DealCreateRequest = {
      ...this.form.value,
      card: {
        ...this.form.get('card').value,
        phones: this.removeEmptyPhoneControls(this.phonesWithCountryCodes),
      },
      task: {
        ...this.form.get('task').value,
        meetingDate: getAPIFormattedDate(this.meetingDateTime),
        meetingTime: getFormattedTimeWithSec(this.meetingDateTime),
      },
    };

    this.dealCreateService
      .createDeal(dealCreateRequest)
      .pipe(first())
      .subscribe(
        (data: DealCreateResponse) => {
          if (data?.cardId && this.adminUrl && this.managerDefaultId === this.selectedManagerId) {
            this.searchURL = `${this.adminUrl}/search/manager?crm_id=${data.cardId}&isAuto=0`;
            this.electron.shell.openExternal(this.searchURL).then();
          }
          const notify: Notify = {
            title: 'Сделка создана!',
            text: 'Сделка создана успешно!',
            type: NotifyTypeEnum.success,
          };
          this.notifyService.create(notify);

          if (this.managerDefaultId === this.selectedManagerId) {
            this.router.navigate([`/deals`]).then(() => {
              this.router.navigate([`/deals/view/${data?.cardId}`]).then();
            });
          }

          this.resetForm();
          this.isLoading = false;
          this.dialogRef.close();
        },
        error => {
          this.isLoading = false;
          const notify: Notify = {
            title: 'Ошибка сохранения',
            formErrors: error?.errors,
            type: NotifyTypeEnum.error,
          };
          this.notifyService.create(notify);
          this.cdRef.detectChanges();
        },
      );
  }

  openDatetimepickerPopup() {
    this.datetimePopup = true;
  }

  closeDatetimepickerPopup() {
    this.datetimePopup = false;
  }

  resetForm(): void {
    this.form.reset();
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  onPhonesChange() {
    this.alreadyExistingDeal = null;
    for (const phone of this.phonesWithCountryCodes) {
      if (phone.length >= MINIMAL_PHONE_LENGTH) {
        this.isSearching = true;
        this.cdRef.detectChanges();
        this.dealViewService
          .findDeal(phone, this.currentWorker.cityId, this.currentWorker.brandId)
          .pipe(untilDestroyed(this))
          .subscribe((alreadyExistingDeal: FindDealResponse) => {
            if (alreadyExistingDeal.dealId) {
              this.alreadyExistingDeal = alreadyExistingDeal;
              this.cdRef.detectChanges();
            } else {
              this.alreadyExistingDeal = null;
              this.cdRef.detectChanges();
            }
          });
      }
    }
  }

  redirectToExistingCard() {
    this.router.navigate(['/deals/view', this.alreadyExistingDeal.crmCardId]).then(() => {
      this.dialogRef.close();
    });
  }
}
