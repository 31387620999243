<div class="container">
  <p>{{'PAGES.DEALS.CREATE_POST_SALE_TASK.INFO' | translate}}</p>
  <!-- TODO: REFACTOR -->
  <!-- <button

    (click)="showCreateTaskModal()"
    class="btn secondary emphasis">
    {{'PAGES.DEALS.CREATE_POST_SALE_TASK.ADD_TASK' | translate}}
  </button> -->
</div>
