import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CardSearchResultItem } from '@api-clients/crm-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { SearchService } from '../services/search.service';
import { timeConversion } from '../../../../../helpers/dateDiff';
import { AmplitudeTrackService } from '../../../../../core/services/amplitude/amplitude-track.service';
import { SEARCH_HISTORY_LEAD_OPEN } from '../../../../../core/services/amplitude/amplitudeEvents';
import { ScreenTypes } from '../../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  public readonly screenType: ScreenTypes = ScreenTypes.SEARCH_RESULTS;
  public searchResults: CardSearchResultItem[];
  public searchResultsSubscription: Subscription;
  public term: string;
  public loading: boolean;
  public empty: boolean;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private cdRef: ChangeDetectorRef,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.loading = true;
    this.empty = false;
  }

  ngOnInit(): void {
    this.searchResultsSubscription = this.searchService.searchResults$
      .asObservable()
      .subscribe((results: { data: CardSearchResultItem[]; term: string }) => {
        this.term = results?.term;
        this.empty = !results?.data?.length;
        this.searchResults = results?.data;
        this.loading = false;
        this.cdRef.markForCheck();
      });
  }

  daysBefore(date: string): string {
    const timeDelta = Math.abs(Date.now() - Date.parse(date));
    const lastCallTimeAgo = timeConversion(timeDelta);

    return `${lastCallTimeAgo} назад`;
  }

  clearInputValue(): void {
    this.searchService.clearSearchInputValue$.next(true);
  }

  unsubscribeFromObservable(): void {
    if (this.searchResultsSubscription) {
      this.searchResultsSubscription.unsubscribe();
    }
  }

  close(): void {
    this.location.back();
    this.clearInputValue();
    this.unsubscribeFromObservable();
  }

  ngOnDestroy(): void {
    this.clearInputValue();
    this.unsubscribeFromObservable();
  }

  onSelect(id: number) {
    this.amplitudeTrackService.trackEvent(SEARCH_HISTORY_LEAD_OPEN, {
      crmCardId: id,
      screenType: this.screenType,
    });
  }
}
