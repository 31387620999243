export enum WhatsappMessageItemTypes {
  chat = 'chat',
  ptt = 'ptt',
  image = 'image',
  video = 'video',
  audio = 'audio',
  location = 'location',
  sticker = 'sticker',
  vcard = 'vcard',
  multi_vcard = 'multi_vcard',
  document = 'document',
  revoked = 'revoked',
  notification = 'notification',
  notification_template = 'notification_template',
  e2e_notification = 'e2e_notification',
  gp2 = 'gp2',
  ciphertext = 'ciphertext',
  call_log = 'call_log',
  unsupported = 'unsupported',
  webp = 'webp',
}
