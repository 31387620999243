import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-ui-datetimepicker',
  templateUrl: './ui-datetimepicker.component.html',
  styleUrls: ['./ui-datetimepicker.component.scss'],
})
export class UiDatetimepickerComponent implements OnInit {
  @Input() dateControl: UntypedFormControl;
  @Input() timeControl: UntypedFormControl;
  @Input() labelTranslatePath: string;
  @Input() isDisabled: boolean;
  @Output() openPopup = new EventEmitter<void>();

  ngOnInit(): void {}

  openDatetimepickerPopup() {
    this.openPopup.emit();
  }
}
