import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

const LANG_KEYS = {
  complaintCompleted: 'PAGES.COMPLAINTS.COMPLETED',
};

@Component({
  selector: 'app-complaint-completion',
  templateUrl: './complaint-completion.component.html',
  styleUrls: ['./complaint-completion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplaintCompletionComponent implements OnInit {
  public placeholder: string;
  public complaintCompletedLabel: string;
  public form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.translate.get(LANG_KEYS.complaintCompleted).subscribe(translates => {
      this.complaintCompletedLabel = translates;
    });

    this.placeholder = 'Комментарий к завершению';
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      comment: ['', Validators.required],
      completed: [0, Validators.required],
    });
    this.cdRef.detectChanges();
  }

  onSubmit(): void {}
}
