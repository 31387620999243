import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SearchRequest } from '@api-clients/crm-api-client';

@Component({
  selector: 'app-search-request-date-days',
  templateUrl: './search-request-date-days.component.html',
  styleUrls: ['./search-request-date-days.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SearchRequestDateDaysComponent implements OnInit {
  @Input() request: SearchRequest;

  displayString: string;

  ngOnInit() {
    this.generateDisplayString();
  }

  generateDisplayString() {
    const dateFrom = this.formatDate(new Date(this.request.dateFrom));
    const dateTo = this.formatDate(new Date(this.request.dateTo));
    const nights =
      this.request.nightsFrom === this.request.nightsTo
        ? this.request.nightsFrom + 1
        : `${this.request.nightsFrom + 1} - ${this.request.nightsTo + 1}`;
    this.displayString = `${dateFrom} - ${dateTo} (${nights} дней)`;
  }

  formatDate(date: Date): string {
    const options = { day: 'numeric', month: 'short', year: 'numeric' } as const;
    return date.toLocaleDateString('ru-RU', options);
  }
}
