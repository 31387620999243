<div class="chat-list-item">
  <div class="oval">
    <span class="count" *ngIf="unreadMessageCount>0">{{unreadMessageCount}}</span>
    <span class="client-pic">
    {{ contactName | getInitials }}
  </span>
  </div>

  <div class="text">
    <div class="list-item-header">
      <span class="contact-name">{{contactName}}</span>
      <span class="time">{{time | date:'H:mm'}}</span>
    </div>
    <span class="last-message">{{contactLastMessage}}</span>
  </div>
</div>
