<div class="wrap" *ngIf="conversationStatus !== 1; else newMessageTemplate">
  <img *ngIf="isClicked" class="touch-loader" alt='loading' src="../../../../../../../../assets/icons/loading.gif">
  <button class="wait-answer" (click)="waitAnswer()" [disabled]="isClicked"
          [ngClass]="{'--touched': conversationStatus  === 2}">
    <img src="../../../../../../../../assets/icons/touch.svg" alt="make a touch" >
    <span>{{'PAGES.DEALS.WAIT_ANSWER' | translate}}</span>
  </button>
</div>


<ng-template #newMessageTemplate>
  <div class="new-message" *ngIf="conversationStatus === 1">
    <span>
      {{'PAGES.DEALS.NEW_MESSAGE' | translate}}
    </span>
  </div>
</ng-template>
