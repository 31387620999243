<div class="questionnaire">
  <app-client-needs
    *ngIf="card"
    [needs]="card.info"
    [crmId]="card.id"
    [lastInfoUpdate]="card.lastInfoUpdate"
  >
  </app-client-needs>
</div>
<div class="last-search">
  <app-vacation-reminder
    [cardId]="card.id"
    [savedVacationDateTime]="savedVacationDateTime"
  >
  </app-vacation-reminder>
  <div class="last-search-label">
    <p class="title">
      {{'PAGES.DEALS.LAST_SEARCH' | translate}}
    </p>

    <button class="make-search" (click)="searchForTours()">
      <span>{{'PAGES.DEALS.MAKE_SEARCH' | translate}}</span>
    </button>
  </div>
  <div *ngIf="searchRequestItem; else emptySearch" class="requests">
    <a
      libExternalLink
      [externalURL]="searchRequestItem.url"
      target="_blank"
      class="search-link"
    >
      <div class="header">
        <ng-container *ngIf="searchRequestItem.country?.name as countryName">{{ countryName }}</ng-container>
        <ng-container *ngIf="searchRequestItem.regions[0]?.name as regionName">, {{ regionName }}</ng-container>
      </div>
    </a>
    <div class="request">
      <app-search-request-stars
        *ngIf="searchRequestItem.stars[0] as stars; else noStars"
        [stars]="parseInt(stars)"
      ></app-search-request-stars>

      <ng-template #noStars>
        {{'PAGES.DEALS.CLIENT_CARD.SEARCH_REQUEST.NO_STARS' | translate}}
      </ng-template>

      <app-search-request-date-days
        [request]="searchRequestItem"
      >

      </app-search-request-date-days>
      <app-search-request-tourists
        [childAges]="searchRequestItem.childAges"
        [adult]="searchRequestItem.adult"
        [child]="searchRequestItem.child"
      >

      </app-search-request-tourists>
      <app-search-request-add-date
        class="add-date"
        [addDate]="searchRequestItem.addDate"
      >

      </app-search-request-add-date>

    </div>

  </div>

  <ng-template
  #emptySearch
  >
    <div class="empty-search">
      <img src="assets/icons/deal-view/search-empty.svg" alt="empty">
      <p class="text">{{'PAGES.DEALS.EMPTY_SEARCH' | translate}}</p>
    </div>

  </ng-template>

</div>
<app-client-reservations
  *ngIf="card"
  [crmCardId]="card.id"
  [tpStats]="card.tpStats"
  [tourPackage]="tourPackage"
></app-client-reservations>
