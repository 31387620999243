import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClientActionsOnSiteService } from './services/client-actions-on-site.service';
import { ClientActionsModalService } from './services/client-actions-modal.service';
import { ClientActionsOnSiteComponent } from './client-actions-on-site.component';
import { SharedUtilityModule } from '../../../../../../../../libs/shared/utility/src';

@NgModule({
  declarations: [ClientActionsOnSiteComponent],
  imports: [CommonModule, TranslateModule, SharedUtilityModule],
  providers: [ClientActionsOnSiteService, ClientActionsModalService],
})
export class ClientActionsOnSiteModule {}
