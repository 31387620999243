import { Component, Input } from '@angular/core';
import { ClientStatusIcon } from '../../../../../client-card-info/client-status-icon/client-status-icon';

@Component({
  selector: 'app-client-status-tag-helper-popup',
  templateUrl: './client-status-tag-helper-popup.component.html',
  styleUrls: ['./client-status-tag-helper-popup.component.scss'],
})
export class ClientStatusTagHelperPopupComponent {
  @Input() text: string;
  @Input() icon: string | ClientStatusIcon;
}
