import { NgForOf, NgIf, SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OutputRefSubscription,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PopupService } from '../../../../../../../../../shared/services/popup-service.service';
import { FavoriteHotelsSearchFormCheckboxListDropdownComponent } from './dropdown/favorite-hotels-search-form-checkbox-list-dropdown.component';

export interface CheckboxItem {
  id: string | number;
  name: string;
  selected: boolean;
}

@Component({
  selector: 'app-favorite-hotels-search-form-checkbox-list',
  templateUrl: './favorite-hotels-search-form-checkbox-list.component.html',
  styleUrls: ['./favorite-hotels-search-form-checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FavoriteHotelsSearchFormCheckboxListComponent),
    },
    PopupService,
  ],
  imports: [NgForOf, FormsModule, SlicePipe, NgIf],
})
export class FavoriteHotelsSearchFormCheckboxListComponent implements OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() items: CheckboxItem[] = [];
  @Input() visibleCount = 4;

  selectedCount = signal(0);
  allItemsRef = viewChild<ElementRef>('allItems');

  private componentRef: ComponentRef<FavoriteHotelsSearchFormCheckboxListDropdownComponent>;
  private componentSelectedSubscription: OutputRefSubscription;

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor(private readonly popupService: PopupService, private readonly cdRef: ChangeDetectorRef) {}

  onCheckboxChange() {
    const selectedItems = this.items.filter(item => item.selected).map(item => item.id);
    this.onChange(selectedItems);
    this.calcCount();
    this.onTouched();

    this.cdRef.detectChanges();
  }

  removeSelected(event: Event) {
    event.stopPropagation();

    this.items.forEach(item => {
      item.selected = false;
    });
    this.onCheckboxChange();
  }

  writeValue(value: any[]): void {
    if (value) {
      this.items.forEach(item => {
        item.selected = value.indexOf(item.id) !== -1;
      });

      this.calcCount();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }

  ngOnDestroy() {
    this.destroyComponent();
  }

  openDropdown() {
    this.componentRef = this.popupService.showPopup(
      FavoriteHotelsSearchFormCheckboxListDropdownComponent,
      {},
      {
        anchorElement: this.allItemsRef(),
      },
    );
    this.componentRef.instance.items = this.items;
    this.componentRef.instance.label = this.label;
    this.componentSelectedSubscription = this.componentRef.instance.selected.subscribe(() => {
      this.onCheckboxChange();
      this.calcCount();
    });
  }

  private calcCount(): void {
    let count = 0;
    this.items.forEach(item => {
      if (item.selected) {
        count++;
      }
    });

    this.selectedCount.set(count);
  }

  private destroyComponent(): void {
    this.componentRef?.destroy();
    this.componentSelectedSubscription?.unsubscribe();
  }
}
