import { CabinetTourPackageTour } from '@api-clients/api-client';
import {
  CrmCardViewItem,
  CrmTaskItem,
  CurrentCallItem,
  PhoneItem,
  SipStateListEvent,
} from '@api-clients/crm-api-client';
import {
  BaseNotification,
  CountryPhoneFormat,
  DealNotification,
  NotificationType,
  TourPackageClaimNotification,
} from '@api-clients/crm-api-client/dist/models';
import {
  AmplitudeEventData,
  AmplitudeEventDataWithScreenType,
} from '../core/services/amplitude/amplitudeEventData';
import { AmplitudeEventsType } from '../core/services/amplitude/amplitudeEvents';
import { Worker } from './worker';

export const SetManagerStateManualBusy = 'set-manager-state-manual-busy';
export const OpenWhatsappChat = 'open-whatsapp-chat';
export const OpenDealView = 'open-deal-view';
export const AmplitudeEventKey = 'amplitude';

export interface AmplitudeEvent {
  event: typeof AmplitudeEventKey;
  amplitudeData: {
    event: AmplitudeEventsType;
    props?: AmplitudeEventData | AmplitudeEventDataWithScreenType;
  };
}

export interface UpdateProgressEvent {
  speedBytesPerSecond: number;
  percent: number;
  transferred: number;
  total: number;
}

export interface MessageNotify {
  crmCardId: number;
  contactId: string;
  clientName: string;
  shortMessage: string;
  messageId?: string;
  phone?: PhoneItem;
}

export interface TaskItem {
  task: CrmTaskItem;
  clientName: string;
  crmCardId: number;
  status: number;
}

export interface SetCountryCodesEvent {
  event: 'country-codes';
  phoneConfig: CountryPhoneFormat[];
}

export interface SetCurrentWorkerEvent {
  event: 'current-worker';
  worker: Worker;
}
export interface SaleNotify {
  tourPackageId: number;
  siteLink: string;
  countdown: number;
  manager: {
    id: number;
    employeeId: number;
    username: string;
    name: string;
    role: string;
  };
  tour: CabinetTourPackageTour;
}

export interface Notify {
  notificationId: number;
  countdown?: number;
}

export interface ShowNotifyEvent {
  event: 'show-notify-event';
  notification: CurrentNotification;
}

export interface SetSipStatesEvent {
  event: 'set-sip-states';
  sipList: SipStateListEvent[];
}
export interface SetWorkerListEvent {
  event: 'worker-list';
  list: Worker[];
}
export interface SetCurrentWorkerCityIdEvent {
  event: 'current-worker-city-id';
  cityId: number;
}

export interface CreateNotificationSaleEvent {
  event: typeof EventsType.CreateSale;
  notification: SaleNotify;
}

export interface CreateNotifyByIdEvent {
  event: NotificationType;
  notification: BaseNotification;
  countdown: number;
}

export interface CreateNotificationEvent {
  event: 'create-notification';
  notification: CallNotification;
}

export interface UpdateTasksListEvent {
  event: 'update-tasks-list';
  tasks: TaskItem[];
}

export interface WorkersListWithSip {
  event: 'workers-list-with-sip';
  workersList: Worker[];
}

export interface CreateNotificationFromWhatsappEvent {
  event: 'create-notification-from-whatsapp';
  notification: MessageNotify;
}
export interface UpdateNotificationEvent {
  event: 'update-notification';
  userData: CrmCardViewItem;
}
export interface CloseNotificationEvent {
  event: 'close-notification';
  userCurrentPhone: string;
}

export interface ManagerStateManualBusyEvent {
  event: 'set-manager-state-manual-busy';
}
export interface OpenWhatsappModalEvent {
  event: 'open-whatsapp-chat';
  crmCardId: number | null;
  phone: PhoneItem | null;
  contactId: string | null;
}
export interface CallNotification {
  from?: string;
  currentCall?: CurrentCallItem;
  dealView?: CrmCardViewItem;
  timer?: number;
  uid?: string;
  secretMask?: string;
  lastCall?: string;
}

export interface OpenDealViewEvent {
  event: 'open-deal-view';
  crmCardId: number;
}

export interface ClientPaidOnlineNotify {
  tourPackageId: number;
  value: number;
  siteLink: string;
  countdown: number;
}
export interface ComplimentNotifyEvent {
  event: 'create-compliment';
  notification: ComplimentNotify;
}
export interface ComplimentNotify {
  text: string;
}

export interface CreateNotificationClientPaidOnline {
  event: typeof EventsType.CreateClientPaidOnline;
  notification: ClientPaidOnlineNotify;
}

export enum EventsType {
  CreateSale = 'create-sale',
  CreateClientPaidOnline = 'create-client-paid-online',
}
export type NotificationItemType = DealNotification | TourPackageClaimNotification;

export type CurrentNotification =
  | CreateNotificationClientPaidOnline
  | CreateNotificationSaleEvent
  | CreateNotifyByIdEvent;

export type IpcEventType =
  | SetCountryCodesEvent
  | CreateNotificationEvent
  | UpdateTasksListEvent
  | CreateNotificationFromWhatsappEvent
  | UpdateNotificationEvent
  | CloseNotificationEvent
  | ManagerStateManualBusyEvent
  | CreateNotificationClientPaidOnline
  | CreateNotificationSaleEvent
  | OpenDealViewEvent
  | ShowNotifyEvent
  | OpenWhatsappModalEvent
  | SetSipStatesEvent
  | SetCurrentWorkerCityIdEvent
  | CreateNotifyByIdEvent
  | SetWorkerListEvent
  | ComplimentNotifyEvent
  | SetCurrentWorkerEvent
  | AmplitudeEvent;
