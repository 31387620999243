import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationsComponent } from './components/recommendations/recommendations.component';
import { SharedModule } from '../../shared/shared.module';
import { ClientCardNameModule } from '../deals/modules/deal-view/modules/client-card-name/client-card-name.module';

@NgModule({
  declarations: [RecommendationsComponent],
  imports: [CommonModule, SharedModule, ClientCardNameModule],
})
export class RecommendationsModule {}
