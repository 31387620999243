<form [formGroup]="sendMessageForm">
  <app-attach
    class="attach"
    *ngIf="isAttachPopupShow"
    [cardId]="cardId"
    [searchLink]="searchLink"
    (attachEvent)="onFileSelected($event)"
    (clickEvent)="closeAttachPopup()"
    (selectTemplateEvent)="addTemplateTextToForm($event)"
  >
  </app-attach>
  <div class="send-message-field" #sendMessageField>
    <div class="icon-field">
      <button (click)="toggleEmojiPicker()">
        <img src="./assets/icons/whatsapp/emoji.svg" alt="Эмодзи" />
      </button>
      <emoji-mart
        class="emoji-mart"
        *ngIf="isEmojiPickerVisible"
        (emojiSelect)="addEmoji($event)"
        [i18n]="i18nEmoji"
        [style]="{ position: 'absolute', bottom: '60px' }"
        [enableSearch]="false"
        [emojiTooltip]="false"
        [showPreview]="false"
        title="Выберите эмодзи"
      >
      </emoji-mart>
      <button id="attach-button" (click)="toggleAttachPopup()">
        <img src="./assets/icons/whatsapp/attach.svg" alt="Прикрепить что-то" />
      </button>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="whatsappTemplateSelected($event)">
      <ng-container *ngIf="whatsappTemplates as templates">
        <mat-option *ngFor="let option of templates | filterByKey : lastTag" [value]="option">
          <div class="template">
            <p class="key">{{ option.key }}</p>
            <p class="value">{{ option.value }}</p>
          </div>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <div class="textarea-container">
      <textarea
        type="text"
        rows="1"
        class="send-message-input"
        spellcheck="true"
        #textArea
        appResizableTextarea
        [maxHeightInPx]="500"
        formControlName="text"
        [placeholder]="getTextControlPlaceholderString()"
        matInput
        [(ngModel)]="inputValue"
        (ngModelChange)="onInputChange($event)"
        (input)="onInput($event)"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
        (paste)="onPaste($event)"
        [disabled]="isTypingNotAvailable"
        [matAutocompleteDisabled]="!isTemplatePopupShow"
        (keydown.control.enter)="onCtrlEnter()"
        (keydown.enter)="onEnter($event)"
        [matAutocomplete]="auto"
        [matAutocompletePosition]="'above'"
        (resizeEvent)="onResize()"
        [triggerResize]="triggerTextareaResize"
        [appAutofocus]="shouldFocus$"
      ></textarea>
      <ng-container *ngIf="!inputValue; else openScheduleOverlayButton">
        <ng-container *ngIf="hasScheduledMessages$ | async">
          <button
            (click)="openScheduledMessagesList()"
            type="button"
            class="scheduled-list-button"
            title="Открыть список отложенных сообщений"
          >
            <img src="assets/icons/scheduled-list.svg" alt="" srcset="" />
          </button>
        </ng-container>
      </ng-container>

      <ng-template #openScheduleOverlayButton>
        @if (this.attachedFiles.length === 0) {
        <button
          (click)="openScheduleMessageOverlay()"
          type="button"
          class="schedule-button"
          title="Запланировать отправку сообщения"
        >
          <img src="assets/icons/schedule.svg" alt="" srcset="" />
        </button>
        }
      </ng-template>

      <!-- Оверлей для планирования отправки -->
      @if(isScheduleMessageOverlayOpen$ | async) {
      <div class="schedule-overlay">
        <div class="overlay-content">
          <div class="overlay-header">
            <div class="overlay-title">Время отправки</div>
            <div class="overlay-close" (click)="closeScheduleMessageOverlay()">
              <img src="assets/icons/chats/close.svg" alt="Закрыть" [width]="10" [height]="10" />
            </div>
          </div>
          <div class="overlay-body">
            <div class="shortcut-buttons">
              <button (click)="setToday()" type="button" class="btn --gray">Сегодня</button>
              <button (click)="setTomorrow()" type="button" class="btn --gray">Завтра</button>
            </div>

            <div class="selectors">
              <div class="selectors-item">
                <input
                  type="date"
                  placeholder=""
                  id="date-select"
                  class="input-button date-button"
                  [ngClass]="{ 'has-value': selectedDate }"
                  [(ngModel)]="selectedDate"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
              <div class="selectors-item">
                <input
                  type="time"
                  placeholder=""
                  id="time-select"
                  class="input-button time-button"
                  [ngClass]="{ 'has-value': selectedTime }"
                  [(ngModel)]="selectedTime"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
          </div>
          <div class="overlay-footer">
            <button
              (click)="scheduleMessage()"
              type="button"
              class="btn primary schedule"
              [disabled]="!selectedDate || !selectedTime || isSchedulingMessage"
              [class.disabled]="!selectedDate || !selectedTime || isSchedulingMessage"
            >
              @if(!isSchedulingMessage) { Запланировать отправку } @else {
              <app-frontend-basic-loader [height]="20" [width]="20" [stretch]="true">
              </app-frontend-basic-loader>
              }
            </button>
          </div>
        </div>
      </div>
      }

      <button
        *ngIf="attachedFiles.length === 0 && text?.length > 0; else recordButton"
        class="submit-button"
        (click)="onSubmit()"
      >
        <mat-icon [class.disabled]="isSendButtonDisabled" svgIcon="whatsapp_send"> </mat-icon>
      </button>

      <ng-template #recordButton>
        <app-audio-recorder
          (recorderOutput)="onRecord($event)"
          (startRecordingOutput)="disableTextInput()"
          (removeRecordingOutput)="onRemoveRecording()"
          (transcriptionResult)="setText($event)"
          class="submit-button"
        >
        </app-audio-recorder>
      </ng-template>
    </div>
    <ng-container *ngIf="attachedFiles.length > 0; else quickAnswerBtn">
      <button
        class="btn-send"
        [ngClass]="{ disabled: isSendPhotosButtonDisabled }"
        (click)="onSubmit()"
        [disabled]="isSendPhotosButtonDisabled"
      >
        <img src="./assets/icons/whatsapp/send-btn.svg" alt="Отправить сообщение" class="btn-send-icon" />
        <div class="attached-files-counter">{{ attachedFiles.length }}</div>
      </button>
    </ng-container>

    <ng-template #quickAnswerBtn>
      <app-quick-answer-btn
        (holdEvent)="navigateToQuickAnswers(cardId)"
        (clickEvent)="openTemplatePopup()"
        [matTooltip]="templateTooltip"
        matTooltipClass="multiline-tooltip"
        [isDisabled]="whatsappTemplates.length === 0"
      >
      </app-quick-answer-btn>
    </ng-template>
  </div>
</form>
