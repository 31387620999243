import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageSyncService {
  // Subject для уведомления таймлайна с локальным состоянием об удалении сообщений
  private messageDeletedSubject = new Subject<string>();
  public messageDeleted$ = this.messageDeletedSubject.asObservable();

  notifyMessageDeleted(messageId: string): void {
    this.messageDeletedSubject.next(messageId);
  }
}
