<div class="message-info"

>
  <div class="message-header">
    <div class="name">
      {{name}}
    </div>

    <div class="date">
      {{lastMessage.receivedAt | date: 'HH:mm'}}
    </div>
  </div>
  <div class="message">
    <ng-container *ngIf="lastMessage.isFromMe">
      {{'COMMON.ME' | translate }}:
    </ng-container>

    {{shortMessage}}
    <div class="counter-arrow">
      <div class="counter info"
           *ngIf="hasUnreadMessages"
      >
        1
      </div>
      <a
        (click)="dealViewOpened()"
        [routerLink]="['/deals/view', crmId]"
        [queryParams]="{phone: phone.phone, code: phone.code}"
        class="arrow-right">
        <img src="assets/icons/deal-view/arrow-right.svg" alt="right">
      </a>
    </div>

  </div>

</div>
