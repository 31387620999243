import { Component, Input, OnInit } from '@angular/core';
import { TourPackagePaymentNotification } from '@api-clients/crm-api-client/dist/models/tour-package-payment-notification';

@Component({
  selector: 'app-tour-package-new-payment',
  templateUrl: './tour-package-new-payment.component.html',
  styleUrls: ['./tour-package-new-payment.component.scss'],
})
export class TourPackageNewPaymentComponent implements OnInit {
  @Input() notification: TourPackagePaymentNotification;

  ngOnInit(): void {}
}
