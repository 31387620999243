<div class="whatsapp-screen">
  <ng-container *ngIf="loadedChats">
    <app-chat-list
      [loadedChats] = loadedChats
      class="chat-list">
    </app-chat-list>
  </ng-container>

  <div class="whatsapp-content">
    <app-whatsapp-chat *ngIf="selectedChat.contactId && loadedChats; else chatNotSelected"
      [loadedChats] = loadedChats
      [selectedChat] = selectedChat
    >
    </app-whatsapp-chat>
    <ng-template #chatNotSelected>
      <div class="chat-not-selected">
        <p>Выберите чат для начала общения</p>
      </div>

    </ng-template>
  </div>
</div>
