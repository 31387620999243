import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chat-list-item',
  templateUrl: './chat-list-item.component.html',
  styleUrls: ['./chat-list-item.component.scss'],
})
export class ChatListItemComponent {
  @Input() contactName: string;
  @Input() contactLastMessage: string;
  @Input() time: string;
  @Input() unreadMessageCount: number;
}
