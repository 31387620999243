import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-hotel-booking-rating',
  template: `<span class="value">{{ rating | number : '1.1-1' : 'en' }}</span>`,
  styles: `
      .value {
          display: flex;
          border-radius: 6px 6px 6px 2px;
          background-color: #003680;
          color: #fff;
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;
          justify-content: center;
          width: 25px;
          height: 20px;
      }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe],
})
export class SearchBookingRatingComponent {
  @Input() rating: number;
}
