import { Component, Input } from '@angular/core';
import { Tour } from '@api-clients/crm-api-client';

@Component({
  selector: 'app-tour-package-tour-info',
  templateUrl: './tour-package-tour-info.component.html',
  styleUrls: ['./tour-package-tour-info.component.scss'],
})
export class TourPackageTourInfoComponent {
  @Input() tour: Tour;
  public dayMeasure = 'дн.';
}
