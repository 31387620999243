import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CardUpdateNextRestDateRequest,
  CrmCardViewItem,
  FindDealRequest,
} from '@api-clients/crm-api-client/dist/models';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDealBooleanParamRequest } from '@api-clients/crm-api-client/models/update-deal-boolean-param-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { CallsFacade } from '../../../../../+state/calls/calls.facade';
import { CurrentCallPayload } from '../../../../../+state/calls/call.interface';
import { ElectronService } from '../../../../../core/services';
// нельзя оптимизировать этот импорт, так как это приведет к циклической зависимости
// noinspection ES6PreferShortImport
import { ConfigsService } from '../../../../../core/services/configs/configs.service';
import { PopupFacade } from '../../../../../+state/popup/popup.facade';
import { PopupStatusState } from '../../../../../+state/popup/popup.interface';
import { WorkerStateService } from '../../../../../core/services/worker/worker-state.service';
import { apiResponsePipeTyped } from '../../../../../api-response-typed.pipe';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';

// TODO: вынести хранение ролей в enum
const CALL_OPERATOR_KEY = 'call_operator';
export const ON_START_CONVERSATION = 'on_start_conversation';

@Injectable({
  providedIn: 'root',
})
export class DealViewService {
  private currentCallUuid: string;
  private waitedDealDataCallUuid: string;
  public dealViewRefreshed$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public lastCrmId: number | null = null;

  constructor(
    private configService: ConfigsService,
    private http: HttpClient,
    private router: Router,
    private electronService: ElectronService,
    private callsFacade: CallsFacade,
    private popupFacade: PopupFacade,
    private matDialog: MatDialog,
    private workerStateService: WorkerStateService,
    private ngZone: NgZone,
  ) {
    this.currentCallUuid = '';
  }

  getDealView(crmCardId: number, expandList?: string[]): Observable<CrmCardViewItem> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('crmCardId', `${crmCardId}`);
    if (expandList) {
      queryParams = queryParams.append('expandList', `${expandList}`);
    }

    return this.http
      .get<ApiResponse<CrmCardViewItem>>(`${AppConfig.apiUrl}/crm-card/view`, {
        params: queryParams,
      })
      .pipe(
        apiResponsePipeTyped<CrmCardViewItem>(),
        // Запоминаем lastCrmId
        tap(response => {
          if (response && response.card) {
            this.lastCrmId = response.card.id;
          } else {
            this.lastCrmId = null;
          }
        }),
      );
  }

  getDealViewForChat(crmCardId: number, expandList?: string[]): Observable<CrmCardViewItem> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('crmCardId', `${crmCardId}`);
    if (expandList) {
      queryParams = queryParams.append('expandList', `${expandList.toString()}`);
    }

    return this.http
      .get<ApiResponse<CrmCardViewItem>>(`${AppConfig.apiUrl}/crm-card/view-for-chat`, {
        params: queryParams,
      })
      .pipe(apiResponsePipeTyped<CrmCardViewItem>());
  }

  findCardByPhone(phone: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('phone', `${phone}`);

    return this.http
      .get(`${AppConfig.apiUrl}/crm-card/find`, {
        params: queryParams,
      })
      .pipe(apiResponsePipe);
  }

  findDeal(phone: string, cityId: number, brandId: number) {
    const body: FindDealRequest = {
      phone,
      cityId,
      brandId,
    };
    return this.http.post(`${AppConfig.apiUrl}/deal/find`, body).pipe(apiResponsePipe);
  }

  updateAutoActionsDisabled(dealId: number, value: boolean) {
    const body: UpdateDealBooleanParamRequest = {
      dealId,
      value,
    };
    return this.http
      .post(`${AppConfig.apiUrl}/deal/update-auto-actions-disabled`, body)
      .pipe(apiResponsePipe);
  }

  getCountSold(dealId: number) {
    return this.http
      .get(`${AppConfig.apiUrl}/deal/tour-package/count-sold?dealId=${dealId}`)
      .pipe(apiResponsePipe);
  }

  reshowDealView(): void {
    const dealViewRoute = '/deals/view';
    if (this.router.routerState.snapshot.url !== dealViewRoute) {
      this.router.navigate(['deals/view']).then();
    }
  }

  showDealView(deal: CrmCardViewItem, options?: { from?: string }): void {
    this.currentCallUuid = deal?.currentCall?.id;
    this.waitedDealDataCallUuid = '';
    this.updateCurrentCallState(deal);

    const queryParams = {};
    if (options?.from) {
      queryParams['from'] = options.from;
    }

    this.ngZone.run(() => {
      void this.router.navigate(['deals/view', deal?.card?.id], { queryParams }).then();
    });
  }

  openSearchTour(crmCardId: number): boolean | void {
    const { adminUrl } = this.configService;

    const manager = this.workerStateService.currentWorkerValue;
    const managerIsCallOperator = manager.role === CALL_OPERATOR_KEY;

    if (adminUrl && crmCardId && !managerIsCallOperator) {
      const url = `${adminUrl}/search/manager?crm_id=${crmCardId}&isAuto=1`;
      this.electronService.shell
        .openExternal(url, {
          activate: true,
        })
        .then();
      return true;
    }
  }
  updateDealView(deal: CrmCardViewItem): void {
    if (this.isCurrentCall(deal?.currentCall?.currentPhone?.phone)) {
      this.updateCurrentCallState(deal);
    }
    if (deal.currentCall.id === this.waitedDealDataCallUuid && deal?.card?.id) {
      this.showDealView(deal);
    }
  }

  updateCurrentCallState(deal: CrmCardViewItem) {
    const payload: CurrentCallPayload = {
      deal,
      callId: deal?.currentCall?.cdrId,
      callUuid: deal?.currentCall?.id,
    };
    this.callsFacade.updateCurrentCall(payload);
  }

  resetCurrentCallId(crmCardViewItem: CrmCardViewItem): void {
    if (this.isCurrentCall(crmCardViewItem?.currentCall?.id)) {
      this.callsFacade.reset();
    }
  }

  private isCurrentCall(newCallUuid: string): boolean {
    return newCallUuid === this.currentCallUuid;
  }

  showPopupAndBlockWindow() {
    this.matDialog.closeAll();
    const popupPayload: PopupStatusState = {
      popupShow: true,
      isBlocked: true,
    };
    this.popupFacade.setPopupStatus(popupPayload);
  }
  closePopupAndUnblockWindow() {
    const popupPayload: PopupStatusState = {
      popupShow: false,
      isBlocked: false,
    };
    this.popupFacade.setPopupStatus(popupPayload);
  }

  showSkeletonAndSetWaitedCallId(id: string) {
    this.waitedDealDataCallUuid = id;
    this.router.navigate(['deals/preload']).then();
  }

  refreshDealView() {
    this.dealViewRefreshed$.next(true);
  }

  resetDealViewRefreshed() {
    this.dealViewRefreshed$.next(null);
  }

  updateNextRestDate(cardId: number, nextRestDate: string) {
    const body: CardUpdateNextRestDateRequest = {
      cardId,
      nextRestDate,
    };
    return this.http.post(`${AppConfig.apiUrl}/crm-card/update-next-rest-date`, body).pipe(apiResponsePipe);
  }
}
