import { CrmTaskType } from '@api-clients/crm-api-client';
import { NotificationType } from '@api-clients/crm-api-client/dist/models';
import { ContentCreatorContentType } from '../../../modules/content-creator/interfaces/content-creator.interface';
import {
  CurrentStageEnum,
  Stage,
} from '../../../modules/deals/modules/deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import {
  ExperimentalDaysStages,
  FilterIds,
  SalesFunnelCategoryEnum,
  SortType,
} from '../../../modules/deals/modules/deals-list/deal-list';

export interface DealId {
  dealId: number;
}

export interface CrmCardId {
  crmCardId: number;
}

export interface RefactoredCrmCardId {
  'Crm card ID': number;
}

export interface Filter {
  filter: string;
}
export interface Value {
  value: string;
}

export interface ScreenType {
  screenType: ScreenTypes | string;
}

export interface RefactoredScreenType {
  'Screen type': ScreenTypes | string;
}

export interface DealsListFilterName {
  'Filter name': FilterIds;
}

export interface DealsListSortType {
  'Sort type': SortType;
}

export interface DealsMenuStageName {
  'Stage name': CurrentStageEnum | ExperimentalDaysStages;
}

export interface DealsMenuStatusName {
  'Status name': SalesFunnelCategoryEnum;
}

export interface IsManagerCard {
  isManagerCard: boolean;
}

export interface Term {
  term: string;
}

export interface SalesFunnelTaskCreated {
  stage: Stage;
}

export interface SalesFunnelDealFinished {
  reason: string;
}

export enum ScreenTypes {
  DEAL_LISTING = 'deal_listing',
  DEAL_VIEW = 'deal_view',
  ADD_DEAL = 'add deal',
  BOOKING_HISTORY = 'booking_history',
  CALL_LIST = 'call list',
  TOP_MENU = 'top menu',
  SEARCH_RESULTS = 'search results',
  NOTIFICATIONS_CENTER = 'notifications center',
  QUICK_ANSWERS = 'quick answers',
  ASIDE_CHATS = 'aside chats',
  WORKERS_LIST = 'workers list',
  NOTIFICATION_TASK_REMINDER = 'hermes_notifications_task_reminder',
  NOTIFICATION_NEW_DEAL_ASSIGNED = 'hermes_notifications_new_deal_assigned',
  NOTIFICATION_NEW_WHATSAPP_MESSAGE = 'hermes_notifications_new_whatsapp_message',
  CHAT = 'hermes_chat',
  CONTENT_CREATOR = 'hermes_content_creator',
  MESSAGE_RECOMMENDATION = 'hermes_message_recommendation',
}

export interface NotificationTypeValue {
  notificationType: NotificationType;
}
export interface MessageTemplate {
  templateType?: string;
  messageType?: string;
  templateText?: string;
}

export interface SendBookingInstruction {
  sendBookingInstruction: boolean;
}

export interface TaskType {
  taskType: CrmTaskType;
}

export interface IsAfterCall {
  isAfterCall: boolean;
}

export interface DealReadinessChanged {
  readiness: number;
}
export interface DealUpdated {
  field: string;
}

export interface FilesPaste {
  filesCount: number;
}

export interface VacationSchedule {
  vacationScheduleDateTime: string;
}

export enum TranscribedMessageTypeEnum {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export interface TranscribedMessageType {
  transcribedMessageType: TranscribedMessageTypeEnum;
}

export enum TranscribedCallTypeEnum {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export interface TranscribedCallType {
  transcribedCallType: TranscribedCallTypeEnum;
}

export interface ContentCreatorOpen {
  'Initial content type': string | null;
  'Is tour found': boolean;
  'Tour ID': string;
}

export interface QuickMessageSelect {
  'Deal ID': number;
  'Message text': string;
  'Message name'?: string;
}

export interface DelayedMessagePlanned {
  'Message text': string;
}

export interface GptCompleteRequest {
  Prompt: string;
  Place: string;
}

export interface ChatRecommendation {
  'Recommendation ID': number;
}

export interface ChatRecommendationMessage {
  'Recommendation ID': number;
  'Message ID': number;
  'Message text': string;
  'Changed message text': string | null;
}

export type AmplitudeEventData =
  | DealId
  | CrmCardId
  | RefactoredCrmCardId
  | Filter
  | ScreenType
  | RefactoredScreenType
  | Value
  | Term
  | NotificationTypeValue
  | MessageTemplate
  | DealReadinessChanged
  | SalesFunnelTaskCreated
  | SalesFunnelDealFinished
  | SendBookingInstruction
  | DealUpdated
  | TaskType
  | IsAfterCall
  | FilesPaste
  | IsManagerCard
  | VacationSchedule
  | TranscribedMessageType
  | TranscribedCallType
  | ContentCreatorOpen
  | DealsListFilterName
  | DealsListSortType
  | DealsMenuStageName
  | DealsMenuStatusName
  | QuickMessageSelect
  | GptCompleteRequest
  | DelayedMessagePlanned
  | ChatRecommendation
  | ChatRecommendationMessage
  | null;

export type AmplitudeEventDataWithScreenType = AmplitudeEventData & ScreenType;
