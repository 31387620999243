<button *ngIf="isCanRecord" (click)="startRecording()">
  <img
    src="./assets/icons/whatsapp/record-audio.svg"
    alt="record"  class = "icon">
</button>

<div *ngIf="isShowOverlay"
     class="overlay-on-recording">

    <div class="svg-animation"
         *ngIf="recorderState === RecorderState.RECORDING"></div>
    <app-audio
      *ngIf="isShowAudioElement"
      [duration]="currentBlobDuration"
      [mediaSource]="currentBlobUrl"
      [screenType]="screenType"
      [type]="recordedAudioType"
      [isTranscriptResultAsTooltip]="true"
      (transcriptionResult)="transcriptionResult.emit($event)"
    />
  <button *ngIf="isCanRemoveRecord" (click)="removeRecord()">
    <img
      src="./assets/icons/whatsapp/remove-record.svg"
      alt="send" class = "icon">
  </button>
  <button (click)="sendAudio()"   *ngIf="isCanSendAudio">
    <img *ngIf="recorderState !== RecorderState.BUFFERING"
         src="./assets/icons/whatsapp/send-message.svg"
         alt="send" class = "icon">
  </button>
  <button
    *ngIf="recorderState === RecorderState.RECORDING"
    (click)="pause()">
    <img
      src="./assets/icons/whatsapp/audio/stop.svg"
      alt="stop"  class = "icon">
  </button>

</div>
