import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CurrentCallPayload, SearchOpenedPayload } from './call.interface';
import * as CallSelectors from './calls.selectors';
import { ResetCall, SetSearchOpened, UpdateCurrentCall } from './calls.actions';

@Injectable()
export class CallsFacade {
  currentCall$ = this.store.pipe(select(CallSelectors.getCurrentCall));

  constructor(private store: Store<CurrentCallPayload>) {}

  updateCurrentCall(payload: CurrentCallPayload) {
    this.store.dispatch(new UpdateCurrentCall(payload));
  }

  reset() {
    this.store.dispatch(new ResetCall());
  }

  setSearchOpened(payload: SearchOpenedPayload) {
    this.store.dispatch(new SetSearchOpened(payload));
  }
}
