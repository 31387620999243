<div class="header-container">
  <div class="header-container-photo">
    <app-search-hotel-photo [hotelPhoto]="hotel.photo" />
  </div>
  <div class="header-container-main">
    <div class="header">
      <div class="header-left-aside">
        <app-hotel-stars [value]="hotel.stars" />
        @if (hotel.salesCount) {
          <app-hotel-sales-count [salesCount]="hotel.salesCount" />
        }
        @if (hotel.bookingRating) {
          <app-search-hotel-booking-rating [rating]="hotel.bookingRating" />
        }
        @if (mapPointerVisible) {
          <div class="hotel-map-pointer"
               matTooltip="Показать на карте"
               [class.on-map]="hotelOnMap"
               (click)="showHotelOnMap.emit(hotel.id)">
            <img src="assets/icons/search/hotel-map-pointer.svg">
          </div>
        }

        @if (tags.length) {
          <div class="tags-container">
            @for (tag of tags; track tag.tag.id) {
              <img [attr.src]="'assets/icons/favorite-hotels/tag-' + (tag.priority + 1)  +'.svg'"
                   [matTooltip]="tag.tag.name + ' (' + tag.tag.usePercent + '%)'"
                   class="tag">
            }
          </div>
        }
      </div>
      <div class="header-right-aside">
        <app-search-hotel-recalc-btn *ngIf="allowReCalculate && searchTours.length"
                                     [adults]="adults()"
                                     [childAges]="childAges()"
                                     (recalcTourists)="recalcTours($event)" />
      </div>
    </div>
    <div class="sub-header">
      <span class="hotel-name">{{ hotel.name }}</span>
      <span class="hotel-region">{{ hotel.region }}</span>
    </div>
  </div>
</div>

@if (searchTours.length) {
  <div class="tours-list">
    @for (tour of searchTours; track tour.id; let i = $index) {
      <app-search-tour [class.visible]="i < toursVisibleCount || isAllToursVisible"
                       [tour]="tour"
                       [emitRecalcTour$]="emitRecalcTour$"
                       [initSearchRequest]="initSearchRequest"
                       [contentCreatorType]="contentCreatorType"
                       [tourSelectMode]="tourSelectMode"
      />
    }
  </div>
}

@if (initSearchRequest && searchTours.length === 0 && allowResearch && !showSearchToursLoader) {
  <div class="allow-research-container">
    <span class="tours-not-found-label">Туры не найдены</span>
    <button type="button" class="research" (click)="researchTours()">Переискать</button>
  </div>
}

<ng-container *ngIf="searchTours.length > toursVisibleCount && !isAllToursVisible">
  <div (click)="showAllTours()" class="more-tours">
    +{{ searchTours.length - 1 | pluralize:'тур,тура,туров' }}
  </div>
</ng-container>

@if (showRecalcToursLoader) {
  <div class="search-tours-loader">
    <div class="search-tours-loader-label">
      Пересчитываем туры...
      <app-line-progress [animationDuration]="'30s'" />
    </div>
    <img class="search-tours-loader-icon" src="assets/icons/search/hotel-search-tours-loader.svg">
  </div>
}

@if (showSearchToursLoader) {
  <div class="search-tours-loader">
    <div class="search-tours-loader-label">Загружаем цены...</div>
    <img class="search-tours-loader-icon" src="assets/icons/search/hotel-search-tours-loader.svg">
  </div>
}

@if (showToursNotFound && !allowResearch) {
  <div class="tours-not-found">
    <span class="tours-not-found-label">Туры не найдены</span>
  </div>
}

