import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ExternalLinkDirective } from './directives/external-link/external-link.directive';
import { ChunksPipe } from './pipes/chunks/chunks.pipe';
import { RemoveHtmlEntitiesPipe } from './pipes/removeHTMLEntities/remove-html-entities.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ElectronService } from './services/electron.service';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: true,
  };
};

@NgModule({
  imports: [CommonModule, NgxMaskModule.forRoot(maskConfigFunction)],
  declarations: [ExternalLinkDirective, TruncatePipe, ChunksPipe, RemoveHtmlEntitiesPipe],
  exports: [NgxMaskModule, ExternalLinkDirective, TruncatePipe, ChunksPipe, RemoveHtmlEntitiesPipe],
  providers: [ElectronService],
})
export class SharedUtilityModule {
}
