import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ManagerOffersListService } from './services/manager-offers-list.service';
import { ApiTourWithManager } from './types/api-tour-with-manager.type';

@Component({
  selector: 'app-manager-offers',
  templateUrl: './manager-offers.component.html',
  styleUrls: ['./manager-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ManagerOffersComponent implements OnInit {
  public readonly dateFormat = 'dd.MM.YY';
  public managerOfferTours$: Observable<ApiTourWithManager[]>;
  public dealId: number;
  public crmId: number;

  public isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { dealId: number; crmId: number },
    private dialogRef: MatDialogRef<ManagerOffersComponent>,
    private managerOffersListService: ManagerOffersListService,
  ) {}

  ngOnInit(): void {
    this.dealId = this.data.dealId;
    this.crmId = this.data.crmId;
    this.managerOfferTours$ = this.managerOffersListService
      .getListItemDetails(this.dealId)
      .pipe(tap(() => this.isLoading$.next(false)));
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
