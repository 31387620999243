import { HttpClient, HttpParams } from '@angular/common/http';

import {
  CardUpdateWhatsappPhoneRequest,
  ClearContactUnreadMessagesRequest,
  WhatsappMessage,
} from '@api-clients/crm-api-client';
import { Injectable } from '@angular/core';
import { WhatsappMessageExpand } from '@api-clients/crm-api-client/models/whatsapp-message-expand';
import { apiResponsePipe } from '../../../api-response.pipe';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { DealsFacade } from '../../../+state/deals/deals.facade';

const WHATSAPP_PAGE_MESSAGE_COUNT = 50;

/**
 * TODO: Перенести методы в ChatApiService и удалить этот сервис
 */
@Injectable({
  providedIn: 'root',
})
export class WhatsappApiService {
  constructor(private http: HttpClient, private dealsFacade: DealsFacade) {}

  /**
   * @deprecated
   * @use ChatApiService.sendMessage
   */
  messageSend(body, data?: Partial<{ cardId: number; dealId: number }>) {
    if (data && data.cardId && data.dealId) {
      console.log('Touching card. from messageSend');
      this.dealsFacade.selectedZenmodeCardTouched({
        cardId: data.cardId,
        dealId: data.dealId,
      });
    }
    return this.http
      .post<ApiResponse<any>>(`${AppConfig.apiUrl}/whatsapp/send-message`, body)
      .pipe(apiResponsePipe);
  }

  getWhatsappMessagesByContactId(
    contactId: string,
    page = 1,
    limit: number = WHATSAPP_PAGE_MESSAGE_COUNT,
    expandList: WhatsappMessageExpand[] = [
      'quoted-message' as WhatsappMessageExpand,
    ],
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('contactId', `${contactId}`);
    queryParams = queryParams.append('page', `${page}`);
    queryParams = queryParams.append('limit', `${limit}`);
    queryParams = queryParams.append('expandList', expandList.join(','));
    return this.http
      .get<ApiResponse<WhatsappMessage[]>>(`${AppConfig.apiUrl}/whatsapp/get-messages-by-contact-id`, {
        params: queryParams,
      })
      .pipe(apiResponsePipe);
  }

  clearContactUnreadMessages(chatId: string) {
    const body: ClearContactUnreadMessagesRequest = {
      id: chatId,
    };
    return this.http
      .post<ApiResponse<ClearContactUnreadMessagesRequest>>(
        `${AppConfig.apiUrl}/whatsapp/clear-contact-unread-messages`,
        body,
      )
      .pipe(apiResponsePipe);
  }

  changeWhatsappPhone(phone: string, cardId: number) {
    const body: CardUpdateWhatsappPhoneRequest = {
      cardId,
      phone,
    };
    return this.http
      .post<ApiResponse<any>>(`${AppConfig.apiUrl}/crm-card/update-whatsapp-phone`, body)
      .pipe(apiResponsePipe);
  }

  clearAllContactsUnreadMessages() {
    return this.http
      .post<ApiResponse<any>>(`${AppConfig.apiUrl}/whatsapp/clear-all-contacts-unread-messages`, {})
      .pipe(apiResponsePipe);
  }
}
