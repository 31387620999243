import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { SlideToggleComponent } from './components/slide-toggle.component';

@NgModule({
  declarations: [SlideToggleComponent],
  imports: [CommonModule, InputSwitchModule, FormsModule],
  exports: [SlideToggleComponent],
})
export class SlideToggleModule {}
