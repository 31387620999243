<div class="complete-deal-step" *ngIf="isLoaded; else loading">
  <form [formGroup]="form"
    (ngSubmit)="submit()"
  >

    <h3>{{TRANSLATE_PATH + "TITLE" | translate}}</h3>
    <p>{{TRANSLATE_PATH + "TIP" | translate}}</p>

    <div class="complete-type-list">
      <div *ngFor="let completeType of completeDealTypes; let isLast = last"
           class="type-item-wrapper" [ngClass]="{'--last': isLast}"
      >
        <div  class="type-item" >
          <div class="custom-checkbox">
            <input class="checkbox" type="checkbox" [id]="completeType"
                   [value]="completeType"
                   [hidden]="true">
            <img  alt="checkbox-checked"
                  *ngIf="selectedCompleteDealType === completeType"
                  src="assets/icons/deal-view/checkbox-checked.svg" class="checkbox-svg">
            <img alt="checkbox"
                 (click)="selectCompleteDealType(completeType)"
                 *ngIf="selectedCompleteDealType !== completeType"
                 src="assets/icons/deal-view/checkbox.svg" class="checkbox-svg">
          </div>
          <label
            class="stage-label"
            [for]="completeType"
            (click)="selectCompleteDealType(completeType)"
          >
            {{ 'PAGES.DEALS.SALES_FUNNEL.COMPLETE_ACTION_TYPES.' + completeType | translate }}
          </label>
        </div>
        <div
          *ngIf="completeType === types.Complete"
        >
          <app-complete-deal-reason-list
          [isListDisabled]="selectedCompleteDealType !== types.Complete"
          (activeReasonKeyChanged)="changeActiveReasonKey($event)"

          >
          </app-complete-deal-reason-list>
        </div>
        <div *ngIf="completeType === types.Booking &&
         selectedCompleteDealType === types.Booking">
            <div *ngIf="countInfo?.count === 0">
              <app-no-found-any-tour-packages>
              </app-no-found-any-tour-packages>
            </div>
        </div>
        <div
          *ngIf="completeType === types.MarkAsNonConversion"
        >
          <app-non-conversion-reason-list
            [isListDisabled]="selectedCompleteDealType !== types.MarkAsNonConversion"
            (activeReasonKeyChanged)="changeActiveNonConversionReasonKey($event)"

          >
          </app-non-conversion-reason-list>
        </div>
      </div>
    </div>

    <textarea formControlName="managerComment"
              class="comment-field"
              placeholder="{{TRANSLATE_PATH + 'COMMENT_PLACEHOLDER' | translate}}">
    </textarea>

    <div class="nav-buttons">
      <button class="btn secondary ghost"
              (click)="goPrev()">{{"COMMON.NAV_BUTTONS.PREVIOUS" | translate}}</button>
      <button  class="btn secondary emphasis"
               [disabled]="getIsButtonDisabled()"
               type="submit">{{"COMMON.NAV_BUTTONS.DONE" | translate}}</button>
    </div>
  </form>
</div>

<ng-template #loading>
  <app-preloader></app-preloader>

</ng-template>
