<div class="sort-container" *ngIf="currentSortOption$ | async as currentSortOption">
  <span class="current-sort" (click)="toggleDropdown()" [class.opened]="isDropdownOpen">
    <span>{{ currentSortOption | sortLabel : sortingOptions }}</span>
    <svg
      [ngClass]="{ 'arrow-up': isDropdownOpen, 'arrow-down': !isDropdownOpen }"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12 6L8 10L4 6"
        stroke="#21304E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
  <div class="dropdown-menu" *ngIf="isDropdownOpen" @dropdownAnimation>
    <div class="dropdown-header">
      <div class="icon-container"></div>
      <span>Сортировать по:</span>
    </div>
    <div
      *ngFor="let sortOption of sortingOptions"
      (click)="onSortOptionChange(sortOption.id)"
      class="dropdown-item"
      [class.active]="currentSortOption === sortOption.id"
    >
      <div class="icon-container">
        <ng-container *ngIf="currentSortOption === sortOption.id">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M11.535 5.64062L6.82163 10.354L4.46497 7.99729"
              stroke="#F4F8FE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-container>
      </div>
      <span>{{ sortOption.label }}</span>
    </div>
  </div>
</div>
