<div class="post-sale-list-content">
  <ng-container *ngIf="list?.length">
    <ng-container *ngIf="todayDeals.length" >
      <ng-container *ngFor="let item of todayDeals">
        <ng-container *ngFor="let crmCardListPostSaleTourPackage of item.tourPackages">
          <app-post-sale-list-item
            *ngIf="crmCardListPostSaleTourPackage.tourPackage;"
            [crmCardListPostSaleItem]="item"
            id="deal-{{item.dealListItem.deal.id}}"
            [tourPackage]="crmCardListPostSaleTourPackage"
            [isMinimize]="!!!maximizedTourPackagesList.includes(crmCardListPostSaleTourPackage.tourPackage.id)"
          >
          </app-post-sale-list-item>
        </ng-container>
      </ng-container>
    </ng-container>
    <p class="empty-deals-list-placeholder center" *ngIf="otherDeals.length && !todayDeals.length">
      {{ 'PAGES.DEALS.EMPTY_TODAY_DEALS_LIST'  | translate }}
    </p>
    <button class="show-other-post-sale-deals-btn"
            *ngIf="otherDeals.length"
            [ngClass]="{'rotate-arrow':isAllowToShowOtherDeals}"
            (click)="toggleAllowToShowOtherDeal()"
    >
      <ng-container *ngIf="isAllowToShowOtherDeals">{{ 'PAGES.DEALS.TOUR_PACKAGE.HIDE_OTHER_TOUR_PACKAGES' | translate }}</ng-container>
      <ng-container *ngIf="!isAllowToShowOtherDeals">{{ 'PAGES.DEALS.TOUR_PACKAGE.SHOW_OTHER_TOUR_PACKAGES' | translate }}</ng-container>
    </button>
    <ng-container *ngIf="isAllowToShowOtherDeals">
      <ng-container *ngFor="let item of otherDeals">
        <ng-container *ngFor="let crmCardListPostSaleTourPackage of item.tourPackages">
          <app-post-sale-list-item
            *ngIf="crmCardListPostSaleTourPackage.tourPackage;"
            [crmCardListPostSaleItem]="item"
            id="deal-{{item.dealListItem.deal.id}}"
            [tourPackage]="crmCardListPostSaleTourPackage"
            [isMinimize]="!!!maximizedTourPackagesList.includes(crmCardListPostSaleTourPackage.tourPackage.id)"
          >
          </app-post-sale-list-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>


<!-- <p class="empty-deals-list-placeholder center" *ngIf="getPlaceholderText() as text">
  {{ text | translate }}
</p> -->
