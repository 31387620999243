import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  computed,
  ElementRef,
  EventEmitter,
  input,
  OnDestroy,
  OnInit,
  output,
  Output,
  signal,
  viewChild,
} from '@angular/core';
import { SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { PopupService } from '../../../../../../../../../../../shared/services/popup-service.service';
import { FavoriteHotelsSearchFormSelectedCountry } from '../../../../../favorites-hotels.model';
import { FavoriteHotelsCountryRegionsDropdownComponent } from '../../dropdown-regions/favorite-hotels-country-regions-dropdown.component';

@Component({
  selector: 'app-favorite-hotels-countries-dropdown-item',
  templateUrl: './favorite-hotels-countries-dropdown-item.component.html',
  styleUrl: './favorite-hotels-countries-dropdown-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FavoriteHotelsCountryRegionsDropdownComponent],
  providers: [PopupService],
})
export class FavoriteHotelsCountriesDropdownItemComponent implements OnInit, OnDestroy {
  selectedDirection = output<FavoriteHotelsSearchFormSelectedCountry>();
  closed = output<void>();

  country = input<SearchFormParamsCountry>();
  regions = input<SearchFormParamsRegion[]>([]);
  active = input<boolean>(false);
  countryRegions = computed(() => {
    const country = this.country();
    const regions = this.regions();

    return regions.filter(region => region.countryId === country.id);
  });
  showCheckIcon = computed(() => {
    return this.active();
  });
  regionsRef = viewChild<ElementRef>('regions');
  selectedRegions = signal<SearchFormParamsRegion[]>([]);
  selectedRegionIds = input<number[]>([]);

  private regionComponentRef: ComponentRef<FavoriteHotelsCountryRegionsDropdownComponent>;

  constructor(private readonly popupService: PopupService, private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    const regions = [];
    this.selectedRegionIds().forEach(regionId => {
      const region = this.countryRegions().find(countryRegion => countryRegion.id === regionId);
      if (region) {
        regions.push(region);
      }
    });

    this.selectedRegions.set(regions);
  }

  ngOnDestroy() {
    this.destroyComponent();
  }

  showRegions() {
    this.selectedDirection.emit({
      country: this.country(),
      regions: this.selectedRegions(),
    });

    if (this.countryRegions().length < 2) {
      this.closed.emit();
      return;
    }

    this.regionComponentRef = this.popupService.showPopup(
      FavoriteHotelsCountryRegionsDropdownComponent,
      {},
      {
        anchorElement: this.regionsRef(),
        hasBackdrop: false,
      },
    );

    this.regionComponentRef.instance.regions = this.countryRegions();
    this.regionComponentRef.instance.selectedRegionIds = this.selectedRegionIds();
    this.regionComponentRef.instance.selected.subscribe(regions => {
      this.selectedRegions.update(() => regions);
      this.selectedDirection.emit({
        country: this.country(),
        regions: this.selectedRegions(),
      });
    });
    this.regionComponentRef.instance.closed.subscribe(() => {
      this.popupService.closeAllModals();
    });
    this.regionComponentRef.instance.closedCountry.subscribe(() => {
      this.popupService.closeAllModals();
      this.closed.emit();
    });
  }

  private destroyComponent() {
    this.regionComponentRef?.destroy();
  }
}
