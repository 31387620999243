import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotelTagListRequest } from '@api-clients/api-client';
import { Subscription } from 'rxjs';
import { AutoFocusDirective } from '../../../../../../shared/directives/auto-focus.directive';
import { SearchHotelTagsService } from '../../../../../deals/modules/deal-view/modules/favorites-hotels/services/search-hotel-tags.service';
import { SearchHotelStarsComponent } from '../../../../../search/components/hotel/hotel-stars/search-hotel-stars.component';

export interface CheckboxItem {
  id: number;
  name: string;
  selected: boolean;
}

/**
 * Никита обещал вынести multiple select в отдельный компонент,
 * поэтому в наглую пока копипастим с его компонента поиска на карте
 */
@Component({
  selector: 'app-favorite-hotels-map-filter-tags-copy',
  templateUrl: './favorite-hotels-map-filter-tags-copy.component.html',
  styleUrl: './favorite-hotels-map-filter-tags-copy.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, SearchHotelStarsComponent, FormsModule, AutoFocusDirective],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FavoriteHotelsMapFilterTagsComponentCopy implements OnInit, OnDestroy {
  @Input() countryId: number;
  @Input() hotelIds: number[];
  @Input() selectedIds: number[] = [];

  closed = output<number[] | null>();

  searchQuery = signal<string>('');
  items = signal<CheckboxItem[]>([]);
  visibleItems = computed(() => {
    const items = this.items();
    const searchText = this.searchQuery();

    return items.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
  });

  isLoading = signal<boolean>(true);
  disabled = signal<boolean>(false);
  tagsPriorities = [];

  private loadTagsSubscription: Subscription;

  constructor(private readonly tagService: SearchHotelTagsService) {}

  onCheckboxChange(tagId: number, checked: boolean) {
    if (checked) {
      this.tagsPriorities.push(tagId);
    } else {
      this.tagsPriorities.splice(
        this.tagsPriorities.findIndex(tagPriorityId => tagPriorityId === tagId),
        1,
      );
    }
  }

  ngOnInit() {
    const request: HotelTagListRequest = {};
    if (this.countryId) {
      request.countryId = this.countryId;
    }
    if (this.hotelIds) {
      request.hotelIds = this.hotelIds;
    }

    this.isLoading.set(true);
    this.loadTagsSubscription = this.tagService.loadTags$(request).subscribe(tags => {
      this.isLoading.set(false);
      this.tagsPriorities = this.selectedIds;

      this.items.set(
        tags.map(tag => {
          return {
            id: tag.id,
            name: tag.name,
            selected: this.selectedIds.includes(tag.id),
          };
        }),
      );
    });
  }

  ngOnDestroy() {
    this.loadTagsSubscription?.unsubscribe();
  }

  apply(): void {
    const selectedIds = this.visibleItems()
      .filter(item => item.selected)
      .map(item => item.id);

    if (selectedIds.length) {
      this.closed.emit(selectedIds);
    } else {
      this.tagService.flushTagsByHotels();
      this.closed.emit(null);
    }
  }

  reset(): void {
    this.tagsPriorities = [];
    this.items.set(
      this.items().map(item => {
        return {
          ...item,
          selected: false,
        };
      }),
    );
    this.disabled.set(false);
    this.tagService.flushTagsByHotels();
    this.closed.emit(null);
  }

  onSearchUpdated(sq: string) {
    this.searchQuery.set(sq);
  }
}
