import { Pipe, PipeTransform } from '@angular/core';

/**
 * Заглушка применяется при отсутствии Duration, чтобы не сломался проигрыватель.
 */
const DummyDuration = 777;

// получает секунды возвращает время в формате мин:сек
@Pipe({ name: 'timeDurationFormat' })
export class TimeDurationFormatPipe implements PipeTransform {
  public transform(value: number): string {
    if (value === DummyDuration) {
      return '';
    }
    value = Math.floor(value);
    let minutes: number;
    let seconds: number;
    if (value < 3600) {
      minutes = Math.floor(value / 60);
      seconds = value - minutes * 60;
      return `${this.format(minutes)}:${this.format(seconds)}`;
    }

    const hours = Math.floor(value / 3600);
    minutes = Math.floor(value - hours * 3600) / 60;
    seconds = value - hours * 3600 - minutes * 60;
    return `${this.format(hours)}:${this.format(minutes)}:${this.format(seconds)}`;
  }

  private format(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
