import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../../../../ui-components/alert-label/alert-label.component';
import { ChatDragAndDropService } from '../../../../services/chat-drag-and-drop.service';
import { HotOffersService } from '../../hot-offers.service';
import { HotOffersFiltersModalComponent } from '../filters-modal/hot-offers-filters-modal.component';
import { HotOffersCountriesComponent } from './components/countries/hot-offers-countries.component';
import { HotOffersSortsComponent } from './components/sorts/hot-offers-sorts.component';

@Component({
  selector: 'app-hot-offers-list',
  templateUrl: './hot-offers-list.component.html',
  styleUrls: ['./hot-offers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HotOffersCountriesComponent, AsyncPipe, HotOffersSortsComponent, AlertLabelComponent],
  providers: [ChatDragAndDropService],
  standalone: true,
})
export class HotOffersListComponent implements OnInit, OnDestroy {
  departCityId: number;
  isReady = false;
  loading = true;
  showError = false;
  alertType = AlertLabelType.warning;

  hasActiveFilters$ = this.hotOffersService.hasActiveFilters$;

  private initServiceSubscription: Subscription;
  private departCitySubscription: Subscription;

  private ddComponentRef: ComponentRef<HotOffersFiltersModalComponent>;

  private ddComponentOnDestroySubscription: Subscription;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly hotOffersService: HotOffersService,
    private readonly chatDDService: ChatDragAndDropService,
  ) {}

  ngOnInit(): void {
    this.initServiceSubscription = this.hotOffersService.initState$().subscribe({
      next: () => {
        this.isReady = true;
        this.loading = false;
        this.cdRef.detectChanges();
      },
      error: () => {
        this.showError = true;
        this.loading = false;
        this.cdRef.detectChanges();
      },
    });

    this.departCitySubscription = this.hotOffersService.activeDepartCity$
      .pipe(filter(v => !!v))
      .subscribe(departCity => {
        this.departCityId = departCity.departCityId;
        this.cdRef.detectChanges();
      });

    this.ddComponentOnDestroySubscription = this.chatDDService.ddComponentOnDestroy$.subscribe(() => {
      this.destroyDDComponent();
    });
  }

  ngOnDestroy(): void {
    this.initServiceSubscription?.unsubscribe();
    this.ddComponentOnDestroySubscription?.unsubscribe();
    this.departCitySubscription?.unsubscribe();

    this.hotOffersService.flushData();

    this.chatDDService.closeArea();
    this.destroyDDComponent();
  }

  toggleFilters(): void {
    if (this.ddComponentRef) {
      this.destroyDDComponent();
      this.chatDDService.closeArea();
    } else {
      this.ddComponentRef = this.viewContainerRef.createComponent(HotOffersFiltersModalComponent);
      this.ddComponentRef.instance.ngOnDestroy = () => {
        this.destroyDDComponent();
        this.chatDDService.closeArea();
      };

      this.chatDDService.showArea({
        showTourDropArea: true,
        showCloseButton: true,
        customComponentRef: this.ddComponentRef,
      });
    }
  }

  private destroyDDComponent(): void {
    this.ddComponentRef?.destroy();
    this.ddComponentRef = undefined;
  }
}
