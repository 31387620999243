import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-image',
  templateUrl: './message-image.component.html',
  styleUrls: ['./message-image.component.scss'],
})
export class MessageImageComponent {
  @Input() isSticker: boolean;
  @Input() isFromMe: boolean;
  @Input() mediaSource: string;
  @Input() text: string;
  @Input() isVisibleAdditionalElements: boolean;
  @Input() isLoaded = false;
  @Input() imageWidth: number | null;
  @Input() imageHeight: number | null;
  // Максимальный размер изображения (высота или ширина)
  public maxsize = 270;

  showImage() {
    this.isLoaded = true;
  }

  get height() {
    if (this.imageHeight >= this.imageWidth) {
      return this.maxsize;
    }

    return (this.maxsize * this.imageHeight) / this.imageWidth;
  }
  get width() {
    if (this.imageWidth >= this.imageHeight) {
      return this.maxsize;
    }
    return (this.maxsize * this.imageWidth) / this.imageHeight;
  }
}
