export enum SipTypes {
  Main = 'Main',
  Additional = 'Additional',
}
export type SipType = SipTypes.Main | SipTypes.Additional;

export interface Click2CallEvent {
  phoneNumber: string;
  sipType: SipType;
}

export interface CurrentOutcomeCall {
  clientName: string;
  clientPhone: string;
  success?: boolean;
  message?: string;
  isHidden?: boolean;
}
