import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Notification, NotificationType } from '@api-clients/crm-api-client/dist/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notify-center-item',
  templateUrl: './notify-center-item.component.html',
  styleUrls: ['./notify-center-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifyCenterItemComponent implements OnInit {
  @Input() notification: Notification;
  @Output() deletedById: EventEmitter<number> = new EventEmitter<number>();
  public NotificationType = NotificationType;
  public isHovered: boolean;
  constructor(private cdRef: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {}

  openDealView(crmCardId: number) {
    this.router.navigate([`/deals`]).then(() => this.router.navigate([`/deals/view/${crmCardId}`]).then());
  }
  showDeleteButton() {
    this.isHovered = true;
    this.cdRef.detectChanges();
  }

  hideDeleteButton() {
    this.isHovered = false;
    this.cdRef.detectChanges();
  }

  deleteById(id: number) {
    this.deletedById.emit(id);
  }
}
