import { callInitialState, CurrentCallState } from './calls.state';
import { CallsAction, CallsActionTypes } from './calls.actions';

export const USER_FEATURE_KEY = 'calls';

export function callsReducer(
  state: CurrentCallState = callInitialState,
  action: CallsAction,
): CurrentCallState {
  switch (action.type) {
    case CallsActionTypes.UpdateCurrentCall:
      state = {
        ...state,
        deal: action.payload?.deal ? action.payload.deal : state.deal,
        callId: action.payload?.deal?.currentCall?.cdrId,
        callUuid: action.payload?.deal?.currentCall?.id,
      };
      break;
    case CallsActionTypes.SetSearchOpened:
      state = {
        ...state,
        isSearchOpened: action.payload.isOpened,
      };
      break;
    case CallsActionTypes.ResetCall:
      state = {
        ...state,
        callId: null,
        callUuid: null,
        isSearchOpened: false,
      };
      break;
    default:
      return state;
  }

  return state;
}
