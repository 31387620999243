import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './components/settings/settings.component';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { SlideToggleModule } from '../../ui-components/slide-toggle/slide-toggle.module';
import { WhatsappTemplatesSettingsComponent } from './components/whatsapp-templates-settings/whatsapp-templates-settings.component';
import { WhatsappTemplateManageItemComponent } from './components/whatsapp-templates-settings/components/whatsapp-template-manage-item/whatsapp-template-manage-item.component';
import { WhatsappTemplateFormComponent } from './components/whatsapp-templates-settings/components/whatsapp-template-form/whatsapp-template-form.component';
import { FormErrorModule } from '../../ui-components/form-error/form-error.module';
import { FormComponentsModule } from '../form-components/form-components.module';
import { ZenmodeWelcomePopupContainerModule } from '../zenmode/modules/zenmode-welcome-popup-container/zenmode-welcome-popup-container.module';
import { ZoomLevelToPercentPipe } from './components/settings/pipes/zoom-level-to-percent.pipe';

@NgModule({
  declarations: [
    SettingsComponent,
    WhatsappTemplatesSettingsComponent,
    WhatsappTemplateManageItemComponent,
    WhatsappTemplateFormComponent,
    ZoomLevelToPercentPipe,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    SlideToggleModule,
    ReactiveFormsModule,
    FormErrorModule,
    FormComponentsModule,
    ZenmodeWelcomePopupContainerModule,
  ],
})
export class SettingsModule {}
