<div class="progress-container">
  <app-line-progress *ngIf="searchInProgress$ | async" [animationDuration]="'30s'" />
</div>

<div class="filters-and-sort-container">
  <div class="filters-and-sort">
    <div class="search-control-container">
      <input #searchQuery
             (input)="onSearchUpdated(searchQuery.value)"
             type="text"
             class="search-control"
             placeholder="Поиск по названию отелю">
    </div>

    <app-favorites-hotels-sorts (selectedSort)="selectedSort($event)" />
  </div>
</div>

<div class="groups">
  <virtual-scroller #scroll [items]="visibleGroups()" [checkResizeInterval]="2000">
    @for (group of scroll.viewPortItems; track group.hotel.id) {
      <app-search-hotel
        (mouseenter)="onHotelMouseEnter(group)"
        (mouseleave)="onHotelMouseLeave()"
        (showHotelOnMap)="showHotelOnMap($event)"
        [contentCreatorType]="contentCreatorType.ManagerPricesCalendar"
        [tourSelectMode]="tourSelectMode"
        [initSearchRequest]="initSearchRequest"
        [allowResearch]="true"
        [allowReCalculate]="true"
        [mapPointerVisible]="true"
        [hotelOnMap]="(mapVisibleHotelIds().length === 0 && !hasFilters()) || mapVisibleHotelIds().includes(group.hotel.id)"
        [tags]="tags().get(group.hotel.id) || []"
        [hotel]="group.hotel"
        [initSearchTours]="group.tours" />
    }
  </virtual-scroller>
</div>
