import { CallItem } from '@api-clients/crm-api-client/dist/models';
import { CrmTaskItem } from '@api-clients/crm-api-client';

export interface IndicatorPayload {
  conversationStatus: number; // 0-unknown,1-new, 2-waiting
  status: number; // 0 - new, 1 - in work, 2 - post sale
  calls: CallItem[];
  task: CrmTaskItem;
}

export enum IndicatorType {
  NewMessage = 'NewMessage',
  NewClient = 'NewClient',
  MissedCall = 'MissedCall',
  PendingTask = 'PendingTask',
  TaskCompletionReminder = 'TaskCompletionReminder',
  UrgentTask = 'UrgentTask',
}

export enum IndicatorMessagePartTypeEnum {
  IndicatorHighlightedText,
  IndicatorText,
  IndicatorEmoji,
}

interface IndicatorMessagePart {
  type: IndicatorMessagePartTypeEnum;
  content: string;
  count?: number;
}

export interface IndicatorData {
  type: IndicatorType;
  messageParts: IndicatorMessagePart[];
}
