import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealsMenuComponent } from './deals-menu.component';
import { SharedModule } from '../../../../../../shared/shared.module';
import { DealsMenuStagesListComponent } from './components/deals-menu-stages-list/deals-menu-stages-list.component';

@NgModule({
  declarations: [DealsMenuComponent, DealsMenuStagesListComponent],
  imports: [CommonModule, SharedModule],
  exports: [DealsMenuComponent],
})
export class DealsMenuModule {}
