import { Component, Input } from '@angular/core';
import { Flight } from '@api-clients/crm-api-client/models/flight';

@Component({
  selector: 'app-flights-info-item',
  templateUrl: './flights-info-item.component.html',
  styleUrls: ['./flights-info-item.component.scss'],
})
export class FlightsInfoItemComponent {
  @Input() flight: Flight;
}
