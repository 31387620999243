<div class="message-box-wrapper">
  <div
    #textContainer
    class="message-box-inner"
    [ngClass]="{
      fromClient: isFromClient,
      'is-group-incoming': isGroup && isFromClient,
    }"
    (mouseup)="handleMouseUp($event)">
    @if (isGroup && isFromClient && isLastOfGroup) {
      <picture class="message-sender-avatar">
        @if (sender?.avatarUri) {
          <img src="{{ sender.avatarUri }}" alt="{{ sender.name }}"
               width="24" height="24"/>
        } @else {
          <img src="assets/icons/chats/avatar.svg" alt="{{ sender?.name || '' }}"
               width="24" height="24" />
        }
      </picture>
    }

    <div
      #messageBox
      class="message-box"
      [ngClass]="{

        fromClient: isFromClient,

        'last-in-group': isLastOfGroup,

        'first-in-group': isFirstOfGroup,
        'is-hotel': hotel,
        scheduled: chatTimeLineItem.type === chatTimelineItemTypeEnum.scheduledMessage
     ,
       }"
    >
      <app-message-menu [chatTimeLineItem]="chatTimeLineItem" (reply)="reply()" />
      @if (isGroup && isFromClient && isFirstOfGroup) {
        <div class="message-box-header">
            <span class="message-sender-name">{{ sender?.name || '...' }}</span>
        </div>
      }
      <div *ngIf="isTextSelected"
              [style.top]="selectedTextButtonPosition.top"
              [style.left]="selectedTextButtonPosition.left"
              class="selected-text-button"
              (mousedown)="openGptForSelectedText()">
        <img class="icon" src="assets/icons/timeline/message-menu/ai.svg" /> GPT-чат
      </div>
      <div
        class="send-error"
        #tooltip="matTooltip"
        matTooltip="{{ sendErrorTooltipTextLangKey | translate }}"
        [matTooltipPosition]="'above'"
        matTooltipHideDelay="100"
        *ngIf="isErrorSend"
      >
        <img src="assets/icons/timeline/no-app.svg" alt="error" />
      </div>

      <ng-container>
        <div [ngSwitch]="chatTimeLineItem.type" class="message">
          <div *ngSwitchCase="chatTimelineItemTypeEnum.call">
            <app-chat-timeline-item-call [callItem]="chatTimeLineItem.data"> </app-chat-timeline-item-call>
          </div>
          <div *ngSwitchCase="chatTimelineItemTypeEnum.searchRequest">
            <app-chat-timeline-item-search-history [searchRequest]="chatTimeLineItem.data">
            </app-chat-timeline-item-search-history>
          </div>
          <div *ngSwitchCase="chatTimelineItemTypeEnum.sms" [style.margin-right.px]="-40">
            <app-chat-timeline-item-sms [smsItem]="chatTimeLineItem.data"> </app-chat-timeline-item-sms>
          </div>

          <div *ngSwitchCase="chatTimelineItemTypeEnum.reservation" [style.margin-right.px]="-40">
            <app-chat-timeline-item-reservation [tourPackage]="chatTimeLineItem.data">
            </app-chat-timeline-item-reservation>
          </div>
          <div
            *ngSwitchCase="chatTimelineItemTypeEnum.message"
            [id]="chatTimeLineItem.data.id"
            [ngClass]="{ audio: isAudio }"
          >
            <app-chat-timeline-item-message
              (maximized)="maximized.emit($event)"
              [message]="chatTimeLineItem.data"
            >
            </app-chat-timeline-item-message>
          </div>
          <div *ngSwitchCase="chatTimelineItemTypeEnum.scheduledMessage" [id]="chatTimeLineItem.data.id">
            <app-chat-timeline-item-scheduled-message [scheduledMessage]="chatTimeLineItem.data" />
          </div>
          <div *ngSwitchCase="chatTimelineItemTypeEnum.note">
            {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.NOTE' | translate }}
            {{ chatTimeLineItem.data.comment }}
          </div>
          <div *ngSwitchDefault></div>
        </div>
      </ng-container>

      <div class="message-footer">
        <ng-container
          *ngIf="
            chatTimeLineItem.type == chatTimelineItemTypeEnum.message && !chatTimeLineItem.data?.isFromMe
          "
        >
          <img src="assets/icons/timeline/whatsapp.svg" class="whatsapp-icon" alt="whatsapp-icon" />
        </ng-container>
        <span>{{ messageTime }}</span>
        <ng-container
          *ngIf="chatTimeLineItem.type == chatTimelineItemTypeEnum.message && chatTimeLineItem.data?.isFromMe"
        >
          <ng-container *ngIf="chatTimeLineItem.data as message">
            <div class="status">
              <ng-container *ngIf="statusIconPath">
                <img [src]="statusIconPath" [alt]="statusIconAltText" />
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="additional-btn" *ngIf="chatTimeLineItem.type === chatTimelineItemTypeEnum.searchRequest">
      <img src="assets/icons/timeline/black-web.svg" alt="black-web" />
      <a libExternalLink [externalURL]="chatTimeLineItem.data.url">
        {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.SEARCH_AGAIN' | translate }}</a
      >
    </div>
    <div class="additional-btn" *ngIf="chatTimeLineItem.type === chatTimelineItemTypeEnum.reservation">
      <img src="assets/icons/timeline/black-web.svg" alt="black-web" />
      <a libExternalLink [externalURL]="chatTimeLineItem.data.link">
        {{
          'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.RESERVATION.OPEN_TOUR_PACKAGE_ON_SITE' | translate
        }}</a
      >
    </div>
  </div>
</div>
