import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientReservationsComponent } from '../client-reservations.component';

@Injectable({
  providedIn: 'root',
})
export class ReservationsModalService {
  constructor(public matDialog: MatDialog) {}

  showReservationsModal(crmCardId: number) {
    this.matDialog.open(ClientReservationsComponent, {
      id: 'client-reservations-list',
      disableClose: false,
      panelClass: 'modal-panel',
      width: '1100px',
      minHeight: 'calc(100vh - 200px)',
      height: 'auto',
      hasBackdrop: true,
      data: {
        crmCardId,
      },
    });
  }
}
