import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { PhoneItem } from '@api-clients/crm-api-client';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { MultiplePhoneInputComponent, MultiplePhoneInputModes } from '../../../add-deal/components/multiple-phone-input/multiple-phone-input.component';
import { Stage } from '../../modules/sales-funnel-stages/sales-funnel-stages';
import { CommonModule } from '@angular/common';
import { Click2callComponent } from '../click2call/click2call.component';
import { SalesFunnelStagesMiniComponent } from '../../modules/sales-funnel-stages-mini/sales-funnel-stages-mini.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  imports: [CommonModule, Click2callComponent, SalesFunnelStagesMiniComponent, MultiplePhoneInputComponent],
  standalone: true,
})
export class ContactsComponent implements OnInit {
  public error: any;
  @Input() parentForm: UntypedFormGroup;
  @Input() currentStage: Stage;
  @Input() status: number;
  @Input() cardId: number;
  @Input() dealId: number;
  @Input() clientName: string;
  @Input() selectedPhone: PhoneItem;
  @Input() phoneItems: PhoneItem[];
  @Output() phonesChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() selectedPhoneChanged: EventEmitter<PhoneItem> = new EventEmitter<PhoneItem>();
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public phonesControls: AbstractControl[];
  public phonesFrom: UntypedFormArray;
  public MULTIPLE_PHONE_INPUT_MODES = MultiplePhoneInputModes;
  public readonly CONVERSION_EXCLUDE_STATUS = 8;

  getPhonesWithCountryCodes($event: string[]) {
    this.phonesChanged.emit($event);
  }

  ngOnInit() {
    this.phonesFrom = this.parentForm.get('phones') as UntypedFormArray;
    this.phonesControls = this.phonesFrom.controls;
  }

  onSelectedPhoneChanged($event: PhoneItem) {
    this.selectedPhoneChanged.emit($event);
  }
}
