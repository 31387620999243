import {
  WhatsappMessage,
  WhatsappScheduledMessage,
  TourPackage,
  CallItem,
  Note,
  SearchRequest,
  SmsItem,
} from '@api-clients/crm-api-client';

export enum ChatTimelineItemTypeEnum {
  call = 'call',
  sms = 'sms',
  note = 'note',
  searchRequest = 'searchRequest',
  task = 'task',
  reservation = 'reservation',
  message = 'message',
  scheduledMessage = 'scheduled-message',
}

export enum WhatsappMessageStatusList {
  Unknown = 'unknown',
  InFlight = 'inFlight',
  Sent = 'sent',
  Received = 'received',
  Read = 'read',
  NoApp = 'noApp',
  UnknownError = 'unknownError',
  DialogExpired = 'dialogExpired',
}

export interface ChatTimeLineDayItem {
  sortDate: string;
  items: Array<ChatTimeLineItem>;
}

export interface ChatTimeLineItem {
  type: ChatTimeLineItemsType;
  data: ChatTimeLineItemsDataType;
  sortDate: string;
  isLastOfGroup?: boolean;
  isFirstOfGroup?: boolean;
}

export type ChatTimeLineItemsType = ChatTimelineItemTypeEnum;
export type ChatTimeLineItemsDataType =
  | CallItem
  | SmsItem
  | Note
  | SearchRequest
  | TourPackage
  | WhatsappMessage
  | WhatsappScheduledMessage
  | any;

export interface ImageGroup {
  type: 'imageGroup';
  images: WhatsappMessage[];
  isFromMe: boolean;
}

export type ChatTimelineMessage = WhatsappMessage | ImageGroup;

export enum CallTypes {
  'income' = 0,
  'outcome' = 1,
  'callback' = 4,
  'fromManager' = 5,
}

export interface ImageItem {
  id?: string;
  previewUrl?: string;
  contentLink: string;
  isVideo?: boolean;
  date?: string;
  text?: string;
}

export enum WhatsappMessageTypeList {
  Chat = 'chat',
  Ptt = 'ptt',
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Location = 'location',
  Sticker = 'sticker',
  Vcard = 'vcard',
  MultiVcard = 'multi_vcard',
  Document = 'document',
  Revoked = 'revoked',
  Notification = 'notification',
  NotificationTemplate = 'notification_template',
  E2eNotification = 'e2e_notification',
  Gp2 = 'gp2',
  Ciphertext = 'ciphertext',
  CallLog = 'call_log',
  Unsupported = 'unsupported',
}

export enum FilterListEnum {
  all = 'all',
  call = 'call',
  reservation = 'reservation',
  sms = 'sms',
  // searchRequest = 'searchRequest',
  message = 'message',
  messageOffer = 'messageOffer',
}

export interface FilterWithCount {
  filter: FilterListEnum;
  count: number;
}

export interface ChatTimelineData {
  lastWhatsappMessages: WhatsappMessage[];
  whatsappScheduledMessages?: WhatsappScheduledMessage[];
  lastSmsList?: SmsItem[];
  calls?: CallItem[];
  searchRequests?: SearchRequest[];
  tourPackages?: TourPackage[];
  notes?: Note[];
}
