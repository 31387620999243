import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-request-add-date',
  templateUrl: './search-request-add-date.component.html',
  styleUrls: ['./search-request-add-date.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SearchRequestAddDateComponent {
  @Input() addDate: string;
  dateFormat = 'dd.MM.yyyy';
}
