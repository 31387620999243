<div class="progress-bar">
  <div class="step" [ngClass]="{'no-line': isLast}"
       *ngFor="let stepNum of [1, 2, 3]; let isLast = last">
    <div class="circle" [class.completed]="step >= stepNum">
      <span class="step-number" *ngIf="!isLast; else done">
        {{ stepNum }}
      </span>
      <ng-template #done>
        <img
          *ngIf="step !== stepNum; else doneImg"
          src="assets/icons/deal-view/end-task-btn.svg"
          alt="done">

      </ng-template>
    </div>
    <div *ngIf="!isLast" class="line" [ngClass]="{'done': stepNum < step}"></div>
  </div>
</div>

<ng-template #doneImg>
  <img
    src="assets/icons/deal-view/end-task-btn-white.svg" alt="done">
</ng-template>
