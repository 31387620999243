import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteMessageConfirmComponent } from '../components/delete-message-confirm/delete-message-confirm.component';

@Injectable({
  providedIn: 'root',
})
export class DeleteMessageConfirmModalService {
  constructor(public matDialog: MatDialog) {}

  showModal(messageId: string) {
    this.matDialog.open(DeleteMessageConfirmComponent, {
      id: 'delete-message-confirm-modal',
      panelClass: 'modal-panel-rounded',
      minWidth: '400px',
      width: '400px',
      minHeight: '100px',
      maxHeight: 'calc(100vh - 10px)',
      hasBackdrop: true,
      data: {
        messageId,
      },
    });
  }
}
