import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchResultService } from '../../../../../../../../../search/services/search-result.service';
import { SearchFormService } from '../../../search-form/search-form.service';
import { SearchResultControlSearchFormComponent } from './control/search-result-control-search-form.component';

@Component({
  selector: 'app-search-result-controls',
  templateUrl: './search-result-controls.component.html',
  styleUrl: './search-result-controls.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTooltip, AsyncPipe, SearchResultControlSearchFormComponent],
})
export class SearchResultControlsComponent implements OnInit {
  clientAvatar = signal<string>('');

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly searchResultService: SearchResultService,
    private readonly searchFormService: SearchFormService,
  ) {}

  ngOnInit(): void {
    this.searchFormService.activeCrmCard$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(crmCard => {
      if (crmCard?.avatar) {
        this.clientAvatar.set(crmCard.avatar);
      }
    });
  }

  hideSearchResult(): void {
    this.searchResultService.hideResultComponent();
  }
}
