<ng-container *ngIf="!isSelectedPhone || mode === multiplePhoneInputModes.dealCreateMode" [formGroup]="parentForm">
  <label *ngIf="label" class="form-label">{{ label }}</label>
  <div #phoneWithCountryCode class="phone-with-country-code rounded-border">
    <div (click)="open()" [ngClass]="{ open: showList, readOnly: readOnlyMode }" class="country-code"
         *ngIf="selectedCountry?.mobilePhoneCode">
      <span class="flag-icon"><img src="{{ selectedCountry.flagIcon }}" alt="" class="flag" /></span>
      <span class="code">{{ selectedCountry.mobilePhoneCode }}</span>
    </div>
    <ul
      *ngIf="showList"
      (clickOutside)="close()"
      [delayClickOutsideInit]="true"
      [exclude]="'input.form-control'"
      class="country-code-list rounded-border"
    >
      <li class="search">
        <label for="code-search"><img src="./assets/icons/phone-codes.svg" alt="" /></label>
        <input type="text" id="code-search" placeholder="Поиск" />
      </li>
      <li
        *ngFor="let country of countryPhoneFormats"
        (click)="selectCountry(country)"
        [ngClass]="{ selected: country === selectedCountry }"
        class="country-code-item"
      >
        <span class="flag-icon"><img src="{{ country.flagIcon }}" alt="" class="flag" /></span>
        <span class="country">{{ country?.name }}</span>
        <span class="code">({{ country.mobilePhoneCode }})</span>
      </li>
    </ul>
    <ng-container *ngIf="!formArrayMode; else formArray">
      <input
        [mask]="mask"
        [readOnly]="readOnlyMode"
        [showMaskTyped]="true"
        (click)="setCursorPosition()"
        [formControlName]="formControlNameValue"
        (focus)="onFocus(phoneWithCountryCode)"
        (blur)="onBlur(phoneWithCountryCode)"
        class="form-control skip-validation-highlight"
        type="text"
      />
    </ng-container>
    <ng-container
    *ngIf="mode === multiplePhoneInputModes.dealViewMode"
    >
      <ng-container></ng-container>
      <div class="control-icon"
      (click)="copy()"
      >
        <img
          src="/assets/icons/deal-view/copy.svg" alt="copy">
      </div>
      <div
        (click)="openSmsModal()"
        class="control-icon end">
        <img
          src="/assets/icons/deal-view/message.svg" alt="message">
      </div>
        <div class="options"
             *ngIf="!isSelectedPhone || isFirstPhone"
        >

          <div class="select-phone"

                #tooltip="matTooltip"
               matTooltip="{{ 'PAGES.DEALS.CLIENT_CARD.SELECT_PHONE_TOOLTIP' | translate }}"
               [matTooltipPosition]="'above'"
               matTooltipHideDelay="100"
               (click)="selectPhone()"
          >

          </div>


          <div class="dots"
               (click)="openOptionsDropdown()"
          >
            <img src="assets/icons/deal-view/dots.svg" class="dots-img" alt="dots">
          </div>
          <app-phone-input-options-popup *ngIf="isShowOptionsDropdown"

                                         (closeClicked)="closeOptions()"
                                         (editClicked)="editPhone()"
                                         (removeClicked)="removePhone()"

          >
          </app-phone-input-options-popup>
        </div>

    </ng-container>
    <ng-template #formArray>
      <ng-container
        [formArrayName]="formArrayMode">
        <input
          [mask]="mask"
          [readOnly]="readOnlyMode"
          [showMaskTyped]="true"
          (click)="setCursorPosition()"
          [formControlName]="formControlNameValue"
          (focus)="onFocus(phoneWithCountryCode)"
          (blur)="onBlur(phoneWithCountryCode)"
          class="form-control skip-validation-highlight"
          type="text"
        />
      </ng-container>

    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="isSelectedPhone && mode === multiplePhoneInputModes.dealViewMode" [formGroup]="phoneForm">
  <div class="phone-with-country-code rounded-border selected-phone">
    <div [ngClass]="{ readOnly: true }" class="country-code"
         *ngIf="selectedCountry?.mobilePhoneCode">
      <span class="flag-icon"><img src="{{ selectedCountry.flagIcon }}" alt="" class="flag" /></span>
      <span class="code">{{ selectedCountry.mobilePhoneCode }}</span>
    </div>
  </div>
    <input
      formControlName="selectedPhone"
      [mask]="mask"
      [readOnly]="true"
      [showMaskTyped]="true"
      class="selected-phone-input form-control skip-validation-highlight"
      type="text"
    />
  <div class="control-icon"
       (click)="copy()"
  >
    <img
      src="/assets/icons/deal-view/copy.svg" alt="copy">
  </div>
  <div class="control-icon end"
        (click)="openSmsModal()"
  >
    <img
      src="/assets/icons/deal-view/message.svg" alt="message">
  </div>

</ng-container>
