import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryPhoneFormat } from '@api-clients/crm-api-client/dist/models';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PhoneItem } from '@api-clients/crm-api-client';
import { debounceTime } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { ConfigsService } from '../../../core/services';
import { MultiplePhoneInputModes } from '../../../modules/deals/modules/add-deal/components/multiple-phone-input/multiple-phone-input.component';
import { SmsPopupService } from '../../../modules/sms/services/sms-popup.service';
import { ScreenTypes } from '../../../core/services/amplitude/amplitudeEventData';
import { NotifyService, NotifyTypeEnum } from '../../../shared/notify/services/notify.service';

@Component({
  selector: 'app-phone-input-with-code',
  templateUrl: './phone-input-with-code.component.html',
  styleUrls: ['./phone-input-with-code.component.scss'],
})
export class PhoneInputWithCodeComponent implements OnInit, OnChanges {
  @Input() formArrayMode: string;
  @Input() formControlItem: AbstractControl;
  @Input() formControlNameValue: any;
  @Input() parentForm: UntypedFormGroup;
  @Input() concatenateCountryCode: boolean;
  @Input() translatableLabel: string;

  @Input() phoneItemWithCode: PhoneItem;
  @Input() set labelText(value: string) {
    this.label = value;
  }
  @Input() set readOnly(value: boolean) {
    this.readOnlyMode = value;
  }
  @Input() mode: MultiplePhoneInputModes;
  @Input() isSelectedPhone = false;
  @Input() isFirstPhone = false;
  @Input() initialFirstPhone: PhoneItem;
  @Output() phoneValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedPhoneChanged: EventEmitter<PhoneItem> = new EventEmitter<PhoneItem>();
  @Output() countryCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() removePhoneItem: EventEmitter<void> = new EventEmitter<void>();

  public label = '';
  public countryPhoneFormats: CountryPhoneFormat[];
  public selectedCountry: CountryPhoneFormat;
  public showList = false;
  public mask: string;
  public initialCountry: CountryPhoneFormat;
  public readOnlyMode: boolean;
  public multiplePhoneInputModes = MultiplePhoneInputModes;
  public isShowOptionsDropdown = false;
  public phoneForm: UntypedFormGroup;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  constructor(
    private translate: TranslateService,
    private configs: ConfigsService,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private clipboardService: ClipboardService,
    private smsPopupService: SmsPopupService,
    private notificationService: NotifyService,
  ) {
    this.configs.phoneConfig$.subscribe(value => {
      this.countryPhoneFormats = value;
    });
  }

  ngOnInit(): void {
    if (this.translatableLabel) {
      this.translateLabel(this.translatableLabel);
    }
    if (this.isSelectedPhone) {
      this.phoneForm = this.formBuilder.group({
        selectedPhone: [{ value: this.phoneItemWithCode.phone }],
      });
    }
    if (this.countryPhoneFormats) {
      const countryPhoneFormat = this.countryPhoneFormats.find((item: CountryPhoneFormat, index: number) => {
        if (!this.phoneItemWithCode && index === 0) {
          this.initialCountry = item;
          return item;
        }
        return item.mobilePhoneCode === this.phoneItemWithCode.code;
      });
      this.selectCountry(countryPhoneFormat);
      if (this.initialCountry) {
        this.countryCode.emit(this.initialCountry.mobilePhoneCode);
      }
    }

    if (this.formControl) {
      if (!this.isSelectedPhone) {
        this.phoneValue.emit(this.formControl.value);

        this.formControl.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
          this.phoneValue.emit(value);
          this.cdRef.detectChanges();
        });
      } else {
        this.phoneValue.emit(this.phoneItemWithCode.phone);
        this.cdRef.detectChanges();
      }
    }
  }

  translateLabel(label: string): void {
    this.translate.get(label).subscribe(translatedLabel => {
      this.labelText = translatedLabel;
    });
  }

  get formControl(): AbstractControl {
    return this.formControlItem;
  }

  selectCountry(country: CountryPhoneFormat): void {
    if (!country) {
      return;
    }
    this.mask = country.mobilePhoneMask;
    this.selectedCountryItem = country;
    this.cdRef.markForCheck();
  }

  set selectedCountryItem(country: CountryPhoneFormat) {
    this.selectedCountry = country;
    this.countryCode.emit(country.mobilePhoneCode);
    this.close();
    this.cdRef.markForCheck();
  }

  setCursorPosition() {
    if (!this.formControl.value) {
      this.elRef.nativeElement.querySelector('input').setSelectionRange(1, 1);
      this.cdRef.detectChanges();
    }
  }

  open() {
    if (!this.readOnlyMode) {
      this.showList = true;
    }
  }

  close() {
    this.showList = false;
  }

  onFocus(phoneWithCountryCode: HTMLDivElement) {
    phoneWithCountryCode.classList.add('focused');
  }

  onBlur(phoneWithCountryCode: HTMLDivElement) {
    phoneWithCountryCode.classList.remove('focused');
  }

  openOptionsDropdown() {
    this.isShowOptionsDropdown = true;
  }

  removePhone() {
    if (this.initialFirstPhone && this.phoneItemWithCode.phone === this.initialFirstPhone.phone) {
      this.notificationService.create({
        type: NotifyTypeEnum.error,
        title: this.translate.instant('ERRORS.ERROR'),
        text: this.translate.instant('ERRORS.PHONES.CANT_DELETE_FIRST_PHONE'),
      });
      return;
    }
    this.removePhoneItem.emit();
    this.isShowOptionsDropdown = false;
  }

  editPhone() {
    this.readOnlyMode = false;
    this.isShowOptionsDropdown = false;
  }

  closeOptions() {
    this.isShowOptionsDropdown = false;
  }

  selectPhone() {
    this.selectedPhoneChanged.emit(this.phoneItemWithCode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.phoneItemWithCode &&
      changes.phoneItemWithCode.currentValue &&
      this.phoneForm &&
      !changes.phoneItemWithCode.isFirstChange()
    ) {
      this.phoneForm.patchValue({
        selectedPhone: this.phoneItemWithCode.phone,
      });
    }
  }

  copy() {
    this.clipboardService.copyFromContent(`${this.phoneItemWithCode.code}${this.phoneItemWithCode.phone}`);
  }

  openSmsModal() {
    this.smsPopupService.showSMSPopup(this.phoneItemWithCode, this.screenType);
  }
}
