import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, TourSelectionState } from './tour-selection.reducer';

// Feature Selector
export const selectChatState = createFeatureSelector<TourSelectionState>(FEATURE_KEY);

// Selectors
export const selectIsTourSelectionOpen = createSelector(
  selectChatState,
  (state: TourSelectionState) => state.isTourSelectionOpen,
);

export const selectCurrentTab = createSelector(
  selectChatState,
  (state: TourSelectionState) => state.currentTab,
);
