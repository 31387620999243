import { Action } from '@ngrx/store';
import { SalesFunnelCategory, SalesFunnelStage } from '../../modules/deals/modules/deals-list/deal-list';

export enum DealsListActionTypes {
  SetCurrentStage = '[Deals list] Deals list set current stage',
  SetCurrentCategory = '[Deals list] Deals list set current category',
  SetLastSelectedListItemId = '[Deals list] Deals list set last selected list item id',
  SetIsAllowShowOtherDeals = '[Deals list] Deals list set is allow show other deals',
  ToggleIsAllowShowOtherDeals = '[Deals list] Deals list toggle is allow show other deals',
  PostSaleDealListItemSetMaximized = '[Deals list] PostSale DealList Item SetMaximized',
  PostSaleDealListItemSetMinimized = '[Deals list] PostSale DealList Item SetMinimized',
  PostSaleDealListItemSetMinimizedAll = '[Deals list] PostSale DealList Item SetMinimized all',
}

export class DealsSetCurrentStage implements Action {
  readonly type = DealsListActionTypes.SetCurrentStage;
  constructor(public payload: SalesFunnelStage | null) {}
}

export class DealsSetCurrentCategory implements Action {
  readonly type = DealsListActionTypes.SetCurrentCategory;
  constructor(public payload: SalesFunnelCategory | null) {}
}

export class PostSaleDealListItemSetMaximized implements Action {
  readonly type = DealsListActionTypes.PostSaleDealListItemSetMaximized;
  constructor(public payload: number) {}
}
export class PostSaleDealListItemSetMinimized implements Action {
  readonly type = DealsListActionTypes.PostSaleDealListItemSetMinimized;
  constructor(public payload: number) {}
}
export class PostSaleDealListItemSetMinimizedAll implements Action {
  readonly type = DealsListActionTypes.PostSaleDealListItemSetMinimizedAll;
}

export class DealsSetLastSelectedListItemId implements Action {
  readonly type = DealsListActionTypes.SetLastSelectedListItemId;
  constructor(public payload: number) {}
}

export class DealsSetIsAllowShowOtherDeals implements Action {
  readonly type = DealsListActionTypes.SetIsAllowShowOtherDeals;
  constructor(public payload: boolean) {}
}
export class DealsToggleIsAllowShowOtherDeals implements Action {
  readonly type = DealsListActionTypes.ToggleIsAllowShowOtherDeals;
}

export type DealsListAction =
  | DealsSetCurrentStage
  | DealsSetCurrentCategory
  | DealsSetIsAllowShowOtherDeals
  | DealsToggleIsAllowShowOtherDeals
  | PostSaleDealListItemSetMaximized
  | PostSaleDealListItemSetMinimized
  | PostSaleDealListItemSetMinimizedAll
  | DealsSetLastSelectedListItemId;
