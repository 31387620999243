import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ManagerStateActions from './manager-state.actions';
import * as ManagerSipStateSelectors from './manager-state.selectors';
import { ManagerSipStateInterface } from './manager-state.state';

@Injectable()
export class ManagerStateFacade {
  managerSipState$ = this.store.pipe(select(ManagerSipStateSelectors.getManagerSipState));

  constructor(private store: Store<ManagerSipStateInterface>) {}

  setManagerSipState(payload: ManagerSipStateInterface) {
    this.store.dispatch(ManagerStateActions.managerSipState({ payload }));
  }
}
