import { Component, Input } from '@angular/core';
import { PhoneItem, WhatsappMessage } from '@api-clients/crm-api-client';
import { SearchRequest } from '@api-clients/crm-api-client/dist/models';
import { ChatTimelineFacade } from 'app/+state/chat-timeline/chat-timeline.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chat-timeline-footer',
  templateUrl: './chat-timeline-footer.component.html',
  styleUrls: ['./chat-timeline-footer.component.scss'],
})
export class ChatTimelineFooterComponent {
  @Input() lastSearchRequest: SearchRequest;
  @Input() crmCardId: number;
  @Input() dealId: number;
  @Input() dealStatus: number;
  @Input() currentPhoneOrChatId: PhoneItem | string;

  currentReplyMessage$: Observable<WhatsappMessage | null> = this.chatTimelineFacade.currentReplyMessage$;
  isScheduledMessagesListOpen$: Observable<boolean> = this.chatTimelineFacade.isScheduledMessagesListOpen$;

  constructor(private chatTimelineFacade: ChatTimelineFacade) {}
}
