import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DefaultResponse } from '@api-clients/api-client';
import { CrmTaskItem, CrmTaskType, PhoneItem } from '@api-clients/crm-api-client';
import { CrmTaskCloseReason, DealStage } from '@api-clients/crm-api-client/dist/models';
import { TranslatePipe } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { delay, filter, first, takeUntil, tap } from 'rxjs/operators';
import { PopupService } from '../../../../../../../../shared/services/popup-service.service';
import { POST_SALE_STATUS } from '../../../../../deals-list/deal-list';
import { CompleteDealTypeEnum } from '../../../../models/complete-deal-type.model';
import { CreateTaskModalPayload } from '../../../../models/create-task-modal.model';
import { IndicatorType } from '../../../../models/indicator-payload.model';
import { CrmTaskApiService } from '../../../../services/crm-task-api.service';
import { DealViewService } from '../../../../services/deal-view.service';
import { IndicatorEventService } from '../../../../services/indicator-event.service';
import { NotifyHelperService } from '../../../../services/notify-helper.service';
import { StepsId } from '../../../create-task-modal/create-task-modal/create-task-modal';
import { CreateTaskModalComponent } from '../../../create-task-modal/create-task-modal/create-task-modal.component';
import { CreateTaskService } from '../../../create-task-modal/services/create-task.service';
import { CurrentStageEnum } from '../../../sales-funnel-stages/sales-funnel-stages';
import { TaskAlertType } from '../../task-alert.model';

@Component({
  selector: 'app-task-alert',
  templateUrl: './task-alert.component.html',
  styleUrls: ['./task-alert.component.scss'],
})
export class TaskAlertComponent implements OnDestroy, OnInit, OnChanges {
  @Input() task: CrmTaskItem;
  @Input() crmCardId: number;
  @Input() dealId: number;
  @Input() clientAvatarUrl: string;
  @Input() clientName: string;
  @Input() dealStage: string | DealStage;
  @Input() phoneItem: PhoneItem;
  @Input() dealStatus: number;
  @Input() isMine: boolean;
  @Input() isFirst: boolean;
  @Input() isSingle: boolean;
  @Input() totalTasks: number;
  @Input() isExpandedFromParent?: boolean;
  @Output() expandToggle = new EventEmitter<void>();
  public formattedDate: string;
  public formattedTime: string;
  public isHighlighted = false;
  public isCommentOpened = false;
  // TODO: REFACTOR
  public NEW_CLIENT_STATUS = 0;
  private destroy$ = new Subject<void>();
  constructor(
    private popupService: PopupService,
    private crmTaskApiService: CrmTaskApiService,
    private notifyService: NotifyHelperService,
    private translatePipe: TranslatePipe,
    private dealViewService: DealViewService,
    private createTaskService: CreateTaskService,
    private indicatorEventService: IndicatorEventService,
    private datePipe: DatePipe,
  ) {
    this.popupService.popupOpenSource.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (!status.isOpen && status.closedByBackdropClick) {
        this.createTaskService.reset();
      }
    });
  }

  showCreateTaskModalPopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  showReassignPopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.REASSIGN);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  showRecallPopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.RECALL);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  showSendSelectionPopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.NEXT_STAGE);
    this.createTaskService.setCheckedStage(CurrentStageEnum.sendOffer);
    this.createTaskService.setCheckedTaskType(CrmTaskType.Whatsapp);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  showNextStagePopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.NEXT_STAGE);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  showAppointmentPopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.NEXT_STAGE);
    this.createTaskService.setCheckedStage(CurrentStageEnum.meeting);
    this.createTaskService.setCheckedTaskType(CrmTaskType.Meeting);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  showNonConversionPopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.COMPLETE);
    this.createTaskService.setCompleteType(CompleteDealTypeEnum.MarkAsNonConversion);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  showCompleteDealPopup(task?: CrmTaskItem) {
    this.popupService.closeAllModals();
    this.createTaskService.setStep(StepsId.COMPLETE);
    this.createTaskService.setCompleteType(CompleteDealTypeEnum.Complete);
    const payload: CreateTaskModalPayload = {
      task,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };

    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }

  toggleContent() {
    this.expandToggle.emit();
  }

  getTaskAlertType(): TaskAlertType {
    if (
      this.task.type !== CrmTaskType.Urgent &&
      this.task.type !== CrmTaskType.Missed &&
      this.dealStatus === POST_SALE_STATUS
    ) {
      return TaskAlertType.AdditionalDisabled;
    }

    if (this.isFirst) {
      return TaskAlertType.Main;
    }
    return TaskAlertType.Additional;
  }

  getIsUrgentOrMissed(): boolean {
    return this.task.type === CrmTaskType.Urgent || this.task.type === CrmTaskType.Missed;
  }

  onCheckMarked() {
    if (this.getIsUrgentOrMissed() && this.dealStatus === POST_SALE_STATUS) {
      this.crmTaskApiService
        .crmTaskClose({ id: this.task.id, reason: CrmTaskCloseReason.TaskComplete })
        .pipe(first())
        .subscribe((res: DefaultResponse) => {
          if (res.success) {
            this.notifyService.notify(
              this.translatePipe.transform('PAGES.DEALS.SALES_FUNNEL.CREATE_TASK_MODAL.RESULT.complete'),
              true,
            );
          }
          this.dealViewService.refreshDealView();
        });

      return;
    }

    this.showCreateTaskModalPopup(this.task);
  }

  highlight() {
    this.isHighlighted = true;
  }

  ngOnInit() {
    this.indicatorEventService.indicatorEventObservable
      .pipe(
        takeUntil(this.destroy$),
        filter(
          type =>
            type === IndicatorType.PendingTask ||
            type === IndicatorType.UrgentTask ||
            type === IndicatorType.TaskCompletionReminder,
        ),
        tap(() => this.highlight()),
        delay(5000),
        tap(() => this.resetAnimation()),
      )
      .subscribe();
    this.formatDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.task) {
      this.formatDate();
    }
  }

  formatDate() {
    this.formattedDate = this.datePipe.transform(this.task.date, 'd MMM').replace('.', '') || '';
    const timeParts = this.task.time.split(':');
    this.formattedTime = `${timeParts[0]}:${timeParts[1]}`;
  }

  resetAnimation() {
    this.isHighlighted = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
