import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { CrmCardListPostSaleItem } from '@api-clients/crm-api-client/models/crm-card-list-post-sale-item';
import { CurrentStageEnum } from '../deal-view/modules/sales-funnel-stages/sales-funnel-stages';

export enum SalesFunnelCategoryEnum {
  withoutCategory = 'withoutCategory',
  inWork = 'inWork',
  postponed = 'postponed',
  postSale = 'postSale',
}

export class SalesFunnelCategory {
  name: SalesFunnelCategoryEnum;
  stages: SalesFunnelStage[];
  status?: number;
  selectable?: boolean;
  count_all?: number;
  count_forgotten?: number;
  hasNewMessages?: boolean;
}

export class SalesFunnelStage {
  name: CurrentStageEnum | ExperimentalDaysStages;
  count?: number;
  count_deals_with_expired_tasks?: number;
  count_today_forgotten_no_task?: number;
  count_today?: number;
  count_new?: number;
  deals?: CrmCardViewItem[];
  dealsPostSale?: CrmCardListPostSaleItem[];
  status?: number;
  textColor?: string;
  labelColor?: string;
  hasNewMessages?: boolean;
}

export enum ExperimentalDaysStages {
  firstDay = 'stage1',
  secondDay = 'stage2',
  thirdDay = 'stage3',
  fourthToSeventhDay = 'stage4',
  moreThanSevenDays = 'stage5',
}

export const DefaultCategories: SalesFunnelCategory[] = [
  {
    name: SalesFunnelCategoryEnum.withoutCategory,
    stages: [
      {
        name: CurrentStageEnum.missed,
        deals: [],
        status: 0,
        textColor: '#FDA033',
        labelColor: '#FDA033',
      },
      {
        name: CurrentStageEnum.coldTouch,
        deals: [],
        status: 0,
        labelColor: '#3396F3',
      },
      {
        name: CurrentStageEnum.new,
        deals: [],
        status: 0,
        labelColor: '#3396F3',
      },
    ],
  },
  {
    name: SalesFunnelCategoryEnum.inWork,
    stages: [
      {
        name: CurrentStageEnum.sendOffer,
        deals: [],
        status: 1,
        labelColor: '#ED6980',
      },
      {
        name: CurrentStageEnum.feedback,
        deals: [],
        status: 1,
        labelColor: '#FC8800',
      },
      {
        name: ExperimentalDaysStages.firstDay,
        deals: [],
        status: 1,
        labelColor: '#FC8800',
      },
      {
        name: ExperimentalDaysStages.secondDay,
        deals: [],
        status: 1,
        labelColor: '#FC8800',
      },
      {
        name: ExperimentalDaysStages.thirdDay,
        deals: [],
        status: 1,
        labelColor: '#FC8800',
      },
      {
        name: ExperimentalDaysStages.fourthToSeventhDay,
        deals: [],
        status: 1,
        labelColor: '#FC8800',
      },
      {
        name: ExperimentalDaysStages.moreThanSevenDays,
        deals: [],
        status: 1,
        labelColor: '#FC8800',
      },
      {
        name: CurrentStageEnum.meeting,
        deals: [],
        status: 1,
        labelColor: '#9BD029',
      },
      {
        name: CurrentStageEnum.finishingTouch,
        deals: [],
        status: 1,
        labelColor: '#C85DC8',
      },
      {
        name: CurrentStageEnum.autoTouch,
        deals: [],
        status: 0,
        labelColor: '#3396F3',
      },
      {
        name: CurrentStageEnum.readyForBooking,
        deals: [],
        status: 1,
        labelColor: '#FF6347',
      },
    ],
    selectable: true,
  },
  {
    name: SalesFunnelCategoryEnum.postponed,
    stages: [],
    selectable: true,
  },
  {
    name: SalesFunnelCategoryEnum.postSale,
    stages: [
      {
        name: CurrentStageEnum.waitConfirm,
        dealsPostSale: [],
        status: 2,
        labelColor: '#48C262',
      },
      {
        name: CurrentStageEnum.waitPayment,
        dealsPostSale: [],
        status: 2,
        labelColor: '#48C262',
      },
      {
        name: CurrentStageEnum.waitDocs,
        dealsPostSale: [],
        status: 2,
        labelColor: '#48C262',
      },
      {
        name: CurrentStageEnum.waitFlight,
        dealsPostSale: [],
        status: 2,
        labelColor: '#48C262',
      },
      {
        name: CurrentStageEnum.onRest,
        dealsPostSale: [],
        status: 2,
        labelColor: '#48C262',
      },
      {
        name: CurrentStageEnum.review,
        dealsPostSale: [],
        status: 2,
        labelColor: '#48C262',
      },
    ],
  },
];

export enum Readiness {
  all = 0,
  cold = 1,
  warm = 2,
  hot = 3,
}

export enum NeedConnectType {
  urgentOrMissed = 1,
  coldTouch = 2,
}

export const POST_SALE_STATUS = 2;

export enum AvatarMode {
  dealView = 'dealView',
  dealList = 'dealList',
  postSaleList = 'postSaleList',
}

export enum DealListFilterKeys {
  IS_FORGOTTEN = 'isForgotten',
  IS_NEARBY_DATES = 'isNearbyDates',
  IS_RETURN_TOURIST = 'isReturnTourist',
  IS_MOBILE_APP_INSTALLED = 'isMobileAppInstalled',
  IS_HIGH_CHECK = 'isHighCheck',
}

export type FilterIds = 'forgotten' | 'nearby_dates' | 'return_tourists' | 'has_mobile_app' | 'high_check';

export enum SortType {
  LAST_TOUCH = 'last_touch',
  ZENMODE = 'zenmode',
}

export interface SortOption {
  id: SortType;
  label: string;
}
