import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultResponse, MarkAsAudioReviewRequest } from '@api-clients/crm-api-client';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';
import { ApiResponse } from '../../../../../../backend/src/models/models';

@Injectable({
  providedIn: 'root',
})
export class CallListService {
  constructor(private http: HttpClient) {}

  getCalls(date: string) {
    return this.http.get(`${AppConfig.apiUrl}/call/history?date=${date}`).pipe(apiResponsePipe);
  }

  getTourPackagesForMarkAsAudioReview() {
    return this.http.get(`${AppConfig.apiUrl}/tour-package/get-audio-review`).pipe(apiResponsePipe);
  }

  markAsAudioReview(body: MarkAsAudioReviewRequest) {
    return this.http
      .post<ApiResponse<DefaultResponse>>(`${AppConfig.apiUrl}/crm-task/mark-as-audio-review`, body)
      .pipe(apiResponsePipe);
  }
}
