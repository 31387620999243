import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { CHAT_FILTER_SET } from '../../../../core/services/amplitude/amplitudeEvents';
import { FilterListEnum, FilterWithCount } from '../../interfaces/chat-timeline.interface';
import { ChatTimelineFilterService } from './services/chat-timeline-filter.service';

@Component({
  selector: 'app-chat-time-line-filters',
  templateUrl: './chat-time-line-filters.component.html',
  styleUrls: ['./chat-time-line-filters.component.scss'],
  animations: [
    trigger('filtersInOut', [
      state('filtersIn', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateX(25%)' }),
        animate('300ms ease-in'),
      ]),
      transition('* => void', [
        animate('300ms ease-out', style({ opacity: 0, transform: 'translateX(25%)' })),
      ]),
    ]),
  ],
})
export class ChatTimeLineFiltersComponent implements OnInit, OnDestroy {
  @Input() availableFilters: string[];
  @Input() filterListWithCount: FilterWithCount[];
  public FILTER_LIST_ARRAY = Object.keys(FilterListEnum);
  public activeFiltersList = [];
  public whatsappFilterSetsOnSub: Subscription;
  public screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public showFilters = false;
  constructor(
    private chatTimelineFilterService: ChatTimelineFilterService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  ngOnInit(): void {
    this.activeFiltersList.push(FilterListEnum.all);
    this.whatsappFilterSetsOnSub = this.chatTimelineFilterService.whatsappFilterSetsOn$.subscribe(() => {
      if (this.activeFiltersList.length === 0 || this.activeFiltersList.includes(FilterListEnum.all)) {
        return;
      }
      if (!this.activeFiltersList.includes(FilterListEnum.message)) {
        this.interactFilter(FilterListEnum.message);
      }
    });
  }

  interactFilter(filter: string) {
    if (!this.availableFilters.includes(filter)) {
      return;
    }
    this.amplitudeTrackService.trackEvent(CHAT_FILTER_SET, {
      filter,
      screenType: this.screenType,
    });

    this.activeFiltersList = [filter];

    this.chatTimelineFilterService.setChatTimelineFilters(this.activeFiltersList);
  }

  ngOnDestroy(): void {
    if (this.whatsappFilterSetsOnSub) {
      this.whatsappFilterSetsOnSub.unsubscribe();
    }
  }

  getFilterCount(filter: string) {
    const filterWithCount = this.filterListWithCount.find(item => item.filter === filter);
    return filterWithCount ? filterWithCount.count : 0;
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }
}
