import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtmlEntities',
})
export class RemoveHtmlEntitiesPipe implements PipeTransform {
  transform(text: string): unknown {
    return text.replace(/&nbsp;/g, ' ');
  }
}
