@if (!loadingFormParams()) {
  <div class="title-container">
    <h3 class="title">{{ 'PAGES.DEALS.TABS.TAB_FAVORITE_HOTELS_CONTENT.TITLE' | translate }}</h3>
    <div class="form-params-control">
      <button type="button"
              class="btn-search-form"
              matTooltip="Форма поиска"
              (click)="openSearchForm()"></button>
    </div>
  </div>

  <div class="search-progress-container">
    <app-line-progress *ngIf="searchInProgress$ | async" [animationDuration]="'30s'" />
  </div>

  @if (!hasEmployeeToken()) {
    <div class="no-employee-token">
      <app-alert-label [type]="hasEmployeeTokenAlertType">
        Для корректной работы поиска необходимо повторно авторизоваться в Hermes
      </app-alert-label>
    </div>
  }

  @if (showSearchStartedContainer()) {
    <div class="skeleton-container">
      <app-favorite-hotels-tab-skeleton />
      <app-favorite-hotels-tab-skeleton />
      <app-favorite-hotels-tab-skeleton />
    </div>
  }

  <app-search-tours-filters-and-sort [class.hide]="showSearchStartedContainer()"
                                     [searchResultVisible]="showSearchResult()"
                                     (selectedSort)="updateSort($event)"
                                     (onSearchUpdated)="onSearchUpdated($event)" />

  @if (showSearchResult()) {
    <app-favorites-hotels-list [onlyHotelsPreview]="false"
                               [initSearchRequest]="lastSearchInitRequest"
                               [toursCount]="toursCount()"
                               [selectedCountry]="selectedCountry()"
                               [hotels]="visibleHotels()" />
  }

  @if (showPreviewHotels() && !(loadingHotelsList$ | async) && !(searchInProgress$ | async)) {
    <div class="template-hotels-container">
      <app-favorites-hotels-list [onlyHotelsPreview]="true"
                                 [selectedCountry]="selectedCountry()"
                                 [hotels]="previewHotels()" />
    </div>
  }

  @if (loadingHotelsList$ | async) {
    <div class="skeleton-countries-templates">
      <app-favorite-hotels-tab-skeleton />
    </div>
    <div class="skeleton-container">
      <app-favorite-hotels-tab-skeleton />
      <app-favorite-hotels-tab-skeleton />
      <app-favorite-hotels-tab-skeleton />
    </div>
  }

  @if (showToursNotFound()) {
    <div class="tours-not-found">
      <app-alert-label>
        <p>Туры не найдены, попробуйте изменить параметры поиска</p>
      </app-alert-label>
    </div>
  }
} @else {
  <div class="title-container">
    <h3 class="title">{{ 'PAGES.DEALS.TABS.TAB_FAVORITE_HOTELS_CONTENT.TITLE' | translate }}</h3>
  </div>
  <div class="skeleton-countries-templates">
    <app-favorite-hotels-tab-skeleton />
  </div>
  <div class="skeleton-container">
    <app-favorite-hotels-tab-skeleton />
    <app-favorite-hotels-tab-skeleton />
    <app-favorite-hotels-tab-skeleton />
  </div>
}

