import { Injectable } from '@angular/core';
import {
  CrmCardListItem,
  CrmCardListPostSaleItem,
  CrmCardListPostSaleTourPackage,
  CrmCardViewItem,
} from '@api-clients/crm-api-client';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { DealsListFacade } from '../../../../../+state/deals-list/deals-list.facade';
import { DealsFacade } from '../../../../../+state/deals/deals.facade';
import { CurrentStageEnum } from '../../deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import {
  DefaultCategories,
  NeedConnectType,
  POST_SALE_STATUS,
  SalesFunnelCategory,
  SalesFunnelCategoryEnum,
  SalesFunnelStage,
} from '../deal-list';
import { FeatureToggleService } from '../modules/deals-list-content/helpers/feature-toggle.service';
import { DealListService } from './deal-list.service';

// REFACTOR!!!

const NEW_CLIENT_STATUS = 0;
const HAS_NEW_MESSAGES_STATUS = 1;

@Injectable({ providedIn: 'root' })
export class DealFlowService {
  public categories$: Observable<SalesFunnelCategory[]>;
  public regularDeals$: Observable<CrmCardViewItem[]>;
  public postSaleDeals$: Observable<CrmCardListPostSaleItem[]>;
  public selectedCategory$: Observable<SalesFunnelCategory | null>;
  public selectedStage$: Observable<SalesFunnelStage | null>;
  public isSelectedPostSale$: Observable<boolean>;
  private isInitialized = false;
  private isNewUiEnabled = false;

  constructor(
    private dealsFacade: DealsFacade,
    private dealsListFacade: DealsListFacade,
    private dealListService: DealListService,
    private featureToggleService: FeatureToggleService,
  ) {
    this.initializeStreams();
    this.featureToggleService.shouldDisplayNewFeature$
      .pipe(first())
      .subscribe(isEnabled => (this.isNewUiEnabled = isEnabled));
  }

  private initializeStreams(): void {
    const combinedDeals$ = combineLatest([
      this.dealsFacade.dealsList$.pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      ),
      this.dealsFacade.dealsListPostSale$.pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      ),
    ]);

    this.categories$ = combinedDeals$.pipe(
      map(([deals, postSalesDeals]) => this.mapDealsToCategories(deals, postSalesDeals)),
      tap(categories => {
        if (!this.isInitialized) {
          this.dealsListFacade.setCurrentStage(categories[0].stages[0]);
          this.isInitialized = true;
        }
      }),
    );

    this.selectedCategory$ = this.dealsListFacade.currentCategory$.pipe(distinctUntilChanged());
    this.selectedStage$ = this.dealsListFacade.currentStage$.pipe(distinctUntilChanged());

    this.isSelectedPostSale$ = combineLatest([this.selectedStage$, this.selectedCategory$]).pipe(
      map(([stage, category]) => {
        if (stage) {
          return this.isPostSale(stage);
        }
        if (category) {
          return category.stages.some(categoryStage => this.isPostSale(categoryStage));
        }
        return false;
      }),
      distinctUntilChanged(),
    );

    // Regular deals stream
    this.regularDeals$ = combineLatest([
      this.dealsFacade.dealsList$,
      this.selectedCategory$,
      this.selectedStage$,
    ]).pipe(
      map(([deals, selectedCategory, selectedStage]) => {
        return this.filterDeals(deals, selectedCategory, selectedStage, false) as CrmCardViewItem[];
      }),
      distinctUntilChanged(),
    );

    // Post-sale deals stream
    this.postSaleDeals$ = combineLatest([
      this.dealsFacade.dealsListPostSale$,
      this.selectedCategory$,
      this.selectedStage$,
    ]).pipe(
      filter(([, , selectedStage]) => this.isPostSale(selectedStage)),
      map(
        ([postSaleDeals, selectedCategory, selectedStage]) =>
          this.filterDeals(postSaleDeals, selectedCategory, selectedStage, true) as CrmCardListPostSaleItem[],
      ),
      distinctUntilChanged(),
    );
  }

  private isPostSale(stage: SalesFunnelStage | null): boolean {
    return stage && stage.status === POST_SALE_STATUS && stage.name !== CurrentStageEnum.missed;
  }

  private filterDeals(
    deals: (CrmCardViewItem | CrmCardListPostSaleItem)[],
    category: SalesFunnelCategory | null,
    stage: SalesFunnelStage | null,
    isPostSale: boolean,
  ): (CrmCardViewItem | CrmCardListPostSaleItem)[] {
    if (stage) {
      return deals.filter(deal =>
        isPostSale
          ? this.isPostSaleDealItem(deal) && deal.dealListItem.deal.stage === stage.name
          : this.isRegularDealItem(deal) &&
            (!this.isNewUiEnabled || !this.isDealPostponed(deal)) &&
            this.matchVirtualStage(deal, stage),
      );
    }
    if (category) {
      switch (category.name) {
        case SalesFunnelCategoryEnum.inWork:
          return deals.filter(
            deal =>
              this.isRegularDealItem(deal) &&
              (!this.isNewUiEnabled || !this.isDealPostponed(deal)) &&
              category.stages.some(categoryStage => categoryStage.name === deal.experimentalData?.daysStage),
          );
        case SalesFunnelCategoryEnum.postponed:
          return deals.filter(deal => this.isRegularDealItem(deal) && this.isDealPostponed(deal));
        default:
          return deals.filter(deal =>
            isPostSale
              ? this.isPostSaleDealItem(deal) &&
                category.stages.some(categoryStage => deal.dealListItem.deal.stage === categoryStage.name)
              : this.isRegularDealItem(deal) &&
                category.stages.some(categoryStage => this.matchVirtualStage(deal, categoryStage)),
          );
      }
    }
    return [];
  }

  private matchVirtualStage(deal: CrmCardViewItem, stage: SalesFunnelStage): boolean {
    switch (stage.name) {
      case CurrentStageEnum.missed:
        return this.getIsMissedOrUrgent(deal);
      case CurrentStageEnum.coldTouch:
        return this.getIsColdTouch(deal);
      case CurrentStageEnum.new:
        return this.getIsNewClient(deal);
      default:
        return deal.deal.stage === stage.name || deal.experimentalData?.daysStage === stage.name;
    }
  }

  private isRegularDealItem(deal: any): deal is CrmCardViewItem {
    return (deal as CrmCardViewItem).deal !== undefined;
  }

  private isPostSaleDealItem(deal: any): deal is CrmCardListPostSaleItem {
    return (deal as CrmCardListPostSaleItem).dealListItem !== undefined;
  }

  private mapDealsToCategories(
    deals: CrmCardViewItem[],
    postSalesDeals: CrmCardListPostSaleItem[],
  ): SalesFunnelCategory[] {
    const updatedCategories: SalesFunnelCategory[] = this.initializeCategories();

    deals.forEach(deal => {
      this.processDeal(deal, updatedCategories);
    });

    postSalesDeals.forEach(deal => {
      this.processPostSalesDeal(deal, updatedCategories);
    });

    return updatedCategories;
  }

  private initializeCategories(): SalesFunnelCategory[] {
    const categories: SalesFunnelCategory[] = JSON.parse(JSON.stringify(DefaultCategories));
    categories.forEach(category => {
      category.count_all = 0;
      category.count_forgotten = 0;
      category.stages.forEach(stage => {
        stage.count = 0;
        stage.count_deals_with_expired_tasks = 0;
        stage.count_today_forgotten_no_task = 0;
      });
    });

    return categories;
  }

  private processDeal(deal: CrmCardViewItem, updatedCategories: SalesFunnelCategory[]): void {
    if (this.isNewUiEnabled) {
      this.processDealForNewUi(deal, updatedCategories);
    } else {
      this.processDealForOldUi(deal, updatedCategories);
    }
  }

  private processDealForNewUi(deal: CrmCardViewItem, updatedCategories: SalesFunnelCategory[]): void {
    this.updateSpecialCounts(deal, updatedCategories);

    const categoryIndex = this.getCategoryIndex(deal, updatedCategories);
    if (categoryIndex === -1) {
      return;
    }

    const postponedCategoryIndex = this.getPostponedCategoryIndex(deal, updatedCategories);
    if (postponedCategoryIndex === -1) {
      return;
    }

    const isDealPostponed = this.isDealPostponed(deal);

    if (isDealPostponed) {
      updatedCategories[postponedCategoryIndex].count_all += 1;

      const hasNewMessages = this.hasNewMessages(deal);

      if (hasNewMessages) {
        updatedCategories[postponedCategoryIndex].hasNewMessages = true;
      }
    }

    if (!isDealPostponed) {
      const category = updatedCategories[categoryIndex];
      const stage = category.stages[this.getStageIndexWithExclude(deal, category)];
      const experimentalStage = category.stages[this.getExperimentalStageIndex(deal, category)];

      const context = {
        deal,
        category,
        stage,
        experimentalStage,
      };

      this.updateStageCountsForNewUi(context);
    }
  }

  private processDealForOldUi(deal: CrmCardViewItem, updatedCategories: SalesFunnelCategory[]): void {
    this.updateSpecialCounts(deal, updatedCategories);

    const categoryIndex = this.getCategoryIndex(deal, updatedCategories);

    if (categoryIndex === -1) {
      return;
    }

    const category = updatedCategories[categoryIndex];
    const stageIndex = this.getStageIndex(deal, category);

    if (stageIndex === -1) {
      return;
    }

    const stage = category.stages[stageIndex];

    const context = {
      deal,
      stage,
    };

    this.updateStageCountsForOldUi(context);
  }

  private getCategoryIndex(deal: CrmCardViewItem, updatedCategories: SalesFunnelCategory[]) {
    return updatedCategories.findIndex(category =>
      category.stages.some(stage => stage.name === deal.deal.stage && deal.deal.status !== NEW_CLIENT_STATUS),
    );
  }

  private getPostponedCategoryIndex(deal: CrmCardViewItem, updatedCategories: SalesFunnelCategory[]) {
    return updatedCategories.findIndex(category => category.name === SalesFunnelCategoryEnum.postponed);
  }

  private getStageIndex(deal: CrmCardViewItem, category: SalesFunnelCategory): number {
    return category.stages.findIndex(stage => stage.name === deal.deal.stage);
  }

  private getStageIndexWithExclude(deal: CrmCardViewItem, category: SalesFunnelCategory): number {
    return category.stages.findIndex(
      stage => stage.name !== CurrentStageEnum.autoTouch && stage.name === deal.deal.stage,
    );
  }

  private getExperimentalStageIndex(deal: CrmCardViewItem, category: SalesFunnelCategory): number {
    return category.stages.findIndex(stage => stage.name === deal.experimentalData?.daysStage);
  }

  private updateStageCountsForNewUi(context: {
    deal: CrmCardViewItem;
    category: SalesFunnelCategory;
    stage: SalesFunnelStage;
    experimentalStage: SalesFunnelStage;
  }): void {
    const { deal, category, stage, experimentalStage } = context;
    const hasNewMessages = this.hasNewMessages(deal);
    const isDealNextTaskMissing = this.getIsDealNextTaskMissing(deal);
    const isDealForgotten = this.getIsDealForgotten(deal);
    const isDealToday = this.getIsTodayDeal(deal);

    if (stage) {
      stage.count += 1;
      category.count_all += 1;

      if (isDealForgotten) {
        category.count_forgotten += 1;
      }

      if (hasNewMessages) {
        stage.hasNewMessages = true;
        category.hasNewMessages = true;
      }

      if (isDealToday || isDealForgotten || isDealNextTaskMissing) {
        stage.count_today_forgotten_no_task += 1;

        if (experimentalStage) {
          experimentalStage.count_today_forgotten_no_task += 1;
        }
      }
    }

    if (experimentalStage) {
      experimentalStage.count += 1;

      if (hasNewMessages) {
        experimentalStage.hasNewMessages = true;
      }
    }
  }

  private updateStageCountsForOldUi(context: { deal: CrmCardViewItem; stage: SalesFunnelStage }): void {
    const { deal, stage } = context;

    const hasNewMessages = this.hasNewMessages(deal);
    const isDealNextTaskMissing = this.getIsDealNextTaskMissing(deal);
    const isDealNextTaskExpired = this.getIsDealNextTaskExpired(deal);

    if (hasNewMessages) {
      stage.hasNewMessages = true;
    }

    if (isDealNextTaskExpired || isDealNextTaskMissing) {
      stage.count_deals_with_expired_tasks += 1;
    }

    stage.count += 1;
  }

  private updateSpecialCounts(deal: CrmCardViewItem, updatedCategories: SalesFunnelCategory[]): void {
    if (this.getIsMissedOrUrgent(deal)) {
      updatedCategories[0].stages[0].count += 1;
    }

    if (this.getIsColdTouch(deal)) {
      updatedCategories[0].stages[1].count += 1;
    }

    if (this.getIsNewClient(deal)) {
      updatedCategories[0].stages[2].count += 1;
    }
  }

  private processPostSalesDeal(
    deal: CrmCardListPostSaleItem,
    updatedCategories: SalesFunnelCategory[],
  ): void {
    deal.tourPackages.forEach(tourPackage => {
      const categoryIndex = updatedCategories.findIndex(category =>
        category.stages.some(stage => (stage?.name as string) === (tourPackage.stage as string)),
      );
      if (categoryIndex !== -1) {
        const stageIndex = updatedCategories[categoryIndex].stages.findIndex(
          stage => (stage?.name as string) === (tourPackage.stage as string),
        );
        if (stageIndex !== -1) {
          const [firstTourPackage] = deal.tourPackages || null;
          const isTodayDeal = this.isTodayDealPostSale(firstTourPackage);
          if (isTodayDeal) {
            updatedCategories[categoryIndex].stages[stageIndex].count_today =
              updatedCategories[categoryIndex].stages[stageIndex].count_today + 1 || 1;
          }
          updatedCategories[categoryIndex].stages[stageIndex].count =
            updatedCategories[categoryIndex].stages[stageIndex].count + 1 || 1;
        }
      }
    });
  }

  private getIsTodayDeal(deal: CrmCardListItem): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const taskDate = deal.nextTask?.date ? new Date(deal.nextTask.date) : null;
    taskDate?.setHours(0, 0, 0, 0);

    return today.getTime() >= taskDate?.getTime();
  }

  private getIsDealForgotten(deal: CrmCardViewItem): boolean {
    return !!deal?.isForgotten;
  }

  private isDealPostponed(deal: CrmCardViewItem): boolean {
    return !!deal?.experimentalData?.isPostponedFolder;
  }

  private getIsDealNextTaskMissing(deal: CrmCardViewItem): boolean {
    return !deal.nextTask;
  }

  private getIsDealNextTaskExpired(deal: CrmCardViewItem) {
    return !!deal?.nextTask?.isExpired;
  }

  private getIsMissedOrUrgent(crmCardViewItem: CrmCardListItem): boolean {
    if (crmCardViewItem.deal.status === NEW_CLIENT_STATUS) {
      return false;
    }
    return crmCardViewItem.needConnectType === NeedConnectType.urgentOrMissed;
  }

  private getIsColdTouch(crmCardViewItem: CrmCardListItem): boolean {
    return !!crmCardViewItem.isColdConnectFolder;
  }

  private getIsNewClient(crmCardViewItem: CrmCardListItem): boolean {
    if (crmCardViewItem.needConnectType === NeedConnectType.coldTouch) {
      return false;
    }
    if (this.getIsColdTouch(crmCardViewItem)) {
      return false;
    }
    return crmCardViewItem.deal.status === NEW_CLIENT_STATUS;
  }

  private isTodayDealPostSale(deal: CrmCardListPostSaleTourPackage): boolean {
    if (deal) {
      return this.dealListService.isTodayDealPostSale(deal);
    }
    return false;
  }

  private hasNewMessages(deal: CrmCardListItem) {
    return deal.deal?.conversationStatus === HAS_NEW_MESSAGES_STATUS;
  }
}
