import { Component, Input } from '@angular/core';
import { ClientStatusIcon } from './client-status-icon';

@Component({
  selector: 'app-client-status-icon',
  templateUrl: './client-status-icon.component.html',
  styleUrls: ['./client-status-icon.component.scss'],
})
export class ClientStatusIconComponent {
  @Input() icon = ClientStatusIcon;
}
