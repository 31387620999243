<div>
  <div class="container" [ngClass]="{ highlighted: isHighlighted }" *ngIf="task">
    <div class="task-and-comment">
      <app-task-comment [taskComment]="task.managerComment" [speechBubbleBottomLeft]="true"></app-task-comment>
      <div class="right-col">
        <div class="task">
          <span class="task-name">{{
            'PAGES.DEALS.CRM_TASK_TYPES_WITH_POST_SALE.' + task.type | translate
          }}</span>
          <div class="task-due-date">
            <span>{{ formattedDate }}</span>
            <span>{{ formattedTime }}</span>
          </div>
        </div>
        <button
          (click)="closeMissedOrUrgentTask()"
          type="button"
          class="btn secondary subtle --close-task"
        >
          Закрыть срочный
        </button>
      </div>
    </div>
  </div>
</div>
