import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ChatScrollerService } from '../services/chat-scroller.service';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appContentAdjust]',
  standalone: true,
})
export class ContentAdjustDirective implements AfterViewInit, OnDestroy {
  @Input() mainContentSelector: string;
  @Input() headerSelector: string;
  @Input() footerSelector: string;
  @Input() autoScrollBottom? = false;
  @Output() initialScrollDone = new EventEmitter<void>();

  private mainContent: HTMLElement;
  private header: HTMLElement;
  private footer: HTMLElement;
  private resizeObserver: ResizeObserver;
  private mutationObserver: MutationObserver;
  private isInitialScrollDone = false;

  private destroy$ = new Subject<void>();

  constructor(private el: ElementRef<HTMLElement>, private chatScrollerService: ChatScrollerService) {}

  ngAfterViewInit(): void {
    this.initializeElements();
    this.initResizeObserver();

    if (this.autoScrollBottom) {
      this.initMutationObserver();
    } else {
      this.isInitialScrollDone = true;
      this.initialScrollDone.emit();
    }

    this.initUserSendMessageSubscription();

    this.adjustHeight();
  }

  ngOnDestroy(): void {
    this.disconnectObservers();
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.adjustHeight();
  }

  private initializeElements(): void {
    this.mainContent = document.querySelector(this.mainContentSelector);
    this.header = document.querySelector(this.headerSelector);
    this.footer = document.querySelector(this.footerSelector);
  }

  private initResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.adjustHeight();
    });

    [this.header, this.footer].forEach(element => {
      if (element) {
        this.resizeObserver.observe(element);
      }
    });
  }

  private initMutationObserver(): void {
    const contentScroller = this.el.nativeElement;

    this.mutationObserver = new MutationObserver(() => {
      this.maybeScrollToBottom(contentScroller);
    });

    this.mutationObserver.observe(contentScroller, {
      childList: true,
      subtree: true,
    });
  }

  private initUserSendMessageSubscription() {
    this.chatScrollerService
      .needToScrollBottom()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.scrollToBottom(this.el.nativeElement, true);
      });
  }

  private adjustHeight(): void {
    if (this.header && this.footer) {
      const totalHeight = this.mainContent.offsetHeight;
      const headerHeight = this.header.offsetHeight;
      const footerHeight = this.footer.offsetHeight;
      const availableHeight = totalHeight - headerHeight - footerHeight;

      const contentScroller = this.el.nativeElement;
      contentScroller.style.height = `${availableHeight}px`;
      contentScroller.style.overflowY = 'auto';
    }
  }

  private maybeScrollToBottom(scroller: HTMLElement): void {
    const shouldScroll = !this.isInitialScrollDone || this.isUserAtBottom(scroller);

    if (shouldScroll) {
      setTimeout(() => {
        this.scrollToBottom(scroller);
        this.isInitialScrollDone = true;
        this.initialScrollDone.emit();
      }, 100);
    }
  }

  private isUserAtBottom(scroller: HTMLElement): boolean {
    // TODO: change to last message height
    const threshold = 80;
    return scroller.scrollHeight - (scroller.scrollTop + scroller.clientHeight) < threshold;
  }

  private scrollToBottom(scroller: HTMLElement, animated = false): void {
    if (!scroller) {
      console.warn('Scroller element is not defined');
      return;
    }

    const scrollOptions: ScrollToOptions = {
      top: scroller.scrollHeight,
      behavior: animated ? 'smooth' : 'auto',
    };

    scroller.scrollTo(scrollOptions);
  }

  private disconnectObservers(): void {
    this.resizeObserver?.disconnect();
    this.mutationObserver?.disconnect();
  }
}
