import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

const NO_COMMENT_LANG_KEY = 'PAGES.DEALS.TOUR_PACKAGE.NO_COMMENT';

@Component({
  selector: 'app-tour-package-info-comment',
  templateUrl: './tour-package-info-comment.component.html',
  styleUrls: ['./tour-package-info-comment.component.scss'],
})
export class TourPackageInfoCommentComponent implements OnInit {
  @Input() comment: string;
  public noCommentLabel: string;
  constructor(private translateService: TranslateService) {
    this.translateService
      .get(NO_COMMENT_LANG_KEY)
      .pipe(first())
      .subscribe(translated => {
        this.noCommentLabel = translated;
      });
  }

  ngOnInit(): void {
    if (!this.comment) {
      this.comment = this.noCommentLabel;
    }
  }
}
