import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { UiDatetimepickerComponent } from './components/ui-datetimepicker/ui-datetimepicker.component';

@NgModule({
  declarations: [UiDatetimepickerComponent],
  exports: [UiDatetimepickerComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
})
export class UiDatetimepickerModule {}
