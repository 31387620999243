import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  inject,
  Injector,
  Input,
  OnInit,
  output,
  signal,
  ViewChild,
  viewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  GoogleMap,
  MapAdvancedMarker,
  MapInfoWindow,
  MapMarker,
  MapMarkerClusterer,
} from '@angular/google-maps';
import { TopHotelListRequest } from '@api-clients/api-client/models';
import { MarkerClusterer, SuperClusterViewportAlgorithm } from '@googlemaps/markerclusterer';
import { SuperClusterOptions } from '@googlemaps/markerclusterer/dist/algorithms/supercluster';
import { merge, Observable, of, Subject, switchMap } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SearchHotel } from '../../../../../../../../../search/search.model';
import { SearchResultService } from '../../../../../../../../../search/services/search-result.service';
import {
  InitSearchRequest,
  SearchResultsResponseTour,
} from '../../../../../../../../../search/services/search/websocket-tours.model';
import { HotelTagWithPriority, SearchResultGroup } from '../../../../favorites-hotels.model';
import { SearchHotelTagsService } from '../../../../services/search-hotel-tags.service';
import { SearchToursProgressService } from '../../../../services/search-tours-progress.service';
import { SearchFormService } from '../../../search-form/search-form.service';
import { SearchResultFiltersService } from '../../services/search-result-filters.service';
import { SearchResultUiService } from '../../services/search-result-ui.service';
import { SearchResultMapInfoWindowComponent } from './info-window/search-result-map-info-window.component';
import { options } from './map-options';
import {
  svgMarkerFull,
  svgMarkerHotelStarsAndPrice,
  svgMarkerRatingAndHotelStars,
  svgMarkerRatingAndPrice,
} from './marker/marker-svg.functions';
import createInfoWindow from './overlay/info-window.overlay';
import { SearchResultMapSearchOnPlaceComponent } from './search-on-place/search-result-map-search-on-place.component';

@Component({
  selector: 'app-search-result-map',
  templateUrl: './search-result-map.component.html',
  styleUrl: './search-result-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GoogleMap,
    MapAdvancedMarker,
    MapMarker,
    SearchResultMapSearchOnPlaceComponent,
    MapMarkerClusterer,
    MapInfoWindow,
    SearchResultMapInfoWindowComponent,
  ],
})
export class SearchResultMapComponent implements OnInit, AfterViewInit {
  @Input() resultGroups$: Observable<SearchResultGroup[]>;
  @Input() initSearchRequest: InitSearchRequest;

  @ViewChild('infoWindowContainer', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  windowWidth = signal<number>(0);
  windowHeight = signal<number>(0);

  map = viewChild(GoogleMap);
  mapCluster: MarkerClusterer;
  clusterMarkers: google.maps.Marker[] = [];

  filterHotelStarsList = signal<string[]>([]);
  filterHotelRatingList = signal<number[]>([]);
  filterTourMinPrice = signal<number>(0);
  filterTourMaxPrice = signal<number>(0);
  filterTags = signal<Map<number, HotelTagWithPriority[]>>(new Map<number, HotelTagWithPriority[]>());
  filerOnlyWithPrice = signal<boolean>(false);

  boundsHotelIds = output<number[]>();

  mapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  mapOptions: google.maps.MapOptions = options;

  private resultGroups: SearchResultGroup[] = [];
  private destroyRef = inject(DestroyRef);
  private isResultsVisible = false;
  private tempCountryClusterMarkers: google.maps.Marker[] = [];
  private countryLatLngBounds: google.maps.LatLngBounds;
  private tagIds: number[] = [];
  private previousTagsRequest: TopHotelListRequest | null = null;
  private currentTagsRequest$: Observable<any> | null = null;
  private loadTagsSubject = new Subject<TopHotelListRequest>();

  constructor(
    private readonly injector: Injector,
    private readonly uiService: SearchResultUiService,
    private readonly searchFormService: SearchFormService,
    private readonly searchResultFiltersService: SearchResultFiltersService,
    private readonly searchResultService: SearchResultService,
    private readonly searchTagsService: SearchHotelTagsService,
    private readonly searchProgressService: SearchToursProgressService,
  ) {}

  ngAfterViewInit(): void {
    this.setMapSmallSize();
  }

  ngOnInit(): void {
    this.searchFormService.hotelsOnSearch$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(hotels => !!hotels.length),
      )
      .subscribe(hotels => {
        this.tempCountryClusterMarkers.forEach(marker => {
          google.maps.event.clearInstanceListeners(marker);
        });

        this.countryLatLngBounds = new google.maps.LatLngBounds();
        this.tempCountryClusterMarkers = [];
        setTimeout(() => {
          hotels
            .filter(hotel => hotel.latitude && hotel.longitude)
            .forEach(hotel => {
              const marker = this.createClusterMarker(hotel);
              this.tempCountryClusterMarkers.push(marker);
              this.countryLatLngBounds.extend(marker.getPosition());
            });
        });
      });

    this.searchFormService.startSearch$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.clearCluster();

      this.clusterMarkers = [...this.tempCountryClusterMarkers];
      this.tempCountryClusterMarkers = [];
      this.resultGroups = [];

      if (this.isResultsVisible) {
        this.fitBoundsMap();

        if (this.mapCluster) {
          this.mapCluster.addMarkers(this.clusterMarkers);
        }
      }
    });

    this.searchProgressService.searchCompleted$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.isResultsVisible && this.mapCluster) {
        this.updateMarkersOnMap();
      }
    });

    this.resultGroups$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v.length),
        debounceTime(500),
      )
      .subscribe(resultGroups => {
        this.resultGroups = resultGroups.filter(
          resultGroup => resultGroup.hotel.latitude && resultGroup.hotel.longitude,
        );
        if (this.isResultsVisible && this.mapCluster) {
          this.updateMarkersOnMap();
        }
      });

    this.searchResultService.showResultComponent$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(visible => {
        this.isResultsVisible = visible;
        if (this.isResultsVisible) {
          this.fitBoundsMap();

          if (!this.mapCluster) {
            this.initializeCluster();
          } else if (!this.clusterMarkers.length && this.resultGroups.length && this.clusterMarkers.length) {
            this.createClusterMarkersFromResultGroups();
          }
        }
      });

    this.uiService.toursVisibleUpdated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(visible => {
      if (visible) {
        this.setMapSmallSize();
      } else {
        this.setMapFullSize();
      }

      google.maps.event.trigger(this.map().googleMap, 'resize');
    });

    this.searchResultService.showHotelOnMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hotelId => {
      const resultGroup = this.resultGroups.find(marker => marker.hotel.id === hotelId);
      if (resultGroup) {
        const position = new google.maps.LatLng(resultGroup.hotel.latitude, resultGroup.hotel.longitude);

        this.map().googleMap.panTo(position);
        if (this.map().googleMap.getZoom() < 12) {
          this.map().googleMap.setZoom(16);
        }

        const clusterMarker = this.clusterMarkers.find(
          clusterMarker => clusterMarker.get('hotelId') === hotelId,
        );

        if (clusterMarker) {
          clusterMarker.setAnimation(google.maps.Animation.BOUNCE);
          setTimeout(() => {
            clusterMarker.setAnimation(null);
          }, 3200);
        }
      }
    });

    this.searchResultFiltersService.filterTagIds$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(tagIds => {
        this.tagIds = tagIds;
      });

    this.loadTagsSubject
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(100),
        switchMap(request => this.searchTagsService.loadHotelIdsByTags$(request)),
      )
      .subscribe(() => {
        this.updateMarkersOnMap();
        this.currentTagsRequest$ = null;
      });

    this.subscribeToFiltersEvents();
  }

  onMapChanged(): void {
    if (this.tagIds.length) {
      const resultGroups = this.getResultGroupsByMapBounce();
      const request: TopHotelListRequest = {
        countryId: this.initSearchRequest.params.params.countryId,
        tagIds: this.tagIds,
        hotelIds: resultGroups.map(result => result.hotel.id).sort(),
        // @ts-ignore
        expand: ['tags'],
        disableFiltration: true,
      };

      if (this.isSameTagRequest(request)) {
        if (this.currentTagsRequest$) {
          this.currentTagsRequest$.subscribe(() => {
            this.updateMarkersOnMap();
          });
        }
      } else {
        this.previousTagsRequest = request;
        this.currentTagsRequest$ = of(request);
        this.loadTagsSubject.next(request);
      }
    } else {
      this.updateMarkersOnMap();
    }
  }

  private isSameTagRequest(request: TopHotelListRequest): boolean {
    return JSON.stringify(this.previousTagsRequest) === JSON.stringify(request);
  }

  getMarkerIcon(hotel: SearchHotel, tour: SearchResultsResponseTour | undefined): google.maps.Icon {
    let svgString = '';
    const tags = this.filterTags().get(hotel.id) || [];
    if (tour) {
      const price = tour.brandPrice.value;
      if (hotel.bookingRating && hotel.stars) {
        svgString = svgMarkerFull(hotel.bookingRating, hotel.stars, price, tags);
      } else if (hotel.bookingRating) {
        svgString = svgMarkerRatingAndPrice(hotel.bookingRating, price, tags);
      } else {
        svgString = svgMarkerHotelStarsAndPrice(hotel.stars, price, tags);
      }
    } else {
      svgString = svgMarkerRatingAndHotelStars(hotel.bookingRating, hotel.stars, tags);
    }

    return {
      url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
    };
  }

  private updateMarkersOnMap(ignoreZoomRestriction?: boolean): void {
    if (!this.map() || !this.map().googleMap || !this.isResultsVisible) {
      return;
    }

    let resultGroups = this.getResultGroupsByMapBounce();
    resultGroups = this.applyFilters(resultGroups);
    if (this.map().googleMap.getZoom() > 9 || ignoreZoomRestriction === true || resultGroups.length < 100) {
      // Нет отелей в области видимости
      const hotelIds = resultGroups.map(result => result.hotel.id);
      if (!hotelIds.length) {
        this.boundsHotelIds.emit([]);
        return;
      }

      const hotelIdsInsideCluster = [];
      this.clusterMarkers.forEach(clusterMarker => {
        const resultGroup = resultGroups.find(result => result.hotel.id === clusterMarker.get('hotelId'));
        if (resultGroup) {
          hotelIdsInsideCluster.push(resultGroup.hotel.id);

          let needUpdate = false;
          const hotelTags = this.filterTags().get(resultGroup.hotel.id) || [];
          const hasTags = !!hotelTags.length;
          const hotelTagIds = hotelTags
            .map(tag => tag.tag.id)
            .sort()
            .join('+');
          const hasTour = !!resultGroup.tours?.length;
          const tourPrice = hasTour ? resultGroup.tours[0].brandPrice.value : 0;

          if (clusterMarker.get('hasTour') !== hasTour) {
            needUpdate = true;
          } else {
            if (clusterMarker.get('price') !== tourPrice) {
              needUpdate = true;
            } else if (clusterMarker.get('hasTags') !== hasTags) {
              needUpdate = true;
            } else if (hasTags) {
              if (clusterMarker.get('tagIds') !== hotelTagIds) {
                needUpdate = true;
              }
            }
          }

          if (needUpdate) {
            clusterMarker.setIcon(
              this.getMarkerIcon(resultGroup.hotel, hasTour ? resultGroup.tours[0] : undefined),
            );

            clusterMarker.set('price', tourPrice);
            clusterMarker.set('hasTour', hasTour);
            clusterMarker.set('hasTags', hasTags);
            clusterMarker.set('tagIds', hotelTagIds);
          }
        }
      });

      const resultGroupsOutsideCluster = resultGroups.filter(
        result => !hotelIdsInsideCluster.some(hotelId => result.hotel.id === hotelId),
      );
      if (resultGroupsOutsideCluster.length) {
        const newMarkers = resultGroupsOutsideCluster.map(result =>
          this.createClusterMarker(result.hotel, result.tours[0] || undefined),
        );
        this.clusterMarkers = this.clusterMarkers.concat(newMarkers);
        this.mapCluster.addMarkers(newMarkers);
      }

      this.boundsHotelIds.emit(hotelIds);
    } else {
      this.boundsHotelIds.emit([]);
    }
  }

  private initializeCluster(): void {
    const options: SuperClusterOptions = {
      maxZoom: 16,
      minPoints: 5,
      radius: 280,
    };

    this.mapCluster = new MarkerClusterer({
      markers: this.clusterMarkers,
      map: this.map().googleMap,
      algorithm: new SuperClusterViewportAlgorithm(options),
    });
  }

  private createClusterMarkersFromResultGroups(): void {
    const clusterMarkers = [];
    this.resultGroups.forEach(result => {
      const tour = result.tours[0] || undefined;
      clusterMarkers.push(this.createClusterMarker(result.hotel, tour));
    });

    this.clusterMarkers = clusterMarkers;
    this.mapCluster.addMarkers(this.clusterMarkers);
  }

  private createClusterMarker(
    hotel: SearchHotel,
    tour?: SearchResultsResponseTour | undefined,
  ): google.maps.Marker {
    const position = new google.maps.LatLng(hotel.latitude, hotel.longitude);

    const clusterMarker = new google.maps.Marker({
      position,
      icon: this.getMarkerIcon(hotel, tour),
      optimized: true,
    });

    clusterMarker.set('hotelId', hotel.id);
    clusterMarker.set('hasTour', !!tour);
    clusterMarker.set('hasTags', this.filterTags().has(hotel.id));
    clusterMarker.set('tagIds', '');
    clusterMarker.set('price', tour ? tour.brandPrice.value : 0);

    clusterMarker.addListener('click', () => {
      this.viewContainerRef.clear();
      const componentRef: ComponentRef<SearchResultMapInfoWindowComponent> =
        this.viewContainerRef.createComponent(SearchResultMapInfoWindowComponent, {
          injector: this.injector,
        });
      componentRef.instance.hotel = hotel;
      componentRef.instance.tour =
        this.resultGroups.find(result => result.hotel.id === hotel.id)?.tours[0] || undefined;
      componentRef.instance.initSearchRequest = this.initSearchRequest;
      componentRef.instance.tags = [];
      if (this.filterTags().has(hotel.id)) {
        componentRef.instance.tags = this.filterTags().get(hotel.id);
      }
      componentRef.changeDetectorRef.detectChanges();

      const popup = createInfoWindow({
        position: clusterMarker.getPosition(),
        content: componentRef.location.nativeElement,
      });
      popup.setMap(this.map().googleMap);

      componentRef.instance.close.subscribe(() => {
        popup.setMap(null);
      });
    });

    return clusterMarker;
  }

  private getResultGroupsByMapBounce(): SearchResultGroup[] {
    const bounds = this.map().googleMap.getBounds();
    if (!bounds) {
      return [];
    }

    return this.resultGroups.filter(resultGroup => {
      const position = new google.maps.LatLng(resultGroup.hotel.latitude, resultGroup.hotel.longitude);
      return bounds.contains(position);
    });
  }

  private setMapSmallSize(): void {
    this.windowWidth.set(window.innerWidth - 400);
    this.windowHeight.set(window.innerHeight - 50);
  }

  private setMapFullSize(): void {
    this.windowWidth.set(window.innerWidth);
    this.windowHeight.set(window.innerHeight - 50);
  }

  private applyFilters(resultGroups: SearchResultGroup[]): SearchResultGroup[] {
    if (this.filerOnlyWithPrice()) {
      resultGroups = resultGroups.filter(result => result.tours.length > 0);
    }

    if (this.filterHotelStarsList().length) {
      resultGroups = resultGroups.filter(result => this.filterHotelStarsList().includes(result.hotel.stars));
    }

    if (this.filterHotelRatingList().length) {
      resultGroups = resultGroups.filter(result => {
        let success = false;
        for (const rating of this.filterHotelRatingList()) {
          if (result.hotel.bookingRating >= rating) {
            success = true;
            break;
          }
        }

        return success;
      });
    }

    if (this.filterTourMinPrice()) {
      resultGroups = resultGroups
        .filter(result => !!result.tours?.length)
        .filter(result => {
          return result.tours[0].brandPrice.value >= this.filterTourMinPrice();
        });
    }

    if (this.filterTourMaxPrice()) {
      resultGroups = resultGroups
        .filter(result => !!result.tours?.length)
        .filter(result => {
          const correctionPrice = this.filterTourMinPrice() <= 1000000 ? 10000 : 20000;
          return result.tours[0].brandPrice.value <= this.filterTourMaxPrice() + correctionPrice;
        });
    }

    if (this.filterTags().size) {
      resultGroups = resultGroups.filter(result => {
        return this.filterTags().has(result.hotel.id);
      });
    }

    return resultGroups;
  }

  private fitBoundsMap(): void {
    setTimeout(() => {
      this.map().googleMap.fitBounds(this.countryLatLngBounds);
    }, 100);
  }

  private clearCluster(): void {
    this.clusterMarkers.forEach(marker => {
      google.maps.event.clearInstanceListeners(marker);
    });

    this.mapCluster?.clearMarkers();
    this.clusterMarkers = [];
  }

  private reCreateClusterMarkers(): void {
    if (!this.mapCluster) {
      return;
    }

    let markers = this.getResultGroupsByMapBounce();
    markers = this.applyFilters(markers);
    if (!markers.length) {
      return;
    }
    const markersForAdd = markers
      .filter(
        marker =>
          !this.clusterMarkers.some(clusterMarker => clusterMarker.get('hotelId') === marker.hotel.id),
      )
      .map(markerByBounce =>
        this.createClusterMarker(markerByBounce.hotel, markerByBounce.tours[0] || undefined),
      );

    const markersForDelete = this.clusterMarkers.filter(
      clusterMarker => !markers.some(marker => marker.hotel.id === clusterMarker.get('hotelId')),
    );

    if (markersForAdd.length) {
      this.clusterMarkers = this.clusterMarkers.concat(markersForAdd);
      this.mapCluster.addMarkers(markersForAdd);
    }

    if (markersForDelete.length) {
      markersForDelete.forEach(markerForDelete => {
        google.maps.event.clearInstanceListeners(markerForDelete);
      });
      this.clusterMarkers = this.clusterMarkers.filter(
        clusterMarker =>
          !markersForDelete.some(
            markerForDelete => markerForDelete.get('hotelId') === clusterMarker.get('hotelId'),
          ),
      );
      this.mapCluster.removeMarkers(markersForDelete);
    }

    const ignoreZoomRestriction = true;
    this.updateMarkersOnMap(ignoreZoomRestriction);
  }

  private subscribeToFiltersEvents(): void {
    const starsList$ = this.searchResultFiltersService.filterHotelStarsList$.pipe(
      takeUntilDestroyed(this.destroyRef),
    );
    const ratingList$ = this.searchResultFiltersService.filterHotelRatingList$.pipe(
      takeUntilDestroyed(this.destroyRef),
    );
    const prices$ = this.searchResultFiltersService.filterPrices$.pipe(takeUntilDestroyed(this.destroyRef));
    const tags$ = this.searchTagsService.tags$.pipe(takeUntilDestroyed(this.destroyRef));
    const onlyWithPrice$ = this.searchResultFiltersService.filterOnlyWithPrice$.pipe(
      takeUntilDestroyed(this.destroyRef),
    );

    starsList$.subscribe(starsList => {
      this.filterHotelStarsList.set(starsList);
    });

    ratingList$.subscribe(ratingList => {
      this.filterHotelRatingList.set(ratingList);
    });

    prices$.subscribe(prices => {
      this.filterTourMinPrice.set(prices.from);
      this.filterTourMaxPrice.set(prices.to);
    });

    tags$.subscribe(tags => {
      this.filterTags.set(tags);
    });

    onlyWithPrice$.subscribe(onlyWithPrice => {
      this.filerOnlyWithPrice.set(onlyWithPrice);
    });

    merge(starsList$, ratingList$, prices$, tags$, onlyWithPrice$)
      .pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reCreateClusterMarkers();
      });
  }
}
