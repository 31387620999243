import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TourPackage } from '@api-clients/crm-api-client/models';
import { Observable } from 'rxjs';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { BOOKING_PAGE_IN_TOUR_PACKAGE_DATABASE_OPEN } from '../../../../core/services/amplitude/amplitudeEvents';
import { FeatureToggleService } from '../deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { TourPackageListService } from './services/tour-package-list.service';

export const ONLY_NOT_FINISHED = 0;

@Component({
  selector: 'app-client-reservations',
  templateUrl: './client-reservations.component.html',
  styleUrls: ['./client-reservations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ClientReservationsComponent implements OnInit {
  public readonly dateFormat = 'dd.MM.YY';
  public dayMeasure = 'дн.';
  public reservations$: Observable<TourPackage[]>;
  public crmCardId: number;
  public readonly screenType: ScreenTypes = ScreenTypes.BOOKING_HISTORY;
  public newUi$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { crmCardId: number },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClientReservationsComponent>,
    private tourPackageListService: TourPackageListService,
    private amplitudeTrackService: AmplitudeTrackService,
    private featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this.crmCardId = this.data.crmCardId;
    this.reservations$ = this.tourPackageListService.getListByCrmId(this.crmCardId, ONLY_NOT_FINISHED);
    this.newUi$ = this.featureToggleService.shouldDisplayNewFeature$;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  reservationOpened() {
    this.amplitudeTrackService.trackEvent(BOOKING_PAGE_IN_TOUR_PACKAGE_DATABASE_OPEN, {
      screenType: this.screenType,
    });
  }
}
