import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompleteReason, CompleteReasonsList } from '../../../../../../../models/complete-deal-type.model';

@Component({
  selector: 'app-complete-deal-reason-list',
  templateUrl: './complete-deal-reason-list.component.html',
  styleUrls: ['./complete-deal-reason-list.component.scss'],
})
export class CompleteDealReasonListComponent implements OnInit {
  @Input() isListDisabled: boolean;
  @Output() activeReasonKeyChanged: EventEmitter<CompleteReason> = new EventEmitter<CompleteReason>();
  public activeReasonKey: CompleteReason = null;
  public readonly COMPLETE_REASONS = CompleteReasonsList;

  ngOnInit(): void {
    [this.activeReasonKey] = this.COMPLETE_REASONS;
  }

  selectType(value: CompleteReason) {
    this.activeReasonKey = value;
    this.activeReasonKeyChanged.emit(value);
  }
}
