<!-- TODO: REFACTOR -->
<ng-container *ngIf="isDealListNewUi$ | async; else oldUi">
  <ng-container *ngIf="isPostponedCategorySelected$ | async; else default">
    <div #dealsContainer class="deal-list-content" *ngIf="filteredCards">
      <app-deals-list-item-new
        *ngFor="let deal of filteredCards; trackBy: trackByFn"
        [crmCardViewItem]="deal"
        id="deal-{{ deal.deal.id }}"
        [attr.data-deal-id]="deal.deal.id"
        [attr.data-card-id]="deal.card.id"
      >
      </app-deals-list-item-new>
    </div>
  </ng-container>
  <ng-template #default>
    <div #dealsContainer class="deal-list-content" *ngIf="filteredCards | dealFilterByTouch as filteredDeals">
      <app-deals-list-item-new
        *ngFor="let deal of filteredDeals.touchedTodayDeals; trackBy: trackByFn"
        [crmCardViewItem]="deal"
        id="deal-{{ deal.deal.id }}"
        [attr.data-deal-id]="deal.deal.id"
        [attr.data-card-id]="deal.card.id"
      ></app-deals-list-item-new>
      <button
        class="show-other-deals-btn"
        *ngIf="filteredDeals.untouchedDeals.length"
        [ngClass]="{ 'rotate-arrow': (isAllowShowOtherDeals$ | async) }"
        (click)="toggleAllowToShowOtherDeal()"
      >
        <ng-container *ngIf="isAllowShowOtherDeals$ | async; else showText">
          {{ 'PAGES.DEALS.TOUCH_TODAY.HIDE' | translate }}
        </ng-container>
        <ng-template #showText>
          {{ 'PAGES.DEALS.TOUCH_TODAY.SHOW' | translate }}
        </ng-template>
      </button>

      <ng-container *ngIf="isAllowShowOtherDeals$ | async">
        <app-deals-list-item-new
          *ngFor="let deal of filteredDeals.untouchedDeals; trackBy: trackByFn"
          [crmCardViewItem]="deal"
          id="deal-{{ deal.deal.id }}"
          [attr.data-deal-id]="deal.deal.id"
          [attr.data-card-id]="deal.card.id"
        ></app-deals-list-item-new>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
<ng-template #oldUi>
  <div #dealsContainer class="deal-list-content" *ngIf="filteredCards | dealFilter as filteredDeals">
    <app-deals-item
      *ngFor="let deal of filteredDeals.todayDeals; trackBy: trackByFn"
      [crmCardListItem]="deal"
      id="deal-{{ deal.deal.id }}"
      [attr.data-deal-id]="deal.deal.id"
      [attr.data-card-id]="deal.card.id"
    >
    </app-deals-item>
    <button
      class="show-other-deals-btn"
      *ngIf="filteredDeals.otherDeals.length"
      [ngClass]="{ 'rotate-arrow': (isAllowShowOtherDeals$ | async) }"
      (click)="toggleAllowToShowOtherDeal()"
    >
      <ng-container *ngIf="isAllowShowOtherDeals$ | async; else showText">
        {{ 'PAGES.DEALS.EARLIER_TOUCH.HIDE' | translate }}
      </ng-container>
      <ng-template #showText>
        {{ 'PAGES.DEALS.EARLIER_TOUCH.SHOW' | translate }}
      </ng-template>
    </button>

    <ng-container *ngIf="isAllowShowOtherDeals$ | async">
      <app-deals-item
        *ngFor="let deal of filteredDeals.otherDeals; trackBy: trackByFn"
        [crmCardListItem]="deal"
        id="deal-{{ deal.deal.id }}"
        [attr.data-deal-id]="deal.deal.id"
        [attr.data-card-id]="deal.card.id"
      >
      </app-deals-item>
    </ng-container>
  </div>
</ng-template>

<div *ngIf="deals?.length === 0" class="empty-deals-list-placeholder center">
  {{ 'PAGES.DEALS.EMPTY_DEALS_LIST_TOTAL' | translate }}
</div>
