import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { SmsSendComponent } from './components/sms-send/sms-send.component';
import { SmsService } from './services/sms.service';
import { SharedModule } from '../../shared/shared.module';
import { FormComponentsModule } from '../form-components/form-components.module';
import { SmsTemplatesComponent } from './components/sms-send/components/sms-templates/sms-templates.component';
import { SmsSendService } from './components/sms-send/services/sms-send.service';
import { SharedUtilityModule } from '../../../../../../libs/shared/utility/src';
import { SmsPopupService } from './services/sms-popup.service';
import { PhoneInputWithCodeModule } from '../../ui-components/phone-input-with-code/phone-input-with-code.module';
import { FormErrorModule } from '../../ui-components/form-error/form-error.module';

@NgModule({
  declarations: [SmsSendComponent, SmsTemplatesComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUtilityModule,
    PhoneInputWithCodeModule,
    FormErrorModule,
    NgClickOutsideDirective,
  ],
  providers: [SmsService, SmsSendService, SmsPopupService],
})
export class SMSModule {}
