<div [ngClass]="isFromMe ? 'from-me' : 'from-client'"  class="pending">
  <p
    *ngIf="messageType != MessageTypes.chat; else textPendingMessage">
    Ваше сообщение отправляется ..
  </p>
  <ng-template #textPendingMessage>
    {{messageText}}
  </ng-template>
</div>

