<div class="inner-wrapper">
  <app-click2call
    [phones]="phoneItems"
    [clientName]="clientName"
    [parentForm]="parentForm"
    [cardId]="cardId"
    [dealId]="dealId"
  >
  </app-click2call>
  <app-sales-funnel-stages-mini
    *ngIf="currentStage && status !== null && status !== CONVERSION_EXCLUDE_STATUS"
    [status]="status"
    [currentStage]="currentStage"
  >
  </app-sales-funnel-stages-mini>
</div>
<app-multiple-phone-input
  class="contact-phone-list"
  [selectedPhoneItem]="selectedPhone"
  [screenType]="screenType"
  [cardId]="cardId"
  [parentForm]="parentForm"
  [mode]="MULTIPLE_PHONE_INPUT_MODES.dealViewMode"
  [phonesWithCodes]="phoneItems"
  (phonesWithCountryCode)="getPhonesWithCountryCodes($event)"
  (selectedPhoneChanged)="onSelectedPhoneChanged($event)"
  [whatsappPhone]="selectedPhone"
></app-multiple-phone-input>
