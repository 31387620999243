import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { NgxMaskPipe } from 'ngx-mask';
import { SharedUtilityModule } from '../../../../../../../../libs/shared/utility/src';
import { SharedModule } from '../../../../shared/shared.module';
import { AudioModule } from '../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import { FormComponentsModule } from '../../../form-components/form-components.module';
import { AttachComponent } from './components/attach/attach.component';
import { AttachedFilesPreviewComponent } from './components/attached-files-preview/attached-files-preview.component';
import { AudioRecorderComponent } from './components/audio-recorder/audio-recorder.component';
import { QuickAnswerBtnComponent } from './components/quick-answer-btn/quick-answer-btn.component';
import { FilterByKeyPipe } from './components/whatsapp-send-message/pipes/filter-by-key.pipe';
import { WhatsappSendMessageComponent } from './components/whatsapp-send-message/whatsapp-send-message.component';
import { BasicLoaderComponent } from 'app/ui-components/basic-loader/basic-loader.component';

@NgModule({
  declarations: [
    WhatsappSendMessageComponent,
    AudioRecorderComponent,
    AttachComponent,
    AttachedFilesPreviewComponent,
    QuickAnswerBtnComponent,
    FilterByKeyPipe,
  ],
  exports: [WhatsappSendMessageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgClickOutsideDirective,
    AudioModule,
    FormComponentsModule,
    SharedUtilityModule,
    MatAutocompleteModule,
    MatInputModule,
    SharedModule,
    PickerModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    AutoFocusDirective,
    BasicLoaderComponent,
  ],
  providers: [NgxMaskPipe],
})
export class ChatFooterModule {}
