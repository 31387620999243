<div class="info">
  <div class="item">
    <img src="./assets/icons/notifications-center/client.svg"
         alt=""
         class="icon">
    <span class="text">
      <a libExternalLink [externalURL]="notification.viewLink">
        {{notification.payerName}}
      </a>
      &nbsp; {{'COMMON.PREPOSITIONS.OFF' | translate }} {{notification.tourPackageCreatedAt | date: dateFormat}}
    </span>
  </div>

  <div class="item" *ngIf="notification.operatorRequestNumber as operatorRequestNumber">
    <img src="./assets/icons/notifications-center/request-number.svg"
         alt=""
         class="icon">
    <span class="text">
       {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_CLAIM_CONFIRMED_CONTENT.OPERATOR_REQUEST_NUMBER' | translate }}
      : {{operatorRequestNumber}}
    </span>
  </div>
  <div class="item" *ngIf="notification.operatorName as operatorName">
    <img src="./assets/icons/notifications-center/plane.svg"
         alt=""
         class="icon">
    <span class="text">
       {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_CLAIM_CONFIRMED_CONTENT.OPERATOR_NAME' | translate }}
      : {{operatorName}}
    </span>
  </div>
  <div class="item" *ngIf="notification.countryName">
    <img src="./assets/icons/notifications-center/region.svg"
         alt=""
         class="icon">
    <span class="text">
      <p *ngIf="notification.countryName as countryName">{{countryName}},</p>
      <p *ngIf="notification.regionName as regionName">{{regionName}},</p>
      <p *ngIf="notification.hotelName as hotelName">{{hotelName}}</p>
    </span>
  </div>
</div>
