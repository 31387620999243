import { CrmTaskType } from '@api-clients/crm-api-client';
import { CompleteDealTypeEnum } from '../../../models/complete-deal-type.model';
import { CurrentStageEnum } from '../../sales-funnel-stages/sales-funnel-stages';

export enum Steps {
  CHOOSE_ACTION = 1,
  ASSIGNING_TASK = 2,
  RESULT = 3,
}

export enum StepsId {
  CHOOSE_ACTION = 'chooseAction',
  NEXT_STAGE = 'nextStage',
  COMPLETE = 'complete',
  REASSIGN = 'reassign',
  RESULT = 'result',
  NEW_DEAL_ADDED = 'newDealAdded',
  RECALL = 'recall',
  IS_AFTER_CALL = 'isAfterCall',
}

export interface CreateTaskModalStep {
  id: StepsId;
  step: Steps;
}

export interface CreateTaskModalCheckedTaskType {
  taskType: CrmTaskType | '';
}

export interface CreateTaskModalCheckedStage {
  stage: CurrentStageEnum | string;
}

export interface CreateTaskModalCompleteType {
  completeType: CompleteDealTypeEnum | string;
}

export enum Actions {
  NEXT_STAGE = 'nextStage',
  COMPLETE = 'complete',
  REASSIGN = 'reassign',
  RECALL = 'recall',
}
