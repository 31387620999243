<div class="container">
  <div class="control">
    <button #prices type="button" class="btn-control btn-control-price"
            [class.active]="filterPrices()?.from || filterPrices()?.to"
            (click)="showPrices()">
      <div class="label">
        <div class="name">Цена</div>
        <img src="assets/icons/favorite-hotels/chevron-down.svg">
      </div>
    </button>
  </div>

  <div class="control">
    <button #starsList type="button" class="btn-control"
            [class.active]="filterStarsList().length"
            (click)="showStarsList()">
      <div class="label">
        <div class="name">Звездность</div>
        <img src="assets/icons/favorite-hotels/chevron-down.svg">
      </div>
    </button>
  </div>

  <div class="control">
    <button #ratingList type="button" class="btn-control"
            [class.active]="filterRatingList().length"
            (click)="showRatingList()">
      <div class="label">
        <div class="name">Рейтинг</div>
        <img src="assets/icons/favorite-hotels/chevron-down.svg">
      </div>
    </button>
  </div>

  <div class="control">
    <button #tagsList type="button" class="btn-control"
            [class.active]="filterTagIdsList()?.length"
            (click)="showTags()">
      <div class="label">
        Поиск по потребностям
        <img src="assets/icons/favorite-hotels/chevron-down.svg">
      </div>
    </button>
  </div>

  <div class="control only-with-price">
    <app-slide-toggle labelText="Только с ценами" (change)="toggleOnlyWithPrice($event)" />
  </div>
</div>
