<div class="flight-info">
  <div class="block">
    <img
      class="icon plane"
      src="../../../../../../../../../../../../../assets/icons/post-sale/plane.svg"
      alt="plane">
  </div>
  <div class="block">
    <span class="top">
      {{flight.departure.city}} ({{flight.departure.airport}})-
    </span>
    <span class="top">
     {{flight.arrival.city}} ({{flight.arrival.airport}})
    </span>
  </div>
  <div class="block">
    <span class="top">{{flight.airline.name}}</span>
    <span class="bottom">{{flight.number}}</span>
  </div>
  <div class="block">
    <span class="top">
      {{flight.departure.dateTime | date: 'hh:mm'}}
    </span>
    <span class="top">
     {{flight.departure.dateTime | date: 'dd.MM.yy'}}
    </span>
  </div>
  <div class="block">
    <img
      class="icon arrow"
      src="../../../../../../../../../../../../../assets/icons/post-sale/arrow-right.svg"
      alt="arrow-right">
  </div>
  <div class="block">
    <span class="top">
      {{flight.arrival.dateTime | date: 'hh:mm'}}
    </span>
    <span class="top">
     {{flight.arrival.dateTime | date: 'dd.MM.yy'}}
    </span>
  </div>
</div>


