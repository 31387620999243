import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsideChat } from '../aside-chats';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { OverlayService } from '../../services/overlay.service';
import { WorkerStateService } from '../../../../core/services';
import { WorkerConfigFacade } from '../../../../+state/worker-config/worker-config.facade';

@Component({
  selector: 'app-aside-chats',
  templateUrl: './aside-chats.component.html',
  styleUrls: ['./aside-chats.component.scss'],
})
export class AsideChatsComponent implements OnInit {
  public asideChatList$: Observable<AsideChat[]>;
  public asideChatsScrollBarEnabled = false;

  constructor(
    private dealsFacade: DealsFacade,
    private overlayService: OverlayService,
    private workerState: WorkerStateService,
    private workerConfigFacade: WorkerConfigFacade,
  ) {
    this.workerConfigFacade.asideChatsScrollBarEnabled$.subscribe(enabled => {
      if (enabled !== undefined) {
        this.asideChatsScrollBarEnabled = enabled;
      }
    });

    this.asideChatList$ = this.dealsFacade.asideChatList$.pipe(
      map(chats =>
        chats
          .filter(chat => chat.lastMessage !== null)
          .sort((a, b) => {
            const aTime = new Date(a.lastMessage.receivedAt).getTime();
            const bTime = new Date(b.lastMessage.receivedAt).getTime();
            return bTime - aTime;
          })
          .slice(0, this.getChatLimit()),
      ),
    );
  }

  ngOnInit(): void {}

  onMouseLeave() {
    this.overlayService.close();
  }

  getChatLimit(): number {
    return this.asideChatsScrollBarEnabled ? 20 : 7;
  }
}
