import { Directive, HostListener, Input } from '@angular/core';
import { ElectronService } from '../../services/electron.service';

@Directive({
  selector: '[libExternalLink]',
})
export class ExternalLinkDirective {
  @Input() externalURL: string;

  @HostListener('click') onClick() {
    if (this.externalURL) {
      void this.electron.shell.openExternal(this.externalURL).then(() => {});
    }
  }

  constructor(private readonly electron: ElectronService) {
  }
}
