import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { WhatsappMedia, WhatsappMessage } from '@api-clients/crm-api-client';
import { WhatsappApiService } from '../../../../../services/whatsapp-api.service';
import { ConfigsService } from '../../../../../../../core/services';
import { WhatsappMessageItemTypes } from './message-item.interface';
import { WhatsappService } from '../../../../../services/whatsapp.service';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageItemComponent implements OnInit, OnChanges {
  @Output() clickedOnQuotedMessage = new EventEmitter();
  @Input() message: WhatsappMessage;
  @Input() clientName: string;
  @Input() quotedMessage: WhatsappMessage | null;
  @Input() isLastOfGroup: boolean;
  public messageStatus: string;

  public messageText: string;
  public instanceId: number;
  public messageId: string;
  public quotedMessageId: string;
  public messageType: string;
  public messageMediaExtension: string;
  public messageTime: string;
  public messageMedia: WhatsappMedia;
  public file;
  public adminUrl: string;
  public mediaSource: string | null;
  public fileNameRaw: string;
  public MessageTypes = WhatsappMessageItemTypes;
  public isVisibleAdditionalElements = true;
  public isOnlyImage: boolean;
  public isFromMe: boolean;
  public isPendingMessage = false;
  public isSticker = false;
  public isUnsupported: boolean;
  constructor(
    private whatsappApiService: WhatsappApiService,
    private configsService: ConfigsService,
    private whatsappService: WhatsappService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.adminUrl = this.configsService.adminUrl;
  }

  clickToQuote() {
    this.clickedOnQuotedMessage.emit();
  }

  checkIsUnsupportedMessage() {
    return !!(
      this.messageType === this.MessageTypes.unsupported ||
      this.messageType === this.MessageTypes.vcard ||
      this.messageType === this.MessageTypes.e2e_notification
    );
  }

  initMessageValues() {
    this.quotedMessageId = this.message.quotedMessageId;
    this.instanceId = this.message.instanceId;
    this.messageId = this.message.id;
    this.messageText = this.message.text;
    this.messageStatus = this.message.status;
    this.messageTime = this.message.createdAt;
    this.messageMedia = this.message.media;
    this.messageType = this.message.type;
    this.isFromMe = this.message.isFromMe;

    if (this.message.media) {
      this.messageMediaExtension = this.messageMedia.extension;
      this.mediaSource = this.messageMedia?.contentLink;
      this.isSticker = this.messageMediaExtension === this.MessageTypes.webp;
    }
    if (this.messageId === 'pending') {
      this.isPendingMessage = true;
    }
    this.isOnlyImage = this.messageType === this.MessageTypes.image && !this.messageText;
    this.isVisibleAdditionalElements = this.isPendingMessage;
    this.isUnsupported = this.checkIsUnsupportedMessage();
  }

  ngOnInit(): void {
    if (this.message) {
      this.initMessageValues();
    }

    if (this.messageMedia) {
      this.fileNameRaw = this.messageMedia.fileName;
    }
    this.cdRef.detectChanges();
  }
  showAdditionalElementsOnImage() {
    if (!this.isPendingMessage && this.isOnlyImage) {
      this.isVisibleAdditionalElements = true;
      this.cdRef.detectChanges();
    }
  }
  hideAdditionalElementsOnImage() {
    if (!this.isPendingMessage && this.isOnlyImage) {
      this.isVisibleAdditionalElements = false;
      this.cdRef.detectChanges();
    }
  }

  get isNotification() {
    return (
      this.messageType === this.MessageTypes.notification ||
      this.messageType === this.MessageTypes.notification_template ||
      this.messageType === this.MessageTypes.e2e_notification ||
      this.messageType === this.MessageTypes.gp2 ||
      this.messageType === this.MessageTypes.ciphertext ||
      this.messageType === this.MessageTypes.call_log
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.message?.currentValue) {
      this.message = changes.message.currentValue;
      this.messageStatus = this.message.status;
      this.cdRef.detectChanges();
    }
  }
}
