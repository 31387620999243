import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox-rounded',
  standalone: true,
  templateUrl: './checkbox-rounded.component.html',
  styleUrls: ['./checkbox-rounded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class CheckboxRoundedComponent {
  @Input() public isChecked = false;
  @Output() public changed = new EventEmitter<boolean>();

  toggle(): void {
    this.isChecked = !this.isChecked;
    this.changed.emit(this.isChecked);
  }
}
