import { NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { debounceTime } from 'rxjs/operators';
import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import {
  SearchTouristsRecalc,
  TOURISTS_MAX_ADULTS_COUNT,
  TOURISTS_MAX_CHILD_COUNT,
  TOURISTS_MIN_ADULTS_COUNT,
  TOURISTS_MIN_CHILD_COUNT,
} from '../../search.model';
import { childAgesValidator } from '../../validators/child-ages.validator';

const DEFAULT_ADULTS = 2;

@Component({
  selector: 'app-search-recalc-modal',
  templateUrl: './search-recalc-modal.component.html',
  styleUrls: ['./search-recalc-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, NgIf, PaginatorModule, ReactiveFormsModule, AutoFocusDirective],
})
export class SearchRecalcModalComponent implements OnInit {
  initFormParams = input.required<SearchTouristsRecalc>();

  onClose = output<void>();
  onSubmit = output<SearchTouristsRecalc>();

  form: FormGroup;

  private splitRoomsChangedManually = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.createForm();

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100)).subscribe(() => {
      if (this.canSearchSplitRooms && !this.splitRoomsChangedManually) {
        this.form.get('splitRooms').patchValue(true, { emitEvent: false });
      }
    });
  }

  close() {
    this.onClose.emit();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    const tourists: SearchTouristsRecalc = this.form.value;
    tourists.childAges = tourists.childAges.map(v => Number(v));

    if (!this.canSearchSplitRooms) {
      tourists.splitRooms = false;
    }

    this.onSubmit.emit(tourists);
    this.onClose.emit();
  }

  changeSplitRooms(): void {
    this.splitRoomsChangedManually = true;
  }

  get adults(): number {
    return this.adultsControl.value;
  }

  get child(): number {
    return this.childControl.value.length;
  }

  get adultsControl(): FormControl {
    return this.form.get('adults') as FormControl;
  }

  get childControl(): FormArray {
    return this.form.get('childAges') as FormArray;
  }

  incAdults(): void {
    const currenAdults = this.adults;
    const newAdults = currenAdults + 1;
    if (newAdults >= TOURISTS_MAX_ADULTS_COUNT) {
      return;
    }

    this.adultsControl.patchValue(newAdults);
  }

  decAdults(): void {
    const currentAdults = this.adults;
    const newAdults = currentAdults - 1;
    if (currentAdults < TOURISTS_MIN_ADULTS_COUNT) {
      return;
    }

    this.adultsControl.patchValue(newAdults);
  }

  incChild(): void {
    const currentChild = this.child;
    const newChild = currentChild + 1;
    if (newChild > TOURISTS_MAX_CHILD_COUNT) {
      return;
    }

    this.childControl.push(
      new FormControl<number | null>(null, { validators: [Validators.required, childAgesValidator()] }),
    );
  }

  decChild(): void {
    const currentChild = this.child;
    const newChild = currentChild - 1;
    if (newChild < TOURISTS_MIN_CHILD_COUNT) {
      return;
    }

    this.childControl.removeAt(-1);
  }

  get canSearchSplitRooms(): boolean {
    //  3+0 || 2+2 || 3+1
    return this.adults > 3 || (this.adults > 1 && this.adults + this.child > 3);
  }

  private createForm(): void {
    const initFormParam = this.initFormParams();

    const adults = initFormParam?.adults || DEFAULT_ADULTS;
    const splitRooms = initFormParam?.splitRooms || false;

    this.form = this.fb.group({
      adults: new FormControl<number>(adults, { validators: [Validators.required], nonNullable: true }),
      childAges: new FormArray<FormControl<number>>([]),
      splitRooms: new FormControl<boolean>(splitRooms),
    });

    if (initFormParam && initFormParam?.childAges?.length) {
      initFormParam?.childAges.forEach(age => {
        this.childControl.push(
          new FormControl<number | null>(age, { validators: [Validators.required, childAgesValidator()] }),
        );
      });
    }
  }
}
