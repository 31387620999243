import { Injectable, Renderer2, RendererFactory2, Inject, OnDestroy } from '@angular/core';
import introJs from 'intro.js';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TooltipPosition } from 'intro.js/src/core/steps';
import { DealsFacade } from '../../+state/deals/deals.facade';
import { FeatureId } from './feature-id.enum';

export interface FeatureStep {
  elementSelector: string;
  title?: string;
  paragraphs?: string[];
  position?: 'top' | 'right' | 'bottom' | 'left';
  stepId?: string;
  importantInfo?: string;
}

interface IntroJsStep {
  element: string;
  intro: string;
  position: TooltipPosition;
  title: string;
}

interface ButtonOptions {
  closeText?: string;
  tryText?: string;
  nextText?: string;
  prevText?: string;
  stepNumbersOfText?: string;
}

export interface FeatureIntroConfig {
  steps: FeatureStep[];
  callback?: () => void;
  buttonOptions?: ButtonOptions;
  featureId: string;
  isForced?: boolean; // запущенно вручную
  canTry?: boolean; // показывать кнопку "попробовать"
}

const DEFAULT_OPTIONS = {
  exitOnOverlayClick: false,
  nextToDone: true,
  showBullets: false,
  helperElementPadding: 0,
  tooltipClass: 'custom-intro-tooltip',
  showStepNumbers: false,
  padding: 10,
};

@Injectable({
  providedIn: 'root',
})
export class FeatureIntroService implements OnDestroy {
  private FEATURE_INTRO_KEY = 'featureIntros';
  private introJsInstance = introJs();
  private renderer: Renderer2;
  private destroy$ = new Subject<void>();
  public introInProgress$ = new BehaviorSubject<boolean>(false);
  private zenModeOnValue: boolean;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private dealsFacade: DealsFacade,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.dealsFacade.zenmodeOn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => (this.zenModeOnValue = value));
  }

  introduceNewFeature(config: FeatureIntroConfig): void {
    if (this.isFeatureIntroduced(config.featureId) && !config.isForced) {
      return;
    }

    if (config.featureId !== FeatureId.ZENMODE && this.isZenmodeON()) {
      return;
    }

    this.introInProgress$.next(true);

    const steps = config.steps.map(step => ({
      element: step.elementSelector,
      intro: this.buildIntroContent(step.paragraphs, config.buttonOptions, config.canTry, step.importantInfo),
      position: step.position || ('right' as TooltipPosition),
      title: step.title,
    }));

    if (this.introJsInstance) {
      this.introJsInstance.exit(false).then(() => {
        this.setupIntroJs(steps, config.buttonOptions, config.featureId, config.callback);
      });
    } else {
      this.setupIntroJs(steps, config.buttonOptions, config.featureId, config.callback);
    }
  }

  private isZenmodeON(): boolean {
    return this.zenModeOnValue;
  }

  public isFeatureIntroduced(featureId: string): boolean {
    const introducedFeatures = this.getIntroducedFeatures();
    return !!introducedFeatures[featureId];
  }

  private setFeatureIntroduced(featureId: string): void {
    const introducedFeatures = this.getIntroducedFeatures();
    introducedFeatures[featureId] = true;
    localStorage.setItem(this.FEATURE_INTRO_KEY, JSON.stringify(introducedFeatures));
  }

  private getIntroducedFeatures(): Record<string, boolean> {
    const storedData = localStorage.getItem(this.FEATURE_INTRO_KEY);
    return storedData ? JSON.parse(storedData) : {};
  }

  private buildIntroContent(
    paragraphs: string[],
    buttonOptions?: ButtonOptions,
    canTry?: boolean,
    importantInfo?: string,
  ): string {
    const tryText = buttonOptions?.tryText;
    const paragraphText = paragraphs
      .filter(p => p !== '' && p !== undefined && p !== null)
      .map(p => `<p>${p}</p>`)
      .join('');
    let tryButton = '';
    let importantInfoText = '';
    if (tryText) {
      tryButton = `<button class="intro-try">${tryText}</button>`;
    }

    if (importantInfo) {
      importantInfoText = `
           <div class="important-info-container">
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="#E1E9F5" stroke-width="1.5"/>
              <path d="M12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15Z" fill="#E1E9F5"/>
              <path d="M12 12V7" stroke="#E1E9F5" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
           <p class="important-info">${importantInfo}</p>
           </div>
            `;
    }

    return `
      <div class="custom-intro">
        ${paragraphText}
        ${importantInfoText}
        ${
          canTry
            ? `
    <div class="buttons">
          ${tryButton}
        </div>`
            : ``
        }

      </div>
    `;
  }

  private setupIntroJs(
    steps: IntroJsStep[],
    buttonOptions: ButtonOptions,
    featureId: string,
    callback?: () => void,
  ): void {
    this.introJsInstance = introJs()
      .setOptions({
        ...DEFAULT_OPTIONS,
        steps,
        hideNext: false,
        showButtons: true,
        showStepNumbers: steps.length > 1,
        showBullets: false,
        exitOnOverlayClick: false,
        doneLabel: buttonOptions?.closeText,
        nextLabel: buttonOptions?.nextText,
        stepNumbersOfLabel: buttonOptions?.stepNumbersOfText,
        hidePrev: true,
        exitOnEsc: false,
        nextToDone: true,
      })
      .oncomplete(() => {
        this.setFeatureIntroduced(featureId);
        this.introInProgress$.next(false);
      });

    this.introJsInstance.start();
    setTimeout(() => {
      this.addClickListener(featureId, callback);
    }, 0);
  }

  private addClickListener(featureId: string, callback?: () => void): void {
    const customIntroElement = this.document.querySelector('.custom-intro');
    if (!customIntroElement) {
      return;
    }

    fromEvent(customIntroElement, 'click')
      .pipe(
        filter((event: any) => ['intro-close', 'intro-try', 'intro-next'].includes(event.target.className)),
        takeUntil(this.destroy$),
      )
      .subscribe(event => this.handleButtonClick(event, featureId, callback));
  }

  private handleButtonClick(event: Event, featureId: string, callback?: () => void): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement.classList.contains('intro-try')) {
      this.setFeatureIntroduced(featureId);
      this.closeIntro();
      callback?.();
    }
  }

  private closeIntro(): void {
    this.introJsInstance.exit(false);
    this.destroy$.next();
    this.introInProgress$.next(false);
  }

  cleanup(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.cleanup();
  }
}
