<div class="basic-loader" [ngClass]="{ 'stretch': stretch }">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M3.89293 9.07507C3.17034 8.96563 2.66225 8.28491 2.89682 7.59275C3.09293 7.01408 3.35955 6.45997 3.69183 5.94328C4.26349 5.05434 5.01658 4.29627 5.90173 3.71874C6.78687 3.14122 7.78408 2.7573 8.82799 2.59214C9.87189 2.42698 10.9389 2.48432 11.9591 2.76039C12.9793 3.03647 13.9296 3.52504 14.7478 4.1941C15.5659 4.86315 16.2335 5.69756 16.7066 6.64264C17.1797 7.58772 17.4477 8.62212 17.4931 9.67804C17.5195 10.2918 17.4703 10.9047 17.3478 11.5033C17.2013 12.2193 16.4193 12.5509 15.7401 12.281C15.0609 12.0111 14.7494 11.2383 14.8264 10.5116C14.8517 10.2731 14.8593 10.0325 14.849 9.79165C14.8196 9.10834 14.6462 8.43895 14.34 7.82737C14.0338 7.21579 13.6019 6.67581 13.0724 6.24286C12.543 5.8099 11.928 5.49373 11.2678 5.31507C10.6076 5.13642 9.9171 5.09931 9.24156 5.20619C8.56603 5.31307 7.92071 5.56152 7.34791 5.93524C6.77511 6.30897 6.28777 6.79954 5.91783 7.3748C5.78745 7.57753 5.6727 7.78917 5.57426 8.00785C5.27428 8.67428 4.61552 9.18451 3.89293 9.07507Z"
      fill="#CCD5E2"
    />
    <path
      d="M16.1706 9.72601C16.9007 9.69359 17.5309 10.2632 17.4347 10.9876C17.2903 12.0742 16.9088 13.1215 16.3107 14.0528C15.4729 15.3574 14.2514 16.3702 12.8143 16.952C11.3772 17.5337 9.79509 17.6558 8.28574 17.3015C6.77639 16.9471 5.41395 16.1337 4.38591 14.9731C3.35787 13.8126 2.71475 12.362 2.54506 10.8209C2.37537 9.27982 2.68743 7.72402 3.43832 6.3676C4.18921 5.01117 5.34201 3.92079 6.73809 3.24649C7.73476 2.7651 8.82044 2.51267 9.9165 2.50046C10.6473 2.49232 11.1367 3.18662 11.0164 3.90749C10.8962 4.62835 10.2066 5.0962 9.48001 5.1745C8.93076 5.23369 8.39241 5.38656 7.88914 5.62964C6.9857 6.06599 6.23969 6.77161 5.75378 7.64938C5.26786 8.52716 5.06591 9.53395 5.17572 10.5312C5.28554 11.5285 5.70171 12.4672 6.36699 13.2182C7.03226 13.9692 7.91392 14.4956 8.89066 14.725C9.8674 14.9543 10.8912 14.8753 11.8212 14.4988C12.7512 14.1223 13.5416 13.4669 14.0838 12.6227C14.3858 12.1524 14.6025 11.6364 14.7275 11.0983C14.8929 10.3865 15.4405 9.75842 16.1706 9.72601Z"
      fill="#007CF0"
    />
  </svg>
</div>
