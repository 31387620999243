import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-unsupported',
  templateUrl: './message-unsupported.component.html',
  styleUrls: ['./message-unsupported.component.scss'],
})
export class MessageUnsupportedComponent {
  @Input() text: string;
}
