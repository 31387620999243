import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => CheckboxComponent) }],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() checked = true;
  @Output() change = new EventEmitter<boolean>();

  toggleCheck() {
    this.checked = !this.checked;
    this.change.emit(this.checked);
  }

  writeValue(): void {}

  registerOnChange(): void {}

  registerOnTouched(): void {}
  setDisabledState?(): void {}
}
