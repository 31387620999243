import { Injectable } from '@angular/core';
import { AppConfig } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private apiLoaded = false;
  private readonly googleMapsKey = AppConfig.googleMapsKey;

  constructor() {}

  loadApi(): Promise<void> {
    if (this.apiLoaded) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKey}&loading=async&libraries=drawing,geometry,places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.apiLoaded = true;
        console.log('Google Maps API loaded');
        resolve();
      };
      script.onerror = () => {
        reject('Google Maps API could not be loaded.');
      };
      document.head.appendChild(script);
    });
  }
}
