<div *ngIf="crmCardViewItem" class="history">
  <div class="actual-tasks-list"></div>

  <div class="timeline-items">
    @if (isScheduledMessagesListOpen$ | async) {
    <div class="back-to-chat-button" (click)="closeScheduledMessagesList()">
      <img src="assets/icons/arrow-left.svg" alt="Иконка 'Назад'" />
      <span>Вернуться к чату</span>
    </div>
    <app-frontend-scheduled-messages />
    } @else {
    <app-chat-time-line-filters
      [availableFilters]="getAvailableFilters()"
      [filterListWithCount]="getFilterListWithCount()"
    >
    </app-chat-time-line-filters>
    <app-chat-timeline
      [currentPhoneOrChatId]="currentPhoneItem"
      [chatTimelineData]="chatTimelineData"
      [isNoTasks]="isNoTasks"
    >
      <ng-content></ng-content>
    </app-chat-timeline>
    }
  </div>
</div>

<ng-container *ngIf="isLoading">
  <app-preloader></app-preloader>
</ng-container>
