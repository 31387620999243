import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { CallListService } from './services/call-list.service';
import { CallItemComponent } from './components/call-list/components/call-item/call-item.component';
import { TourpackagesForMarkAsAudioreviewComponent } from './components/tourpackages-for-mark-as-audioreview/tourpackages-for-mark-as-audioreview.component';
import { TourPackageItemComponent } from './components/tourpackages-for-mark-as-audioreview/components/tour-package-item/tour-package-item.component';
import { CallListComponent } from './components/call-list/call-list.component';
import { AudioModule } from '../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import { TourPackagesWithoutAudioReviewPopUpService } from './components/tourpackages-for-mark-as-audioreview/services/tourpackages-for-mark-as-audioreview-popup.service';
import { PrimengComponentsModule } from '../../shared/primeng-components/primeng-components.module';
import { PreloaderModule } from '../../ui-components/preloader/preloader.module';

@NgModule({
  declarations: [
    CallListComponent,
    CallItemComponent,
    TourpackagesForMarkAsAudioreviewComponent,
    TourPackageItemComponent,
  ],
  exports: [CallListComponent, TourpackagesForMarkAsAudioreviewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
    AudioModule,
    PrimengComponentsModule,
    PreloaderModule,
    NgxMaskModule,
    ClipboardModule,
    TranslateModule,
  ],
  providers: [CallListService, TourPackagesWithoutAudioReviewPopUpService],
})
export class CallListModule {}
