import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { pluralize } from 'jest-util';
import { Observable } from 'rxjs';
import { PluralizePipe } from '../../../../../../../../../../../helpers/pipes/plural/pluralize.pipe';
import { HotOffersService } from '../../../../../hot-offers.service';

@Component({
  selector: 'app-hot-offers-country-tours-count',
  template: `
    @if (count$ | async; as count) {
      {{ count | pluralize:'тур,тура,туров' }}
    }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    PluralizePipe
],
})
export class HotsOfferCountryToursCount {
  @Input() departCityId!: number;
  @Input() countryId!: number;

  constructor(
    private readonly hotOffersService: HotOffersService,
  ) {
  }

  get count$(): Observable<number> {
    return this.hotOffersService.toursCountByDepartCityCountryIdS(this.departCityId, this.countryId);
  }
}
