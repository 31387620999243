<div  class="content rounded-border relative-position">
  <div class='page-header'>
    <h2 class='page-title'>
      {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.TITLE' | translate }}
    </h2>
    <button class='page-close' (click)='closePopup()' >
      <img alt="" src="assets/icons/close-icon.svg" />
    </button>
  </div>

  <div class='grid-table'>
    <div class='table-header rounded-border'>
      <div class='column-title'> &nbsp; </div>
      <div class='column-title'>
        {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.TABLE.HEADER.TOUR_PACKAGE_ID' | translate }}
      </div>
      <div class='column-title'>
        {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.TABLE.HEADER.CLIENT_NAME' | translate }}
      </div>
      <div class='column-title'>
        {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.TABLE.HEADER.RELAX_DATE' | translate }}
      </div>
      <div class='column-title'>
        {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.TABLE.HEADER.PHONE' | translate }}
      </div>
      <div class='column-title'>
        {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.TABLE.HEADER.HOTEL' | translate }}
      </div>
    </div>

    <app-tour-package-item
      *ngFor='let tourPackage of tourPackagesList'
      [tourPackage]='tourPackage'
      (closeModal)='closePopup()'
      (tourPackageId)='getTourPackageIdForMark($event)' >
    </app-tour-package-item>
  </div>

<div class='bottom-buttons'>
  <div class='checkbox-container' >
      <label class="check" >
        <input type="checkbox" (click)='toPublish()'  />
        <div class="checkbox"></div>
      </label>
    <span> {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.DONT_PUBLISH' | translate }} </span>
  </div>
  <button (click)='onSubmit()' class='mark-as-audio-review-btn'>
    {{ 'PAGES.CALL_LIST.TOUR_PACKAGES_FOR_MARK_AS_AUDIOREVIEW.SAVE_BTN' | translate }}
  </button>
</div>
</div>

<ng-container *ngIf="isLoading">
  <app-preloader></app-preloader>
</ng-container>


