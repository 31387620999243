import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatRecommendationMessage } from '@api-clients/crm-api-client/models/chat-recommendation-message';
import { EditMessageRecommendationComponent } from '../components/message-recommendation/edit-message-recommendation/edit-message-recommendation.component';

@Injectable({
  providedIn: 'root',
})
export class EditMessageRecommendationModalService {
  constructor(public matDialog: MatDialog) {}

  showModal(
    message: ChatRecommendationMessage,
  ): MatDialogRef<EditMessageRecommendationComponent, { action?: string; message?: string }> {
    return this.matDialog.open(EditMessageRecommendationComponent, {
      id: 'edit-message-recommendation-modal',
      panelClass: ['modal-panel-rounded', 'modal-panel-zero-padding'],
      width: '600px',
      minHeight: '300px',
      maxHeight: 'calc(100vh - 10px)',
      hasBackdrop: true,
      data: {
        message,
      },
    });
  }
}
