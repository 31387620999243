<div class="call-header">
  <img src="assets/icons/timeline/phone.svg" class="caller" alt="incoming" />
  <div class="call-info">
    <span class="call-type-text">
      {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.CALLS.' + CALL_TYPES[callItem.callType] | translate }}
      <ng-container *ngIf="callItem?.worker?.name as manager"> ({{ manager }})</ng-container>
    </span>
    <span class="call-duration">
      <ng-container *ngIf="callItem.answer">
        <img src="assets/icons/calls/call-list/answered.svg" class="call-status" alt="answered" />
      </ng-container>
      <ng-container *ngIf="!callItem.answer">
        <img src="assets/icons/calls/call-list/missed.svg" class="call-status" alt="missed" />
      </ng-container>
      <ng-container *ngIf="callItem.duration"> {{ callItem.duration | secondsToString }}. </ng-container>
      <ng-container *ngIf="!callItem.answer">
        {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.CALLS.CALL_STATUS.MISSED' | translate }}
      </ng-container>
      <span class="client-number">
        <ng-container *ngIf="callItem?.phone?.phone as phone"> ({{ phone }})</ng-container>
      </span>
    </span>
  </div>
</div>

<div class="call-content" *ngIf="callItem.hasRecord">
  <app-audio
    [screenType]="screenType"
    [itemId]="callItem.id"
    [recordingFileLink]="callItem.recordingFileLink"
    [mediaSource]="callItem.recordingContentLink"
    [duration]="callItem.duration"
    [isFromMe]="callItem.callType === CALL_TYPES.outcome"
    [attr.data-record-id]="callItem.id"
    [attr.data-record-duration]="callItem.duration"
    [type]="audioCallType"
    class="timeline-mode"
  />
</div>
