import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatTimelineFacade } from 'app/+state/chat-timeline/chat-timeline.facade';
import { ChatTimelineModule } from '../chat-timeline/chat-timeline.module';
import { Observable } from 'rxjs';
import { WhatsappScheduledMessage } from '@api-clients/crm-api-client';

@Component({
  selector: 'app-frontend-scheduled-messages',
  standalone: true,
  imports: [CommonModule, ChatTimelineModule],
  templateUrl: './scheduled-messages.component.html',
  styleUrls: ['./scheduled-messages.component.scss'],
})
export class ScheduledMessagesComponent {
  scheduledMessages$: Observable<WhatsappScheduledMessage[]> = this.chatTimelineFacade.scheduledMessages$;

  constructor(private chatTimelineFacade: ChatTimelineFacade) {}

  public closeScheduledMessagesList() {
    this.chatTimelineFacade.closeScheduledMessagesList();
  }
}
