import { Component, ChangeDetectionStrategy, HostBinding, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { SMS_TYPES } from '../../../../../../models/sms.model';

const SMS_TYPES_LANG_KEY = 'MODALS.SMS.TYPES';

@Component({
  selector: 'app-sms-templates',
  templateUrl: './sms-templates.component.html',
  styleUrls: ['./sms-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => SmsTemplatesComponent) },
  ],
})
export class SmsTemplatesComponent implements ControlValueAccessor {
  public SMS_TYPES = SMS_TYPES;
  public smsTypeLabels;
  public selectedValue;
  public text: string;
  @HostBinding('class.open') isOpen = false;
  @Input() options;
  @Input() placeholder: string;
  private onChange: any;
  private onTouch: any;

  constructor(private translate: TranslateService) {
    this.text = '';
    this.translate
      .get(SMS_TYPES_LANG_KEY)
      .pipe(first())
      .subscribe(value => {
        this.smsTypeLabels = value;
      });
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
    this.updateText();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  close(): void {
    this.isOpen = false;
  }

  open(): void {
    this.isOpen = true;
  }

  select(val): void {
    this.selectedValue = val;
    this.updateText();
    if (this.onChange) {
      this.onChange(val.type);
      this.close();
    }
  }

  private updateText(): void {
    if (this.selectedValue) {
      const type = this.SMS_TYPES[this.selectedValue.type].langKey;
      this.text = this.smsTypeLabels[type];
    }
  }
}
