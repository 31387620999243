import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DealNotification } from '@api-clients/crm-api-client/dist/models';

@Component({
  selector: 'app-deal-assigned',
  templateUrl: './deal-assigned.component.html',
  styleUrls: ['./deal-assigned.component.scss'],
})
export class DealAssignedComponent implements OnInit {
  @Input() notification: DealNotification;
  @Output() dealViewOpened: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {}

  openDealView(crmCardId: number) {
    this.dealViewOpened.emit(crmCardId);
  }
}
