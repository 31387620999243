import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-date',
  templateUrl: './label-date.component.html',
  styleUrls: ['./label-date.component.scss'],
})
export class LabelDateComponent implements OnInit {
  @Input() date: string;
  @Input() isFixedOnTop: boolean;
  public dateFormat = 'd MMM y';

  ngOnInit(): void {}
}
