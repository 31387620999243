<div *ngIf="isReturnTourist"
     class="client-status"
>
  <app-client-status-icon
  [icon]="returnableTouristIcon"
  >
  </app-client-status-icon>
  <app-client-status-tag-helper-popup
    class="helper-popup"
    [text]="returnableTouristLabel"
    [icon] = "returnableTouristIcon"
  >
  </app-client-status-tag-helper-popup>
</div>
