import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToursSearchConnectionService } from './tours-search-connection.service';
import { ToursStorageService } from './tours-storage.service';
import {
  InitSearchRequest,
  SearchResult,
  SearchResultsResponse,
  SearchResultsResponseResultType,
} from './websocket-tours.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketToursSearchService {
  private mainSearchId: string;

  constructor(
    private readonly webSocketService: ToursSearchConnectionService,
    private readonly toursStorageService: ToursStorageService,
  ) {}

  static generateId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  searchTours(
    initSearchRequest: InitSearchRequest,
    isMainSearch = false,
    addExtendsTours = true,
  ): Observable<SearchResult> {
    const searchRequest = initSearchRequest;
    if (isMainSearch) {
      this.mainSearchId = initSearchRequest.id;
    } else {
      searchRequest.id = this.mainSearchId + '---' + initSearchRequest.id;
    }

    return new Observable(observer => {
      const subscription = this.webSocketService
        .createMultiplexedStream(searchRequest.id, searchRequest)
        .subscribe({
          next: (message: SearchResultsResponse) => {
            const requestId = searchRequest.id;
            if (message.result) {
              if (message.result.type === SearchResultsResponseResultType.searchResults) {
                const tours = message.result?.data?.tours || [];
                if (tours.length > 0) {
                  if (requestId.includes(this.mainSearchId)) {
                    this.toursStorageService.saveSearchResults(requestId, tours);
                    if (addExtendsTours) {
                      this.toursStorageService.saveSearchResults(this.mainSearchId, tours);
                    }
                  }
                }

                const uniqueTours = this.toursStorageService.getSearchResults(requestId);
                observer.next({
                  tours: uniqueTours,
                  searchIsDone: false,
                  total: uniqueTours.length,
                });
              } else if (message.result.type === SearchResultsResponseResultType.searchCompleted) {
                const uniqueTours = this.toursStorageService.getSearchResults(requestId);
                observer.next({
                  tours: uniqueTours,
                  searchIsDone: true,
                  total: uniqueTours.length,
                });
                observer.complete();
              }
            }
            if (message.error) {
              observer.error(message.error.message);
            }
          },
          error: error => observer.error(error),
          complete: () => observer.complete(),
        });

      return () => subscription.unsubscribe();
    });
  }
}
