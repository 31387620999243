import { AsyncPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { CrmCardItem } from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../../core/services';
import { PopupService } from '../../../../../../shared/services/popup-service.service';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../../ui-components/alert-label/alert-label.component';
import { LineProgressComponent } from '../../../../../../ui-components/line-progress/line-progress.component';
import { SearchHotelComponent } from '../../../../../search/components/hotel/search-hotel.component';
import { SEARCH_DATE_FORMAT, SearchHotel } from '../../../../../search/search.model';
import { SearchResultService } from '../../../../../search/services/search-result.service';
import { ToursSearchConnectionService } from '../../../../../search/services/search/tours-search-connection.service';
import { ToursStorageService } from '../../../../../search/services/search/tours-storage.service';
import { WebsocketToursSearchService } from '../../../../../search/services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestParams,
  InitSearchRequestType,
} from '../../../../../search/services/search/websocket-tours.model';
import { ChatDragAndDropService } from '../../services/chat-drag-and-drop.service';
import { SearchToursFiltersAndSortComponent } from './components/hotels-filters-and-sort/search-tours-filters-and-sort.component';
import { FavoriteHotelsListComponent } from './components/hotels-list/favorite-hotels-list.component';
import { FavoriteHotelsSearchFormComponent } from './components/search-form/favorite-hotels-search-form.component';
// tslint:disable-next-line:max-line-length
import { SearchFormService } from './components/search-form/search-form.service';
import { SearchResultFiltersService } from './components/search-result/services/search-result-filters.service';
import { SkeletonComponent } from './components/skeleton/favorite-hotels-tab-skeleton.component';
import { FavoritesHotelsSortsComponent } from './components/sorts/favorite-hotels-sorts.component';
import {
  FavoriteHotelsDefaultSortDirection,
  FavoriteHotelsSearchFormParams,
  FavoriteHotelsSortDirection,
  SearchResultGroup,
} from './favorites-hotels.model';
import { filterHotelsBySearchQueryFunctions } from './functions/filter-hotels-by-search-query.functions';
import { SearchToursProgressService } from './services/search-tours-progress.service';

@Component({
  selector: 'app-favorites-hotels-tab-content',
  templateUrl: './favorites-hotels-tab-content.component.html',
  styleUrls: ['./favorites-hotels-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    FavoriteHotelsListComponent,
    AsyncPipe,
    SkeletonComponent,
    NgIf,
    FavoritesHotelsSortsComponent,
    NgForOf,
    SearchHotelComponent,
    LineProgressComponent,
    AlertLabelComponent,
    SearchToursFiltersAndSortComponent,
    MatTooltip,
  ],
  providers: [ChatDragAndDropService, PopupService],
})
export class FavoritesHotelsTabContentComponent implements OnInit, OnDestroy {
  @Input() crmCardItem: CrmCardItem;

  searchInProgress$ = this.searchToursProgressService.searchInProgress$;

  loadingHotelsList$ = this.searchFormService.loadingHotelsList$;
  hasEmployeeToken = signal<boolean>(false);
  hasEmployeeTokenAlertType = AlertLabelType.danger;

  loadingFormParams = signal<boolean>(true);

  showSearchStartedContainer = signal<boolean>(false);
  showSearchResult = signal<boolean>(false);
  showToursNotFound = signal<boolean>(false);

  formParams = signal<SearchFormParams>(undefined);
  searchHotels = signal<SearchResultGroup[]>([]);
  hotelsSort = signal<FavoriteHotelsSortDirection>(FavoriteHotelsDefaultSortDirection);
  selectedCountry = signal<SearchFormParamsCountry>(undefined);
  toursCount = signal<number>(0);
  searchQuery = signal<string>('');
  visibleHotels = computed<SearchResultGroup[]>(() => {
    const results: SearchResultGroup[] = [];

    const sortDirection = this.hotelsSort();
    const searchText = this.searchQuery();
    const searchResultsHotels = this.searchHotels();

    let hotels = this.allHotelsInCountry();
    hotels = filterHotelsBySearchQueryFunctions(hotels, searchText);

    for (const countryHotel of hotels) {
      const result: SearchResultGroup = {
        hotel: countryHotel,
        tours: [],
      };
      for (const searchResultsHotel of searchResultsHotels) {
        if (searchResultsHotel.hotel.id === countryHotel.id) {
          result.tours = searchResultsHotel.tours;
          break;
        }
      }
      results.push(result);
    }
    return [...results].sort((a, b) => {
      switch (sortDirection) {
        case FavoriteHotelsSortDirection.salesCount:
          let salesCount1 = a.hotel.salesCount;
          let salesCount2 = b.hotel.salesCount;

          if (a.tours.length === 0) {
            salesCount1 = 0;
          }
          if (b.tours.length === 0) {
            salesCount2 = 0;
          }

          return salesCount2 - salesCount1;

        case FavoriteHotelsSortDirection.priceAsc:
          const bigPrice = 9999999;

          const ascTourPriceA = a.tours.length ? a.tours[0].price.value : bigPrice;
          const ascTourPriceB = b.tours.length ? b.tours[0].price.value : bigPrice;
          return ascTourPriceA - ascTourPriceB;

        case FavoriteHotelsSortDirection.hotelRating:
          if (a.tours.length === 0) {
            a.hotel.bookingRating = 0;
          }
          if (b.tours.length === 0) {
            b.hotel.bookingRating = 0;
          }
          return b.hotel.bookingRating - a.hotel.bookingRating;

        case FavoriteHotelsSortDirection.discountDesc:
          const discountA = a.tours.length ? a.tours[0].discount || 0 : 0;
          const discountB = b.tours.length ? b.tours[0].discount || 0 : 0;
          return discountB - discountA;
        default:
          return 0;
      }
    });
  });
  showPreviewHotels = signal(false);
  previewHotels = computed<SearchResultGroup[]>(() => {
    const searchText = this.searchQuery();
    let hotels = this.allHotelsInCountry();
    hotels = filterHotelsBySearchQueryFunctions(hotels, searchText);

    return hotels
      .map(hotel => {
        return { hotel, tours: [] };
      })
      .sort((a, b) => b.hotel.salesCount - a.hotel.salesCount);
  });

  private allHotelsInCountry = signal<SearchHotel[]>([]);

  lastSearchFormParams: FavoriteHotelsSearchFormParams;
  lastSearchInitRequest: InitSearchRequest;

  private componentSearchFormRef: ComponentRef<FavoriteHotelsSearchFormComponent>;
  private componentSearchFormOnDestroySubscription: Subscription;

  private mainSearchId = '';
  private mainSearchId$: BehaviorSubject<string>;
  private isSearchResultOpen = false;
  private isSearchResultOpenedOnFirstTours = false;
  private destroyRef = inject(DestroyRef);
  private isOpenSearchResultAutomatically = false;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly authService: AuthenticationService,
    private readonly searchFormService: SearchFormService,
    private readonly chatDDService: ChatDragAndDropService,
    private readonly webSocketConnection: ToursSearchConnectionService,
    private readonly searchToursService: WebsocketToursSearchService,
    private readonly searchToursProgressService: SearchToursProgressService,
    private readonly mapService: SearchResultFiltersService,
    private readonly toursStorage: ToursStorageService,
    private readonly searchResultService: SearchResultService,
  ) {}

  ngOnInit() {
    this.mainSearchId$ = new BehaviorSubject(this.mainSearchId);

    this.hasEmployeeToken.set(!!this.authService.employeeToken);

    this.loadFormParams();
    this.webSocketConnection.getConnectionStatus().subscribe(() => {});

    if (this.crmCardItem) {
      this.searchFormService.setActiveCrmCardItem(this.crmCardItem);
    }

    this.searchFormService.hotelsOnSearch$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(hotels => !!hotels.length),
      )
      .subscribe(hotels => {
        this.allHotelsInCountry.set(hotels);
        this.showPreviewHotels.set(true);
        this.loadingFormParams.set(false);
      });

    this.searchFormService.selectedCountry$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v),
      )
      .subscribe(selectedCountry => {
        this.mainSearchId = WebsocketToursSearchService.generateId();
        this.mainSearchId$.next(this.mainSearchId);

        if (!this.isSearchResultOpen) {
          this.showToursNotFound.set(false);
          if (this.selectedCountry()?.id !== selectedCountry.id) {
            this.searchToursProgressService.searchCompleted();

            this.selectedCountry.set(selectedCountry);
            this.showSearchResult.set(false);
          }
        }
      });

    this.searchResultService.showResultComponent$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isSearchResultOpen => {
        this.isSearchResultOpen = isSearchResultOpen;
      });

    this.componentSearchFormOnDestroySubscription = this.chatDDService.ddComponentOnDestroy$.subscribe(() => {
      this.destroySearchFormComponent();
    });

    this.mainSearchId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v),
        switchMap(searchId => {
          return this.toursStorage.getSearchResults$(searchId).pipe(filter(tours => !!tours.length));
        }),
      )
      .subscribe(tours => {
        const groupsByHotels: { [key: number]: SearchResultGroup } = {};
        this.allHotelsInCountry().forEach(hotel => {
          groupsByHotels[hotel.id] = { hotel, tours: [] };
        });

        tours.forEach(tour => {
          if (groupsByHotels.hasOwnProperty(tour.hotel.id)) {
            groupsByHotels[tour.hotel.id].tours.push(tour);
          }
        });

        this.showPreviewHotels.set(false);

        const groupsByHotelsValues: SearchResultGroup[] = Object.values(groupsByHotels);
        if (groupsByHotelsValues.length) {
          this.searchHotels.set(groupsByHotelsValues);
          this.searchResultService.setSearchResultGroups(groupsByHotelsValues);

          if (!this.isSearchResultOpenedOnFirstTours && this.isOpenSearchResultAutomatically) {
            this.isSearchResultOpenedOnFirstTours = true;
            this.searchResultService.showResultComponent();
          }

          this.showSearchStartedContainer.set(false);
          this.showSearchResult.set(true);
        }
      });

    this.searchFormService.startSearch$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          if (this.mainSearchId) {
            this.toursStorage.flushSearchResults(this.mainSearchId);
          }

          this.mainSearchId = WebsocketToursSearchService.generateId();
          this.mainSearchId$.next(this.mainSearchId);

          this.showSearchStartedContainer.set(true);
          this.showToursNotFound.set(false);
          this.showSearchResult.set(false);

          this.emitHotelsInSearchResult();

          this.searchToursProgressService.searchCompleted();
          this.searchHotels.set([]);

          this.isSearchResultOpenedOnFirstTours = false;

          this.mapService.flushFilters();
        }),
        switchMap(searchParams => {
          this.searchToursProgressService.searchStarted();

          const initSearchRequest = this.prepareSearchRequest(searchParams);
          this.searchFormService.setCurrentInitSearchRequest(initSearchRequest);

          return this.searchToursService.searchTours(initSearchRequest, true);
        }),
      )
      .subscribe(result => {
        const hasResult = result?.total > 0;

        this.toursCount.set(result.total);

        if (result.searchIsDone) {
          this.searchToursProgressService.searchCompleted();

          if (!hasResult) {
            this.showToursNotFound.set(true);
            this.showSearchStartedContainer.set(false);
            this.showSearchResult.set(false);
            this.showPreviewHotels.set(false);
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroySearchFormComponent();

    this.toursStorage.flushAllSearchResults();
  }

  updateSort(sort: FavoriteHotelsSortDirection) {
    this.hotelsSort.update(() => sort);
  }

  onSearchUpdated(sq: string) {
    this.searchQuery.set(sq);
  }

  openSearchForm() {
    if (this.componentSearchFormRef) {
      this.destroySearchFormComponent();
      return;
    }

    this.createSearchFormComponent();
    this.chatDDService.showArea({
      showCloseButton: false,
      showTourDropArea: false,
      customComponentRef: this.componentSearchFormRef,
    });
  }

  private emitHotelsInSearchResult(): void {
    const groupsByHotels: SearchResultGroup[] = [];
    this.allHotelsInCountry().forEach(hotel => {
      groupsByHotels.push({ hotel, tours: [] });
    });
    this.searchResultService.setSearchResultGroups(groupsByHotels);
  }

  private prepareSearchRequest(searchParams: FavoriteHotelsSearchFormParams): InitSearchRequest {
    const params: InitSearchRequestParams = {
      adults: searchParams.tourists.adults,
      childrenAges: searchParams.tourists.childAges.map(age => Number(age)),
      splitRooms: searchParams.tourists.splitRooms,
      combined: Number(searchParams.combined),
      countryId: searchParams.direction.countryId,
      dateFrom: this.datePipe.transform(searchParams.dates.from, SEARCH_DATE_FORMAT),
      dateTo: this.datePipe.transform(searchParams.dates.to, SEARCH_DATE_FORMAT),
      departCityId: searchParams.departCityId,
      hotels: searchParams.hotelIds,
      nightsFrom: searchParams.days.from - 1,
      nightsTo: searchParams.days.to - 1,
      notGDS: searchParams.notGDS,
      onlyHotels: false,
    };
    if (searchParams.direction.regionIds.length) {
      params.regions = searchParams.direction.regionIds;
    }
    if (searchParams.mealIds.length) {
      params.meals = searchParams.mealIds;
    }
    if (searchParams.operatorIds.length) {
      params.operators = searchParams.operatorIds;
    }
    if (searchParams.airlineIds.length) {
      params.airlines = searchParams.airlineIds;
    }
    if (searchParams.stars.length) {
      params.starsList = searchParams.stars;
    }

    const initSearchRequest: InitSearchRequest = {
      id: this.mainSearchId,
      params: {
        params,
        options: {
          type: InitSearchRequestType.main,
          groupResults: InitSearchRequestOptionsGroupResult.byHotel,
          allowParallel: true,
        },
      },
      method: InitSearchRequestMethod.search,
    };

    if (this.crmCardItem?.id) {
      initSearchRequest.params.historyParams = {};
      initSearchRequest.params.historyParams.crmID = this.crmCardItem.id;
    }

    this.lastSearchFormParams = searchParams;
    this.lastSearchInitRequest = initSearchRequest;

    return initSearchRequest;
  }

  private loadFormParams(): void {
    this.searchFormService
      .loadFormParams$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formParams => {
        this.formParams.set(formParams);
        this.openSearchForm();
      });
  }

  private destroySearchFormComponent(): void {
    this.componentSearchFormRef?.destroy();
    this.componentSearchFormOnDestroySubscription?.unsubscribe();

    this.componentSearchFormRef = undefined;

    this.chatDDService.closeArea();
  }

  private createSearchFormComponent(): void {
    if (this.componentSearchFormRef) {
      this.destroySearchFormComponent();
      return;
    }

    this.componentSearchFormRef = this.viewContainerRef.createComponent(FavoriteHotelsSearchFormComponent);
    this.componentSearchFormRef.setInput('formParams', this.formParams());
    if (this.selectedCountry()) {
      this.componentSearchFormRef.setInput('defaultCountryId', this.selectedCountry().id);
    }
    this.componentSearchFormRef.instance.close.subscribe(() => {
      this.destroySearchFormComponent();
    });
    this.componentSearchFormRef.instance.submit.subscribe(() => {
      this.destroySearchFormComponent();
    });

    this.componentSearchFormRef.instance.ngOnDestroy = () => {
      this.destroySearchFormComponent();
      this.chatDDService.closeArea();
    };
  }
}
