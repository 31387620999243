import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tour-package-info-title',
  templateUrl: './tour-package-info-title.component.html',
  styleUrls: ['./tour-package-info-title.component.scss'],
})
export class TourPackageInfoTitleComponent {
  @Input() infoTitle: string;
}
