import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-request-tourists',
  templateUrl: './search-request-tourists.component.html',
  styleUrls: ['./search-request-tourists.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class SearchRequestTouristsComponent {
  @Input() adult: number;
  @Input() child: number;
  @Input() childAges: number[];

  getTouristsInfo() {
    let childInfo = '';
    if (this.child && this.child > 0) {
      let childAges = '';
      if (this.childAges && this.childAges.length > 0) {
        childAges = `(${this.childAges.join(', ')})`;
      }
      childInfo = `, ${this.child} дет, ${childAges}`;
    }
    return `${this.adult} взр${childInfo}`;
  }
}
