import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class TaskListService {
  constructor(private http: HttpClient) {}

  getTaskList() {
    return this.http.get(`${AppConfig.apiUrl}/crm-task/list-actual`).pipe(apiResponsePipe);
  }
}
