import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ManagerSearchHotel,
  ManagerSearchHotelsList,
  ManagerSearchHotelTemplateList,
  ToursCalendar,
} from '@api-clients/api-client';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { CrmCardItem } from '@api-clients/crm-api-client';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../../../../api-response.pipe';
import { CountryList, SearchHotel } from '../../../../../../../search/search.model';
import { InitSearchRequest } from '../../../../../../../search/services/search/websocket-tours.model';
import { FavoriteHotelsSearchFormParams } from '../../favorites-hotels.model';

const SEARCH_FORM_CURRENCY = 'kzt';
const SEARCH_FORM_TYPE = 'manager';
const SEARCH_FORM_EXPAND = ['form.airlines-list', 'form.tour-operators-list'];

@Injectable({ providedIn: 'root' })
export class SearchFormService {
  private lastSearchParams: FavoriteHotelsSearchFormParams;

  private cacheDue = 30 * 60 * 1000;

  private cacheSearchFormParams$: Observable<SearchFormParams>;
  private cacheSearchFormParamsTimer$: Observable<void | 0>;

  private cacheHotelsList$: { [key: number]: Observable<ManagerSearchHotel[]> } = {};
  private cacheHotelsListTimer$: { [key: number]: Observable<void | 0> } = {};
  private cacheHotelsListPermittedCountries: number[] = [
    CountryList.Turkey,
    CountryList.UAE,
    CountryList.Egypt,
    CountryList.Maldives,
    CountryList.Thailand,
  ];

  private loadingHotelsListSub = new BehaviorSubject<boolean>(false);
  loadingHotelsList$ = this.loadingHotelsListSub.asObservable();

  private startSearchSub = new Subject<FavoriteHotelsSearchFormParams>();
  startSearch$ = this.startSearchSub.asObservable();

  private selectedCountrySub = new BehaviorSubject<SearchFormParamsCountry>(undefined);
  selectedCountry$ = this.selectedCountrySub.asObservable();

  private activeCrmCardSub = new BehaviorSubject<CrmCardItem>(undefined);
  activeCrmCard$ = this.activeCrmCardSub.asObservable();

  private hotelsOnSearchSub = new Subject<SearchHotel[]>();
  hotelsOnSearch$ = this.hotelsOnSearchSub.asObservable();

  private currentInitSearchRequestSub = new Subject<InitSearchRequest>();
  currentInitSearchRequest$ = this.currentInitSearchRequestSub.asObservable();

  constructor(private readonly http: HttpClient) {}

  loadFormParams$(): Observable<SearchFormParams> {
    if (!this.cacheSearchFormParams$) {
      let queryParams = new HttpParams();
      queryParams = queryParams.append('currency', `${SEARCH_FORM_CURRENCY}`);
      queryParams = queryParams.append('type', `${SEARCH_FORM_TYPE}`);
      queryParams = queryParams.append('expand', `${SEARCH_FORM_EXPAND}`);
      this.cacheSearchFormParams$ = this.http
        .get<ApiResponse<SearchFormParams>>(`${AppConfig.apiUrl}/search-form/params`, {
          params: queryParams,
        })
        .pipe(
          apiResponsePipe,
          map(formParams => {
            formParams.countries = formParams.countries.sort((c1, c2) => c1.position - c2.position);

            return formParams;
          }),
          shareReplay(1),
        );

      this.cacheSearchFormParamsTimer$ = timer(this.cacheDue).pipe(
        tap(() => (this.cacheSearchFormParams$ = null)),
      );

      this.cacheSearchFormParamsTimer$.subscribe();
    }

    return this.cacheSearchFormParams$;
  }

  loadHotelsTemplates$(countryId: number): Observable<ManagerSearchHotelTemplateList> {
    return this.http
      .get<ApiResponse<ManagerSearchHotelTemplateList>>(`${AppConfig.apiUrl}/search-form/hotels-templates`, {
        params: new HttpParams().set('countryId', countryId),
      })
      .pipe(apiResponsePipe);
  }

  loadFormCalendar$(
    departCityId: number,
    countryId: number,
    nightsFrom: number,
    nightsTo: number,
  ): Observable<ToursCalendar> {
    return this.http
      .get<ApiResponse<ToursCalendar>>(`${AppConfig.apiUrl}/search-form/calendar`, {
        params: new HttpParams()
          .set('departCityId', departCityId)
          .set('countryId', countryId)
          .set('nightsFrom', nightsFrom)
          .set('nightsTo', nightsTo),
      })
      .pipe(apiResponsePipe);
  }

  loadHotelsList$(countryId: number): Observable<ManagerSearchHotel[]> {
    if (!this.cacheHotelsList$.hasOwnProperty(countryId)) {
      const isCachePermittedCountry = this.cacheHotelsListPermittedCountries.includes(countryId);

      this.loadingHotelsListSub.next(true);

      const apiResponse = this.http
        .post<ApiResponse<ManagerSearchHotelsList>>(`${AppConfig.apiUrl}/search-form/hotels-list`, {
          countryId,
        })
        .pipe(
          apiResponsePipe,
          shareReplay(1),
          tap(() => this.loadingHotelsListSub.next(false)),
          map((response: ManagerSearchHotelsList) => response.list),
        );
      if (isCachePermittedCountry) {
        this.cacheHotelsList$[countryId] = apiResponse;
        this.cacheHotelsListTimer$[countryId] = timer(this.cacheDue).pipe(
          tap(() => (this.cacheHotelsList$[countryId] = null)),
        );

        this.cacheHotelsListTimer$[countryId].subscribe();
      } else {
        return apiResponse;
      }
    }

    return this.cacheHotelsList$[countryId];
  }

  saveLastSearchParams(searchParams: FavoriteHotelsSearchFormParams) {
    this.lastSearchParams = searchParams;
  }

  getLastSearchParams(): FavoriteHotelsSearchFormParams | undefined {
    return this.lastSearchParams;
  }

  startSearch(value: FavoriteHotelsSearchFormParams) {
    this.startSearchSub.next(value);
  }

  setSelectedCountry(country: SearchFormParamsCountry): void {
    this.selectedCountrySub.next(country);
  }

  setActiveCrmCardItem(value: CrmCardItem | undefined): void {
    this.activeCrmCardSub.next(value);
  }

  setHotelsOnSearch(value: SearchHotel[]): void {
    this.hotelsOnSearchSub.next(value);
  }

  setCurrentInitSearchRequest(value: InitSearchRequest): void {
    this.currentInitSearchRequestSub.next(value);
  }
}
