import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-document',
  templateUrl: './message-document.component.html',
  styleUrls: ['./message-document.component.scss'],
})
export class MessageDocumentComponent {
  @Input() fileNameRaw: string;
  @Input() mediaSource: string;
  @Input() messageText: string;
  @Input() isFromMe: boolean;
}
