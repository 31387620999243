import { Action } from '@ngrx/store';
import {
  SetCurrentOutcomeCallPayload,
  UpdateCurrentOutcomeCallInfoPayload,
} from './current-outcome-call.interface';

export enum CurrentOutcomeCallActionsTypes {
  SetCurrentOutcomeCall = '[Current Outcome Call] Set Current Outcome Call',
  ClearCurrentOutcomeCall = '[Current Outcome Call] Clear Current Outcome Call',
  UpdateCurrentOutcomeCallInfo = '[Current Outcome Call] Update Current Outcome Call Info',
}

export class SetCurrentOutcomeCall implements Action {
  readonly type = CurrentOutcomeCallActionsTypes.SetCurrentOutcomeCall;
  constructor(public payload: SetCurrentOutcomeCallPayload) {}
}

export class ClearCurrentOutcomeCall implements Action {
  readonly type = CurrentOutcomeCallActionsTypes.ClearCurrentOutcomeCall;
}

export class UpdateCurrentOutcomeCallInfo implements Action {
  readonly type = CurrentOutcomeCallActionsTypes.UpdateCurrentOutcomeCallInfo;
  constructor(public payload: UpdateCurrentOutcomeCallInfoPayload) {}
}

export type CurrentOutcomeCallAction =
  | SetCurrentOutcomeCall
  | ClearCurrentOutcomeCall
  | UpdateCurrentOutcomeCallInfo;
