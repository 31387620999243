
<div class="wrapper">
  <div class="header">
    <div class="avatar-wrap">
      <app-client-avatar
        [clientAvatar]="clientAvatar"
        [mode]="avatarMode"
        [isReturnTourist]="isReturnTourist"
        [isNew]="false"
        class="avatar-picture"
      >
      </app-client-avatar>
    </div>
    <div class="wrap">
      <div class="top">
        <app-client-card-name
          [name]="name"
          [cardId]="id"
          [phone]="phone"
          [dealId]="dealId"
          [phoneCode]="phoneCode"
        >
        </app-client-card-name>
        <ng-container *ngIf="name">
          <app-client-phone
            [phone]="phone"
            [phoneCode]="phoneCode"
            class="bg-gray"
          >
          </app-client-phone>
        </ng-container>
        <app-client-status-tags
          [isReturnTourist]="isReturnTourist"
        >
        </app-client-status-tags>
      </div>
    </div>

  </div>
  <div class="additional-info"
  [ngClass]="{'green-border': isGreenBorder}"
  >
    <span *ngIf="!isHavePayDeadline; else deadlineInfo">
      {{ 'PAGES.DEALS.TOUR_PACKAGE.STAGE_ADDITIONAL_INFO.'+tourPackage.stage  | translate }}
    </span>
    <ng-template #deadlineInfo>
      <span class="deadline-info">
        {{ 'PAGES.DEALS.TOUR_PACKAGE.DEADLINE_WAIT_PAYMENT' | translate }}
        <span class="deadline-date">
          {{ tourPackage.claimNextPaymentInfo.payDeadline | date: ' dd.MM' }}
        </span>
      </span>
    </ng-template>
  </div>
</div>


