import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ManagerOffersComponent } from '../manager-offers.component';

@Injectable()
export class ManagerOffersModalService {
  constructor(public matDialog: MatDialog) {}

  showManagerOffersModal(dealId: number, crmId: number) {
    this.matDialog.open(ManagerOffersComponent, {
      id: 'manager-offers-list',
      disableClose: false,
      panelClass: 'modal-panel',
      width: '1100px',
      minHeight: 'calc(100vh - 200px)',
      height: 'auto',
      hasBackdrop: true,
      data: {
        dealId,
        crmId,
      },
    });
  }
}
