<div class="message-item"
     [ngClass]="isFromMe ? 'from-me' : 'from-client'">
  <app-message-sticker
    *ngIf="isSticker"
    [mediaSource]="mediaSource"
  ></app-message-sticker>
  <div class="message"
       *ngIf="!isSticker"
       [ngClass]="{'triangle':isLastOfGroup}">
    <div *ngIf="isPendingMessage">
      <app-message-pending
        [messageText]="messageText"
        [messageType]="messageType"
        [isFromMe] = "message.isFromMe"
      ></app-message-pending>
      <app-whatsapp-message-status
        [isFromMe]="isFromMe"
        [messageStatus]="message.status.toString()"
        [date]="messageTime"
        [type]="messageType"
        [isOnlyImage]="false"
        *ngIf="messageType === MessageTypes.image"
      ></app-whatsapp-message-status>
    </div>
    <div *ngIf="!isPendingMessage">
      <app-message-quote
        *ngIf="quotedMessage"
        [quotedMessage] = "quotedMessage"
        [clientName]="clientName"
        [isFromMe]="isFromMe"
        (click)="clickToQuote()"
      ></app-message-quote>
      <app-message-text
        *ngIf="messageType === 'chat' && !messageMedia"
        [messageText] = "messageText"
        [isFromMe] = "message.isFromMe"
      ></app-message-text>
      <div *ngIf="messageType === MessageTypes.image && !isSticker">
        <app-message-image
          [mediaSource]="mediaSource"
          [text]="messageText"
          [isFromMe] = "message.isFromMe"
          [imageWidth]="messageMedia?.width"
          [imageHeight]="messageMedia?.height"
          (mouseenter)="showAdditionalElementsOnImage()"
          (mouseleave)="hideAdditionalElementsOnImage()"
        ></app-message-image>
        <app-whatsapp-message-status
          [style.visibility]="isVisibleAdditionalElements ? 'visible' : 'hidden'"
          [isFromMe]="isFromMe"
          [messageStatus]="message.status.toString()"
          [date]="messageTime"
          [type]="messageType"
          [isOnlyImage]="isOnlyImage"
        ></app-whatsapp-message-status></div>
        <app-message-video
          *ngIf="messageType === MessageTypes.video"
          [mediaSource]="mediaSource"
          [text]="messageText"
          [isFromMe] = "message.isFromMe">
        </app-message-video>
        <app-message-audio
          *ngIf="messageType === MessageTypes.audio || messageType === MessageTypes.ptt"
          [isFromMe] = "message.isFromMe"
          [mediaSource]="mediaSource"
        ></app-message-audio>
        <app-message-document
          *ngIf="messageType === MessageTypes.document"
          [fileNameRaw]="fileNameRaw"
          [messageText]="messageText"
          [mediaSource]="mediaSource"
          [isFromMe]="isFromMe"
        ></app-message-document>
        <app-message-unsupported
          *ngIf="isUnsupported"
          [text]="messageText"
        ></app-message-unsupported>
        <app-message-notification
          *ngIf="isNotification"
          [isFromMe]="isFromMe"
          [messageType]="messageType"
        ></app-message-notification>
      </div>
      <app-whatsapp-message-status
        *ngIf="messageType !== MessageTypes.image"
        [isFromMe]="isFromMe"
        [messageStatus]="message.status.toString()"
        [date]="messageTime"
        [type]="messageType"
        [isOnlyImage]="isOnlyImage">
      </app-whatsapp-message-status>
  </div>
</div>




