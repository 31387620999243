import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CrmCardItem,
  DealItem,
  QuestionnaireItem,
  SearchRequest,
  TourPackage,
} from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilityModule } from '../../../../../../../../../../libs/shared/utility/src';
import { ConfigsService, ElectronService } from '../../../../../../core/services';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { DEAL_SEARCH_FOR_TOURS } from '../../../../../../core/services/amplitude/amplitudeEvents';
import { ReservationsModalService } from '../../../client-reservations/services/reservations-modal.service';
import { VacationReminderComponent } from '../vacation-reminder/vacation-reminder.component';
import { ClientNeedsComponent } from './components/client-needs/client-needs.component';
import { SearchRequestStarsComponent } from './components/search-request-stars/search-request-stars.component';
import { SearchRequestDateDaysComponent } from './components/search-request-date-days/search-request-date-days.component';
import { SearchRequestTouristsComponent } from './components/search-request-tourists/search-request-tourists.component';
import { SearchRequestAddDateComponent } from './components/search-request-add-date/search-request-add-date.component';
import { ClientReservationsComponent } from './components/client-reservations/client-reservations.component';

export const DATE_FORMAT = 'd.MM';

@Component({
  selector: 'app-base-info',
  templateUrl: './base-info.component.html',
  styleUrls: ['./base-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ClientNeedsComponent,
    VacationReminderComponent,
    SharedUtilityModule,
    SearchRequestStarsComponent,
    SearchRequestDateDaysComponent,
    SearchRequestTouristsComponent,
    SearchRequestAddDateComponent,
    ClientReservationsComponent,
  ],
})
export class BaseInfoComponent {
  public questionnaireItem: QuestionnaireItem;
  public searchRequestItem: SearchRequest;
  public readonly parseInt = parseInt;
  public adminUrl: string;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  @Input() dealInfo: DealItem;
  @Input() card: CrmCardItem;
  @Input() lastTags: string[];
  @Input() tourPackage: TourPackage;
  @Input() set questionnaire(questionnaire: QuestionnaireItem) {
    this.questionnaireItem = questionnaire;
  }
  @Input() set searchOption(searchOption: SearchRequest) {
    this.searchRequestItem = searchOption;
  }
  @Input() savedVacationDateTime: string;

  constructor(
    private reservationsModalService: ReservationsModalService,
    private amplitudeTrackService: AmplitudeTrackService,
    private electron: ElectronService,
    private configsService: ConfigsService,
  ) {
    this.adminUrl = this.configsService.adminUrl;
  }

  showReservationsList(crmCardId: number, reservationsTotal: number) {
    if (reservationsTotal) {
      this.reservationsModalService.showReservationsModal(crmCardId);
    }
  }

  searchForTours() {
    this.amplitudeTrackService.trackEvent(DEAL_SEARCH_FOR_TOURS, { screenType: this.screenType });
    const crmCardId: number = this.card.id;
    const url = `${this.adminUrl}/search/manager?crm_id=${crmCardId}`;
    this.electron.shell.openExternal(url).then();
  }
}
