import { Component, Input, OnInit } from '@angular/core';
import { WhatsappMessageItemTypes } from '../message-item.interface';

@Component({
  selector: 'app-message-notification',
  templateUrl: './message-notification.component.html',
  styleUrls: ['./message-notification.component.scss'],
})
export class MessageNotificationComponent implements OnInit {
  @Input() isFromMe: boolean;
  @Input() messageType: string;
  public messageText: string;

  ngOnInit(): void {
    this.messageText = this.getMessageText();
  }

  getMessageText() {
    switch (this.messageType) {
      case WhatsappMessageItemTypes.notification:
        return 'Уведомление';
      case WhatsappMessageItemTypes.notification_template:
        return 'Шаблон уведомления';
      case WhatsappMessageItemTypes.e2e_notification:
        return 'e2e уведомление';
      case WhatsappMessageItemTypes.gp2:
        return 'Информация о движениях пользователей в группе';
      case WhatsappMessageItemTypes.ciphertext:
        return 'Зашифрованный тексте';
      case WhatsappMessageItemTypes.call_log:
        return 'Пропущенный звонок';
      default:
        return 'Сообщение неподдерживаемого типа';
    }
  }
}
