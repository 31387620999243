import { HttpClient } from '@angular/common/http';

import {
  ChatDeleteMessageRequest,
  ChatSendMessageRequest,
  DefaultResponse,
  SendMessagesResponse,
} from '@api-clients/crm-api-client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { apiResponsePipe } from '../../../api-response.pipe';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { DealsFacade } from '../../../+state/deals/deals.facade';

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  constructor(private http: HttpClient, private dealsFacade: DealsFacade) {}

  sendMessage(
    messageSendRequest: ChatSendMessageRequest,
    data?: { cardId: number; dealId: number },
  ): Observable<SendMessagesResponse> {
    if (data) {
      console.log('Touching card. from messageSend');
      this.dealsFacade.selectedZenmodeCardTouched({
        cardId: data.cardId,
        dealId: data.dealId,
      });
    }
    const formData = new FormData();
    formData.append('phone', messageSendRequest.phoneOrChatId.toString());
    formData.append('text', messageSendRequest.text || '');
    if (messageSendRequest.fileContent) {
      formData.append('fileContent', messageSendRequest.fileContent as Blob);
    }
    if (messageSendRequest.fileUrl) {
      formData.append('fileUrl', messageSendRequest.fileUrl);
    }
    if (messageSendRequest.quotedMessageId) {
      formData.append('quotedMessageId', messageSendRequest.quotedMessageId);
    }
    if (messageSendRequest.contentParams) {
      Object.keys(messageSendRequest.contentParams).forEach(key => {
        formData.append(`contentParams[${key}]`, messageSendRequest.contentParams[key] as string);
      });
    }

    formData.append('place', messageSendRequest.place);

    return this.http
      .post<ApiResponse<SendMessagesResponse>>(`${AppConfig.crmApiUrl}/chat/messages/send`, formData)
      .pipe(
        apiResponsePipe,
        map(response => response as SendMessagesResponse),
      );
  }

  deleteMessage(messageSendRequest: ChatDeleteMessageRequest): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${AppConfig.crmApiUrl}/chat/messages/delete`, messageSendRequest);
  }
}
