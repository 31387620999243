<div class="message-phone">
  <div class="message-phone__content">
    <span class="message-phone__code">{{ formattedPhoneCode }}</span>
    <span class="message-phone__phone"> {{ formattedPhone }}</span>
  </div>
  <!-- TODO: REFACTOR -->
  <!-- <button type="button" title="Скопировать телефон" class="message-phone__copy" (click)="copyPhoneWithCode($event)">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M14.8768 19.625L6.50217 19.625C5.67376 19.625 5.0022 18.9534 5.0022 18.125L5.0022 9.74998C5.0022 8.92157 5.67376 8.25001 6.50217 8.25001L14.8768 8.25C15.7052 8.25 16.3768 8.92156 16.3768 9.74998L16.3768 18.125C16.3768 18.9534 15.7053 19.625 14.8768 19.625Z"
        stroke="#007CF0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8.25037 8.25V6.49997C8.25037 5.67156 8.92193 5 9.75034 5H18.125C18.9534 5 19.625 5.67156 19.625 6.49997V14.875C19.625 15.7034 18.9534 16.375 18.125 16.375H16.3751"
        stroke="#007CF0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  </button> -->
</div>
