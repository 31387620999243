import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-favorite-hotels-search-form-days',
  templateUrl: './favorite-hotels-search-form-days.component.html',
  styleUrls: ['./favorite-hotels-search-form-days.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf, NgClickOutsideDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FavoriteHotelsSearchFormDaysComponent),
    },
  ],
})
export class FavoriteHotelsSearchFormDaysComponent implements OnInit, OnDestroy, ControlValueAccessor {
  availableNightsInDirection = input<number[]>();

  hoveredDays: number;

  form: FormGroup;

  private selectedDaysFrom: number;
  private selectedDaysTo: number;

  private formValueChangesSubscription: Subscription;

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit() {
    const fomOpts = {
      validators: [Validators.required],
      nonNullable: true,
    };
    this.form = this.fb.group({
      from: new FormControl<number>(3, fomOpts),
      to: new FormControl<number>(5, fomOpts),
    });

    this.selectedDaysFrom = this.daysFrom;
    this.selectedDaysTo = this.daysTo;

    this.formValueChangesSubscription = this.form.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngOnDestroy() {
    this.formValueChangesSubscription?.unsubscribe();
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value, { emitEvent: false });
      this.selectedDaysFrom = value.from;
      this.selectedDaysTo = value.to;
    } else {
      this.form.reset();
    }
  }

  get daysFrom(): number {
    return this.selectedDaysFrom;
  }

  get daysTo(): number {
    return this.selectedDaysTo;
  }

  selectDaysCount(daysCount: number): void {
    if (this.selectedDaysFrom && this.selectedDaysTo) {
      this.selectedDaysFrom = undefined;
      this.selectedDaysTo = undefined;
    }

    if (this.selectedDaysFrom) {
      if (daysCount < this.selectedDaysFrom) {
        this.selectedDaysTo = this.selectedDaysFrom;
        this.selectedDaysFrom = daysCount;
      } else {
        this.selectedDaysTo = daysCount;
      }
    } else {
      this.selectedDaysFrom = daysCount;
    }

    if (this.selectedDaysFrom && this.selectedDaysTo) {
      this.form.setValue({
        from: this.selectedDaysFrom,
        to: this.selectedDaysTo,
      });
    }
  }

  clickOutside(): void {
    if (this.selectedDaysFrom && !this.selectedDaysTo) {
      this.selectedDaysTo = this.selectedDaysFrom;
      this.form.setValue({
        from: this.selectedDaysFrom,
        to: this.selectedDaysTo,
      });
    }
  }

  onMouseEnter(daysCount: number): void {
    this.hoveredDays = daysCount;
  }

  onMouseLeave(): void {
    this.hoveredDays = null;
  }

  isInRange(daysCount: number): boolean {
    if (this.daysFrom && !this.daysTo && this.hoveredDays) {
      return (
        (this.daysFrom < daysCount && daysCount <= this.hoveredDays) ||
        (this.daysFrom > daysCount && daysCount >= this.hoveredDays)
      );
    } else if (this.daysFrom && this.daysTo) {
      return (
        (this.daysFrom < daysCount && daysCount < this.daysTo) ||
        (this.daysFrom > daysCount && daysCount > this.daysTo)
      );
    }
    return false;
  }
}
