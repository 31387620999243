import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkerConfigureRequest } from '@api-clients/api-client';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  workerConfigure(body: WorkerConfigureRequest) {
    return this.http
      .post<ApiResponse<WorkerConfigureRequest>>(`${AppConfig.apiUrl}/worker/configure`, body)
      .pipe(apiResponsePipe);
  }
}
