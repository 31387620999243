import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CardUpdateInfoRequest } from '@api-clients/crm-api-client/dist/models';
import { AppConfig } from '../../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientNeedsService {
  constructor(public http: HttpClient) {}

  updateInfo(crmId: number, info: string) {
    const updateInfoRequest: CardUpdateInfoRequest = {
      info,
      cardId: crmId,
    };

    return this.http.post(`${AppConfig.apiUrl}/crm-card/update-info`, updateInfoRequest);
  }
}
