import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-complaint-list',
  templateUrl: './complaint-list.component.html',
  styleUrls: ['./complaint-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplaintListComponent implements OnInit {
  ngOnInit(): void {}
}
