import { Pipe, PipeTransform } from '@angular/core';
import { SortOption, SortType } from '../../../deal-list';

@Pipe({
  name: 'sortLabel',
})
export class SortLabelPipe implements PipeTransform {
  transform(value: SortType, sortingOptions: SortOption[]): string {
    const sortItem = sortingOptions.find(option => option.id === value);
    return sortItem ? `По ${sortItem.label.toLowerCase()}` : '';
  }
}
