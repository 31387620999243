<div class="container">
  <button (click)="close.emit()" class="close-button" type="button">
    <img src="assets/icons/favorite-hotels/map-info-close.svg" />
  </button>

  <div class="container-hotel">
    @if (showTags()) {
      <div class="tags-container">
        @for (tag of tags; track tag.tag.id) {
          <div class="tag">
            <img [attr.src]="'assets/icons/favorite-hotels/tag-' + (tag.priority + 1)  +'.svg'">
            <span class="tag-name">{{ tag.tag.name }}</span>
            <span class="pointer">&nbsp;</span>
            <span class="tag-percent">{{ tag.tag.usePercent | number:'1.0-0' }}%</span>
          </div>
        }
      </div>
    }
    <div class="hotel">
      <div class="hotel-image" [style.background-image]="hotelPhotoUrl">

        @if (tour?.isCombined) {
          <div class="label-combi"><app-search-result-label-combi /></div>
        }
      </div>
      <div class="hotel-info">
        @if (isHotel) {
          <div class="hotel-stars">
            <app-hotel-stars [value]="hotel.stars" />
            @if (hotel.salesCount > 0) {
              <app-hotel-sales-count [salesCount]="hotel.salesCount" />
            }

            @if (conversionPercent) {
            <span class="conversion-value" matTooltip="Конверсия в продажу">
              <img src="assets/icons/favorite-hotels/hotel-conversion.svg">
              {{ conversionPercent }}%
            </span>
            }
          </div>
        }
        <div class="hotel-name">{{ hotel.name }}</div>
        <div class="hotel-region-rating">
          @if (hotel.bookingRating) {
            <app-search-hotel-booking-rating [rating]="hotel.bookingRating" />
          }
          @if (hotel.bookingReviewsCount) {
            <span class="hotel-reviews-count">{{ hotel.bookingReviewsCount | pluralize:'отзыв,отзыва,отзывов' }}</span>
          }
          <img src="assets/icons/favorite-hotels/map-info-ellipse.svg">
          <span class="hotel-region">{{ hotel.region }}</span>
        </div>
      </div>
    </div>
  </div>

  @if (hasServices) {
    <div class="hotel-services">
      @if (hotel.seaLine) {
        <div class="service">
          <img class="service-icon" src="assets/icons/search/beach-line.svg"> {{ hotel.seaLine }}-я линия
        </div>
      }

      @if (hotel.beachType) {
        <div class="service">
          <img class="service-icon"
               [attr.src]="'assets/icons/search/' + hotel.beachType + '.svg'"> {{ hotel.beachType | beachTypeLabel }}
        </div>
      }
    </div>
  }

  <div class="container-tour">
    @if (showTour()) {
      <!--      <div class="tour-flights-container">-->
        <!--        <app-favorite-hotels-tour-flights [tourId]="tour.id" />-->
        <!--      </div>-->

      <div class="tour-information-container">
        <div class="tour-information">
          <div class="tour-date">{{ tour.date | date:'dd.MM' }} на {{ tourDays }} дн.</div>
          <div class="tour-meal">{{ mealName }}</div>
          <div class="tour-room">
            {{ roomName }} @if (isSplitRooms) {
            <app-search-tour-label-split-rooms />
          }
          </div>
          <div class="tour-operator">{{ operatorCode }}</div>

          <div class="tour-airlines">
            <div class="tour-airlines-name">
              @if (tour?.airlines?.length) {
                {{ airlines }}
                @if (tour.isGDS) {
                  <app-search-tour-label-gds />
                }
              } @else {
                @if (tour.isGDS) {
                  <app-search-tour-label-gds />
                }
              }
            </div>
            <button type="button" class="btn-flights" [class.open]="showFlightsDetail()"
                    (click)="toggleFlightsDetail()">
              @if (showFlightsDetail()) {
                Скрыть
              } @else {
                Детали перелета
              }
              <img class="icon-chevron" src="assets/icons/favorite-hotels/map-info-flights-chevron-down.svg">
            </button>
          </div>

          @if (showFlightsDetail()) {
            <div class="airlines-detail">
              <app-search-result-map-info-window-tour-flights [tourId]="tour.id" />
            </div>
          }
        </div>
        <div class="tour-price-container">
          <div class="tour-price">
            <div class="tour-tourists">{{ tourists }}</div>
            <div class="tour-price-value">
              {{ tour.brandPrice.value | number: '1.0-0' }} {{ tour.brandPrice.currency | priceCurrency }}
            </div>
          </div>
          <div #recalc class="controls">
            <button type="button" class="btn-tourists-recalc"
                    (click)="recalcTour()"
                    [disabled]="searchInProgress()"></button>
            <button type="button" class="btn-action"
                    [disabled]="searchInProgress()"
                    (click)="sendTour()">
              @if (searchInProgress()) {
                <div class="search-loader">
                  <img class="icon" src="assets/icons/favorite-hotels/search-tours-loader.svg">
                </div>
              } @else {
                <img class="icon" src="assets/icons/favorite-hotels/map-info-send-tour.svg">
              }
            </button>
          </div>
        </div>
      </div>
    } @else {
      <div class="tours-not-found">
        @if (isTourResearched()) {
          <p>К сожалению по выбранным параметрам нет предложений от туроператоров</p>
        } @else {
          <p>Туры не найдены. Повторите поиск</p>
        }

        <div #recalc class="controls">
          <button type="button" class="btn-tourists-recalc"
                  (click)="recalcTour()"
                  [disabled]="searchInProgress()"></button>
          <button type="button" class="btn-action"
                  [disabled]="searchInProgress()"
                  (click)="startResearchTours()">
            @if (searchInProgress()) {
              <div class="search-loader">
                <img class="icon" src="assets/icons/favorite-hotels/search-tours-loader.svg">
              </div>
            } @else {
              <img class="icon" src="assets/icons/favorite-hotels/map-info-tour-research.svg">
            }
          </button>
        </div>
      </div>
    }
  </div>
</div>
