import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CityItem, CrmTaskCloseReason, CrmTaskIntent } from '@api-clients/crm-api-client/dist/models';
import { Observable, throwError } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CrmTaskCreateNextRequest, CrmTaskItem, CrmTaskType } from '@api-clients/crm-api-client';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ConfigsService, WorkerStateService } from '../../../../../../../../../core/services';
import { Worker } from '../../../../../../../../../models';
import { ManagerListService } from '../../../../../services/manager-list.service';
import { CreateTaskService } from '../../../services/create-task.service';
import {
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../../shared/notify/services/notify.service';
import { CrmTaskApiService } from '../../../../../services/crm-task-api.service';
import { getAPIFormattedDate, getFormattedTimeWithSec } from '../../../../../../../../../helpers/time';
import { AmplitudeTrackService } from '../../../../../../../../../core/services/amplitude/amplitude-track.service';
import { SALES_FUNNEL_TASK_REASSIGN } from '../../../../../../../../../core/services/amplitude/amplitudeEvents';

@Component({
  selector: 'app-reassign-step',
  templateUrl: './reassign-step.component.html',
  styleUrls: ['./reassign-step.component.scss'],
})
export class ReassignStepComponent implements OnInit {
  public cities$: Observable<CityItem[]>;
  public currentManager: Worker;
  public selectedCityId: number;
  public managersList$: Observable<Worker[]>;
  public selectedManager: number;
  public form: UntypedFormGroup;
  public readonly TRANSLATE_PATH = 'PAGES.DEALS.SALES_FUNNEL.CREATE_TASK_MODAL.REASSIGN.';
  @Input() task: CrmTaskItem;
  @Input() dealId: number;
  @Input() crmCardId: number;
  @Input() phone: string;
  @Input() dealStage: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  public isLoaded = false;
  constructor(
    private configService: ConfigsService,
    private managerListService: ManagerListService,
    private workerStateService: WorkerStateService,
    private formBuilder: UntypedFormBuilder,
    private createTaskService: CreateTaskService,
    private taskCreateService: CrmTaskApiService,
    private notifyService: NotifyService,
    private crmTaskApiService: CrmTaskApiService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.cities$ = this.configService.cities$;
    this.currentManager = this.workerStateService.currentWorkerValue;
    this.selectedCityId = this.currentManager?.cityId;
    this.managersList$ = this.managerListService
      .getManagersList(this.selectedCityId)
      .pipe(map(managers => managers.filter(manager => manager?.id !== this.currentManager?.id)));
  }

  ngOnInit(): void {
    this.initForm();
    this.isLoaded = true;
  }

  selectCity(cityId: number, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedCityId = cityId;
    this.managersList$ = this.managerListService.getManagersList(this.selectedCityId).pipe(
      map(managers => {
        this.selectedManager = null;
        return managers.filter(manager => {
          return manager.id !== this.currentManager?.id;
        });
      }),
    );
  }

  goBack() {
    this.createTaskService.goBack();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      dealId: [this.dealId],
      crmCardId: [this.crmCardId],
      dealStage: [this.dealStage],
      sendBookingInstruction: [false],
      type: [CrmTaskType.Urgent],
      meetingDate: [this.task?.date || ''],
      meetingTime: [this.task?.time || ''],
      managerComment: [this.task?.managerComment || ''],
      assignedManagerId: [this.selectedManager],
      intent: [CrmTaskIntent.ChangeDate],
      phone: [this.phone],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const payload: CrmTaskCreateNextRequest = {
        ...this.form.value,
        meetingDate: getAPIFormattedDate(new Date()),
        meetingTime: getFormattedTimeWithSec(new Date()),
      };

      this.taskCreateService
        .crmTaskCreateNext(payload)
        .pipe(
          take(1),
          mergeMap(() => {
            return this.crmTaskApiService
              .crmTaskClose({
                id: this.task.id,
                reason: CrmTaskCloseReason.Reassigned,
              })
              .pipe(take(1));
          }),
          catchError(error => {
            console.error('Ошибка:', error);
            this.notifyService.create({
              text: JSON.stringify(error.errors),
              type: NotifyTypeEnum.error,
            });
            this.createTaskService.goResult();
            return throwError(() => new Error(error));
          }),
        )
        .subscribe({
          next: () => {
            this.amplitudeTrackService.trackEvent(SALES_FUNNEL_TASK_REASSIGN, {
              dealId: this.dealId,
            });
            this.createTaskService.goResult();
          },
          error: error => {
            console.error('Ошибка при закрытии задачи:', error);
            this.closeModal.emit();
          },
        });
    }
  }
}
