import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudioTranscribeFileRequest, DefaultResponse } from '@api-clients/crm-api-client';
import {
  AudioTranscriptResponse,
  FindOrCreateAudioTranscriptRequest,
} from '@api-clients/crm-api-client/dist/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../../../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class AudioTranscriptService {
  constructor(private http: HttpClient) {}
  private apiUrl = `${AppConfig.crmApiUrl}/audio-transcript`;

  public findOrCreateAudioTranscript(
    findOrCreateAudioTranscriptRequest: FindOrCreateAudioTranscriptRequest,
  ): Observable<AudioTranscriptResponse> {
    return this.http
      .post<ApiResponse<FindOrCreateAudioTranscriptRequest>>(
        `${this.apiUrl}/find-or-create`,
        findOrCreateAudioTranscriptRequest,
      )
      .pipe(
        apiResponsePipe,
        map(response => response as AudioTranscriptResponse),
      );
  }

  public transcribeFile(request: AudioTranscribeFileRequest): Observable<DefaultResponse> {
    const formData = new FormData();
    formData.append('file', request.file as Blob);
    formData.append('fromPlace', request.fromPlace);
    return this.http.post<ApiResponse<DefaultResponse>>(`${this.apiUrl}/transcribe-file`, formData).pipe(
      apiResponsePipe,
      map(response => response as DefaultResponse),
    );
  }

  public getBlobByUrl(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
}
