import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  POPUP_DATA,
  PopupConfig,
  PopupService,
} from '../../../../../../shared/services/popup-service.service';

interface FileWithPreview extends File {
  preview?: SafeUrl | string;
  previewSmall?: SafeUrl | string;
  audioSource?: SafeUrl;
  videoSource?: SafeUrl;
  isFileIcon?: boolean;
  id?: string;
  extension?: string;
  fileName?: string;
  fileSize?: string;
}

@Component({
  selector: 'app-attached-files-preview',
  templateUrl: './attached-files-preview.component.html',
  styleUrls: ['./attached-files-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedFilesPreviewComponent implements OnInit, OnDestroy {
  @Output() addMoreFiles = new EventEmitter<void>();
  @Output() removedFile = new EventEmitter<File>();
  @Output() close = new EventEmitter<void>();

  private readonly previewFileDefault = 'assets/icons/whatsapp/preview-file/';
  private readonly previewFileExtension = '.svg';
  private readonly previewFileSmallExtension = '-small.svg';
  private readonly availableFileExtensions = ['doc', 'docx', 'pdf', 'txt', 'xsl', 'zip'];

  popupConfig: PopupConfig;
  displayedFiles: FileWithPreview[] = [];
  selectedDisplayFile: FileWithPreview;
  files: File[] = [];

  constructor(
    @Optional() @Inject(POPUP_DATA) private data: any,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private popupService: PopupService,
  ) {
    this.files = data.files;
  }

  ngOnInit() {
    this.updatePreviews();
  }

  ngOnDestroy() {
    this.revokeUrls();
  }

  trackByFn(_: number, item: FileWithPreview): string {
    return item.id;
  }

  get isVideo(): boolean {
    return !!this.selectedDisplayFile?.videoSource;
  }

  get isAudio(): boolean {
    return !!this.selectedDisplayFile?.audioSource;
  }

  get isImage(): boolean {
    return !this.isVideo && !this.isAudio && !!this.selectedDisplayFile?.preview;
  }

  bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
  }

  updatePreviews() {
    this.revokeUrls();
    this.displayedFiles = this.files.map((file: File) => {
      const id = file.name;
      let preview: SafeUrl;
      let previewSmall: SafeUrl;
      let audioSource: SafeUrl;
      let videoSource: SafeUrl;
      let isFileIcon = true;
      const extension = file.name.split('.').pop();
      const fileName = file.name;
      const fileSize = this.bytesToSize(file.size);
      if (file.type.startsWith('image/')) {
        const url = URL.createObjectURL(file);
        preview = this.sanitizer.bypassSecurityTrustUrl(url);
        previewSmall = this.sanitizer.bypassSecurityTrustUrl(url);
        isFileIcon = false;
      } else if (file.type.startsWith('audio/')) {
        const url = URL.createObjectURL(file);
        audioSource = this.sanitizer.bypassSecurityTrustUrl(url);
        preview = `${this.previewFileDefault}${'audio'}${this.previewFileExtension}`;
        previewSmall = `${this.previewFileDefault}${'audio'}${this.previewFileSmallExtension}`;
      } else if (file.type.startsWith('video/')) {
        const url = URL.createObjectURL(file);
        videoSource = this.sanitizer.bypassSecurityTrustUrl(url);
        preview = `${this.previewFileDefault}${'video'}${this.previewFileExtension}`;
        previewSmall = `${this.previewFileDefault}${'video'}${this.previewFileSmallExtension}`;
      } else if (this.availableFileExtensions.includes(extension)) {
        preview = `${this.previewFileDefault}${extension}${this.previewFileExtension}`;
        previewSmall = `${this.previewFileDefault}${extension}${this.previewFileSmallExtension}`;
      } else {
        preview = `${this.previewFileDefault}${'unknown'}${this.previewFileExtension}`;
        previewSmall = `${this.previewFileDefault}${'unknown'}${this.previewFileSmallExtension}`;
      }

      return {
        ...file,
        preview,
        previewSmall,
        audioSource,
        videoSource,
        isFileIcon,
        id,
        extension,
        fileName,
        fileSize,
      };
    });
    this.selectFileForPreview(this.displayedFiles[0]);
  }

  revokeUrls() {
    this.displayedFiles.forEach(file => {
      if (file?.type?.startsWith('image/')) {
        URL.revokeObjectURL(file.preview as string);
      }
    });
  }

  closePreview() {
    this.popupService.closeAllModals();
    this.close.emit();
  }

  removeFile(file: FileWithPreview) {
    const index = this.displayedFiles.indexOf(file);
    if (index > -1) {
      this.displayedFiles.splice(index, 1);
      this.files.splice(index, 1);
      if (file?.type?.startsWith('image/')) {
        URL.revokeObjectURL(file.preview as string);
      }
    }

    if (this.displayedFiles.length === 0) {
      this.closePreview();
    } else {
      this.selectFileForPreview(this.displayedFiles[0]);
    }
  }

  addFiles() {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = '*/*';
    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length) {
        const newFiles: File[] = Array.from(target.files);
        this.files = [...this.files, ...newFiles];
        this.updatePreviews();
        this.addMoreFiles.emit();
      }
    };

    input.click();
  }

  selectFileForPreview(file: FileWithPreview) {
    this.selectedDisplayFile = file;
    this.cdRef.detectChanges();
  }
}
