import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';

const CURRENT_CURRENCY = 'kzt';
const SEARCH_FORM_TYPE = 'manager';
const SEARCH_FORM_EXPAND = ['form.airlines-list', 'form.tour-operators-list'];

@Injectable({
  providedIn: 'root',
})
export class DealParamsService {
  constructor(private http: HttpClient) {}

  getDealParams() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('currency', `${CURRENT_CURRENCY}`);
    queryParams = queryParams.append('type', `${SEARCH_FORM_TYPE}`);
    queryParams = queryParams.append('expand', `${SEARCH_FORM_EXPAND}`);
    return this.http
      .get<ApiResponse<SearchFormParams>>(`${AppConfig.apiUrl}/search-form/params`, {
        params: queryParams,
      })
      .pipe(apiResponsePipe);
  }
}
