import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkerStateService } from '../worker/worker-state.service';
import { ConfigsService } from '../configs/configs.service';
import { WebsocketService } from '../websocket/websocket.service';
import { AmplitudeTrackService } from '../amplitude/amplitude-track.service';
import { ComplimentService } from '../../../helpers/compliment.service';

const LOCAL_KEY = 'auth-data';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private translate: TranslateService,
    private workerState: WorkerStateService,
    private configsService: ConfigsService,
    private websocketService: WebsocketService,
    private router: Router,
    private amplitudeTrackService: AmplitudeTrackService,
    private complimentService: ComplimentService,
  ) {}

  init() {
    return new Promise<void>(resolve => {
      this.setCurrentWorker().then((isAuth: boolean) => {
        if (isAuth) {
          this.setConfigs().then(() => {
            resolve();
          });
        } else {
          this.translate.setDefaultLang('ru');
          this.router.navigateByUrl('/login').then();
          resolve();
        }
      });
    });
  }

  setCurrentWorker() {
    return new Promise<boolean>(resolve => {
      const localWorker = JSON.parse(localStorage.getItem(LOCAL_KEY));
      if (localWorker) {
        this.workerState
          .getInfo(localWorker.id, localWorker.token)
          .pipe(untilDestroyed(this))
          .subscribe(worker => {
            this.workerState.currentWorker = worker;
            this.amplitudeTrackService.initAmplitude(worker);
            this.complimentService.sendCompliment(worker.gender);
            resolve(true);
          });
      } else {
        this.workerState.currentWorker = null;
        resolve(false);
      }
    });
  }

  setConfigs() {
    return new Promise<void>(resolve => {
      this.configsService
        .getConfiguration()
        .pipe(untilDestroyed(this))
        .subscribe(config => {
          const currentLang = config.lang;
          this.translate.setDefaultLang(currentLang);
          this.configsService.dealsCategories = config.categories;
          this.configsService.cities = config.cities;
          this.configsService.dealSources = config.clientSources;
          this.configsService.phoneConfig = config.countryPhoneFormats;
          this.configsService.cityId = this.workerState.currentCityId;
          this.configsService.workerList = this.workerState.currentCityId;
          this.configsService.adminUrl = config.adminUrl;
          this.configsService.taskIntents = config.taskIntents;
          this.configsService.currentWorker = this.workerState.currentWorkerValue;
          this.websocketService.initWebsocket();
          resolve();
        });
    });
  }
}
