<div class="filters-and-sort">
  <div class="filters-and-sort-wrapper">
    <div class="search-control-container" [class.full-line]="!searchResultVisible()">
      <input #searchQuery
             (input)="onSearchUpdated.emit(searchQuery.value)"
             type="text"
             class="search-control"
             placeholder="Поиск по названию отелю">
    </div>

    @if (searchResultVisible()) {
      <app-favorites-hotels-sorts (selectedSort)="selectedSort.emit($event)" />
    }
  </div>
</div>
