<div class="wrapper-container" id="salesFunnelIntro">
  <div *ngIf="currentStage !== CurrentStageEnum.autoTouch; else autoTouchTemplate" class="stage-container">

    <div class="stage-bar">
      <div *ngFor="let stage of stages"
           class="stage-progress"
           [class.active]="isActive(stage)"
           [style.background]="isActive(stage) ? getStageColor(currentStage) : '#FFFFFF'">
      </div>
    </div>

    <div class="stage-details">
      <div class="stage-title">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            *ngIf="highlightNewClient"
            class="blinking-rect"
            x="1" y="1" width="16" height="16" rx="8" fill="#97C9F9"/>
          <rect x="5" y="5" width="8" height="8" rx="4"
                [attr.fill]="getStageColor(currentStage)"/>
        </svg>



        {{ 'PAGES.DEALS.SALES_FUNNEL.STAGES.' + currentStage | translate }}
      </div>
      <div>{{ 'PAGES.DEALS.SALES_FUNNEL.STATUS.' + status | translate }}</div>
    </div>
  </div>
</div>
<ng-template #autoTouchTemplate>
  <div class="stage-container">
    {{ 'PAGES.DEALS.SALES_FUNNEL.AUTO_TOUCH' | translate }}
  </div>
</ng-template>
