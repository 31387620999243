import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  output,
  Output,
  signal,
} from '@angular/core';
import { HotelPresentation } from '@api-clients/api-client';
import { TranslateModule } from '@ngx-translate/core';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { SlideToggleModule } from '../../../../../../ui-components/slide-toggle/slide-toggle.module';
import { AudioModule } from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import { AudioType } from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/interfaces/audio.interface';

@Component({
  selector: 'app-audio-presentation-player',
  templateUrl: './audio-presentation-player.component.html',
  styleUrls: ['./audio-presentation-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AudioModule, SlideToggleModule, TranslateModule],
})
export class AudioPresentationPlayerComponent {
  @Input() public presentation: HotelPresentation;
  @Input() public activePresentation: HotelPresentation;
  @Output() public setActivePresentation = new EventEmitter<HotelPresentation>();
  public sendAsText = output<{ isChecked: boolean; transcriptionResult: string }>();
  public transcriptionResult = signal('');

  protected readonly screenType = ScreenTypes.CONTENT_CREATOR;
  protected readonly audioPresentationType = AudioType.audioPresentation;

  constructor() {}

  public setActive(): void {
    this.setActivePresentation.emit(this.presentation);
  }

  public setSendAsText(isChecked: boolean): void {
    this.sendAsText.emit({ isChecked, transcriptionResult: this.transcriptionResult() });
  }
}
