import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { PhoneItem, WhatsappMessage } from '@api-clients/crm-api-client';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { AsideChat } from '../aside-chats';
import { WhatsappService } from '../../../whatsapp/services/whatsapp.service';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { DEAL_VIEW_OPENED } from '../../../../core/services/amplitude/amplitudeEvents';
import { MarkAsNotNewService } from '../../../deals/modules/deal-view/services/mark-as-not-new/mark-as-not-new.service';
import { OverlayService } from '../../services/overlay.service';

@Component({
  selector: 'app-aside-chat-item',
  templateUrl: './aside-chat-item.component.html',
  styleUrls: ['./aside-chat-item.component.scss'],
})
export class AsideChatItemComponent implements OnInit, OnDestroy {
  @Input() chat: AsideChat;
  public crmId: number;
  public dealId: number;
  public phone: PhoneItem;
  public clientAvatar: string;
  public hasUnreadMessages: boolean;
  public name: string;
  public lastMessage: WhatsappMessage;
  public isSelectedChat = false;
  public subscription: Subscription;
  public shortMessage: string;
  public screenType: ScreenTypes = ScreenTypes.ASIDE_CHATS;
  public overlayTimer;
  constructor(
    private route: ActivatedRoute,
    private whatsappService: WhatsappService,
    private amplitudeTrackService: AmplitudeTrackService,
    private markAsNotNewService: MarkAsNotNewService,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private overlayService: OverlayService,
  ) {}

  ngOnInit(): void {
    this.clientAvatar = this.chat.clientAvatar;
    this.hasUnreadMessages = this.chat.hasUnreadMessages;
    this.name = this.chat.name ? this.chat.name : this.chat.phone.phone;
    this.lastMessage = this.chat.lastMessage;
    this.crmId = this.chat.crmId;
    this.dealId = this.chat.dealId;
    this.phone = this.chat.phone;
    this.shortMessage = this.whatsappService.parseShortMessage(this.chat.lastMessage, 40);
    this.subscription = this.route.queryParams.subscribe(({ phone, code }) => {
      if (phone && code) {
        this.isSelectedChat = this.phone.phone === phone && this.phone.code === code;
      } else {
        this.isSelectedChat = false;
      }
    });
  }

  get avatarSrc(): string {
    return this.clientAvatar ? this.clientAvatar : './assets/icons/client-avatar.svg';
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  dealViewOpened() {
    this.markAsNotNewService
      .markAsNotNew({
        dealId: this.dealId,
      })
      .pipe(first())
      .subscribe();
    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, { screenType: this.screenType });
  }

  showOverlay() {
    const config = {
      elementRef: this.elementRef,
      data: {
        name: this.chat.name ? this.chat.name : this.chat.phone.phone,
        lastMessage: this.chat.lastMessage,
        shortMessage: this.shortMessage,
        hasUnreadMessages: this.chat.hasUnreadMessages,
        phone: this.phone,
        crmId: this.crmId,
        dealId: this.dealId,
      },
    };
    this.overlayService.open(config);
  }
}
