import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZoiperCallService {
  private isZoiperCallSubject: BehaviorSubject<boolean>;
  private readonly localStorageKey = 'isZoiperCall';

  constructor() {
    this.isZoiperCallSubject = new BehaviorSubject<boolean>(this.getIsZoiperCallFromLocalStorage());
  }

  get isZoiperCall$() {
    return this.isZoiperCallSubject.asObservable();
  }

  changeIsZoiperCall(event: boolean) {
    this.isZoiperCallSubject.next(event);
    localStorage.setItem(this.localStorageKey, JSON.stringify(event));
  }

  private getIsZoiperCallFromLocalStorage(): boolean {
    const storedValue = localStorage.getItem(this.localStorageKey);
    return storedValue !== null ? JSON.parse(storedValue) : false;
  }
}
