<div class="last-action-text actions-text">
  Сообщение на ватсап
</div>
<div class="whatsapp-message">
  <span *ngIf="lastActionItem.isFromMe">Менеджер: </span>
  <span *ngIf="!lastActionItem.isFromMe">Клиент: </span>
  {{messageText}}
</div>
<span class="last-action-date actions-date">
  {{lastActionItem.createdAt | date: actionsDateFormat | lowercase}}
</span>
