import { createReducer, on } from '@ngrx/store';
import { popupInitialState } from './popup.state';
import * as PopupActions from './popup.actions';

export const USER_FEATURE_KEY = 'popup';

export const popupReducer = createReducer(
  popupInitialState,
  on(PopupActions.popupStatus, (state, { payload }) => ({
    ...state,
    popupShow: payload?.popupShow ? payload.popupShow : false,
    isBlocked: payload?.isBlocked ? payload?.isBlocked : false,
  })),
);
