<div class="client-card">
  <app-whatsapp-client-card
    *ngIf="currentCardView; else loadingClientCard"
    [dealViewItem]="currentCardView"
    [isModal]="isModal"
    [isFromDealView]="isFromDealView"
  ></app-whatsapp-client-card>
  <ng-template #loadingClientCard>
    <app-whatsapp-client-card-loading>
    </app-whatsapp-client-card-loading>
  </ng-template>
</div>
<div #ChatScreen
     class="whatsapp-chat-screen"
     [ngClass]="{'modal-chat':isModal}">
  <div class="popup-header" *ngIf="isModal">
    <button (click)="closePopup()" class="close-button" type="button">
      <img alt="" src="./assets/icons/close-icon.svg"/>
    </button>
  </div>

  <div class="messages">
    <div *ngFor="let message of messages">
      <app-message-item
        [clientName] = "currentCardView?.card?.name"
        [message] = "message"
        [id] = "message.id"
        [quotedMessage] = "message.quotedMessageId ? getQuotedMessage(message.quotedMessageId) : null"
        [isLastOfGroup] = "message.id===messageTheLastOfTheGroupId"
        (clickedOnQuotedMessage) = scrollToMessage(message.quotedMessageId)
      ></app-message-item>
    </div>
  </div>
  <ng-container *ngIf="loadedMessages?.length || isChatEmpty; else preloader">
    <div *ngFor="let message of loadedMessages">
      <app-message-item
        [clientName] = "currentCardView?.card?.name"
        [message] = "message"
        [id] = "message.id"
        [quotedMessage] = "message.quotedMessageId ? getQuotedMessage(message.quotedMessageId) : null"
        [isLastOfGroup] = "message.id===messageTheLastOfTheGroupId"
        (clickedOnQuotedMessage) = scrollToMessage(message.quotedMessageId)
      ></app-message-item>
    </div>
  </ng-container>


</div>
<div *ngIf="isChatEmpty" class="no-messages"
     [ngClass]="{'modal-no-messages':isModal}">
  В чате нет сообщений, начните общение сейчас :)
</div>
<div class="whatsapp-footer">
  <app-whatsapp-send-message
    *ngIf="currentCardView; else loading"
    class="footer"
    [phoneOrChatId]="currentCardView?.card?.whatsappPhone || currentCardView?.card?.mobilePhones[0]"
    [phones]="phones"
    [cardId]="currentCardView?.card?.id"
    [lastSearchRequest]="currentCardView.searchRequests[0]"
    [dealId]="currentCardView?.deal?.id"
  ></app-whatsapp-send-message>

</div>


<ng-template #loading>
  <app-whatsapp-footer-loading
  class="footer"
  [isModal]="isModal"
  >
  </app-whatsapp-footer-loading>
</ng-template>

<ng-template #preloader>
  <app-preloader></app-preloader>
</ng-template>


