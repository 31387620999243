import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnInit,
  output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AudioTranscribeFileRequest,
  AudioTranscriptResponse,
  FindOrCreateAudioTranscriptRequest,
  FindOrCreateAudioTranscriptRequestTypeEnum,
} from '@api-clients/crm-api-client';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EMPTY, fromEvent, Observable } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AmplitudeTrackService } from '../../../../../../../../core/services/amplitude/amplitude-track.service';
import {
  AmplitudeEventData,
  ScreenTypes,
  TranscribedCallTypeEnum,
  TranscribedMessageTypeEnum,
} from '../../../../../../../../core/services/amplitude/amplitudeEventData';
import {
  AmplitudeEventsType,
  AUDIO_CALL_PLAY,
  AUDIO_MESSAGE_PLAY,
  AUDIO_PRESENTATION_PLAY,
  AUDIO_PRESENTATION_TO_TEXT_CONVERT_CLICK,
  AUDIO_RECORDING_PLAY,
  AUDIO_RECORDING_TO_TEXT_CONVERT_CLICK,
  CALL_TO_TEXT_CONVERT_CLICK,
  VOICE_MESSAGE_TO_TEXT_CONVERT_CLICK,
} from '../../../../../../../../core/services/amplitude/amplitudeEvents';
import { AudioType } from './interfaces/audio.interface';
import { AudioTranscriptService } from './services/audio-transcript.service';
import { AudioService } from './services/audio.service';

enum PlaybackRate {
  normal = 1,
  oneAndHalf = 1.5,
  double = 2,
}

/**
 * Заглушка применяется при отсутствии Duration, чтобы не сломался проигрыватель.
 */
const DummyDuration = 777;

@Directive()
export abstract class BaseAudioDirective extends OnDestroyMixin implements OnInit, AfterViewInit {
  @Input() public recordingFileLink = '';
  @Input() public duration = DummyDuration;
  @Input() public type!: AudioType;
  @Input() public isFromMe = false;
  @Input() public mediaSource = '';
  @Input() public readonly screenType: ScreenTypes;
  @Input() public itemId: string | number;
  @Input() public isTranscribeHidden = false;
  @Input() public isTranscriptResultAsTooltip = false;
  public transcriptionResult = output<string>();

  @ViewChild('audio') public audioEl: ElementRef;

  @ViewChild('handleTime') public handleTimeEl: ElementRef;

  @ViewChild('progressLine') progressLine: ElementRef;

  public currentTime = 0;

  public currentVolume = 1;

  public volumeInPercents = 100;

  public playProgress = 0;

  public isPaused = true;

  public playbackRateValue: PlaybackRate;

  private isDragging = false;

  private startMouseMovePosition: number = null;

  private DRAGGING_RESET_TIMEOUT = 0;

  public audioTranscript: string;
  public audioTranscriptErrorText: string;
  public isAudioTranscriptLoading = false;
  public audioTranscriptOpened = false;

  protected constructor(
    private audioService: AudioService,
    private audioTranscriptService: AudioTranscriptService,
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.playbackRateValue = PlaybackRate.oneAndHalf;
  }

  public get progress(): string {
    return `${this.playProgress}%`;
  }

  public get volume(): string {
    return `${this.volumeInPercents}%`;
  }

  public ngAfterViewInit(): void {
    this.playbackRate = this.playbackRateValue;
    this.watchToHandlersEvents();
    this.checkPauseStatus();
  }

  public checkPauseStatus(): void {
    this.audioService.isPaused.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.isPaused = this.audioEl?.nativeElement.paused;
      this.cdRef.markForCheck();
    });
  }

  setActualDuration($event: any): void {
    if (
      this.duration &&
      $event?.target?.duration &&
      this.duration !== $event?.target?.duration &&
      $event?.target?.duration !== Infinity
    ) {
      this.duration = $event?.target?.duration;
    }
    this.cdRef.markForCheck();
    /*
    Записанный файл имеет не определенную длину, по этому ставится длина заглушка
     */
    if (this.duration === Infinity) {
      this.duration = DummyDuration;
    }
  }

  public watchHandlerPosition(handle: ElementRef): Observable<Object> {
    const mousedown$ = fromEvent(handle?.nativeElement, 'mousedown');
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(document, 'mouseup');
    return mousedown$.pipe(
      tap((event: MouseEvent) => {
        this.isDragging = false;
        this.startMouseMovePosition = event.clientX;
      }),
      switchMap((start: any) => {
        return mousemove$.pipe(
          tap(() => {
            this.isDragging = true;
          }),
          map((move: any) => {
            return {
              left: move.clientX - start.offsetX - start.target.parentElement.getBoundingClientRect().x,
              totalWidth: start.target.offsetParent.offsetWidth,
              bottom: move.clientY - start.offsetY - start.target.parentElement.getBoundingClientRect().y,
              totalHeight: start.target.offsetParent.offsetHeight,
            };
          }),
          takeUntil(
            mouseup$.pipe(
              tap((end: MouseEvent) => {
                if (this.startMouseMovePosition !== end.clientX) {
                  setTimeout(() => {
                    this.isDragging = false;
                  }, this.DRAGGING_RESET_TIMEOUT); // Сбрасываем флаг "перетаскивания" асинхронно после определения перетаскивания, чтобы корректно обработать последующую перемотку с помощью this.seek()
                }
                this.startMouseMovePosition = null;
              }),
            ),
          ),
        );
      }),
    );
  }

  public recountVolumeOnDragHandler(position): void {
    this.volumeInPercents =
      100 - Math.ceil(this.audioService.handlePosPxToPercents(position.totalHeight, position.bottom));
    this.currentVolume = this.volumeInPercents / 100;
    this.audioEl.nativeElement.volume = this.currentVolume;
    this.cdRef.markForCheck();
  }

  public recountTimeOnDragHandler(position): void {
    this.playProgress = this.audioService.handlePosPxToPercents(position.totalWidth, position.left);
    this.currentTime = this.audioService.handlePercentsToSec(this.duration, this.playProgress);
    this.audioEl.nativeElement.currentTime = this.currentTime;
    this.cdRef.markForCheck();
  }

  public watchToHandlersEvents(): void {
    if (this.handleTimeEl) {
      this.watchHandlerPosition(this.handleTimeEl)
        .pipe(untilComponentDestroyed(this))
        .subscribe(position => {
          this.recountTimeOnDragHandler(position);
        });
    }
  }

  public watchTimeUpdate(): void {
    fromEvent(this.audioEl.nativeElement, 'timeupdate')
      .pipe(untilComponentDestroyed(this))
      .subscribe((data: any) => {
        this.currentTime = data.srcElement.currentTime;
        this.playProgress = this.audioService.updateTimeProgress(this.currentTime, this.duration);
        this.cdRef.markForCheck();
      });
  }

  public watchAudioHasEnded(): void {
    fromEvent(this.audioEl.nativeElement, 'ended')
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.isPaused = true;
        this.cdRef.markForCheck();
      });
  }

  public play(): void {
    const eventsByType = {
      [AudioType.call]: AUDIO_CALL_PLAY,
      [AudioType.audioMessage]: AUDIO_MESSAGE_PLAY,
      [AudioType.recordedAudio]: AUDIO_RECORDING_PLAY,
      [AudioType.audioPresentation]: AUDIO_PRESENTATION_PLAY,
    };
    const event = eventsByType[this.type] as AmplitudeEventsType;
    if (!event) {
      alert('Не определен тип аудио, невозможно воспроизвести');
      return;
    }
    this.amplitudeTrackService.trackEvent(event, { screenType: this.screenType });
    this.audioService.play(this.audioEl.nativeElement);
    this.watchTimeUpdate();
    this.watchAudioHasEnded();
  }

  public pause(): void {
    this.audioService.pause();
  }

  seek(event: MouseEvent): void {
    if (this.isDragging) {
      return;
    }

    const audio = this.audioEl.nativeElement;
    const progressLineWidth = this.progressLine.nativeElement.offsetWidth;
    const clickX = event.offsetX;
    const { duration } = audio;

    audio.currentTime = (clickX / progressLineWidth) * duration;
  }

  public changePlaybackRate(): void {
    if (this.playbackRateValue === PlaybackRate.normal) {
      this.playbackRateValue = PlaybackRate.oneAndHalf;
    } else if (this.playbackRateValue === PlaybackRate.oneAndHalf) {
      this.playbackRateValue = PlaybackRate.double;
    } else {
      this.playbackRateValue = PlaybackRate.normal;
    }
    this.playbackRate = this.playbackRateValue;
  }

  private set playbackRate(rate: number) {
    if (this.audioEl) {
      this.audioEl.nativeElement.playbackRate = rate;
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public transcribe() {
    const eventsByType = {
      [AudioType.call]: CALL_TO_TEXT_CONVERT_CLICK,
      [AudioType.audioMessage]: VOICE_MESSAGE_TO_TEXT_CONVERT_CLICK,
      [AudioType.recordedAudio]: AUDIO_RECORDING_TO_TEXT_CONVERT_CLICK,
      [AudioType.audioPresentation]: AUDIO_PRESENTATION_TO_TEXT_CONVERT_CLICK,
    };
    const event = eventsByType[this.type] as AmplitudeEventsType;
    if (!event) {
      alert('Не определен тип аудио, невозможно расшифровать');
      return;
    }
    let eventData: AmplitudeEventData = {};
    switch (this.type) {
      case AudioType.call:
        eventData = {
          transcribedCallType: this.isFromMe
            ? TranscribedCallTypeEnum.OUTGOING
            : TranscribedCallTypeEnum.INCOMING,
        };
        break;
      case AudioType.audioMessage:
        eventData = {
          transcribedMessageType: this.isFromMe
            ? TranscribedMessageTypeEnum.OUTGOING
            : TranscribedMessageTypeEnum.INCOMING,
        };
        break;
    }

    this.amplitudeTrackService.trackEvent(event, eventData);
    this.isAudioTranscriptLoading = true;
    this.audioTranscriptErrorText = '';
    const fromPlace = ScreenTypes.CHAT;

    if (this.type === AudioType.recordedAudio) {
      // Записанное на лету аудио нужно транскрибировать по другому, т.к. аудио еще нет в базе.
      // В mediaSource лежит ссылка на blob://, сделаем из нее файл
      const blob$ = this.audioTranscriptService.getBlobByUrl(this.mediaSource);
      blob$
        .pipe(
          switchMap(blob => {
            const file = new File([blob], 'audio/wav', { type: 'audio/wav' });
            const request: AudioTranscribeFileRequest = { file, fromPlace };
            return this.audioTranscriptService.transcribeFile(request);
          }),
          untilComponentDestroyed(this),
          catchError(() => {
            this.audioTranscriptErrorText = 'Ошибка расшифровки аудио';
            return EMPTY;
          }),
          take(1),
          finalize(() => this.finalizeRequest()),
        )
        .subscribe(response => this.handleSuccess({ text: response.message }));
    } else {
      const requestTypesByAudioType = {
        [AudioType.call]: FindOrCreateAudioTranscriptRequestTypeEnum.Call,
        [AudioType.audioMessage]: FindOrCreateAudioTranscriptRequestTypeEnum.WhatsappAudio,
        [AudioType.audioPresentation]: FindOrCreateAudioTranscriptRequestTypeEnum.AudioPresentation,
      };
      const type = requestTypesByAudioType[this.type];
      const request: FindOrCreateAudioTranscriptRequest = {
        type,
        itemId: `${this.itemId}`,
        fromPlace,
      };

      this.audioTranscriptService
        .findOrCreateAudioTranscript(request)
        .pipe(
          untilComponentDestroyed(this),
          catchError(() => {
            this.audioTranscriptErrorText = 'Ошибка расшифровки аудио';
            return EMPTY;
          }),
          take(1),
          finalize(() => this.finalizeRequest()),
        )
        .subscribe(transcript => this.handleSuccess(transcript));
    }
  }

  private finalizeRequest(): void {
    this.isAudioTranscriptLoading = false;
    this.toggleResult();
    this.cdRef.markForCheck();
  }

  private handleSuccess(transcript: AudioTranscriptResponse): void {
    if (transcript) {
      this.transcriptionResult.emit(transcript.text);
      this.audioTranscript = transcript.text;
    }
  }

  public toggleResult() {
    this.audioTranscriptOpened = !this.audioTranscriptOpened;
  }
}
