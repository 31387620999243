import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-favorite-hotels-tab-skeleton',
  template: `<div class="skeleton-line" [style.height.px]="height"></div>`,
  styles: [`
      .skeleton-line {
          display: inline-block;
          width: 100%;
          background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
          background-size: 200% 100%;
          animation: skeleton-loading 1.5s infinite linear;
      }

      @keyframes skeleton-loading {
          0% {
              background-position: 200% 0;
          }
          100% {
              background-position: -200% 0;
          }
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SkeletonComponent {
  @Input() height = '58';
}
