import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp-client-card-loading',
  templateUrl: './whatsapp-client-card-loading.component.html',
  styleUrls: ['./whatsapp-client-card-loading.component.scss'],
})
export class WhatsappClientCardLoadingComponent {
  public isShowCardInfo = false;

  showClientCard() {
    this.isShowCardInfo = true;
  }

  hideClientCard() {
    this.isShowCardInfo = false;
  }
}
