import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultResponse } from '@api-clients/crm-api-client';
import { ApiResponse } from '../../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../../api-response.pipe';
import { DealFinishRequestDto } from '../../../../../../../../../backend/src/models/deal-create.model';

@Injectable({
  providedIn: 'root',
})
export class FinishDealService {
  constructor(private http: HttpClient) {}

  finishDeal(body: DealFinishRequestDto) {
    return this.http
      .post<ApiResponse<DefaultResponse>>(`${AppConfig.apiUrl}/deal/finish`, body)
      .pipe(apiResponsePipe);
  }
}
