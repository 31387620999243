<div class="reasons">
      <span
        *ngFor="let reason of COMPLETE_REASONS"
        (click)="selectType(reason)"
        [ngClass]="{
        '--disabled': isListDisabled,
        '--active': !isListDisabled && activeReasonKey === reason }"
        class="reason-item"
      >
        {{ 'PAGES.DEALS.COMPLETE_DEAL.COMPLETE_REASONS.' + reason.key | translate }}
      </span>
</div>
