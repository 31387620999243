
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-hot-offers-active-filter-value',
  templateUrl: './hot-offers-active-filter-value.component.html',
  styleUrls: ['./hot-offers-active-filter-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class HotOffersActiveFilterValueComponent {
  @Input() label!: string;
}
