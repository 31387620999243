import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DealViewItem } from '@api-clients/crm-api-client/dist/models/deal-view-item';

@Component({
  selector: 'app-whatsapp-client-card',
  templateUrl: './whatsapp-client-card.component.html',
  styleUrls: ['./whatsapp-client-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsappClientCardComponent implements OnChanges {
  @Input() dealViewItem: DealViewItem;
  @Input() isModal: boolean;
  @Input() isFromDealView: boolean;
  public currentDealViewItem: DealViewItem;
  public isShowCardInfo = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  showClientCard() {
    this.isShowCardInfo = true;
    this.cdRef.detectChanges();
  }

  hideClientCard() {
    this.isShowCardInfo = false;
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dealViewItem?.currentValue !== changes.dealViewItem?.previousValue) {
      this.currentDealViewItem = changes.dealViewItem.currentValue;
      this.cdRef.detectChanges();
    }
  }
}
