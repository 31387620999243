import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsCenterListComponent } from './components/notifications-center-list/notifications-center-list.component';
import { NotifyCenterItemComponent } from './components/notify-center-item/notify-center-item.component';
import { TourPackageClaimConfirmedComponent } from './components/notify-center-item/components/tour-package-claim-confirmed/tour-package-claim-confirmed.component';
import { DealAssignedComponent } from './components/notify-center-item/components/deal-assigned/deal-assigned.component';
import { SharedUtilityModule } from '../../../../../../libs/shared/utility/src';
import { TourPackageDocsListChangedComponent } from './components/notify-center-item/components/tour-package-docs-list-changed/tour-package-docs-list-changed.component';
import { TourPackageNewPaymentComponent } from './components/notify-center-item/components/tour-package-new-payment/tour-package-new-payment.component';
import { TourPackageClaimOutdatedComponent } from './components/notify-center-item/components/tour-package-claim-outdated/tour-package-claim-outdated.component';
import { TimeSincePipe } from './pipes/time-since.pipe';
import { TourPackageDocsReadyComponent } from './components/notify-center-item/components/tour-package-docs-ready/tour-package-docs-ready.component';

@NgModule({
  declarations: [
    NotificationsCenterListComponent,
    NotifyCenterItemComponent,
    TourPackageClaimConfirmedComponent,
    DealAssignedComponent,
    TourPackageDocsListChangedComponent,
    TourPackageNewPaymentComponent,
    TourPackageClaimOutdatedComponent,
    TimeSincePipe,
    TourPackageDocsReadyComponent,
  ],
  imports: [CommonModule, TranslateModule, SharedUtilityModule],
})
export class NotificationsCenterModule {}
