import { Component, Input, OnInit } from '@angular/core';
import { SmsItem } from '@api-clients/crm-api-client/dist/models';

@Component({
  selector: 'app-chat-timeline-item-sms',
  templateUrl: './chat-timeline-item-sms.component.html',
  styleUrls: ['./chat-timeline-item-sms.component.scss'],
})
export class ChatTimelineItemSmsComponent implements OnInit {
  @Input() smsItem: SmsItem;
  public smsText: string;
  public smsAuthor: string;

  ngOnInit(): void {
    this.smsText = this.smsItem.text;
    this.smsAuthor = this.smsItem?.worker?.name;
  }
}
