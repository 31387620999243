import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PostSaleListItemModule } from './components/post-sale-list-item/post-sale-list-item.module';
import { DealsListPostSaleContentComponent } from './deals-list-post-sale-content.component';

@NgModule({
  declarations: [DealsListPostSaleContentComponent],
  imports: [CommonModule, PostSaleListItemModule, TranslateModule],
  exports: [DealsListPostSaleContentComponent],
})
export class DealsListPostSaleContentModule {}
