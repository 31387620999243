import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientCardNameComponent } from './client-card-name.component';
import { ClientPhoneComponent } from './components/client-phone/client-phone.component';
import { ClientStatusTagsComponent } from './components/client-status-tags/client-status-tags.component';
import { ClientStatusTagHelperPopupComponent } from './components/client-status-tags/components/client-status-tag-helper-popup/client-status-tag-helper-popup.component';
import { AppRoutingModule } from '../../../../../../app-routing.module';
import { SharedUtilityModule } from '../../../../../../../../../../libs/shared/utility/src';
import { SharedModule } from '../../../../../../shared/shared.module';
import { ClientStatusIconModule } from '../client-card-info/client-status-icon/client-status-icon.module';

@NgModule({
  declarations: [
    ClientCardNameComponent,
    ClientPhoneComponent,
    ClientStatusTagsComponent,
    ClientStatusTagHelperPopupComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedUtilityModule,
    SharedModule,
    SharedModule,
    ClientStatusIconModule,
  ],
  exports: [ClientCardNameComponent, ClientPhoneComponent, ClientStatusTagsComponent],
})
export class ClientCardNameModule {}
