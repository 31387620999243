// eslint-disable-next-line
import {Component, Input, OnInit} from '@angular/core';
import { CreateTaskService } from '../../../services/create-task.service';
import { Actions, StepsId } from '../../create-task-modal';
import { PopupService } from '../../../../../../../../../shared/services/popup-service.service';
import { PopupFacade } from '../../../../../../../../../+state/popup/popup.facade';
import { AmplitudeTrackService } from '../../../../../../../../../core/services/amplitude/amplitude-track.service';
import { DEAL_LEAVE_AS_IS } from '../../../../../../../../../core/services/amplitude/amplitudeEvents';

@Component({
  selector: 'app-choose-action-step',
  templateUrl: './choose-action-step.component.html',
  styleUrls: ['./choose-action-step.component.scss'],
})
export class ChooseActionStepComponent implements OnInit {
  @Input() isAfterCall: boolean;
  @Input() isNewClient: boolean;
  public actions = Actions;
  public stepsId = StepsId;
  public readonly TRANSLATE_PATH = 'PAGES.DEALS.SALES_FUNNEL.CREATE_TASK_MODAL.CHOOSE_ACTION.';
  public readonly isAfterCallId = StepsId.IS_AFTER_CALL;
  constructor(
    private createTaskService: CreateTaskService,
    private popupFacade: PopupFacade,
    private popupService: PopupService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  ngOnInit(): void {}

  chooseAction(action: Actions, stepId: StepsId) {
    this.createTaskService.setStep(stepId);
  }

  leaveAsIs() {
    this.amplitudeTrackService.trackEvent(DEAL_LEAVE_AS_IS, {
      isAfterCall: this.isAfterCall,
    });
    this.popupService.closeAllModals();
    this.popupFacade.setPopupStatus({
      popupShow: false,
      isBlocked: false,
    });
  }
}
