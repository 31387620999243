@if (countries$ | async; as countries) {
  @for (country of countries; track country) {
    <div class="country-container">
      <div class="country-info-container">
        <div class="country-name">
          <img [attr.src]="'https://ht.kz/img/flag/' + country.countryCode + '.png'"
            class="country-flag">
          {{ country.countryName }}
        </div>
        <div class="country-tours-count">
          <app-hot-offers-country-tours-count [departCityId]="departCityId"
            [countryId]="country.countryId" />
        </div>
      </div>
      <div class="country-tours">
        <app-hot-offers-country-hotels [departCityId]="departCityId"
          [countryId]="country.countryId" />
      </div>
    </div>
  }
}
