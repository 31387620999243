import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CallItem } from '@api-clients/crm-api-client/dist/models';
import {
  Notify,
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../shared/notify/services/notify.service';
import { ConfigsService } from '../../../../../../core/services';
import { AudioType } from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/interfaces/audio.interface';
import { TourPackagesWithoutAudioReviewPopUpService } from '../../../tourpackages-for-mark-as-audioreview/services/tourpackages-for-mark-as-audioreview-popup.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';

enum CallType {
  'income' = 0,
  'outcome' = 1,
  'callback' = 4,
}

@Component({
  selector: 'app-call-item',
  templateUrl: './call-item.component.html',
  styleUrls: ['./call-item.component.scss'],
})
export class CallItemComponent implements OnInit {
  @Input() callItem: CallItem;
  @ViewChild('dropDownLink') dropDownLink: ElementRef;
  public showLink = false;
  public CALL_TYPE = CallType;
  public secretMask: string;
  public isPhoneHidden = true;
  public readonly dateFormat = 'dd.MM.YYYY, HH:mm';
  public readonly screenType: ScreenTypes = ScreenTypes.CALL_LIST;
  public readonly audioCallType = AudioType.call;

  constructor(
    private notifyService: NotifyService,
    private configsService: ConfigsService,
    private tourPackagesModalService: TourPackagesWithoutAudioReviewPopUpService,
  ) {}

  ngOnInit(): void {
    this.secretMask = this.configsService.getPhoneHiddenMaskByCode(this.callItem?.phone?.code);
  }

  openCallLinkModal(): void {
    this.showLink = !this.showLink;
    if (this.showLink) {
      this.dropDownLink.nativeElement.style.display = 'block';
    } else {
      this.dropDownLink.nativeElement.style.display = 'none';
    }
  }
  showNotify(): void {
    const notify: Notify = {
      title: 'Ссылка скопирована',
      text: 'Ссылка успешно скопирована!',
      type: NotifyTypeEnum.success,
    };
    this.notifyService.create(notify);
  }

  showTourPackagesForMarkAsAudioReview(): void {
    this.tourPackagesModalService.tourPackagesForMarkAsAudioReview(this.callItem.id);
  }
}
