import
  {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WhatsappMessageTemplate } from '@api-clients/crm-api-client';
import { Subject, first, takeUntil } from 'rxjs';
import { WhatsappTemplatesApiService } from './services/whatsapp-templates-api.service';

@Component({
  selector: 'app-whatsapp-templates-settings',
  templateUrl: './whatsapp-templates-settings.component.html',
  styleUrls: ['./whatsapp-templates-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsappTemplatesSettingsComponent implements OnInit, OnDestroy {
  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();
  public templates: WhatsappMessageTemplate[] = [];
  public isLoading = false;
  public currentTemplate: WhatsappMessageTemplate;
  public fromCardViewId?: number;
  private destroy$ = new Subject<void>();

  constructor(
    private whatsappTemplatesApiService: WhatsappTemplatesApiService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.refreshTemplateList();
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const fromCardView = params['fromCardView'];
      if (fromCardView) {
        this.fromCardViewId = +fromCardView;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  back() {
    this.backEvent.emit();
    if (this.fromCardViewId) {
      this.router.navigate([`/deals/view/${this.fromCardViewId}`]).then();
    }
  }

  refreshTemplateList() {
    this.isLoading = true;
    this.whatsappTemplatesApiService
      .getTemplates()
      .pipe(first())
      .subscribe({
        next: templates => {
          if (templates) {
            this.templates = templates;
            this.isLoading = false;
            this.cdRef.markForCheck();
          }
        },
        error: error => {
          console.error('Failed to load templates', error);
          this.isLoading = false;
          this.cdRef.markForCheck();
        },
      });
  }

  editTemplate(event: WhatsappMessageTemplate) {
    this.currentTemplate = event;
    this.cdRef.markForCheck();
  }
}
