import { Pipe, PipeTransform } from '@angular/core';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { DealListService } from '../../../services/deal-list.service';

@Pipe({
  name: 'dealFilter',
  pure: false,
})
export class DealFilterPipe implements PipeTransform {
  constructor(private dealListService: DealListService) {}

  transform(deals: CrmCardViewItem[]): { todayDeals: CrmCardViewItem[]; otherDeals: CrmCardViewItem[] } {
    if (!deals) {
      return { todayDeals: [], otherDeals: [] };
    }

    const todayDeals = deals.filter(deal => this.dealListService.isTodayDeal(deal));
    const otherDeals = deals.filter(deal => !this.dealListService.isTodayDeal(deal));

    return { todayDeals, otherDeals };
  }
}
