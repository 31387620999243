<div class="reservations">
  <div class="reservations-header">
    <p class="title">{{ 'PAGES.DEALS.CLIENT_CARD.RESERVATIONS_HISTORY' | translate }}</p>
    <button class="open-all" *ngIf="tpStats.count > 0" (click)="showReservationsList()">
      <span>{{ 'PAGES.DEALS.CLIENT_CARD.ALL_RESERVATIONS' | translate }}</span>
      <img src="assets/icons/deal-view/arrow-right.svg" alt="arrow" />
    </button>
  </div>
  <div class="amount-info">
    <div class="info">
      <p>{{ 'PAGES.DEALS.CLIENT_CARD.TOTAL' | translate }}</p>
      <span>{{ tpStats.count }}</span>
    </div>
    <div class="info">
      <p>{{ 'PAGES.DEALS.CLIENT_CARD.AMOUNT' | translate }}</p>
      <span>{{ tpStats.sum | mask : 'separator' }} ₸</span>
    </div>
  </div>

  <ng-container *ngIf="tourPackage; else emptyTourPackage">
    <div class="tour-chip">
      <div class="chip-header">
        <span class="direction">
          <a (click)="reservationOpened()" [externalURL]="tourPackage?.link" libExternalLink>
            <ng-container *ngIf="tourPackage.tours[0] as tour">
              {{ tour.country?.name }}
              <ng-container *ngIf="tour.region">, ({{ tour.region?.name }})</ng-container>
            </ng-container>
          </a>
        </span>
        <span class="amount"> {{ tourPackage.totalCost | mask : 'separator' }} ₸ </span>
      </div>
      <div class="hotel" *ngIf="tourPackage.tours[0] as tour">
        <span class="name">{{ tour.hotel?.name }}</span>
        <span class="date"
          >{{ tour.checkInDate | date : dateFormat }} - {{ tour.checkOutDate | date : dateFormat }}</span
        >
      </div>
      <div class="additional-info">
        <div class="left-group">
          <span *ngIf="tourPackage.isConfirmed" class="confirmed">
            {{ 'PAGES.DEALS.CLIENT_CARD.TOUR_PACKAGES.CONFIRMED' | translate }}
          </span>
          <span class="worker" *ngIf="tourPackage.manager as worker">
            <img src="assets/icons/deal-view/worker.svg" alt="worker" />
            <p>{{ worker?.name }}</p>
          </span>
        </div>
        <span class="review" *ngIf="newUi$ | async">
          <app-last-review-button
            [reservation]="tourPackage"
            [screenType]="screenType"
          ></app-last-review-button>
        </span>
      </div>
    </div>
  </ng-container>

  <ng-template #emptyTourPackage>
    <div class="empty-tour-package">
      <img src="assets/icons/deal-view/deadline.svg" alt="deadline" />
      <span>{{ 'PAGES.DEALS.CLIENT_CARD.TOUR_PACKAGES.EMPTY' | translate }}</span>
    </div>
  </ng-template>
</div>
