import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AppConfiguration,
  CityItem,
  CountryPhoneFormat,
  CrmTaskIntentItem,
} from '@api-clients/crm-api-client/dist/models';
import { Observable, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';
import { DealSource, DealsCategory, Worker } from '../../../models';
import { ElectronService } from '../../../../../../../libs/shared/utility/src/lib/services/electron.service';
import {
  SetCountryCodesEvent,
  SetCurrentWorkerCityIdEvent,
  SetCurrentWorkerEvent,
} from '../../../models/ipcEvent';

const PHONE_MASK_PATTERN = new RegExp(/9/g);
const PHONE_MASK_REPLACE_VALUE = '0';

@Injectable({
  providedIn: 'root',
})
export class ConfigsService {
  public dealSources$: Observable<DealSource[]>;
  private dealSourcesSub$: BehaviorSubject<DealSource[]>;
  public dealsCategories$: Observable<DealsCategory[]>;
  private dealsCategoriesSub$: BehaviorSubject<DealsCategory[]>;
  public phoneConfig$: Observable<CountryPhoneFormat[]>;
  private phoneConfigSub$: BehaviorSubject<CountryPhoneFormat[]>;
  public adminUrl$: Observable<string>;
  private adminUrlSub$: BehaviorSubject<string>;
  public cities$: Observable<CityItem[]>;
  private citiesSub$: BehaviorSubject<CityItem[]>;
  public taskIntents$: Observable<CrmTaskIntentItem[]>;
  private taskIntentsSubject: BehaviorSubject<CrmTaskIntentItem[]>;
  public cityId$: Observable<number>;
  private cityIdSub$: BehaviorSubject<number>;

  constructor(private http: HttpClient, private electronService: ElectronService) {
    this.dealsCategoriesSub$ = new BehaviorSubject<DealsCategory[]>(null);
    this.dealsCategories$ = this.dealsCategoriesSub$.asObservable();

    this.citiesSub$ = new BehaviorSubject<CityItem[]>(null);
    this.cities$ = this.citiesSub$.asObservable();

    this.dealSourcesSub$ = new BehaviorSubject<DealSource[]>(null);
    this.dealSources$ = this.dealSourcesSub$.asObservable();

    this.phoneConfigSub$ = new BehaviorSubject<CountryPhoneFormat[]>(null);
    this.phoneConfig$ = this.phoneConfigSub$.asObservable();

    this.cityIdSub$ = new BehaviorSubject<number>(null);
    this.cityId$ = this.cityIdSub$.asObservable();

    this.adminUrlSub$ = new BehaviorSubject<string>(null);
    this.adminUrl$ = this.adminUrlSub$.asObservable();

    this.taskIntentsSubject = new BehaviorSubject<CrmTaskIntentItem[]>(null);
    this.taskIntents$ = this.taskIntentsSubject.asObservable();
  }

  set dealsCategories(categories: DealsCategory[]) {
    this.dealsCategoriesSub$.next(categories);
  }

  get dealsCategories(): DealsCategory[] {
    return this.dealsCategoriesSub$.value;
  }

  set cities(cities: CityItem[]) {
    this.citiesSub$.next(cities);
  }

  get cities(): CityItem[] {
    return this.citiesSub$.value;
  }

  set dealSources(sources: DealSource[]) {
    this.dealSourcesSub$.next(sources);
  }

  get dealSources(): DealSource[] {
    return this.dealSourcesSub$.value;
  }

  set taskIntents(intentTasks: CrmTaskIntentItem[]) {
    this.taskIntentsSubject.next(intentTasks);
  }

  get taskIntents(): CrmTaskIntentItem[] {
    return this.taskIntentsSubject.value;
  }

  getIntentDescriptionByAlias(intentAlias: string): CrmTaskIntentItem {
    return this.taskIntents.find(intent => intent.alias === intentAlias);
  }

  set phoneConfig(phoneConfig: CountryPhoneFormat[]) {
    this.replaceMaskDigits(phoneConfig).then(() => {
      const eventData: SetCountryCodesEvent = {
        event: 'country-codes',
        phoneConfig,
      };
      this.electronService.ipcRenderer.send('send-to-notification', eventData);

      this.phoneConfigSub$.next(phoneConfig);
    });
  }

  set currentWorker(worker: Worker) {
    const eventData: SetCurrentWorkerEvent = {
      event: 'current-worker',
      worker,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
  }

  set cityId(cityId: number) {
    const eventData: SetCurrentWorkerCityIdEvent = {
      event: 'current-worker-city-id',
      cityId,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
    this.cityIdSub$.next(cityId);
  }

  set workerList(cityId) {
    this.fetchWorkersList(cityId)
      .pipe(first())
      .subscribe((workers: Worker[]) => {
        this.electronService.ipcRenderer.send('send-to-notification', {
          event: 'worker-list',
          list: workers,
        });
      });
  }

  get phoneConfig(): CountryPhoneFormat[] {
    return this.phoneConfigSub$.value;
  }

  getPhoneHiddenMaskByCode(code: string): string | undefined {
    const countryPhone: CountryPhoneFormat = this.phoneConfigSub$.value.find(
      (item: CountryPhoneFormat) => item.mobilePhoneCode === code,
    );
    return countryPhone?.mobilePhoneSecretMask;
  }

  getPhoneMaskByCode(code: string): string | undefined {
    const countryPhone: CountryPhoneFormat = this.phoneConfigSub$.value.find(
      (item: CountryPhoneFormat) => item.mobilePhoneCode === code,
    );
    return countryPhone?.mobilePhoneMask;
  }

  replaceMaskDigits(phoneConfigs: CountryPhoneFormat[]): Promise<void> {
    return new Promise<void>(resolve => {
      phoneConfigs.forEach(item => {
        item.mobilePhoneMask = item.mobilePhoneMask.replace(PHONE_MASK_PATTERN, PHONE_MASK_REPLACE_VALUE);
        item.mobilePhoneSecretMask = item.mobilePhoneSecretMask.replace(
          PHONE_MASK_PATTERN,
          PHONE_MASK_REPLACE_VALUE,
        );
      });
      resolve();
    });
  }

  set adminUrl(adminUrl: string) {
    this.adminUrlSub$.next(adminUrl);
  }

  get adminUrl(): string {
    return this.adminUrlSub$.value;
  }

  getConfiguration() {
    return this.http
      .get<ApiResponse<AppConfiguration>>(`${AppConfig.apiUrl}/configuration/get`)
      .pipe(apiResponsePipe);
  }

  fetchWorkersList(cityId: number): Observable<Worker[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('cityId', `${cityId}`);
    return this.http
      .get<ApiResponse<Worker[]>>(`${AppConfig.apiUrl}/worker/list`, { params: queryParams })
      .pipe(apiResponsePipe);
  }
}
