<div class="phones">
  <ng-container
    *ngFor="let phone of phones.controls;
    let idx = index; let last = last;
      let first = first">
    <div
         [ngClass]="{'--hovered': isHoveredIdx === idx}"
         class="phone"
    >
      <app-phone-input-with-code
        [parentForm]="parentForm"
        [formControlItem]="phone"
        [concatenateCountryCode]="true"
        [formControlNameValue]="idx"
        [phoneItemWithCode]="phoneItems[idx]"
        [formArrayMode]="'phones'"
        [readOnly]="true"
        class="click-to-call"
      >
      </app-phone-input-with-code>
      <button
        class="click-to-call-btn"
        (click)="callToPhone(phoneItems[idx], clientName)"
        (mouseenter)="isHoveredIdx = idx"
        (mouseleave)="isHoveredIdx = null"
      >
        <img src="assets/icons/deal-view/click2call.svg" alt="click2call">
      </button>
    </div>


  </ng-container>
</div>

