<div #slider class="slider">
  <ul>
    <li *ngFor="let image of images; let idx = index"
    class="item loading"
        [ngClass]="{
          current: idx === currentImageIdx,
          'is-selected': isImageSelected(image)
        }"
    >
      <img
        alt="image"
        [src]="image?.previewUrl || image?.contentLink"
        (click)="setActiveImage(idx)"
        class="slider-image"/>
      <app-checkbox-rounded
        *ngIf="isSelectionEnabled"
        [isChecked] = "isImageSelected(image)"
        (changed)="selectImage($event, image)"/>
    </li>
  </ul>
</div>

<div class="image-viewer">
  <div class="image-viewer-controls">
    <button
      (click)="previousImage()"
      [disabled]="currentImageIdx === 0">
      <img src="assets/icons/whatsapp/slide-left.svg" alt="left"/>
    </button>
    <button
      (click)="nextImage()"
      [disabled]="currentImageIdx === images.length-1">
      <img src="assets/icons/whatsapp/slide-right.svg" alt="left"/>
    </button>
  </div>
  <div *ngIf="currentImage" class="image-viewer-content">
    <img *ngIf="!currentImage.isVideo"
         [src]="currentImage.contentLink"
         alt="image"
         class="full-screen"
         loading="lazy"
         decoding="async"
    />
    <video *ngIf="currentImage.isVideo"
           class="full-screen"
           [src]="currentImage.contentLink"
           controls
           autoplay>
    </video>
    <div *ngIf="currentImage.text" class="image-text">{{ currentImage.text }}</div>
  </div>
  <div class="head-controls">
    <a [externalURL]="currentImage?.contentLink"
       libExternalLink
    >
      <img class="download" src="assets/icons/whatsapp/download.svg" title="Скачать" alt="download"/>
    </a>
    <button (click)="closed.emit()">
      <img class="close" src="assets/icons/whatsapp/close.svg" title="Закрыть" alt="close"/>
    </button>
  </div>
</div>

<div class="overlay">
</div>
