import { Component, Input, OnInit } from '@angular/core';
import { CallItem } from '@api-clients/crm-api-client/models/call-item';
import { ScreenTypes } from '../../../../../../../../core/services/amplitude/amplitudeEventData';
import { AudioType } from '../audio/interfaces/audio.interface';

enum CallType {
  'income' = 0,
  'outcome' = 1,
  'callback' = 4,
}

@Component({
  selector: 'app-last-action-call',
  templateUrl: './last-action-call.component.html',
  styleUrls: ['./last-action-call.component.scss'],
})
export class LastActionCallComponent implements OnInit {
  @Input() lastActionItem: CallItem;
  @Input() actionsDateFormat: string;

  public CALL_TYPE = CallType;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_LISTING;
  public readonly audioCallType = AudioType.call;

  ngOnInit(): void {}
}
