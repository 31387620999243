import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExperimentalDaysStages, SalesFunnelStage } from '../../../../deal-list';
import { CurrentStageEnum } from '../../../../../deal-view/modules/sales-funnel-stages/sales-funnel-stages';

@Component({
  selector: 'app-deals-menu-stages-list',
  templateUrl: './deals-menu-stages-list.component.html',
  styleUrls: ['./deals-menu-stages-list.component.scss'],
})
export class DealsMenuStagesListComponent implements OnInit {
  @Input() stages: SalesFunnelStage[] = [];
  @Input() selectedStage?: SalesFunnelStage;
  @Input() isNewUi?: boolean;
  @Output() selectStage = new EventEmitter<SalesFunnelStage>();

  public filteredStages: SalesFunnelStage[] = [];

  ngOnInit(): void {
    const experimentalDaysNames = Object.values(ExperimentalDaysStages);

    if (this.isNewUi) {
      this.filteredStages = this.stages.filter(stage => {
        return stage.name !== CurrentStageEnum.feedback;
      });
    } else {
      this.filteredStages = this.stages.filter(stage => {
        return !experimentalDaysNames.includes(stage.name as ExperimentalDaysStages);
      });
    }
  }
}
