import { Action } from '@ngrx/store';
import { WorkerGetConfigResult } from '@api-clients/api-client';

export enum WorkerConfigActionTypes {
  LoadConfig = '[Worker Config] Load Whatsapp Config',
  LoadedConfig = '[Worker Config] Loaded Whatsapp Config',
  LoadedConfigError = '[Worker Config] Loaded Whatsapp Config',
  ToggleAsideChatsScrollBar = '[Worker Config] Toggle Aside Chats Scroll Bar',
  SetAsideChatsScrollBar = '[Worker Config] Set Aside Chats Scroll Bar',
}

export class LoadConfig implements Action {
  readonly type = WorkerConfigActionTypes.LoadConfig;
}
export class LoadedConfig implements Action {
  readonly type = WorkerConfigActionTypes.LoadedConfig;
  constructor(public payload: WorkerGetConfigResult) {}
}

export class LoadedConfigError implements Action {
  readonly type = WorkerConfigActionTypes.LoadedConfigError;
  constructor(public payload: any) {}
}

export class ToggleAsideChatsScrollBar implements Action {
  readonly type = WorkerConfigActionTypes.ToggleAsideChatsScrollBar;
}

export class SetAsideChatsScrollBar implements Action {
  readonly type = WorkerConfigActionTypes.SetAsideChatsScrollBar;
  constructor(public payload: boolean) {}
}

export type WorkerConfigActions =
  | LoadConfig
  | LoadedConfig
  | LoadedConfigError
  | SetAsideChatsScrollBar
  | ToggleAsideChatsScrollBar;
