import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tour-package-number',
  templateUrl: './tour-package-number.component.html',
  styleUrls: ['./tour-package-number.component.scss'],
})
export class TourPackageNumberComponent {
  @Input() id: number;
  @Input() link: string;
}
