import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => SelectComponent) }],
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  public text = '';
  @HostBinding('class.open') isOpen = false;
  @Input() selectedValue: any;
  @Input() options: [any];
  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() placeholder: string;
  @Input() hash: string;
  @Input() firstOptionAsDefault: boolean;
  @Input() isListDirectionUp: boolean = false;
  @Input() defaultValue: any;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  private onChange: any;
  private onTouch: any;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.firstOptionAsDefault && this.options && this.options[0]) {
      this.select(this.options[0]);
      this.cdRef.detectChanges();
    }
    if (this.defaultValue && this.options) {
      for (const option of this.options) {
        if (option[this.optionValue] === this.defaultValue) {
          this.select(option);
          this.cdRef.detectChanges();
          return;
        }
      }
    }
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
    this.updateText();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  close(): void {
    this.isOpen = false;
  }

  open(): void {
    this.isOpen = true;
  }

  select(val: any): void {
    this.selectedValue = val;
    this.change.emit(this.selectedValue);
    this.updateText();
    this.close();
    if (this.onChange && this.selectedValue) {
      this.onChange(this.selectedValue[this.optionValue]);
    }
  }

  private updateText(): void {
    if (this.selectedValue) {
      const newText = this.selectedValue[this.optionLabel];
      if (newText){
        this.text = newText;
      }
    }
    }

  ngOnChanges(changes: SimpleChanges): void {


    if (changes.options){
      for (const option of this.options) {
          if (option[this.optionValue] === this.defaultValue) {
            this.select(option);
            return;
          }
      }
    }

    if (changes.selectedValue?.currentValue !== undefined){
      this.select(this.options.find(option => option[this.optionValue] === changes.selectedValue?.currentValue));
      this.cdRef.detectChanges();
    }
  }
}
