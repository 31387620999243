import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentOutcomeCallFacade } from '../../../../../../../../+state/current-outcome-call/current-outcome-call.facade';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-current-call',
  templateUrl: './current-call.component.html',
  styleUrls: ['./current-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class CurrentCallComponent {
  public currentCall$ = this.currentOutcomeCallFacade.currentOutcomeCall$;

  constructor(private currentOutcomeCallFacade: CurrentOutcomeCallFacade) {}
}
