import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DealsFacade } from '../../../../../../../../+state/deals/deals.facade';
import { AddDealPopupService } from '../../../../../add-deal/services/add-deal-popup.service';
import { Readiness } from '../../../../deal-list';
import { FeatureToggleService } from '../../helpers/feature-toggle.service';

@UntilDestroy()
@Component({
  selector: 'app-deal-list-content-header',
  templateUrl: './deal-list-content-header.component.html',
  styleUrls: ['./deal-list-content-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealListContentHeaderComponent implements OnInit {
  @Input() stageTitle: string;
  @Input() selectedFilterByReadiness: Readiness;
  @Input() hasDeals: boolean;
  public isDealListNewUi$: Observable<boolean>;
  public isRefreshing: boolean;
  constructor(
    private addDealPopupService: AddDealPopupService,
    private dealsFacade: DealsFacade,
    private cdRef: ChangeDetectorRef,
    private featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this.isDealListNewUi$ = this.featureToggleService.shouldDisplayNewFeature$;
  }

  public manualRefresh(): void {
    this.isRefreshing = true;
    this.dealsFacade.loadAll();
    setTimeout(() => {
      this.isRefreshing = false;
      this.cdRef.detectChanges();
    }, 1000);
  }

  public showAddDealPopup() {
    this.addDealPopupService.showAddDealPopup();
  }
}
