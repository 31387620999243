import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DatetimepickerComponent } from './datetimepicker.component';
import { SharedModule } from '../../../../../../shared/shared.module';

@NgModule({
  declarations: [DatetimepickerComponent],
  imports: [CommonModule, FullCalendarModule, SharedModule],
  exports: [DatetimepickerComponent],
})
export class DatetimepickerModule {}
