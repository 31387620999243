import { Component, Input, OnInit } from '@angular/core';
import { LastWhatsappMessageItem } from '@api-clients/crm-api-client/models/last-whatsapp-message-item';
import { WhatsappService } from '../../../../../../../whatsapp/services/whatsapp.service';

@Component({
  selector: 'app-last-action-whatsapp-message',
  templateUrl: './last-action-whatsapp-message.component.html',
  styleUrls: ['./last-action-whatsapp-message.component.scss'],
})
export class LastActionWhatsappMessageComponent implements OnInit {
  @Input() lastActionItem: LastWhatsappMessageItem;
  @Input() actionsDateFormat: string;
  public messageText: string;
  constructor(private whatsappService: WhatsappService) {}

  ngOnInit(): void {
    this.messageText = this.whatsappService.parseShortMessage(this.lastActionItem, 50);
  }
}
