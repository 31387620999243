import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY } from './manager-state.reducers';
import { ManagerSipStateInterface } from './manager-state.state';

export const getManagerStateFeature = createFeatureSelector<ManagerSipStateInterface>(USER_FEATURE_KEY);

export const getManagerSipState = createSelector(
  getManagerStateFeature,
  (payload: ManagerSipStateInterface) => payload,
);
