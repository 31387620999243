<div class="filter"
     [ngClass]="{
  'active': isActive || (isHover && !isDisabled),
  'disabled': isDisabled}"
     (mouseover)="isHover=true"
     (mouseout)="isHover=false">
  <span class="text" [ngClass]="{
  'active': isActive || (isHover && !isDisabled),
  'disabled': isDisabled}">
    {{'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.FILTER.'+filter | translate}}
    <div #tooltip="matTooltip"
      matTooltip="{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.FILTER_TOOLTIP' | translate }}"
      [matTooltipPosition]="'above'"
      matTooltipHideDelay="100"
      *ngIf="count > 0">({{count}})
    </div>
  </span>
</div>
