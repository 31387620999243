<ng-container *ngIf="chatMessages$ | async as items">
  <app-chat-time-line-item
    *ngFor="let item of items; let i = index; trackBy: trackByFunction"
    (maximized)="maximized.emit($event)"
    [chatTimeLineItem]="item"
    [isLastOfGroup]="item.isLastOfGroup"
    [isFirstOfGroup]="item.isFirstOfGroup"
  >
  </app-chat-time-line-item>
</ng-container>
