import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByKey',
})
export class FilterByKeyPipe implements PipeTransform {
  transform(items: any[], filterKey: string): any[] {
    if (!items || !filterKey) {
      return items;
    }
    return items.filter(item => item.key.toLowerCase().includes(filterKey.toLowerCase()));
  }
}
