import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  CurrentOutcomeCallState,
  SetCurrentOutcomeCallPayload,
  UpdateCurrentOutcomeCallInfoPayload,
} from './current-outcome-call.interface';
import * as Selectors from './current-outcome-call.selectors';
import {
  ClearCurrentOutcomeCall,
  SetCurrentOutcomeCall,
  UpdateCurrentOutcomeCallInfo,
} from './current-outcome-call.actions';

@Injectable()
export class CurrentOutcomeCallFacade {
  currentOutcomeCall$ = this.store.pipe(select(Selectors.getCurrentCall));
  constructor(private store: Store<CurrentOutcomeCallState>) {}

  setCurrentOutcomeCallState(payload: SetCurrentOutcomeCallPayload) {
    this.store.dispatch(new SetCurrentOutcomeCall(payload));
  }

  updateCurrentOutcomeCallInfo(payload: UpdateCurrentOutcomeCallInfoPayload) {
    this.store.dispatch(new UpdateCurrentOutcomeCallInfo(payload));
  }

  clearCurrentOutcomeCall() {
    this.store.dispatch(new ClearCurrentOutcomeCall());
  }
}
