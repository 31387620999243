import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { IndicatorType } from '../../models/indicator-payload.model';
import { IndicatorEventService } from '../../services/indicator-event.service';
import { CurrentStageEnum, Stage } from '../sales-funnel-stages/sales-funnel-stages';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../../shared/shared.module';

@Component({
  selector: 'app-sales-funnel-stages-mini',
  templateUrl: './sales-funnel-stages-mini.component.html',
  styleUrls: ['./sales-funnel-stages-mini.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class SalesFunnelStagesMiniComponent implements OnInit, OnDestroy {
  private readonly POST_SALE_STATUS = 2;
  @Input() currentStage: Stage;
  @Input() status: number;
  highlightNewClient = false;
  private unsubscribe$ = new Subject<void>();
  CurrentStageEnum = CurrentStageEnum;

  stages: Stage[];
  constructor(private indicatorEventService: IndicatorEventService) {}
  getStageColor(stage: Stage): string {
    const stageColors = {
      [CurrentStageEnum.new]: '#3396F3',
      [CurrentStageEnum.meeting]: '#9BD029',
      [CurrentStageEnum.sendOffer]: '#ED6980',
      [CurrentStageEnum.feedback]: '#FC8800',
      [CurrentStageEnum.finishingTouch]: '#C85DC8',
      [CurrentStageEnum.readyForBooking]: '#FF6347',
    };

    return stageColors[stage] || '#9BD029';
  }

  isActive(stage: Stage): boolean {
    const currentIndex = this.stages.indexOf(this.currentStage);
    const thisIndex = this.stages.indexOf(stage);
    return thisIndex <= currentIndex;
  }

  ngOnInit(): void {
    this.stages =
      this.status === this.POST_SALE_STATUS
        ? [
            CurrentStageEnum.waitConfirm,
            CurrentStageEnum.waitPayment,
            CurrentStageEnum.waitDocs,
            CurrentStageEnum.waitFlight,
            CurrentStageEnum.onRest,
            CurrentStageEnum.review,
          ]
        : [
            CurrentStageEnum.new,
            CurrentStageEnum.sendOffer,
            CurrentStageEnum.feedback,
            CurrentStageEnum.meeting,
            CurrentStageEnum.finishingTouch,
            CurrentStageEnum.readyForBooking,
          ];

    this.indicatorEventService.indicatorEventObservable
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((type: IndicatorType) => {
          if (type === IndicatorType.NewClient) {
            this.highlight();
          }
        }),
        delay(5000),
        tap((type: IndicatorType) => {
          if (type === IndicatorType.NewClient) {
            this.resetAnimation();
          }
        }),
      )
      .subscribe();
  }

  highlight() {
    this.highlightNewClient = true;
  }
  resetAnimation() {
    this.highlightNewClient = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
