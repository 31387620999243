import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DealItemsCounterService } from '../../../deals/services/deal-items-counter.service';
import { ZenmodeAbTestService } from '../../services/zenmode-ab-test.service';
import { WhatsappContactsFacade } from 'app/+state/whatsapp-contacts/whatsapp-contacts.facade';
import { DealsListFacade } from 'app/+state/deals-list/deals-list.facade';
import { DealFlowService } from 'app/modules/deals/modules/deals-list/services/deal-flow.service';
import { CurrentStageEnum } from 'app/modules/deals/modules/deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import { SalesFunnelCategory, SalesFunnelStage } from 'app/modules/deals/modules/deals-list/deal-list';
import { map, Observable } from 'rxjs';
import { FeatureToggleService } from 'app/modules/deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideMenuComponent implements OnInit {
  public dealsTotalNumber$ = this.dealItemsCounterService.dealsNumber$;
  public unreadChatsCount$ = this.whatsappContactsFacade.unreadChats$;
  public isOnlyZenmode$ = this.zenmodeAbTestService.isOnlyZenmodeForWorker$;

  public specificStages$: Observable<SalesFunnelStage[]>;
  public shouldDisplayChats$ = this.featureToggleService.shouldDisplayChats$;
  public readonly currentStageEnum = CurrentStageEnum;

  constructor(
    private zenmodeAbTestService: ZenmodeAbTestService,
    private dealItemsCounterService: DealItemsCounterService,
    private whatsappContactsFacade: WhatsappContactsFacade,
    private dealListFacade: DealsListFacade,
    private dealFlowService: DealFlowService,
    private readonly featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this.specificStages$ = this.dealFlowService.categories$.pipe(
      map((categories: SalesFunnelCategory[]) =>
        categories
          .flatMap(category => category.stages)
          .filter(
            stage =>
              // Укажите необходимые имена этапов для фильтрации
              stage.name === CurrentStageEnum.missed || stage.name === CurrentStageEnum.new,
          ),
      ),
    );
  }

  public openMissedAndUrgentDeals(stage: SalesFunnelStage) {
    this.dealListFacade.setCurrentStage(stage);
  }

  public openNewDeals(stage: SalesFunnelStage) {
    this.dealListFacade.setCurrentStage(stage);
  }
}
