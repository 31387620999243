import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChatRecommendationMarkAsViewedRequest,
  ChatRecommendationMarkMessageAsDeletedRequest,
  ChatRecommendationMarkMessageAsSentRequest,
  ChatRecommendationsGetResponse,
  DefaultResponse,
} from '@api-clients/crm-api-client';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatRecommendationsApiService {
  constructor(private http: HttpClient) {}

  get(): Observable<ChatRecommendationsGetResponse> {
    return this.http.get<ChatRecommendationsGetResponse>(`${AppConfig.crmApiUrl}/chat/recommendations/get`);
  }

  markAsViewed(recommendationId: number): Observable<DefaultResponse> {
    const request: ChatRecommendationMarkAsViewedRequest = {
      recommendationId,
    };
    return this.http.post<DefaultResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/mark-as-viewed`,
      request,
    );
  }

  markMessageAsSent(messageId: number, changedText: string = null): Observable<DefaultResponse> {
    const request: ChatRecommendationMarkMessageAsSentRequest = {
      messageId,
      changedMessageText: changedText,
    };
    return this.http.post<DefaultResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/mark-message-as-sent`,
      request,
    );
  }

  markMessageAsDeleted(messageId: number): Observable<DefaultResponse> {
    const request: ChatRecommendationMarkMessageAsDeletedRequest = {
      messageId,
    };
    return this.http.post<DefaultResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/mark-message-as-deleted`,
      request,
    );
  }
}
