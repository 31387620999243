import { createReducer, on } from '@ngrx/store';
import * as ManagerStateActions from './manager-state.actions';
import { managerSipInitialState } from './manager-state.state';

export const USER_FEATURE_KEY = 'managerSipState';

export const managerStateReducer = createReducer(
  managerSipInitialState,
  on(ManagerStateActions.managerSipState, (state, { payload }) => ({
    ...state,
    state: payload.state,
    value: payload.value,
  })),
);
