import { Pipe, PipeTransform } from '@angular/core';
import { ChatTimeLineItem, FilterListEnum } from '../interfaces/chat-timeline.interface';
import { ChatTimelineService } from '../services/chat-timeline.service';

@Pipe({
  name: 'filterChatItems',
})
export class FilterChatItemsPipe implements PipeTransform {
  constructor(private chatTimelineService: ChatTimelineService) {}

  transform(items: ChatTimeLineItem[], activeFilters: FilterListEnum[]): ChatTimeLineItem[] {
    let filteredItems = items;

    if (activeFilters.length !== 0 && !activeFilters.includes(FilterListEnum.all)) {
      const hasMessageOfferFilter = activeFilters.includes(FilterListEnum.messageOffer);
      filteredItems = items.filter(item => {
        const itemType = item.type as string as FilterListEnum;
        return (
          // Тип должен совпасть по фильтру
          activeFilters.includes(itemType) ||
          // А для подборок туров ищем сообщения чата с привязанным отелем
          (hasMessageOfferFilter && itemType === FilterListEnum.message && item.data?.meta?.hotel)
        );
      });
    }

    this.addLastInGroupFlag(filteredItems);
    return filteredItems;
  }

  private addLastInGroupFlag(items: ChatTimeLineItem[]): void {
    return this.chatTimelineService.addFirstAndLastInGroupFlag(items);
  }
}
