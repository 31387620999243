import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddDealComponent } from '../add-deal.component';

@Injectable({
  providedIn: 'root',
})
export class AddDealPopupService {
  constructor(public matDialog: MatDialog) {}

  showAddDealPopup(phone?: string, name?: string): void {
    this.matDialog.open(AddDealComponent, {
      maxHeight: '675px',
      data: {
        phone,
        name,
      },
    });
  }
}
