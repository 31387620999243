import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WhatsappMessage, WhatsappMessageTypeList } from '@api-clients/crm-api-client';
import { Store } from '@ngrx/store';
import { clearCurrentReplyMessage } from '../../../../../../+state/chat-timeline/chat-timeline.actions';
import { ChatTimelineState } from '../../../../../../+state/chat-timeline/chat-timeline.reducer';

@Component({
  selector: 'app-reply-to-preview',
  templateUrl: './reply-to-preview.component.html',
  styleUrls: ['./reply-to-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReplyToPreviewComponent implements OnInit, OnChanges {
  @Input() message: WhatsappMessage;
  public readonly messageTypes = WhatsappMessageTypeList;
  public messageTextFirstLine: string;
  public currentReplyMessage: WhatsappMessage;

  constructor(private store: Store<ChatTimelineState>) {}

  ngOnInit(): void {
    this.initValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.initValues();
    }
  }

  private initValues() {
    this.messageTextFirstLine = this.getMessageFirstLine();
  }

  getMessageFirstLine(): string {
    return this.message.text.split('\n')[0];
  }

  getMessageAuthor(): string {
    return this.message.isFromMe ? 'Вы' : this.message.contactId.split('@')[0];
  }

  clearCurrentReplyMessage() {
    this.store.dispatch(clearCurrentReplyMessage());
  }
}
