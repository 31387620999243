import { Injectable } from '@angular/core';
import { CrmTaskItem, DealChangedEventDetail } from '@api-clients/crm-api-client';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { CardChangedEventDetail } from '@api-clients/crm-api-client/models';
import { CrmCardListPostSaleItem } from '@api-clients/crm-api-client/models/crm-card-list-post-sale-item';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CardUpdaterService } from '../../modules/deals/modules/deal-view/services/card-updater.service';
import { SortType } from '../../modules/deals/modules/deals-list/deal-list';
import {
  AddShadowTime,
  AddSkippedCard,
  DealChanged,
  DealsChangeSortType,
  DealsPostSaleDropdownClose,
  DealsPostSaleDropdownOpen,
  DealsSelectNextCard,
  DealsSelectPreviousCard,
  GenerateZenmodeList,
  LoadDeals,
  NewMessageReceived,
  SelectedZenmodeCardTouched,
  ShowZenModeIntro,
  ToggleZenmode,
  UpdateLastWhatsappMessage,
} from './deals.actions';
import {
  CardIdPayload,
  DealsState,
  SelectedZenmodeCard,
  SelectedZenmodeCardTouchedPayload,
  UpdateLastWhatsappMessageEvent,
} from './deals.interface';
import { dealsQuery } from './deals.selectors';

@Injectable()
export class DealsFacade {
  dealsList$: Observable<CrmCardViewItem[]> = this.store.select(dealsQuery.getAllDeals);
  sortType$ = this.store.select(dealsQuery.getSortType);
  dealsListPostSale$: Observable<CrmCardListPostSaleItem[]> = this.store.select(
    dealsQuery.getAllPostSaleDeals,
  );
  postSaleDropdownIsOpen$: Observable<boolean> = this.store.select(dealsQuery.getPostSaleDropdownIsOpen);
  asideChatList$ = this.store.select(dealsQuery.getAsideChatList);
  zenmodeList$: Observable<CrmCardViewItem[]> = this.store.select(dealsQuery.getZenmodeList);
  zenmodeOn$: Observable<boolean> = this.store.select(dealsQuery.getZenmodeOn);
  selectedZenmodeCard$: Observable<SelectedZenmodeCard> = this.store.select(
    dealsQuery.getSelectedZenmodeCard,
  );
  getShadowNextTasksMap$ = this.store.select(dealsQuery.getShadowNextTasksMap);
  skippedCards$: Observable<SelectedZenmodeCard[]> = this.store.select(dealsQuery.getSkippedCards);
  viewedCards$: Observable<SelectedZenmodeCard[]> = this.store.select(dealsQuery.getViewedCards);
  backwardCount$: Observable<number> = this.store.select(dealsQuery.getBackwardCount);
  backtrackedCards$: Observable<SelectedZenmodeCard[]> = this.store.select(dealsQuery.getBacktrackedCards);
  zenmodeHistory$: Observable<SelectedZenmodeCard[]> = this.store.select(dealsQuery.getZenmodeHistory);
  untouchedCards$: Observable<CrmCardViewItem[]> = this.store.select(dealsQuery.getUntouchedCards);
  constructor(private store: Store<DealsState>, private cardUpdaterService: CardUpdaterService) {}

  loadAll() {
    this.store.dispatch(new LoadDeals());
  }

  changeDealsListSortType(sortType: SortType) {
    this.store.dispatch(new DealsChangeSortType(sortType));
  }

  closePostSaleDropdown() {
    this.store.dispatch(new DealsPostSaleDropdownClose());
  }

  openPostSaleDropdown() {
    this.store.dispatch(new DealsPostSaleDropdownOpen());
  }

  dealChanged(payload: DealChangedEventDetail) {
    this.store.dispatch(new DealChanged(payload));
  }

  // TODO: REFACTOR for use ngrx instead
  cardChanged(payload: CardChangedEventDetail) {
    this.cardUpdaterService.pushCardUpdates(payload);
  }

  selectNextCard(isSkip?: boolean, nextTask?: CrmTaskItem, selectedCard?: SelectedZenmodeCard) {
    if (selectedCard?.isTouched && nextTask) {
      this.store.dispatch(
        new AddShadowTime({
          dealId: selectedCard.dealId,
          nextTask,
        }),
      );
    }

    if (isSkip) {
      this.store.dispatch(new AddSkippedCard(selectedCard));
    }
    this.store.dispatch(new DealsSelectNextCard({ isSkip, isTouched: selectedCard.isTouched, nextTask }));
  }

  selectPreviousCard(isSkip?: boolean) {
    this.store.dispatch(new DealsSelectPreviousCard(isSkip));
  }

  toggleZenmode(isOn: boolean) {
    this.store.dispatch(new ToggleZenmode(isOn));
  }

  updateLastWhatsappMessage(payload: UpdateLastWhatsappMessageEvent) {
    this.store.dispatch(new UpdateLastWhatsappMessage(payload));
  }

  newMessageReceived(payload: CardIdPayload) {
    console.log('newMessageReceived', payload);
    this.store.dispatch(new NewMessageReceived(payload));
  }

  showZenModeIntro(isManual?: boolean) {
    this.store.dispatch(new ShowZenModeIntro({ isManual }));
  }

  selectedZenmodeCardTouched(payload: SelectedZenmodeCardTouchedPayload) {
    this.store.dispatch(new SelectedZenmodeCardTouched(payload));
    this.store.dispatch(new LoadDeals());
  }

  generateZenmodeList() {
    this.store.dispatch(new GenerateZenmodeList());
  }
}
