<div *ngIf="showFilters" @filtersInOut class="filters">
  <app-filter-item
    *ngFor="let filter of FILTER_LIST_ARRAY"
    [filter]="filter"
    [count]="getFilterCount(filter)"
    [isActive]="activeFiltersList.includes(filter)"
    [isDisabled]="!availableFilters.includes(filter)"
    (click)="interactFilter(filter)"
  ></app-filter-item>
</div>
<button (click)="toggleFilters()" type="button" class="filter-action-button" title="Открыть фильтры">
  <img *ngIf="!showFilters" src="assets/icons/deal-view/filter-floating-button.svg" alt="" class="" />
  <img
    *ngIf="showFilters"
    src="assets/icons/deal-view/filter-floating-button-selected.svg"
    alt=""
    srcset=""
  />
</button>
