import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SipStateListEvent } from '@api-clients/crm-api-client';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { Worker } from '../../../../../models';

@Injectable({
  providedIn: 'root',
})
export class WorkersListService {
  public sipState$: Observable<SipStateListEvent[]>;
  private sipStateSub$: BehaviorSubject<SipStateListEvent[]>;

  constructor(private http: HttpClient) {
    this.sipStateSub$ = new BehaviorSubject<SipStateListEvent[]>(null);
    this.sipState$ = this.sipStateSub$.asObservable();
  }

  set sipState(state: SipStateListEvent[]) {
    this.sipStateSub$.next(state);
  }

  get sipStateValue(): SipStateListEvent[] {
    return this.sipStateSub$.value;
  }

  fetchWorkersList(cityId: number): Observable<Worker[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('cityId', `${cityId}`);
    return this.http
      .get<ApiResponse<Worker[]>>(`${AppConfig.apiUrl}/worker/list`, { params: queryParams })
      .pipe(apiResponsePipe);
  }
}
