import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CallItem } from '@api-clients/crm-api-client/dist/models';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { CallListService } from '../../services/call-list.service';

const DATE_FORMAT = 'yyyy-MM-dd';

@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallListComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public callList: CallItem[] = [];
  public currentDate: string = this.datePipe.transform(new Date(), DATE_FORMAT);
  public selectedDate: string = this.currentDate;
  public callListSub: Subscription;

  constructor(
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private callListService: CallListService,
  ) {}

  ngOnInit(): void {
    this.callListSub = this.callListService.getCalls(this.currentDate).subscribe(calls => {
      this.callList = calls;
      this.isLoading = false;
      this.cdRef.markForCheck();
    });
  }

  getSelected(): void {
    this.isLoading = true;
    this.callListService
      .getCalls(this.datePipe.transform(this.selectedDate, DATE_FORMAT))
      .subscribe(calls => {
        this.callList = calls;
        this.isLoading = false;
        this.cdRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.callListSub.unsubscribe();
  }
}
