import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Сервис для обмена сообщениями между окнами и фреймами
 */
@Injectable({
  providedIn: 'root',
})
export class WindowMessagesService {
  private messagesSubject = new Subject<any>();
  public messages$ = this.messagesSubject.asObservable();
  private allowedOrigins = ['https://myht.kz', 'https://ht.kz'];

  constructor() {
    window.addEventListener('message', event => {
      if (!this.allowedOrigins.includes(event.origin)) {
        return;
      }
      this.messagesSubject.next(event.data);
    });
  }
}
