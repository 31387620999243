<div class="reasons" [ngClass]="{ '--expanded': isExpanded }">
  <span
    *ngFor="let reason of NON_CONVERSION_CALL_REASONS"
    (click)="selectType(reason)"
    [ngClass]="{
      '--disabled': isListDisabled,
      '--active': !isListDisabled && activeReasonKey === reason }"
    class="reason-item"
  >
    {{ 'PAGES.DEALS.NON_CONVERSION_CALL.REASONS.' + reason.key | translate }}
  </span>
</div>
<button (click)="expandReasons()" class="expand-button">
  {{ 'COMMON.' + (isExpanded ? 'HIDE' : 'SHOW_ALL') | translate }}
</button>
