import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService, AppInitService, ConfigsService, WindowMessagesService } from './services';
import { WebsocketService } from './services/websocket/websocket.service';
import { ScriptLoaderService } from './services/script-loader/script-loader.service';
import { WebsocketHelperService } from './services/websocket/helper/websocket-helper.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    AuthenticationService,
    AppInitService,
    ConfigsService,
    WebsocketService,
    ScriptLoaderService,
    WebsocketHelperService,
    WindowMessagesService,
  ],
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('Нельзя повторно подключать CoreModule');
    }
  }
}
