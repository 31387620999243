import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ManagerSearchHotelTemplate } from '@api-clients/api-client/models/manager-search-hotel-template';
import { ConfigsService, ElectronService } from '../../../../../../../../../../core/services';
import { FavoriteHotelsTemplatesDropdownItemComponent } from './favorite-hotels-templates-dropdown-item.component';

@Component({
  selector: 'app-favorite-hotels-templates-dropdown',
  templateUrl: './favorite-hotels-templates-dropdown.component.html',
  styleUrls: ['./favorite-hotels-templates-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FavoriteHotelsTemplatesDropdownItemComponent, NgIf, NgForOf],
})
export class FavoriteHotelsTemplatesDropdownComponent {
  @Input() activeTemplate: ManagerSearchHotelTemplate;
  @Input() basicTemplates: ManagerSearchHotelTemplate[] = [];
  @Input() managerTemplates: ManagerSearchHotelTemplate[] = [];

  @Output() selected = new EventEmitter<ManagerSearchHotelTemplate>();

  constructor(private electron: ElectronService, private readonly config: ConfigsService) {}

  selectedTemplate(template: ManagerSearchHotelTemplate) {
    this.selected.emit(template);
  }

  openManagerSearch() {
    const searchURL = `${this.config.adminUrl}/search/manager`;
    this.electron.shell.openExternal(searchURL).then();
  }
}
