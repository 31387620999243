import {
  CreateWhatsappScheduledMessageRequest,
  DeleteWhatsappScheduledMessageRequest,
  ListWhatsappScheduledMessageForm,
  RescheduleWhatsappScheduledMessageRequest,
  WhatsappMessage,
  WhatsappScheduledMessage,
} from '@api-clients/crm-api-client';
import { createAction, props } from '@ngrx/store';

export const setCurrentReplyMessage = createAction(
  '[ChatTimeline] Set Current Reply Message',
  props<{ replyMessage: WhatsappMessage }>(),
);

export const clearCurrentReplyMessage = createAction('[ChatTimeline] Clear Current Reply Message');
export const resetChatTimelineState = createAction('[ChatTimeline] Reset State');

// Экшены для получения списка запланированных сообщений
export const loadScheduledMessages = createAction(
  '[ChatTimeline] Load Scheduled Messages',
  props<{ request: ListWhatsappScheduledMessageForm }>(),
);

export const loadScheduledMessagesSuccess = createAction(
  '[ChatTimeline] Load Scheduled Messages Success',
  props<{ messages: WhatsappScheduledMessage[] }>(),
);

export const loadScheduledMessagesFailure = createAction(
  '[ChatTimeline] Load Scheduled Messages Failure',
  props<{ error: any }>(),
);

// Экшены для создания нового запланированного сообщения
export const createScheduledMessage = createAction(
  '[WhatsApp] Create Scheduled Message',
  props<{ request: CreateWhatsappScheduledMessageRequest }>(),
);

export const createScheduledMessageSuccess = createAction(
  '[WhatsApp] Create Scheduled Message Success',
  props<{ request: CreateWhatsappScheduledMessageRequest }>(),
);

export const createScheduledMessageFailure = createAction(
  '[WhatsApp] Create Scheduled Message Failure',
  props<{ error: any }>(),
);

// Экшены для работы с оверлеем создания запланированного сообщения
export const openScheduleMessageOverlay = createAction('[ChatTimeline] Open Schedule Message Overlay');
export const closeScheduleMessageOverlay = createAction('[ChatTimeline] Close Schedule Message Overlay');

// Экшены для работы со списком запланированных сообщений
export const openScheduledMessagesList = createAction('[ChatTimeline] Open Scheduled Messages List');
export const closeScheduledMessagesList = createAction('[ChatTimeline] Close Scheduled Messages List');

// Экшены для изменения запланированного сообщения
export const rescheduleMessage = createAction(
  '[WhatsApp] Reschedule Message',
  props<{ request: RescheduleWhatsappScheduledMessageRequest }>(),
);

export const rescheduleMessageSuccess = createAction('[WhatsApp] Reschedule Message Success');

export const rescheduleMessageFailure = createAction(
  '[WhatsApp] Reschedule Message Failure',
  props<{ error: any }>(),
);

// Экшены для удаления запланированного сообщения
export const deleteScheduledMessage = createAction(
  '[WhatsApp] Delete Scheduled Message',
  props<{ request: DeleteWhatsappScheduledMessageRequest }>(),
);

export const deleteScheduledMessageSuccess = createAction(
  '[WhatsApp] Delete Scheduled Message Success',
  props<{ messageId: number }>(),
);

export const deleteScheduledMessageFailure = createAction(
  '[WhatsApp] Delete Scheduled Message Failure',
  props<{ error: any }>(),
);
