import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DealCreateRequest } from '@api-clients/crm-api-client/dist/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';

@Injectable({
  providedIn: 'root',
})
export class DealCreateService {
  constructor(private http: HttpClient) {}

  createDeal(dealCreateRequest: DealCreateRequest) {
    return this.http
      .post<ApiResponse<DealCreateRequest>>(`${AppConfig.apiUrl}/deal/create`, dealCreateRequest)
      .pipe(apiResponsePipe);
  }
}
