import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { PhoneItem } from '@api-clients/crm-api-client';
import { Subject, takeUntil } from 'rxjs';
import { SipType } from '../../click2call.model';
import { ClickToCallService } from '../../services/click-to-call.service';
import { CommonModule } from '@angular/common';
import { PhoneInputWithCodeModule } from 'app/ui-components/phone-input-with-code/phone-input-with-code.module';

@Component({
  selector: 'app-click2call-phones',
  templateUrl: './click2call-phones.component.html',
  styleUrls: ['./click2call-phones.component.scss'],
  standalone: true,
  imports: [CommonModule, PhoneInputWithCodeModule],
})
export class Click2callPhonesComponent implements OnInit, OnDestroy {
  @Input() phoneItems: PhoneItem[];
  @Input() parentForm: UntypedFormGroup;
  @Input() clientName: string;
  @Output() closeCallModal: EventEmitter<{
    withCall?: boolean;
  }> = new EventEmitter();
  public isHoveredIdx: number;
  private sipType: SipType;
  private destroy$ = new Subject<void>();

  constructor(private clickToCallService: ClickToCallService) {}

  ngOnInit(): void {
    this.clickToCallService.sipType$
      .pipe(takeUntil(this.destroy$))
      .subscribe(sipType => (this.sipType = sipType));
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get phones(): UntypedFormArray {
    return this.parentForm.get('phones') as UntypedFormArray;
  }

  callToPhone(phoneItem: PhoneItem, clientName: string) {
    this.clickToCallService.callToPhone(phoneItem, clientName, this.sipType);
    this.closeCallModal.emit({
      withCall: true,
    });
  }
}
