<div class="indicator"
     [@slideUpDown]="isVisible ? 'in' : 'out'"
    [ngClass]="{'hidden': !isVisible}"
>
  <div
    *ngFor="let part of indicatorData.messageParts; let i = index"
    class="part">
    <span class="text">

      <ng-container
        *ngIf="part.type === typeEnum.IndicatorText"
      >
        {{ part.content | translate }}
      </ng-container>
      <ng-container
        *ngIf="part.type === typeEnum.IndicatorEmoji"
            >
        <img src="assets/icons/emoji/{{ part.content}}.svg" alt="{{ part.content}}">
      </ng-container>
      <ng-container
        *ngIf="part.type === typeEnum.IndicatorHighlightedText"
      >

        <p class="highlighted">
          <ng-container *ngIf="part.count">
            {{ part.count }}
          </ng-container>
          {{ part.content | translate }}
        </p>
      </ng-container>
    </span>
  </div>
  <button
    (click)="hideIndicator()"
    class="hide-button">
    {{ 'COMMON.HIDE' | translate }}

  </button>
</div>
