import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientStatusIcon } from '../../../client-card-info/client-status-icon/client-status-icon';
import { Subject, takeUntil } from 'rxjs';

const RETURNABLE_TOURIST_LABEL_LANG_KEY = 'PAGES.DEALS.CLIENT_CARD.STATUS.RETURNABLE';

@Component({
  selector: 'app-client-status-tags',
  templateUrl: './client-status-tags.component.html',
  styleUrls: ['./client-status-tags.component.scss'],
})
export class ClientStatusTagsComponent implements OnChanges, OnDestroy {
  @Input() isReturnTourist: boolean;
  public returnableTouristLabel: string;
  public returnableTouristIcon = ClientStatusIcon.RETURNABLE;
  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnChanges() {
    this.translateService
      .get(RETURNABLE_TOURIST_LABEL_LANG_KEY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(translated => {
        this.returnableTouristLabel = translated;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
