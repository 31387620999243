import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-speech-bubble',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './speech-bubble.component.html',
  styleUrls: ['./speech-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeechBubbleComponent {}
