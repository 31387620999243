import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  CrmCardItem,
  CrmTaskItem,
  CrmTaskType,
  DealItem,
  LastActionItem,
  QuestionnaireItem,
  SearchRequest,
} from '@api-clients/crm-api-client/dist/';
import { LastBudgetItem } from '@api-clients/crm-api-client/models/last-budget-item';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { first } from 'rxjs/operators';
import { DealsFacade } from '../../../../../../../+state/deals/deals.facade';
import { NeedConnectType } from '../../../deal-list';

const ACTIONS_DATE_FORMAT = 'd MMMM, HH:mm';
const LAST_ACTIONS_TYPE_LANG_KEY = 'PAGES.DEALS.LAST_ACTIONS_TYPE';
const INTENT_TYPES_LANG_KEY = 'PAGES.DEALS.INTENT_TYPES';
const CRM_TASK_TYPES_LANG_KEY = 'PAGES.DEALS.CRM_TASK_TYPES';

const RED = '--red';
const GRAY = '--gray';
const YELLOW = '--yellow';
const NEXT_ACTION_TEXT_MAX_HEIGHT = 40;

@Component({
  selector: 'app-deals-item',
  templateUrl: './deals-item.component.html',
  styleUrls: ['./deals-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsItemComponent implements OnInit, AfterViewInit, OnChanges {
  public nextTaskTypes: any;
  public nextTaskIntents: any;
  public lastActionsType: any;
  public isEmptyDeal: boolean;
  public dealInfo: DealItem;
  public card: CrmCardItem;
  public lastTags: string[];
  public lastBudget: LastBudgetItem;
  public nextTask: CrmTaskItem;
  public crmId: number;
  public lastAction: LastActionItem;
  public searchOption: SearchRequest;
  public questionnaire: QuestionnaireItem;
  public actionsDateFormat: string;
  public nextTaskDate: string;
  public dateColor: string = GRAY;
  public fadeOutAnimation: boolean;
  public nextActionHideToggle = true;
  public showMore = false;

  @ViewChild('dealItemRef') public dealItemRef: ElementRef;
  @ViewChild('showMoreToggle') showMoreToggle: ElementRef;
  @ViewChild('nextActionRef') nextActionRef: ElementRef;
  @Input() crmCardListItem: CrmCardViewItem;
  @Input() isWhatsappClientCard = false;
  @Input() isWhatsappClientCardFromDealView: boolean;
  @Input() isModal: boolean;
  @Input() scrollUpdate$: Observable<Event>;
  @Input() isShowCardInfo = true;
  @Input() isAbleToAnimate = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private dealsFacade: DealsFacade,
  ) {
    this.translate
      .get([LAST_ACTIONS_TYPE_LANG_KEY, INTENT_TYPES_LANG_KEY, CRM_TASK_TYPES_LANG_KEY])
      .pipe(first())
      .subscribe(value => {
        this.lastActionsType = value[LAST_ACTIONS_TYPE_LANG_KEY];
        this.nextTaskIntents = value[INTENT_TYPES_LANG_KEY];
        this.nextTaskTypes = value[CRM_TASK_TYPES_LANG_KEY];
      });
  }

  getNextTask() {
    const shouldFindUrgentOrMissed = this.crmCardListItem.needConnectType === NeedConnectType.urgentOrMissed;

    const urgentOrMissedTask = shouldFindUrgentOrMissed
      ? this.crmCardListItem.actualTasks.find(
          task => task.type === CrmTaskType.Urgent || task.type === CrmTaskType.Missed,
        )
      : undefined;

    return urgentOrMissedTask || this.crmCardListItem.nextTask;
  }

  openUrgentTaskCard() {
    if (this.nextTask) {
      if (this.nextTask.type === CrmTaskType.Urgent) {
        this.crmId = this.nextTask?.crmCardId;
      }
    }
  }

  refreshCard() {
    this.dealInfo = this.crmCardListItem.deal;
    this.card = this.crmCardListItem.card;
    this.lastTags = this.crmCardListItem.lastTags;
    this.lastBudget = this.crmCardListItem.lastBudget;
    this.nextTask = this.getNextTask();
    this.crmId = this.crmCardListItem?.card?.id;
    this.openUrgentTaskCard();
    this.lastAction = this.crmCardListItem.lastAction;
    this.questionnaire = this.crmCardListItem.questionnaire;
    if (this.nextTask != null && this.nextTask.date != null && this.nextTask.time != null) {
      const nextTaskDateToBeCheckOut = new Date(`${this.nextTask.date} ${this.nextTask.time}`);
      const today = new Date();

      const timeDiff = Number(nextTaskDateToBeCheckOut) - Number(today);
      const timeDiffInMin = Math.ceil(timeDiff / 1000 / 60);
      if (
        nextTaskDateToBeCheckOut.getDate() === today.getDate() &&
        nextTaskDateToBeCheckOut.getMonth() === today.getMonth() &&
        nextTaskDateToBeCheckOut.getFullYear() === today.getFullYear()
      ) {
        if (timeDiffInMin >= 20) {
          this.dateColor = GRAY;
        }

        if (timeDiffInMin <= 20 && timeDiffInMin > 5) {
          this.dateColor = YELLOW;
        }

        if (timeDiffInMin <= 5) {
          this.dateColor = RED;
        }
      } else if (nextTaskDateToBeCheckOut < today) {
        this.dateColor = RED;
      }
      this.cdRef.detectChanges();
    }

    if (this.crmCardListItem.lastSearchRequest) {
      this.searchOption = this.crmCardListItem.lastSearchRequest;
    }

    if (this.nextTask) {
      this.nextTaskDate = `${this.nextTask.date} ${this.nextTask.time ? this.nextTask.time : ''}`;
    }

    this.isDealEmpty();
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.actionsDateFormat = ACTIONS_DATE_FORMAT;
    this.refreshCard();
  }

  ngAfterViewInit(): void {
    if (this.nextActionRef) {
      this.nextActionHideToggle = this.nextActionRef.nativeElement.offsetHeight > NEXT_ACTION_TEXT_MAX_HEIGHT;
      this.cdRef.detectChanges();
    }
  }

  isDealEmpty(): void {
    this.isEmptyDeal = !(!!this.searchOption || !!this.nextTask || !!this.lastAction);
  }

  fadeOut(): void {
    if (this.isAbleToAnimate) {
      this.fadeOutAnimation = true;
    }
    setTimeout(() => {
      this.dealsFacade.loadAll();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.crmCardListItem) {
      this.refreshCard();
    }
  }
}
