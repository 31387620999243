import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ClientNeedsService } from './client-needs.service';
import { NotifyHelperService } from '../../../../services/notify-helper.service';
import { AmplitudeTrackService } from '../../../../../../../../core/services/amplitude/amplitude-track.service';
import { DEAL_UPDATED } from '../../../../../../../../core/services/amplitude/amplitudeEvents';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';

const UPDATE_INFO_MESSAGE = 'PAGES.DEALS.CLIENT_CARD.UPDATED.INFO';

@Component({
  selector: 'app-client-needs',
  templateUrl: './client-needs.component.html',
  styleUrls: ['./client-needs.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule]
})
export class ClientNeedsComponent implements OnChanges, OnDestroy {
  @Input() lastInfoUpdate?: string = '';
  @Input() needs: string;
  @Input() crmId: number;
  @ViewChild('textAreaElement') textAreaElement: ElementRef;
  public form: UntypedFormGroup;
  public isEditingMode = false;
  public previousValue: string;
  public isCancelEditing = false;
  public updateInfoMessage = '';
  private destroy$ = new Subject<void>();
  constructor(
    private fb: UntypedFormBuilder,
    private clientNeedsService: ClientNeedsService,
    private translateService: TranslateService,
    private notifyHelperService: NotifyHelperService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.translateService
      .get(UPDATE_INFO_MESSAGE)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: string) => {
        this.updateInfoMessage = res;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.needs) {
      this.form = this.fb.group({
        needs: [this.needs],
      });

      this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(2000)).subscribe(value => {
        if (!this.isCancelEditing) {
          this.clientNeedsService
            .updateInfo(this.crmId, value.needs)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.amplitudeTrackService.trackEvent(DEAL_UPDATED, { field: 'info' });
              this.isEditingMode = false;
              this.previousValue = value.needs;
              this.notifyHelperService.notify(this.updateInfoMessage, true);
            });
        } else {
          this.isCancelEditing = false;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearNeeds() {
    this.form.get('needs').setValue('');
  }

  cancelEditing() {
    this.isEditingMode = false;
    this.isCancelEditing = true;
    this.form.get('needs').setValue(this.previousValue);
    this.textAreaElement.nativeElement.blur();
  }

  save() {
    this.isEditingMode = false;
    this.previousValue = this.form.get('needs').value;
    this.textAreaElement.nativeElement.blur();
  }

  enableEditingMode() {
    this.isEditingMode = true;
    this.previousValue = this.form.get('needs').value;
  }
}
