import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PhoneItem, WhatsappContactListItem } from '@api-clients/crm-api-client/dist/models';
import { Subscription } from 'rxjs';
import { DefaultResponse } from '@api-clients/crm-api-client';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';
import { ConfigsService } from '../../../../../../core/services';
import { WhatsappApiService } from '../../../../services/whatsapp-api.service';
import { WhatsappChatListItem } from './chat-list.interface';
import { WhatsappContactsFacade } from '../../../../../../+state/whatsapp-contacts/whatsapp-contacts.facade';
import { WhatsappService } from '../../../../services/whatsapp.service';
import {
  Chat,
  ChatPayload,
  SelectedChatPayload,
} from '../../../../../../+state/whatsapp-contacts/whatsapp-contacts.interfaces';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListComponent implements OnInit, OnDestroy {
  public selectedChatSub: Subscription;
  public whatsappContactsSub: Subscription;
  public listItems: WhatsappChatListItem[];
  public isLoading = true;
  public selectedChatCrmId: number;
  public selectedChatContactId: string;
  public NoMessageLabel = 'Нет сообщений';
  public clients: Map<number, string>;
  public SEARCH_FILTER_PLACEHOLDER = 'Поиск';
  public searchContact: string;
  public unreadChatsSub: Subscription;
  public unreadChats = 0;
  @Input() loadedChats: Chat[];
  public isOptionsOpened = false;
  public isBeingCleanedChats = false;

  constructor(
    private dealsFacade: DealsFacade,
    private configsService: ConfigsService,
    private cdRef: ChangeDetectorRef,
    private whatsappApiService: WhatsappApiService,
    private whatsappService: WhatsappService,
    private whatsappContactsFacade: WhatsappContactsFacade,
  ) {}

  ngOnInit() {
    this.listItems = [];
    this.whatsappContactsSub = this.whatsappContactsFacade.whatsappContacts$.subscribe(contactList => {
      if (contactList) {
        this.refreshContacts(contactList);
        this.whatsappContactsFacade.countChatsWithUnreadMessages();
      }
    });
    this.unreadChatsSub = this.whatsappContactsFacade.unreadChats$.subscribe(chats => {
      this.unreadChats = chats;
    });
    this.isLoading = false;
    this.cdRef.detectChanges();
  }

  getSortedListItemsByDate() {
    return this.listItems.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }

  refreshContacts(contactList: WhatsappContactListItem[]) {
    this.listItems = [];
    for (const item of contactList) {
      let lastMessage = 'Нет сообщений.';
      if (item.lastMessage) {
        lastMessage = this.whatsappService.parseShortMessage(item.lastMessage);
      }
      const chatListItem: WhatsappChatListItem = {
        clientName: item.contact.name,
        contactId: item.contact.id,
        crmId: item.contact.crmCardId,
        lastMessageText: lastMessage,
        createdAt: item.contact.lastMessageAt,
        phone: item.contact.phone,
        unreadMessageCount: item.contact.unreadMessageCount,
      };
      this.listItems.push(chatListItem);
    }
    this.listItems = this.getSortedListItemsByDate();
    this.isLoading = false;
    this.cdRef.detectChanges();
  }

  selectChat(crmId: number, phone: PhoneItem = null, contactId: string = null) {
    if (contactId === this.selectedChatContactId) {
      return;
    }
    this.selectedChatCrmId = crmId;
    this.selectedChatContactId = contactId;
    const payload: SelectedChatPayload = {
      crmCardId: crmId,
      phone,
      contactId,
    };
    this.whatsappContactsFacade.setSelectedChat(payload);
    this.isLoading = true;

    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.selectedChatSub) {
      this.selectedChatSub.unsubscribe();
    }
    if (this.whatsappContactsSub) {
      this.whatsappContactsSub.unsubscribe();
    }
  }

  loadChat(item: WhatsappChatListItem) {
    for (const loadedItem of this.loadedChats) {
      if (loadedItem.contactId === item.contactId) {
        return;
      }
    }
    this.whatsappApiService.getWhatsappMessagesByContactId(item.contactId, 1, 25).subscribe(messages => {
      const chat: ChatPayload = {
        contactId: item.contactId,
        crmCardId: item.crmId,
        clientName: item.clientName,
        phone: item.phone,
        messages,
      };
      this.whatsappContactsFacade.loadChat(chat);
    });
  }

  clearChats() {
    this.isBeingCleanedChats = true;
    this.cdRef.detectChanges();
    this.whatsappApiService.clearAllContactsUnreadMessages().subscribe((result: DefaultResponse) => {
      if (result.success) {
        this.closeOptions();
        this.whatsappContactsSub = this.whatsappContactsFacade.whatsappContacts$.subscribe(contactList => {
          if (contactList) {
            this.listItems = [];
            for (const item of contactList) {
              let lastMessage = 'Нет сообщений.';
              if (item.lastMessage) {
                lastMessage = this.whatsappService.parseShortMessage(item.lastMessage);
              }
              const chatListItem: WhatsappChatListItem = {
                clientName: item.contact.name,
                contactId: item.contact.id,
                crmId: item.contact.crmCardId,
                lastMessageText: lastMessage,
                createdAt: item.contact.lastMessageAt,
                phone: item.contact.phone,
                unreadMessageCount: 0,
              };
              this.listItems.push(chatListItem);
            }
            this.listItems = this.getSortedListItemsByDate();
            this.whatsappContactsFacade.loadContacts();
            this.whatsappContactsFacade.countChatsWithUnreadMessages();
            this.isBeingCleanedChats = false;
            this.cdRef.detectChanges();
          }
        });
        this.cdRef.detectChanges();
      }
    });
  }
  toggleOptions() {
    this.isOptionsOpened = !this.isOptionsOpened;
    this.cdRef.detectChanges();
  }

  closeOptions() {
    this.isOptionsOpened = false;
    this.cdRef.detectChanges();
  }
}
