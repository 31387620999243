<div class="content">
  <ng-container *ngIf="empty">
    <h4>По данному запросу нет соответствий</h4>
  </ng-container>
  <ng-container *ngIf="loading">
    <h4>Идет поиск...</h4>
  </ng-container>
  <ng-container *ngIf="!empty && !loading">
    <h2 class="title">
      Результаты поиска по запросу: &nbsp;<span *ngIf="term" class="search-value">{{ term }}</span>
      <button (click)='close()' class="close"><img src="./assets/icons/close.svg" alt=""></button>
    </h2>
    <div class="search-results">
      <div *ngFor="let result of searchResults" class="search-result rounded-border">
        <div class="client-name-info search-result-item">
          <span class="client-pic">{{ result.card.name | getInitials }}</span>
          <div class="client-name">
            <a
              (click)="onSelect(result.card.id)"
              [routerLink]="['/deals/view', result.card.id]">
                {{ result.card.name ? result.card.name :  result.card.phone }}
            </a>
            <div class="client-phone">{{ result.card.phone }}</div>
          </div>
        </div>
        <div *ngIf="result.lastTourPackage?.tours.length" class="last-booking search-result-item">
          <h5 class="inner-title">Последняя бронь:</h5>
          <p *ngFor="let tour of result.lastTourPackage.tours; let first = first">
            <ng-container *ngIf="first">
              {{ tour.country?.name }}
               ({{ tour.region?.name }}),
              <ng-container *ngIf="result.lastTourPackage?.manager as manager">
                {{ manager.name }}
                <ng-container *ngIf='manager.sip as sip'>
                  (вн. {{ sip }})
              </ng-container>
              </ng-container>
            </ng-container>
          </p>
        </div>
        <div *ngIf="result.lastCall" class="last-appeal search-result-item">
          <h5 class="inner-title">Последнее обр.:</h5>
          <p>
            {{ daysBefore(result.lastCall?.dateTime) }}
            {{ result.lastCall?.worker?.name }}
            <ng-container *ngIf='result.lastTourPackage?.manager?.sip as sip'>
              (вн. {{ sip }}),
            </ng-container>
            Звонок
            {{ result.lastCall?.duration | secondsToMinutesSeconds }}
          </p>
        </div>
        <div *ngIf="result.activeDeal?.worker" class="manager search-result-item">
          <h5 class="inner-title">В работе:</h5>
          <p>{{ result.activeDeal?.worker?.name }}</p>
        </div>
        <div class="date">{{ result.card.lastInfoUpdate | date: 'dd.MM.yyyy H:mm' }}</div>
        <div class="open-in-browser">
          <img src="./assets/icons/search-popup/browsing.svg" alt="" />
          <a libExternalLink [externalURL]="result.card.link">Открыть в браузере</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
