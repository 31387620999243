import { Component, Input, OnInit } from '@angular/core';
import {
  CrmCardListPostSaleItem,
  CrmCardListPostSaleTourPackage,
} from '@api-clients/crm-api-client/dist/models';
import { DealsListFacade } from '../../../../../../../../+state/deals-list/deals-list.facade';

@Component({
  selector: 'app-post-sale-list-item',
  templateUrl: './post-sale-list-item.component.html',
  styleUrls: ['./post-sale-list-item.component.scss'],
})
export class PostSaleListItemComponent implements OnInit {
  @Input() crmCardListPostSaleItem: CrmCardListPostSaleItem;
  @Input() tourPackage: CrmCardListPostSaleTourPackage;
  @Input() isMinimize = true;
  constructor(private dealsListFacade: DealsListFacade) {}

  ngOnInit(): void {}

  toggleMinimize() {
    this.isMinimize = !this.isMinimize;
    if (this.isMinimize) {
      this.dealsListFacade.setMinimized(this.tourPackage.tourPackage.id);
    } else {
      this.dealsListFacade.setMaximized(this.tourPackage.tourPackage.id);
    }
  }
}
