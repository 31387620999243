  <div class="last-action-text actions-text">
  <ng-container>
    {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.CALLS.' + CALL_TYPE[lastActionItem.callType] | translate }}
  </ng-container> от
  <ng-container>{{ lastActionItem.worker?.name || "вн. " + lastActionItem.code }}</ng-container>
  </div>
        <span class="last-action-date actions-date">{{
          lastActionItem.dateTime | date: actionsDateFormat | lowercase
        }}</span>
        <app-audio
          *ngIf="lastActionItem.hasRecord"
          [screenType]="screenType"
          [itemId]="lastActionItem.id"
          [duration]="lastActionItem.duration"
          [attr.data-record-id]="lastActionItem.id"
          [attr.data-record-duration]="lastActionItem.duration"
          [mediaSource]="lastActionItem.recordingContentLink"
          [recordingFileLink]="lastActionItem.recordingFileLink"
          [type]="audioCallType"
        />
