import { NgForOf, NgIf, SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { Observable, Subject } from 'rxjs';
import { PluralizePipe } from '../../../../helpers/pipes/plural/pluralize.pipe';
import { LineProgressComponent } from '../../../../ui-components/line-progress/line-progress.component';
import { ContentCreatorContentType } from '../../../content-creator/interfaces/content-creator.interface';
import { SearchFormService } from '../../../deals/modules/deal-view/modules/favorites-hotels/components/search-form/search-form.service';
import { HotelTagWithPriority } from '../../../deals/modules/deal-view/modules/favorites-hotels/favorites-hotels.model';
import { SearchHotelCategoryPipe } from '../../pipes/search-hotel-category.pipe';
import { SearchHotel, SearchTouristsRecalc, SearchTourSelectMode } from '../../search.model';
import { WebsocketToursSearchService } from '../../services/search/websocket-tours-search.service';
import { InitSearchRequest, SearchResultsResponseTour } from '../../services/search/websocket-tours.model';
import { SearchTourComponent } from '../tour/search-tour.component';
import { SearchBookingRatingComponent } from './hotel-booking-rating/search-hotel-booking-rating.component';
import { SearchHotelPhotoComponent } from './hotel-photo/search-hotel-photo.component';
import { SearchHotelRecalcBtnComponent } from './hotel-recalc-btn/search-hotel-recalc-btn.component';
import { SearchHotelSaleCountComponent } from './hotel-sales-count/search-hotel-sales-count.component';
import { SearchHotelStarsComponent } from './hotel-stars/search-hotel-stars.component';

@Component({
  selector: 'app-search-hotel',
  templateUrl: './search-hotel.component.html',
  styleUrls: ['./search-hotel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SearchHotelStarsComponent,
    SearchHotelSaleCountComponent,
    NgIf,
    SearchBookingRatingComponent,
    SearchHotelRecalcBtnComponent,
    SearchTourComponent,
    NgForOf,
    SearchHotelCategoryPipe,
    SlicePipe,
    PluralizePipe,
    SearchHotelPhotoComponent,
    LineProgressComponent,
    MatTooltip,
  ],
  providers: [SearchFormService],
})
export class SearchHotelComponent implements OnInit {
  @Input() hotel!: SearchHotel;
  @Input() allowReCalculate = true;
  @Input() emitSearchTours$: Observable<SearchResultsResponseTour[]>;
  @Input() toursVisibleCount = 1;
  @Input() contentCreatorType = ContentCreatorContentType.ManagerOffer;
  @Input() initSearchRequest: InitSearchRequest;
  @Input() allowResearch = false;
  @Input() tourSelectMode = SearchTourSelectMode.drop;
  @Input() mapPointerVisible = false;
  @Input() hotelOnMap = false;
  @Input() set initSearchTours(tours: SearchResultsResponseTour[]) {
    this.searchTours = tours;
  }
  @Input() tags: HotelTagWithPriority[] = [];
  showHotelOnMap = output<number>();

  isAllToursVisible = false;

  searchTours: SearchResultsResponseTour[] = [];

  showRecalcToursLoader = false;
  showSearchToursLoader = false;
  showToursNotFound = false;

  adults = signal<number>(2);
  childAges = signal<number[]>([]);

  private emitRecalcTourSub = new Subject<Partial<SearchTouristsRecalc>>();
  emitRecalcTour$ = this.emitRecalcTourSub.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly searchToursService: WebsocketToursSearchService,
  ) {}

  ngOnInit() {
    if (this.initSearchRequest) {
      const searchParams = this.initSearchRequest.params.params;

      this.adults.set(searchParams.adults);
      this.childAges.set(searchParams.childrenAges);
    }
  }

  showAllTours() {
    this.isAllToursVisible = true;
  }

  recalcTours(tourists: SearchTouristsRecalc) {
    this.adults.set(tourists.adults);
    this.childAges.set(tourists.childAges);

    if (this.initSearchRequest) {
      this.researchToursByNewTourists(tourists);
    } else {
      this.recalcCurrentTours(tourists);
    }
  }

  /**
   * Метод запускает перепоиск туров по параметрам формы поиска initSearchRequest
   */
  researchTours() {
    this.showSearchToursLoader = true;
    this.cdRef.detectChanges();

    const researchInitRequest = this.initSearchRequest;
    researchInitRequest.params.params.hotels = [this.hotel.id];
    researchInitRequest.params.options.allowParallel = true;

    this.searchToursService
      .searchTours(researchInitRequest, false)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.showSearchToursLoader = false;

          if (response.tours.length === 0) {
            this.showToursNotFound = true;
          } else {
            this.searchTours = response.tours;
          }

          this.cdRef.detectChanges();
        },
      });
  }

  private recalcCurrentTours(tourists: SearchTouristsRecalc): void {
    this.emitRecalcTourSub.next(tourists);
  }

  private researchToursByNewTourists(tourists: SearchTouristsRecalc): void {
    this.showRecalcToursLoader = true;

    this.cdRef.detectChanges();

    const recalcInitRequest = { ...this.initSearchRequest };
    const addExtendsTours =
      recalcInitRequest.params.params.adults === tourists.adults &&
      recalcInitRequest.params.params.childrenAges.sort().join('') === tourists.childAges.sort().join('') &&
      recalcInitRequest.params.params.splitRooms === tourists.splitRooms;

    recalcInitRequest.id = WebsocketToursSearchService.generateId();
    recalcInitRequest.params.params.hotels = [this.hotel.id];
    recalcInitRequest.params.params.adults = tourists.adults;
    recalcInitRequest.params.params.childrenAges = tourists.childAges;
    recalcInitRequest.params.params.splitRooms = tourists.splitRooms;
    recalcInitRequest.params.options.allowParallel = true;

    this.searchToursService
      .searchTours(recalcInitRequest, false, addExtendsTours)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (!response.searchIsDone) {
            return;
          }
          this.showRecalcToursLoader = false;

          if (response.tours.length === 0) {
            this.showToursNotFound = true;
          } else {
            this.searchTours = response.tours;
          }

          this.cdRef.detectChanges();
        },
      });
  }
}
