import { Component, Input, OnInit } from '@angular/core';
import { TourPackage } from '@api-clients/crm-api-client/models';
import { Tour } from '@api-clients/crm-api-client';
import { ReservationService } from '../../../../services/reservation.service';

@Component({
  selector: 'app-chat-timeline-item-reservation',
  templateUrl: './chat-timeline-item-reservation.component.html',
  styleUrls: ['./chat-timeline-item-reservation.component.scss'],
})
export class ChatTimelineItemReservationComponent implements OnInit {
  @Input() tourPackage: TourPackage;
  public tour: Tour;
  public destination: string;
  public hotelName: string;
  public dates: string;
  public nights: string;
  public meal: string;
  constructor(private reservationService: ReservationService) {}

  ngOnInit(): void {
    if (this.tourPackage?.tours?.length) {
      this.tour = this.tourPackage.tours[0];
      if (this.tour) {
        this.destination = this.reservationService.getDestination(this.tour);
        this.hotelName = this.reservationService.sliceName(this.tour?.hotel?.name, 15);
        this.dates = this.reservationService.getDates(this.tourPackage?.dateFrom, this.tourPackage?.dateTo);
        this.nights = this.reservationService.getNights(this.tour);
        this.meal = this.tour?.meal?.name;
      }
    }
  }
}
