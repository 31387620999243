<div class="right-side-menu">
  <app-right-side-menu-item
  *ngFor="let item of menuItems; let i = index; let isLast = last"
  [item]="item"
  [isLast] = isLast
  (close)="close()"
  [exclude]="'div.icon.arrow'"
  (clickOutside)="close()"
  >
  </app-right-side-menu-item>
</div>
