<div class="deal-list-filters">
  <button
    type="button"
    class="filter btn --small --white"
    (click)="resetFilters()"
    [class.active]="(isAnyFilterActive$ | async) === false"
  >
    Показать все
  </button>
  <ng-container *ngIf="currentFilters$ | async as filters">
    <button
      *ngFor="let filter of filters | keyvalue"
      type="button"
      class="filter btn --small --white"
      (click)="toggleFilter(filter.key, filter.value.isActive)"
      [class.active]="filter.value.isActive"
    >
      {{ filter.value.label }} ({{ filter.value.count }})
    </button>
  </ng-container>
</div>
