import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChatMessagesFacade } from '../../+state/chat-messages/chat-messages.facade';
import { chatMessagesReducer } from '../../+state/chat-messages/chat-messages.reducers';
import { chatMessagesInitialState } from '../../+state/chat-messages/chat-messages.state';
import { WhatsappContactsEffects } from '../../+state/whatsapp-contacts/whatsapp-contacts.effects';
import { WhatsappContactsFacade } from '../../+state/whatsapp-contacts/whatsapp-contacts.facade';
import { whatsappContactsReducer } from '../../+state/whatsapp-contacts/whatsapp-contacts.reducers';
import { whatsappContactsInitialState } from '../../+state/whatsapp-contacts/whatsapp-contacts.state';
import { SharedUtilityModule } from '../../../../../../libs/shared/utility/src';
import { GetInitialsModule } from '../../helpers/pipes/getInitials/get-initials.module';
import { ReversePipe } from '../../helpers/pipes/reverse.pipe';
import { SharedModule } from '../../shared/shared.module';
import { PreloaderModule } from '../../ui-components/preloader/preloader.module';
import {
  AudioModule,
} from '../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import { FormComponentsModule } from '../form-components/form-components.module';
import { LayoutModule } from '../layout/layout.module';
import { WhatsappComponent } from './components/whatsapp.component';
import { ChatFooterModule } from './modules/chat-footer/chat-footer.module';
import { ChatListModule } from './modules/chat-list/chat-list.module';
import { ChatModule } from './modules/chat/chat.module';
import { WhatsappClientCardModule } from './modules/whatsapp-client-card/whatsapp-client-card.module';
// tslint:disable-next-line:max-line-length
import {
  WhatsappFooterLoadingModule,
} from './modules/whatsapp-footer-loading/whatsapp-footer-loading.module';

@NgModule({
  declarations: [WhatsappComponent, ReversePipe],
  imports: [
    LayoutModule,
    CommonModule,
    ChatListModule,
    WhatsappClientCardModule,
    WhatsappFooterLoadingModule,
    AudioModule,
    PreloaderModule,
    SharedUtilityModule,
    StoreModule.forFeature('chat-messages', chatMessagesReducer, {
      initialState: chatMessagesInitialState,
    }),
    StoreModule.forFeature('whatsapp-contacts', whatsappContactsReducer, {
      initialState: whatsappContactsInitialState,
    }),
    EffectsModule.forFeature([WhatsappContactsEffects]),
    GetInitialsModule,
    FormsModule,
    FormComponentsModule,
    ChatModule,
    ChatFooterModule,
    SharedModule,
  ],
  providers: [ChatMessagesFacade, WhatsappContactsFacade],
  exports: [],
})
export class WhatsappModule {
}
