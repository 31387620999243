import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CrmTaskCreateNextRequest, CrmTaskItem, CrmTaskType } from '@api-clients/crm-api-client';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CrmTaskIntent } from '@api-clients/crm-api-client/dist/models';
import { CreateTaskService } from '../../../services/create-task.service';
import { ActionsPopupHelperService } from '../../../../../components/actions-popup-menu/services/actions-popup-helper.service';
import { CrmTaskApiService } from '../../../../../services/crm-task-api.service';
import { WorkerStateService } from '../../../../../../../../../core/services';
import {
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../../shared/notify/services/notify.service';
import { DatetimepickerComponent, DateTimeValue } from '../../../../datetimepicker/datetimepicker.component';
import {
  getAPIFormattedDate,
  getFormattedDate,
  getFormattedTime,
  getFormattedTimeWithSec,
} from '../../../../../../../../../helpers/time';
import { TimePeriods } from '../../../../../../../../../models/datetimepicker';
import { AmplitudeTrackService } from '../../../../../../../../../core/services/amplitude/amplitude-track.service';
import { SALES_FUNNEL_TASK_RECALL } from '../../../../../../../../../core/services/amplitude/amplitudeEvents';
import { take } from 'rxjs';

@Component({
  selector: 'app-recall-step',
  templateUrl: './recall-step.component.html',
  styleUrls: ['./recall-step.component.scss'],
})
export class RecallStepComponent implements OnInit {
  @ViewChild(DatetimepickerComponent) datetimepicker: DatetimepickerComponent;
  @Input() task: CrmTaskItem;
  @Input() dealId: number;
  @Input() crmCardId: number;
  @Input() phone: string;
  @Input() dealStage: string;
  @Input() isPostSale: boolean;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  public form: UntypedFormGroup;
  public datetimePopup: boolean;
  public assignedManagerId: number;
  public meetingDateTime: Date;
  public TRANSLATE_PATH = 'PAGES.DEALS.SALES_FUNNEL.CREATE_TASK_MODAL.RECALL.';
  meetingDateControl: UntypedFormControl;
  meetingTimeControl: UntypedFormControl;
  public readonly TIME_PERIODS_ARRAYS = [
    TimePeriods.after15Min,
    TimePeriods.after30Min,
    TimePeriods.afterHour,
    TimePeriods.today,
  ];
  public isLoaded = false;

  constructor(
    private createTaskService: CreateTaskService,
    private formBuilder: UntypedFormBuilder,
    private actionsPopupHelperService: ActionsPopupHelperService,
    private taskCreateService: CrmTaskApiService,
    private workerStateService: WorkerStateService,
    private notifyService: NotifyService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.assignedManagerId = this.workerStateService.currentWorkerValue?.id;
  }

  ngOnInit(): void {
    this.initForm();
    this.initDateTime();
    this.isLoaded = true;
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      dealId: [this.dealId, [Validators.required]],
      crmCardId: [this.crmCardId, [Validators.required]],
      sendBookingInstruction: [false],
      type: [CrmTaskType.Calling, [Validators.required]],
      meetingDate: ['', [Validators.required]],
      meetingTime: ['', [Validators.required]],
      managerComment: [this.task?.managerComment || ''],
      assignedManagerId: [this.assignedManagerId, [Validators.required]],
      intent: [CrmTaskIntent.ChangeDate],
      phone: [this.phone, [Validators.required]],
    });

    this.meetingDateControl = this.form.get('meetingDate') as UntypedFormControl;
    this.meetingTimeControl = this.form.get('meetingTime') as UntypedFormControl;
  }

  setMeetingDateTime($event) {
    this.meetingDateTime = $event.date;
    this.form.patchValue({
      meetingDate: $event.dateValue,
      meetingTime: $event.timeValue,
    });
  }

  openDatetimepickerPopup() {
    this.datetimePopup = true;
    this.datetimepicker.selectTimeOnTimegrid(TimePeriods.after15Min);
  }

  closeDatetimepickerPopup($event: any) {
    $event.stopPropagation();
    this.datetimePopup = false;
  }

  initDateTime() {
    const DATE_TIME_VALUE: DateTimeValue = {
      date: new Date(),
      dateValue: getFormattedDate(new Date()),
      timeValue: getFormattedTime(new Date()),
      duration: this.actionsPopupHelperService.getDuration(this.dealStage),
    };
    this.meetingDateTime = DATE_TIME_VALUE.date;

    this.form.patchValue({
      meetingDate: DATE_TIME_VALUE.dateValue,
      meetingTime: DATE_TIME_VALUE.timeValue,
    });
  }

  goPrev(): void {
    this.createTaskService.goBack();
  }

  submitForm(): void {
    if (this.form.valid) {
      const payload: CrmTaskCreateNextRequest = {
        ...this.form.value,
        meetingDate: getAPIFormattedDate(this.meetingDateTime),
        meetingTime: getFormattedTimeWithSec(this.meetingDateTime),
      };

      this.taskCreateService
        .crmTaskCreateNext(payload)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.amplitudeTrackService.trackEvent(SALES_FUNNEL_TASK_RECALL, {
              dealId: this.dealId,
            });
            this.createTaskService.goResult();
          },
          error: event => {
            for (const error of event?.errors) {
              this.notifyService.create({
                title: 'Ошибка',
                text: error.errors.join(', '),
                type: NotifyTypeEnum.error,
              });
            }
            this.closeModal.emit();
          },
        });
    }
  }

  getIsDisabledForm() {
    return this.form?.invalid;
  }
}
