import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeExtras',
})
export class RemoveExtrasPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace('ТОО', '').replace(/\"/g, '');
  }
}
