import { Injectable } from '@angular/core';
import {
  DEFAULT_TASK_DURATION_FOR_CALLS_IN_MIN,
  DEFAULT_TASK_DURATION_IN_MIN,
} from '../models/task-durations';

export const CALLING_TYPE = 'calling';

@Injectable({
  providedIn: 'root',
})
export class ActionsPopupHelperService {
  getDuration(type: string): number {
    if (type === CALLING_TYPE) {
      return DEFAULT_TASK_DURATION_FOR_CALLS_IN_MIN;
    }
    return DEFAULT_TASK_DURATION_IN_MIN;
  }

  getDurationString(type: string): string {
    if (type === CALLING_TYPE) {
      return new Date(DEFAULT_TASK_DURATION_FOR_CALLS_IN_MIN * 60 * 1000).toISOString().substr(11, 8);
    }
    return new Date(DEFAULT_TASK_DURATION_IN_MIN * 60 * 1000).toISOString().substr(11, 8);
  }
}
