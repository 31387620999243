<div class="container">
  <div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
      <h4 class="card-header">{{ 'PAGES.LOGIN.TITLE' | translate }}</h4>
      <div class="card-body">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="username">{{ 'PAGES.LOGIN.FIELDS.LOGIN' | translate }}</label>
            <input
              type="text"
              id="username"
              formControlName="username"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }"
            />
            <app-form-error [parentForm]="loginForm" [assignedFormControl]="loginForm.get('username')"></app-form-error>
          </div>
          <div class="form-group position-relative">
            <label for="password">{{ 'PAGES.LOGIN.FIELDS.PASSWORD' | translate }}</label>
            <input
              type="password"
              id="password"
              formControlName="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }"
              [type]="hidePassword ? 'password' : 'text'"
            />
            <img *ngIf="hidePassword"
                 src="assets/icons/eye-show.svg" alt="Show Password" class="toggle-password" (click)="togglePasswordVisibility()" />
            <img *ngIf="!hidePassword"
                 src="assets/icons/eye-hide.svg" alt="Hide Password" class="toggle-password" (click)="togglePasswordVisibility()" />
            <app-form-error [parentForm]="loginForm" [assignedFormControl]="loginForm.get('password')"></app-form-error>
            <div *ngIf="submitted && formControls.password.errors" class="invalid-feedback">
              <div *ngIf="formControls.password.errors.required">
                {{ 'PAGES.LOGIN.ERRORS.PASSWORD' | translate }}
              </div>
            </div>
          </div>
          <button [disabled]="loading || loginForm.invalid" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'PAGES.LOGIN.BUTTONS.LOGIN' | translate }}
          </button>
          <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error | json }}</div>
        </form>
      </div>
    </div>
  </div>
</div>
<link href="//netdna.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" rel="stylesheet" />
