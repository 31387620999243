import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-close-modal-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './close-modal-button.component.html',
  styleUrls: ['./close-modal-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseModalButtonComponent {
  public dialogRef = input<MatDialogRef<any>>();

  closeModal(): void {
    this.dialogRef().close();
  }
}
