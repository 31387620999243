import { OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../backend/src/models/models';

export function apiResponsePipeTyped<T>(): OperatorFunction<ApiResponse<T>, T> {
  return pipe(
    map((data: ApiResponse<T>) => {
      switch (data.statusCode) {
        case 200:
          return data.data;
        case 404:
          throw new Error(`Resource not found: ${JSON.stringify(data.data)}`);
        default:
          throw new Error(`Unexpected API status code: ${data.statusCode}`);
      }
    }),
  );
}
