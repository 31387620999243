<ul *ngIf="deal" class="statistics">
  <li class="action">
    <button class="btn --with-outline"
            (click)="openOnSite()"
    >
      Открыть на сайте
    </button></li>
  <li class="action">
    <button class="btn --with-outline"
            (click)="searchForTours()"
    >
      Сделать подборку
    </button>
  </li>
  <li class="action" *ngIf="!deal?.deal?.id">
    <button class="btn --with-outline"
            (click)="showAddDealPopup()"
    >
      Добавить к себе в работу
    </button>
  </li>


  <li
    *ngIf="deal.deal"
    class="auto-touches-toggle">
    <app-slide-toggle
      [defaultValue]="deal.deal.isAutoActionsDisabled"
      (change)="autoActionsDisabledChanged($event)"
    >
    </app-slide-toggle>
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.ACTION_TYPES.doNotMakeAutomaticTouches'| translate }}</span>
  </li>
</ul>
<ul *ngIf="deal && deal.deal" class="statistics">
  <li *ngIf="deal?.card as card" class="statistic-item statistic-item-actions">
    <div class="statistic-item-label">
      {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.ACTIONS_ON_SITE' | translate }}
    </div>
    <div
      (click)="showModal(card.actionsCount, card.id, card.name)"
      [ngClass]="{ 'has-actions': card.actionsCount > 0 }"
      class="statistic-item-value"
    >
      {{ card.actionsCount }}
    </div>
  </li>
  <li *ngIf="inWorkSince" class="statistic-item">
    <div class="statistic-item-label">{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.IN_WORK' | translate }}</div>
    <div class="statistic-item-value">
      <ng-container *ngIf="inWorkSince">{{ inWorkSince}} </ng-container>
    </div>
  </li>
  <li class="statistic-item" *ngIf="deal?.deal?.createDate">
    <div class="statistic-item-label">{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.CREATED' | translate }}</div>
    <div class="statistic-item-value">{{ deal.deal.createDate | date: DATE_FORMAT }}</div>
  </li>
  <li class="statistic-item" *ngIf="lastInfoUpdate">
    <div class="statistic-item-label">
      {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.LAST_TOUCH_DATE' | translate }}
    </div>
    <div class="statistic-item-value" >{{ lastInfoUpdate | date: DATE_FORMAT }}</div>
  </li>
  <li class="statistic-item" *ngIf="dealSource">
    <div class="statistic-item-label">{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.SOURCE' | translate }}</div>
    <div class="statistic-item-value">{{ dealSource.title }}</div>
  </li>
</ul>
