import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientActionsOnSiteComponent } from '../client-actions-on-site.component';

@Injectable({
  providedIn: 'root',
})
export class ClientActionsModalService {
  constructor(public matDialog: MatDialog) {}

  showModal(crmCardId: number, name: string): void {
    this.matDialog.open(ClientActionsOnSiteComponent, {
      id: 'client-actions-list',
      disableClose: false,
      panelClass: 'modal-panel',
      width: '1100px',
      minHeight: 'calc(100vh - 200px)',
      height: 'auto',
      hasBackdrop: true,
      data: {
        crmCardId,
        name,
      },
    });
  }
}
