import { Component, Input } from '@angular/core';
import { Flight } from '@api-clients/crm-api-client';

@Component({
  selector: 'app-tour-package-flights-info',
  templateUrl: './tour-package-flights-info.component.html',
  styleUrls: ['./tour-package-flights-info.component.scss'],
})
export class TourPackageFlightsInfoComponent {
  @Input() flights: Flight[];
}
