import { Pipe, PipeTransform } from '@angular/core';
import { format, isToday, isYesterday, isThisWeek, addDays, isSameDay, isThisYear } from 'date-fns';
import { ru } from 'date-fns/locale';

@Pipe({
  name: 'whatsappDate',
})
export class WhatsappDatePipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date(value);
    const today = new Date();
    const tomorrow = addDays(today, 1);

    if (isToday(date)) {
      return 'Сегодня';
    } else if (isYesterday(date)) {
      return 'Вчера';
    } else if (isSameDay(date, tomorrow)) {
      return 'Завтра';
    } else if (date > tomorrow && date <= addDays(today, 7)) {
      return format(date, 'EEEE', { locale: ru }); // Возвращает название дня недели
    } else if (isThisWeek(date, { weekStartsOn: 1 })) {
      return format(date, 'EEEE', { locale: ru });
    } else if (isThisYear(date)) {
      // Формат "14 августа" для дат в текущем году, но не на этой неделе
      return format(date, 'd MMMM', { locale: ru });
    } else {
      // Формат "14.08.2025" для дат в других годах
      return format(date, 'dd.MM.yyyy');
    }
  }
}
