import { PhoneItem, WhatsappMessage } from '@api-clients/crm-api-client';

export interface ChatMessagesState {
  messages: WhatsappMessage[];
  contactPhoneItemOrChatId: PhoneItem | string;
}

export const chatMessagesInitialState: ChatMessagesState = {
  messages: [],
  contactPhoneItemOrChatId: null,
};
