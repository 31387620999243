import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NonConversionCallReasonsList } from '../../../../../../sales-funnel-complete-popup/models/non-conversion-call-reasons';
import { CompleteReason } from '../../../../../../../models/complete-deal-type.model';

@Component({
  selector: 'app-non-conversion-reason-list',
  templateUrl: './non-conversion-reason-list.component.html',
  styleUrls: ['./non-conversion-reason-list.component.scss'],
})
export class NonConversionReasonListComponent implements OnInit {
  public readonly NON_CONVERSION_CALL_REASONS = NonConversionCallReasonsList;
  @Input() isListDisabled: boolean;
  @Output() activeReasonKeyChanged: EventEmitter<CompleteReason> = new EventEmitter<CompleteReason>();
  public activeReasonKey: CompleteReason = null;
  public isExpanded = false;
  ngOnInit(): void {
    [this.activeReasonKey] = this.NON_CONVERSION_CALL_REASONS;
  }

  selectType(value: CompleteReason) {
    this.activeReasonKey = value;
    this.activeReasonKeyChanged.emit(value);
  }

  expandReasons() {
    this.isExpanded = !this.isExpanded;
  }
}
