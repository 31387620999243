export const AppConfig = {
  production: true,
  environment: 'PROD',
  apiUrl: 'https://gera-bff.ht.kz/api',
  commonApiUrl: 'https://api.bronix.com/common/v1',
  crmApiUrl: 'https://api.bronix.com/crm/v1',
  baseUrl: 'http://localhost',
  audioRecordToken: 'kioNv5KaHWhRkjBN',
  downloadWhatsappFileUrl: 'https://crm-api.ht.kz/v1',
  amplitudeApiKey: '9257d6460427f2f1b5d76c3c373837e7', // prod
  searchToursUrl: 'https://ws.ht.kz',
  googleMapsKey: 'AIzaSyAzsjK8RByH9FbMl9ly89iXNB3hOFb_kBk',
};
