import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchFormParamsDepartCity } from '@api-clients/api-client';

@Component({
  selector: 'app-favorite-hotels-depart-cities-dropdown',
  templateUrl: './favorite-hotels-depart-cities-dropdown.html',
  styleUrls: ['./favorite-hotels-depart-cities-dropdown.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FavoriteHotelsDepartCitiesDropdownComponent {
  @Input() activeDepartCityId: number;
  @Input() departCities: SearchFormParamsDepartCity[] = [];

  @Output() selected = new EventEmitter<SearchFormParamsDepartCity>();

  selectDepartCity(departCity: SearchFormParamsDepartCity) {
    this.selected.emit(departCity);
  }
}
