import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-last-action-date-time-helper-popup',
  templateUrl: './last-action-date-time-helper-popup.component.html',
  styleUrls: ['./last-action-date-time-helper-popup.component.scss'],
})
export class LastActionDateTimeHelperPopupComponent implements OnInit {
  @Input() date: string;
  @Input() time: string;
  public formattedTime: string;
  public dateFormat = 'd MMMM yyyy';

  ngOnInit(): void {
    if (this.time) {
      this.formattedTime = this.time.replace(':00', '');
    }
  }
}
