import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AlertOnTopComponent } from './components/interact-with-last-client/alert-on-top.component';

@NgModule({
  declarations: [AlertOnTopComponent],
  imports: [CommonModule, SharedModule],
  exports: [AlertOnTopComponent],
})
export class AlertOnTopModule {}
