Исходный текст:
<textarea
  rows="1"
  spellcheck="true"
  appResizableTextarea
  [maxHeightInPx]="250"
  matInput
  [(ngModel)]="initialText"
></textarea>

Что нужно сделать?
<textarea
  rows="1"
  spellcheck="true"
  appResizableTextarea
  [maxHeightInPx]="250"
  [triggerResize]="triggerTextareaResize"
  matInput
  [(ngModel)]="prompt"
  placeholder="Например: придумай продающий текст..."
></textarea>

<div class="prompt-variants">
  @for (promptVariant of promptVariants; track promptVariant) {
    <div class="prompt-variant" (click)="setPrompt(promptVariant)">
      {{ promptVariant.label }}
    </div>
  }
</div>

<div class="right">
  <button class="btn-action generate" (click)="generateCompletion()"
    ><img class="icon" src="assets/icons/timeline/message-menu/ai.svg" /> Сгенерировать</button>
</div>

@if (isLoading$ | async) {
  <app-preloader/>
} @else {
  @if (completionText) {
    Итоговый текст для отправки:
    <textarea
      rows="1"
      spellcheck="true"
      appResizableTextarea
      [maxHeightInPx]="400"
      matInput
      [(ngModel)]="completionText"
    ></textarea>
    <div class="right">
      <button class="btn-action" (click)="sendContent()"
        ><img class="icon" src="assets/icons/content-creator/send-message.svg" /> Отправить</button>
    </div>
  }
}
