<div class="deal-view-content-wrap">
  <aside
    class="deal-view-aside rounded-border"
  >
    <div class="aside-header">
      <div class="view-content-header-title" style="margin-left: 7px">Данные загружаются ...</div>
    </div>
    <div class="preload-content">
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
      <span class="skeleton-box" style="width:97%; height: 4em; margin-top: 7px"></span>
    </div>
  </aside>
  <div class="deal-view-content">
    <div class="view-content-header">
      <h4 class="view-content-header-title">Истоия взаимодействий</h4>
    </div>
    <div class="preload-content">
      <span class="skeleton-box" style="width:99%; height: 5em; margin-top: 10px"></span>
      <span class="skeleton-box" style="width:99%; height: 5em; margin-top: 10px"></span>
      <span class="skeleton-box" style="width:99%; height: 5em; margin-top: 10px"></span>
      <span class="skeleton-box" style="width:99%; height: 5em; margin-top: 10px"></span>
      <span class="skeleton-box" style="width:99%; height: 5em; margin-top: 10px"></span>
      <span class="skeleton-box" style="width:99%; height: 5em; margin-top: 10px"></span>
    </div>

  </div>
</div>
