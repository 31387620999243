import {
  ComponentType,
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

export const POPUP_DATA = new InjectionToken<any>('POPUP_DATA');

export interface PopupConfig {
  anchorElement?: ElementRef;
  position?: {
    right?: number;
    left?: number;
    top?: number;
    bottom?: number;
    width?: number;
    height?: number;
  };
  overlayWidth?: number;
  overlayHeight?: number;
  hasBackdrop?: boolean;
  transparentBackdrop?: boolean;
  connectedPositions?: ConnectedPosition[];
}

@Injectable({ providedIn: 'root' })
export class PopupService {
  private overlayRef: OverlayRef | null = null;
  private backdropClickSubscription: Subscription | null = null;
  public popupOpenSource = new BehaviorSubject<{
    isOpen: boolean;
    closedByBackdropClick: boolean;
  }>({
    isOpen: false,
    closedByBackdropClick: false,
  });

  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  showPopup(componentType: ComponentType<any>, data?: any, config?: PopupConfig): ComponentRef<any> {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.backdropClickSubscription?.unsubscribe();
    }

    let positionStrategy;

    if (config?.anchorElement) {
      let connectedPositions: ConnectedPosition[] = [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ];
      if (config?.connectedPositions?.length > 0) {
        connectedPositions = config.connectedPositions;
      }
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(config?.anchorElement)
        .withFlexibleDimensions(false)
        .withPush(false)
        .withPositions(connectedPositions);
      const overlayConfig: OverlayConfig = {
        positionStrategy,
        hasBackdrop: config?.hasBackdrop ?? true,
        backdropClass: config?.transparentBackdrop ? 'cdk-overlay-transparent-backdrop' : undefined,
      };

      if (config?.overlayWidth) {
        overlayConfig.width = config.overlayWidth;
        overlayConfig.height = config.overlayHeight;
      }

      this.overlayRef = this.overlay.create(overlayConfig);
    } else {
      positionStrategy = this.overlay.position().global();

      if (config?.position) {
        if (config.position.right) {
          positionStrategy = positionStrategy.right(`${config.position.right}px`);
        }
        if (config.position.left) {
          positionStrategy = positionStrategy.left(`${config.position.left}px`);
        }
        if (config.position.top) {
          positionStrategy = positionStrategy.top(`${config.position.top}px`);
        }
        if (config.position.bottom) {
          positionStrategy = positionStrategy.bottom(`${config.position.bottom}px`);
        }
      } else {
        positionStrategy = positionStrategy.centerHorizontally().centerVertically();
      }

      const overlayConfig: OverlayConfig = {
        positionStrategy,
        hasBackdrop: config?.hasBackdrop ?? true,
        backdropClass: config?.transparentBackdrop ? 'cdk-overlay-transparent-backdrop' : undefined,
      };

      if (config?.overlayWidth) {
        overlayConfig.width = config.overlayWidth;
        overlayConfig.height = config.overlayHeight;
      }

      this.overlayRef = this.overlay.create(overlayConfig);
    }

    this.backdropClickSubscription = this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef?.detach();
      this.popupOpenSource.next({ isOpen: false, closedByBackdropClick: true });
    });

    const injector = this.createInjector(this.overlayRef, data);
    const componentRef = this.overlayRef.attach(new ComponentPortal(componentType, null, injector));
    this.popupOpenSource.next({ isOpen: true, closedByBackdropClick: false });

    return componentRef;
  }

  closeAllModals(): void {
    this.overlayRef?.dispose();
    this.backdropClickSubscription?.unsubscribe();
    this.overlayRef = null;
    this.popupOpenSource.next({ isOpen: false, closedByBackdropClick: false });
  }

  updatePosition() {
    if (this.overlayRef) {
      this.overlayRef.updatePosition();
    }
  }

  private createInjector(overlayRef: OverlayRef, data?: any): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(OverlayRef, overlayRef);

    if (data) {
      injectionTokens.set(POPUP_DATA, data);
    }

    return new PortalInjector(this.injector, injectionTokens);
  }
}
