<app-top-menu></app-top-menu>

<div class="app-container" [ngClass]="{'zen-mode': (isZenModeOn$ | async) === true}">
  <div class="aside-nav">
    <app-aside-menu
      *ngIf="(isZenModeOn$ | async) === false">
    </app-aside-menu>

  </div>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</div>
<app-zenmode-paginate *ngIf="isZenModeOn$ | async"></app-zenmode-paginate>
