<div class="info">
  <div class="main-block">
    <div class="block">
      <app-tour-package-info-title
        [infoTitle]="touristsTitle"
      >
      </app-tour-package-info-title>
      <app-tour-package-info-tourists
        [passports]="tourPackage.tourPackage.passports"
      >
      </app-tour-package-info-tourists>
    </div>
    <div class="block">
      <app-tour-package-info-title
        [infoTitle]="tourInfoTitle"
      >
      </app-tour-package-info-title>
      <app-tour-package-tour-info
        [tour]="tourPackage.tourPackage.tours[0]"
      >
      </app-tour-package-tour-info>
    </div>
    <div class="block">
      <app-tour-package-info-title
        [infoTitle]="commentTitle"
      >
      </app-tour-package-info-title>
      <div>
        <app-tour-package-info-comment
        [comment]="tourPackage.tourPackage.comment || null"
        >
        </app-tour-package-info-comment>
      </div>
    </div>
  </div>

  <div class="flights-block" *ngIf="tourPackage?.tourPackage?.flights?.length">
    <app-tour-package-info-title
      [infoTitle]="flightsTitle"
    >
    </app-tour-package-info-title>
    <app-tour-package-flights-info
    [flights]="tourPackage.tourPackage.flights"
    >

    </app-tour-package-flights-info>
    <span class="flight-tip"><span class="star">*</span>
      {{ 'PAGES.DEALS.TOUR_PACKAGE.FLIGHT_TIME_NOTE' | translate }}
    </span>
  </div>
  <div class="buttons-block">
    <ng-container [ngSwitch]="tourPackage.stage">
      <ng-container *ngSwitchCase="tourPackageStageEnum.WaitDocs">
        <button class="btn --blue" (click)="markClientDocumentsAsReady()" [disabled]="isLoading">
          {{ 'PAGES.DEALS.TOUR_PACKAGE.ACTIONS.DOCUMENTS_READY' | translate }}
        </button>
        <button class="btn --blue" (click)="markClientDocumentsAsReceived()" [disabled]="isLoading">
          {{ 'PAGES.DEALS.TOUR_PACKAGE.ACTIONS.DOCUMENTS_RECEIVED' | translate }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="tourPackageStageEnum.WaitConfirm">
        <button class="btn --blue"  [disabled]="isLoading"
                (click)="confirmTourpackage()"
        >
          {{ 'PAGES.DEALS.TOUR_PACKAGE.ACTIONS.CONFIRM_TOURPACKAGE' | translate }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="tourPackageStageEnum.WaitFlight">
      </ng-container>
      <ng-container *ngSwitchCase="tourPackageStageEnum.WaitPayment"></ng-container>
      <ng-container *ngSwitchCase="tourPackageStageEnum.OnRest"></ng-container>
      <ng-container *ngSwitchCase="tourPackageStageEnum.Review"></ng-container>
      <ng-container *ngSwitchDefault>...</ng-container>
    </ng-container>

  </div>
</div>
