import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SearchRequest } from '@api-clients/crm-api-client';

@Component({
  selector: 'app-deals-item-search-options',
  templateUrl: './deals-item-search-options.component.html',
  styleUrls: ['./deals-item-search-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsItemSearchOptionsComponent {
  public stars: string;
  @Input() searchOption: SearchRequest;

  joinedStars(stars: Array<string>): string {
    if (stars.length === 1 && stars.includes('0')) {
      return 'Все';
    }
    return `${stars.join(', ')} звезд`;
  }
}
