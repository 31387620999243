import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HotOffersFilterType, HotOffersFilterValue } from '../../../../hot-offers.model';
import { HotOffersDepartDatesComponent } from '../filter-depart-dates/hot-offers-depart-dates.component';

@Component({
  selector: 'app-hot-offers-filter-calendar',
  templateUrl: './hot-offers-filter-calendar.component.html',
  styleUrls: ['./hot-offers-filter-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    AsyncPipe,
    JsonPipe,
    HotOffersDepartDatesComponent
],
})
export class HotOffersFilterCalendarComponent {
  @Input() departCityId!: number;
  @Input() type!: HotOffersFilterType;
  @Input() values$!: Observable<HotOffersFilterValue[]>;
}
