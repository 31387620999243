import { Injectable } from '@angular/core';
import { PhoneItem } from '@api-clients/crm-api-client/models/phone-item';

@Injectable({
  providedIn: 'root',
})
export class WhatsappChatService {
  getChatIdByPhoneItem(phoneItem: PhoneItem) {
    return `${phoneItem.code}${phoneItem.phone}@c.us`.replace('+', '');
  }

  getChatIdByPhone(phone: string) {
    return `${phone}@c.us`;
  }
}
