<div class="post-sale-card">
  <div class="card-info">
    <app-post-sale-card-header
      [dealId]="crmCardListPostSaleItem.dealListItem.deal.id"
      [payer]="crmCardListPostSaleItem.tourPackages[0]?.tourPackage?.payer"
      [phone]="crmCardListPostSaleItem.dealListItem.card.phone"
      [phoneCode]="crmCardListPostSaleItem.dealListItem.card.phones[0].code"
      [isReturnTourist]="crmCardListPostSaleItem.dealListItem.deal.isReturnTourist"
      [id]="crmCardListPostSaleItem.dealListItem.card.id"
      [tourPackage]="tourPackage"
      [nextTask]="crmCardListPostSaleItem.dealListItem.nextTask"
      [clientAvatar]="crmCardListPostSaleItem.dealListItem?.card?.avatar"
    >
    </app-post-sale-card-header>
    <app-tour-short-info
      [tourPackage]="tourPackage"
    >
    </app-tour-short-info>
  </div>


</div>
<ng-container >
  <div *ngIf="isMinimize; else fullInfo"
       class="toggle"
       (click)="toggleMinimize()">
    <span>Больше информации</span>
    <img src="../../../../../../../../../assets/icons/post-sale/arrow.svg"
         class="show" alt="arrow"/>
  </div>

  <ng-template #fullInfo>
    <app-tour-package-info
    [tourPackage]="tourPackage"
    >
    </app-tour-package-info>
    <div
         class="toggle"
         (click)="toggleMinimize()">
      <span>Меньше информации</span>
      <img src="../../../../../../../../../assets/icons/post-sale/arrow.svg" alt="arrow"/>
    </div>
  </ng-template>
</ng-container>



