import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zoomLevelToPercent',
})
export class ZoomLevelToPercentPipe implements PipeTransform {
  transform(zoomLevel: number): string {
    const zoomFactor = 1.2 ** zoomLevel;
    return `${Math.round((zoomFactor * 100) / 5) * 5}%`;
  }
}
