import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatComponent } from './components/chat.component';
import { MessageItemModule } from './modules/message-item/message-item.module';
import { SharedModule } from '../../../../shared/shared.module';
import { WhatsappFooterLoadingModule } from '../whatsapp-footer-loading/whatsapp-footer-loading.module';
import { WhatsappClientCardModule } from '../whatsapp-client-card/whatsapp-client-card.module';
import { MessageStatusModule } from './modules/message-status/message-status/message-status.module';
import { ChatFooterModule } from '../chat-footer/chat-footer.module';

@NgModule({
  declarations: [ChatComponent],
  imports: [
    CommonModule,
    MessageItemModule,
    MessageStatusModule,
    WhatsappClientCardModule,
    WhatsappFooterLoadingModule,

    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ChatFooterModule,
  ],
  exports: [ChatComponent],
})
export class ChatModule {}
