import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CrmTaskCreateNextRequest,
  CrmTaskItem,
  CrmTaskType,
  DealTourPackageCountSold,
} from '@api-clients/crm-api-client';
import { CrmTaskIntent } from '@api-clients/crm-api-client/dist/models';
import { first, take } from 'rxjs/operators';
import { WorkerStateService } from '../../../../../../../../../core/services';
import { AmplitudeTrackService } from '../../../../../../../../../core/services/amplitude/amplitude-track.service';
import { SALES_FUNNEL_DEAL_FINISHED } from '../../../../../../../../../core/services/amplitude/amplitudeEvents';
import { getAPIFormattedDate, getFormattedTimeWithSec } from '../../../../../../../../../helpers/time';
import {
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../../shared/notify/services/notify.service';
import {
  CompleteDealTypeEnum,
  CompleteReason,
  CompleteReasonsList,
} from '../../../../../models/complete-deal-type.model';
import { CrmTaskApiService } from '../../../../../services/crm-task-api.service';
import { DealViewService } from '../../../../../services/deal-view.service';
import { FinishDealService } from '../../../../../services/finish-deal/finish-deal.service';
import { MarkNonConversionService } from '../../../../../services/mark-non-conversion/mark-non-conversion.service';
import { CurrentStageEnum } from '../../../../sales-funnel-stages/sales-funnel-stages';
import { CreateTaskService } from '../../../services/create-task.service';
import { CreateTaskModalCompleteType } from '../../create-task-modal';

enum DealStatusEnum {
  New = 0,
  InWork = 1,
  PostSale = 2,
}

@Component({
  selector: 'app-complete-deal-step',
  templateUrl: './complete-deal-step.component.html',
  styleUrls: ['./complete-deal-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteDealStepComponent implements OnInit {
  @Input() dealId: number;
  @Input() dealStatus: number | DealStatusEnum;
  @Input() task: CrmTaskItem;
  @Input() crmCardId: number;
  @Input() phone: string;
  @Input() dealStage: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  public form: UntypedFormGroup;
  public completeDealForm: UntypedFormGroup;
  public nonConversionForm: UntypedFormGroup;
  public completeDealTypes: CompleteDealTypeEnum[] = [];
  public types = CompleteDealTypeEnum;
  @Input() completeDealType?: CreateTaskModalCompleteType;
  public selectedCompleteDealType?: CompleteDealTypeEnum | string;
  public countInfo: DealTourPackageCountSold;
  public activeReason: CompleteReason;
  public activeNonConversionReason: CompleteReason;
  public assignedManagerId: number;
  public readonly completeReasonsList = CompleteReasonsList;
  public readonly OtherReasonKey = 'other';
  public readonly TRANSLATE_PATH = 'PAGES.DEALS.SALES_FUNNEL.CREATE_TASK_MODAL.COMPLETE_DEAL.';
  public isLoaded = false;
  constructor(
    private createTaskService: CreateTaskService,
    private dealViewService: DealViewService,
    private formBuilder: UntypedFormBuilder,
    private workerStateService: WorkerStateService,
    private taskCreateService: CrmTaskApiService,
    private notifyService: NotifyService,
    private finishDealService: FinishDealService,
    private cdRef: ChangeDetectorRef,
    private markNonConversionService: MarkNonConversionService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.assignedManagerId = this.workerStateService.currentWorkerValue?.id;
  }

  ngOnInit(): void {
    [this.activeReason] = this.completeReasonsList;
    [this.selectedCompleteDealType] = this.completeDealTypes;
    if (this.completeDealType && this.completeDealType.completeType) {
      this.selectedCompleteDealType = this.completeDealType.completeType;
    }
    this.dealViewService
      .getCountSold(this.dealId)
      .pipe(take(1))
      .subscribe(countInfo => {
        this.countInfo = countInfo;
        this.cdRef.detectChanges();
      });
    if (this.dealStatus === DealStatusEnum.New) {
      this.completeDealTypes = [
        CompleteDealTypeEnum.Booking,
        CompleteDealTypeEnum.Complete,
        CompleteDealTypeEnum.MarkAsNonConversion,
      ];
    } else if (this.dealStatus === DealStatusEnum.InWork) {
      this.completeDealTypes = [
        CompleteDealTypeEnum.Booking,
        CompleteDealTypeEnum.MarkAsAutoTouch,
        CompleteDealTypeEnum.Complete,
      ];
    }
    this.initForm();
  }

  goPrev(): void {
    this.createTaskService.goBack();
  }

  selectCompleteDealType(completeType: CompleteDealTypeEnum) {
    this.selectedCompleteDealType = completeType;
  }

  initForm() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(10, 0, 0, 0);

    this.form = this.formBuilder.group({
      dealId: [this.dealId],
      crmCardId: [this.crmCardId],
      dealStage: [CurrentStageEnum.meeting],
      sendBookingInstruction: [false],
      type: [CrmTaskType.Calling],
      meetingTime: [getFormattedTimeWithSec(tomorrow)],
      meetingDate: [getAPIFormattedDate(tomorrow)],
      managerComment: [this.task?.managerComment || ''],
      assignedManagerId: [this.assignedManagerId],
      intent: [CrmTaskIntent.ChangeDate],
      phone: [this.phone],
    });

    this.completeDealForm = this.formBuilder.group({
      id: [this.dealId, Validators.required],
      taskCloseReason: ['', Validators.required],
      taskCloseComment: [''],
      closeCallId: [null],
    });

    this.nonConversionForm = this.formBuilder.group({
      dealId: [this.dealId, Validators.required],
      reason: ['', Validators.required],
      comment: [''],
    });
    this.isLoaded = true;
    this.cdRef.detectChanges();
  }

  getIsButtonDisabled() {
    if (!this.selectedCompleteDealType) {
      return true;
    }
    if (this.selectedCompleteDealType === this.types.Booking) {
      return !this.countInfo?.count;
    }

    if (this.selectedCompleteDealType === this.types.Complete) {
      if (this.activeReason?.key === this.OtherReasonKey && !this.form?.value?.managerComment) {
        return true;
      }
    }

    return false;
  }

  changeActiveReasonKey($event: CompleteReason) {
    if ($event) {
      this.activeReason = $event;
    }
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    const taskCloseReason =
      this.selectedCompleteDealType === this.types.Complete
        ? this.activeReason?.value
        : this.selectedCompleteDealType;

    if (
      this.selectedCompleteDealType === this.types.Complete ||
      this.selectedCompleteDealType === this.types.Booking
    ) {
      // complete
      this.completeDealForm.patchValue({
        taskCloseReason,
        taskCloseComment: this.form?.value?.managerComment,
      });

      if (this.completeDealForm.valid) {
        this.finishDealService
          .finishDeal(this.completeDealForm?.value)
          .pipe(first())
          .subscribe({
            next: () => {
              this.amplitudeTrackService.trackEvent(SALES_FUNNEL_DEAL_FINISHED, {
                dealId: this.dealId,
              });
              this.createTaskService.goResult();
            },
            error: event => {
              for (const error of event?.errors) {
                this.notifyService.create({
                  title: 'Ошибка',
                  text: error.errors.join(', '),
                  type: NotifyTypeEnum.error,
                });
              }
              this.closeModal.emit();
            },
          });
      }
    } else if (this.selectedCompleteDealType === this.types.MarkAsAutoTouch) {
      if (this.form.valid) {
        const payload: CrmTaskCreateNextRequest = {
          ...this.form?.value,
          dealStage: CurrentStageEnum.autoTouch,
          type: CrmTaskType.Whatsapp,
        };

        this.taskCreateService
          .crmTaskCreateNext(payload)
          .pipe(first())
          .subscribe({
            next: () => {
              this.createTaskService.goResult();
            },
            error: event => {
              for (const error of event?.errors) {
                this.notifyService.create({
                  title: 'Ошибка',
                  text: error.errors.join(', '),
                  type: NotifyTypeEnum.error,
                });
              }
              this.closeModal.emit();
            },
          });
      }
      // mark autoTouches
    } else if (this.selectedCompleteDealType === this.types.MarkAsNonConversion) {
      // mark non conversion
      this.nonConversionForm.patchValue({
        reason: this.activeNonConversionReason?.value,
        comment: this.form?.value?.managerComment,
      });

      if (this.nonConversionForm.valid) {
        this.markNonConversionService
          .markNonConversion(this.nonConversionForm?.value)
          .pipe(first())
          .subscribe({
            next: () => {
              this.createTaskService.goResult();
            },
            error: event => {
              for (const error of event?.errors) {
                this.notifyService.create({
                  title: 'Ошибка',
                  text: error.errors.join(', '),
                  type: NotifyTypeEnum.error,
                });
              }
              this.closeModal.emit();
            },
          });
      }
    }
  }

  changeActiveNonConversionReasonKey($event: CompleteReason) {
    this.activeNonConversionReason = $event;
  }
}
