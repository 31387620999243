import { WhatsappMessage, WhatsappNewMessage } from '@api-clients/crm-api-client';
import { chatMessagesInitialState, ChatMessagesState } from './chat-messages.state';
import { ChatMessagesAction, ChatMessagesActionTypes } from './chat-messages.actions';
import { formatDate, generatePendingMessageId, isCurrentChat } from './chat-messages.helper';

export const USER_FEATURE_KEY = 'chat-messages';
export function chatMessagesReducer(
  state: ChatMessagesState = chatMessagesInitialState,
  action: ChatMessagesAction,
): ChatMessagesState {
  switch (action.type) {
    case ChatMessagesActionTypes.SetChatMessages: {
      return {
        ...state,
        messages: action.payload?.messages ? action.payload?.messages : [],
        contactPhoneItemOrChatId: action.payload.contactPhoneItemOrChatId ? action.payload.contactPhoneItemOrChatId : null,
      };
    }
    case ChatMessagesActionTypes.DeleteMessageSuccess: {
      return {
        ...state,
        messages: state.messages.map(message =>
          message.id === action.messageId ? { ...message, isDeleted: true } : message,
        ),
      };
    }
    case ChatMessagesActionTypes.DeleteMessageSuccess: {
      return {
        ...state,
        messages: state.messages.map(message =>
          message.id === action.messageId ? { ...message, isDeleted: true } : message,
        ),
      };
    }
    case ChatMessagesActionTypes.UpdateChatMessages: {
      const messages: WhatsappMessage[] = [];
      const newMessages: WhatsappNewMessage[] = action.payload.messages;
      const existingMessageIds = new Set(state.messages ? state.messages.map(msg => msg.id) : []);

      newMessages.forEach(message => {
        if (isCurrentChat(message, state, 'UpdateChatMessages')) {
          const newMessage = { ...message.message }; // Create a copy of the message
          if (!existingMessageIds.has(newMessage.id)) {
            newMessage.receivedAt = formatDate(new Date());
            messages.push(newMessage);
          }
        }
      });

      if (state.messages && messages.length) {
        return {
          ...state,
          messages: [...state.messages, ...messages],
        };
      }

      return {
        ...state,
      };
    }

    case ChatMessagesActionTypes.DeletePendingMessage: {
      const newMessage = action.payload.messages[0];
      let pendingId: string;
      // Если удаляют сообщение, которое еще не отправлено. То у него уже есть pending id
      if (newMessage.message.id.startsWith('pending-')) {
        pendingId = newMessage.message.id;
      } else {
        const text = newMessage.message.text || newMessage.message?.media?.fileName || '';
        pendingId = generatePendingMessageId(text);
      }

      const newMessages = state.messages.filter(message => message.id !== pendingId);
      return {
        ...state,
        messages: [...newMessages] || [],
      };
    }
    case ChatMessagesActionTypes.ShowPendingMessage: {
      const messages = action.payload.messages
        .filter(message => isCurrentChat(message, state, 'ShowPendingMessage'))
        .map(message => message.message);

      return {
        ...state,
        messages: [...state.messages, ...messages],
      };
    }

    case ChatMessagesActionTypes.RefreshChatMessagesStatus: {
      if (!Array.isArray(action.payload.messages) || !Array.isArray(state.messages)) {
        throw new TypeError('Expected action.payload.messages and state.messages to be arrays');
      }

      const newMessages = state.messages.map(message => {
        const update = action.payload.messages.find(({ id }) => id === message.id);
        if (update) {
          return {
            ...message,
            status: update.status,
          };
        }
        return message;
      });

      return {
        ...state,
        messages: newMessages,
      };
    }

    case ChatMessagesActionTypes.ResetChat: {
      return {
        messages: [],
        contactPhoneItemOrChatId: null,
      };
    }

    default:
      return state;
  }
}
