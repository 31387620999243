import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../../shared/shared.module';
import { SelectComponent } from './select/select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';

@NgModule({
  declarations: [SelectComponent, CheckboxComponent],
  imports: [CommonModule, SharedModule, NgxMaskModule, NgClickOutsideDirective],
  exports: [SelectComponent, CheckboxComponent],
})
export class FormComponentsModule {}
