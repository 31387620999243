<div class="name">{{ name }}</div>
<div [attr.class]="'values ' + type">
  @for (filter of values$ | async; track filter) {
    <div
      (click)="selectFilter(filter)"
      [class.is-checked]="filter.isChecked"
      [class.disabled]="filter.count === 0"
      class="value" >
      <span class="checkbox-icon">
        @if (!filter.isChecked) {
          <img src="assets/icons/hot-offers/filters-checkbox-off.svg">
        }
        @if (filter.isChecked) {
          <img src="assets/icons/hot-offers/filters-checkbox-on.svg">
        }
      </span>
      <div>
        <span [innerHTML]="filter.labelFilter" class="v"></span>
        @if (showCounter) {
          <span> ({{ filter.count }})</span>
        }
      </div>
    </div>
  }
</div>
