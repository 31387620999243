<div *ngIf="isShow;
      else questionnaireEmpty"
     [ngClass]="{'full-height-mode': fullHeightMode}"
     class="needs">
  <div  [ngClass]="{'show': showMore}" class="needs-list rounded-border">
    <h5 *ngIf="isShowTitle" class="needs-title info-card-content-title">{{ 'PAGES.DEALS.CLIENT_CARD.NEEDS.TITLE' | translate }}</h5>
    <div *ngIf="info" #needsList>
      <span class="needs-answer">{{info}}</span>
    </div>
    <div *ngIf="lastTags?.length">
      <span class="needs-key">{{ 'PAGES.DEALS.CLIENT_CARD.TAGS' | translate }}: </span>
      <span class="needs-answer">{{lastTags.join(', ')}}</span>
    </div>
    <div *ngIf="lastBudget">
      <span class="needs-key">{{ 'PAGES.DEALS.CLIENT_CARD.BUDGET' | translate }}: </span>
      <span class="needs-answer">{{lastBudget.from}}-{{lastBudget.to}}</span>
    </div>
    <div *ngIf="needsForClient">
     <span class="needs-key">{{ 'PAGES.DEALS.CLIENT_CARD.NEEDS_FOR.CLIENT' | translate }}: </span>
     <span class="needs-answer">{{needsForClient}}</span>
   </div>
    <div *ngIf="needsForCompanion">
      <span class="needs-key">{{ 'PAGES.DEALS.CLIENT_CARD.NEEDS_FOR.COMPANION' | translate }}: </span>
      <span class="needs-answer">{{needsForCompanion}}</span>
    </div>
    <div *ngIf="needsForKids">
      <span class="needs-key">{{ 'PAGES.DEALS.CLIENT_CARD.NEEDS_FOR.KIDS' | translate }}: </span>
      <span class="needs-answer">{{needsForKids}}</span>
    </div>
    <div *ngIf="budget>0 && currency">
      <span class="needs-key">{{ 'PAGES.DEALS.CLIENT_CARD.BUDGET' | translate }}: </span>
      <span class="needs-answer">{{budget}}{{currency}}</span>
    </div>

  </div>
  <a #showMoreToggle *ngIf="fullHeightMode && !hideToggle && info" (click)="showMore = !showMore" class="show-more">
    <span class="text-span" *ngIf="showMore">{{ 'COMMON.HIDE' | translate }}</span>
    <span class="text-span" *ngIf="!showMore">{{ 'COMMON.SHOW_MORE' | translate }}</span>
  </a>
</div>

<ng-template #questionnaireEmpty>
  <div class="needs" [ngClass]="{'full-height-mode': fullHeightMode}">
    <h5 *ngIf="isShowTitle" class="needs-title info-card-content-title">{{ 'PAGES.DEALS.CLIENT_CARD.NEEDS.TITLE' | translate }}</h5>
    <div class="needs-list rounded-border">
      <span>
        Не заполнено
      </span>
    </div>
  </div>
</ng-template>
