<div class="choose-action-step">
  <div class="congratulations">
    <img src="{{'assets/icons/deal-view/congratulations'+ ((isAfterCall && isNewClient) ? '-'+isAfterCallId:'')+'.svg'}}"
         alt="congratulations" class="congratulations-icon">
    <h2>{{TRANSLATE_PATH + "CHEERS" + ((isAfterCall && isNewClient) ? '_'+isAfterCallId:'') | translate}}</h2>
  </div>
  <h3 class="question">
    {{TRANSLATE_PATH + "QUESTION"  | translate}}
  </h3>
  <p class="choose-tip">
    {{TRANSLATE_PATH + "TIP" | translate}}
  </p>

  <div class="steps">
    <button class="btn secondary emphasis"
            (click)="chooseAction(actions.NEXT_STAGE, stepsId.NEXT_STAGE)">
      {{TRANSLATE_PATH + "STEPS.NEXT" + ((isAfterCall && isNewClient) ? '_'+isAfterCallId:'')  | translate }}</button>
    <button class="btn secondary subtle"
            (click)="chooseAction(actions.REASSIGN, stepsId.REASSIGN)">{{TRANSLATE_PATH + "STEPS.REASSIGN" | translate}}</button>
    <button class="btn secondary subtle"
            (click)="chooseAction(actions.COMPLETE, stepsId.COMPLETE)">{{TRANSLATE_PATH + "STEPS.FINISH" | translate}}</button>
  </div>

  <div class="additional-block">
    <p class="choose-tip">
      {{TRANSLATE_PATH + "TIP_RECALL" | translate}}
    </p>
    <button class="btn secondary subtle"
            (click)="chooseAction(actions.RECALL, stepsId.RECALL)">{{TRANSLATE_PATH + "STEPS.RECALL" | translate }}
    </button>
    <button class="btn secondary subtle"

            *ngIf="!isNewClient"
            (click)="leaveAsIs()">{{TRANSLATE_PATH + "LEAVE_AS_IS" | translate }}
    </button>

  </div>

</div>
