<div class="helper-popup rounded-border">
  <div class="title">
    <img src="../../../../../../../../../assets/icons/stopwatch.svg" alt="icon">
  </div>
  <div class="text">
    <p>{{ 'PAGES.DEALS.NEXT_TASK.TASK_IS_SCHEDULED' | translate }} </p>
    <div class="second-line"><p>{{'COMMON.PREPOSITIONS.FOR_TIME' | translate}} </p>
      <p class="date-time">{{date | date: dateFormat}} </p>
      <ng-container *ngIf="formattedTime">
        <p>{{'COMMON.PREPOSITIONS.AT' | translate}} </p>
        <p class="date-time">{{formattedTime}}</p>
      </ng-container>
  </div>
  </div>
</div>
