import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CirclePreloaderComponent } from './circle-preloader/circle-preloader.component';

@NgModule({
  declarations: [CirclePreloaderComponent],
  imports: [CommonModule],
  exports: [CirclePreloaderComponent],
})
export class CirclePreloaderModule {}
