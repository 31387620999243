import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { SharedModule } from '../../../../../../shared/shared.module';
import { FormComponentsModule } from '../../../../../form-components/form-components.module';
import { DealListContentHeaderComponent } from './components/deal-list-content-header/deal-list-content-header.component';
import { DealListFiltersComponent } from './components/deal-list-filters/deal-list-filters.component';
import { DealListSortingComponent } from './components/deal-list-sorting/deal-list-sorting.component';
import { DealListContentComponent } from './deal-list-content.component';
import { DealsItemModule } from './deals-item/deals-item.module';
import { DealsListItemNewModule } from './deals-list-item-new/deals-list-item-new.module';
import { DealFilterPipe } from './helpers/deal-filter.pipe';
import { DealFilterByTouchPipe } from './helpers/deal-filter-by-touch.pipe';
import { SortLabelPipe } from './helpers/sort-label.pipe';

@NgModule({
  declarations: [
    DealListContentComponent,
    DealListContentHeaderComponent,
    DealListFiltersComponent,
    DealListSortingComponent,
    DealFilterPipe,
    DealFilterByTouchPipe,
    SortLabelPipe,
  ],
  imports: [
    CommonModule,
    DealsItemModule,
    FormComponentsModule,
    SharedModule,
    ReactiveFormsModule,
    VirtualScrollerModule,
    TranslateModule,
    DealsListItemNewModule,
  ],
  exports: [DealListContentComponent, DealListContentHeaderComponent],
})
export class DealsListContentModule {}
