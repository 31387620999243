import { identify, Identify, init, track } from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';

import { take, tap } from 'rxjs/operators';
import { DealsFacade } from '../../../+state/deals/deals.facade';
import { AppConfig } from '../../../../environments/environment';
import { Worker } from '../../../models';
import { AmplitudeEventData, AmplitudeEventDataWithScreenType } from './amplitudeEventData';
import { AmplitudeEventsType } from './amplitudeEvents';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeTrackService {
  public apiKey: string;
  constructor(private dealsFacade: DealsFacade) {
    this.apiKey = AppConfig.amplitudeApiKey;
  }

  public initAmplitude(worker: Worker) {
    const identifyObj = new Identify();
    identifyObj.set('city', worker.cityId);
    identifyObj.set('officeId', worker.office.id);
    identifyObj.set('officeName', worker.office.name);
    identifyObj.set('username', worker.username);
    identifyObj.set('name', worker.name);
    init(this.apiKey, `employee-${worker.employeeId}`);
    identify(identifyObj);
  }

  public trackEvent(
    event: AmplitudeEventsType,
    data?: AmplitudeEventData | AmplitudeEventDataWithScreenType,
  ) {
    this.dealsFacade.zenmodeOn$
      .pipe(
        take(1),
        tap(isZenModeOn => {
          const enhancedData = isZenModeOn ? { ...data, zenMode: true } : data;
          track(event, enhancedData);
        }),
      )
      .subscribe();
  }
}
