import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-hot-offers-active-flush-all',
  templateUrl: './hot-offers-active-flush-all.component.html',
  styleUrls: ['./hot-offers-active-flush-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HotOffersActiveFlushAllComponent { }
