<div class="popup">
  <div class="header">
    <h1>{{'PAGES.NOTIFICATIONS_CENTER.TITLE' | translate }}</h1>
    <a (click)="clearNotificationsCenter()">{{'PAGES.NOTIFICATIONS_CENTER.CLEAR_ALL' | translate }}</a>
  </div>
  <div class="notify-list-body">
    <div *ngIf="listActual$ | async as list" class="notify-list">
      <ng-container *ngIf="list.list.length; else emptyList">
        <ng-container *ngFor="let item of list.list">
          <app-notify-center-item
            *ngIf="item.isNew"
            [notification]="item"
            (deletedById)="deleteById($event, item.type)"
          >
          </app-notify-center-item>
        </ng-container>
      </ng-container>

    </div>
    <ng-container *ngIf="listActual$ | async as list">
      <ng-container *ngIf="list.list.length">
        <div class="header">
          <h2>{{'PAGES.NOTIFICATIONS_CENTER.TITLE_PREVIOUS' | translate }}</h2>
        </div>
        <div class="notify-list previous">
          <ng-container *ngFor="let item of list.list">
            <app-notify-center-item
              *ngIf="!item.isNew"
              [notification]="item"
              (deletedById)="deleteById($event, item.type)"
            >
            </app-notify-center-item>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

  </div>

  <ng-template #emptyList>
    <div class="empty-list">
      {{'PAGES.NOTIFICATIONS_CENTER.EMPTY_LIST' | translate }}
    </div>
  </ng-template>
</div>


