import { Component, Input } from '@angular/core';
import { ManagerSipStateEnum } from '../../../../../../+state/manager-state/manager-state.state';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
  @Input() status: ManagerSipStateEnum;
  public managerSipStateEnum = ManagerSipStateEnum;
}
