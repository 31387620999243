import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CrmTaskItem, PhoneItem } from '@api-clients/crm-api-client';
import {
  CallItem,
  CrmCardViewItem,
  Note,
  SearchRequest,
  SmsItem,
} from '@api-clients/crm-api-client/dist/models';
import { TourPackage } from '@api-clients/crm-api-client/models';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { ChatTimelineFacade } from 'app/+state/chat-timeline/chat-timeline.facade';
import { ChatTimelineService } from 'app/modules/chat-timeline/services/chat-timeline.service';
import { Observable } from 'rxjs';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import {
  ChatTimelineData,
  FilterListEnum,
  FilterWithCount,
} from '../../../../../chat-timeline/interfaces/chat-timeline.interface';
import { POST_SALE_STATUS } from '../../../deals-list/deal-list';

const LAST_ACTIONS_TYPE_LANG_KEY = 'PAGES.DEALS.LAST_ACTIONS_TYPE';
const INTENT_TYPES_LANG_KEY = 'PAGES.DEALS.INTENT_TYPES';
const CRM_TASK_TYPES_LANG_KEY = 'PAGES.DEALS.CRM_TASK_TYPES';
const MISSED = 'missed';
const URGENT = 'urgent';
const DEFAULT_TIME = '09:00';
enum TimelineItemTypeEnum {
  call = 'call',
  sms = 'sms',
  note = 'note',
  searchRequest = 'searchRequest',
  task = 'task',
  reservation = 'reservation',
}
export interface TimelineItemCall extends CallItem {
  timelineType: TimelineItemTypeEnum.call;
  sortDate: string;
}

export interface TimelineItemSms extends SmsItem {
  timelineType: TimelineItemTypeEnum.sms;
  sortDate: string;
}

export interface TimelineItemNote extends Note {
  timelineType: TimelineItemTypeEnum.note;
  sortDate: string;
}

export interface TimelineItemSearchRequest extends SearchRequest {
  timelineType: TimelineItemTypeEnum.searchRequest;
  sortDate: string;
}

export interface TimelineItemReservation extends TourPackage {
  timelineType: TimelineItemTypeEnum.reservation;
  sortDate: string;
}

export type TimelineItem =
  | TimelineItemCall
  | TimelineItemSms
  | TimelineItemSearchRequest
  | TimelineItemReservation
  | TimelineItemNote;

@Component({
  selector: 'app-deal-view-content',
  templateUrl: './deal-view-content.component.html',
  styleUrls: ['./deal-view-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('.3s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.3s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class DealViewContentComponent implements OnInit, OnChanges {
  @Output() noteViewSuccessfullyCreated: EventEmitter<void> = new EventEmitter<void>();
  @Output() refreshDealView: EventEmitter<void> = new EventEmitter<void>();
  @Input() crmCardViewItem: CrmCardViewItem;
  @Input() isPostSaleDeal: boolean;
  @Input() isNoTasks: boolean;
  @Input() currentPhoneItem: PhoneItem;

  public readonly actionsDateFormat = 'd MMMM yyyy';
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public taskTypes: string[];
  public taskIntents: any;
  public lastActionsType: any;
  public timelineItems: TimelineItem[];
  public hidePhone: boolean;
  public isLoading = true;
  public isShowChatTimeline = true;

  public forms: UntypedFormGroup[] = [];
  public isShowTaskDateTimeInput = true;
  public isShowTasksControllers = true;
  isScheduledMessagesListOpen$: Observable<boolean> = this.chatTimelineFacade.isScheduledMessagesListOpen$;

  public chatTimelineData: ChatTimelineData;

  constructor(
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private chatTimelineService: ChatTimelineService,
    private chatTimelineFacade: ChatTimelineFacade,
  ) {
    this.hidePhone = true;

    this.translate
      .get([LAST_ACTIONS_TYPE_LANG_KEY, INTENT_TYPES_LANG_KEY, CRM_TASK_TYPES_LANG_KEY])
      .pipe(first())
      .subscribe(value => {
        this.lastActionsType = value[LAST_ACTIONS_TYPE_LANG_KEY];
        this.taskIntents = value[INTENT_TYPES_LANG_KEY];
        this.taskTypes = value[CRM_TASK_TYPES_LANG_KEY];
      });
  }

  public closeScheduledMessagesList() {
    this.chatTimelineFacade.closeScheduledMessagesList();
  }

  formatDateForDatetimePicker(date: string) {
    if (!date) {
      return null;
    }
    return date.split('-').reverse().join('.');
  }
  formatTimeForDatetimePicker(time: string) {
    if (!time) {
      return DEFAULT_TIME;
    }
    return time.slice(0, 5);
  }

  createForm(nextTask: CrmTaskItem): UntypedFormGroup {
    return this.fb.group({
      meetingDate: [this.formatDateForDatetimePicker(nextTask.date), Validators.required],
      meetingTime: [this.formatTimeForDatetimePicker(nextTask.time), Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.crmCardViewItem) {
      this.updateChatTimelineData(this.crmCardViewItem);
      this.crmCardViewItem?.tasks?.forEach(task => {
        this.forms.push(this.createForm(task));
        if (task.type === MISSED || task.type === URGENT) {
          this.isShowTaskDateTimeInput = false;
          if (
            this.crmCardViewItem?.tasks.length === 1 &&
            this.crmCardViewItem?.lastTasks.length === 1 &&
            this.crmCardViewItem.deal.status !== POST_SALE_STATUS
          ) {
            this.isShowTasksControllers = false;
          }
        }
      });
      this.initValues();
      this.chatTimelineFacade.getScheduledMessagesList({
        phone: `${this.currentPhoneItem.code}${this.currentPhoneItem.phone}`.replace(/[^0-9]/g, ''),
      });
    }
  }

  initValues(): void {
    this.timelineItems = this.mergeAndSortTimelineItems();

    this.cdRef.detectChanges();
  }

  assignTimelineType(timelineArray: any[], type: string) {
    return timelineArray.map(timelineItem => ({
      ...timelineItem,
      sortDate:
        timelineItem['dateTime'] ||
        timelineItem['addDate'] ||
        timelineItem['receivedAt'] ||
        timelineItem['createdAt'],
      timelineType: type,
    }));
  }

  mergeAndSortTimelineItems(): TimelineItem[] {
    const timelineCalls: TimelineItemCall[] = this.assignTimelineType(
      this.crmCardViewItem.calls,
      TimelineItemTypeEnum.call,
    );

    const timelineSms: TimelineItemSms[] = this.assignTimelineType(
      this.crmCardViewItem.lastSmsList,
      TimelineItemTypeEnum.sms,
    );

    const timelineSearchRequests: TimelineItemSearchRequest[] = this.assignTimelineType(
      this.crmCardViewItem.searchRequests,
      TimelineItemTypeEnum.searchRequest,
    );

    const timelineReservations: TimelineItemReservation[] = this.assignTimelineType(
      this.crmCardViewItem.tourPackages,
      TimelineItemTypeEnum.reservation,
    );

    const timelineNotes: TimelineItemNote[] = this.assignTimelineType(
      this.crmCardViewItem.notes,
      TimelineItemTypeEnum.note,
    );

    const timelineItems: TimelineItem[] = [
      ...timelineCalls,
      ...timelineSms,
      ...timelineSearchRequests,
      ...timelineReservations,
      ...timelineNotes,
    ];
    this.isLoading = false;

    return timelineItems.sort((a: TimelineItem, b: TimelineItem) => {
      return <any>new Date(b.sortDate) - <any>new Date(a.sortDate);
    });
  }

  public resetForms(): void {
    this.forms = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.crmCardViewItem?.currentValue?.tasks?.length !==
      changes?.crmCardViewItem?.previousValue?.tasks?.length
    ) {
      this.updateChatTimelineData(this.crmCardViewItem);
      this.resetForms();
    }
  }

  getAvailableFilters() {
    const availableFilters = [FilterListEnum.all, FilterListEnum.message, FilterListEnum.messageOffer];

    if (this.crmCardViewItem.calls.length) {
      availableFilters.push(FilterListEnum.call);
    }
    if (this.crmCardViewItem.lastSmsList.length) {
      availableFilters.push(FilterListEnum.sms);
    }
    if (this.crmCardViewItem.searchRequests.length) {
      // availableFilters.push(FilterListEnum.searchRequest);
    }
    if (this.crmCardViewItem.tourPackages.length) {
      availableFilters.push(FilterListEnum.reservation);
    }
    return availableFilters;
  }

  getItemsCountForLastMonth(items: any[], dateFieldName: string) {
    const currentDate = new Date();
    const monthAgoItems = items.filter((item: any) => {
      const date = new Date(item[dateFieldName]);
      const diff = Math.abs(currentDate.getTime() - date.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      return diffDays <= 30;
    });

    return monthAgoItems.length;
  }

  getFilterListWithCount() {
    const filterListWithCount: FilterWithCount[] = [];
    const filterItems = [
      { items: this.crmCardViewItem.calls, filter: FilterListEnum.call, dateFieldName: 'dateTime' },
      { items: this.crmCardViewItem.lastSmsList, filter: FilterListEnum.sms, dateFieldName: 'addDate' },
      /* {
        items: this.crmCardViewItem.searchRequests,
        filter: FilterListEnum.searchRequest,
        dateFieldName: 'addDate',
      }, */
      {
        items: this.crmCardViewItem.tourPackages,
        filter: FilterListEnum.reservation,
        dateFieldName: 'addDate',
      },
    ];

    filterItems.forEach(filterItem => {
      if (filterItem.items.length) {
        const count = this.getItemsCountForLastMonth(filterItem.items, filterItem.dateFieldName);
        filterListWithCount.push({
          filter: filterItem.filter,
          count,
        });
      } else {
        filterListWithCount.push({
          filter: filterItem.filter,
          count: 0,
        });
      }
    });

    filterListWithCount.unshift({
      filter: FilterListEnum.all,
      count: 0,
    });

    filterListWithCount.push({
      filter: FilterListEnum.message,
      count: 0,
    });

    filterListWithCount.push({
      filter: FilterListEnum.messageOffer,
      count: 0,
    });

    return filterListWithCount;
  }

  private updateChatTimelineData(crmCardViewItem: CrmCardViewItem) {
    this.chatTimelineData = this.chatTimelineService.createChatTimelineData(crmCardViewItem);
  }
}
