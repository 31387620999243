import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WhatsappMessage } from '@api-clients/crm-api-client';
import { AudioType } from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/interfaces/audio.interface';
import { ChatTimelineService } from '../../../../services/chat-timeline.service';
import { WhatsappMessageTypeList } from '../../../../interfaces/chat-timeline.interface';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-quoted-message',
  templateUrl: './quoted-message.component.html',
  styleUrls: ['./quoted-message.component.scss'],
})
export class QuotedMessageComponent implements OnInit {
  @Output() scrollToQuoteId: EventEmitter<string> = new EventEmitter();
  @Input() message: WhatsappMessage;
  public types = WhatsappMessageTypeList;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public audioMessageType = AudioType.audioMessage;

  constructor(private chatTimelineService: ChatTimelineService) {}

  ngOnInit(): void {}
  scrollToQuote(quoteId) {
    this.chatTimelineService.scrollToMessage(quoteId);
  }
}
