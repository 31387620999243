<div class="wrap">
  <img
    [src]="avatarSrc"
    [ngClass]="{'scale' : isScalable,'dealViewMode' : isDealViewMode,
    'highlighted': isHighlighted}"
    class="avatar" alt="avatar">
  <img
    *ngIf="(isDealViewMode) && (isReturnTourist  || isNew)"
    class="status"
    [src]="statusSrc" alt="status">

</div>
