<div *ngIf="card" class="info-card-header">
  <app-client-avatar
  [clientAvatar]="card.avatar"
  >
  </app-client-avatar>
  <app-client-card-name
    [isNew]="dealInfo.isNew"
    [dealId]="dealInfo.id"
    [name]="card?.name || null"
    [phone]="card.phone"
    [phoneCode]="card.phones[0].code"
    [cardId]="crmId">
  </app-client-card-name>
  <ng-container *ngIf="card?.name">
    <app-client-phone
    [phone]="card.phone"
    [phoneCode]="card.phones[0].code"
    class="bg-gray"
    >
    </app-client-phone>
  </ng-container>
  <app-client-status-tags
    [isReturnTourist]="isReturnTourist"
  >
  </app-client-status-tags>

  <app-online-badge
    *ngIf="isOnlineClient"

  >
  </app-online-badge>
  <div
    class="in-work-since info-card-chip">
    <span class="info-card-chip --title">{{ 'PAGES.DEALS.CLIENT_CARD.IN_WORK_SINCE' | translate }}</span>
    <span>
      <ng-container *ngIf="inWorkSince">{{ inWorkSince }}</ng-container>
    </span>
  </div>
  <div
    (click)="showReservationsList(card.id)"
    *ngIf="card.tpStats.sum"
    class="tour-package-sum info-card-chip"
  >
    <span class="info-card-chip --title">{{ 'PAGES.DEALS.CLIENT_CARD.TOUR_PACKAGE_SUM' | translate }}</span>
    <span class="tour-package-sum-value">{{ card.tpStats.count }} / {{ card.tpStats.sum | mask: 'separator':' ' }}</span>
  </div>
  <div
    *ngIf="card.actionsCount"
    (click)="showActionsModal(card.actionsCount, card.id, card.name)"
    class="actions-on-site info-card-chip"
  >
    <span class="info-card-chip --title">
      {{ 'PAGES.DEALS.CLIENT_CARD.ACTION_COUNT_ON_SITE' | translate }}</span
    >
    <span>{{ card.actionsCount }}</span>
  </div>

  <app-wait-answer
    class="right"
    [dealId]="dealInfo?.id"
    [conversationStatus]="dealInfo.conversationStatus"
    (waitAnswerTouched)="fadeOut()"
  >
  </app-wait-answer>
</div>
