import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent {
  @Input() filter: string;
  @Input() isActive: boolean;
  @Input() isDisabled: boolean;
  @Input() count: number;
  public isHover: boolean;
  public readonly allFilter = 'all';
}
