import { Component, Input } from '@angular/core';
import { Tour } from '@api-clients/crm-api-client';

@Component({
  selector: 'app-vacation-direction',
  templateUrl: './vacation-direction.component.html',
  styleUrls: ['./vacation-direction.component.scss'],
})
export class VacationDirectionComponent {
  @Input() tour: Tour;
}
