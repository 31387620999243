<div class="wrapper">
  <div class="close-btn">
    <img src="assets/icons/whatsapp/close.svg" (click)="onClicked()" />
  </div>
  <div class="message-templates" id="attach-button" *ngIf="isShowMessageTemplates && smsTemplates">
    <div class="attach-popup">
      <ul>
        <li class="field" *ngFor="let template of smsTemplates" (click)="onSelectTemplate(template)">
          <div>
            {{ 'MODALS.SMS.TYPES.' + SMS_TYPES[template.type]['langKey'] | translate }}
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="attach-popup">
    <ul>
      <li class="field" [ngClass]="{ selected: isShowMessageTemplates }" (click)="showTemplates()">
        Вставить шаблон
      </li>
      <li class="field" (click)="openTourSelection()" [class.disabled]="isTourSelectionOpen$ | async">
        <span>Отправить подборку</span>
      </li>
      <li class="field" (click)="triggerFileInput($event)">
        Отправить файл(ы)
        <input #fileInput id="file-upload" type="file" multiple (change)="onFileSelected($event)" />
      </li>
    </ul>
  </div>
</div>
