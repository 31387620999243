import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MarkAsAudioReviewRequest, TourPackage } from '@api-clients/crm-api-client';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CallListService } from '../../services/call-list.service';
import { Notify, NotifyService, NotifyTypeEnum } from '../../../../shared/notify/services/notify.service';

@Component({
  selector: 'app-tourpackages-for-mark-as-audioreview',
  templateUrl: './tourpackages-for-mark-as-audioreview.component.html',
  styleUrls: ['./tourpackages-for-mark-as-audioreview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourpackagesForMarkAsAudioreviewComponent implements OnInit {
  public isLoading = true;
  public tourPackagesList: TourPackage[] = [];
  public isPublish = false;
  public tourPackageIdForMark: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { callId: number },
    public dialogRef: MatDialogRef<TourpackagesForMarkAsAudioreviewComponent>,
    private callListService: CallListService,
    private cdRef: ChangeDetectorRef,
    private notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    this.callListService.getTourPackagesForMarkAsAudioReview().subscribe(tourPackages => {
      this.tourPackagesList = tourPackages;
      this.isLoading = false;
      this.cdRef.markForCheck();
    });
  }

  closePopup(): void {
    this.dialogRef.close();
  }
  toPublish(): void {
    this.isPublish = !this.isPublish;
  }
  getTourPackageIdForMark(tourPackageId: number): void {
    this.tourPackageIdForMark = tourPackageId;
  }

  onSubmit(): void {
    const markAsAudioReviewRequest: MarkAsAudioReviewRequest = {
      callId: this.data.callId,
      tpId: this.tourPackageIdForMark,
      isPublish: this.isPublish,
    };

    this.callListService.markAsAudioReview(markAsAudioReviewRequest).subscribe(
      data => {
        let notify: Notify = {
          title: 'Аудиоотзыв',
          text: 'Звонок отмечен как аудиоотзыв!',
          type: NotifyTypeEnum.success,
        };
        if (data.success) {
          this.cdRef.detectChanges();
          this.isLoading = true;
        } else {
          notify = {
            ...notify,
            text: 'Ошибка при пометки звонкак как аудиоотзыва!',
            type: NotifyTypeEnum.error,
          };
        }
        this.isLoading = false;
        this.dialogRef.close();
        this.notifyService.create(notify);
      },
      error => {
        console.error('error: ', error);
      },
    );
  }
}
