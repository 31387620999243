import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryListComponent } from './components/history-list/history-list.component';
import { HistoryItemComponent } from './components/history-item/history-item.component';

@NgModule({
  declarations: [HistoryListComponent, HistoryItemComponent],
  imports: [CommonModule],
})
export class HistoryModule {}
