  <div class='call-list-item' >
    <div class='call-item-content'>
      <div class='call-item-info'>
        <ng-container *ngIf='callItem.callType === CALL_TYPE.income'>
          <ng-container *ngIf='callItem.answer; else noAnswer'>
            <img src='assets/icons/calls/call-list/green-call-icon.svg' class='call-icon missed-call' alt=''>
            <p class='call-type'> {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.INCOME" | translate }} </p>
          </ng-container>
          <ng-template #noAnswer >
            <img src='assets/icons/calls/call-list/red-call-icon.svg' class='call-icon missed-call' alt=''>
            <p class='call-type'> {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.CALL_STATUS.MISSED" | translate }} </p>
          </ng-template>
        </ng-container>

        <ng-container *ngIf='callItem.callType === CALL_TYPE.outcome'>
          <ng-container *ngIf='callItem.answer; else noAnswer' >
            <img src='assets/icons/calls/call-list/green-call-icon.svg' class='call-icon missed-call' alt=''>
            <p class='call-type'> {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.OUTCOME" | translate }}  </p>
          </ng-container>
          <ng-template #noAnswer >
            <img src='assets/icons/calls/call-list/red-call-icon.svg' class='call-icon missed-call' alt=''>
            <p class='call-type'> {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.OUTCOME" | translate }}
              <b> {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.CALL_STATUS.UNSUCCESSFUL" | translate }}</b> </p>
          </ng-template>
        </ng-container>

        <ng-container *ngIf='callItem.callType === CALL_TYPE.callback'>
          <ng-container *ngIf='callItem.answer; else noAnswer' >
            <img src='assets/icons/calls/call-list/green-call-icon.svg' class='call-icon missed-call' alt=''>
            <p class='call-type'> {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.CALLBACK" | translate }} </p>
          </ng-container>
          <ng-template #noAnswer >
            <img src='assets/icons/calls/call-list/red-call-icon.svg' class='call-icon missed-call' alt=''>
            <p class='call-type'>  {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.CALLBACK" | translate }}
              <b> {{ "PAGES.CALL_LIST.CALL_ITEM.CALL_TYPE.CALL_STATUS.UNSUCCESSFUL" | translate }}  </b></p>
          </ng-template>
        </ng-container>

        <a [routerLink]="['/deals/view', callItem.crmCardId]" class='client-name' *ngIf='callItem.crmCard?.name;'>
          {{ callItem.crmCard.name }}
        </a>

        <div class='client-number' *ngIf='callItem.crmCard?.name; else noCard' >
          <label class="hidden-phone-label">
            {{ callItem?.phone?.code }}
            <input
              class="hidden-phone"
              type="text"
              [hiddenInput]="isPhoneHidden"
              [mask]="secretMask"
              [readOnly]="true"
              [ngModel]="callItem?.phone?.phone"
            />
          </label>
          <span (click)="isPhoneHidden = !isPhoneHidden" class="toggle-phone-visibility">
            <img src='assets/icons/deal-view/hide-icon.svg' alt="" />
          </span>
        </div>

        <ng-template #noCard>
          <div class='client-number'>
            <label [routerLink]="['/deals/view', callItem.crmCardId]"
              class="hidden-phone-label-no-card">
              {{ callItem?.phone?.code }}
              <input
                class="hidden-phone-no-card"
                type="text"
                [hiddenInput]="isPhoneHidden"
                [mask]="secretMask"
                [readOnly]="true"
                [ngModel]="callItem?.phone?.phone"
              />
            </label>
            <span (click)="isPhoneHidden = !isPhoneHidden" class="toggle-phone-visibility">
            <img src='assets/icons/deal-view/hide-icon.svg' alt="" />
          </span>
          </div>
        </ng-template>

        <div class='audio-player'>
          <div class="audio-player-box" *ngIf='callItem.answer'>
                <app-audio
                  [screenType]="screenType"
                  [itemId]="callItem.id"
                  [mediaSource]="callItem.recordingContentLink"
                  [recordingFileLink]="callItem.recordingFileLink"
                  [duration]="callItem.duration"
                  [attr.data-record-id]="callItem.id"
                  [attr.data-record-duration]="callItem.duration"
                  [type]="audioCallType"
                />
          </div>
        </div>
      </div>

      <div class='call-item-extra'>
        <p class='call-item-date' > {{ callItem.dateTime | date: dateFormat }} </p>
        <div class='call-feedback'  *ngIf='callItem.answer'>
          <img src='/assets/icons/calls/call-list/audio-feedback.svg' class='call-info-icon' alt=''>
          <span (click)='showTourPackagesForMarkAsAudioReview()' class='audioreview-link'>
            {{ "PAGES.CALL_LIST.CALL_ITEM.AUDIOREVIEW" | translate }}
          </span>
        </div>
        <div (click)='openCallLinkModal()' class='call-link' >
          <img src='assets/icons/calls/call-list/Share-link.svg' class='call-info-icon' alt=''>
          <span > {{ "PAGES.CALL_LIST.CALL_ITEM.SHARE" | translate }} </span>
          <div class='absolute-section'  #dropDownLink >
            <div class='triangle-section'>
              <div class='triangle'></div>
            </div>
            <div class='dropdown-link'>
              <input class='copy-link-url'  #copyLink  readonly value='{{ callItem.recordingFileLink }} ' >
              <button class='copy-link-btn' (click)='showNotify()' [ngxClipboard]="copyLink" >
                <img src='assets/icons/calls/call-list/copy-link.svg' alt=''>
                <span> {{ "PAGES.CALL_LIST.CALL_ITEM.SHARE_BTN.COPY_LINK" | translate }}  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

