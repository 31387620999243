import { Component, Injector, OnInit } from '@angular/core';
import { PhoneItem, WhatsappMessage } from '@api-clients/crm-api-client';
import { first } from 'rxjs/operators';
import { DEAL_VIEW_OPENED } from '../../../../../../core/services/amplitude/amplitudeEvents';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { MarkAsNotNewService } from '../../../../../deals/modules/deal-view/services/mark-as-not-new/mark-as-not-new.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss'],
})
export class MessageInfoComponent implements OnInit {
  public screenType: ScreenTypes = ScreenTypes.ASIDE_CHATS;
  name: string;
  lastMessage: WhatsappMessage;
  shortMessage: string;
  hasUnreadMessages: boolean;
  phone: PhoneItem;
  crmId: number;
  dealId: number;

  constructor(
    private injector: Injector,
    private amplitudeTrackService: AmplitudeTrackService,
    private markAsNotNewService: MarkAsNotNewService,
  ) {}

  ngOnInit(): void {
    this.name = this.injector.get('name');
    this.lastMessage = this.injector.get('lastMessage');
    this.shortMessage = this.injector.get('shortMessage');
    this.hasUnreadMessages = this.injector.get('hasUnreadMessages');
    this.phone = this.injector.get('phone');
    this.crmId = this.injector.get('crmId');
    this.dealId = this.injector.get('dealId');
  }

  dealViewOpened() {
    this.markAsNotNewService
      .markAsNotNew({
        dealId: this.dealId,
      })
      .pipe(first())
      .subscribe();
    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, { screenType: this.screenType });
  }
}
