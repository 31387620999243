import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-text',
  templateUrl: './message-text.component.html',
  styleUrls: ['./message-text.component.scss'],
})
export class MessageTextComponent implements OnInit {
  @Input() messageText: string;
  @Input() isFromMe: boolean;
  public message: string;

  ngOnInit(): void {
    this.message = this.messageText.replace(/^\s+|\s+$/g, '');
  }
}
