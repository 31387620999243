import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilityModule } from '../../../../../../../../libs/shared/utility/src';
import { UniqueValuePipe } from '../../../../helpers/pipes/unique-value/unique-value.pipe';
import { LastReviewButtonComponent } from '../../../../ui-components/last-review-button/last-review-button.component';
import { ClientReservationsComponent } from './client-reservations.component';
import { ReservationsModalService } from './services/reservations-modal.service';
import { TourPackageListService } from './services/tour-package-list.service';

@NgModule({
  declarations: [ClientReservationsComponent, UniqueValuePipe],
  imports: [CommonModule, TranslateModule, SharedUtilityModule, LastReviewButtonComponent],
  providers: [TourPackageListService, ReservationsModalService],
})
export class ClientReservationsModule {}
