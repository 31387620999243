export enum CompleteDealTypeEnum {
  Complete = 'complete',
  MarkAsNonConversion = 'notConversion',
  MarkAsAutoTouch = 'autoTouch',
  Booking = 'booking',
}

export interface CompleteReason {
  key: string;
  value: string;
}

export const CompleteReasonsList = [
  {
    key: 'clientOtherBooking',
    value: 'client other booking',
  },
  {
    key: 'noContact',
    value: 'no-contact',
  },
  {
    key: 'refuseContact',
    value: 'refuse-contact',
  },
  {
    key: 'refusePrice',
    value: 'refuse-price',
  },
  {
    key: 'putOffRest',
    value: 'put-off-rest',
  },
  {
    key: 'cold client',
    value: 'cold client',
  },
  {
    key: 'phoneIsOff',
    value: 'phone is off',
  },
  {
    key: 'installmentPlanDenied',
    value: 'installment plan denied',
  },
  {
    key: 'other',
    value: 'other',
  },
];
