import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { ZenmodeComponent } from './components/zenmode-list/zenmode.component';
import { ZenmodeWelcomePopupContainerModule } from './modules/zenmode-welcome-popup-container/zenmode-welcome-popup-container.module';
import { ZenmodePaginateModule } from './modules/zenmode-paginate/zenmode-paginate.module';
import { ZenmodeMainListComponent } from './components/zenmode-main-list/zenmode-main-list.component';
import { ShadowtimeListComponent } from './components/shadowtime-list/shadowtime-list.component';
import { ViewedCardsComponent } from './components/viewed-cards/viewed-cards.component';
import { SkippedCardsComponent } from './components/skipped-cards/skipped-cards.component';

@NgModule({
  declarations: [
    ZenmodeComponent,
    ZenmodeMainListComponent,
    ShadowtimeListComponent,
    ViewedCardsComponent,
    SkippedCardsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ZenmodeWelcomePopupContainerModule,
    MatDialogModule,
    ZenmodePaginateModule,
    MatTabsModule,
  ],
})
export class ZenmodeModule {}
