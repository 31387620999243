<div class="content rounded-border overflow-y">
  <div class="recommendations-table">
    <div class="recommendations-table-header rounded-border">
      <span>
          <span class="table-header-icon"><img src="./assets/icons/date-calendar.svg" alt="" /></span>
          <span class="table-header-title">{{'PAGES.RECOMMENDATIONS.DATE' | translate }} </span></span
      >
      <span>
          <span class="table-header-icon"><img alt="" src="./assets/icons/user-gray.svg" /></span>
          <span class="table-header-title">{{'PAGES.RECOMMENDATIONS.CLIENT' | translate }} </span></span
      >
      <span>
          <span class="table-header-icon"><img alt="" src="./assets/icons/phone.svg" /></span>
          <span class="table-header-title">{{'PAGES.RECOMMENDATIONS.PHONE' | translate }} </span></span
      >
      <span>
      </span>
    </div>
    <div *ngIf="standaloneTaskItems?.length; else noTasks" class="recommendations-table-body">

        <div *ngFor="let task of standaloneTaskItems" class="recommendations-table-row">
          <span class="manager">{{task.task.assignedAt}}</span>
          <span class="region">{{task.card.name}}</span>
          <span class="hotel">
            <app-client-phone
            [phoneCode]="task.card.phones[0].code"
            [phone]="task.card.phones[0].phone"
            [isClickable]="true"
            >
          </app-client-phone>
          </span>
          <span class="date">
            <button class="btn --green" (click)="closeTask(task.task.id)">{{'PAGES.RECOMMENDATIONS.CLOSE_TASK' | translate }}</button>
          </span>
        </div>
      </div>
  </div>

</div>

<ng-template #noTasks>
  <span *ngIf="!isLoading">
    {{'PAGES.RECOMMENDATIONS.NO_TASKS' | translate }}
</span>
</ng-template>
<ng-container *ngIf="isLoading">
  <app-preloader></app-preloader>
</ng-container>
