import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseAudioDirective } from '../../../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/base-audio.component';
import { AudioType } from '../../../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/interfaces/audio.interface';
import { AudioService } from '../../../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/services/audio.service';
import { AmplitudeTrackService } from '../../../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../../../core/services/amplitude/amplitudeEventData';
import { AudioTranscriptService } from '../../../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/services/audio-transcript.service';

@Component({
  selector: 'app-message-audio',
  templateUrl: './message-audio.component.html',
  styleUrls: ['./message-audio.component.scss'],
})
export class MessageAudioComponent extends BaseAudioDirective {
  @Input() mediaSource: string;
  @Input() isFromMe: boolean;
  public isShow = false;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public audioMessageType = AudioType.audioMessage;

  public constructor(
    audioService: AudioService,
    cdRef: ChangeDetectorRef,
    sanitizer: DomSanitizer,
    amplitudeTrackService: AmplitudeTrackService,
    audioTranscriptService: AudioTranscriptService,
  ) {
    super(audioService, audioTranscriptService, cdRef, sanitizer, amplitudeTrackService);
  }

  showAudio() {
    this.isShow = true;
  }
}
