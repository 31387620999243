import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsappClientCardComponent } from './components/whatsapp-client-card/whatsapp-client-card.component';
import { WhatsappClientCardLoadingComponent } from './components/whatsapp-client-card-loading/whatsapp-client-card-loading.component';
import { CirclePreloaderModule } from '../circle-preloader/circle-preloader.module';
import { DealsModule } from '../../../deals/deals.module';

@NgModule({
  declarations: [WhatsappClientCardComponent, WhatsappClientCardLoadingComponent],
  imports: [CommonModule, CirclePreloaderModule, DealsModule],
  exports: [WhatsappClientCardLoadingComponent, WhatsappClientCardComponent],
})
export class WhatsappClientCardModule {}
