import { Injectable } from '@angular/core';
import { CrmTaskType } from '@api-clients/crm-api-client';
import {
  IndicatorData,
  IndicatorMessagePartTypeEnum,
  IndicatorPayload,
  IndicatorType,
} from '../models/indicator-payload.model';

@Injectable({
  providedIn: 'root',
})
export class DealIndicatorService {
  getIndicatorData(indicatorPayload: IndicatorPayload): IndicatorData {
    const { conversationStatus, status, calls, task } = indicatorPayload;
    const indicatorData: IndicatorData = {
      type: IndicatorType.NewMessage,
      messageParts: [],
    };
    const translatePathPrefix = 'INDICATORS';

    if (conversationStatus === 1) {
      const prefix = 'NEW_MESSAGE';
      indicatorData.type = IndicatorType.NewMessage;
      indicatorData.messageParts = [
        { type: IndicatorMessagePartTypeEnum.IndicatorEmoji, content: 'PolarBear' },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.NOTICE`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorHighlightedText,
          content: `${translatePathPrefix}.${prefix}.NEW_MESSAGES`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.FROM_CLIENT`,
        },
      ];
    } else if (status === 0) {
      const prefix = 'NEW_CLIENT';
      indicatorData.type = IndicatorType.NewClient;
      indicatorData.messageParts = [
        { type: IndicatorMessagePartTypeEnum.IndicatorText, content: `${translatePathPrefix}.${prefix}.ITS` },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorHighlightedText,
          content: `${translatePathPrefix}.${prefix}.NEW_CLIENT`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.MAKE_TOUCH`,
        },
        { type: IndicatorMessagePartTypeEnum.IndicatorEmoji, content: 'MoneyMouthFace' },
      ];
    } else if (calls[0] && calls[0].answer === false && calls[0].callType === 0) {
      const prefix = 'MISSED_CALLS';

      indicatorData.type = IndicatorType.MissedCall;
      indicatorData.messageParts = [
        { type: IndicatorMessagePartTypeEnum.IndicatorEmoji, content: 'Seal' },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.PAY_ATTENTION`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorHighlightedText,
          content: `${translatePathPrefix}.${prefix}.MISSED_CALL`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.CALL_FROM_CLIENT`,
        },
      ];
    } else if (task && task.id && task.type === CrmTaskType.Urgent) {
      const prefix = 'URGENT_TASK';
      indicatorData.type = IndicatorType.UrgentTask;
      indicatorData.messageParts = [
        { type: IndicatorMessagePartTypeEnum.IndicatorEmoji, content: 'Bomb' },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.MISSION_ACTIVATED`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `PAGES.DEALS.CRM_TASK_TYPES_WITH_POST_SALE.${task.type}`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.YOU_NEED`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorHighlightedText,
          content: `${translatePathPrefix}.${prefix}.CALL_CLIENT`,
        },
      ];
    } else if (task && task.id && task.isExpired === true) {
      const prefix = 'TASK_REMINDER';
      indicatorData.type = IndicatorType.TaskCompletionReminder;
      indicatorData.messageParts = [
        { type: IndicatorMessagePartTypeEnum.IndicatorEmoji, content: 'PawPrints' },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.TIME_TO`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorHighlightedText,
          content: `PAGES.DEALS.CRM_TASK_TYPES_WITH_POST_SALE.${task.type}`,
        },
      ];
    } else if (task && task.id && task.isExpired === false) {
      const prefix = 'PENDING_TASKS';
      indicatorData.type = IndicatorType.PendingTask;
      indicatorData.messageParts = [
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.TASK`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorHighlightedText,
          content: `PAGES.DEALS.CRM_TASK_TYPES_WITH_POST_SALE.${task.type}`,
        },
        {
          type: IndicatorMessagePartTypeEnum.IndicatorText,
          content: `${translatePathPrefix}.${prefix}.CLOSE_IT`,
        },
        { type: IndicatorMessagePartTypeEnum.IndicatorEmoji, content: 'WhiteHeart' },
      ];
    }
    return indicatorData;
  }
}
