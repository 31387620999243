<div class="complaint rounded-border">
  <div class="complaint-header">

    <div class="complaint-data manager">
      <h5 class="complaint-data-title">
        Досихова Алия
        <span (click)="assignTaskToggle = !assignTaskToggle" class="assign-task">
          <img src="./assets/icons/complaints/assign.svg" alt="">
        </span>
      </h5>
      <p class="complaint-data-text">В работе <span>#2382</span></p>
    </div>

    <div class="complaint-data added-by">
      <h5 class="complaint-data-title">Добавлено:</h5>
      <p class="complaint-data-text">Анна Обидник</p>
    </div>

    <div class="complaint-data type">
      <h5 class="complaint-data-title">Тип:</h5>
      <p class="complaint-data-text">На оператора</p>
    </div>

    <div class="complaint-data source">
      <h5 class="complaint-data-title">Источник:</h5>
      <p class="complaint-data-text">Whatsapp</p>
    </div>

    <div class="complaint-data stage">
      <h5 class="complaint-data-title">Этап:</h5>
      <p class="complaint-data-text">После отдыха</p>
    </div>

    <div class="complaint-data client">
      <h5 class="complaint-data-title">Привет от клиента:</h5>
      <p class="complaint-data-text">Оразбекова Акнур</p>
    </div>

    <div class="complaint-data deadline">
      <h5 class="complaint-data-title">Дедлайн:</h5>
      <p class="complaint-data-text">15.06.2020, 00:00</p>
      <p>(Истёк)</p>
    </div>

    <div class="controls">
      <div class="control control-discuss">
        <div class="control-img">
          <img src="./assets/icons/complaints/discuss.svg" alt="">
          <span class="discuss-count">4</span>
        </div>
        <a (click)="discussToggle = !discussToggle" class="control-text">Обсуждение</a>
      </div>
      <div class="control control-complete">
        <img src="./assets/icons/complaints/on-off.svg" alt="">
        <a (click)="completionToggle = !completionToggle" class="control-text">Завершить</a>
      </div>
    </div>
  </div>
  <div class="complaint-text">
    <h5 class="complaint-data-title">Текст обращения</h5>
    <p>Таким образом постоянный количественный рост и сфера нашей активности представляет собой интересный эксперимент проверки форм развития. Товарищи! рамки и место обучения кадров представляет собой интересный эксперимент проверки позиций, занимаемых участниками в отношении поставленных задач. Не следует, однако забывать, что постоянный количественный рост и сфера нашей активности обеспечивает широкому кругу (специалистов) участие в формировании существенных финансовых и административных условий. Значимость этих проблем настолько очевидна, что укрепление и развитие структуры влечет за собой процесс внедрения и модернизации дальнейших направлений развития. Товарищи! новая модель организационной деятельности обеспечивает широкому кругу (специалистов) участие в формировании модели развития. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке новых предложений.</p>
  </div>
  <app-complaint-discuss *ngIf="discussToggle"></app-complaint-discuss>
  <app-complaint-completion *ngIf="completionToggle"></app-complaint-completion>
  <app-complaint-assign *ngIf="assignTaskToggle"></app-complaint-assign>
</div>
