import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DealsListFacade } from '../../../../../../+state/deals-list/deals-list.facade';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';
import { AddDealPopupService } from '../../../add-deal/services/add-deal-popup.service';
import { SalesFunnelCategory, SalesFunnelCategoryEnum, SalesFunnelStage } from '../../deal-list';
import { FeatureToggleService } from '../deals-list-content/helpers/feature-toggle.service';

@Component({
  selector: 'app-deals-menu',
  templateUrl: './deals-menu.component.html',
  styleUrls: ['./deals-menu.component.scss'],
})
export class DealsMenuComponent implements OnInit, OnDestroy {
  @Input() selectedStage: SalesFunnelStage;
  @Input() selectedCategory: SalesFunnelCategory;
  @Input() categories: SalesFunnelCategory[];

  @Output() stageChanged = new EventEmitter<SalesFunnelStage>();
  @Output() categoryChanged = new EventEmitter<SalesFunnelCategory>();

  public isDealListNewUiEnabled: boolean;
  public readonly withoutCategory = SalesFunnelCategoryEnum.withoutCategory;
  public readonly postSaleCategory = SalesFunnelCategoryEnum.postSale;
  public postSaleDropdownIsOpen$ = this.dealsFacade.postSaleDropdownIsOpen$;
  private isPostSaleDropdownOpen: boolean;
  private isDealListNewUi$ = this.dealListNewUiAbTestService.shouldDisplayNewFeature$;

  private destroy$ = new Subject<void>();

  constructor(
    private dealsListFacade: DealsListFacade,
    private dealsFacade: DealsFacade,
    private dealListNewUiAbTestService: FeatureToggleService,
    private addDealPopupService: AddDealPopupService,
  ) {}

  ngOnInit() {
    this.postSaleDropdownIsOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => {
      this.isPostSaleDropdownOpen = isOpen;
    });
    this.isDealListNewUi$.pipe(takeUntil(this.destroy$)).subscribe(isEnabled => {
      this.isDealListNewUiEnabled = isEnabled;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public shouldDisplayCategory(category: SalesFunnelCategory): boolean {
    if (category.name === this.withoutCategory) {
      return false;
    }
    // Исключить категорию "Отложенные" если пользователь не в тестовой группе
    if (category.name === SalesFunnelCategoryEnum.postponed && !this.isDealListNewUiEnabled) {
      return false;
    }
    return true;
  }

  public showAddDealPopup() {
    this.addDealPopupService.showAddDealPopup();
  }

  public isPostSaleCategory(category: SalesFunnelCategory) {
    return category.name === this.postSaleCategory;
  }

  public togglePostSaleDropdown() {
    if (this.isPostSaleDropdownOpen) {
      this.dealsFacade.closePostSaleDropdown();
    } else {
      this.dealsFacade.openPostSaleDropdown();
    }
  }

  public selectStage(stage: SalesFunnelStage): void {
    this.dealsListFacade.setMinimizedAll();
    this.selectedStage = stage;
    this.emitSelectedStage(this.selectedStage);
  }

  public canSelectCategory(category: SalesFunnelCategory) {
    return this.isDealListNewUiEnabled && category.selectable;
  }

  public selectCategory(category: SalesFunnelCategory): void {
    this.categoryChanged.emit(category);
  }

  private emitSelectedStage(stage: SalesFunnelStage): void {
    this.stageChanged.emit(stage);
  }
}
