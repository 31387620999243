import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import {
  Notify,
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../shared/notify/services/notify.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-task-comment',
  templateUrl: './task-comment.component.html',
  styleUrls: ['./task-comment.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TaskCommentComponent implements AfterViewInit, OnChanges {
  @Input() taskComment?: string;
  @Input() importantForClient?: string;
  @Input() speechBubbleBottomLeft = false;
  @Input() speechBubbleTopLeft = false;
  @Input() whiteBg = false;
  @Input() smallText = false;
  @Input() smallPadding = false;
  @Input() isCopyable = false;
  @Input() isFullWidth = false;
  @Input() boxShadow = false;
  @Input() boldText = false;
  @HostBinding('style.width') get width() {
    return this.isFullWidth ? '100%' : 'auto';
  }
  @ViewChild('taskCommentText') textContainer!: ElementRef;

  constructor(
    private cd: ChangeDetectorRef,
    private clipboardService: ClipboardService,
    private notifyService: NotifyService,
  ) {}

  public isOpened = false;
  public isTruncated = false;

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: REFACTOR
    if (changes.taskComment) {
      setTimeout(() => {
        this.checkTruncated();
        this.cd.detectChanges();
      }, 0);
    }
  }

  ngAfterViewInit() {
    this.checkTruncated();
    this.cd.detectChanges();
  }

  public copyTextToClipboard(text: string) {
    this.clipboardService.copy(text);
    const notify: Notify = {
      title: 'Комментарий скопирован',
      text: 'Комментарий успешно скопирован в буфер обмена!',
      type: NotifyTypeEnum.success,
    };
    this.notifyService.create(notify);
  }

  public toggleOpen() {
    if (this.isTruncated) {
      this.isOpened = !this.isOpened;
    }
  }

  private checkTruncated(): void {
    if (this.textContainer) {
      const element: HTMLElement = this.textContainer.nativeElement;
      this.isTruncated = element.scrollWidth > element.clientWidth;
    }
  }
}
