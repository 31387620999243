import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PopupStatusState } from './popup.interface';
import * as PopupActions from './popup.actions';
import * as PopupSelectors from './popup.selectors';

@Injectable()
export class PopupFacade {
  popupStatus$ = this.store.pipe(select(PopupSelectors.getPopupStatus));

  constructor(private store: Store<PopupStatusState>) {}

  setPopupStatus(payload: PopupStatusState) {
    this.store.dispatch(PopupActions.popupStatus({ payload }));
  }
}
