import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';

export interface CurrentCallState {
  deal?: CrmCardViewItem;
  callId?: number | null;
  callUuid?: string | null;
  isSearchOpened: boolean;
}

export const callInitialState: CurrentCallState = {
  deal: null,
  callId: null,
  callUuid: null,
  isSearchOpened: false,
};
