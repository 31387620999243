import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthenticationService, WorkerStateService } from '../core/services';
import { AppConfig } from '../../environments/environment';

/**
 * Добавляет ко всем запросам к API JWT токен
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public isLoggedIn: boolean;
  constructor(
    private authenticationService: AuthenticationService,
    private workerStateService: WorkerStateService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.workerStateService.hasAccess$.subscribe(is => {
      this.isLoggedIn = is;
    });
    const isApiUrl =
      req.url.startsWith(AppConfig.apiUrl) ||
      req.url.startsWith(AppConfig.commonApiUrl) ||
      req.url.startsWith(AppConfig.crmApiUrl) ||
      req.url.startsWith(AppConfig.searchToursUrl);

    if (this.isLoggedIn && isApiUrl) {
      const { token } = this.authenticationService;
      let url = req.url;
      if (!AppConfig.production) {
        const separator = req.url.includes('?') ? '&' : '?';
        url = `${req.url}${separator}XDEBUG_SESSION=1`;
      }
      req = req.clone({
        url,
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(req);
  }
}
