<div *ngIf="searchOption; else notFilled" class="requests">

    <div class="request-item">

      <h5 class="request-name info-card-content-title">
        Запрос клиента
      </h5>
      <ul>
        <li class="region request-item-parameter">
          <ng-container *ngIf="searchOption?.country?.name as countryName">{{ countryName }}</ng-container>
          <ng-container *ngIf="searchOption?.regions[0]?.name as regionName">, {{ regionName }}</ng-container>
        </li>
        <li class="star request-item-parameter">
          <img src="../../../../../../../../../assets/icons/star-blue.svg" alt="" />
          <span *ngIf="searchOption.stars.length; else anyStars">{{ joinedStars(searchOption.stars) }}</span>
          <ng-template #anyStars>
            <span>Любая</span>
          </ng-template>
        </li>
        <li class="person-count request-item-parameter">
          <img alt="" src="../../../../../../../../../assets/icons/user.svg" />
          <span
            >{{ searchOption.adult }} взр.
            <ng-container *ngIf="searchOption.child">
              {{ searchOption.child }} дет.
              <ng-container *ngIf="searchOption.childAges"
                >(<ng-container *ngFor="let age of searchOption.childAges; let index = index"
                  ><ng-container *ngIf="index > 0">, </ng-container>{{ age }}</ng-container
                >)</ng-container
              >
            </ng-container>
          </span>
        </li>
        <li class="person-date request-item-parameter">
          <img alt="" src="../../../../../../../../../assets/icons/calendar.svg" />
          <span
            >{{ searchOption.dateFrom | date: 'd.MM' }}-{{ searchOption.dateTo | date: 'd.MM' }} ({{
              searchOption.nightsFrom + 1
            }}-{{ searchOption.nightsTo + 1 }} дн.)</span
          >
        </li>
      </ul>
    </div>

</div>

<ng-template #notFilled>
<div class="requests">
  <div class="request-item">
    <h5 class="request-name info-card-content-title">
      Запрос клиента
    </h5>
    <span class="request-item-empty">Не заполнено</span>
  </div>
</div>
</ng-template>
