import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-complaint-item',
  templateUrl: './complaint-item.component.html',
  styleUrls: ['./complaint-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplaintItemComponent implements OnInit {
  public completionToggle: boolean;
  public assignTaskToggle: boolean;
  public discussToggle: boolean;

  constructor() {
    this.completionToggle = false;
    this.assignTaskToggle = false;
    this.discussToggle = false;
  }
  ngOnInit(): void {}
}
