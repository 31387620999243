<div class="quoted-message" (click)="scrollToQuote(message.quotedMessageId)">
  <div class="author">{{message.quotedMessage.author}}</div>
  <ng-container [ngSwitch]="message.quotedMessage.type">
    <ng-container *ngSwitchCase="types.Chat">
      <span class="message">{{message.quotedMessage.description}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="types.Ptt">
      <app-audio
        *ngIf="message.quotedMessage?.mediaContentLink as mediaSource; else noAudio"
        [screenType]="screenType"
        [mediaSource]="mediaSource"
        [type]="audioMessageType"
        [isFromMe]="false"
      />
      <ng-template #noAudio>
        <div class="no-audio">
          Аудио сообщение не загружено
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="types.Image">
    <div class="image-wrapper" *ngIf="message.quotedMessage?.mediaContentLink; else noImage">
      <div class="image-container">
        <img [src]="message.quotedMessage?.mediaContentLink"
             alt="image"
             class="image-message"
        >
      </div>
    </div>
      <ng-template #noImage>
        Загрузка ...
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="types.Document">
      <div class="message-document">
        <ng-container *ngIf="message.quotedMessage?.description as text; else unsupported">
          <div class="message-text">
            {{text}}
          </div>
        </ng-container>
        <a *ngIf="message.quotedMessage?.mediaContentLink" [href]="message?.media?.contentLink">
          Скачать документ
        </a>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="types.Video">
      <div class="message-video">
        <video controls preload="metadata">
          <source [src]="message.quotedMessage?.mediaContentLink" type="video/mp4">
          <source [src]="message.quotedMessage?.mediaContentLink" type="video/ogg">
          Ваш браузер не поддерживает тег video.
        </video>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="types.CallLog">
      <div class="whatsapp-call">
        <img src="assets/icons/timeline/phone.svg"
             class="caller"
             alt="call">
        <div class="call-info">
            <span class="call-type-text">
              {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.CALLS.whatsapp_call' | translate }}
            </span>
        </div>
      </div>
    </ng-container>

    <ng-template #unsupported>
      Сообщение не поддерживается
    </ng-template>

    <ng-container *ngSwitchCase="types.Unsupported">
      <ng-container *ngIf="message.text as text; else unsupported">
        {{text}}
      </ng-container>
    </ng-container>
  </ng-container>

</div>
