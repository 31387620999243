<div class="complaint-information top-border-line">
  <h5 class="title">Информация</h5>

  <ul class="discuss-info-list">
    <li class="discuss-info-item">
      <span class="info-type">Город:</span>
      <span class="info-value">Нур-Султан (Астана)</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Привет решён?</span>
      <span class="info-value">Нет</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Жалуются на:</span>
      <span class="info-value">Не указан</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Номер ТП:</span>
      <span class="info-value">#86322</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Телефон клиента:</span>
      <span class="info-value">Не указан</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Клиент:</span>
      <span class="info-value">Оразбекова Акнур</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Звонок:</span>
      <span class="info-value">Не указан</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Уровень сложности:</span>
      <span class="info-value">Зелёный</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Отели в ТП:</span>
      <span class="info-value">Reef Oasis Beach Resort 5*</span>
    </li>
    <li class="discuss-info-item">
      <span class="info-type">Список файлов:</span>
      <span class="info-value">Нет файлов</span>
    </li>
  </ul>
</div>
<div class="complaint-discuss top-border-line">
  <h5 class="title">Обсуждение</h5>
  <ul class="discuss-history">
    <li class="history-item">
      <span class="manager rounded-border">
        <img src="./assets/icons/complaints/client.svg" alt="">
        <span class="name">Аманжол Назерке</span>
      </span>
      <a class="link">
        http://joxi.ru/brRSfdfsTyey942
      </a>
      <span class="comment">Написала Данаре информацию по возврату</span>
      <span class="date">30.06.2020, 10:20</span>
    </li>
    <li class="history-item">
      <span class="manager rounded-border">
        <img src="./assets/icons/complaints/client.svg" alt="">
        <span class="name">Жунусбекова Назерке</span>
      </span>
      <a class="link">
        http://joxi.ru/brRSfdfsTyey942
      </a>
      <span class="comment"></span>
      <span class="date">30.06.2020, 10:20</span>
    </li>
    <li class="history-item">
      <span class="manager rounded-border">
        <img src="./assets/icons/complaints/client.svg" alt="">
        <span class="name">Мохнатов Шмель</span>
      </span>
      <a class="link">
        http://joxi.ru/brRSfdfsTyey942
      </a>
      <span class="comment">Написала Данаре информацию по возврату</span>
      <span class="date">30.06.2020, 10:20</span>
    </li>
    <li class="history-item">
      <span class="manager rounded-border">
        <img src="./assets/icons/complaints/client.svg" alt="">
        <span class="name">Абракадабраева Феличита</span>
      </span>
      <a class="link">
        http://joxi.ru/brRSfdfsTyey942
      </a>
      <span class="comment">Ответ Itours</span>
      <span class="date">30.06.2020, 10:20</span>
    </li>
  </ul>
</div>
