import { Injectable } from '@angular/core';
import { ElectronService } from '../../../../../../../libs/shared/utility/src/lib/services/electron.service';

const APP_PREFIX = '[frontend] ';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private electronService: ElectronService) {}

  log(...data: any[]) {
    const message = APP_PREFIX + JSON.stringify(data);
    this.electronService.ipcRenderer.send('log', message);
  }
}
