<div class="modal actions-popup rounded-border">
  <div class="actions-header">
    <div class="actions-title">{{ 'MODALS.ACTIONS_ON_SITE.TITLE' | translate }} {{ name }}:</div>
    <a (click)="closeModal()" class="close-icon">
      <img alt="" src="../../../../../assets/icons/close-round-outline.svg" />
      <span>{{ 'COMMON.CLOSE' | translate }} </span>
    </a>
  </div>
  <div class="actions-content">
    <div class="actions-table">
      <div class="actions-table-header rounded-border">
        <span>
          <span class="table-header-icon"><img src="../../../../../assets/icons/date-calendar.svg" alt="" /></span>
          <span class="table-header-title">{{ 'MODALS.ACTIONS_ON_SITE.DATE' | translate }} </span></span
        >
        <span class="column-gap"></span>
        <span>
          <span class="table-header-icon"><img alt="" src="../../../../../assets/icons/action-on-site/text.svg" /></span>
          <span class="table-header-title"
            >{{ 'MODALS.ACTIONS_ON_SITE.DESCRIPTION' | translate }}
          </span></span
        >
        <span class="column-gap"></span>
        <span>
          <span class="table-header-icon"><img alt="" src="../../../../../assets/icons/action-on-site/type.svg" /></span>
          <span class="table-header-title">{{ 'MODALS.ACTIONS_ON_SITE.TYPE.TITLE' | translate }} </span></span
        >
        <span class="column-gap"></span>
        <span>
          <span class="table-header-icon"><img alt="" src="../../../../../assets/icons/action-on-site/globe.svg" /></span>
          <span class="table-header-title">{{ 'MODALS.ACTIONS_ON_SITE.URL' | translate }} </span></span
        >
      </div>
      <div *ngIf="actions$ | async as actions" class="actions-table-body">
        <div class="actions-table-row" *ngFor="let action of actions">
          <span class="date">{{ action?.date | date: dateFormat }}</span>
          <span class="column-gap"></span>
          <span class="description">
            {{ action?.description | removeHtmlEntities }}
          </span>
          <span class="column-gap"></span>
          <span class="type">
            {{ 'MODALS.ACTIONS_ON_SITE.TYPE.' + action?.type | translate }}</span
          >
          <span class="column-gap"></span>
          <span class="url">
            <a libExternalLink [externalURL]="action?.url">Перейти</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
