export const NonConversionCallReasonsList = [
  {
    key: 'review',
    value: 'review',
  },
  {
    key: 'avia',
    value: 'avia',
  },
  {
    key: 'visa',
    value: 'visa',
  },
  {
    key: 'local tourism',
    value: 'local tourism',
  },
  {
    key: 'commercial proposal',
    value: 'commercial proposal',
  },
  {
    key: 'birthday',
    value: 'birthday',
  },
  {
    key: 'cold call',
    value: 'cold call',
  },
  {
    key: 'different direction',
    value: 'different direction',
  },
  {
    key: 'mistake',
    value: 'mistake',
  },
  {
    key: 'other city',
    value: 'other city',
  },
  {
    key: 'partners',
    value: 'partners',
  },
  {
    key: 'address and work scheduler',
    value: 'address and work scheduler',
  },
  {
    key: 'refuse',
    value: 'refuse',
  },
];
