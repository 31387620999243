import { CurrentOutcomeCallState } from './current-outcome-call.interface';
import { currentOutcomeCallInitialState } from './current-outcome-call.state';
import { CurrentOutcomeCallAction, CurrentOutcomeCallActionsTypes } from './current-outcome-call.actions';

export const USER_FEATURE_KEY = 'current-outcome-call';

export function currentOutcomeCallReducer(
  state: CurrentOutcomeCallState = currentOutcomeCallInitialState,
  action: CurrentOutcomeCallAction,
): CurrentOutcomeCallState {
  switch (action.type) {
    case CurrentOutcomeCallActionsTypes.SetCurrentOutcomeCall: {
      return {
        ...state,
        clientPhone: action.payload.clientPhone,
        clientName: action.payload.clientName,
      };
    }
    case CurrentOutcomeCallActionsTypes.UpdateCurrentOutcomeCallInfo: {
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
      };
    }

    case CurrentOutcomeCallActionsTypes.ClearCurrentOutcomeCall: {
      return {
        ...state,
        success: null,
        message: '',
        clientPhone: '',
        clientName: '',
      };
    }
    default:
      return state;
  }
}
