import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ChatRecommendationMessage } from '@api-clients/crm-api-client/models/chat-recommendation-message';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedModule } from '../../../../../shared/shared.module';
import { CloseModalButtonComponent } from '../../../../../ui-components/close-modal-button/close-modal-button.component';
import { i18nEmoji } from '../../../../whatsapp/modules/chat-footer/components/whatsapp-send-message/emoji.model';

@Component({
  selector: 'app-frontend-edit-message-recommendation',
  standalone: true,
  imports: [CommonModule, CloseModalButtonComponent, PickerModule, MatInput, SharedModule],
  templateUrl: './edit-message-recommendation.component.html',
  styleUrls: ['./edit-message-recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMessageRecommendationComponent {
  public messageText: string;
  public isEmojiPickerVisible = false;

  @ViewChild('messageTextArea') messageTextArea: ElementRef;
  protected readonly i18nEmoji = i18nEmoji;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: ChatRecommendationMessage;
    },
    public dialogRef: MatDialogRef<EditMessageRecommendationComponent>,
  ) {
    this.messageText = data.message.text;
  }

  sendMessage(): void {
    this.dialogRef.close({
      action: 'sendMessage',
      message: this.messageText,
    });
  }

  addEmoji(event: any): void {
    const emoji = event.emoji.native as string;
    const textareaElement: HTMLTextAreaElement = this.messageTextArea.nativeElement;
    const selectionStartPosition = textareaElement.selectionStart ?? 0;
    const selectionEndPosition = textareaElement.selectionEnd ?? 0;
    this.messageText =
      this.messageText.slice(0, selectionStartPosition) +
      emoji +
      this.messageText.slice(selectionEndPosition);
    setTimeout(() => {
      const newCursorPosition = selectionStartPosition + emoji.length;
      textareaElement.setSelectionRange(newCursorPosition, newCursorPosition);
      textareaElement.focus();
    }, 0);
    this.isEmojiPickerVisible = false;
  }

  toggleEmojiPicker(): void {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }
}
