<div class="message-quote" [ngClass]="isFromMe ? 'from-me' : 'from-client'">
  <svg width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1C0 0.447716 0.447715 0 1 0C1.55228 0 2 0.447715 2 1V37C2 37.5523 1.55228 38 1 38C0.447715 38 0 37.5523 0 37V1Z" fill="#007CF0"/>
  </svg>
  <div class="quote-text">
          <span class="client-name">
            {{quotedMessageAuthor}}
          </span>
    <span class="message-text">{{quotedMessageText}}</span>
  </div>
</div>
