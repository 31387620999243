<div *ngIf="hotel" class="hotel-info">
  <div class="title">
    {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MANAGER_OFFER' | translate }}
  </div>
  <div class="counters">
    <div class="main-icon"></div>
    <div
      class="counter"
      [ngClass]="{  'no-records': !hotel.photosCount  }"
      title="{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.PHOTOS' | translate }}"
      (click)="showContentCreatorModalPhotos()"
    >
      <img src="assets/icons/timeline/message-menu/photo.svg" />
      <div class="value">{{ photosCount }}</div>
    </div>
    <div
      class="counter"
      [ngClass]="{ 'no-records': !hotel.videosCount }"
      title="{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.VIDEOS' | translate }}"
      (click)="showContentCreatorModalVideos()"
    >
      <img src="assets/icons/timeline/message-menu/video.svg" />
      <div class="value">{{ hotel.videosCount }}</div>
    </div>
    <div
      class="counter"
      [ngClass]="{  'no-records': !hotel.audioPresentationsCount  }"
      title="{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.AUDIO_PRESENTATION' | translate }}"
      (click)="showContentCreatorModalAudioPresentation()"
    >
      <img src="assets/icons/timeline/message-menu/audio.svg" />
      <div class="value">{{ audioPresentationsCount }}</div>
    </div>
    <div
      class="counter"
      [ngClass]="{ 'no-records': !hotel.reviewsWithTextCount }"
      title="{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.REVIEWS' | translate }}"
      (click)="showContentCreatorModalReviews()"
    >
      <img src="assets/icons/timeline/message-menu/like.svg" />
      <div class="value">{{ hotel.reviewsWithTextCount }}</div>
    </div>
    <div
      class="counter"
      [ngClass]="{ 'no-records': !hotel.managerCommentsCount }"
      title="{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.MANAGER_COMMENTS' | translate }}"
      (click)="showContentCreatorModalManagerComments()"
    >
      <img src="assets/icons/timeline/message-menu/comment.svg" />
      <div class="value">{{ hotel.managerCommentsCount }}</div>
    </div>
  </div>
</div>
<button
  *ngIf="(isMessage && !isDeleted && !isPendingMessageStatus) || isScheduledMessage"
  class="reply-button"
  type="button"
  title="reply button"
  [matMenuTriggerFor]="replyMenu"
  [ngClass]="{  'is-hotel': !!this.hotel  }"
  (menuOpened)="toggleReplyMenu()"
  (menuClosed)="toggleReplyMenu()"
>
  <img [src]="getReplyButtonIconSrc()" alt="" />
</button>

<mat-menu #replyMenu="matMenu" class="reply-menu">
  <button
    *ngIf="isMenuItemVisible('reply-to-message')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showReply()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/reply.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.REPLY' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('deleteMessage')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="deleteMessage()"
  >
    <img class="icon" width="24" src="assets/icons/timeline/message-menu/slash.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.DELETE' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('calendar')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showContentCreatorModalPricesCalendar()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/calendar.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.PRICES_CALENDAR' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('photos')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showContentCreatorModalPhotos()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/photo.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.PHOTOS' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('videos')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showContentCreatorModalVideos()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/video.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.VIDEOS' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('audioPresentation')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showContentCreatorModalAudioPresentation()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/audio.svg" />
    <span>{{

      'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.AUDIO_PRESENTATION' | translate

    }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('reviews')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showContentCreatorModalReviews()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/like.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.REVIEWS' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('managerComments')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showContentCreatorModalManagerComments()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/comment.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.MANAGER_COMMENTS' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('gpt')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="showContentCreatorModalGpt()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/ai.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.GPT' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('repeatSearch')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="repeatSearch()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/search.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.REPEAT_SEARCH' | translate }}</span>
  </button>
  <button
    *ngIf="isMenuItemVisible('deleteScheduledMessage')"
    mat-menu-item
    class="reply-menu-item"
    type="button"
    (click)="deleteScheduledMessage()"
  >
    <img class="icon" src="assets/icons/timeline/message-menu/decline.svg" />
    <span>{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_MENU.DELETE_SCHEDULED' | translate }}</span>
  </button>
</mat-menu>
