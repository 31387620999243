import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';
import { WorkersListService } from '../../../../layout/components/workers-list/services/workers-list.service';
import { Worker } from '../../../../../models';

const MANAGER_KEY = 'manager';
const SALES_HEAD_KEY = 'salesHead';
const CALL_OPERATOR_KEY = 'call_operator';
const ADMIN_KEY = 'admin';
const SENIOR_KEY = 'senior';

@Injectable({
  providedIn: 'root',
})
export class ManagerListService {
  public availableRoles = [MANAGER_KEY, SALES_HEAD_KEY, CALL_OPERATOR_KEY, ADMIN_KEY, SENIOR_KEY];

  constructor(private workersListService: WorkersListService, private translatePipe: TranslatePipe) {}

  getManagersList(cityId: number): Observable<Worker[]> {
    return this.workersListService.fetchWorkersList(cityId).pipe(
      map(values => {
        const managers = values.filter(v => this.availableRoles.includes(v.role));

        const callOperatorManagers = managers.filter(manager => manager.role === CALL_OPERATOR_KEY);
        const otherManagers = managers.filter(manager => manager.role !== CALL_OPERATOR_KEY);

        const sortedManagers = [...callOperatorManagers, ...otherManagers];

        return sortedManagers.map(manager => {
          const { name, sip } = manager;

          const role =
            manager.role !== MANAGER_KEY
              ? `(${this.translatePipe.transform(`PAGES.DEALS.WORKER_ROLES.${manager.role}`)})`
              : '';

          return {
            ...manager,
            nameWithSip: `${name} (${sip.sips[0]}) ${role}`,
          };
        });
      }),
    );
  }
}
