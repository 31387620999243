<div class="menu-container">
  <ul *ngIf="categories" class="deals-menu">
    <li *ngFor="let category of categories" class="deals-menu-item has-submenu">
      <span
        *ngIf="shouldDisplayCategory(category)"
        class="menu-item-link rounded-border"
        [class.has-new-messages]="category.hasNewMessages"
        [class.selectable]="canSelectCategory(category) && category.selectable"
        [class.--active]="category.name === selectedCategory?.name"
        (click)="canSelectCategory(category) && selectCategory(category)"
      >
        {{ 'PAGES.DEALS.SALES_FUNNEL.CATEGORIES.' + category.name | translate }}
        <ng-container *ngIf="canSelectCategory(category)">
          <span *ngIf="category.count_all" class="counter">
            <span class="counter-value">
              <ng-container *ngIf="category.count_forgotten">
                <span class="forgotten">
                  {{ category.count_forgotten }}
                </span>
                <span>/</span>
              </ng-container>
              {{ category.count_all }}
            </span>
          </span>
        </ng-container>

        <span (click)="togglePostSaleDropdown()" *ngIf="isPostSaleCategory(category)" class="dropdown-arrow">
          <ng-container *ngIf="postSaleDropdownIsOpen$ | async; else arrowDown">
            <!-- Стрелка вверх -->
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.3334 10.6666L8.70715 6.04042C8.31662 5.6499 7.68346 5.6499 7.29293 6.04042L2.66671 10.6666"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </ng-container>
          <ng-template #arrowDown>
            <!-- Стрелка вниз -->
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.3334 5.33335L8.70715 9.95958C8.31662 10.3501 7.68346 10.3501 7.29293 9.95958L2.66671 5.33335"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </ng-template>
        </span>
      </span>

      <div *ngIf="isPostSaleCategory(category); else otherCategory">
        <ng-container *ngIf="postSaleDropdownIsOpen$ | async">
          <app-deals-menu-stages-list
            [stages]="category.stages"
            [selectedStage]="selectedStage"
            (selectStage)="selectStage($event)"
          ></app-deals-menu-stages-list>
        </ng-container>
      </div>

      <ng-template #otherCategory>
        <app-deals-menu-stages-list
          [stages]="category.stages"
          [selectedStage]="selectedStage"
          (selectStage)="selectStage($event)"
          [isNewUi]="isDealListNewUiEnabled"
        ></app-deals-menu-stages-list>
      </ng-template>
    </li>
  </ul>
  <ng-container *ngIf="isDealListNewUiEnabled">
    <button (click)="showAddDealPopup()" class="btn secondary ghost-outlined rounded-border add-deal-btn">
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Vector 3"
          d="M8.5 3.33594V12.6693"
          stroke="#007CF0"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector 4"
          d="M13.1666 8L3.83329 8"
          stroke="#007CF0"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>{{ 'PAGES.DEALS.ADD_DEAL.TITLE' | translate }}</span>
    </button>
  </ng-container>
</div>
