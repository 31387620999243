import { Injectable } from '@angular/core';

import { CrmCardViewItem, WhatsappMessageTypeList } from '@api-clients/crm-api-client/dist/models';
import { WhatsappMessage } from '@api-clients/crm-api-client';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChatTimeLineItemComponent } from '../components/chat-time-line-item/chat-time-line-item.component';
import {
  CallTypes,
  ChatTimeLineDayItem,
  ChatTimeLineItem,
  ChatTimeLineItemsDataType,
  ChatTimelineData,
  ChatTimelineItemTypeEnum,
  ImageItem,
} from '../interfaces/chat-timeline.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatTimelineService {
  private messageTextSelectedSubject$ = new BehaviorSubject<ChatTimeLineItemComponent>(null);
  public messageTextSelected$ = this.messageTextSelectedSubject$.asObservable();
  private scrollToMessageSubject$ = new Subject<string>();
  public scrollToMessage$ = this.scrollToMessageSubject$.asObservable();
  private scrollToMessageWithTourSubject$ = new Subject<string>();
  public scrollToMessageWithTour$ = this.scrollToMessageWithTourSubject$.asObservable();

  scrollToMessage(messageId: string) {
    this.scrollToMessageSubject$.next(messageId);
  }

  scrollToMessageWithTour(tourId: string) {
    this.scrollToMessageWithTourSubject$.next(tourId);
  }

  generateChatTimeLineItems(
    items: ChatTimeLineItemsDataType[],
    type: ChatTimelineItemTypeEnum,
  ): ChatTimeLineItem[] {
    if (!items?.length) {
      return [];
    }
    const result: ChatTimeLineItem[] = [];
    for (const item of items) {
      const chatTimeLineItem: ChatTimeLineItem = {
        type,
        data: item,
        sortDate:
          item['dateTime'] ||
          item['addDate'] ||
          item['receivedAt'] ||
          item['createdAt'] ||
          // TODO: fix on backend WhatsappScheduledMessage object. Replace scheduled_at with scheduledAt as declared in types
          item['scheduled_at'],
      };
      result.push(chatTimeLineItem);
    }
    return result;
  }

  getAllChatTimeLineItems(chatTimelineData: ChatTimelineData): ChatTimeLineItem[] {
    if (!chatTimelineData) {
      return [];
    }

    const allChatTimeLineItems: ChatTimeLineItem[] = [];
    const calls = this.generateChatTimeLineItems(chatTimelineData.calls, ChatTimelineItemTypeEnum.call);
    const sms = this.generateChatTimeLineItems(chatTimelineData.lastSmsList, ChatTimelineItemTypeEnum.sms);
    /*
     // @deprecated Заменили на подборки туров
     const searchRequests = this.generateChatTimeLineItems(
      chatTimelineData.searchRequests,
      ChatTimelineItemTypeEnum.searchRequest,
    ); */
    const reservations = this.generateChatTimeLineItems(
      chatTimelineData.tourPackages,
      ChatTimelineItemTypeEnum.reservation,
    );
    const notes = this.generateChatTimeLineItems(chatTimelineData.notes, ChatTimelineItemTypeEnum.note);
    const messages = this.generateChatTimeLineItems(
      chatTimelineData.lastWhatsappMessages,
      ChatTimelineItemTypeEnum.message,
    );

    const scheduledMessages = this.generateChatTimeLineItems(
      chatTimelineData.whatsappScheduledMessages,
      ChatTimelineItemTypeEnum.scheduledMessage,
    );
    calls.forEach(item => allChatTimeLineItems.push(item));
    sms.forEach(item => allChatTimeLineItems.push(item));
    // searchRequests.forEach(item => allChatTimeLineItems.push(item));
    reservations.forEach(item => allChatTimeLineItems.push(item));
    notes.forEach(item => allChatTimeLineItems.push(item));
    // messages.forEach(item => allChatTimeLineItems.push(item));
    this.groupMessagesWithImages(messages).forEach(item => allChatTimeLineItems.push(item));
    scheduledMessages.forEach(item => allChatTimeLineItems.push(item));

    return allChatTimeLineItems;
  }

  sortChatTimelineItemsByDay(items: ChatTimeLineItem[]): ChatTimeLineDayItem[] {
    if (!items?.length) {
      return [];
    }
    let chatTimeLineDayItems: ChatTimeLineDayItem[] = [];
    for (const item of items) {
      const sortDateDay = item.sortDate.split(' ')[0];
      const foundIndex = chatTimeLineDayItems.findIndex(el => el.sortDate === sortDateDay);
      if (foundIndex === -1) {
        const dayItem: ChatTimeLineDayItem = {
          sortDate: sortDateDay,
          items: [item],
        };
        chatTimeLineDayItems.push(dayItem);
      } else {
        chatTimeLineDayItems[foundIndex].items.push(item);
      }
    }

    chatTimeLineDayItems = chatTimeLineDayItems.sort((a, b) => {
      return new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime();
    });

    return chatTimeLineDayItems;
  }

  generateChatTimeLineDayItems(chatTimelineData: ChatTimelineData) {
    let items: ChatTimeLineItem[] = this.getAllChatTimeLineItems(chatTimelineData);

    items = items.sort((a, b) => {
      return new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime();
    });
    return this.sortChatTimelineItemsByDay(items);
  }

  generateChatTimeLineItemsByWhatsappMessages(messages: WhatsappMessage[]) {
    let items: ChatTimeLineItem[] = this.generateChatTimeLineItems(
      messages,
      ChatTimelineItemTypeEnum.message,
    );
    items = items.sort((a, b) => {
      return new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime();
    });
    return items;
  }

  generateChatTimeLineItemByWhatsappMessage(message: WhatsappMessage): ChatTimeLineItem {
    return {
      type: ChatTimelineItemTypeEnum.message,
      data: message,
      sortDate: message.receivedAt,
    };
  }
  generateImages(messages: WhatsappMessage[]): ImageItem[] {
    const images: ImageItem[] = [];
    if (!messages?.length) {
      return [];
    }
    for (const message of messages) {
      if (message.type === WhatsappMessageTypeList.Image) {
        const imageItem: ImageItem = {
          date: message.receivedAt,
          id: message.id,
          contentLink: message?.media?.contentLink,
          text: message.text,
        };
        images.push(imageItem);
      }
    }
    return images;
  }

  public getIsFromClient(chatTimeLineItem: ChatTimeLineItem) {
    switch (chatTimeLineItem.type) {
      case ChatTimelineItemTypeEnum.call:
        return (
          chatTimeLineItem.data.callType !== CallTypes.fromManager &&
          chatTimeLineItem.data.callType !== CallTypes.outcome
        );

      case ChatTimelineItemTypeEnum.sms:
        return false;
      case ChatTimelineItemTypeEnum.note:
        return false;
      case ChatTimelineItemTypeEnum.reservation:
        return false;
      case ChatTimelineItemTypeEnum.task:
        return false;
      case ChatTimelineItemTypeEnum.message:
        return !chatTimeLineItem.data.isFromMe;
      default:
        return false;
    }
  }

  getMessageTime(sortDate: string) {
    const date = new Date(sortDate);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
  }

  getMessageStatus(chatTimeLineItem: ChatTimeLineItem) {
    return chatTimeLineItem.data.isFromMe ? chatTimeLineItem.data.status : null;
  }

  public addFirstAndLastInGroupFlag(items: ChatTimeLineItem[]): void {
    const lastIndex = items.length - 1;
    for (let i = lastIndex; i >= 0; i--) {
      if (i === 0) {
        items[i].isLastOfGroup = true;
      } else {
        items[i].isLastOfGroup = this.isLastOfGroup(items[i], items[i - 1]);
      }

      if (i === lastIndex) {
        items[i].isFirstOfGroup = true;
      } else {
        items[i].isFirstOfGroup = this.isFirstOfGroup(items[i], items[i + 1]);
      }
    }
  }

  private isLastOfGroup(current: ChatTimeLineItem, next: ChatTimeLineItem): boolean {
    return current.data.contactId !== next.data.contactId;
  }

  private isFirstOfGroup(current: ChatTimeLineItem, prev: ChatTimeLineItem): boolean {
    return current.data.contactId !== prev.data.contactId;
  }

  public createChatTimelineData(crmCardViewItem: CrmCardViewItem): ChatTimelineData {
    return {
      lastWhatsappMessages: [...crmCardViewItem.lastWhatsappMessages],
      lastSmsList: [...crmCardViewItem.lastSmsList],
      calls: [...crmCardViewItem.calls],
      searchRequests: [...crmCardViewItem.searchRequests],
      tourPackages: [...crmCardViewItem.tourPackages],
      notes: [...crmCardViewItem.notes],
    };
  }

  public messageTextSelected(chatTimeLineItemComponent: ChatTimeLineItemComponent) {
    this.messageTextSelectedSubject$.next(chatTimeLineItemComponent);
  }

  private groupMessagesWithImages(messages: ChatTimeLineItem[]): ChatTimeLineItem[] {
    const result: ChatTimeLineItem[] = [];
    let imageGroup: ChatTimeLineItem[] = [];

    const pushImageGroupIfNecessary = () => {
      if (imageGroup.length > 1) {
        result.push(this.createImageGroup(imageGroup));
      } else if (imageGroup.length === 1) {
        result.push(imageGroup[0]); // если это не последовательность изображений, а только одно изображение, добавляем его как отдельное сообщение
      }
      imageGroup = [];
    };

    for (const item of messages) {
      if (item.data.type === WhatsappMessageTypeList.Image && item.data.isFromMe && !item.data.text) {
        imageGroup.push(item);
      } else {
        pushImageGroupIfNecessary();
        result.push(item);
      }
    }

    pushImageGroupIfNecessary();

    return result;
  }

  private createImageGroup(imageGroup: ChatTimeLineItem[]): ChatTimeLineItem {
    return {
      type: ChatTimelineItemTypeEnum.message,
      data: {
        type: 'imageGroup',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        images: imageGroup.map((item: ChatTimeLineItem) => item.data),
        isFromMe: true,
      },
      sortDate: imageGroup[0].sortDate,
    };
  }
}
