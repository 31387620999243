import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AmplitudeTrackService } from '../../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../../core/services/amplitude/amplitudeEventData';
import { DEAL_WAIT_ANSWER } from '../../../../../../../core/services/amplitude/amplitudeEvents';
import { DealListService } from '../../../../deals-list/services/deal-list.service';

enum DealConversationStatus {
  undefined = 0,
  newMessage,
  waitingForAnswer,
}

@Component({
  selector: 'app-wait-answer',
  templateUrl: './wait-answer.component.html',
  styleUrls: ['./wait-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitAnswerComponent implements OnChanges, OnDestroy {
  @Input() dealId: number;
  @Input() conversationStatus: number;
  @Output() waitAnswerTouched = new EventEmitter();
  public isClicked: boolean;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_LISTING;
  private destroy$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private dealListService: DealListService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conversationStatus && changes.conversationStatus.currentValue) {
      this.conversationStatus = changes.conversationStatus.currentValue;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  waitAnswer() {
    if (this.conversationStatus === DealConversationStatus.waitingForAnswer) {
      return;
    }
    // TODO: Delete event
    this.amplitudeTrackService.trackEvent(DEAL_WAIT_ANSWER, { screenType: this.screenType });
    this.isClicked = true;
    this.cdRef.detectChanges();
    this.dealListService
      .updateLastTouchDateRequest(this.dealId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.waitAnswerTouched.emit();
        this.isClicked = false;
        this.cdRef.detectChanges();
      });
  }
}
