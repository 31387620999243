import { DatePipe } from '@angular/common';
import {
  ChatSendMessageRequest,
  PhoneItem,
  WhatsappMessageStatusList,
  WhatsappMessageTypeList,
  WhatsappNewMessage,
} from '@api-clients/crm-api-client';
import { Injectable } from '@angular/core';
import { ChatMessagesFacade } from '../../../+state/chat-messages/chat-messages.facade';
import { generatePendingMessageId } from '../../../+state/chat-messages/chat-messages.helper';
import { UpdateChatMessagesPayload } from '../../../+state/chat-messages/chat-messages.interface';
import {
  ChatTimeLineItem,
  ChatTimelineItemTypeEnum,
} from '../../../modules/chat-timeline/interfaces/chat-timeline.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(
    private chatMessagesFacade: ChatMessagesFacade,
    private readonly datePipe: DatePipe,
  ) {}

  getPendingMessageType(file: Blob): WhatsappMessageTypeList | null {
    if (file) {
      if (file.type.toString().slice(0, 5) === 'image') {
        return WhatsappMessageTypeList.Image;
      }
      if (file.type.toString().slice(0, 5) === 'video') {
        return WhatsappMessageTypeList.Video;
      }
      if (file.type.toString().slice(0, 5) === 'audio') {
        return WhatsappMessageTypeList.Audio;
      }

      return WhatsappMessageTypeList.Document;
    }
    return null;
  }

  public generateContactId(phoneOrChatId: string): string {
    // Если есть собака, то возвращаем как есть
    if (`${phoneOrChatId}`.includes('@')) {
      return phoneOrChatId;
    }
    return `${phoneOrChatId}@c.us`.replace('+', '');
  }

  public isGroupChatId(chatId: string | null): boolean {
    return chatId ? chatId.includes('@g.us') : false;
  }

  private getFileNameFromSendRequest(messageSendRequest: Partial<ChatSendMessageRequest>): string {
    let fileName: string = messageSendRequest?.fileContent?.name || '';
    if (!fileName && messageSendRequest.fileUrl) {
      fileName = messageSendRequest.fileUrl.split('/').pop();
    }
    return fileName || '';
  }

  private generatePendingMessageIdByRequest(messageSendRequest: Partial<ChatSendMessageRequest>) {
    return generatePendingMessageId(
      messageSendRequest.text || this.getFileNameFromSendRequest(messageSendRequest) || '',
    );
  }

  public showPendingMessage(
    messageSendRequest: Partial<ChatSendMessageRequest>,
    phoneItem: PhoneItem | null,
    chatId: string | null = null,
  ): WhatsappNewMessage {
    const messageType = this.getPendingMessageType(messageSendRequest.fileContent as Blob);
    const currentDate = new Date().toISOString().replace('T', ' ').slice(0, 19);
    const pendingId = this.generatePendingMessageIdByRequest(messageSendRequest);

    const pendingMessage: WhatsappNewMessage = {
      message: {
        id: pendingId,
        instanceId: -1,
        quotedMessageId: null,
        userId: null,
        chatId: this.generateContactId(messageSendRequest.phoneOrChatId),
        contactId: '',
        isFromMe: true,
        text: messageSendRequest.text || 'Сообщение отправляется...',
        status: WhatsappMessageStatusList.Unknown,
        type: messageType || WhatsappMessageTypeList.Chat,
        media: null,
        receivedAt: currentDate,
        createdAt: currentDate,
      },
      contact: {
        id: chatId || 'pending', // todo: для групп не прокидывается, как минимум не проверял
        name: 'pending',
        phone: phoneItem,
        isGroup: this.isGroupChatId(chatId),
      },
    };

    const newMessages: UpdateChatMessagesPayload = {
      messages: [pendingMessage],
    };
    this.chatMessagesFacade.showPendingMessage(newMessages);
    return pendingMessage;
  }

  public deletePendingMessage(pendingMessage: WhatsappNewMessage) {
    this.chatMessagesFacade.deletePendingMessage({
      messages: [pendingMessage],
    });
  }

  public createChatTimelineItemByText(text: string): ChatTimeLineItem {
    return {
      type: ChatTimelineItemTypeEnum.message,
      data: {
        text,
      },
      sortDate: this.datePipe.transform(new Date(), 'YYYY-MM-dd hh:mm:ss'),
    };
  }
}
