import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Tour } from '@api-clients/crm-api-client';
import { TranslateService } from '@ngx-translate/core';

const TRANSLATION_KEYS = {
  from: 'COMMON.PREPOSITIONS.FROM',
  to: 'COMMON.PREPOSITIONS.TO',
  daysShortened: 'COMMON.DAYS.SHORTENED',
  nightsShortened: 'COMMON.NIGHTS.SHORTENED',
  adultsShortened: 'COMMON.TOURISTS.ADULTS.SHORTENED',
  childrenShortened: 'COMMON.TOURISTS.CHILDREN.SHORTENED',
  meals: 'PAGES.DEALS.CLIENT_CARD.SEARCH_REQUEST.MEALS',
};

interface TranslatedValues {
  'COMMON.PREPOSITIONS.FROM': string;
  'COMMON.PREPOSITIONS.TO': string;
  'COMMON.DAYS.SHORTENED': string;
  'COMMON.NIGHTS.SHORTENED': string;
  'COMMON.TOURISTS.ADULTS.SHORTENED': string;
  'COMMON.TOURISTS.CHILDREN.SHORTENED': string;
  'PAGES.DEALS.CLIENT_CARD.SEARCH_REQUEST.MEALS': string;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  private dateFormatLocale = 'ru-RU';
  public dateFormat = 'd.MM';
  public translatedValues: TranslatedValues;
  constructor(private translateService: TranslateService) {
    this.translateService
      .get([
        TRANSLATION_KEYS.from,
        TRANSLATION_KEYS.to,
        TRANSLATION_KEYS.daysShortened,
        TRANSLATION_KEYS.nightsShortened,
        TRANSLATION_KEYS.adultsShortened,
        TRANSLATION_KEYS.childrenShortened,
        TRANSLATION_KEYS.meals,
      ])
      .subscribe((res: TranslatedValues) => {
        this.translatedValues = res;
      });
  }
  public getDestination(tour: Tour): string {
    if (!tour?.country || !tour?.region) {
      return null;
    }
    return `${tour.country.name}, ${tour.region.name}`;
  }
  public getDates(from: string, to: string): string {
    const dateFrom = formatDate(from, this.dateFormat, this.dateFormatLocale);
    const dateTo = formatDate(to, this.dateFormat, this.dateFormatLocale);
    return from !== to ? `${dateFrom}-${dateTo}` : dateFrom;
  }

  public getNights(tour: Tour): string {
    let nightsTextPostfix = '';

    if (this.translatedValues) {
      nightsTextPostfix = `${this.translatedValues?.[TRANSLATION_KEYS.nightsShortened]}` || '';
    }

    return `(${tour.nights} ${nightsTextPostfix})`;
  }
  public getRoom(tour: Tour): string {
    return this.sliceName(`${tour?.room?.name}, ${tour?.roomType?.name}`, 15);
  }
  public sliceName(longText: string, length: number): string {
    let shortText = longText.slice(0, length);
    if (!!longText[length - 1] && longText[length - 1] !== ' ') {
      shortText = `${shortText}...`;
    }
    return shortText;
  }
}
