import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { ClientCardInfoComponent } from './client-card-info.component';
import { ClientAvatarComponent } from './client-avatar/client-avatar.component';
import { WaitAnswerComponent } from './wait-answer/wait-answer.component';
import { ClientCardNameModule } from '../client-card-name/client-card-name.module';
import { SharedModule } from '../../../../../../shared/shared.module';
import { ActionsPopupMenuModule } from '../../components/actions-popup-menu/actions-popup-menu.module';
import { OnlineBadgeComponent } from './online-badge/online-badge.component';

@NgModule({
  declarations: [ClientCardInfoComponent, ClientCardInfoComponent, WaitAnswerComponent, OnlineBadgeComponent],
  imports: [
    CommonModule,
    ClientCardNameModule,
    SharedModule,
    NgxMaskModule,
    ActionsPopupMenuModule,
    ClientAvatarComponent,
  ],
  exports: [ClientCardInfoComponent],
})
export class ClientCardInfoModule {}
