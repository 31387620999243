<div class="sms">
  <img src="assets/icons/timeline/sms.svg"
       alt="sms"
       class="icon">
  <div class="sms-content">
    <span class="header-title">
      {{'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.SMS' | translate}}
      <ng-container *ngIf="smsAuthor">
       {{'COMMON.PREPOSITIONS.OFF' | translate}}
        {{smsAuthor}}
      </ng-container>

    </span>
    <div class="text">
      {{smsText}}
    </div>
  </div>
</div>
