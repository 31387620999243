import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SortType } from '../../modules/deals/modules/deals-list/deal-list';
import { DealsState } from './deals.interface';

const getDealsState = createFeatureSelector<DealsState>('deals');

const getLoaded = createSelector(getDealsState, (state: DealsState) => state.loaded);
const getLoadedPostSale = createSelector(getDealsState, (state: DealsState) => state.loadedPostSale);

const getPostSaleDropdownIsOpen = createSelector(
  getDealsState,
  (state: DealsState) => state.postSaleDropdownIsOpen,
);

const getSortType = createSelector(getDealsState, (state: DealsState): SortType => state.sortType);

const getError = createSelector(getDealsState, (state: DealsState) => state.error);

const getAllDeals = createSelector(getDealsState, getLoaded, (state: DealsState, isLoaded) => {
  return isLoaded ? state.list : [];
});

const getAllPostSaleDeals = createSelector(
  getDealsState,
  getLoadedPostSale,
  (state: DealsState, isLoaded) => {
    return isLoaded ? state.listPostSale : [];
  },
);

const getAsideChatList = createSelector(getDealsState, (state: DealsState) => state.asideChatList);

const getZenmodeList = createSelector(getDealsState, (state: DealsState) => state.zenmodeList);

const getZenmodeOn = createSelector(getDealsState, (state: DealsState) => state.isZenmodeOn);

const getSelectedZenmodeCard = createSelector(
  getDealsState,
  (state: DealsState) => state.selectedByZenmodeCard,
);

const getShadowNextTasksMap = createSelector(getDealsState, (state: DealsState) => state.shadowNextTasksMap);

const getViewedCards = createSelector(getDealsState, (state: DealsState) => state.viewedCards);

const getSkippedCards = createSelector(getDealsState, (state: DealsState) => state.skippedCards);

const getBackwardCount = createSelector(getDealsState, (state: DealsState) => state.backtrackedCards.length);

const getBacktrackedCards = createSelector(getDealsState, (state: DealsState) => state.backtrackedCards);

const getZenmodeHistory = createSelector(getDealsState, (state: DealsState) => state.zenmodeHistory);

const getUntouchedCards = createSelector(getDealsState, (state: DealsState) => state.untouchedCards);

export const dealsQuery = {
  getLoaded,
  getSortType,
  getError,
  getPostSaleDropdownIsOpen,
  getAllDeals,
  getAllPostSaleDeals,
  getAsideChatList,
  getZenmodeList,
  getZenmodeOn,
  getSelectedZenmodeCard,
  getShadowNextTasksMap,
  getViewedCards,
  getSkippedCards,
  getBackwardCount,
  getBacktrackedCards,
  getZenmodeHistory,
  getUntouchedCards,
};
