import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CrmTaskCreateNextRequest, CrmTaskItem, CrmTaskType } from '@api-clients/crm-api-client';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CrmTaskIntent } from '@api-clients/crm-api-client/dist/models';
import { CurrentStageEnum, Stage } from '../../../../sales-funnel-stages/sales-funnel-stages';
import { CreateTaskService } from '../../../services/create-task.service';
import { DateTimeValue } from '../../../../datetimepicker/datetimepicker.component';
import {
  getAPIFormattedDate,
  getFormattedDate,
  getFormattedTime,
  getFormattedTimeWithSec,
} from '../../../../../../../../../helpers/time';
import { ActionsPopupHelperService } from '../../../../../components/actions-popup-menu/services/actions-popup-helper.service';
import { CrmTaskApiService } from '../../../../../services/crm-task-api.service';
import { WorkerStateService } from '../../../../../../../../../core/services';
import {
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../../shared/notify/services/notify.service';
import {
  CreateTaskModalCheckedStage,
  CreateTaskModalCheckedTaskType,
  StepsId,
} from '../../create-task-modal';
import { ScreenTypes } from '../../../../../../../../../core/services/amplitude/amplitudeEventData';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-next-stage-step',
  templateUrl: './next-stage-step.component.html',
  styleUrls: ['./next-stage-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextStageStepComponent implements OnInit, OnDestroy {
  @Input() task: CrmTaskItem;
  @Input() dealId: number;
  @Input() crmCardId: number;
  @Input() phone: string;
  @Input() dealStage: string;
  @Input() isAfterCall: boolean;
  @Input() isNewClient: boolean;
  @Input() checkedStage?: CreateTaskModalCheckedStage;
  @Input() checkedTaskType?: CreateTaskModalCheckedTaskType;
  @Input() place?: ScreenTypes;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  public currentStage;
  public sendInstructionsStage = CurrentStageEnum.readyForBooking;
  public stages: Stage[] = [
    CurrentStageEnum.sendOffer,
    CurrentStageEnum.feedback,
    CurrentStageEnum.meeting,
    CurrentStageEnum.finishingTouch,
    CurrentStageEnum.readyForBooking,
  ];
  public crmTaskTypes: CrmTaskType[];
  public form: UntypedFormGroup;
  public datetimePopup: boolean;
  public assignedManagerId: number;
  public meetingDateTime: Date;
  public TRANSLATE_PATH = 'PAGES.DEALS.SALES_FUNNEL.CREATE_TASK_MODAL.NEXT_STAGE.';
  public meetingDateControl: UntypedFormControl;
  public meetingTimeControl: UntypedFormControl;
  public isLoaded = false;
  public screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public readonly isAfterCallId = StepsId.IS_AFTER_CALL;
  private destroy$ = new Subject<void>();

  constructor(
    private createTaskService: CreateTaskService,
    private formBuilder: UntypedFormBuilder,
    private actionsPopupHelperService: ActionsPopupHelperService,
    private taskCreateService: CrmTaskApiService,
    private workerStateService: WorkerStateService,
    private notifyService: NotifyService,
  ) {
    this.assignedManagerId = this.workerStateService.currentWorkerValue?.id;
  }

  ngOnInit(): void {
    this.currentStage = this.dealStage;

    this.initForm();

    if (this.checkedStage && this.checkedStage.stage) {
      this.form.patchValue({
        dealStage: this.checkedStage.stage,
      });
    }

    if (this.checkedTaskType && this.checkedTaskType.taskType) {
      this.form.patchValue({
        type: this.checkedTaskType.taskType,
      });
    }

    this.initDateTime();
    this.crmTaskTypes = [CrmTaskType.Calling, CrmTaskType.Whatsapp, CrmTaskType.Meeting];
    this.isLoaded = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      dealId: [this.dealId, [Validators.required]],
      crmCardId: [this.crmCardId, [Validators.required]],
      dealStage: ['', [Validators.required]],
      sendBookingInstruction: [false],
      type: ['', [Validators.required]],
      meetingDate: ['', [Validators.required]],
      meetingTime: ['', [Validators.required]],
      managerComment: [this.task?.managerComment || ''],
      assignedManagerId: [this.assignedManagerId, [Validators.required]],
      intent: [CrmTaskIntent.ChangeDate],
      phone: [this.phone, [Validators.required]],
    });

    this.meetingDateControl = this.form?.get('meetingDate') as UntypedFormControl;
    this.meetingTimeControl = this.form?.get('meetingTime') as UntypedFormControl;
  }

  setMeetingDateTime($event) {
    this.meetingDateTime = $event.date;
    this.form.patchValue({
      meetingDate: $event.dateValue,
      meetingTime: $event.timeValue,
    });
  }

  openDatetimepickerPopup() {
    this.datetimePopup = true;
  }

  closeDatetimepickerPopup($event: any) {
    $event.stopPropagation();
    this.datetimePopup = false;
  }

  initDateTime() {
    const DATE_TIME_VALUE: DateTimeValue = {
      date: new Date(),
      dateValue: getFormattedDate(new Date()),
      timeValue: getFormattedTime(new Date()),
      duration: this.actionsPopupHelperService.getDuration(this.dealStage),
    };
    this.meetingDateTime = DATE_TIME_VALUE.date;

    this.form.patchValue({
      meetingDate: DATE_TIME_VALUE.dateValue,
      meetingTime: DATE_TIME_VALUE.timeValue,
    });
  }

  getStageColor(stage: string | CurrentStageEnum) {
    switch (stage) {
      case CurrentStageEnum.meeting:
        return '#9BD029';
      case CurrentStageEnum.sendOffer:
        return '#ED6980';
      case CurrentStageEnum.feedback:
        return '#FC8800';
      case CurrentStageEnum.finishingTouch:
        return '#C85DC8';
      case CurrentStageEnum.readyForBooking:
        return '#FF6347';
      default:
        return '#9BD029';
    }
  }

  goPrev(): void {
    this.createTaskService.goBack();
  }

  submitForm(): void {
    if (this.form.valid) {
      const place = this.place || this.screenType;
      const formattedPlace = this.formatCreateTaskPlace(place);
      const payload: CrmTaskCreateNextRequest = {
        ...this.form.value,
        meetingDate: getAPIFormattedDate(this.meetingDateTime),
        meetingTime: getFormattedTimeWithSec(this.meetingDateTime),
        place: formattedPlace,
      };

      this.taskCreateService
        .crmTaskCreateNext(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.createTaskService.goResult();
          },
          error: event => {
            for (const error of event?.errors) {
              this.notifyService.create({
                title: 'Ошибка',
                text: error.errors.join(', '),
                type: NotifyTypeEnum.error,
              });
            }
            this.closeModal.emit();
          },
        });
    }
  }

  private formatCreateTaskPlace(place: ScreenTypes): string {
    return `hermes_${place}`;
  }

  setTaskType(type: CrmTaskType) {
    if (!this.form.controls['dealStage'].value) {
      return;
    }
    this.form.patchValue({
      type,
    });
  }

  getIsDisabledTypes() {
    return !this.form?.controls['dealStage']?.value;
  }

  getIsDisabledForm() {
    return this.form?.invalid || this.getIsDisabledTypes();
  }
}
