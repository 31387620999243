<div class="complaint-completion top-border-line">
  <h5 class="title">Завершение работы с “Приветом”</h5>
  <form *ngIf="form" [formGroup]="form">
    <label for="completion">
      <textarea
        formControlName="comment"
        [placeholder]="placeholder"
        class="form-control form-textarea"
        id="completion"
      ></textarea>
    </label>
    <div class="controls">
      <div class="form-group form-checkbox">
        <app-ui-checkbox formControlName="completed" [label]="complaintCompletedLabel"></app-ui-checkbox>
      </div>
      <div><img src="./assets/icons/complaints/attachment.svg" alt="" /> <span>Прикрепить</span></div>
      <button class="btn --green">{{ 'PAGES.COMPLAINTS.SAVE' | translate }}</button>
    </div>
  </form>
</div>
