import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ElectronService } from '../../../../core/services';
import { UpdateProgressEvent } from '../../../../models/ipcEvent';

@Component({
  selector: 'app-update-window',
  templateUrl: './update-window.component.html',
  styleUrls: ['./update-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateWindowComponent implements OnInit {
  constructor(private electronService: ElectronService, private cdRef: ChangeDetectorRef) {}
  public messages = [];
  public currentProgress: UpdateProgressEvent = null;
  public isDownloadingStarted = false;

  ngOnInit(): void {
    this.electronService.ipcRenderer.on('update-message', (event, text) => {
      console.log('update-message (renderer)', event, text);
      this.messages.push(text);
      this.cdRef.detectChanges();
    });

    this.electronService.ipcRenderer.on('update-available', () => {
      this.isDownloadingStarted = true;
      this.cdRef.detectChanges();
    });

    this.electronService.ipcRenderer.on('download-progress', (event, progress: UpdateProgressEvent) => {
      this.currentProgress = progress;
      this.isDownloadingStarted = false;
      this.cdRef.detectChanges();
    });
  }

  checkForUpdates() {
    this.electronService.ipcRenderer.send('checkForUpdates');
  }
}
