import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { HotOffersToursSortVariant } from '../../../../hot-offers.model';
import { HotOffersService } from '../../../../hot-offers.service';
import { HotOffersSortPipe } from './hot-offers-sorts.pipe';

@Component({
  selector: 'app-hot-offers-sorts',
  templateUrl: './hot-offers-sorts.component.html',
  styleUrls: ['./hot-offers-sorts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    HotOffersSortPipe,
    NgClickOutsideDirective
],
})
export class HotOffersSortsComponent {
  activeSort$ = this.hotOffersService.activeSort$;
  showVariants = false;

  readonly toursSortVariant = HotOffersToursSortVariant;

  constructor(
    private readonly hotOffersService: HotOffersService,
  ) {
  }

  sortTours(newSort: HotOffersToursSortVariant): void {
    this.hotOffersService.changeSort(newSort);
  }

  toggleVariants(): void {
    this.showVariants = !this.showVariants;
  }

  closeVariants(): void {
    if (!this.showVariants) {
      return;
    }

    this.showVariants = false;
  }
}
