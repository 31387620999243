import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HotOffersApplyFilterEvent, HotOffersFilterType, HotOffersFilterValue } from '../../../../hot-offers.model';
import { HotOffersService } from '../../../../hot-offers.service';

@Component({
  selector: 'app-hot-offers-filter',
  templateUrl: './hot-offers-filter.component.html',
  styleUrls: ['./hot-offers-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe
],
})
export class HotOffersFilterComponent {
  @Input() departCityId!: number;
  @Input() name!: string;
  @Input() type!: HotOffersFilterType;
  @Input() showCounter = false;
  @Input() values$!: Observable<HotOffersFilterValue[]>;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly hotOffersService: HotOffersService,
  ) {
  }

  selectFilter(filterValue: HotOffersFilterValue) {
    if (filterValue.count === 0) {
      return;
    }

    const emitEvent: HotOffersApplyFilterEvent = {
      eventType: 'HotOffersApplyFilterEvent',
      type: this.type,
      departCityId: this.departCityId,
      isChecked: !filterValue.isChecked,
      value: filterValue.value,
    };
    this.hotOffersService.applyFilter(emitEvent);
    this.cdRef.detectChanges();
  }
}
