<div class="deal-view">
      <div
        #dealItemRef class="info-card"
        [ngClass]="{
        'is-whatsapp-client-card':isWhatsappClientCard,
        '--new': dealInfo.isNew,
        'fade-out': fadeOutAnimation}">

        <app-client-card-info


          [card]="card"
          [crmId]="crmId"
                              [dealInfo]="dealInfo"
                              [crmCardViewItem]="crmCardListItem || null"
                              (fadeOutAnimation)="fadeOut()"
                              [isModal]="isModal"
        ></app-client-card-info>
        <ng-container *ngIf="isShowCardInfo">
          <div class="info-card-content">
            <div *ngIf="nextTask; else nextTaskEmpty" class="next-actions actions info-card-content-item">
              <h5 class="next-actions-title info-card-content-title">
                {{ 'PAGES.DEALS.CLIENT_CARD.NEXT_ACTIONS' | translate }}
              </h5>
              <div class="next-actions-wrap">
                <div class="next-action-text actions-text">
                  <div class="next-action-label {{nextTask.type}}" *ngIf="nextTaskTypes[nextTask.type] as taskType" >
                    <img src="/assets/icons/{{nextTask.type}}-icon.svg"  alt="icon">
                    {{ taskType }}</div>
                </div>
                <div [ngClass]='dateColor' class="next-action-date actions-date">
                  <img
                    src="assets/icons/stopwatch{{dateColor}}.svg" alt="stopwatch">
                  <ng-container *ngIf="nextTask.date">
                    {{ nextTask.date | date: 'd MMMM' | lowercase }}
                  </ng-container>
                  <ng-container *ngIf="nextTask.date && nextTask.time">
                    {{ nextTaskDate | date: 'HH:mm' }}
                  </ng-container>

                  <app-last-action-date-time-helper-popup
                    class="helper-popup"
                    [date]="nextTask.date"
                    [time]="nextTask.time"
                  >

                  </app-last-action-date-time-helper-popup>
                </div>
              </div>
              <div [ngClass]="{'show': showMore}" class="comment-row"
                   *ngIf="nextTask?.managerComment as comment"
              >
                <p class="comment">{{ 'PAGES.DEALS.CLIENT_CARD.COMMENT' | translate }}:</p>
                <p class="comment-content" #nextActionRef>
                  {{comment}}
                </p>
              </div>
              <a #showMoreToggle *ngIf="nextActionHideToggle && nextTask?.managerComment"
                 (click)="showMore = !showMore"
                 class="show-more">
                <span class="text-span" *ngIf="showMore">{{ 'COMMON.HIDE' | translate }}</span>
                <span class="text-span" *ngIf="!showMore">{{ 'COMMON.SHOW_MORE' | translate }}</span>
              </a>

            </div>
            <ng-template #nextTaskEmpty>
              <div class="next-actions actions info-card-content-item">
                <h5 class="next-actions-title info-card-content-title">
                  {{ 'PAGES.DEALS.CLIENT_CARD.NEXT_ACTIONS' | translate }}
                </h5>
                <div class="next-action-text actions-text">
                  <p>
                    Не заполнено
                  </p>
                </div>
              </div>
            </ng-template>
            <app-deals-item-search-options [searchOption]="searchOption" class="info-card-content-item"></app-deals-item-search-options>
            <app-deals-item-questionnaire
              [lastTags]="lastTags"
              [lastBudget]="lastBudget"
              [questionnaire]="questionnaire"
              [fullHeightMode]="true"
              [info]="card.info"
              class="info-card-content-item"></app-deals-item-questionnaire>
            <div *ngIf="lastAction; else lastActionEmpty" class="last-actions actions info-card-content-item">
              <h5 class="last-actions-title info-card-content-title">
                {{ 'PAGES.DEALS.CLIENT_CARD.LAST_ACTIONS' | translate }}
              </h5>
              <div>
                <ng-container [ngSwitch]="lastAction?.actionType" class="last-action-text actions-text">
                  <app-last-action-call
                    *ngSwitchCase="'call'"
                    [lastActionItem]="lastAction"
                    [actionsDateFormat]="actionsDateFormat"
                  ></app-last-action-call>
                  <app-last-action-sms
                    *ngSwitchCase="'sms'"
                    [lastActionItem]="lastAction"
                    [actionsDateFormat]="actionsDateFormat"
                  ></app-last-action-sms>
                  <app-last-action-offer
                    *ngSwitchCase="'offer'"
                    [lastActionItem]="lastAction"
                    [actionsDateFormat]="actionsDateFormat"
                  ></app-last-action-offer>
                  <app-last-action-whatsapp-message
                    *ngSwitchCase="'whatsappMessage'"
                    [lastActionItem]="lastAction"
                    [actionsDateFormat]="actionsDateFormat"
                  ></app-last-action-whatsapp-message>
                </ng-container>
              </div>

            </div>
            <ng-template #lastActionEmpty>
              <div class="last-actions actions info-card-content-item">
                <h5 class="last-actions-title info-card-content-title">
                  {{ 'PAGES.DEALS.CLIENT_CARD.LAST_ACTIONS' | translate }}
                </h5>
                <div class="last-action-text actions-text">
                  <ng-container> Не заполнено</ng-container>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
  </div>
