<div class="reply-preview">
  <div class="reply-preview__body">
    <div class="reply-preview__container">
      <div class="reply-preview__inner">
        <div class="reply-preview__left-bar-icon"></div>
      </div>
      <ng-container [ngSwitch]="message.type">
        <ng-container *ngSwitchCase="messageTypes.Chat">
          <div class="reply-preview__content">
            <div class="reply-preview__author">{{ getMessageAuthor() }}</div>
            <span class="reply-preview__message">{{ messageTextFirstLine }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="messageTypes.Image">
          <div class="reply-preview__content">
            <div class="reply-preview__author">{{ getMessageAuthor() }}</div>
            <div class="reply-preview__inner">
              <img src="assets/icons/photo.svg" alt="photo icon" class="reply-preview__icon" />
              <span *ngIf="message.text; else basicImageText" class="reply-preview__message">
                {{ messageTextFirstLine }}
              </span>
              <ng-template #basicImageText>
                <span class="reply-preview__message">Фото</span>
              </ng-template>
            </div>
          </div>
          <div class="reply-preview__media-content">
            <img [src]="message.media.contentLink" alt="image" class="reply-preview__image" />
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="messageTypes.Video">
          <div class="reply-preview__content">
            <div class="reply-preview__author">{{ getMessageAuthor() }}</div>
            <div class="reply-preview__inner">
              <img src="assets/icons/videocam.svg" alt="video cam icon" class="reply-preview__icon" />
              <span *ngIf="message.text; else basicVideoText" class="reply-preview__message">
                {{ messageTextFirstLine }}
              </span>
              <ng-template #basicVideoText>
                <span class="reply-preview__message">Видео</span>
              </ng-template>
            </div>
          </div>
          <div class="reply-preview__media-content">
            <video
              [src]="message.media.contentLink"
              alt="video"
              class="reply-preview__video"
              muted
              preload="metadata"
              [autoplay]="false"
              [controls]="false"
            ></video>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="messageTypes.Ptt">
          <div class="reply-preview__content">
            <div class="reply-preview__author">{{ getMessageAuthor() }}</div>
            <div class="reply-preview__inner">
              <img src="assets/icons/mic.svg" alt="mic icon" class="reply-preview__icon" />
              <span
                *ngIf="message.media.duration as duration; else basicPttText"
                class="reply-preview__message"
              >
                {{ duration | secondsToMinutesSeconds }}
              </span>
              <ng-template #basicPttText>
                <span class="reply-preview__message">Голосовое сообщение</span>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </ng-container>
    </div>
    <button class="reply-preview__close-btn" (click)="clearCurrentReplyMessage()">
      <img src="assets/icons/whatsapp/close.svg" alt="close icon" />
    </button>
  </div>
</div>
