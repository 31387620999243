import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ElectronService } from '../../../core/services';

interface LastClient {
  lastMessageId: string;
  isClosed: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AlertOnTopService {
  constructor(
    private http: HttpClient,
    private electronService: ElectronService,
  ) {
  }

  private lastClientSubject = new BehaviorSubject<LastClient | null>(null);

  public get lastClient$(): Observable<LastClient | null> {
    return this.lastClientSubject.asObservable();
  }

  public setLastClient(lastMessageId: string) {
    const lastClient: LastClient = {
      lastMessageId,
      isClosed: false,
    };
    this.lastClientSubject.next(lastClient);
  }

  public closeInteractForLastClient(lastMessageId: string) {
    const lastClient: LastClient = {
      lastMessageId,
      isClosed: true,
    };
    this.lastClientSubject.next(lastClient);
  }

  openWhatsappIntegration(url: string): boolean | void {
    this.electronService.shell
      .openExternal(url, {
        activate: true,
      })
      .then();
  }
}
