import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ui-checkbox',
  templateUrl: './ui-checkbox.component.html',
  styleUrls: ['./ui-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => UiCheckboxComponent) },
  ],
})
export class UiCheckboxComponent implements ControlValueAccessor {
  public selectedValue: 1 | 0;
  @Input() label: string;
  private current_value = false;
  @Output() onChecked: EventEmitter<boolean> = new EventEmitter<boolean>(this.current_value);
  private onChange: any;
  private onTouch: any;

  onStateChange(value): void {
    this.current_value = !this.current_value;
    this.onChecked.emit(this.current_value);
    if (this.onChange) {
      this.onChange(+value.checked);
    }
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


}
