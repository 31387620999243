<div class="result-step">
  <div class="done">
    <img src="assets/icons/deal-view/done.svg" alt="done" class="done-icon">

    <h2 *ngIf="previousStep$ | async as prev">
      {{TRANSLATE_PATH+prev.id+isAfterCallId | translate}}
    </h2>
  </div>

  <div class="close-btn">
    <button class="btn secondary emphasis" type="button" name="button"
            (click)="close()"
    >{{'COMMON.CLOSE' | translate}}</button>
  </div>
</div>

