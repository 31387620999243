<div class="modal-wrapper" *ngIf="currentStep$ | async as currentStep">
  <div class="modal">
    <div class="close-icon">
      <img
        [hidden]="isAfterCall"
        src="assets/icons/deal-view/close-black.svg"
        alt="close"
        (click)="closeModal()"
      />
    </div>
    <div class="modal-body">
      <app-choose-action-step
        *ngIf="currentStep.id === stepsId.CHOOSE_ACTION"
        [isAfterCall]="isAfterCall"
        [isNewClient]="isNewClient"
      ></app-choose-action-step>
      <app-next-stage-step
        *ngIf="currentStep.id === stepsId.NEXT_STAGE"
        [task]="task"
        [place]="place"
        [dealStage]="dealStage"
        [dealId]="dealId"
        [phone]="phone"
        [crmCardId]="crmCardId"
        [isAfterCall]="isAfterCall"
        [isNewClient]="isNewClient"
        [checkedStage]="checkedStage$ | async"
        [checkedTaskType]="checkedTaskType$ | async"
        (closeModal)="closeModal()"
      ></app-next-stage-step>

      <app-reassign-step
        *ngIf="currentStep.id === stepsId.REASSIGN"
        [task]="task"
        [dealStage]="dealStage"
        [dealId]="dealId"
        [phone]="phone"
        [crmCardId]="crmCardId"
        (closeModal)="closeModal()"
      >
      </app-reassign-step>

      <app-complete-deal-step
        *ngIf="currentStep.id === stepsId.COMPLETE"
        [dealId]="dealId"
        [dealStatus]="dealStatus"
        [task]="task"
        [dealStage]="dealStage"
        [phone]="phone"
        [crmCardId]="crmCardId"
        (closeModal)="closeModal()"
        [completeDealType]="completeType$ | async"
      >
      </app-complete-deal-step>

      <app-result-step
        *ngIf="currentStep.id === stepsId.RESULT"
        [dealId]="dealId"
        [crmCardId]="crmCardId"
        [isAfterCall]="isAfterCall"
        (closeModal)="closeModal()"
      >
      </app-result-step>

      <app-recall-step
        *ngIf="currentStep.id === stepsId.RECALL"
        [task]="task"
        [dealStage]="dealStage"
        [dealId]="dealId"
        [phone]="phone"
        [crmCardId]="crmCardId"
        [isPostSale]="dealStatus === POST_SALE_STATUS"
        (closeModal)="closeModal()"
      >
      </app-recall-step>
    </div>
  </div>
  <div class="modal-footer">
    <app-progress-bar [step]="currentStep.step"></app-progress-bar>
  </div>
</div>
