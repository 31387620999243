import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IndicatorData, IndicatorMessagePartTypeEnum } from '../../models/indicator-payload.model';
import { IndicatorEventService } from '../../services/indicator-event.service';

@Component({
  selector: 'app-deal-view-indicator',
  templateUrl: './deal-view-indicator.component.html',
  styleUrls: ['./deal-view-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideUpDown', [
      state(
        'in',
        style({
          transform: 'translateY(0)',
        }),
      ),
      state(
        'out',
        style({
          transform: 'translateY(-100%)',
          visibility: 'hidden',
        }),
      ),
      transition('out => in', animate('300ms ease-in')),
      transition('in => out', animate('300ms ease-out')),
    ]),
  ],
})
export class DealViewIndicatorComponent implements OnInit, OnChanges {
  @Input() indicatorData: IndicatorData;
  typeEnum = IndicatorMessagePartTypeEnum;

  isVisible = false;

  constructor(private cd: ChangeDetectorRef, private indicatorEventService: IndicatorEventService) {}

  hideIndicator() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  showIndicator() {
    this.hideIndicator();
    setTimeout(() => {
      this.isVisible = true;
      this.indicatorEventService.sendIndicatorEvent(this.indicatorData.type);
      this.cd.detectChanges();
    }, 100);
  }
  ngOnInit(): void {
    this.showIndicator();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.indicatorData && this.indicatorData) {
      this.showIndicator();
    }
  }
}
