import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { SelectedZenmodeCard, ShadowNextTasksMap } from '../../../../+state/deals/deals.interface';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { DealStatusByValue } from '../zenmode-list/zenmode.component';

@Component({
  selector: 'app-zenmode-main-list',
  templateUrl: './zenmode-main-list.component.html',
  styleUrls: ['./zenmode-main-list.component.scss'],
})
export class ZenmodeMainListComponent implements OnInit {
  public zenmodeList$: Observable<CrmCardViewItem[]> = this.dealsFacade.zenmodeList$;
  public selectedZenmodeCard$: Observable<SelectedZenmodeCard> = this.dealsFacade.selectedZenmodeCard$;
  public shadowNextTasksMap$: Observable<ShadowNextTasksMap> = this.dealsFacade.getShadowNextTasksMap$;
  public dateFormat = 'dd.MM.yyyy HH:mm';
  public dealStatus = DealStatusByValue;
  public combinedData$ = combineLatest([this.zenmodeList$, this.shadowNextTasksMap$]).pipe(
    map(([zenList, shadowMap]) => {
      return zenList.map(item => ({
        ...item,
        shadowNextTask: shadowMap[item.deal.id],
      }));
    }),
  );
  public combinedDataWithSelected$ = combineLatest([this.combinedData$, this.selectedZenmodeCard$]).pipe(
    map(([combinedData, selectedCard]) => {
      return combinedData.map(item => ({
        ...item,
        isSelected: item.card.id === selectedCard.cardId,
      }));
    }),
  );

  constructor(private dealsFacade: DealsFacade) {}

  ngOnInit(): void {}
}
