import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrmCardListItemDetailsResponse } from '@api-clients/crm-api-client';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipeTyped } from '../../../../../api-response-typed.pipe';
import { ApiTourWithManager } from '../types/api-tour-with-manager.type';

@Injectable()
export class ManagerOffersListService {
  constructor(private http: HttpClient) {}

  getListItemDetails(dealId: number): Observable<ApiTourWithManager[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('dealId', `${dealId}`);
    return this.http
      .get<ApiResponse<CrmCardListItemDetailsResponse>>(`${AppConfig.apiUrl}/deal/list-item-details`, {
        params: queryParams,
      })
      .pipe(
        apiResponsePipeTyped<CrmCardListItemDetailsResponse>(),
        mergeMap((response: CrmCardListItemDetailsResponse): Observable<ApiTourWithManager[]> => {
          console.log('list item details response: ', response);
          if (response.success && response.managerOffers) {
            // Плоско преобразуем все туры из всех предложений всех менеджеров в один массив
            return of(
              response.managerOffers.flatMap(managerOffer =>
                managerOffer.offers
                  ? managerOffer.offers.flatMap(offer =>
                      offer.tour
                        ? [
                            {
                              ...offer.tour,
                              tourSendDate: offer.tourSendDate ?? '',
                              managerName: managerOffer.managerName ?? '',
                              isViewed: managerOffer.isViewed ?? null,
                              viewCount: managerOffer.viewCount,
                              lastViewDate: managerOffer.lastViewDate,
                            },
                          ]
                        : [],
                    )
                  : [],
              ),
            );
          }
          return of([] as ApiTourWithManager[]); // Возвращаем пустой массив, если нет туров
        }),
        catchError(error => {
          console.error('Error fetching deals list item details:', error);
          return of([] as ApiTourWithManager[]); // Возвращаем пустой массив в случае ошибки
        }),
      );
  }
}
