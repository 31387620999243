import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  input,
  OnDestroy,
  OnInit,
  output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutoFocusDirective } from '../../../../../../../../../../../../shared/directives/auto-focus.directive';
import { NumberFormatDirective } from '../../../../../../../../../../../../shared/directives/number-format.directive';
import { PostFilterPrices } from '../../../../../../favorites-hotels.model';
import { SearchResultFiltersService } from '../../../../services/search-result-filters.service';

@Component({
  selector: 'app-favorite-hotels-map-filter-prices',
  templateUrl: './favorite-hotels-map-filter-prices.component.html',
  styleUrl: './favorite-hotels-map-filter-prices.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AutoFocusDirective, ReactiveFormsModule, NumberFormatDirective, DecimalPipe],
})
export class FavoritesHotelsMapFilterPricesComponent implements OnInit, OnDestroy {
  prices = input<PostFilterPrices>();
  minTourPrice = input<number>();
  maxTourPrice = input<number>();

  closed = output<void>();

  form: FormGroup;
  disabled = true;

  private valueChangesSubscription: Subscription;

  constructor(
    private readonly fb: FormBuilder,
    private readonly mapService: SearchResultFiltersService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      from: new FormControl<number>(this.prices()?.from ? this.prices()?.from : undefined),
      to: new FormControl<number>(this.prices()?.to ? this.prices()?.to : undefined),
    });

    this.valueChangesSubscription = this.form.valueChanges.subscribe((value: PostFilterPrices) => {
      const valueFrom = Number(value.from?.toString()?.replace(/[^0-9,.]/g, ''));
      const valueTo = Number(value.to?.toString()?.replace(/[^0-9,.]/g, ''));

      this.disabled = valueFrom === this.prices()?.from && valueTo === this.prices()?.to;
      this.cdRef.detectChanges();
    });

    if (this.prices()?.from || this.prices()?.to) {
      this.disabled = false;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.valueChangesSubscription?.unsubscribe();
  }

  apply(): void {
    this.mapService.applyPricesFilter(this.prepareValue(this.form.value));
    this.closed.emit();
  }

  reset(): void {
    this.form.reset();
    this.mapService.applyPricesFilter(this.prepareValue(this.form.value));
  }

  private prepareValue(value: any): PostFilterPrices {
    const from = Number(value.from?.toString()?.replace(/[^0-9,.]/g, ''));
    const to = Number(value.to?.toString()?.replace(/[^0-9,.]/g, ''));

    return {
      from: isNaN(from) ? undefined : from,
      to: isNaN(to) ? undefined : to,
    };
  }
}
