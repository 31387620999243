import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompleteComponent } from './components/complete/complete.component';
import { SharedModule } from '../../../../../../shared/shared.module';
import { NonConversionCallComponent } from './components/non-conversion-call/non-conversion-call.component';
import { AutoTouchComponent } from './components/auto-touch/auto-touch.component';

@NgModule({
  declarations: [CompleteComponent, NonConversionCallComponent, AutoTouchComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, NgSelectModule],
  exports: [CompleteComponent, NonConversionCallComponent],
})
export class SalesFunnelCompletePopupModule {}
