<form [formGroup]="form">
  <div class="tourist-container open">
    <button (click)="closeForm()" class="close-button" type="button">
      <img alt="" src="/assets/icons/close-icon.svg" />
    </button>

    <div class="tourist-container-row">
      <app-favorite-hotels-search-form-tourists formControlName="tourists" />

    </div>

    <div class="direction-hotels-container">
      <div class="direction-container">
        <app-favorite-hotels-search-form-depart-cities formControlName="departCityId"
                                                       [departCities]="formParams().departCities" />

        <app-favorite-hotels-search-form-countries *ngIf="countries().length"
                                                   formControlName="direction"
                                                   [countries]="countries()"
                                                   [regions]="regions()" />
      </div>
      <app-favorites-hotels-search-form-templates formControlName="templateId"
                                                  [hotelTemplateList]="hotelTemplateList()" />
    </div>
  </div>
  <div class="params-container shot-ext-params">
    <div class="params-form">
      <div class="main-params">
        <app-favorite-hotels-search-form-calendar formControlName="dates"
                                                  [priceCalendars]="priceCalendars()" />
        <app-favorite-hotels-search-form-stars-list formControlName="stars" />
      </div>
      <div class="extends-params">
        <div class="extends-param-container">
          <app-favorite-hotels-search-form-days formControlName="days"
                                                [availableNightsInDirection]="availableNightsInDirection()" />
        </div>
        <div class="extends-param-container">
          <app-favorite-hotels-search-form-checkbox-list formControlName="mealIds"
                                                         [visibleCount]="0"
                                                         [label]="'Питание'"
                                                         [items]="mealsVariants()" />
        </div>
        <div class="extends-param-container">
          <app-favorite-hotels-search-form-checkbox-list formControlName="operatorIds"
                                                         [visibleCount]="0"
                                                         [label]="'Операторы'"
                                                         [items]="operatorsVariants()" />
        </div>
        <div class="extends-param-container">
          <app-favorite-hotels-search-form-checkbox-list formControlName="airlineIds"
                                                         [visibleCount]="0"
                                                         [label]="'Авиакомпании'"
                                                         [items]="airlinesVariants()" />
        </div>
        <div class="extends-param-container">
          <div class="combined-container">
            <div class="label">Комби туры</div>
            <div class="combined">
              <div class="radio">
                <label><input type="radio" value="0" formControlName="combined">Любые</label>
              </div>
              <div class="radio">
                <label><input type="radio" value="1" formControlName="combined">Только комби</label>
              </div>
              <div class="radio">
                <label><input type="radio" value="2" formControlName="combined">Только не комби</label>
              </div>
            </div>
          </div>
        </div>
        <div class="extends-param-container">
          <app-favorites-hotels-search-form-not-gds formControlName="notGDS" />
        </div>
      </div>
    </div>

    <button type="button"
            class="btn-search-tours"
            [disabled]="loadingHotelsList$ | async"
            (click)="searchTours()">
      @if (searchInProgress$ | async) {
        <div class="search-loader">
          <img class="icon" src="assets/icons/favorite-hotels/search-tours-loader.svg">
          Найти туры
        </div>
      } @else {
        Найти туры
      }
    </button>
  </div>
</form>
