<div class="vacation-container" id="highlight">


  <ng-container [ngSwitch]="modalState">

    <div
      *ngSwitchCase="VacationReminderModalState.CLOSED_NO_REMINDER"
      class="vacation-block">
      <span class="title">{{'PAGES.DEALS.CLIENT_CARD.VACATION_REMINDER.TITLE' | translate}}</span>
      <button
        (click)="showPopup()"
        class="add-button"></button>
    </div>

    <div
      *ngSwitchCase="VacationReminderModalState.CLOSED_WITH_REMINDER"
      class="vacation-item">
      <span class="title">{{'PAGES.DEALS.CLIENT_CARD.VACATION_REMINDER.TITLE' | translate}}</span>
      <div class="edit">
        <span class="date-time">{{savedVacationDateTime | monthYearFormat}}</span>
        <button
          (click)="showPopup()"
          class="edit-button"></button>
      </div>
    </div>
  </ng-container>



</div>



