import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FilterListEnum } from '../../../interfaces/chat-timeline.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatTimelineFilterService {
  private chatTimelineFilters = new BehaviorSubject<string[]>([FilterListEnum.all]);
  private whatsappFilterOn = new Subject<void>();

  public get chatTimelineFilters$(): Observable<string[] | []> {
    return this.chatTimelineFilters.asObservable();
  }
  public get whatsappFilterSetsOn$(): Observable<void> {
    return this.whatsappFilterOn.asObservable();
  }

  public setChatTimelineFilters(filters: string[]) {
    this.chatTimelineFilters.next(filters);
  }
  public setWhatsappFilterOn() {
    this.whatsappFilterOn.next();
  }
}
