import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'secondsToString',
})
export class SecondsToStringPipe implements PipeTransform {
  public hoursText = '';
  public minutesText = '';
  public secondsText = '';
  constructor(private translate: TranslateService) {
    this.translate.get('COMMON.DURATION_SHORT.SECONDS').subscribe(value => {
      this.secondsText = value;
    });
    this.translate.get('COMMON.DURATION_SHORT.MINUTES').subscribe(value => {
      this.minutesText = value;
    });
    this.translate.get('COMMON.DURATION_SHORT.HOURS').subscribe(value => {
      this.hoursText = value;
    });
  }

  transform(value: number): string {
    let minutes: number = Math.trunc(value / 60);
    let hours = 0;
    const seconds: number = value - minutes * 60;

    if (minutes >= 60) {
      hours = Math.trunc(minutes / 60);
      minutes -= hours * 60;
    }

    let response = '';

    if (hours > 0) {
      response = `${response + hours} ${this.hoursText} `;
    }

    if (minutes > 0) {
      response = `${response + minutes} ${this.minutesText} `;
    }

    if (seconds > 0) {
      response = `${response + seconds} ${this.secondsText}`;
    }

    return response;
  }
}
