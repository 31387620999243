import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PhoneItem } from '@api-clients/crm-api-client/models/phone-item';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxMaskPipe } from 'ngx-mask';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import tippy from 'tippy.js';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';
import { ConfigsService } from '../../../../../../core/services';
import { getFormattedDate, getFormattedTime } from '../../../../../../helpers/time';
import { INITIAL_CALENDAR_OPTIONS, TimelineTask, TimePeriods } from '../../../../../../models/datetimepicker';
import {
  ActionsPopupHelperService,
} from '../../components/actions-popup-menu/services/actions-popup-helper.service';

const FRIDAY_NUMBER_IN_WEEK = 5;
const SCROLL_SHIFT = 2;
const TIME_SHIFT_IF_OVERLAP = 1;
const WORK_TIME_START = '08:00:00';
const WORK_TIME_END = '21:30:00';
const CALENDAR_HEIGHT = '252px';
const TASK_TYPES_LANG_KEY = 'PAGES.TASK_LIST.NEXT_ACTIONS';
const INTENT_TYPES_LANG_KEY = 'PAGES.DEALS.INTENT_TYPES';
const CALENDAR_LOCALE_LANG_KEY = 'CALENDAR_LOCALE';
const DEFAULT_TASK_TITLE = 'Новое задание';
const CUSTOM_BUTTON_TEXT = 'Готово';

export interface DateTimeValue {
  date: Date;
  dateValue: string;
  timeValue: string;
  duration: number;
}

type TimePeriodType = TimePeriods | string | Date;

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DatetimepickerComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly TIME_PERIODS_ARRAYS = Object.keys(TimePeriods);
  public dayCalendarOptions: CalendarOptions;
  public deals$ = this.dealsFacade.dealsList$;
  public dayCalendarApi: Calendar;
  public selectedTimePeriod: TimePeriodType;
  public nextTasks: TimelineTask[];
  public initialized = false;
  public taskTypes: { [x: string]: any };
  public intentTypes: { [x: string]: any };
  public taskStartDate: Date;
  private taskEndDate: Date;
  private isOverlappedTask: TimelineTask;
  private dealsListSubscription: Subscription;
  public CALENDAR_HINT =
    'Выберите подходящее вам время и нажмите на строку. Тогда задание добавится в календарь.';

  @Output() taskDateEvent: EventEmitter<DateTimeValue> = new EventEmitter<DateTimeValue>();
  @Output() manualClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() taskTitle: string;
  @Input() positionTop: number;
  @Input() meetingDateTime: Date;
  @ViewChild('dayCalendar', { static: true }) dayCalendar: FullCalendarComponent;
  selectedNotEmptySlot = false;

  constructor(
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private dealsFacade: DealsFacade,
    private cdRef: ChangeDetectorRef,
    private configsService: ConfigsService,
    private maskPipe: NgxMaskPipe,
    private actionsPopupHelperService: ActionsPopupHelperService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    this.translateService
      .get(TASK_TYPES_LANG_KEY)
      .pipe(first())
      .subscribe(res => {
        this.taskTypes = res;
      });
    this.translateService
      .get(INTENT_TYPES_LANG_KEY)
      .pipe(first())
      .subscribe(res => {
        this.intentTypes = res;
        this.getDealTasksAsTimelineTasks();
      });
  }

  ngOnInit(): void {
    const parent = this.el.nativeElement.parentNode;
    const div = this.renderer.createElement('div');
    this.renderer.appendChild(div, this.el.nativeElement);
    this.renderer.appendChild(parent, div);
    this.translateService
      .get(CALENDAR_LOCALE_LANG_KEY)
      .pipe(first())
      .subscribe(res => {
        this.primeNGConfig.setTranslation(res);
      });
    this.dayCalendarOptions = this.initDayCalendarOptions();
  }

  ngAfterViewInit(): void {
    if (this.dayCalendar) {
      this.dayCalendarApi = this.dayCalendar.getApi();
    }
    if (this.dayCalendarApi && !this.initialized) {
      this.loadTasksToCalendar();
      this.selectTimeOnTimegrid(this.meetingDateTime);
      this.initialized = true;
    }
  }

  initDayCalendarOptions(): CalendarOptions {
    return {
      ...INITIAL_CALENDAR_OPTIONS,
      height: CALENDAR_HEIGHT,
      views: {
        timeGridDay: {
          slotMinTime: WORK_TIME_START,
          slotMaxTime: WORK_TIME_END,
          allDaySlot: false,
          slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            omitZeroMinute: false,
          },
        },
      },
      customButtons: {
        closeBtn: {
          text: CUSTOM_BUTTON_TEXT,
          click: (ev: MouseEvent) => {
            this.manualClose.emit(ev);
          },
        },
      },
      eventColor: '#BCDFFF',
      eventBorderColor: '#dfe4e8',
      eventTextColor: '#212b35',
      selectOverlap: true,
      select: this.handleManualSelect.bind(this),
      eventDidMount: this.handleDidMount.bind(this),
      slotDuration: this.actionsPopupHelperService.getDurationString(this.taskTitle),
    };
  }

  getSecretedPhone(clientName: string, phoneItem: PhoneItem): string {
    if (clientName || !phoneItem?.phone) {
      return '';
    }
    const formattedPhone = this.maskPipe.transform(
      phoneItem.phone,
      this.configsService.getPhoneMaskByCode(phoneItem.code),
    );
    return `${phoneItem.code} ${formattedPhone.slice(0, -5).replace(/\d/g, '*')}${formattedPhone.slice(-5)}`;
  }

  handleDidMount(info): void {
    tippy(info.el, {
      trigger: 'click',
      content: `
          <div class="calendar-event-info">
            <div class="header">
               <table>
                  <tr>
                      <th>${info.event.start.getHours()}:${info.event.start.getMinutes() || '00'}</th>
                      <td>${info.event.title}</td>
                  </tr>
                </table>
             </div>
             <div class="info-container">
                <table>
                  <tr>
                    <th>Клиент:</th>
                    <td>${info.event.extendedProps.clientName}</td>
                    <td>${this.getSecretedPhone(
        info.event.extendedProps.clientName,
        info.event.extendedProps.phone,
      )}</td>
                    </td>

                </tr>
                <tr>
                  <th>Комментарий:</th>
                  ${
        info.event.extendedProps.comment
          ? `
                   <td>
                    ${
            info.event.extendedProps.comment.length < 100
              ? info.event.extendedProps.comment
              : `${info.event.extendedProps.comment.slice(0, 100)}...`
          }
                    </td>
                  `
          : `<td>Не заполнено</td>`
      }


                </tr>
                <tr>
              </table>
            </div>
            </div>
          `,
      allowHTML: true,
      onTrigger() {
        this.eventListener = $event => {
          $event.preventDefault();
        };
        window.document
          .getElementById('daytimelinecalendar')
          .addEventListener('wheel', this.eventListener, false);
      },
      onHidden() {
        window.document
          .getElementById('daytimelinecalendar')
          .removeEventListener('wheel', this.eventListener, false);
      },
    });
    if (info.isMirror) {
      info.el.querySelector('.fc-event-title').textContent =
        this.taskTypes[this.taskTitle] || DEFAULT_TASK_TITLE;
    }
  }

  handleManualSelect(selected): void {
    this.taskStartDate = selected.start;
    this.taskEndDate = selected.end;
    this.emitDateTimeValue(selected.start, selected.end);
    const isSlotEmpty = this.timeSlotIsEmpty(selected.start, selected.end, false);
    this.selectedNotEmptySlot = !isSlotEmpty;
  }

  loadTasksToCalendar(): void {
    this.dayCalendarApi.batchRendering(() => {
      this.dayCalendarApi.getEvents().forEach(event => event.remove());
      this.nextTasks.forEach(event => this.dayCalendarApi.addEvent(event));
    });
  }

  getTimeShift(period): Date {
    const now = new Date();
    const timeShift = now;

    if (period instanceof Date) {
      return period;
    }

    switch (period) {
      case TimePeriods.urgent:
        return timeShift;
      case TimePeriods.after15Min:
        return new Date(timeShift.setMinutes(now.getMinutes() + 15));
      case TimePeriods.after30Min:
        return new Date(timeShift.setMinutes(now.getMinutes() + 30));
      case TimePeriods.afterHour:
        return new Date(timeShift.setHours(now.getHours() + 1));
      case TimePeriods.today:
        return new Date(timeShift.setMinutes(now.getMinutes() + 15));
      case TimePeriods.tomorrow:
        timeShift.setDate(now.getDate() + 1);
        timeShift.setHours(9, 0, 0);
        return timeShift;
      case TimePeriods.untilEndOfWeek:
        return new Date(timeShift.setDate(now.getDate() + (FRIDAY_NUMBER_IN_WEEK - timeShift.getDay())));
      case TimePeriods.afterWeek:
        return new Date(timeShift.setDate(now.getDate() + 7));
      case TimePeriods.afterMonth:
        return new Date(timeShift.setMonth(now.getMonth() + 1));
      default:
        return timeShift;
    }
  }

  selectTimeOnTimegrid(selectedTimePeriod: TimePeriodType): void {
    this.taskStartDate = this.getTimeShift(selectedTimePeriod);
    const endDate: Date = this.getTimeShift(selectedTimePeriod);
    this.taskEndDate = new Date(
      endDate.getTime() + this.actionsPopupHelperService.getDuration(this.taskTitle) * 60000,
    );

    if (this.taskStartDate && this.taskEndDate) {
      if (this.isOutsideBusinessHours(this.taskEndDate)) {
        this.taskStartDate = this.getTimeShift(TimePeriods.tomorrow);
        this.taskEndDate = this.getTimeShift(TimePeriods.tomorrow);
        this.taskEndDate = new Date(
          this.taskEndDate.setMinutes(
            this.taskEndDate.getMinutes() + this.actionsPopupHelperService.getDuration(this.taskTitle),
          ),
        );
      }

      if (this.timeSlotIsEmpty(this.taskStartDate, this.taskEndDate)) {
        this.onDaySelect(this.taskStartDate);

        this.dayCalendarApi.select(this.taskStartDate, this.taskEndDate);
        this.dayCalendarApi.scrollToTime(
          `${this.taskStartDate.getHours() - SCROLL_SHIFT}:${this.taskStartDate.getMinutes()}`,
        );
        this.emitDateTimeValue(this.taskStartDate, this.taskEndDate);
        this.cdRef.detectChanges();
      }
    }
  }

  isOutsideBusinessHours(selectedEndDate: Date): boolean {
    const currentDate = selectedEndDate;
    const formattedEndDate = `${selectedEndDate.getMonth() + 1}/${selectedEndDate.getDate()}/
    ${selectedEndDate.getFullYear()}
  `;
    const workTimeEnd = new Date(`${formattedEndDate} ${WORK_TIME_END}`);

    return currentDate > workTimeEnd;
  }

  timeSlotIsEmpty(selectedStartDate: Date, selectedEndDate: Date, isNeedToShift = true): boolean {
    const results = [];

    this.dayCalendarApi.getEvents().forEach(task => {
      const { start, end } = task;
      const isOverlap = this.isBetweenTwoDates(start, end, selectedStartDate, selectedEndDate);
      if (isOverlap) {
        this.isOverlappedTask = task;
      }
      results.push(isOverlap);
    });

    const isSlotEmpty = !results.some(Boolean);
    if (!isSlotEmpty && isNeedToShift) {
      const shiftedTime = this.shiftTaskTimeIfOverlap();
      this.taskStartDate = shiftedTime.start;
      this.taskEndDate = shiftedTime.end;

      return this.timeSlotIsEmpty(this.taskStartDate, this.taskEndDate);
    }

    return isSlotEmpty;
  }

  shiftTaskTimeIfOverlap() {
    const shiftedTime = {
      start: null,
      end: null,
    };

    if (!this.isOverlappedTask) {
      return;
    }

    shiftedTime.start = this.isOverlappedTask.end;
    shiftedTime.end = new Date(
      this.isOverlappedTask.end.setHours(this.isOverlappedTask.end.getHours() + TIME_SHIFT_IF_OVERLAP),
    );

    return shiftedTime;
  }

  isBetweenTwoDates(start, end, selectedStartDate, selectedEndDate) {
    return (
      (selectedStartDate > start && selectedStartDate < end) ||
      (selectedEndDate > start && selectedEndDate < end) ||
      (selectedStartDate < start && selectedEndDate > end)
    );
  }

  onDaySelect(date: Date): void {
    if (this.dayCalendarApi) {
      this.dayCalendarApi.gotoDate(date);
    }
  }

  countDuration(startDate: Date, endDate: Date): Promise<number> {
    return new Promise(resolve => {
      resolve(Math.floor((endDate.getTime() - startDate.getTime()) / 60000));
    });
  }

  emitDateTimeValue(startDate: Date, endDate: Date): void {
    const dateValue: string = getFormattedDate(startDate);
    const timeValue = getFormattedTime(startDate);

    this.countDuration(startDate, endDate).then(duration => {
      const data: DateTimeValue = { date: startDate, dateValue, timeValue, duration };
      this.taskDateEvent.emit(data);
    });
  }

  private getDealTasksAsTimelineTasks() {
    this.dealsListSubscription = this.deals$
      .pipe(
        map(tasks => {
          return tasks
            .filter(v => v.nextTask)
            .map(val => {
              const { duration } = val.nextTask;
              const startDate = new Date(`${val.nextTask.date}T${val.nextTask.time}`);
              let endDate = new Date(`${val.nextTask.date}T${val.nextTask.time}`);
              endDate = new Date(endDate.setMinutes(endDate.getMinutes() + duration));

              const event: TimelineTask = {
                id: `${val.nextTask.id}`,
                title: this.taskTypes[val.nextTask.type],
                clientName: val.card.name,
                phone: val.card.phones[0],
                intent: this.intentTypes[val.nextTask.intent],
                comment: val.nextTask?.managerComment,
                start: startDate,
                end: endDate,
                allDay: false,
              };
              return event;
            });
        }),
      )
      .subscribe((values: TimelineTask[]) => {
        this.nextTasks = values;
        if (this.initialized) {
          this.loadTasksToCalendar();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.dealsListSubscription) {
      this.dealsListSubscription.unsubscribe();
    }
  }
}
