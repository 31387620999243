import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DateTimeValue } from '../../../../modules/datetimepicker/datetimepicker.component';
import {
  getAPIFormattedDate,
  getFormattedDate,
  getFormattedTime,
} from '../../../../../../../../helpers/time';
import { DEFAULT_TASK_DURATION_IN_MIN } from '../../../actions-popup-menu/models/task-durations';
import { DealViewService } from '../../../../services/deal-view.service';
import { POPUP_DATA } from '../../../../../../../../shared/services/popup-service.service';
import { AmplitudeTrackService } from '../../../../../../../../core/services/amplitude/amplitude-track.service';
import { UPDATE_VACATION_SCHEDULE } from '../../../../../../../../core/services/amplitude/amplitudeEvents';

@Component({
  selector: 'app-vacation-reminder-popup',
  templateUrl: './vacation-reminder-popup.component.html',
  styleUrls: ['./vacation-reminder-popup.component.scss'],
})
export class VacationReminderPopupComponent implements OnInit {
  public savedVacationDateTime: string;
  public cardId: number;
  @Output() closeClicked: EventEmitter<string> = new EventEmitter<string>();

  public datetimePopup: boolean;
  public form: UntypedFormGroup;
  public TRANSLATE_PATH = 'PAGES.DEALS.VACATION_REMINDER.DATE_TIME_LABEL';
  public dateTime: Date;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dealViewService: DealViewService,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(POPUP_DATA) private data: any,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.savedVacationDateTime = data.savedVacationDateTime;
    this.cardId = data.cardId;
  }

  ngOnInit(): void {
    this.initDateTime();
  }

  initDateTime() {
    const DATE_TIME_VALUE: DateTimeValue = {
      date: new Date(),
      dateValue: getFormattedDate(new Date()),
      timeValue: getFormattedTime(new Date()),
      duration: DEFAULT_TASK_DURATION_IN_MIN,
    };

    this.dateTime = DATE_TIME_VALUE.date;

    this.form = this.formBuilder.group({
      date: [DATE_TIME_VALUE.dateValue, [Validators.required]],
      time: [DATE_TIME_VALUE.timeValue, [Validators.required]],
      cardId: [],
    });
  }

  get dateControl(): UntypedFormControl {
    return this.form.get('date') as UntypedFormControl;
  }

  get timeControl(): UntypedFormControl {
    return this.form.get('time') as UntypedFormControl;
  }

  getIsDisabledForm(): boolean {
    return false;
  }
  openDatetimepickerPopup() {
    this.datetimePopup = true;
  }

  setMeetingDateTime($event) {
    this.dateTime = $event.date;
    this.form.patchValue({
      date: $event.dateValue,
      time: $event.timeValue,
    });
  }

  closeDatetimepickerPopup($event: any) {
    $event.stopPropagation();
    this.datetimePopup = false;
  }

  save() {
    if (this.form.invalid) {
      return;
    }
    const date = getAPIFormattedDate(this.dateTime);

    this.dealViewService
      .updateNextRestDate(this.cardId, date)
      .pipe(first())
      .subscribe(res => {
        if (res) {
          this.savedVacationDateTime = date;
          this.close();
          this.amplitudeTrackService.trackEvent(UPDATE_VACATION_SCHEDULE, {
            crmCardId: this.cardId,
            vacationScheduleDateTime: date,
          });
        }
      });
  }

  close() {
    this.closeClicked.emit(this.savedVacationDateTime);
    this.datetimePopup = false;
  }
}
