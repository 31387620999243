import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StandaloneTaskItem } from '@api-clients/crm-api-client';
import { Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { RecommendationsApiService } from '../services/recommendations-api.service';
import { WorkerStateService } from '../../../../core/services';
import { Notify, NotifyService, NotifyTypeEnum } from '../../../../shared/notify/services/notify.service';
import { RecommendationsFacade } from '../../../../+state/recommendations/recommendations.facade';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationsComponent implements OnInit {
  public standaloneTaskItems: StandaloneTaskItem[] = [];
  public recommendationsSub: Subscription;
  public employeeId: number;
  public isLoading = true;
  constructor(
    private recommendationsApiService: RecommendationsApiService,
    private recommendationsFacade: RecommendationsFacade,
    private workerStateService: WorkerStateService,
    private cdRef: ChangeDetectorRef,
    private notifyService: NotifyService,
    private translatePipe: TranslatePipe,
  ) {
    this.employeeId = this.workerStateService.currentWorkerValue.employeeId;
  }

  ngOnInit(): void {
    this.recommendationsSub = this.recommendationsFacade.recommendationsList$.subscribe(
      (items: StandaloneTaskItem[]) => {
        this.standaloneTaskItems = items;
        this.isLoading = false;
        this.cdRef.detectChanges();
      },
    );
  }

  refreshData() {
    this.recommendationsFacade.loadRecommendations();
  }

  closeTask(taskId: number) {
    this.isLoading = true;
    this.cdRef.detectChanges();
    this.recommendationsApiService.closeTask(taskId).subscribe(result => {
      this.refreshData();
      if (result.success) {
        const notify: Notify = {
          type: NotifyTypeEnum.success,
          title: this.translatePipe.transform('PAGES.RECOMMENDATIONS.CLOSE_TASK_NOTIFY.TITLE'),
          text: this.translatePipe.transform('PAGES.RECOMMENDATIONS.CLOSE_TASK_NOTIFY.SUCCESS'),
        };
        this.notifyService.create(notify);
      } else {
        const notify: Notify = {
          type: NotifyTypeEnum.success,
          title: this.translatePipe.transform('PAGES.RECOMMENDATIONS.CLOSE_TASK_NOTIFY.TITLE'),
          text: this.translatePipe.transform('PAGES.RECOMMENDATIONS.CLOSE_TASK_NOTIFY.ERROR'),
        };
        this.notifyService.create(notify);
      }
    });
  }
}
