import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxItem } from '../favorite-hotels-search-form-checkbox-list.component';

@Component({
  selector: 'app-favorites-hotels-search-form-checkbox-list-dropdown',
  templateUrl: './favorite-hotels-search-form-checkbox-list-dropdown.component.html',
  styleUrl: './favorite-hotels-search-form-checkbox-list-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule],
})
export class FavoriteHotelsSearchFormCheckboxListDropdownComponent {
  @Input() label: string;
  @Input() items: CheckboxItem[] = [];

  selected = output<void>();

  onCheckboxChange() {
    this.selected.emit();
  }
}
