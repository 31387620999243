<div class="wrap"
>
  <a class="avatar-bar"
     (click)="dealViewOpened()"
     [routerLink]="['/deals/view', crmId]"
     [queryParams]="{phone: phone.phone, code: phone.code}"
     [ngClass]="{'active': isSelectedChat}"
  >
    <div class="avatar-wrap"
         (mouseenter)="showOverlay()"

    >
      <img
        [src]="avatarSrc"
        class="avatar"
        alt="avatar">

      <div class="counter"
           *ngIf="hasUnreadMessages"
      >
        1
      </div>
    </div>

  </a>


</div>
