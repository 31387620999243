import { Action } from '@ngrx/store';
import {
  ChatMessagesPayload,
  DeletePendingMessagePayload,
  RefreshChatMessagesStatusPayload,
  ShowPendingMessagePayload,
  UpdateChatMessagesPayload,
} from './chat-messages.interface';

export enum ChatMessagesActionTypes {
  SetChatMessages = '[Chat Messages] Set Chat Messages',
  ResetChat = '[Chat Messages] Reset Chat',
  DeleteMessage = '[Chat Messages] Delete Message',
  DeleteMessageSuccess = '[Chat Messages] Delete Message Success',
  DeleteMessageFailure = '[Chat Messages] Delete Message Failure',
  UpdateChatMessages = '[Chat Messages] Update Chat Messages',
  ShowPendingMessage = '[Chat Messages] Show Pending Message',
  DeletePendingMessage = '[Chat Messages] Delete Pending Message',
  RefreshChatMessagesStatus = '[Chat Messages] Refresh Chat Messages Status',
}

export class SetChatMessages implements Action {
  readonly type = ChatMessagesActionTypes.SetChatMessages;
  constructor(public payload: ChatMessagesPayload) {}
}
export class UpdateChatMessages implements Action {
  readonly type = ChatMessagesActionTypes.UpdateChatMessages;
  constructor(public payload: UpdateChatMessagesPayload) {}
}
export class ShowPendingMessage implements Action {
  readonly type = ChatMessagesActionTypes.ShowPendingMessage;
  constructor(public payload: ShowPendingMessagePayload) {}
}
export class DeletePendingMessage implements Action {
  readonly type = ChatMessagesActionTypes.DeletePendingMessage;
  constructor(public payload: DeletePendingMessagePayload) {}
}
export class RefreshChatMessagesStatus implements Action {
  readonly type = ChatMessagesActionTypes.RefreshChatMessagesStatus;
  constructor(public payload: RefreshChatMessagesStatusPayload) {}
}

export class ResetChat implements Action {
  readonly type = ChatMessagesActionTypes.ResetChat;
}

export class DeleteMessage implements Action {
  readonly type = ChatMessagesActionTypes.DeleteMessage;
  constructor(public messageId: string) {}
}

export class DeleteMessageSuccess implements Action {
  readonly type = ChatMessagesActionTypes.DeleteMessageSuccess;
  constructor(public messageId: string) {}
}

export class DeleteMessageFailure implements Action {
  readonly type = ChatMessagesActionTypes.DeleteMessageFailure;
  constructor(public error: any) {}
}

export type ChatMessagesAction =
  | SetChatMessages
  | UpdateChatMessages
  | RefreshChatMessagesStatus
  | ShowPendingMessage
  | DeletePendingMessage
  | ResetChat
  | DeleteMessage
  | DeleteMessageSuccess
  | DeleteMessageFailure;
