import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ScheduledMessagesComponent } from 'app/modules/scheduled-messages/scheduled-messages.component';
import { CurrentOutcomeCallFacade } from '../../../../+state/current-outcome-call/current-outcome-call.facade';
import { currentOutcomeCallReducer } from '../../../../+state/current-outcome-call/current-outcome-call.reducers';
import { currentOutcomeCallInitialState } from '../../../../+state/current-outcome-call/current-outcome-call.state';
import { SharedUtilityModule } from '../../../../../../../../libs/shared/utility/src';
import { SharedModule } from '../../../../shared/shared.module';
import { DropdownModule } from '../../../../ui-components/dropdown/dropdown.module';
import { PhoneInputWithCodeModule } from '../../../../ui-components/phone-input-with-code/phone-input-with-code.module';
import { SlideToggleModule } from '../../../../ui-components/slide-toggle/slide-toggle.module';
import { UiDatetimepickerModule } from '../../../../ui-components/ui-datetimepicker/ui-datetimepicker.module';
import { ChatTimelineModule } from '../../../chat-timeline/chat-timeline.module';
import { FormComponentsModule } from '../../../form-components/form-components.module';
import { AddDealModule } from '../add-deal/add-deal.module';
import { DealsItemQuestionnaireModule } from '../deals-list/modules/deals-list-content/deals-item/deals-item-questionnaire/deals-item-questionnaire.module';
import { DealsItemModule } from '../deals-list/modules/deals-list-content/deals-item/deals-item.module';
import { PostSaleListItemModule } from '../deals-list/modules/deals-list-post-sale-content/components/post-sale-list-item/post-sale-list-item.module';
import { ActionsPopupMenuModule } from './components/actions-popup-menu/actions-popup-menu.module';
import { BaseInfoComponent } from './components/base-info/base-info.component';
import { CurrentCallComponent } from './components/click2call/components/current-call/current-call.component';
import { ClientReadinessComponent } from './components/client-readiness/client-readiness.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { DealViewContentComponent } from './components/deal-view-content/deal-view-content.component';
import { DealViewIndicatorComponent } from './components/deal-view-indicator/deal-view-indicator.component';
import { DealViewSkeletonComponent } from './components/deal-view-skeleton/deal-view-skeleton.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { VacationReminderPopupComponent } from './components/vacation-reminder/сomponents/vacation-reminder-popup/vacation-reminder-popup.component';
import { WorkerInfoComponent } from './components/worker-info/worker-info.component';
import { DealViewComponent } from './deal-view.component';
import { ContentAdjustDirective } from './directives/content-adjust.directive';
import { ClientAvatarComponent } from './modules/client-card-info/client-avatar/client-avatar.component';
import { ClientCardInfoModule } from './modules/client-card-info/client-card-info.module';
import { ClientCardNameModule } from './modules/client-card-name/client-card-name.module';
import { CreateTaskModalModule } from './modules/create-task-modal/create-task-modal.module';
import { DatetimepickerModule } from './modules/datetimepicker/datetimepicker.module';
import { SearchFormService } from './modules/favorites-hotels/components/search-form/search-form.service';
import { SearchToursProgressService } from './modules/favorites-hotels/services/search-tours-progress.service';
import { SalesFunnelStagesModule } from './modules/sales-funnel-stages/sales-funnel-stages.module';
import { SalesFunnelTaskPopupModule } from './modules/sales-funnel-task-popup/sales-funnel-task-popup.module';
import { TaskAlertModule } from './modules/task-alert/task-alert.module';
import { CrmTaskApiService } from './services/crm-task-api.service';
import { DealViewService } from './services/deal-view.service';
import { FinishDealService } from './services/finish-deal/finish-deal.service';
import { MarkAsNotNewService } from './services/mark-as-not-new/mark-as-not-new.service';
import { MarkNonConversionService } from './services/mark-non-conversion/mark-non-conversion.service';
import { SearchRequestService } from './services/search-request.service';
import { FavoritesHotelsTabContentComponent } from './modules/favorites-hotels/favorites-hotels-tab-content.component';
import { HotOffersListComponent } from './modules/hot-offers/components/list/hot-offers-list.component';
import { HotOffersService } from './modules/hot-offers/hot-offers.service';

@NgModule({
  declarations: [
    DealViewComponent,
    ClientReadinessComponent,
    DealViewContentComponent,
    DealViewSkeletonComponent,
    VacationReminderPopupComponent,
    DealViewIndicatorComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
    ActionsPopupMenuModule,
    AddDealModule,
    ReactiveFormsModule,
    ClientCardInfoModule,
    ClientCardNameModule,
    DealsItemModule,
    SharedUtilityModule,
    PostSaleListItemModule,
    ChatTimelineModule,
    DealsItemQuestionnaireModule,
    DropdownModule,
    SalesFunnelStagesModule,
    SalesFunnelTaskPopupModule,
    SlideToggleModule,
    DatetimepickerModule,
    PhoneInputWithCodeModule,
    FormComponentsModule,
    StoreModule.forFeature('current-outcome-call', currentOutcomeCallReducer, {
      initialState: currentOutcomeCallInitialState,
    }),
    TranslateModule,
    MatTooltipModule,
    CreateTaskModalModule,
    TaskAlertModule,
    UiDatetimepickerModule,
    HotOffersListComponent,
    FavoritesHotelsTabContentComponent,
    ContentAdjustDirective,
    ScheduledMessagesComponent,
    WorkerInfoComponent,
    ClientAvatarComponent,
    ContactsComponent,
    BaseInfoComponent,
    StatisticsComponent,
    CurrentCallComponent,
  ],
  providers: [
    FinishDealService,
    MarkAsNotNewService,
    MarkNonConversionService,
    CrmTaskApiService,
    DealViewService,
    SearchRequestService,
    CurrentOutcomeCallFacade,

    HotOffersService,
    SearchFormService,
    SearchToursProgressService,
  ],
  exports: [],
})
export class DealViewModule {}
