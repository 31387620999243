import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DealsListState } from './deals-list.interface';
import { SalesFunnelCategory, SalesFunnelCategoryEnum } from 'app/modules/deals/modules/deals-list/deal-list';

const getDealsState = createFeatureSelector<DealsListState>('dealsList');

const getCurrentStage = createSelector(getDealsState, (state: DealsListState) => state.currentStage);

const getCurrentCategory = createSelector(getDealsState, (state: DealsListState) => state.currentCategory);

const getIsPostponedCategorySelected = createSelector(
  getCurrentCategory,
  (currentCategory: SalesFunnelCategory | null) =>
    (currentCategory != null && currentCategory.name) === SalesFunnelCategoryEnum.postponed,
);

const getListMaximizedTourPackages = createSelector(
  getDealsState,
  (state: DealsListState) => state.listMaximizedTourPackages || [],
);

const getLastSelectedListItemId = createSelector(
  getDealsState,
  (state: DealsListState) => state.lastSelectedListItemId,
);

const getIsAllowShowOtherDeals = createSelector(
  getDealsState,
  (state: DealsListState) => state.isAllowShowOtherDeals,
);

export const dealsListQuery = {
  getCurrentStage,
  getCurrentCategory,
  getIsPostponedCategorySelected,
  getListMaximizedTourPackages,
  getLastSelectedListItemId,
  getIsAllowShowOtherDeals,
};
