import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { UiCheckboxComponent } from './components/ui-checkbox.component';

@NgModule({
  declarations: [UiCheckboxComponent],
  imports: [CommonModule, CheckboxModule],
  exports: [UiCheckboxComponent, CheckboxModule],
})
export class UICheckboxModule {}
