import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-quick-answer-btn',
  templateUrl: './quick-answer-btn.component.html',
  styleUrls: ['./quick-answer-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickAnswerBtnComponent {
  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() holdEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() isDisabled = false;

  private holdTimer: ReturnType<typeof setTimeout> | null = null;
  private holdTime = 2000;
  private isHold = false;

  onClick(): void {
    if (!this.isHold) {
      this.clickEvent.emit();
    }
    this.isHold = false;
  }

  startPress(): void {
    this.isHold = false;
    this.holdTimer = setTimeout(() => {
      this.holdEvent.emit();
      this.isHold = true;
    }, this.holdTime);
  }

  stopPress(): void {
    if (this.holdTimer) {
      clearTimeout(this.holdTimer);
      this.holdTimer = null;
    }
  }
}
