<div class="reservation">
  <img src="assets/icons/timeline/shop.svg"
       alt="search-history-item"
       class="icon">
  <div class="reservation-content">
    <span class="header-title">{{'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.RESERVATION.TITLE' | translate}}</span>
    <span class="manager-name text">
      {{'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.RESERVATION.MANAGER' | translate}}:
      <ng-container *ngIf="tourPackage?.manager?.name as managerName">
              {{managerName}}
      </ng-container>
    </span>
    <div class="info text">
      <ng-container *ngIf="tour">
        <div class="info-item"
             *ngIf="tour.country && tour.region">
          <img src="assets/icons/timeline/reservation/destination.svg"
               alt="destination"
               class="item-icon">
          <span class="text">{{destination}}</span>
        </div>
        <div class="info-item"
             *ngIf="tour.hotel">
          <img src="assets/icons/timeline/reservation/hotel.svg"
               alt="hotel"
               class="item-icon">
          <span class="text">{{hotelName}}</span>
        </div>
        <div class="info-item"
             *ngIf="tour.room">
          <img src="assets/icons/timeline/reservation/room.svg"
               alt="room"
               class="item-icon">
          <span class="text">{{tour.room.name}}</span>
        </div>
        <div class="info-item"
             *ngIf="tour.roomType">
          <img src="assets/icons/timeline/reservation/room.svg"
               alt="room"
               class="item-icon">
          <span class="text">{{tour.roomType.name}}</span>
        </div>
        <div class="info-item"
             *ngIf="tour.checkInDate && tour.checkOutDate">
          <img src="assets/icons/timeline/reservation/calendar.svg"
               alt="calendar"
               class="item-icon">
          <span class="text">{{dates}} {{nights}}</span>
        </div>
        <div class="info-item"
             *ngIf="tour.meal">
          <img src="assets/icons/timeline/reservation/meal.svg"
               alt="meal"
               class="item-icon">
          <span class="text">{{meal}}</span>
        </div>
        <div class="info-item total-cost"
             *ngIf="tourPackage.totalCost as totalCost">
          <img src="assets/icons/timeline/reservation/wallet.svg"
               alt="totalCost"
               class="item-icon">
          <span class="text total-cost">{{totalCost}} тг.</span>
        </div>

      </ng-container>

    </div>
  </div>
  <div class="confirmed" *ngIf="tourPackage.isConfirmed">
    {{'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.RESERVATION.CONFIRMED' | translate}}
  </div>
</div>
