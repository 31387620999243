import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ClientAvatarComponent } from 'app/modules/deals/modules/deal-view/modules/client-card-info/client-avatar/client-avatar.component';
import { SharedUtilityModule } from '../../../../../../../../../../../../libs/shared/utility/src';
import { GetInitialsModule } from '../../../../../../../../helpers/pipes/getInitials/get-initials.module';
import { ClientCardNameModule } from '../../../../../deal-view/modules/client-card-name/client-card-name.module';
import { OpenTourPackageComponent } from './components/open-tour-package/open-tour-package.component';
import { PostSaleCardHeaderComponent } from './components/post-sale-card-header/post-sale-card-header.component';
import { RemoveExtrasPipe } from './components/tour-operator-request-id/helpers/remove-extras.pipe';
import { TourOperatorRequestIdComponent } from './components/tour-operator-request-id/tour-operator-request-id.component';
import { TourPackageInfoModule } from './components/tour-package-info/tour-package-info.module';
import { TourPackageNumberComponent } from './components/tour-package-number/tour-package-number.component';
import { TourShortInfoComponent } from './components/tour-short-info/tour-short-info.component';
import { VacationDatesComponent } from './components/vacation-dates/vacation-dates.component';
import { VacationDirectionComponent } from './components/vacation-direction/vacation-direction.component';
import { PostSaleListItemComponent } from './post-sale-list-item.component';

@NgModule({
  declarations: [
    PostSaleListItemComponent,
    OpenTourPackageComponent,
    PostSaleCardHeaderComponent,
    TourOperatorRequestIdComponent,
    TourPackageNumberComponent,
    VacationDatesComponent,
    VacationDirectionComponent,
    TourShortInfoComponent,
    RemoveExtrasPipe,
  ],
  imports: [
    CommonModule,
    TourPackageInfoModule,
    TranslateModule,
    SharedUtilityModule,
    GetInitialsModule,
    ClientCardNameModule,
    ClientAvatarComponent,
  ],
  exports: [PostSaleListItemComponent, OpenTourPackageComponent],
})
export class PostSaleListItemModule {}
