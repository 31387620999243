import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function textOrFileRequired(group: UntypedFormGroup): ValidationErrors | null {
  const text = group.get('text').value;
  const file = group.get('file').value;

  if (text || file) {
    return null;
  }
  return { textOrFileRequired: true };
}
