<div *ngIf="categories$ | async as categories" class="deals-screen">
  <app-deals-menu
    (stageChanged)="filterByStage($event)"
    (categoryChanged)="onCategoryChanged($event)"
    [categories]="categories"
    [selectedStage]="selectedStage$ | async"
    [selectedCategory]="selectedCategory$ | async"
  ></app-deals-menu>
  <div class="deals-content">
    <app-alert-on-top> </app-alert-on-top>
    <!-- REFACTOR! -->
    <app-deal-list-content-header
      [stageTitle]="(selectedStage$ | async)?.name || (selectedCategory$ | async)?.name"
      [hasDeals]="(deals$ | async).length > 0"
    >
    </app-deal-list-content-header>
    <ng-container *ngIf="(isSelectedPostSale$ | async) === false; else postSale">
      <ng-container *ngIf="deals$ | async as deals">
        <app-deal-list-content [deals]="deals"></app-deal-list-content>
      </ng-container>
    </ng-container>
    <ng-template #postSale>
      <ng-container *ngIf="postSaleDeals$ | async as postSaleDeals">
        <app-post-sale-deals-list [list]="postSaleDeals"></app-post-sale-deals-list>
      </ng-container>
    </ng-template>
  </div>
</div>

<!-- <ng-container *ngIf="isLoading">
  <app-preloader></app-preloader>
</ng-container> -->

<div class="zenmode-only-overlay" *ngIf="isOnlyZenMode$ | async">Вам доступен только ZenMode</div>
