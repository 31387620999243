import { PhoneItem, WhatsappNewMessage } from '@api-clients/crm-api-client';
import CryptoES from 'crypto-es';
import { ChatMessagesState } from './chat-messages.state';

export function isPhoneOrChatIdChanged(
  prevValue?: PhoneItem | string | null,
  currentValue?: PhoneItem | string | null,
): boolean {
  if (!prevValue && !currentValue) {
    return false;
  }

  if (typeof prevValue !== typeof currentValue) {
    // string -> PhoneItem
    // PhoneItem -> string
    return true;
  }

  if (typeof prevValue === 'string' || typeof currentValue === 'string') {
    return prevValue !== currentValue;
  }

  return prevValue?.phone !== currentValue?.phone;
}

export function isCurrentChat(
  message: WhatsappNewMessage,
  state?: ChatMessagesState,
  from: string | null = null,
) {
  if (!state?.contactPhoneItemOrChatId) {
    return false;
  }

  const isGroup = message?.contact?.isGroup;
  if (!isGroup && !message?.contact?.phone) {
    // на контакте пользователя ожидается укзание телефона, даже для pending сообщений
    return false;
  }

  if (typeof state.contactPhoneItemOrChatId === 'string') {
    if (isGroup) {
      return state.contactPhoneItemOrChatId === message.message?.chatId;
    }

    return state.contactPhoneItemOrChatId === `${message.contact.phone.code}${message.contact.phone.phone}`
  }

  if (!message.contact.phone) {
    // номера телефона нет только у группы и при работе с телегой по username
    // для этих случаев - кейс выше, сравнение по строке
    // по идее, некорректное состояние,
    // ловил на кейсе,
    // когда не срабатывал setChatMessagesState при переключении между чатом с юзером и группой
    console.warn(state, message.contact, { from });
    return false;
  }

  return (
    state.contactPhoneItemOrChatId.phone === message.contact.phone.phone
    && state.contactPhoneItemOrChatId.code === message.contact.phone.code
  );
}

export function generatePendingMessageId(text: string): string {
  const normalizedText = text.replace(/\r\n/g, '\n');
  const hash = CryptoES.SHA1(normalizedText).toString(CryptoES.enc.Hex);
  return `pending-${hash}`;
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
