import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AiGptCompleteRequestModelEnum, AiGptMessageRoleEnum } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { WhatsappMessage } from '@api-clients/crm-api-client';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { WorkerStateService } from '../../../../../core/services';
import { SharedModule } from '../../../../../shared/shared.module';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { AiGptApiService } from '../../../services/ai-gpt-api.service';

@Component({
  selector: 'app-gpt',
  standalone: true,
  imports: [MatAutocompleteModule, MatInputModule, ReactiveFormsModule, FormsModule, SharedModule],
  templateUrl: './gpt.component.html',
  styleUrls: ['./gpt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GptComponent implements ContentCreatorItemComponent, OnInit, OnDestroy {
  @Input() public tourId: string;
  @Input() public tourContent: TourContent;
  @Input() public initialMessage: WhatsappMessage;
  @Output() public sendContentEvent = new EventEmitter();

  public initialText: string;
  public prompt = '';
  public promptVariants: { label: string; prompt?: string }[] = [
    {
      label: 'Продающий текст',
      prompt: 'Придумай продающий текст',
    },
    {
      label: 'Ответь клиенту',
    },
    {
      label: 'Ответь на возражение',
    },
    {
      label: 'Убеди клиента купить',
    },
    {
      label: 'Плюсы и минусы отеля',
      prompt:
        'Проанализируй отзывы с booking.com, tripadvisor.ru и tophotels.ru по этому отелю и выдели пять ' +
        'самых популярных плюсов и три минуса. Пиши кратко, не упоминай про питание и сервис.\n\n' +
        'Пиши в формате:\nПлюсы:\n- ...\n\nМинусы:\n- ...',
    },
    {
      label: 'Фишки отеля',
      prompt:
        'Проанализируй отзывы с booking.com, tripadvisor.ru и tophotels.ru по этому отелю и ' +
        'напиши кому он подойдет. Простыми словами как другу, но на ВЫ. ' +
        'Важно кратко и по факту упоминать фишки отеля',
    },
  ];
  public completionText: string;

  public triggerTextareaResize = new EventEmitter();
  public isLoading$ = new BehaviorSubject(false);
  private destroy$ = new Subject<void>();

  constructor(private aiGptApiService: AiGptApiService, private workerService: WorkerStateService) {}

  ngOnInit(): void {
    this.initialText = this.initialMessage.text;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setPrompt(promptVariant: { label: string; prompt?: string }): void {
    this.prompt = promptVariant.prompt || promptVariant.label;
    this.triggerTextareaResize.emit();
  }

  generateCompletion(): void {
    if (!this.prompt) {
      alert('Заполните поле "Что нужно сделать?"');
      return;
    }
    this.isLoading$.next(true);
    this.completionText = '';
    const worker = this.workerService.currentWorkerValue;
    this.aiGptApiService
      .complete(
        {
          messages: [
            {
              role: 'system' as AiGptMessageRoleEnum,
              content:
                'Ты менеджер турагентства. ' +
                (worker.gender === 1 ? 'Ты парень. ' : 'Ты девушка. ') +
                'Тебя зовут ' +
                worker.name +
                '. ' +
                'Отвечай кратко. Не здоровайся, если не поздоровались с тобой. ' +
                'Не говори "спасибо за обращение" и т.п. ' +
                'Сегодня ' +
                new Date().toLocaleDateString('ru-RU'),
            },
            {
              role: 'user' as AiGptMessageRoleEnum,
              content: this.initialText,
              name: this.initialMessage.isFromMe ? 'manager' : 'client',
            },
            {
              role: 'system' as AiGptMessageRoleEnum,
              content: this.prompt,
            },
          ],
          model: 'gpt-4o' as AiGptCompleteRequestModelEnum,
          place: 'content-creator-gpt',
          maxTokens: 1000,
        },
        this.prompt,
      )
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.isLoading$.next(false);
          console.error(err);
          alert('Ошибка при генерации текста');
          return of(null);
        }),
      )
      .subscribe(completion => {
        if (completion) {
          this.completionText = completion.messages[0].content;
          this.isLoading$.next(false);
        }
      });
  }

  sendContent(): void {
    this.sendContentEvent.emit();
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    if (!this.completionText) {
      return of([] as ContentCreatorItemMessage[]);
    }
    return of([
      {
        component: this,
        message: { text: this.completionText },
      },
    ]);
  }
}
