<div *ngIf="options" class="form-group form-group-select">
  <div class="popup-input-group custom-dots">
    <input (click)="open()" [placeholder]="placeholder" [value]="text" class="form-control" readonly type="text" />
    <div (clickOutside)="close()" [delayClickOutsideInit]="true" [exclude]="'input.form-control'" class="items-list">
      <ul>
        <li
          (click)="select(option)"
          *ngFor="let option of options"
          [class.active]="option === selectedValue"
        >
          <span>{{ 'MODALS.SMS.TYPES.' + SMS_TYPES[option.type]['langKey'] | translate }} </span>
        </li>
      </ul>
    </div>
  </div>
</div>
