import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CrmCardListPostSaleItem } from '@api-clients/crm-api-client/models/crm-card-list-post-sale-item';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

import { DealsListFacade } from '../../../../../../+state/deals-list/deals-list.facade';
import { DealListService } from '../../services/deal-list.service';

@UntilDestroy()
@Component({
  selector: 'app-post-sale-deals-list',
  templateUrl: './deals-list-post-sale-content.component.html',
  styleUrls: ['./deals-list-post-sale-content.component.scss'],
})
export class DealsListPostSaleContentComponent implements OnInit, OnChanges {
  @Input() list: CrmCardListPostSaleItem[];
  public maximizedTourPackagesList$: Observable<number[]>;
  public maximizedTourPackagesList: number[];
  public todayDeals: CrmCardListPostSaleItem[];
  public otherDeals: CrmCardListPostSaleItem[];
  public isAllowToShowOtherDeals: boolean;
  public lastSelectedDealIdSubscription: Subscription;
  public isAllowToShowOtherDealsSubscription: Subscription;

  constructor(
    private dealsListFacade: DealsListFacade,
    private dealListService: DealListService,
    private dealListFacade: DealsListFacade,
    private el: ElementRef,
  ) {
    this.maximizedTourPackagesList$ = this.dealsListFacade.maximizedTourPackagesList$;
    this.lastSelectedDealIdSubscription = this.dealListFacade.lastSelectedListItemId$.subscribe(
      lastSelectedDealId => {
        if (lastSelectedDealId) {
          setTimeout(() => {
            this.scrollToDeal(lastSelectedDealId);
          }, 0);
        }
      },
    );
    this.isAllowToShowOtherDealsSubscription = this.dealListFacade.isAllowShowOtherDeals$.subscribe(
      isAllowToShowOtherDeals => {
        this.isAllowToShowOtherDeals = isAllowToShowOtherDeals;
      },
    );
  }

  ngOnInit(): void {
    this.maximizedTourPackagesList$.pipe(untilDestroyed(this)).subscribe(value => {
      this.maximizedTourPackagesList = value;
    });
  }

  getPlaceholderText(): string {
    if (this.isAllowToShowOtherDeals) {
      if (!this.otherDeals?.length) {
        return 'PAGES.DEALS.EMPTY_DEALS_LIST';
      }
    } else if (!this.todayDeals?.length) {
      return 'PAGES.DEALS.EMPTY_TODAY_DEALS_LIST';
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.list && changes.list.currentValue !== changes.list.previousValue) {
      this.filterByDay();
    }
  }

  filterByDay() {
    const isTodayDeal = (deal: CrmCardListPostSaleItem): boolean =>
      this.dealListService.isTodayDealPostSale(deal.tourPackages[0]);

    const dateComparator = (a: CrmCardListPostSaleItem, b: CrmCardListPostSaleItem): number =>
      new Date(a.tourPackages[0].tourPackage.dateFrom).getTime() -
      new Date(b.tourPackages[0].tourPackage.dateFrom).getTime();

    const sortedDeals: CrmCardListPostSaleItem[] = [...this.list].sort(dateComparator);

    this.todayDeals = sortedDeals.filter(isTodayDeal);
    this.otherDeals = sortedDeals.filter(deal => !isTodayDeal(deal));
  }

  toggleAllowToShowOtherDeal() {
    this.dealListFacade.toggleAllowToShowOtherDeal();
  }

  scrollToDeal(dealId: number) {
    const dealElement = this.el.nativeElement.querySelector(`#deal-${dealId}`);
    if (dealElement) {
      dealElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }
}
