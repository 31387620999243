import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { EMPTY, Subscription, timer } from 'rxjs';
import { catchError, filter, map, take, takeWhile, tap, timeout } from 'rxjs/operators';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';

// Константа для списка ключей советов
const ADVICE_KEYS = ['COMMENT'];

@Component({
  selector: 'app-zenmode-welcome-loading',
  templateUrl: './zenmode-welcome-loading.component.html',
  styleUrls: ['./zenmode-welcome-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZenmodeWelcomeLoadingComponent implements OnInit, OnDestroy {
  @Output() loadingCompleted: EventEmitter<void> = new EventEmitter<void>();
  public progressWidth = 0;
  public adviceKey: string;
  private readonly TOTAL_MILLISECONDS = 300;
  private readonly MAX_PROGRESS_WIDTH = 157;
  private loadingSubscription: Subscription = new Subscription();

  constructor(private cd: ChangeDetectorRef, private dealsFacade: DealsFacade) {}

  ngOnInit(): void {
    this.selectRandomAdvice();
    this.simulateLoading();
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

  private simulateLoading(): void {
    const INTERVAL = 10;
    const INCREMENT_PER_MILLISECOND = this.MAX_PROGRESS_WIDTH / this.TOTAL_MILLISECONDS;

    this.progressWidth = 0;

    const intervalSubscription = timer(0, INTERVAL)
      .pipe(
        map(elapsedMilliseconds => elapsedMilliseconds + INTERVAL),
        tap(elapsedMilliseconds => {
          this.progressWidth = Math.min(
            elapsedMilliseconds * INCREMENT_PER_MILLISECOND,
            this.MAX_PROGRESS_WIDTH,
          );
          this.cd.detectChanges();
        }),
        takeWhile(elapsedMilliseconds => elapsedMilliseconds < this.TOTAL_MILLISECONDS),
      )
      .subscribe({
        complete: () => {
          this.progressWidth = this.MAX_PROGRESS_WIDTH;
          this.waitForZenmodeList();
        },
      });

    this.loadingSubscription.add(intervalSubscription);
  }

  private waitForZenmodeList(): void {
    const TIMEOUT_SECONDS = 5;

    const zenmodeSubscription = this.dealsFacade.zenmodeList$
      .pipe(
        filter(list => list && list.length > 0),
        timeout(TIMEOUT_SECONDS * 1000),
        catchError(error => {
          console.error('Timeout waiting for zenmodeList', error);
          return EMPTY;
        }),
        take(1),
      )
      .subscribe(() => this.loadingCompleted.emit());

    this.loadingSubscription.add(zenmodeSubscription);
  }

  private selectRandomAdvice(): void {
    const randomIndex = Math.floor(Math.random() * ADVICE_KEYS.length);
    this.adviceKey = `PAGES.ZENMODE.LOADING.ADVICE.${ADVICE_KEYS[randomIndex]}`;
  }
}
