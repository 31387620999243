<div *ngIf="options" class="form-group form-group-select"
     [ngClass]="{ 'list-direction-up': isListDirectionUp}"

>
  <div class="popup-input-group" [ngClass]="{'custom-dots': options.length > 1}">
    <input
      (click)="open()"
      [placeholder]="placeholder"
      [value]="text"
      [ngClass]="hash"
      class="form-control"
      readonly
      type="text"
    />
    <div
      *ngIf="options.length >= 1"
      (clickOutside)="close()"
      [delayClickOutsideInit]="true"
      [exclude]="'input.form-control.'+ hash"
      class="items-list select-item-list"
      [ngClass]="{'list-direction-normal': !isListDirectionUp}">
      <ul  [ngClass]="{'list-direction-up': isListDirectionUp}">
        <li
          (click)="select(option)"
          *ngFor="let option of options"
          [class.selected-item]="option === selectedValue"
        >
          <span>{{ option[optionLabel] }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
