import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_INITIAL = 'АА';

@Pipe({
  name: 'getInitials',
})
export class GetInitialsPipe implements PipeTransform {
  transform(fullName: string): string {
    if (fullName) {
      const names = fullName.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }

      if (!initials.length) {
        return DEFAULT_INITIAL;
      }

      return initials;
    }
  }
}
