import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientAction } from '@api-clients/crm-api-client/models/index';
import { Observable } from 'rxjs';
import { ClientActionsOnSiteService } from './services/client-actions-on-site.service';

@Component({
  selector: 'app-client-actions-on-site',
  templateUrl: './client-actions-on-site.component.html',
  styleUrls: ['./client-actions-on-site.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientActionsOnSiteComponent implements OnInit {
  public actions$: Observable<ClientAction[]>;
  public readonly dateFormat = 'dd.MM.YY, HH:mm';
  public name = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { crmCardId: number; name: string },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClientActionsOnSiteComponent>,
    private clientActionsService: ClientActionsOnSiteService,
  ) {}

  ngOnInit(): void {
    if (this.data.crmCardId) {
      this.actions$ = this.clientActionsService.getClientActionsOnSiteByCrmCardId(this.data.crmCardId);
    }

    this.name = this.data?.name;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
