<p>
  <ng-container *ngIf="tours.length>1">{{'PAGES.DEALS.TOUR_PACKAGE.REQUESTS_IDS' | translate}}</ng-container>
  <ng-container *ngIf="tours.length==1">{{'PAGES.DEALS.TOUR_PACKAGE.REQUEST_ID' | translate}}</ng-container>
  <span>{{tours[0].operatorName | removeExtras }}</span>

  <a
    (click)="openRequest(tour.operatorClaimUrl)"
    class="request-id" *ngFor="let tour of tours; let i = index">
    {{tour.operatorRequestId}}
  </a>
</p>
