<div
  #messageText
  class="message-text"
  [ngClass]="{ show: showMore }"
  [innerHTML]="scheduledMessage.text | formatChatMessage | safeHtml"
></div>
<a *ngIf="!hideToggle" (click)="showMore = !showMore" class="show-more">
  <span class="text-span" *ngIf="showMore">{{ 'COMMON.HIDE' | translate }}</span>
  <span class="text-span" *ngIf="!showMore">{{ 'COMMON.SHOW_MORE' | translate }}</span>
</a>
