import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { PluralRuDirective } from '../helpers/directives/plural-ru.directive';
import { SecondsToStringPipe } from '../helpers/pipes/seconds-to-string.pipe';
import { PreloaderModule } from '../ui-components/preloader/preloader.module';
import { AdjustHeightDirective } from './directives/adjust-height.directive';
import { ResizableTextareaDirective } from './directives/resizable-textarea.directive';
import { PrimengComponentsModule } from './primeng-components/primeng-components.module';

@NgModule({
  declarations: [
    PluralRuDirective,
    SecondsToStringPipe,
    AdjustHeightDirective,
    ResizableTextareaDirective,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    PrimengComponentsModule,
    PreloaderModule,

    NgClickOutsideDirective,
  ],
  exports: [
    TranslateModule,
    FormsModule,
    PrimengComponentsModule,
    SecondsToStringPipe,
    PluralRuDirective,
    PreloaderModule,
    AdjustHeightDirective,
    ResizableTextareaDirective,
  ],
})
export class SharedModule {}
