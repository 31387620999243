<div class="reassign-step" *ngIf="isLoaded; else loading">

  <h3>{{TRANSLATE_PATH + "TITLE" | translate}}</h3>
  <p>{{TRANSLATE_PATH + "TIP" | translate}}</p>
  <form [formGroup]="form"

        (ngSubmit)="onSubmit()"
  >


    <div class="tabs" *ngIf="cities$ | async as cityItems">
      <ng-container
        *ngFor="let city of cityItems"
      >
        <button class="tab-item"
                (click)="selectCity(city.id, $event)"
             [ngClass]="{'active': city.id === selectedCityId}">
          {{city.name}}
        </button>
      </ng-container>

    </div>
    <div class="select-manager"  [ngClass]="{'selected': !!selectedManager}">
      <ng-select  *ngIf="managersList$ | async as managersList; else loading"
          [items]="managersList"
          [clearable]="false"
                  placeholder="{{TRANSLATE_PATH + 'SELECT_MANAGER' | translate}}"
          bindLabel="nameWithSip"
          bindValue="id"
          [notFoundText]="'COMMON.NO_RESULTS' | translate"
          [(ngModel)]="selectedManager"
          formControlName="assignedManagerId"
      >
      </ng-select>

      <ng-template #loading>
        <div class="loading">
           <span>{{TRANSLATE_PATH + "LOADING_MANAGERS" | translate}}</span> <mat-spinner diameter="30"></mat-spinner>
        </div>
      </ng-template>
    </div>

    <textarea formControlName="managerComment"
              class="comment-field"
              placeholder="{{TRANSLATE_PATH + 'COMMENT_PLACEHOLDER' | translate}}">

    </textarea>

    <div class="nav-buttons">
      <button class="btn secondary ghost"
              (click)="goBack()">{{"COMMON.NAV_BUTTONS.PREVIOUS" | translate}}</button>
      <button  class="btn secondary emphasis"
               [disabled]="!selectedManager"
               type="submit">{{"COMMON.NAV_BUTTONS.DONE" | translate}}</button>
    </div>

  </form>
</div>


 <ng-template #loading>
   <app-preloader></app-preloader>
 </ng-template>
