import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TourPackage, TourPackageStats } from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureToggleService } from 'app/modules/deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { LastReviewButtonComponent } from 'app/ui-components/last-review-button/last-review-button.component';
import { Observable } from 'rxjs';
import { SharedUtilityModule } from '../../../../../../../../../../../../libs/shared/utility/src';
import { AmplitudeTrackService } from '../../../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../../../core/services/amplitude/amplitudeEventData';
import {
  BOOKING_HISTORY_OPEN,
  BOOKING_PAGE_IN_TOUR_PACKAGE_DATABASE_OPEN,
} from '../../../../../../../../core/services/amplitude/amplitudeEvents';
import { ReservationsModalService } from '../../../../../client-reservations/services/reservations-modal.service';

@Component({
  selector: 'app-client-reservations',
  templateUrl: './client-reservations.component.html',
  styleUrls: ['./client-reservations.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SharedUtilityModule, LastReviewButtonComponent],
})
export class ClientReservationsComponent implements OnInit {
  public readonly screenType = ScreenTypes.DEAL_VIEW;
  public readonly dateFormat = 'dd.MM.YY';
  public newUi$: Observable<boolean>;

  @Input() tpStats: TourPackageStats;
  @Input() crmCardId: number;
  @Input() tourPackage: TourPackage;
  constructor(
    private amplitudeTrackService: AmplitudeTrackService,
    private reservationsModalService: ReservationsModalService,
    private featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this.newUi$ = this.featureToggleService.shouldDisplayNewFeature$;
  }

  showReservationsList() {
    this.amplitudeTrackService.trackEvent(BOOKING_HISTORY_OPEN, {
      screenType: this.screenType,
    });
    this.reservationsModalService.showReservationsModal(this.crmCardId);
  }

  reservationOpened() {
    this.amplitudeTrackService.trackEvent(BOOKING_PAGE_IN_TOUR_PACKAGE_DATABASE_OPEN, {
      screenType: this.screenType,
    });
  }
}
