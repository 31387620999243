import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TourPackageReviewItem } from '@api-clients/crm-api-client';

@Component({
  selector: 'app-last-review-modal',
  templateUrl: './last-review-modal.component.html',
  styleUrls: ['./last-review-modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LastReviewModalComponent implements OnInit {
  public review: TourPackageReviewItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { review: TourPackageReviewItem },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LastReviewModalComponent>,
  ) {}

  ngOnInit(): void {
    this.review = this.data.review;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
