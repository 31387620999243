import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vacation-dates',
  templateUrl: './vacation-dates.component.html',
  styleUrls: ['./vacation-dates.component.scss'],
})
export class VacationDatesComponent {
  @Input() dateFrom: string;
  @Input() dateTo: string;
}
