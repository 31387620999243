import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-phone-input-options-popup',
  templateUrl: './phone-input-options-popup.component.html',
  styleUrls: ['./phone-input-options-popup.component.scss'],
})
export class PhoneInputOptionsPopupComponent {
  @Output() editClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  closeOptions() {
    this.closeClicked.emit();
  }
}
