import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DealsFacade } from 'app/+state/deals/deals.facade';
import { Observable } from 'rxjs';
import { SortOption, SortType } from '../../../../deal-list';
import { DealListSortService } from '../../../../services/deal-list-sort.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-deal-list-sorting',
  templateUrl: './deal-list-sorting.component.html',
  styleUrls: ['./deal-list-sorting.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate('300ms ease', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate('300ms ease', style({ opacity: 0, transform: 'translateY(-10px)' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealListSortingComponent implements OnInit {
  public sortingOptions: SortOption[];
  public currentSortOption$: Observable<SortType>;
  public isDropdownOpen = false;

  constructor(private dealsFacade: DealsFacade, private dealListSortService: DealListSortService) {}

  ngOnInit(): void {
    this.sortingOptions = this.dealListSortService.getSortingOptions();
    this.currentSortOption$ = this.dealsFacade.sortType$;
  }

  public onSortOptionChange(sortType: SortType) {
    this.dealsFacade.changeDealsListSortType(sortType);
    this.closeDropdown();
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private closeDropdown() {
    this.isDropdownOpen = false;
  }
}
