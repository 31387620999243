import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AudioService {
  private audio: HTMLAudioElement;

  private isAudioPaused = new BehaviorSubject<void>(null);

  // у хендлера задан margin -8 px
  public handlePosPxToPercents(totalLength: number, position: number) {
    const res = ((position + 8) * 100) / totalLength;
    if (res < 0) {
      return 0;
    }
    if (res > 100) {
      return 100;
    }
    return res;
  }

  public updateTimeProgress(currentTime: number, duration: number) {
    const res = ((currentTime * 100) / duration).toFixed(3);
    return +res;
  }

  public handlePercentsToSec(width: number, percents: number) {
    return (width / 100) * percents;
  }

  public get isPaused(): Observable<void> {
    return this.isAudioPaused.asObservable();
  }

  public play(audio: HTMLAudioElement): void {
    // пауза для предыдущего аудио
    if (this.audio && !this.audio.paused) {
      this.audio.pause();
      this.isAudioPaused.next();
    }
    this.audio = audio;
    if (this.audio.paused) {
      this.audio
        .play()
        .then()
        // eslint-disable-next-line no-console
        .catch(reason => console.error(reason));
      this.isAudioPaused.next();
    }
  }

  public pause(): void {
    if (this.audio && !this.audio.paused) {
      this.audio.pause();
      this.isAudioPaused.next();
    }
  }
}
