import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { WorkersListComponent } from '../workers-list/workers-list.component';
import { ConfigsService } from '../../../../core/services';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import {
  MANAGERS_LIST_OPEN,
  NOTIFICATIONS_CENTER_OPEN,
  TOUR_DB_OPENED,
} from '../../../../core/services/amplitude/amplitudeEvents';
import { NotificationsCenterPopupService } from '../../../notifications-center/services/notifications-center-popup.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { RightSideMenuPopupService } from '../right-side-menu/services/right-side-menu-popup.service';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { ZenmodeListPopupService } from '../../../zenmode/services/zenmode-list-popup.service';
import { ZenmodeAbTestService } from '../../services/zenmode-ab-test.service';

@UntilDestroy()
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuComponent implements OnInit {
  public adminUrl: string;
  public readonly screenType: ScreenTypes = ScreenTypes.TOP_MENU;
  public popupOpen$ = this.rightSideMenuPopupService.popupOpen$;
  public zenModeOn$ = this.dealsFacade.zenmodeOn$;
  public isAvailableZenmode$ = this.zenmodeAbTestService.isAvailableZenmode$;
  isDealsRoute = true;

  isDevVersion = false;

  constructor(
    public matDialog: MatDialog,
    private configsService: ConfigsService,
    private amplitudeTrackService: AmplitudeTrackService,
    private notificationsCenterPopupService: NotificationsCenterPopupService,
    private rightSideMenuPopupService: RightSideMenuPopupService,
    private dealsFacade: DealsFacade,
    private zenmodeListPopupService: ZenmodeListPopupService,
    private zenmodeAbTestService: ZenmodeAbTestService,
    private router: Router,
  ) {
    this.adminUrl = this.configsService.adminUrl;
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.router.routerState.snapshot.url === '/deals') {
        this.isDealsRoute = true;
      } else {
        this.isDealsRoute = false;
      }
    });

    this.isDevVersion = window.location.toString().includes('stage-gera');
  }

  showManagersList(): void {
    this.amplitudeTrackService.trackEvent(MANAGERS_LIST_OPEN);
    this.matDialog.open(WorkersListComponent, {
      id: 'workers-list-component',
      disableClose: false,
      hasBackdrop: true,
      width: '100%',
      maxWidth: 'calc(100vw-90px)',
      height: 'calc(100vh - 100px)',
      maxHeight: '100%',
      position: { top: '80px', left: '105px' },
    });
  }

  showNotificationsPopup() {
    this.notificationsCenterPopupService.showPopup();
    this.amplitudeTrackService.trackEvent(NOTIFICATIONS_CENTER_OPEN, {
      screenType: this.screenType,
    });
  }

  toggleMenu() {
    this.rightSideMenuPopupService.toggleMenu();
  }

  showZenModeIntro() {
    this.dealsFacade.showZenModeIntro(true);
  }

  openZenmodeList() {
    this.zenmodeListPopupService.showPopup();
  }

  tourDBOpened() {
    this.amplitudeTrackService.trackEvent(TOUR_DB_OPENED, {
      screenType: this.screenType,
    });
  }

  changeVersionToProduction() {
    (window as any).location = 'https://gera.ht.kz';
  }
}
