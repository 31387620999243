import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-video',
  templateUrl: './message-video.component.html',
  styleUrls: ['./message-video.component.scss'],
})
export class MessageVideoComponent {
  @Input() mediaSource: string;
  @Input() text: string;
  @Input() isFromMe: boolean;
  public isShow = false;
  showVideo() {
    this.isShow = true;
  }
}
