import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[itemInView]',
  standalone: true,
})
export class ItemInViewDirective implements OnInit, OnDestroy {
  @Output() inView = new EventEmitter<boolean>(false);
  private observer: IntersectionObserver | null = null;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.inView.emit(true);
        } else {
          this.inView.emit(false);
        }
      });
    });

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
