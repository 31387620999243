<div *ngIf="notify" [ngClass]="notify.type" [@inOutAnimation] class="notify">
  <div class="header">
    <div class="title">
      <img *ngIf="notify.type !== 'simple'" src="./assets/icons/notify/{{ notify.type }}.svg" alt="" />
      <h5>{{ notify.title }}</h5>
    </div>
    <button (click)="close()" class="close">
      <img src="./assets/icons/notify/close.svg" alt="" />
    </button>
  </div>
  <div class="body">
    <ng-container *ngIf="notify?.textList as texts; else singleText">
      <ul>
        <li *ngFor="let text of texts" class="text">{{ text }}</li>
      </ul>
    </ng-container>
    <ng-template #singleText>
      <p class="text">{{ notify.text }}</p>
    </ng-template>
  </div>
</div>
