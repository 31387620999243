<div class="search-history">
  <img src="assets/icons/timeline/search.svg"
       alt="search-history-item"
       class="icon">
  <div class="search-history-content">
    <span class="header-title">
      {{'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.SEARCH_HISTORY_TITLE' | translate}}
      <ng-container *ngIf="searchRequest?.worker?.name as name">
        ({{name}})
      </ng-container>

    </span>

    <div class="text">
      {{searchRequestText}}
    </div>
    <div class="budget"
         *ngIf="searchRequest.budgetFrom
                && searchRequest.budgetTo">
      {{searchRequest.budgetTo}} -
      {{searchRequest.budgetFrom}}
    </div>
  </div>
</div>
