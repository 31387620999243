<div class="info-wrapper">
  <div class="worker-info" *ngIf="worker">
  <span class="worker">
    <img src="assets/icons/deal-view/worker.svg" alt="worker">
    <span class="label">В работе с</span>
    <p class="worker-name">
      {{worker?.name}}
    </p>
    <p class="role" *ngIf="worker.role">
      {{'PAGES.DEALS.WORKER_ROLES.' + worker.role | translate}}
    </p>
  </span>
  </div>

</div>

