import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HotOffersService } from '../../hot-offers.service';
import {
  HotOffersFiltersVariantsComponent
} from './components/filters-variants/hot-offers-filters-variants.component';
import {
  HotOffersShortOffersModalComponent
} from './components/short-offers/hot-offers-short-offers-modal.component';

@Component({
  selector: 'app-hot-offers-filters-modal',
  templateUrl: './hot-offers-filters-modal.component.html',
  styleUrls: ['./hot-offers-filters-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    HotOffersFiltersVariantsComponent,
    HotOffersShortOffersModalComponent
],
})
export class HotOffersFiltersModalComponent implements OnInit, OnDestroy {
  departCities$ = this.hotOffersService.toursByDepartCities$;
  departCityId: number;

  private initServiceSubscription: Subscription;
  private departCitySubscription: Subscription;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly hotOffersService: HotOffersService,
  ) {
  }

  ngOnInit() {
    this.initServiceSubscription = this.hotOffersService.initState$()
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.departCitySubscription = this.hotOffersService.activeDepartCity$.subscribe(departCity => {
      this.departCityId = departCity.departCityId;
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.initServiceSubscription?.unsubscribe();
    this.departCitySubscription?.unsubscribe();
  }

  changeDepartCity(departCityId: number) {
    this.departCityId = departCityId;
    this.hotOffersService.changeDepartCity(departCityId);
  }
}
