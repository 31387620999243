import { Injectable } from '@angular/core';
import { CrmTaskType } from '@api-clients/crm-api-client';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompleteDealTypeEnum } from '../../../models/complete-deal-type.model';
import { DealViewService } from '../../../services/deal-view.service';
import { CurrentStageEnum } from '../../sales-funnel-stages/sales-funnel-stages';
import {
  CreateTaskModalCheckedStage,
  CreateTaskModalCheckedTaskType,
  CreateTaskModalCompleteType,
  CreateTaskModalStep,
  StepsId,
} from '../create-task-modal/create-task-modal';

@Injectable({
  providedIn: 'root',
})
export class CreateTaskService {
  constructor(private dealViewService: DealViewService) {}

  private checkedStage = new BehaviorSubject<CreateTaskModalCheckedStage>({
    stage: '',
  });
  public checkedStageValue = this.checkedStage.asObservable();

  private checkedTaskType = new BehaviorSubject<CreateTaskModalCheckedTaskType>({
    taskType: '',
  });
  public checkedTaskTypeValue = this.checkedTaskType.asObservable();

  private selectedCompleteType = new BehaviorSubject<CreateTaskModalCompleteType>({
    completeType: '',
  });
  public selectedCompleteTypeValue = this.selectedCompleteType.asObservable();

  private currentStep = new BehaviorSubject<CreateTaskModalStep>({
    step: 1,
    id: StepsId.CHOOSE_ACTION,
  });
  currentStepValue = this.currentStep.asObservable();

  private previousStep = new BehaviorSubject<CreateTaskModalStep>({
    step: 1,
    id: StepsId.CHOOSE_ACTION,
  });
  previousStepValue = this.previousStep.asObservable();

  setStep(step: StepsId) {
    this.currentStep.next({
      step: 2,
      id: step,
    });
  }
  goBack() {
    this.currentStep.next({
      step: 1,
      id: StepsId.CHOOSE_ACTION,
    });
  }

  goResult() {
    this.currentStepValue.pipe(take(1)).subscribe(step => {
      this.previousStep.next(step);
    });

    this.currentStep.next({
      step: 3,
      id: StepsId.RESULT,
    });

    this.dealViewService.refreshDealView();
  }

  setCheckedStage(stage: CurrentStageEnum) {
    this.checkedStage.next({
      stage,
    });
  }

  setCheckedTaskType(taskType: CrmTaskType) {
    this.checkedTaskType.next({
      taskType,
    });
  }

  setCompleteType(completeType: CompleteDealTypeEnum) {
    this.selectedCompleteType.next({
      completeType,
    });
  }

  reset() {
    this.currentStep.next({
      step: 1,
      id: StepsId.CHOOSE_ACTION,
    });
    this.previousStep.next({
      step: 1,
      id: StepsId.CHOOSE_ACTION,
    });
    this.checkedStage.next({
      stage: '',
    });
    this.checkedTaskType.next({
      taskType: '',
    });
    this.selectedCompleteType.next({
      completeType: '',
    });
  }
}
