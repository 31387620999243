import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrmTaskCloseRequest, CrmTaskCreateNextRequest, DefaultResponse } from '@api-clients/crm-api-client';
import { CrmTaskCreateNextResponse } from '@api-clients/crm-api-client/dist/models/crm-task-create-next-response';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { AppConfig } from '../../../../../../environments/environment';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';

@Injectable({
  providedIn: 'root',
})
export class CrmTaskApiService {
  constructor(private http: HttpClient) {}

  crmTaskCreateNext(body: CrmTaskCreateNextRequest) {
    return this.http
      .post<ApiResponse<CrmTaskCreateNextResponse>>(`${AppConfig.apiUrl}/crm-task/create-next`, body)
      .pipe(apiResponsePipe);
  }

  crmTaskClose(body: CrmTaskCloseRequest) {
    return this.http
      .post<ApiResponse<DefaultResponse>>(`${AppConfig.apiUrl}/crm-task/close`, body)
      .pipe(apiResponsePipe);
  }
}
