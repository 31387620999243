import { Component, Input, OnInit } from '@angular/core';
import { TourPackageClaimNotification } from '@api-clients/crm-api-client/dist/models';

@Component({
  selector: 'app-tour-package-docs-list-changed',
  templateUrl: './tour-package-docs-list-changed.component.html',
  styleUrls: ['./tour-package-docs-list-changed.component.scss'],
})
export class TourPackageDocsListChangedComponent implements OnInit {
  @Input() notification: TourPackageClaimNotification;

  ngOnInit(): void {}
}
