import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../../shared/shared.module';
import { FormErrorModule } from '../../ui-components/form-error/form-error.module';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  declarations: [LoginComponent, LogoutComponent],
  imports: [ReactiveFormsModule, CommonModule, SharedModule, FormErrorModule],
})
export class LoginModule {}
