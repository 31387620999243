import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatsState, FEATURE_KEY } from './chats.reducer';

// Feature Selector
export const selectChatState = createFeatureSelector<ChatsState>(FEATURE_KEY);

// Selectors
export const selectAllChatContacts = createSelector(
  selectChatState,
  (state: ChatsState) => state.chatContacts,
);

export const selectCurrentChatContact = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContact,
);

export const selectChatRecommendation = createSelector(
  selectChatState,
  (state: ChatsState) => state.chatRecommendation,
);

export const selectCurrentChatContactCrmCard = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactCrmCard,
);

export const selectCurrentChatContactCrmCardLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactCrmCardLoading,
);

export const selectCurrentChatContactNextTaskLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactNextTaskLoading,
);

export const selectChatContactsLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.loading,
);

export const selectChatContactsError = createSelector(selectChatState, (state: ChatsState) => state.error);

export const selectSearchResults = createSelector(
  selectChatState,
  (state: ChatsState) => state.searchResults,
);

export const selectIsSearching = createSelector(selectChatState, (state: ChatsState) => state.isSearching);
