import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[appAutofocus]',
  standalone: true,
})
export class AutoFocusDirective implements OnInit, OnDestroy {
  @Input('appAutofocus') shouldFocus$: Observable<boolean>;

  private focusSub: Subscription;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.shouldFocus$) {
      this.focusSub = this.shouldFocus$.subscribe(() => {
        this.applyFocus();
      });
    } else {
      this.el.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.focusSub?.unsubscribe();
  }

  private applyFocus() {
    const isFocused = document.activeElement === this.el.nativeElement;
    if (this.shouldFocus$ && !isFocused) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 0);
    }
  }
}
