import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SmsTemplate } from '@api-clients/crm-api-client';
import { forkJoin, Observable } from 'rxjs';
import { Worker } from '../../../../../../models';
import { SmsService } from '../../../../../sms/services/sms.service';
import { SMS_TYPES } from '../../../../../../models/sms.model';
import { OfficeService } from '../../../../../sms/services/office.service';
import { OpenManagerSearchService } from '../../../../../../shared/services/open-manager-search.service';
import { WorkerStateService } from '../../../../../../core/services';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { SMS_TEMPLATE_SEND } from '../../../../../../core/services/amplitude/amplitudeEvents';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { TourSelectionFacade } from 'app/+state/tour-selection/tour-selection.facade';

const HOW_TO_GET = 'howToGet';
const HOW_TO_GET_KEY = '{{sms_info}}';

@Component({
  selector: 'app-attach',
  templateUrl: './attach.component.html',
  styleUrls: ['./attach.component.scss'],
})
export class AttachComponent implements OnInit {
  @Input() cardId: number;
  @Input() searchLink: string;
  @Output() attachEvent = new EventEmitter();
  @Output() clickEvent = new EventEmitter();
  @Output() selectTemplateEvent = new EventEmitter<SmsTemplate>();
  @ViewChild('fileInput') fileInput: ElementRef;
  public isShowMessageTemplates = false;
  public worker: Worker;
  public smsTemplates: SmsTemplate[];
  public currentOfficeAddress: string;
  public workerOfficeId: number;
  public SMS_TYPES = SMS_TYPES;
  public defaultSearchLink: string;
  public searchLinkBySearchRequest: string;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public readonly isTourSelectionOpen$: Observable<boolean> = this.tourSelectionFacade.isTourSelectionOpen$;
  constructor(
    private openManagerSearchService: OpenManagerSearchService,
    public workerStateService: WorkerStateService,
    private smsService: SmsService,
    private officeService: OfficeService,
    private amplitudeTrackService: AmplitudeTrackService,
    private tourSelectionFacade: TourSelectionFacade,
  ) {}

  public openTourSelection() {
    this.tourSelectionFacade.openTourSelection();
  }

  onFileSelected(event: Event) {
    this.attachEvent.emit(event);
  }
  onClicked() {
    this.clickEvent.emit();
  }

  showTemplates() {
    this.isShowMessageTemplates = true;
  }

  loadMessageTemplatesData() {
    return forkJoin([
      this.smsService.getSMSTemplates(this.worker.cityId),
      this.officeService.getOfficesByCityId(this.worker.cityId),
    ]).subscribe(([templates, offices]) => {
      this.smsTemplates = templates;
      for (const office of offices) {
        this.currentOfficeAddress = office.smsAddress;
        if (this.workerOfficeId === office.id) {
          this.currentOfficeAddress = office.smsAddress;
        }
      }
      // Вставляет адресс в шаблон
      for (const template of this.smsTemplates) {
        if (template.type === HOW_TO_GET && this.currentOfficeAddress) {
          template.text = template.text.replace(HOW_TO_GET_KEY, this.currentOfficeAddress);
        }
      }
    });
  }

  ngOnInit(): void {
    this.worker = this.workerStateService.currentWorkerValue;
    this.workerOfficeId = this.worker.office.id;
    this.defaultSearchLink = this.openManagerSearchService.getUrlToOpenManagerSearch(this.cardId, false);
    this.searchLinkBySearchRequest = this.openManagerSearchService.getUrlToOpenManagerSearchBySearchLink(
      this.searchLink,
    );
    this.loadMessageTemplatesData();
  }

  onSelectTemplate(template: SmsTemplate) {
    this.selectTemplateEvent.emit(template);
    this.amplitudeTrackService.trackEvent(SMS_TEMPLATE_SEND, {
      templateType: template.type,
      screenType: this.screenType,
    });
    this.isShowMessageTemplates = false;
  }

  triggerFileInput(event: Event) {
    if (event.target !== this.fileInput.nativeElement) {
      this.fileInput.nativeElement.click();
    }
  }
}
