import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClientAction } from '@api-clients/crm-api-client/models/index';
import { Observable } from 'rxjs';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientActionsOnSiteService {
  constructor(private http: HttpClient) {}

  getClientActionsOnSiteByCrmCardId(crmCardId: number): Observable<ClientAction[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('crmCardId', `${crmCardId}`);
    return this.http
      .get<ApiResponse<ClientAction[]>>(`${AppConfig.apiUrl}/crm-card/list-last-actions`, {
        params: queryParams,
      })
      .pipe(apiResponsePipe);
  }
}
