<div  class='grid-table-row'>
  <div class='grid-table-row__column'>
    <label class="radio-container" >
      <input type="radio" name="mark"  (click)='setTourPackageId()'>
      <span class="checkmark"></span>
    </label>
  </div>
  <div class='grid-table-row__column'>
    <span class='column-item'>
      {{ tourPackage.id }}
    </span>
  </div>

  <div class='grid-table-row__column'>
    <a  (click)='closeTourPackagesList()' *ngIf='tourPackage.payer.firstName || tourPackage.payer.secondName;
        else emptyName' [routerLink]="['/deals/view', tourPackage.crmCard]"  class='column-item'>
      {{ tourPackage.payer.firstName }} {{ tourPackage.payer.secondName }}
    </a>
    <ng-template #emptyName >
      <a (click)='closeTourPackagesList()' [routerLink]="['/deals/view', tourPackage.crmCard]" class='column-item'>
        <label class="hidden-phone-label">
          {{ tourPackage.payer.phone.code }}
          <input
            class="hidden-phone"
            type="text"
            [hiddenInput]="isPhoneHidden"
            [mask]="secretMask"
            [readOnly]="true"
            [ngModel]="tourPackage.payer.phone.phone"
          />
        </label>
        <span (click)="isPhoneHidden = !isPhoneHidden" class="toggle-phone-visibility">
            <img src='assets/icons/deal-view/hide-icon.svg' alt="" />
        </span>
      </a>
    </ng-template>

  </div>

  <div class='grid-table-row__column'>
    <span class='column-item'>
      с {{ tourPackage.dateFrom |  date: dateFormat | lowercase }} по {{ tourPackage.dateTo  |  date: dateFormat | lowercase }}
    </span>
  </div>
  <div class='grid-table-row__column'>
    <span  class='column-item'>
      <label class="hidden-phone-label">
          {{ tourPackage.payer.phone.code }}
        <input
          class="hidden-phone"
          type="text"
          [hiddenInput]="isPhoneHidden"
          [mask]="secretMask"
          [readOnly]="true"
          [ngModel]="tourPackage.payer.phone.phone"
        />
          </label>
          <span (click)="isPhoneHidden = !isPhoneHidden" class="toggle-phone-visibility">
            <img src='assets/icons/deal-view/hide-icon.svg' alt="" />
          </span>
    </span>
  </div>
  <div class='grid-table-row__column'>
    <span class='column-item hotels'>
      {{ hotels }}
    </span>
  </div>
</div>
