import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WhatsappContactsFacade } from '../../../+state/whatsapp-contacts/whatsapp-contacts.facade';
import { Chat, SelectedChat } from '../../../+state/whatsapp-contacts/whatsapp-contacts.interfaces';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent implements OnInit {
  public loadedChats: Chat[];
  public selectedChat: SelectedChat = {
    contactId: null,
    crmCardId: null,
    phone: null,
  };
  public currentChatCrmId: number;
  constructor(private whatsappContactsFacade: WhatsappContactsFacade, private route: ActivatedRoute) {
    this.currentChatCrmId = +this.route.snapshot.paramMap.get('id');
  }

  subscribeToChats() {
    this.whatsappContactsFacade.loadedChats$.subscribe((chats: Chat[]) => {
      this.loadedChats = chats;
    });
    this.whatsappContactsFacade.selectedChat$.subscribe((chat: SelectedChat) => {
      this.selectedChat = chat;
    });
  }

  ngOnInit(): void {
    this.subscribeToChats();
  }
}
