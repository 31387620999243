import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhoneItem } from '@api-clients/crm-api-client';
import { SmsSendComponent } from '../components/sms-send/sms-send.component';
import { ScreenTypes } from '../../../core/services/amplitude/amplitudeEventData';

@Injectable({
  providedIn: 'root',
})
export class SmsPopupService {
  constructor(public matDialog: MatDialog) {}

  showSMSPopup(phone?: PhoneItem, screenType?: ScreenTypes): void {
    this.matDialog.open(SmsSendComponent, {
      id: 'sms-popup',
      disableClose: true,
      hasBackdrop: true,
      width: '480px',
      minHeight: '360px',
      data: {
        phone: phone || null,
        screenType,
      },
    });
  }
}
