<div class="hotels-list">
  @if (hotelsAndTours$ | async; as hotelsAndTours) {
    @for (result of hotelsAndTours | slice:0:visibleCount; track result) {
      <app-search-hotel
        [hotel]="result.hotel"
        [initSearchTours]="result.tours" />
    }
    @if (hotelsAndTours.length > visibleCount) {
      <div class="show-more-btn"
        (click)="showMore()">
        Показать еще отели
      </div>
    }
    @if (hotelsAndTours.length <= visibleCount && hotelsAndTours.length !== 1) {
      <div class="show-more-btn"
        (click)="hide()">
        Скрыть отели
      </div>
    }
  }
</div>
