import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TourpackagesForMarkAsAudioreviewComponent } from '../tourpackages-for-mark-as-audioreview.component';

@Injectable({
  providedIn: 'root',
})
export class TourPackagesWithoutAudioReviewPopUpService {
  constructor(public matDialog: MatDialog) {}

  tourPackagesForMarkAsAudioReview(callId: number): void {
    this.matDialog.open(TourpackagesForMarkAsAudioreviewComponent, {
      id: 'tour-packages-for-mark-as-audio-review-popup',
      disableClose: true,
      hasBackdrop: true,
      width: '100%',
      maxWidth: '94vw',
      height: '66wv',
      maxHeight: '100%',
      data: {
        callId,
      },
    });
  }
}
