import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WorkerItem } from '@api-clients/crm-api-client/dist/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-worker-info',
  templateUrl: './worker-info.component.html',
  styleUrls: ['./worker-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class WorkerInfoComponent {
  @Input() worker: WorkerItem;
  constructor() {}
}
