import { Component, Input, OnInit } from '@angular/core';
import { CallItem } from '@api-clients/crm-api-client/dist/models';
import { AudioType } from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/interfaces/audio.interface';
import { CallTypes } from '../../../../interfaces/chat-timeline.interface';
import { ConfigsService } from '../../../../../../core/services';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-chat-timeline-item-call',
  templateUrl: './chat-timeline-item-call.component.html',
  styleUrls: ['./chat-timeline-item-call.component.scss'],
})
export class ChatTimelineItemCallComponent implements OnInit {
  public CALL_TYPES = CallTypes;
  @Input() callItem: CallItem;
  public isPhoneHidden = true;
  public readonly dateFormat = 'dd.MM.YY, HH:mm';
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public secretMask: string;
  public readonly audioCallType = AudioType.call;

  constructor(private configsService: ConfigsService) {
    this.secretMask = this.configsService.getPhoneHiddenMaskByCode(this.callItem?.phone?.code);
  }

  ngOnInit(): void {}
}
