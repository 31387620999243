<div class="dropdown-items">
  <div class="dropdown-item"
    (click)="editClicked.emit()"
       (clickOutside)="closeOptions()"
       [exclude]="'input.form-control, div.dots, img.dots-img'"
  >
    <img src="assets/icons/deal-view/edit.svg" alt="edit" class="icon">
    <span>{{'PAGES.DEALS.PHONES.OPTIONS.EDIT' | translate}}</span>
  </div>
  <div class="dropdown-item"
       (click)="removeClicked.emit()"
  >
    <img src="assets/icons/deal-view/trash.svg" alt="remove" class="icon">
    <span>{{'PAGES.DEALS.PHONES.OPTIONS.REMOVE' | translate}}</span>
  </div>
</div>
