import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IndicatorType } from '../models/indicator-payload.model';

@Injectable({ providedIn: 'root' })
export class IndicatorEventService {
  private indicatorEvent = new BehaviorSubject<IndicatorType | null>(null);

  public indicatorEventObservable = this.indicatorEvent.asObservable();

  sendIndicatorEvent(type: IndicatorType) {
    this.indicatorEvent.next(type);
  }
}
