import { Injectable } from '@angular/core';
import { CardChangedEventDetail } from '@api-clients/crm-api-client';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CardUpdaterService {
  private cardUpdates = new Subject<CardChangedEventDetail>();
  public cardUpdates$ = this.cardUpdates.asObservable();

  public pushCardUpdates(payload: CardChangedEventDetail) {
    this.cardUpdates.next(payload);
  }
}
