<div #modal class="summary">
  <button type="button"
          class="btn-send"
          [class.disabled]="searchInProgress()"
          [disabled]="searchInProgress()"
          (click)="sendOffers()">
    @if (searchInProgress()) {
      <img class="search-tours-loader-icon" src="assets/icons/search/hotel-search-tours-loader-white.svg">
      Пересчитываем туры...
    } @else {
      <img class="send-message-icon" src="/assets/icons/content-creator/send-message.svg">
      Отправить на whatsapp туры
    }
  </button>
</div>
