import { Injectable } from '@angular/core';
import {
  CrmCardViewItem,
  CrmTaskIntent,
  CrmTaskType,
  NotificationType,
} from '@api-clients/crm-api-client/dist/models';
import { CurrentCallItem } from '@api-clients/crm-api-client';
import {
  CurrentCallItemClientLanguageEnum,
  CurrentCallItemTypeEnum,
} from '@api-clients/crm-api-client/models/current-call-item';
import { TourPackageNotification } from '@api-clients/crm-api-client/models/tour-package-notification';
import { CallNotification, CreateNotifyByIdEvent } from '../../../../../models/ipcEvent';

// const { exec } = require('child_process');

@Injectable({
  providedIn: 'root',
})
export class SettingsHelperService {
  openUrlWithDefaultBrowser(url) {
    // switch (process.platform) {
    //   case 'darwin':
    //     exec(`open ${url}`);
    //     break;
    //   case 'win32':
    //     exec(`start ${url}`);
    //     break;
    //   default:
    //     exec(`xdg-open ${url}`);
    // }
  }

  getFakeCrmCardViewItem(): CrmCardViewItem {
    return {
      deal: {
        id: 101,
        brandId: 1,
        isReturnTourist: false,
        clientSourceId: 141,
        crmId: 101,
        currentPhone: {
          code: '+7',
          phone: '7054747039',
        },
        worker: {
          id: 101,
          employeeId: 101,
          siteId: 23,
          cityId: 3,
          username: 'markbrut',
          name: 'Магжан Магжанович',
          firstName: 'Магжан',
          role: 'manager',
          sip: '1101',
        },
        status: 0,
        stage: 'new',
        isAutoActionsDisabled: false,
        createDate: '2023-04-26 16:14:20',
        finishDate: '2023-04-26 16:18:45',
        finishComment: '',
        cancelReason: 'cold call',
        isLastTouchToday: false,
        isNew: false,
        conversationStatus: 0,
      },
      card: {
        id: 101,
        name: 'Супер длинное имя, Admin, Admin, Admin, Admin, Супер длинное имя, Admin',
        phone: '7999999999',
        mobilePhone: '',
        phone3: '',
        phone4: '',
        phone5: '',
        phone6: '',
        phone7: '',
        phones: [
          {
            code: '+7',
            phone: '7999999999',
          },
        ],
        mobilePhones: [
          {
            code: '+7',
            phone: '7999999999',
          },
        ],
        info: '',
        operatorComment: '',
        email: '',
        isConversionExclude: false,
        isCorporate: false,
        lastInfoUpdate: '2023-04-15 12:37:22',
        isReturnTourist: false,
        tpStats: {
          count: 0,
          sum: 0,
          onlineBookingDepartmentActiveCount: 0,
          travelCount: 0,
          travelSum: 0
        },
        actionsCount: 0,
        link: 'https://example.com',
      },
      lastAction: null,
      tasks: [],
      lastTasks: [
        {
          id: 2195364,
          type: CrmTaskType.Urgent,
          intent: CrmTaskIntent.ChangeDate,
          managerComment: 'регистрация в приложении',
          resultComment: 'Менеджер: Боронина Селин. Причина: Исключение из конверсии.\n',
          createdAt: '2023-04-26 16:14:20',
          assignedAt: '2023-04-26 16:14:20',
          closedAt: '2023-04-26 16:18:45',
          date: '2023-04-26',
          time: '16:14:00',
          isExpired: true,
          duration: 30,
          worker: {
            id: 132098,
            employeeId: 1284,
            siteId: 23,
            cityId: 3,
            username: 'markbrut',
            name: 'Программист Программистов',
            firstName: 'Программист',
            role: 'manager',
            sip: '1101',
          },
          phone: '7054747039',
        },
      ],
      lastSmsList: [
        {
          id: 101,
          brandId: 1,
          phone: {
            code: '+7',
            phone: '7054747039',
          },
          text: 'Test sms',
          addDate: '2023-04-26 16:18:35',
          worker: {
            id: 101,
            employeeId: 101,
            siteId: 23,
            cityId: 3,
            username: 'markbrut',
            name: 'Программист Программов',
            firstName: 'Программист',
            role: 'manager',
            sip: '1101',
          },
        },
      ],
      tourPackages: [],
      calls: [
        {
          id: 101,
          dateTime: '2023-04-26 16:18:15',
          answer: false,
          duration: 0,
          phone: {
            code: '+7',
            phone: '7999999999',
          },
          worker: {
            id: 101,
            employeeId: 101,
            siteId: 23,
            cityId: 3,
            username: 'markbrut',
            name: 'Данияр Даниярович',
            firstName: 'Данияр',
            role: 'manager',
            sip: '1101',
          },
          crmCardId: 101,
          cityId: 3,
          callType: 1,
          code: 2111,
          hasRecord: false,
          recordingFileLink: 'https://example.com',
        },
      ],
      searchRequests: [],
      notes: [],
      lastWhatsappMessages: [],
      currentCall: {
        cdrId: null,
        id: 'x0b90295-de9d-414e-ba6a-8a202e3c7f44',
        type: 'Inbox' as CurrentCallItemTypeEnum,
        currentPhone: {
          code: '+7',
          phone: '9999999999',
        },
        isAnswer: false,
        startTime: '2023-04-26 16:30:03',
        clientLanguage: null,
      },
    };
  }

  getCurrentCallItem(fromApp = false): CurrentCallItem {
    let sourceInfo = null;
    if (fromApp) {
      sourceInfo = {
        site: 0,
        cityId: 3,
        sourceId: 137,
        title: 'Звонок из приложения',
      };
    }
    return {
      cdrId: null,
      id: 'aeca1037-6521-4932-97f1-28fd79e49f57',
      type: 'Inbox' as CurrentCallItemTypeEnum,
      currentPhone: {
        code: '+7',
        phone: '7762729160',
      },
      isAnswer: false,
      startTime: '2023-06-01 14:29:55',
      clientLanguage: 'ru' as CurrentCallItemClientLanguageEnum,
      sourceInfo,
    };
  }

  getFakeCallNotification(withDealView = true, fromApp = false): CallNotification {
    const fakeCurrentCall: CurrentCallItem = this.getCurrentCallItem(fromApp);

    const fakeCrmCardViewItem: CrmCardViewItem = withDealView ? this.getFakeCrmCardViewItem() : null;

    return {
      from: '+77765654932',
      currentCall: fakeCurrentCall,
      dealView: fakeCrmCardViewItem,
      timer: 0,
      uid: '55',
      secretMask: '55',
      lastCall: '55',
    };
  }

  getFakeTourPackageDocsReadyNotification(): CreateNotifyByIdEvent {
    const event = NotificationType.TourPackageDocsReady;
    const notification: TourPackageNotification = {
      id: 101,
      type: NotificationType.TourPackageDocsReady,
      isNew: true,
      createdAt: new Date().toDateString(),
      tourPackageId: 208581,
      payerName: 'Алмат Алматов Алматович',
      viewLink: 'https://google.com',
    };
    const countdown = 0;

    return {
      event,
      notification,
      countdown,
    };
  }
}
