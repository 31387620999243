<div *ngIf="managersList" class="complaint-assign top-border-line">
  <h5 class="title">Назначение задачи</h5>
  <div class="select-manager-wrap">
    <app-select
      [placeholder]="'Выберите менеджера'"
      [options]="managersList"
      [optionLabel]="'name'"
      [optionValue]="'id'"
      [hash]="'intent'"
      class="select-manager content-width-control"
    ></app-select>
    <button class="btn --green">Применить</button>
  </div>
</div>
