import { DatePipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  forwardRef,
  input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToursCalendar } from '@api-clients/api-client';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { Subscription } from 'rxjs';

export interface Cell {
  index: number;
  date?: Date;
  dateYMD: string;
  dayNumber: number;
  otherMonth: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-favorite-hotels-search-form-calendar',
  templateUrl: './favorite-hotels-search-form-calendar.component.html',
  styleUrls: ['./favorite-hotels-search-form-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DatePipe, ReactiveFormsModule, DecimalPipe, NgClickOutsideDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FavoriteHotelsSearchFormCalendarComponent),
    },
  ],
})
export class FavoriteHotelsSearchFormCalendarComponent implements OnInit, OnDestroy, ControlValueAccessor {
  priceCalendars = input<ToursCalendar>();

  prices = computed(() => {
    const calendar = this.priceCalendars();
    const prices = {};

    calendar?.prices.forEach(value => {
      prices[value.date] = value;
    });

    return prices;
  });

  form: FormGroup;

  today: Date;
  calendarCreatedDate: Date;
  tableRows: Cell[][] = [];

  startDate: Date;
  stopDate: Date;
  startDragDate: Date;
  stopDragDate: Date;

  selectedRangeLabel: string;

  private formValueChangesSubscription: Subscription;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdRef: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      from: new FormControl<Date | null>(null, { validators: [Validators.required] }),
      to: new FormControl<Date | null>(null, { validators: [Validators.required] }),
    });

    this.formValueChangesSubscription = this.form.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });

    this.today = new Date();
    if (!this.calendarCreatedDate) {
      this.calendarCreatedDate = this.dateFrom ? this.dateFrom : this.today;
    }

    if (this.dateFrom) {
      this.startDate = this.dateFrom;
      this.startDragDate = this.dateFrom;
    }
    if (this.dateTo) {
      this.stopDate = this.dateTo;
      this.stopDragDate = this.dateTo;
    }

    this.createSelectedRangeLabel();

    this.createMonthTable(this.calendarCreatedDate);
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.formValueChangesSubscription?.unsubscribe();
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(value: any): void {
    if (value) {
      const { from, to } = value;

      this.calendarCreatedDate = from;
      this.startDate = from;
      this.startDragDate = from;
      this.stopDate = to;
      this.stopDragDate = to;
      this.createSelectedRangeLabel();

      this.createMonthTable(this.calendarCreatedDate);

      this.form.setValue(value, { emitEvent: false });

      this.cdRef.detectChanges();
    } else {
      this.form.reset();
    }
  }

  get dateFrom(): Date {
    return this.form.get('from').value;
  }

  get dateTo(): Date {
    return this.form.get('to').value;
  }

  selectNextMonth(): void {
    const current = this.calendarCreatedDate;
    let year = current.getFullYear();
    let month = current.getMonth();

    if (month === 11) {
      month = 0;
      year++;
    } else {
      month++;
    }

    this.calendarCreatedDate = new Date(year, month, 1);
    this.createMonthTable(this.calendarCreatedDate);
    this.cdRef.detectChanges();
  }

  selectPrevMonth(): void {
    const current = this.calendarCreatedDate;
    let year = current.getFullYear();
    let month = current.getMonth();

    if (month === 0) {
      month = 11;
      year--;
    } else {
      month--;
    }

    this.calendarCreatedDate = new Date(year, month, 1);
    this.createMonthTable(this.calendarCreatedDate);
    this.cdRef.detectChanges();
  }

  selectDate(date: Date) {
    if (this.startDate && this.stopDate) {
      this.startDate = undefined;
      this.stopDate = undefined;
      this.startDragDate = undefined;
      this.stopDragDate = undefined;
    }

    if (this.startDate) {
      if (date.getTime() < this.startDate.getTime()) {
        this.stopDate = this.startDate;
        this.startDate = date;

        this.form.get('from').patchValue(this.startDate);
        this.form.get('to').patchValue(this.stopDate);
      } else {
        this.stopDate = date;
        this.form.get('to').patchValue(date);
      }
      this.startDragDate = this.startDate;
      this.stopDragDate = this.stopDate;
    } else {
      this.startDate = date;
      this.startDragDate = date;
      this.form.get('from').patchValue(date);
    }

    this.createSelectedRangeLabel();
    this.cdRef.detectChanges();
  }

  clickOutsideCalendar() {
    if (this.startDate && !this.stopDate) {
      this.stopDate = this.startDate;
      this.stopDragDate = this.startDragDate;

      this.form.get('from').patchValue(this.startDate);
      this.form.get('to').patchValue(this.stopDate);

      this.createSelectedRangeLabel();
    }
  }

  private createSelectedRangeLabel(): void {
    if (!this.startDate && !this.stopDate) {
      return;
    }
    if (this.startDate && !this.stopDate) {
      const dateFromLabel = this.datePipe.transform(this.startDate, 'dd.MM');
      this.selectedRangeLabel = `с ${dateFromLabel} по ...`;
      return;
    }

    if (this.startDate.getTime() === this.stopDate.getTime()) {
      this.selectedRangeLabel = this.datePipe.transform(this.stopDate, 'dd.MM');
    } else {
      const dateFromLabel = this.datePipe.transform(this.startDate, 'dd.MM');
      const dateToLabel = this.datePipe.transform(this.stopDate, 'dd.MM');

      this.selectedRangeLabel = `c ${dateFromLabel} по ${dateToLabel}`;
    }
  }

  private createMonthTable(createFromDate: Date) {
    const month = createFromDate.getMonth();
    const year = createFromDate.getFullYear();

    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const startDay = firstDay === 0 ? 6 : firstDay - 1;
    const prevMonthDays = new Date(year, month, 0).getDate();

    let date = 1;
    let nextMonthDate = 1;

    const rows = [];
    const t = [];
    let index = 0;
    for (let i = 0; i < 5; i++) {
      const cells: Cell[] = [];
      for (let j = 0; j < 7; j++) {
        const currentCell: Partial<Cell> = {
          index,
        };

        if (i === 0 && j < startDay) {
          currentCell.dayNumber = prevMonthDays - (startDay - j - 1);
          currentCell.otherMonth = true;

          currentCell.date = new Date(
            month === 0 ? year - 1 : year,
            month === 0 ? 11 : month - 1,
            currentCell.dayNumber,
            0,
            0,
            0,
          );
        } else if (date > daysInMonth) {
          currentCell.dayNumber = nextMonthDate;
          currentCell.otherMonth = true;

          currentCell.date = new Date(
            month === 11 ? year + 1 : year,
            month === 1 ? 11 : month + 1,
            currentCell.dayNumber,
            0,
            0,
            0,
          );

          nextMonthDate++;
        } else {
          currentCell.dayNumber = date;
          currentCell.otherMonth = false;
          currentCell.date = new Date(year, month, currentCell.dayNumber, 0, 0, 0);

          date++;
        }

        currentCell.disabled = currentCell.date.getTime() <= this.today.getTime();
        currentCell.dateYMD = this.datePipe.transform(currentCell.date, 'YYYY-MM-dd');

        cells.push(currentCell as Cell);
      }

      rows.push(cells);

      if (date > daysInMonth && nextMonthDate > 7) {
        break;
      }

      index++;
    }

    this.tableRows = rows;
  }
}
