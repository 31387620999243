import { BehaviorSubject } from 'rxjs';
import { SearchHotel } from '../../../../../search/search.model';
import { SearchResultsResponseTour } from '../../../../../search/services/search/websocket-tours.model';

export interface HotOfferListItem {
  departCityId: number;
  departCityName: string;
  countries: HotOfferListItemCountry[];
}

export interface HotOfferListItemCountry {
  countryId: number;
  countryName: string;
  countryCode: string;
  countryPosition: number;
  hotels: HotOfferListItemCountryHotel[];
}

export interface HotOfferListItemCountryHotel {
  hotel: SearchHotel;
  tours: SearchResultsResponseTour[];
}

export interface HotOfferListItemTour {
  tourId: string;
  operatorId: number;
  operatorName: string;
  mealId: number | null;
  mealCode: string;
  mealName: string;
  percent: number;
  price: number;
  priceInBrandCurrency: number;
  oldPriceInBrandCurrency: number;
  currency: string;
  brandCurrency: string;
  nights: number;
  date: string;
  hotelId: number;
  departCityId: number;
  countryId: number;
}

export enum HotOffersToursSortVariant {
  BY_PRICE = 'by-price',
  BY_DISCOUNT = 'by-discount',
  BY_SALES_COUNT = 'by-sales-count',
  BY_RATING = 'by-rating',
}

export interface HotOffersToursFilters {
  countries: { [key: number]: BehaviorSubject<HotOffersFilterValue[]> };
  nights: { [key: number]: BehaviorSubject<HotOffersFilterValue[]> };
  departDates: { [key: number]: BehaviorSubject<HotOffersFilterValue[]> };
  ratings: { [key: number]: BehaviorSubject<HotOffersFilterValue[]> };
  hotelStars: { [key: number]: BehaviorSubject<HotOffersFilterValue[]> };
}

export enum HotOffersFilterType {
  BOOKING_RATING = 'booking-rating',
  DEPART_DATE = 'depart-date',
  NIGHTS = 'nights',
  COUNTRIES = 'countries',
  HOTEL_STARS = 'hotel-stars',
}

export interface HotOffersFilterValue {
  type: HotOffersFilterType;
  label: string;
  labelFilter: string;
  value: number | string;
  count: number;
  isChecked: boolean;
}

export type HotOffersApplyFilterType = HotOffersApplyFilterEvent | HotOffersApplyFilterDepartDateRangeEvent;

export interface HotOffersApplyFilterEvent {
  eventType: 'HotOffersApplyFilterEvent';
  type: HotOffersFilterType;
  value: number | string;
  departCityId: number;
  isChecked: boolean;
}

export interface HotOffersApplyFilterDepartDateRangeEvent {
  eventType: 'HotOffersApplyFilterDepartDateRangeEvent';
  values: string[];
  departCityId: number;
}
