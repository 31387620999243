import { EventEmitter } from '@angular/core';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { ChatSendMessageRequest } from '@api-clients/crm-api-client';
import { Observable } from 'rxjs';

/**
 * Тип контента, который может быть добавлен в контент-креатор
 */
export enum ContentCreatorContentType {
  PricesCalendar = 'pricesCalendar',
  Photos = 'photos',
  Videos = 'videos',
  AudioPresentation = 'audioPresentation',
  VideoPresentation = 'videoPresentation',
  Gpt = 'gpt',
  ManagerOffer = 'managerOffer',
  CustomMessage = 'customMessage',
  ManagerPricesCalendar = 'ManagerPricesCalendar',
  ManagerComments = 'managerComments',
  Reviews = 'reviews',
}

export enum ApiContentType {
  TourTextMessageData = 'tour.textMessageData',
  HotelPhotos = 'hotel.photos',
  HotelVideos = 'hotel.videos',
  HotelReviews = 'hotel.reviews',
  HotelAudioReviews = 'hotel.audioReviews',
  HotelManagerComments = 'hotel.managerComments',
  HotelAudioPresentations = 'hotel.audioPresentations',
  CountryExpertQuestions = 'country.expertQuestions',
  ManagerOffer = 'managerOffer',
}

export declare interface ContentCreatorItemMessage {
  component: ContentCreatorItemComponent;
  message: Partial<ChatSendMessageRequest>;
}

/**
 * Интерфейс для компонентов, которые могут быть добавлены в модальное окно контент-креатора
 */
export declare interface ContentCreatorItemComponent {
  tourId: string;
  tourContent: TourContent;

  /**
   * Установка текста для одиночного сообщения
   */
  insertSingleMessageText?: EventEmitter<string>;

  /**
   * Событие для инициализации отправки контента во внешнем компоненте content-creator
   */
  sendContentEvent?: EventEmitter<void>;

  /**
   * Флаг, если в компоненте открыта галерея изображений
   */
  isGalleryOpen?: boolean;
  getMessagesForSend(): Observable<ContentCreatorItemMessage[]>;
}
