<div class="attached-files-preview">
  <div class="file-name">
    {{ selectedDisplayFile?.fileName }}
  </div>

  <div class="close-button" (click)="closePreview()">
    <img src="assets/icons/whatsapp/close-preview-modal.svg" alt="close" />
  </div>

  <div
    class="main-file-preview"
    [ngClass]="{ 'file-icon': selectedDisplayFile.isFileIcon, video: isVideo, audio: isAudio }"
    *ngIf="selectedDisplayFile"
  >
    <video class="preview" *ngIf="isVideo" [src]="selectedDisplayFile.videoSource" controls></video>
    <audio class="preview" *ngIf="isAudio" [src]="selectedDisplayFile.audioSource" controls></audio>
    <img
      class="preview"
      *ngIf="isImage"
      [class.contain]="selectedDisplayFile.isFileIcon"
      [src]="selectedDisplayFile.preview"
      alt="{{ selectedDisplayFile.name }}"
    />
  </div>

  <div class="file-info">{{ selectedDisplayFile?.fileSize }} - {{ selectedDisplayFile?.extension }}</div>

  <div class="additional-files">
    <ng-container *ngFor="let file of displayedFiles; let i = index; trackBy: trackByFn">
      <div
        [class.selected]="selectedDisplayFile === file"
        (click)="selectFileForPreview(file)"
        class="file-preview"
      >
        <img
          class="preview"
          [class.contain]="file.isFileIcon"
          [src]="file.previewSmall"
          alt="{{ file.fileName }}"
        />
        <div class="remove-file-button" (click)="removeFile(file)">
          <img alt="remove" src="assets/icons/whatsapp/delete-file.svg" />
        </div>
      </div>
    </ng-container>
    <div class="add-file-button" (click)="addFiles()">+</div>
  </div>
</div>
