<ul>
  <li *ngFor="let stage of filteredStages" class="deals-submenu-item rounded-border">
    <a
      (click)="selectStage.emit(stage)"
      class="menu-subitem-link is-clickable rounded-border"
      [class.--active]="stage.name === selectedStage?.name"
      [style.color]="stage.textColor ? stage.textColor : ''"
      [style.--label-color-var]="stage.labelColor ? stage.labelColor : ''"
      [class.has-new-messages]="stage.hasNewMessages"
      >{{ 'PAGES.DEALS.SALES_FUNNEL.STAGES.' + stage.name | translate }}</a
    >

    <span *ngIf="stage.count" class="counter">
      <span class="counter-value --new" *ngIf="stage.count_new"> {{ stage.count_new }}</span>

      <ng-container *ngIf="isNewUi; else oldUi">
        <ng-container *ngIf="stage.status === 0 && stage.count > 0; else inWorkStages">
          <span class="counter-value">
            {{ stage.count }}
          </span>
        </ng-container>
        <ng-template #inWorkStages>
          <ng-container *ngIf="stage.count_today_forgotten_no_task > 0">
            <span class="counter-value">
              {{ stage.count_today_forgotten_no_task }}
            </span>
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-template #oldUi>
        <span class="counter-value">
          <ng-container *ngIf="stage.count_deals_with_expired_tasks">
            {{ stage.count_deals_with_expired_tasks }}/
          </ng-container>
          {{ stage.count }}
        </span>
      </ng-template>
    </span>
  </li>
</ul>
