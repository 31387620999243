import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { AsideChatsComponent } from './components/aside-chats/aside-chats.component';
import { AsideChatItemComponent } from './components/aside-chat-item/aside-chat-item.component';
import { AppRoutingModule } from '../../app-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { MessageInfoComponent } from './components/aside-chat-item/components/message-info/message-info.component';

@NgModule({
  declarations: [AsideChatsComponent, AsideChatItemComponent, MessageInfoComponent],
  exports: [AsideChatsComponent],
  imports: [CommonModule, AppRoutingModule, SharedModule, OverlayModule, PortalModule],
})
export class AsideChatsModule {}
