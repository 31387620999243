<div class="date-picker-wrapper">
  <p class="date-picker-text" *ngIf="labelTranslatePath">{{ labelTranslatePath | translate }}</p>
  <div class="date-time-picker" [ngClass]="{'--disabled': isDisabled}">
    <label (click)="openDatetimepickerPopup()"
           [ngClass]="{'--disabled': isDisabled}"
           class="input">
      <input [readOnly]="true"
             [formControl]="dateControl"
             type="text"/>
    </label>
    <label (click)="openDatetimepickerPopup()"
           [ngClass]="{'--disabled': isDisabled}"
           class="input middle">
      <input [readOnly]="true"
             [formControl]="timeControl"
             type="text"/>
    </label>
    <div class="icon-input">
      <img
        *ngIf="!isDisabled; else disabledIcon"
        src="assets/icons/deal-view/calendar.svg" alt="calendar">

      <ng-template #disabledIcon>
        <img
          src="assets/icons/deal-view/calendar-disabled.svg" alt="calendar">
      </ng-template>
    </div>
  </div>
</div>
