<div class="header" (clickOutside)="closeOptions()">
    <div class="center">
      <label>
        <div class="container --search-input">
          <input
            type="text"
            [placeholder]="SEARCH_FILTER_PLACEHOLDER"
            [(ngModel)]="searchContact">
        </div>
      </label>
      <div class="center" >
        <div class="options"
             [ngClass]="{'pressed':isOptionsOpened}"
             (click)="toggleOptions()"
             title="{{ 'PAGES.WHATSAPP.OPTIONS' | translate }}"
        >
            <img class='icon' src="./assets/icons/whatsapp/pointer.svg" alt="options">
        </div>
        <div *ngIf="isOptionsOpened">
          <ng-container>
            <div class="container triangle">
              <div class="clear-chats">
                <button (click)="clearChats()">{{ 'PAGES.WHATSAPP.MARK_ALL_AS_READ' | translate }}</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
</div>
<div class="wrapper">
  <ng-container *ngIf="listItems?.length; else emptyLastMessagesPlaceholder">
    <div *ngFor="let item of listItems |
         filter:
        [{field:'clientName', value: searchContact},
             {field:'phone.phone', value: searchContact},
             {field:'crmId', value: searchContact},
             {field:'lastMessageText', value: searchContact}]"
         class="chat-list">
      <app-chat-list-item
        [ngClass]="{'selected':selectedChatContactId === item.contactId}"
        [contactName]="item.clientName"
        [contactLastMessage]="item.lastMessageText || NoMessageLabel"
        [time]="item.receivedAt || item.createdAt"
        [unreadMessageCount]="item.unreadMessageCount"
        (click)="selectChat(item.crmId, item.phone, item.contactId)"
      ></app-chat-list-item>
    </div>
  </ng-container>
  <ng-container *ngIf="isBeingCleanedChats">
    <div class ="list-preloader-over">
      <img class="spin" src="assets/icons/preloader.svg" alt="" />
    </div>
  </ng-container>

</div>


<ng-template #emptyLastMessagesPlaceholder>
  <div class ="list-preloader">
  <img class="spin" src="assets/icons/preloader.svg" alt="" />
  </div>
</ng-template>



