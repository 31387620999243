<div class="wrapper" >
  <label class="hidden-phone-label" (click)="togglePhoneHidden()"  [ngClass]="{'clickable': isClickable}"
  >
    {{ phoneCode }}
    <input
      class="hidden-phone"
      type="text"
      [hiddenInput]="isPhoneHidden"
      [ngClass]="{'hidden-margin':isPhoneHidden}"
      [mask]="secretMask"
      [readOnly]="true"
      [ngModel]="phone"
    />
  </label>
  <button *ngIf="!isClickable" (click)="copy()">
    <img src="/assets/icons/copy.svg" alt="">
  </button>
</div>

