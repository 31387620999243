<div class="content">
  <div class="update">Обновления CRM</div>
  <button (click)="checkForUpdates()" class="btn --with-outline">
    Проверить обновления
  </button>
  <div class="columns">
    <div class="messages">
      <div class="message" *ngFor="let message of messages">
        {{message}}
      </div>
    </div>
    <div class="progress" *ngIf="currentProgress">
      <div *ngIf="currentProgress.speedBytesPerSecond as speed">
        Скорость (кб/с): {{speed / 1000}}
      </div>
      <mat-progress-bar mode="determinate" [value]="currentProgress.percent"></mat-progress-bar>
      <div *ngIf="currentProgress.percent as percent">
        Прогресс: {{percent | number}}%
      </div>
      <div *ngIf="currentProgress.transferred && currentProgress.total">
        Файлы: {{currentProgress.transferred }} / {{currentProgress.total}}
      </div>
    </div>
    <div class="progress" *ngIf="!currentProgress && isDownloadingStarted">
      <mat-progress-bar mode="query"></mat-progress-bar>
    </div>
  </div>

</div>
