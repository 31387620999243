import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { selectCurrentReplyMessage } from '../../../../../../../+state/chat-timeline/chat-timeline.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChatInputFocusService {
  constructor(private store: Store) {}

  private get replyMessageFocus$(): Observable<boolean> {
    return this.store.select(selectCurrentReplyMessage).pipe(map(message => !!message));
  }

  get shouldFocus$(): Observable<boolean> {
    return combineLatest([
      this.replyMessageFocus$,
      // Если будет необходимо, то создайте по примеру replyMessageFocus$ другие критерии и добавьте в этот массив
    ]).pipe(map(criteria => criteria.some(criterion => criterion)));
  }
}
