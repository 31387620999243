import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Сервис для хранения размеров элементов в ContentCreator
 */
@Injectable({
  providedIn: 'root',
})
export class ContentCreatorSizesService {
  public readonly modalWidth = 840;
  public readonly modalMinHeight = 300;
  public readonly modalVerticalMargin = 19;
  public readonly modalVerticalPadding = 16;
  public readonly modalHeaderHeight = 38;
  public readonly hotelTitleHeight = 50;
  // Устанавливается при первом изменении блока
  private messageSendControlsHeightSubject = new BehaviorSubject<number>(0);
  messageSendControlsHeight$ = this.messageSendControlsHeightSubject.asObservable();

  private reservedHeightSubject = new BehaviorSubject<number>(0);
  reservedHeight$ = this.reservedHeightSubject.asObservable();

  constructor() {
    this.calculateReservedHeight();
  }

  public setMessageSendControlsHeight(height: number) {
    this.messageSendControlsHeightSubject.next(height);
    this.calculateReservedHeight();
  }

  private calculateReservedHeight() {
    this.reservedHeightSubject.next(
      this.modalVerticalMargin * 2 +
        this.modalVerticalPadding * 2 +
        this.modalHeaderHeight +
        this.hotelTitleHeight +
        this.messageSendControlsHeightSubject.value +
        // Резервируем немного больше места, чтобы точно не было скролла
        4,
    );
  }
}
