import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { SharedModule } from '../../../../shared/shared.module';
import { ConfirmDeletingPhoneModule } from '../../../../ui-components/confirm/confirm-deleting-phone.module';
import { FormErrorModule } from '../../../../ui-components/form-error/form-error.module';
import { PhoneInputWithCodeModule } from '../../../../ui-components/phone-input-with-code/phone-input-with-code.module';
import { FormComponentsModule } from '../../../form-components/form-components.module';
import { DatetimepickerModule } from '../deal-view/modules/datetimepicker/datetimepicker.module';
import { AddDealComponent } from './add-deal.component';
import { MultiplePhoneInputComponent } from './components/multiple-phone-input/multiple-phone-input.component';
import { SelectAsWhatsappPhoneComponent } from './components/select-as-whatsapp-phone/select-as-whatsapp-phone.component';
import { AddDealPopupService } from './services/add-deal-popup.service';
import { DealCreateService } from './services/deal-create.service';
import { DealParamsService } from './services/deal-params.service';

@NgModule({
  declarations: [AddDealComponent, SelectAsWhatsappPhoneComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneInputWithCodeModule,
    ConfirmDeletingPhoneModule,
    FormErrorModule,
    BrowserModule,
    SharedModule,
    FormComponentsModule,
    DatetimepickerModule,
    NgClickOutsideDirective,
    MultiplePhoneInputComponent,
  ],
  providers: [AddDealPopupService, DealCreateService, DealParamsService],
})
export class AddDealModule {}
