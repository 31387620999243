export const i18nEmoji = {
  search: 'Поиск',
  emojilist: 'Список эмодзи',
  notfound: 'Не найдено',
  clear: 'Очистить',
  categories: {
    search: 'Результаты поиска',
    recent: 'Частое',
    people: 'Эмоции & Люди',
    nature: 'Животные & Природа',
    foods: 'Еда & Напитки',
    activity: 'Активность',
    places: 'Путешествия & Места',
    objects: 'Объекты',
    symbols: 'Символы',
    flags: 'Флаги',
    custom: 'Кастомное',
  },
  skintones: {
    1: 'По умолчанию',
    2: 'Светлое',
    3: 'Средне-светлое',
    4: 'Среднее',
    5: 'Средне-темное',
    6: 'Темное',
  },
};
