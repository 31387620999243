import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealsItemQuestionnaireComponent } from './deals-item-questionnaire.component';
import { SharedModule } from '../../../../../../../../shared/shared.module';

@NgModule({
  declarations: [DealsItemQuestionnaireComponent],
  imports: [CommonModule, SharedModule],
  exports: [DealsItemQuestionnaireComponent],
})
export class DealsItemQuestionnaireModule {}
