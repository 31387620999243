import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tourist-info-item',
  templateUrl: './tourist-info-item.component.html',
  styleUrls: ['./tourist-info-item.component.scss'],
})
export class TouristInfoItemComponent implements OnInit {
  @Input() firstName: string;
  @Input() secondName: string;
  public name: string;

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  ngOnInit(): void {
    this.name = `${this.capitalizeFirstLetter(this.firstName)} ${this.capitalizeFirstLetter(
      this.secondName,
    )}`;
  }
}
