import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DefaultResponse,
  DeleteWhatsappMessageTemplateRequest,
  WhatsappMessageTemplate,
  WhatsappMessageTemplateTypeEnum,
} from '@api-clients/crm-api-client';
import { WhatsappTemplatesApiService } from '../../services/whatsapp-templates-api.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { QUICK_MESSAGE_DELETE } from '../../../../../../core/services/amplitude/amplitudeEvents';

@Component({
  selector: 'app-whatsapp-template-manage-item',
  templateUrl: './whatsapp-template-manage-item.component.html',
  styleUrls: ['./whatsapp-template-manage-item.component.scss'],
})
export class WhatsappTemplateManageItemComponent implements OnInit {
  public readonly screenType: ScreenTypes = ScreenTypes.QUICK_ANSWERS;
  @Input() id: number;
  @Input() key = '';
  @Input() value = '';
  @Input() type: WhatsappMessageTemplateTypeEnum;
  @Input() alias?: string | null = null;
  @Output() removed: EventEmitter<void> = new EventEmitter<void>();
  @Output() edited: EventEmitter<WhatsappMessageTemplate> = new EventEmitter<WhatsappMessageTemplate>();

  constructor(
    private whatsappTemplatesApiService: WhatsappTemplatesApiService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  ngOnInit(): void {}

  edit() {
    const template: WhatsappMessageTemplate = {
      id: this.id,
      key: this.key,
      value: this.value,
      type: this.type,
      alias: this.alias,
    };
    this.edited.emit(template);
  }

  delete() {
    const body: DeleteWhatsappMessageTemplateRequest = {
      id: this.id,
    };
    this.whatsappTemplatesApiService.delete(body).subscribe((result: DefaultResponse) => {
      if (result.success) {
        this.removed.emit();
        this.amplitudeTrackService.trackEvent(QUICK_MESSAGE_DELETE, { screenType: this.screenType });
      }
    });
  }
}
