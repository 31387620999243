import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActualTaskItem } from '@api-clients/crm-api-client';
import { TaskListService } from './services/task-list.service';
import { ConfigsService, WorkerStateService } from '../../core/services';

const DATE_FORMAT = 'd MMMM, HH:mm';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListComponent implements OnInit {
  public taskList: ActualTaskItem[];
  public workerName: string;
  public readonly DATE_FORMAT = DATE_FORMAT;
  public isLoading = true;

  constructor(
    private configsService: ConfigsService,
    private taskListService: TaskListService,
    private workerState: WorkerStateService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.workerName = this.workerState.currentWorkerValue.name;
  }

  ngOnInit(): void {
    this.taskListService.getTaskList().subscribe(tasks => {
      this.taskList = tasks;
      this.isLoading = false;
      this.cdRef.markForCheck();
    });
  }

  getSecretMask(code: string): string {
    return this.configsService.getPhoneHiddenMaskByCode(code);
  }
}
