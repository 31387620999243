import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatTimelineState } from './chat-timeline.reducer';
import * as ChatTimelineActions from './chat-timeline.actions';
import {
  CreateWhatsappScheduledMessageRequest,
  DeleteWhatsappScheduledMessageRequest,
  ListWhatsappScheduledMessageForm,
  RescheduleWhatsappScheduledMessageRequest,
  WhatsappMessage,
  WhatsappScheduledMessage,
} from '@api-clients/crm-api-client';
import { Observable } from 'rxjs';
import {
  selectCurrentReplyMessage,
  selectHasScheduledMessages,
  selectIsScheduledMessagesListOpen,
  selectIsScheduleMessageOverlayOpen,
  selectIsSchedulingMessage,
  selectScheduledMessages,
} from './chat-timeline.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChatTimelineFacade {
  constructor(private store: Store<ChatTimelineState>) {}

  currentReplyMessage$: Observable<WhatsappMessage> = this.store.select(selectCurrentReplyMessage);
  hasScheduledMessages$: Observable<boolean> = this.store.select(selectHasScheduledMessages);
  scheduledMessages$: Observable<WhatsappScheduledMessage[]> = this.store.select(selectScheduledMessages);
  isSchedulingMessage$: Observable<boolean> = this.store.select(selectIsSchedulingMessage);
  isScheduleMessageOverlayOpen$: Observable<boolean> = this.store.select(selectIsScheduleMessageOverlayOpen);
  isScheduledMessagesListOpen$: Observable<boolean> = this.store.select(selectIsScheduledMessagesListOpen);

  // Метод для загрузки запланированных сообщений
  getScheduledMessagesList(request: ListWhatsappScheduledMessageForm) {
    this.store.dispatch(ChatTimelineActions.loadScheduledMessages({ request }));
  }

  // Метод для открытия оверлея
  openScheduleMessageOverlay() {
    this.store.dispatch(ChatTimelineActions.openScheduleMessageOverlay());
  }

  // Метод для закрытия оверлея
  closeScheduleMessageOverlay() {
    this.store.dispatch(ChatTimelineActions.closeScheduleMessageOverlay());
  }

  // Метод для создания запланированного сообщения
  createScheduledMessage(request: CreateWhatsappScheduledMessageRequest) {
    this.store.dispatch(ChatTimelineActions.createScheduledMessage({ request }));
  }

  // Метод для открытия списка запланированных сообщений
  openScheduledMessagesList() {
    this.store.dispatch(ChatTimelineActions.openScheduledMessagesList());
  }

  // Метод для закрытия списка запланированных сообщений
  closeScheduledMessagesList() {
    this.store.dispatch(ChatTimelineActions.closeScheduledMessagesList());
  }

  // Метод для изменения запланированного сообщения
  rescheduleMessage(request: RescheduleWhatsappScheduledMessageRequest) {
    this.store.dispatch(ChatTimelineActions.rescheduleMessage({ request }));
  }

  // Метод для удаления запланированного сообщения
  deleteScheduledMessage(request: DeleteWhatsappScheduledMessageRequest) {
    this.store.dispatch(ChatTimelineActions.deleteScheduledMessage({ request }));
  }

  // Метод для сброса состояния таймлайна
  resetChatState() {
    this.store.dispatch(ChatTimelineActions.resetChatTimelineState());
  }
}
