import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SipStateListEvent } from '@api-clients/crm-api-client';
import { FeatureId } from '../../../../shared/services/feature-id.enum';
import { WorkersListService } from './services/workers-list.service';
import { WorkerStateService } from '../../../../core/services';
import { Worker } from '../../../../models';
import { DEAL_VIEW_OPENED } from '../../../../core/services/amplitude/amplitudeEvents';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { IntroManagementService } from '../../../../shared/services/intro-management.service';

const CALL_CENTER = 'call_operator';

interface GroupedWorkerList {
  offices: any[];
  callCenter: any[];
}

@Component({
  selector: 'app-workers-list',
  templateUrl: './workers-list.component.html',
  styleUrls: ['./workers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WorkersListComponent implements OnInit, OnDestroy {
  public groupedByOffice: GroupedWorkerList;
  public officeList: string[] = [];
  public workersList$: Subscription;
  public screenType = ScreenTypes.WORKERS_LIST;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WorkersListComponent>,
    public workersListService: WorkersListService,
    private cdRef: ChangeDetectorRef,
    private workerStateService: WorkerStateService,
    private amplitudeTrackService: AmplitudeTrackService,
    private introManagementService: IntroManagementService,
  ) {}
  ngOnInit(): void {
    const currentWorker = this.workerStateService.currentWorkerValue;

    this.workersList$ = this.workersListService
      .fetchWorkersList(currentWorker.cityId)
      .subscribe(workersList => {
        this.workersListService.sipState$.subscribe((sipStates: SipStateListEvent[]) => {
          this.updateSipStates(sipStates, workersList).then(() => {});
        });

        this.groupedByOffice = workersList.reduce(
          (result, currentValue) => {
            if (currentValue.role === CALL_CENTER) {
              result.callCenter.push(currentValue);
            } else {
              (result.offices[currentValue.office.name] =
                result.offices[currentValue.office.name] || []).push(currentValue);
            }
            return result;
          },
          { offices: [], callCenter: [] },
        );

        this.officeList = Object.keys(this.groupedByOffice['offices']);

        this.cdRef.detectChanges();

        this.introManagementService.queueIntro(FeatureId.WORKER_LIST_CARD, false, [], null);
      });
  }

  updateSipStates(sipStates: SipStateListEvent[], workersList: Worker[]): Promise<any> {
    return new Promise<void>(resolve => {
      workersList.forEach((worker: Worker) => {
        sipStates['list'].forEach(workerState => {
          if (workerState.sip === worker.sip.innerPhone) {
            worker['state'] = workerState.state;
          }
        });
      });

      this.cdRef.detectChanges();
      resolve();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.workersList$.unsubscribe();
  }

  cardOpened() {
    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, {
      isManagerCard: true,
      screenType: this.screenType,
    });
    this.closeModal();
  }
}
