import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DealsFacade } from '../../../../+state/deals/deals.facade';

const DEAL_LIST_PAGE_URL = '/deals';
const MAIN_PAGE_URL = '/';

@UntilDestroy()
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  public hideReturnToCall = true;
  public isZenModeOn$ = this.dealsFacade.zenmodeOn$;

  constructor(private router: Router, private dealsFacade: DealsFacade) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.hideReturnToCall = event.url.includes(DEAL_LIST_PAGE_URL) || event.url.includes(MAIN_PAGE_URL);
      }
    });
  }

  ngOnInit(): void {}
}
