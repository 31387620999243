export const options: google.maps.MapOptions = {
  // mapId: '9c8635ae602a2316',
  mapTypeId: 'roadmap',
  zoomControl: true,
  scrollwheel: true,
  disableDoubleClickZoom: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  maxZoom: 19,
  minZoom: 5,
  zoom: 9,
  center: { lat: 0, lng: 0 },
  styles: [
    {
      featureType: 'poi.medical',
      elementType: 'all',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
};
