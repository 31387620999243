import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { SalesFunnelStagesComponent } from './sales-funnel-stages.component';
import { SharedModule } from '../../../../../../shared/shared.module';
import { SalesFunnelTaskPopupModule } from '../sales-funnel-task-popup/sales-funnel-task-popup.module';
import { SalesFunnelCompletePopupModule } from '../sales-funnel-complete-popup/sales-funnel-complete-popup.module';
import { SalesFunnelTooltipPopupModule } from '../sales-funnel-tooltip-popup/sales-funnel-tooltip-popup.module';

@NgModule({
  declarations: [SalesFunnelStagesComponent],
  imports: [
    CommonModule,
    SharedModule,
    SalesFunnelTaskPopupModule,
    SalesFunnelCompletePopupModule,
    SalesFunnelTooltipPopupModule,
    NgSelectModule,
  ],
  exports: [SalesFunnelStagesComponent],
})
export class SalesFunnelStagesModule {}
