import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-whatsapp-footer-loading',
  templateUrl: './whatsapp-footer-loading.component.html',
  styleUrls: ['./whatsapp-footer-loading.component.scss'],
})
export class WhatsappFooterLoadingComponent {
  @Input() isModal: boolean;
}
