import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsappFooterLoadingComponent } from './whatsapp-footer-loading/whatsapp-footer-loading.component';
import { CirclePreloaderModule } from '../circle-preloader/circle-preloader.module';

@NgModule({
  declarations: [WhatsappFooterLoadingComponent],
  imports: [CommonModule, CirclePreloaderModule],
  exports: [WhatsappFooterLoadingComponent],
})
export class WhatsappFooterLoadingModule {}
