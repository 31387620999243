import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { tap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { Notify, NotifyService } from './services/notify.service';

const HIDE_DELAY = 3000;

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NotifyComponent implements OnInit {
  public notify: Notify;

  constructor(private notifyService: NotifyService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.notifyService.createEvent
      .pipe(tap(() => timer(HIDE_DELAY).subscribe(() => this.close())))
      .subscribe((notify: Notify) => {
        this.notify = notify;
        this.cdRef.detectChanges();
      });
  }

  close() {
    this.notify = null;
    this.cdRef.detectChanges();
  }
}
