import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SmsSendMessageRequest } from '@api-clients/api-client';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class SmsSendService {
  constructor(private http: HttpClient) {}

  smsSend(body: SmsSendMessageRequest) {
    return this.http
      .post<ApiResponse<SmsSendMessageRequest>>(`${AppConfig.apiUrl}/sms/send-message`, body)
      .pipe(apiResponsePipe);
  }
}
