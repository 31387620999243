<div class="message-with-video"
     [ngClass]="isFromMe ? 'from-me' : 'from-client'"
>
  <span itemInView (inView)="showVideo()">
    <video controls preload="metadata" *ngIf="isShow; else videoContainer">
      <source [src]="mediaSource" type="video/mp4">
      <source [src]="mediaSource" type="video/ogg">
      Ваш браузер не поддерживает тег video.
    </video>
    <ng-template #videoContainer>
      <div class="video-container"></div>
    </ng-template>
    <p>
       {{ text }}
    </p>
  </span>
</div>
