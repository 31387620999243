import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scriptsLoaders: {
    [url: string]: Observable<Event>;
  } = {};

  public constructor(@Inject(DOCUMENT) private document: any) {}

  public load(
    url: string,
    attributes?: { [s: string]: string },
    targetEl: HTMLElement | string = 'head',
  ): Observable<Event> {
    // eslint-disable-next-line no-return-assign
    return (this.scriptsLoaders[url] =
      this.scriptsLoaders[url] || this.loadScript(url, attributes, targetEl).pipe(take(1), shareReplay(1)));
  }

  private loadScript(
    url: string,
    attributes?: { [s: string]: string },
    targetEl: HTMLElement | string = 'head',
  ): Observable<Event> {
    return new Observable<Event>(observer => {
      const script: HTMLScriptElement = this.document.createElement('script');

      if (attributes) {
        for (const key in attributes) {
          if (attributes.hasOwnProperty(key)) {
            script.setAttribute(key, attributes[key]);
          }
        }
      }

      script.onload = (event: Event) => {
        observer.next(event);
        observer.complete();
      };

      script.onerror = err => {
        observer.error(err);
      };

      script.src = url;

      const targetElement: HTMLElement =
        typeof targetEl === 'string' ? this.document.querySelector(targetEl) : targetEl;
      targetElement.appendChild(script);
    });
  }
}
