<div class="content">
  <div class="header">
    <button (click)="back()" class="button-back rounded-border" type="button">
      <img alt="" src="./assets/icons/back-arrow.svg" />
    </button>
    <h2>{{'PAGES.SETTINGS.WHATSAPP.TEMPLATES_TITLE' | translate}}</h2>
  </div>
  <div class="settings-content">
    <div class="templates column">
      <h3>{{'PAGES.SETTINGS.WHATSAPP.ADD_TEMPLATE' | translate}}</h3>
      <div class="templates" *ngIf="templates?.length; else emptyList">
        <app-whatsapp-template-manage-item
          *ngFor="let template of templates"
          [id]="template.id"
          [key]="template.key"
          [value]="template.value"
          [type]="template.type"
          [alias]="template.alias"
          (removed)="refreshTemplateList()"
          (edited)="editTemplate($event)"

        >
        </app-whatsapp-template-manage-item>
      </div>
      <ng-template #emptyList>{{'PAGES.SETTINGS.WHATSAPP.TEMPLATES_LIST_EMPTY' | translate}}</ng-template>
    </div>
    <div class="controllers column">
      <h3>{{'PAGES.SETTINGS.WHATSAPP.TEMPLATES_LIST' | translate}}</h3>
        <app-whatsapp-template-form
          [template]="currentTemplate"
          (saved)="refreshTemplateList()">
        </app-whatsapp-template-form>
    </div>
  </div>
</div>
