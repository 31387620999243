import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { WhatsappInstanceStateEnum } from '@api-clients/crm-api-client';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CallsFacade } from '../../../../+state/calls/calls.facade';
import { DealViewService } from '../../../deals/modules/deal-view/services/deal-view.service';
import { WhatsappContactsFacade } from '../../../../+state/whatsapp-contacts/whatsapp-contacts.facade';
import { AlertOnTopService } from '../../services/alert-on-top.service';

@Component({
  selector: 'app-alert-on-top',
  templateUrl: './alert-on-top.component.html',
  styleUrls: ['./alert-on-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertOnTopComponent implements OnInit, OnDestroy {
  public qrLink$: Observable<string>;
  public whatsappState$: Observable<WhatsappInstanceStateEnum>;
  public combined$: Observable<{ isNeedScanQR: boolean; url: string }>;
  private whatsappContactsSubscription: Subscription;
  @Input() isDealLayout: boolean;

  constructor(
    private callsFacade: CallsFacade,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private dealViewService: DealViewService,
    private whatsappContactsFacade: WhatsappContactsFacade,
    private alertOnTopService: AlertOnTopService,
  ) {
    this.whatsappState$ = this.whatsappContactsFacade.whatsappState$;
    this.qrLink$ = this.whatsappContactsFacade.qrLink$;
  }

  ngOnInit(): void {
    this.combined$ = combineLatest([this.qrLink$, this.whatsappState$]).pipe(
      map(([qrLink, state]) => {
        return {
          url: qrLink,
          isNeedScanQR: state === WhatsappInstanceStateEnum.Qr || state === WhatsappInstanceStateEnum.Qridle,
        };
      }),
    );
  }

  close(lastMessageId: string) {
    this.alertOnTopService.closeInteractForLastClient(lastMessageId);
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.whatsappContactsSubscription?.unsubscribe();
  }

  openWhatsappIntegration(url: string) {
    this.alertOnTopService.openWhatsappIntegration(url);
  }
}
