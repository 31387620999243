import { Injectable } from '@angular/core';
import { SearchRequest } from '@api-clients/crm-api-client';
import { formatDate } from '@angular/common';
import { ConfigsService } from '../../core/services';

@Injectable({
  providedIn: 'root',
})
export class OpenManagerSearchService {
  public adminUrl: string;
  constructor(private configService: ConfigsService) {
    this.adminUrl = this.configService.adminUrl;
  }

  getUrlToOpenManagerSearch(crmCardId, isAuto) {
    let url = `${this.adminUrl}/search/manager?crm_id=${crmCardId}`;
    if (isAuto) {
      url += '&isAuto=1';
    }

    return url;
  }

  getUrlToOpenManagerSearchBySearchLink(searchLink: string) {
    return `${this.adminUrl}${searchLink}`;
  }

  generateSearchLink(lastSearchRequest: SearchRequest, cardId: number): string {
    const LOCALE = 'RU';
    const FORMAT_DATE = 'dd.MM.yyyy';
    if (!lastSearchRequest) {
      return '';
    }
    const queryParams = [
      ['crm_id', cardId],
      ['departCity', lastSearchRequest.departCity?.id ? lastSearchRequest.departCity.id : ''],
      ['country', lastSearchRequest.country?.id ? lastSearchRequest.country.id : ''],
      [
        'region',
        lastSearchRequest.regions ? lastSearchRequest.regions.map(region => region.id).join(',') : '',
      ],
      ['dateFrom', formatDate(lastSearchRequest.dateFrom, FORMAT_DATE, LOCALE)],
      ['dateTo', formatDate(lastSearchRequest.dateTo, FORMAT_DATE, LOCALE)],
      ['nightsFrom', lastSearchRequest.nightsFrom],
      ['nightsTo', lastSearchRequest.nightsTo],
      ['adult', lastSearchRequest.adult],
      ['child', lastSearchRequest.child],
      ['childAges', lastSearchRequest.childAges],
      ['stars', lastSearchRequest.stars.join(', ')],
      ['anyMeals', !lastSearchRequest.meals || lastSearchRequest.meals.length === 0 ? 1 : 0],
      ['meals', lastSearchRequest.meals ? lastSearchRequest.meals.map(meal => meal.id).join(',') : ''],
      ['hotels', lastSearchRequest.hotels ? lastSearchRequest.hotels.map(hotel => hotel.id).join(',') : ''],
    ];

    return `/search/manager?${queryParams.map(keyValue => keyValue.join('=')).join('&')}`;
  }
}
