<div class="last-action-text actions-text">
  <ng-container>
    {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.SMS'| translate }}
    </ng-container
  > от
  <ng-container>{{ lastActionItem.worker?.name || "Робот" }}</ng-container>
</div>
<div class='sms-content'>
  {{ lastActionItem.text}}
</div>
<span class="last-action-date actions-date">
  {{lastActionItem.addDate | date: actionsDateFormat | lowercase}}
</span>
