import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string): SafeHtml {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return this.sanitizer.bypassSecurityTrustHtml(
      text.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`),
    );
  }
}
