import { Injectable } from '@angular/core';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AmplitudeTrackService } from '../../../../../core/services/amplitude/amplitude-track.service';
import { DEALS_LIST_FILTER_SET } from '../../../../../core/services/amplitude/amplitudeEvents';
import { DealListFilterKeys, FilterIds } from '../deal-list';

export interface FilterItem {
  id: FilterIds;
  label: string;
  isActive: boolean;
  count: number;
}

export type DealListFilters = {
  [key in DealListFilterKeys]: FilterItem;
};

@Injectable({
  providedIn: 'root',
})
export class DealListFilterService {
  private initialFilterState: DealListFilters = {
    [DealListFilterKeys.IS_FORGOTTEN]: {
      id: 'forgotten',
      label: 'Забытые клиенты',
      isActive: false,
      count: 0,
    },
    [DealListFilterKeys.IS_NEARBY_DATES]: {
      id: 'nearby_dates',
      label: 'Ближайшие даты',
      isActive: false,
      count: 0,
    },
    [DealListFilterKeys.IS_RETURN_TOURIST]: {
      id: 'return_tourists',
      label: 'Возвратные туристы',
      isActive: false,
      count: 0,
    },
    [DealListFilterKeys.IS_MOBILE_APP_INSTALLED]: {
      id: 'has_mobile_app',
      label: 'Моб. приложение',
      isActive: false,
      count: 0,
    },
    [DealListFilterKeys.IS_HIGH_CHECK]: {
      id: 'high_check',
      label: 'Большой чек',
      isActive: false,
      count: 0,
    },
  };
  private filtersSource = new BehaviorSubject<DealListFilters>(this.deepCopy(this.initialFilterState));
  private originalDealsSource = new BehaviorSubject<CrmCardViewItem[]>([]);
  private filteredDealsSource = new BehaviorSubject<CrmCardViewItem[]>([]);

  public anyActiveFilters$ = this.filtersSource.pipe(
    map(filters => Object.values(filters).some(filter => filter.isActive)),
  );
  public currentFilters$ = this.filtersSource.asObservable();
  public filteredDeals$ = this.filteredDealsSource.asObservable();

  constructor(private amplitudeTrackService: AmplitudeTrackService) {}

  public updateFilter(key: DealListFilterKeys, isActive: boolean): void {
    const filters = this.deepCopy(this.filtersSource.getValue());

    if (isActive) {
      this.amplitudeTrackService.trackEvent(DEALS_LIST_FILTER_SET, { 'Filter name': filters[key].id });
    }

    filters[key].isActive = isActive;
    this.filtersSource.next(filters);

    this.updateFilteredDeals();
  }

  public resetFilters(): void {
    this.filtersSource.next(this.deepCopy(this.initialFilterState));
    this.updateFilteredDeals();
  }

  public setDeals(deals: CrmCardViewItem[]): void {
    this.originalDealsSource.next(deals);
    this.updateFilteredDeals();
  }

  private updateFilteredDeals(): void {
    const filters = this.filtersSource.getValue();
    const deals = this.originalDealsSource.getValue();
    const filtered = deals.filter(deal => this.applyAllFilters(deal, filters));
    this.filteredDealsSource.next(filtered);
    this.updateCounts();
  }

  private applyAllFilters(deal: CrmCardViewItem, filters: DealListFilters): boolean {
    return Object.values(filters).every(filter => this.applyFilter(deal, filter.id, filter.isActive));
  }

  private applyFilter(deal: CrmCardViewItem, filterId: FilterIds, isActive: boolean): boolean {
    switch (filterId) {
      case 'forgotten':
        return !isActive || deal.isForgotten;
      case 'nearby_dates':
        return !isActive || deal.lastSearchRequest?.isNearbyDates;
      case 'return_tourists':
        return !isActive || deal.deal?.isReturnTourist;
      case 'has_mobile_app':
        return !isActive || deal.card?.hasMobileApplication;
      case 'high_check':
        return !isActive || deal.experimentalData?.isHighCheck;
      default:
        return true;
    }
  }

  private updateCounts(): void {
    const filters = this.deepCopy(this.filtersSource.getValue());
    const deals = this.filteredDealsSource.getValue();

    const filtersArray = Object.values(filters);
    filtersArray.forEach(filter => {
      filter.count = 0;
    });

    deals.forEach(deal => {
      filtersArray.forEach(filter => {
        if (this.matchesFilter(deal, filter.id)) {
          filter.count += 1;
        }
      });
    });

    this.filtersSource.next(filters);
  }

  private matchesFilter(deal: CrmCardViewItem, filterId: FilterIds): boolean {
    switch (filterId) {
      case 'forgotten':
        return deal.isForgotten;
      case 'nearby_dates':
        return deal.lastSearchRequest?.isNearbyDates;
      case 'return_tourists':
        return deal.deal?.isReturnTourist;
      case 'has_mobile_app':
        return deal.card?.hasMobileApplication;
      case 'high_check':
        return deal.experimentalData?.isHighCheck;
      default:
        return false; // Если фильтр не найден
    }
  }

  private deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
  }
}
