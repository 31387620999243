import { Component, Input, OnInit } from '@angular/core';
import { WhatsappMessage } from '@api-clients/crm-api-client';
import { WhatsappService } from '../../../../../../services/whatsapp.service';

@Component({
  selector: 'app-message-quote',
  templateUrl: './message-quote.component.html',
  styleUrls: ['./message-quote.component.scss'],
})
export class MessageQuoteComponent implements OnInit {
  @Input() quotedMessage: WhatsappMessage;
  @Input() clientName: string;
  @Input() isFromMe: boolean;
  public quotedMessageText: string;
  public quotedMessageAuthor: string;
  constructor(private whatsappService: WhatsappService) {}

  ngOnInit(): void {
    this.quotedMessageText = this.whatsappService.parseShortMessage(this.quotedMessage);
    this.quotedMessageAuthor = this.quotedMessage.isFromMe ? this.clientName : 'Вы';
  }
}
