import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import { DEALS_MENU_STAGE_OPEN, DEALS_MENU_STATUS_OPEN } from '../../core/services/amplitude/amplitudeEvents';
import { SalesFunnelCategory, SalesFunnelStage } from '../../modules/deals/modules/deals-list/deal-list';
import {
  DealsSetCurrentCategory,
  DealsSetCurrentStage,
  DealsSetLastSelectedListItemId,
  DealsToggleIsAllowShowOtherDeals,
  PostSaleDealListItemSetMaximized,
  PostSaleDealListItemSetMinimized,
  PostSaleDealListItemSetMinimizedAll,
} from './deals-list.actions';
import { DealsListState } from './deals-list.interface';
import { dealsListQuery } from './deals-list.selectors';

@Injectable()
export class DealsListFacade {
  currentStage$ = this.store.select(dealsListQuery.getCurrentStage);
  currentCategory$ = this.store.select(dealsListQuery.getCurrentCategory);
  isPostponedCategorySelected$ = this.store.select(dealsListQuery.getIsPostponedCategorySelected);
  lastSelectedListItemId$ = this.store.select(dealsListQuery.getLastSelectedListItemId);
  maximizedTourPackagesList$ = this.store.select(dealsListQuery.getListMaximizedTourPackages);
  isAllowShowOtherDeals$ = this.store.select(dealsListQuery.getIsAllowShowOtherDeals);

  constructor(private store: Store<DealsListState>, private amplitudeTrackService: AmplitudeTrackService) {}

  setCurrentStage(currentStage: SalesFunnelStage) {
    this.amplitudeTrackService.trackEvent(DEALS_MENU_STAGE_OPEN, { 'Stage name': currentStage.name });
    this.store.dispatch(new DealsSetCurrentStage(currentStage));
    this.store.dispatch(new DealsSetCurrentCategory(null));
  }

  setCurrentCategory(currentCategory: SalesFunnelCategory) {
    this.amplitudeTrackService.trackEvent(DEALS_MENU_STATUS_OPEN, { 'Status name': currentCategory.name });
    this.store.dispatch(new DealsSetCurrentCategory(currentCategory));
    this.store.dispatch(new DealsSetCurrentStage(null));
  }

  setLastSelectedListItemId(lastSelectedListItemId: number) {
    this.store.dispatch(new DealsSetLastSelectedListItemId(lastSelectedListItemId));
  }
  setMinimized(tourPackageId: number) {
    this.store.dispatch(new PostSaleDealListItemSetMinimized(tourPackageId));
  }

  setMinimizedAll() {
    this.store.dispatch(new PostSaleDealListItemSetMinimizedAll());
  }

  setMaximized(tourPackageId: number) {
    this.store.dispatch(new PostSaleDealListItemSetMaximized(tourPackageId));
  }

  toggleAllowToShowOtherDeal() {
    this.store.dispatch(new DealsToggleIsAllowShowOtherDeals());
  }
}
