import {
  ChatContactsListItem,
  ChatContactsListItemDetailsDealInfo,
  ChatRecommendationMessage,
  CrmCardViewItem,
  CrmTaskItem,
} from '@api-clients/crm-api-client';
import { createAction, props } from '@ngrx/store';
import { IStatuses } from '../../../app/models/whatsapp';
import { ChatRecommendation } from '../../modules/chats/interfaces/chat.interface';

export const loadChatContacts = createAction('[Chats] Load Chat Contacts');
export const loadChatContactsSuccess = createAction(
  '[Chats] Load Chat Contacts Success',
  props<{
    chatContacts: ChatContactsListItem[];
  }>(),
);
export const loadChatContactsFailure = createAction(
  '[Chats] Load Chat Contacts Failure',
  props<{
    error: any;
  }>(),
);
export const chooseChatContact = createAction(
  '[Chats] Choose Chat Contact',
  props<{
    chatContact: ChatContactsListItem;
  }>(),
);

export const updateChatContact = createAction(
  '[Chats] Update Chat Contact',
  props<{
    chatContact: ChatContactsListItem;
  }>(),
);

export const updateChatContactStage = createAction(
  '[Chats] Update Chat Contact Stage',
  props<{
    crmCardId: number;
    stage: string;
  }>(),
);

export const loadChatContact = createAction(
  '[Chats] Load Chat Contact',
  props<{
    contactId: string;
  }>(),
);

export const loadChatContactSuccess = createAction(
  '[Chats] Load Chat Contact Success',
  props<{
    chatContact: ChatContactsListItem;
  }>(),
);

export const loadChatContactFailure = createAction(
  '[Chats] Load Chat Contact Failure',
  props<{
    error: string;
  }>(),
);

export const loadChatRecommendation = createAction('[Chats] Load Chat Recommendation');

export const loadChatRecommendationSuccess = createAction(
  '[Chats] Load Chat Recommendation Success',
  props<ChatRecommendation>(),
);

export const loadChatRecommendationFailure = createAction(
  '[Chats] Load Chat Recommendation Failure',
  props<{
    error: string;
  }>(),
);

export const markRecommendationMessageAsDeleted = createAction(
  '[Chats] Mark Recommendation Message As Deleted',
  props<ChatRecommendationMessage>(),
);

export const markRecommendationMessageAsDeletedSuccess = createAction(
  '[Chats] Mark Recommendation Message As Deleted Success',
  props<ChatRecommendationMessage>(),
);

export const markRecommendationMessageAsDeletedFailure = createAction(
  '[Chats] Mark Recommendation Message As Deleted Failure',
  props<{
    error: string;
  }>(),
);

export const markRecommendationMessageAsSent = createAction(
  '[Chats] Mark Recommendation Message As Sent',
  props<{
    message: ChatRecommendationMessage;
    changedText: string | null;
  }>(),
);

export const markRecommendationMessageAsSentSuccess = createAction(
  '[Chats] Mark Recommendation Message As Sent Success',
  props<ChatRecommendationMessage>(),
);

export const markRecommendationMessageAsSentFailure = createAction(
  '[Chats] Mark Recommendation Message As Sent Failure',
  props<{
    error: string;
  }>(),
);

export const loadChatContactDealInfo = createAction(
  '[Chats] Load Chat Contact Deal Info',
  props<{
    contactId: string;
  }>(),
);

export const loadDealViewForChat = createAction(
  '[Chats] Load Deal View For Chat',
  props<{
    crmCardId: number;
  }>(),
);

export const loadDealViewForChatSuccess = createAction(
  '[Chats] Load Deal View For Chat Success',
  props<{
    crmCardViewItem: CrmCardViewItem;
  }>(),
);

export const loadDealViewForChatFailure = createAction(
  '[Chats] Load Deal View For Chat Failure',
  props<{
    error: string;
  }>(),
);

export const loadDealViewNextTask = createAction(
  '[Chats] Load Deal View Next Task',
  props<{
    crmCardId: number;
  }>(),
);

export const loadDealViewNextTaskSuccess = createAction(
  '[Chats] Load Deal View Next Task Success',
  props<{
    nextTask: CrmTaskItem;
  }>(),
);

export const loadDealViewNextTaskFailure = createAction(
  '[Chats] Load Deal View Next Task Failure',
  props<{
    error: string;
  }>(),
);

export const updateCurrentChatContactCrmCardStage = createAction(
  '[Chats] Update Current Chat Contact Crm Card Stage',
  props<{
    stage: string;
  }>(),
);

export const sortChatContacts = createAction('[Chats] Sort Chat Contacts');

export const sortChatContactsSuccess = createAction(
  '[Chats] Sort Chat Contacts Success',
  props<{
    chatContacts: ChatContactsListItem[];
  }>(),
);

export const refreshMessageStatuses = createAction(
  '[Chats] Refresh Message Statuses',
  props<{
    statuses: IStatuses;
  }>(),
);

export const readChat = createAction(
  '[Chats] Read Chat',
  props<{
    contactId: string;
    fromPlace: string;
  }>(),
);

export const readChatSuccess = createAction(
  '[Chats] Read Chat Success',
  props<{
    contactId: string;
  }>(),
);

export const readChatFailure = createAction(
  '[Chats] Read Chat Failure',
  props<{
    message: string;
    error: any;
  }>(),
);

export const resetCurrentChatState = createAction('[Chats] Reset Chat State');

export const searchContacts = createAction('[Chat List] Search Contacts', props<{ searchQuery: string }>());

export const searchContactsSuccess = createAction(
  '[Chat List] Search Contacts Success',
  props<{ contacts: ChatContactsListItem[] }>(),
);

export const searchContactsFailure = createAction(
  '[Chat List] Search Contacts Failure',
  props<{ error: any }>(),
);

export const clearSearchResults = createAction('[Chat List] Clear Search Results');
