import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-whatsapp-message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageStatusComponent implements OnInit, OnChanges {
  @Input() messageStatus;
  @Input() date;
  @Input() isFromMe: boolean;
  @Input() isOnlyImage: boolean;
  @Input() type;
  public isRead: boolean;
  public isSent: boolean;
  public isReceived: boolean;
  public isInFlight: boolean;
  public isUnknown: boolean;

  constructor(private cdRef: ChangeDetectorRef) {}

  initStatus() {
    this.isRead = this.messageStatus === 'read';
    this.isSent = this.messageStatus === 'sent';
    this.isReceived = this.messageStatus === 'received';
    this.isInFlight = this.messageStatus === 'inFlight';
    this.isUnknown = this.messageStatus === 'unknown';
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.initStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.messageStatus?.currentValue) {
      this.initStatus();
    }
  }
}
