<ng-container *ngIf="isDealListNewUi$ | async as newUi; else oldUi">
  <div class="deals-header new-ui">
    <div class="filters-and-refresh">
      <app-deal-list-filters></app-deal-list-filters>
      <div class="right-side">
        <app-deal-list-sorting></app-deal-list-sorting>
        <button (click)="manualRefresh()" class="btn --transparent refresh-btn">
          <img
            src="../../../../../../../../../assets/icons/refresh.svg"
            alt="refresh"
            [ngClass]="{ rotate: isRefreshing }"
          />
        </button>
      </div>
    </div>

    <ng-container *ngIf="hasDeals">
      <div class="deals-list-table-header">
        <span>Информация о клиенте</span>
        <span>Запрос, состав и даты</span>
        <span>Задание и комментарий</span>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #oldUi>
  <div class="deals-header">
    <h4 *ngIf="stageTitle" class="heading">
      {{ 'PAGES.DEALS.SALES_FUNNEL.STAGES.' + stageTitle | translate }}
    </h4>
    <button (click)="manualRefresh()" class="btn --transparent refresh-btn">
      <img
        src="../../../../../../../../../assets/icons/refresh.svg"
        alt="refresh"
        [ngClass]="{ rotate: isRefreshing }"
      />
    </button>
    <button (click)="showAddDealPopup()" class="btn --blue add-deal-btn rounded-border">
      <img alt="" src="../../../../../../../../../assets/icons/add-client.svg" />
      <span>{{ 'PAGES.DEALS.ADD_DEAL.TITLE' | translate }}</span>
    </button>
  </div>
</ng-template>
