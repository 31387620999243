import { Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ConfigsService } from '../../../../../../../../core/services';
import {
  Notify,
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../shared/notify/services/notify.service';

@Component({
  selector: 'app-client-phone',
  templateUrl: './client-phone.component.html',
  styleUrls: ['./client-phone.component.scss'],
})
export class ClientPhoneComponent implements OnInit {
  @Input() phone: string;
  @Input() phoneCode: string;
  @Input() isClickable = false;
  public secretMask: string;
  public isPhoneHidden = true;

  constructor(
    private configsService: ConfigsService,
    private notifyService: NotifyService,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit(): void {
    this.secretMask = this.configsService.getPhoneHiddenMaskByCode(this.phoneCode);
  }

  togglePhoneHidden() {
    this.isPhoneHidden = !this.isPhoneHidden;
  }

  copy() {
    this.isPhoneHidden = false;
    this.clipboardService.copy(`${this.phoneCode}${this.phone}`);
    const notify: Notify = {
      title: 'Номер скопирован',
      text: 'Номер успешно скопирован в буфер обмена!',
      type: NotifyTypeEnum.success,
    };
    this.notifyService.create(notify);
  }
}
