import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../../../../../../../shared/shared.module';
import { TaskAlertModule } from '../../../../deal-view/modules/task-alert/task-alert.module';
import { MessagePhoneComponent } from './components/message-phone/message-phone.component';
import { DealsListItemNewComponent } from './deals-list-item-new.component';
import { SumFormatPipe } from '../helpers/sum-format.pipe';
import { SecondsToMinutesSecondsPipe } from 'app/helpers/pipes/seconds-to-minutes-seconds.pipe';
import { TaskCommentComponent } from 'app/modules/deals/modules/deal-view/modules/task-alert/components/task-comment/task-comment.component';

@NgModule({
  declarations: [DealsListItemNewComponent, MessagePhoneComponent],
  imports: [
    CommonModule,
    SharedModule,
    TaskAlertModule,
    TaskCommentComponent,
    TranslateModule,
    NgxMaskModule,
    RouterModule,
    SecondsToMinutesSecondsPipe,
    SumFormatPipe,
  ],
  exports: [DealsListItemNewComponent],
})
export class DealsListItemNewModule {}
