import { Injectable } from '@angular/core';
import { PhoneItem, ChatSendMessageRequest, SendMessagesResponse } from '@api-clients/crm-api-client';
import { Observable, Subject, from, EMPTY, of } from 'rxjs';
import { concatMap, catchError, first } from 'rxjs/operators';
import { ChatApiService } from '../../../core/services/chat/chat-api.service';
import { ChatService } from '../../../core/services/chat/chat.service';

/**
 * Сервис для отправки сообщений в Whatsapp в порядке очереди
 */
@Injectable({
  providedIn: 'root',
})
export class WhatsappMessageQueueService {
  private messageQueue: Subject<{ phone: PhoneItem; message: ChatSendMessageRequest }> = new Subject();

  constructor(private chatService: ChatService, private chatApiService: ChatApiService) {
    this.messageQueue
      .pipe(
        concatMap(({ phone, message }) => this.sendMessage(phone, message)),
        catchError(error => {
          console.error('Error in MessageQueueService:', error);
          return of(null);
        }),
      )
      .subscribe();
  }

  /**
   * Добавление сообщения в очередь на отправку
   */
  queueMessage(phone: PhoneItem, message: ChatSendMessageRequest): void {
    this.messageQueue.next({ phone, message });
  }

  /**
   * Получение сообщений из Observable и добавление их в очередь на отправку
   */
  queueFromObservable(phone: PhoneItem, messages$: Observable<ChatSendMessageRequest[]>): void {
    messages$.pipe(first()).subscribe(messages => {
      messages.forEach(message => this.queueMessage(phone, message));
    });
  }

  private sendMessage(
    phone: PhoneItem,
    messageSendRequest: ChatSendMessageRequest,
  ): Observable<SendMessagesResponse> {
    const pendingMessage = this.chatService.showPendingMessage(messageSendRequest, phone);
    return from(
      this.chatApiService.sendMessage(messageSendRequest).pipe(
        first(),
        catchError(error => {
          if (error?.errors?.message) {
            alert(error.errors.message);
          } else {
            alert('Не удалось отправить сообщение');
          }
          this.chatService.deletePendingMessage(pendingMessage);
          return EMPTY;
        }),
      ),
    );
  }
}
