<div class="review-button">
  <div
    class="review-button__body review-button__body--underline"
    (click)="onClick(reservationLastReview)"
    *ngIf="reservationLastReview; else noReview"
  >
    <img src="assets/icons/client-reservations/small-star.svg" alt="" srcset="" />
    <span>{{ reservationLastReview.gradeCompanyService }}</span>
    <img src="assets/icons/client-reservations/arrow-right.svg" alt="" srcset="" />
  </div>
  <ng-template #noReview>
    <div class="review-button__body no-review">
      <span>Нет отзыва</span>
    </div>
  </ng-template>
</div>
