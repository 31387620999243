import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateTaskService } from '../../../services/create-task.service';
import { DealsFacade } from '../../../../../../../../../+state/deals/deals.facade';

@Component({
  selector: 'app-result-step',
  templateUrl: './result-step.component.html',
  styleUrls: ['./result-step.component.scss'],
})
export class ResultStepComponent implements OnInit {
  @Input() dealId: number;
  @Input() crmCardId: number;
  @Input() isAfterCall: Boolean;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  public isAfterCallId = '';

  public previousStep$ = this.createTaskService.previousStepValue;
  public readonly TRANSLATE_PATH = 'PAGES.DEALS.SALES_FUNNEL.CREATE_TASK_MODAL.RESULT.';
  constructor(private dealsFacade: DealsFacade, private createTaskService: CreateTaskService) {
    if (this.isAfterCall) {
      this.isAfterCallId = '_isAfterCall';
    }
  }

  ngOnInit(): void {
    this.dealsFacade.selectedZenmodeCardTouched({
      dealId: this.dealId,
      cardId: this.crmCardId,
    });
  }

  close() {
    this.closeModal.emit();
  }
}
