import { Component, Input, OnInit } from '@angular/core';
import { DealStage } from '@api-clients/crm-api-client/dist/models';
import { PhoneItem } from '@api-clients/crm-api-client';
import { PopupService } from '../../../../../../../../shared/services/popup-service.service';
import { CreateTaskModalPayload } from '../../../../models/create-task-modal.model';
import { CreateTaskModalComponent } from '../../../create-task-modal/create-task-modal/create-task-modal.component';

@Component({
  selector: 'app-no-active-tasks',
  templateUrl: './no-active-tasks.component.html',
  styleUrls: ['./no-active-tasks.component.scss'],
})
export class NoActiveTasksComponent implements OnInit {
  @Input() crmCardId: number;
  @Input() dealId: number;
  @Input() dealStage: string | DealStage;
  @Input() phoneItem: PhoneItem;
  @Input() dealStatus: number;

  constructor(private popupService: PopupService) {}

  ngOnInit(): void {}

  showCreateTaskModal() {
    const payload: CreateTaskModalPayload = {
      task: null,
      crmCardId: this.crmCardId,
      dealId: this.dealId,
      dealStage: this.dealStage,
      phone: `${this.phoneItem.code}${this.phoneItem.phone}`,
      dealStatus: this.dealStatus,
    };
    this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
  }
}
