import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY } from './chat-messages.reducers';
import { ChatMessagesState } from './chat-messages.state';

export const getChatMessagesFeature = createFeatureSelector<ChatMessagesState>(USER_FEATURE_KEY);

export const getChatMessagesState = createSelector(
  getChatMessagesFeature,
  (payload: ChatMessagesState) => payload,
);
export const getChatMessages = createSelector(
  getChatMessagesFeature,
  (payload: ChatMessagesState) => payload.messages,
);
