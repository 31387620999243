import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CrmQuestionnaireNeed, QuestionnaireItem, QuestionnaireNeed } from '@api-clients/crm-api-client';
import { LastBudgetItem } from '@api-clients/crm-api-client/models/last-budget-item';

export class NestedNeeds {
  key?: string;
  answer?: string;
  alreadySeenAdvantage?: QuestionnaireNeed;
  alreadySeenDisadvantage?: QuestionnaireNeed;
  isFirstCountryVisitAdvantage?: QuestionnaireNeed;
  isFirstCountryVisitDisadvantage?: QuestionnaireNeed;
}

const NEED_LIST_MAX_HEIGHT = 75;

@Component({
  selector: 'app-deals-item-questionnaire',
  templateUrl: './deals-item-questionnaire.component.html',
  styleUrls: ['./deals-item-questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsItemQuestionnaireComponent implements OnInit, AfterViewInit {
  public needsForClient = '';
  public needsForCompanion = '';
  public needsForKids = '';
  public budget = 0;
  public currency = '';
  public currencyLabels = {
    kzt: '₸',
    usd: '$',
    eur: '€',
    uah: '₴',
  };
  public showMore = false;
  public hideToggle = false;

  @Input() questionnaire: QuestionnaireItem;
  @Input() fullHeightMode = false;
  @Input() isShowTitle = true;
  @Input() info: string;
  @Input() lastTags: string[];
  @Input() lastBudget: LastBudgetItem;
  @ViewChild('needsList') needsListElRef: ElementRef;
  @ViewChild('showMoreToggle') showMoreToggle: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.questionnaire) {
      this.initNeedsList();
      this.initBudget();
    }
  }

  initBudget() {
    this.budget = this.questionnaire.budgetTo;
    this.currency = this.currencyLabels[this.questionnaire.currency];
  }

  getListOfNeedsAsString(needList: CrmQuestionnaireNeed[]) {
    if (needList.length) {
      return needList.map(needItem => needItem.name).join(', ');
    }
    return '';
  }

  initNeedsList() {
    this.needsForClient = this.getListOfNeedsAsString(this.questionnaire.needsForClient);
    this.needsForCompanion = this.getListOfNeedsAsString(this.questionnaire.needsForCompanion);
    this.needsForKids = this.getListOfNeedsAsString(this.questionnaire.needsForKids);
  }

  ngAfterViewInit(): void {
    if (this.needsListElRef) {
      this.hideToggle = this.needsListElRef.nativeElement.offsetHeight <= NEED_LIST_MAX_HEIGHT;
      this.cdRef.detectChanges();
    }
  }

  get isQuestionnaireExist() {
    return (
      this.questionnaire &&
      (this.needsForClient || this.needsForCompanion || this.needsForKids || this.budget)
    );
  }

  get isShow() {
    return this.lastTags?.length || this.info || this.lastBudget || this.isQuestionnaireExist;
  }
}
