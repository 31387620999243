<div class="wrapper">
  <div class="info">
    <div class="inner">
      <app-tour-package-number [id]="tourPackage.tourPackage.id" [link]="tourPackage.tourPackage.link">

      </app-tour-package-number>
      <app-tour-operator-request-id
        [tours]="tourPackage.tourPackage.tours"
      >
      </app-tour-operator-request-id>
    </div>
    <div class="inner">
      <app-vacation-dates
        [dateFrom]="tourPackage.tourPackage.dateFrom"
        [dateTo]="tourPackage.tourPackage.dateTo"
      >
      </app-vacation-dates>
      <app-vacation-direction
        [tour]="tourPackage.tourPackage.tours[0]"
      >
      </app-vacation-direction>
    </div>
  </div>

  <div class="second-block" *ngIf="isHavePayInfo">
    <div class="sum">
      {{tourPackage.paymentInfo.leftToPayAmount | number: '1.0-0'}} T
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="10" viewBox="0 0 108 10" fill="none">
      <rect width="108" height="10" rx="5" fill="#E1E9F5"/>
      <rect [attr.width]="paidPercentage" height="10" rx="5" fill="#61DA7B"/>
    </svg>
    <div class="label">
      {{ 'PAGES.DEALS.TOUR_PACKAGE.PAYMENT_INFO.LABEL' | translate}}
    </div>
  </div>

</div>




