import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { PluralizePipe } from '../../../../../../../../helpers/pipes/plural/pluralize.pipe';
import { ContentCreatorContentType } from '../../../../../../../content-creator/interfaces/content-creator.interface';
import { SearchHotelComponent } from '../../../../../../../search/components/hotel/search-hotel.component';
import { SearchTourSelectMode } from '../../../../../../../search/search.model';
import { SearchResultService } from '../../../../../../../search/services/search-result.service';
import { InitSearchRequest } from '../../../../../../../search/services/search/websocket-tours.model';
import { SearchResultGroup } from '../../favorites-hotels.model';
import { FavoritesHotelsSortsComponent } from '../sorts/favorite-hotels-sorts.component';

@Component({
  selector: 'app-favorites-hotels-list',
  templateUrl: './favorite-hotels-list.component.html',
  styleUrls: ['./favorite-hotels-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    SearchHotelComponent,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    VirtualScrollerModule,
    JsonPipe,
    PluralizePipe,
    FavoritesHotelsSortsComponent,
  ],
})
export class FavoriteHotelsListComponent {
  hotels = input<SearchResultGroup[]>([]);
  onlyHotelsPreview = input<boolean>(false);
  toursCount = input<number>(0);
  selectedCountry = input<SearchFormParamsCountry>();
  initSearchRequest = input<InitSearchRequest>();

  countryFlagLink = computed(() => {
    const country = this.selectedCountry();

    return `https://ht.kz/img/flag/${country.code}.png`;
  });

  readonly tourSelectMode = SearchTourSelectMode.calendar;
  readonly contentCreatorType = ContentCreatorContentType;

  constructor(private readonly searchResultsService: SearchResultService) {}

  showResultsComponent(): void {
    this.searchResultsService.showResultComponent();
  }
}
