import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { catchError, first, forkJoin, of } from 'rxjs';
import { NotifyService, NotifyTypeEnum } from '../../../../../shared/notify/services/notify.service';

const SUCCESS_KEY_LANG = 'COMMON.SUCCESS';
const ERROR_KEY_LANG = 'COMMON.ERROR';

@Injectable({
  providedIn: 'root',
})
export class NotifyHelperService {
  private successMessage = 'Success';
  private errorMessage = 'Error';

  constructor(private notifyService: NotifyService, private translate: TranslateService) {
    this.loadTranslations();
  }

  private loadTranslations() {
    forkJoin({
      success: this.translate.get(SUCCESS_KEY_LANG).pipe(
        first(),
        catchError(() => {
          console.error(`Failed to load translation for key: ${SUCCESS_KEY_LANG}`);
          return of(this.successMessage);
        }),
      ),
      error: this.translate.get(ERROR_KEY_LANG).pipe(
        first(),
        catchError(() => {
          console.error(`Failed to load translation for key: ${ERROR_KEY_LANG}`);
          return of(this.errorMessage);
        }),
      ),
    }).subscribe({
      next: ({ success, error }) => {
        this.successMessage = success;
        this.errorMessage = error;
      },
      error: err => {
        console.error('Error loading translations', err);
      },
    });
  }

  public notify(text: string, isSuccess: boolean) {
    this.notifyService.create({
      type: isSuccess ? NotifyTypeEnum.success : NotifyTypeEnum.error,
      title: isSuccess ? this.successMessage : this.errorMessage,
      text,
    });
  }
}
