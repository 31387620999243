import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PhoneItem } from '@api-clients/crm-api-client';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { SipType, SipTypes } from './click2call.model';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';
import { IndicatorEventService } from '../../services/indicator-event.service';
import { IndicatorType } from '../../models/indicator-payload.model';
import { CommonModule } from '@angular/common';
import { Click2callPhonesComponent } from './components/click2call-phones/click2call-phones.component';

@Component({
  selector: 'app-click2call',
  templateUrl: './click2call.component.html',
  styleUrls: ['./click2call.component.scss'],
  imports: [CommonModule, Click2callPhonesComponent],
  standalone: true,
})
export class Click2callComponent implements OnInit, OnDestroy {
  @Input() cardId: number;
  @Input() dealId: number;
  @Input() clientName: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() phones: PhoneItem[];
  @Input() sipType: SipType = SipTypes.Additional;
  public isModalVisible = false;
  isButtonHighlighted = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private dealsFacade: DealsFacade,
    private indicatorEventService: IndicatorEventService,
  ) {}

  toggleCallModal() {
    this.isModalVisible = !this.isModalVisible;
  }

  closeCallModal(withCall?: boolean) {
    this.isModalVisible = false;
    if (withCall) {
      this.dealsFacade.selectedZenmodeCardTouched({
        cardId: this.cardId,
        dealId: this.dealId,
      });
    }
  }

  highlightButton() {
    this.isButtonHighlighted = true;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.indicatorEventService.indicatorEventObservable
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((type: IndicatorType) => {
          if (type === IndicatorType.MissedCall) {
            this.highlightButton();
          }
        }),
        delay(5000),
        tap((type: IndicatorType) => {
          if (type === IndicatorType.MissedCall) {
            this.resetButtonAnimation();
          }
        }),
      )
      .subscribe();
  }

  resetButtonAnimation() {
    this.isButtonHighlighted = false;
  }
}
