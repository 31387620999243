export enum ManagerSipStateEnum {
  online = 'online',
  busy = 'busy',
  manual_busy = 'manual_busy',
}

export interface ManagerSipStateInterface {
  state: ManagerSipStateEnum;
  value: boolean;
}

export const managerSipInitialState: ManagerSipStateInterface = {
  state: ManagerSipStateEnum.online,
  value: true,
};

export enum DealConversationStatusEnum {
  unknown = 0,
  new = 1,
  waiting = 2,
}
