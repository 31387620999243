<div class="templates-dropdown">
  <div class="dropdown-label-container">
    <div class="dropdown-label">Выберите шаблон</div>
  </div>
  <div class="dropdown-items">
    @if (basicTemplates.length) {
      @for (template of basicTemplates; track template.id) {
        <app-favorite-hotels-templates-dropdown-item [template]="template"
                                                     [isActive]="activeTemplate.id === template.id"
                                                     (click)="selectedTemplate(template)" />

      }

      <div class="dropdown-item-line"></div>
    }

    @for (template of managerTemplates; track template.id) {
      <app-favorite-hotels-templates-dropdown-item [template]="template"
                                                   [isActive]="activeTemplate.id === template.id"
                                                   (click)="selectedTemplate(template)" />
    }

    @if (managerTemplates.length) {
      <div class="more-templates">
        <span class="open-link" (click)="openManagerSearch()">Добавить еще шаблон</span>
      </div>
    } @else {
      <div class="no-templates">
        У вас нет своих шаблонов.
        <br>
        <span class="open-link" (click)="openManagerSearch()">Хочу добавить шаблон</span>
      </div>
    }
  </div>
</div>
