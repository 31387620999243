import { Component, Input } from '@angular/core';
import { Tour } from '@api-clients/crm-api-client';
import { ElectronService } from '../../../../../../../../../../core/services';
import { DEAL_OPERATOR_CLAIM_OPENED } from '../../../../../../../../../../core/services/amplitude/amplitudeEvents';
import { AmplitudeTrackService } from '../../../../../../../../../../core/services/amplitude/amplitude-track.service';

@Component({
  selector: 'app-tour-operator-request-id',
  templateUrl: './tour-operator-request-id.component.html',
  styleUrls: ['./tour-operator-request-id.component.scss'],
})
export class TourOperatorRequestIdComponent {
  @Input() tours: Tour[];

  constructor(
    private electronService: ElectronService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  openRequest(operatorClaimUrl: string) {
    this.electronService.shell.openPath(operatorClaimUrl).then(() => {
      this.amplitudeTrackService.trackEvent(DEAL_OPERATOR_CLAIM_OPENED);
    });
  }
}
