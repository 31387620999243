import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complaint-discuss',
  templateUrl: './complaint-discuss.component.html',
  styleUrls: ['./complaint-discuss.component.scss'],
})
export class ComplaintDiscussComponent implements OnInit {
  ngOnInit(): void {}
}
