import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CrmCardListItem, CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DealsListFacade } from '../../../../../../+state/deals-list/deals-list.facade';
import { DealListFilterService } from '../../services/deal-list-filters.service';
import { FeatureToggleService } from './helpers/feature-toggle.service';

@Component({
  selector: 'app-deal-list-content',
  templateUrl: './deal-list-content.component.html',
  styleUrls: ['./deal-list-content.component.scss'],
})

// REFACTOR!!!
export class DealListContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() deals: CrmCardViewItem[];
  @ViewChild('dealsContainer') private dealsContainer: ElementRef;

  public filteredCards: CrmCardViewItem[] = [];
  public isAllowShowOtherDeals$: Observable<boolean>;
  public isDealListNewUi$: Observable<boolean>;
  public isPostponedCategorySelected$: Observable<boolean> = this.dealsListFacade.isPostponedCategorySelected$;

  private destroy$ = new Subject<void>();

  constructor(
    private dealsListFacade: DealsListFacade,
    private featureToggleService: FeatureToggleService,
    private dealListFilterService: DealListFilterService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isAllowShowOtherDeals$ = this.dealsListFacade.isAllowShowOtherDeals$;
    this.isDealListNewUi$ = this.featureToggleService.shouldDisplayNewFeature$;
    this.dealsListFacade.lastSelectedListItemId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.handleDealSelection);

    this.dealListFilterService.filteredDeals$.pipe(takeUntil(this.destroy$)).subscribe(filteredDeals => {
      this.filteredCards = [...filteredDeals];
      this.cdRef.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deals) {
      this.dealListFilterService.setDeals(changes.deals.currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public trackByFn(index: number, deal: CrmCardListItem): number {
    return deal?.deal?.id;
  }

  public toggleAllowToShowOtherDeal() {
    this.dealsListFacade.toggleAllowToShowOtherDeal();
  }

  private handleDealSelection = (lastSelectedDealId: number): void => {
    if (lastSelectedDealId) {
      this.scrollToDeal(lastSelectedDealId);
    }
  };

  private scrollToDeal(dealId: number, retryCount = 0) {
    if (this.dealsContainer && this.dealsContainer.nativeElement) {
      const dealElement = this.dealsContainer.nativeElement.querySelector(`#deal-${dealId}`);
      if (dealElement) {
        dealElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    } else if (retryCount < 4) {
      setTimeout(() => this.scrollToDeal(dealId, retryCount + 1), 100);
    }
  }
}
