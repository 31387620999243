<div class="last-review-modal">
  <div class="last-review-modal__header">
    <span class="last-review-modal__author">{{ review?.author }}</span>
  </div>
  <div class="last-review-modal__body">
    <div class="row">
      <span class="last-review-modal__grade-title">Оценка сервису ht.kz:</span>
      <span class="last-review-modal__grade-value">{{ review?.gradeCompanyService }}/5.0</span>
    </div>
    <div class="row">
      <span class="last-review-modal__like-comment-title">Плюсы: </span>
      <span class="last-review-modal__comment-text">{{ review?.likeComment }}</span>
    </div>
    <div class="row">
      <span class="last-review-modal__not-like-comment-title">Не понравилось: </span>
      <span class="last-review-modal__comment-text">{{ review?.notLikeComment }}</span>
    </div>
  </div>
  <div class="last-review-modal__footer">
    <span class="last-review-modal__rest-dates-title">Период отдыха: </span>
    <span class="last-review-modal__rest-dates-range">с {{ review?.dateFrom }} до {{ review?.dateTo }}</span>
  </div>
</div>
