<div
  class="vacation-modal">
  <div class="modal-header">
    <span class="title">{{'PAGES.DEALS.CLIENT_CARD.VACATION_REMINDER.TITLE' | translate}}</span>
    <button
      (click)="close()"

      class="close-button"></button>
  </div>
  <p class="tip">{{'PAGES.DEALS.CLIENT_CARD.VACATION_REMINDER.TIP' | translate}}</p>
  <div class="date-picker">
    <form [formGroup]="form">
      <app-ui-datetimepicker [dateControl]="dateControl"
                             [timeControl]="timeControl"
                             labelTranslatePath="{{TRANSLATE_PATH}}"
                             [isDisabled]="getIsDisabledForm()"
                             (openPopup)="openDatetimepickerPopup()">
      </app-ui-datetimepicker>
    </form>
  </div>
  <button
    (click)="save()"
    class="save-button">{{'PAGES.DEALS.CLIENT_CARD.VACATION_REMINDER.SAVE' | translate}}</button>
</div>


<div class="fixed-container">
  <app-datetimepicker
    *ngIf="datetimePopup"
    [taskTitle]="'test'"
    [meetingDateTime]="form.controls['date'].value"
    (taskDateEvent)="setMeetingDateTime($event)"
    (manualClose)="closeDatetimepickerPopup($event); $event.stopPropagation()"
    class="timepicker"
  ></app-datetimepicker>
</div>
