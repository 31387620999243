import { Injectable } from '@angular/core';
import {
  ChatContactsListItem,
  CrmCardViewItem,
  WhatsappMessage,
  WhatsappNewMessage,
} from '@api-clients/crm-api-client';
import { ChatRecommendationMessage } from '@api-clients/crm-api-client/models/chat-recommendation-message';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, Observable, take, tap } from 'rxjs';
import { INewMessages, IStatuses } from '../../../app/models/whatsapp';
import { ChatRecommendation } from '../../modules/chats/interfaces/chat.interface';
import { UpdateUnreadChatsCount } from '../whatsapp-contacts/whatsapp-contacts.actions';
import * as ChatsActions from './chats.actions';
import { ChatsState } from './chats.reducer';
import {
  selectAllChatContacts,
  selectChatContactsError,
  selectChatContactsLoading,
  selectChatRecommendation,
  selectCurrentChatContact,
  selectCurrentChatContactCrmCard,
  selectCurrentChatContactCrmCardLoading,
  selectCurrentChatContactNextTaskLoading,
  selectIsSearching,
  selectSearchResults,
} from './chats.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChatsFacade {
  chatContacts$: Observable<ChatContactsListItem[]> = this.store.select(selectAllChatContacts);
  loading$: Observable<boolean> = this.store.select(selectChatContactsLoading);
  error$: Observable<string> = this.store.select(selectChatContactsError);
  currentChatContact$: Observable<ChatContactsListItem> = this.store.select(selectCurrentChatContact);
  currentChatContactCrmCard$: Observable<CrmCardViewItem> = this.store.select(
    selectCurrentChatContactCrmCard,
  );
  currentChatContactCrmCardLoading$: Observable<boolean> = this.store.select(
    selectCurrentChatContactCrmCardLoading,
  );
  currentChatContactNextTaskLoading$: Observable<boolean> = this.store.select(
    selectCurrentChatContactNextTaskLoading,
  );
  chatRecommendation$: Observable<ChatRecommendation> = this.store.select(selectChatRecommendation);
  searchResults$: Observable<ChatContactsListItem[]> = this.store.select(selectSearchResults);
  isSearching$: Observable<boolean> = this.store.select(selectIsSearching);

  private lastMessageIsFromMe = false;

  constructor(private store: Store<ChatsState>) {}

  loadChatContacts() {
    this.store.dispatch(ChatsActions.loadChatContacts());
    // this.store.dispatch(ChatsActions.loadChatRecommendation());
  }

  searchContacts(searchQuery: string): void {
    this.store.dispatch(ChatsActions.searchContacts({ searchQuery }));
  }

  clearSearch(): void {
    this.store.dispatch(ChatsActions.clearSearchResults());
  }

  chooseChatContact(chatContact: ChatContactsListItem) {
    this.store.dispatch(ChatsActions.chooseChatContact({ chatContact }));
  }

  updateChatContact(chatContact: ChatContactsListItem) {
    this.store.dispatch(ChatsActions.updateChatContact({ chatContact }));
  }

  loadDealViewForChat(crmCardId: number) {
    this.store.dispatch(ChatsActions.loadDealViewForChat({ crmCardId }));
  }

  loadDealNextTask(crmCardId: number) {
    this.store.dispatch(ChatsActions.loadDealViewNextTask({ crmCardId }));
  }

  loadChatContact(contactId: string) {
    this.store.dispatch(ChatsActions.loadChatContact({ contactId }));
  }

  resetCurrentChatState() {
    this.store.dispatch(ChatsActions.resetCurrentChatState());
  }

  readChat(contactId: string, fromPlace: string) {
    if (!this.lastMessageIsFromMe) {
      this.store.dispatch(ChatsActions.readChat({ contactId, fromPlace }));
    }
  }

  updateChatContactsList(newMessages: INewMessages) {
    newMessages.messages.forEach((newMessage: WhatsappNewMessage) => {
      const contactId = newMessage.contact.id;
      const message = newMessage.message;
      this.checkAndUpdateContact(contactId, newMessage.message);

      this.lastMessageIsFromMe = message.isFromMe;

      if (!message.isFromMe) {
        this.currentChatContact$
          .pipe(
            take(1),
            filter(currentChatContact => currentChatContact?.contact.id === contactId),
            debounceTime(1000),
          )
          .subscribe(() => {
            this.readChat(contactId, 'hermes_new_message_received');
          });
      }
    });
  }

  updateUnreadChatsCounter(newUnreadChatsCount: number) {
    this.store.dispatch(new UpdateUnreadChatsCount({ unreadChatCount: newUnreadChatsCount }));
  }

  refreshStatusOfMessages(statuses: IStatuses) {
    this.store.dispatch(ChatsActions.refreshMessageStatuses({ statuses }));
  }

  markRecommendationMessageAsDeleted(message: ChatRecommendationMessage): void {
    this.store.dispatch(ChatsActions.markRecommendationMessageAsDeleted(message));
  }

  markRecommendationMessageAsSent(message: ChatRecommendationMessage, changedText: string = null): void {
    this.store.dispatch(ChatsActions.markRecommendationMessageAsSent({ message, changedText }));
  }

  private checkAndUpdateContact(contactId: string, message: WhatsappMessage) {
    this.chatContacts$
      .pipe(
        take(1),
        // Проверяем есть контакт из сообщения в нашем текущем списке
        map(chatContacts => chatContacts.find(contact => contact.contact.id === contactId)),
        tap(existingContact => {
          if (existingContact) {
            // Если контакт есть, то обновляем у него last message и сортируем список
            this.updateExistingContact(existingContact, message);
          } else {
            // В противном случае загружаем контакт и добавляем в список
            this.loadNewContact(contactId);
          }
        }),
      )
      .subscribe();
  }

  private updateExistingContact(existingContact: ChatContactsListItem, message: WhatsappMessage) {
    const { unreadMessageCount } = existingContact.contact;
    const updatedUnreadMessageCount = message.isFromMe ? unreadMessageCount : unreadMessageCount + 1;

    this.store.dispatch(
      ChatsActions.updateChatContact({
        chatContact: {
          ...existingContact,
          contact: {
            ...existingContact.contact,
            unreadMessageCount: updatedUnreadMessageCount,
          },
          lastMessage: message,
        },
      }),
    );
  }

  private loadNewContact(contactId: string) {
    this.store.dispatch(ChatsActions.loadChatContact({ contactId }));
  }
}
