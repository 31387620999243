import { Component, Input } from '@angular/core';
import { WhatsappMessageItemTypes } from '../message-item.interface';

@Component({
  selector: 'app-message-pending',
  templateUrl: './message-pending.component.html',
  styleUrls: ['./message-pending.component.scss'],
})
export class MessagePendingComponent {
  @Input() messageType;
  @Input() messageText;
  @Input() isFromMe: boolean;
  public MessageTypes = WhatsappMessageItemTypes;
}
