import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NoteCreateRequest } from '@api-clients/crm-api-client';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';
import { ApiResponse } from '../../../../../../backend/src/models/models';

@Injectable({
  providedIn: 'root',
})
export class NoteCreateService {
  constructor(private http: HttpClient) {}

  noteCreate(body: NoteCreateRequest) {
    return this.http
      .post<ApiResponse<NoteCreateRequest>>(`${AppConfig.apiUrl}/note/create`, body)
      .pipe(apiResponsePipe);
  }
}
