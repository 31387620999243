import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { AvatarMode } from '../../../../deals-list/deal-list';
import { IndicatorType } from '../../../models/indicator-payload.model';
import { IndicatorEventService } from '../../../services/indicator-event.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-client-avatar',
  templateUrl: './client-avatar.component.html',
  styleUrls: ['./client-avatar.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ClientAvatarComponent implements OnInit, OnDestroy {
  @Input() clientAvatar: string;
  @Input() mode: AvatarMode = AvatarMode.dealList;
  @Input() isReturnTourist: boolean;
  @Input() isNew: boolean;
  isHighlighted = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private indicatorEventService: IndicatorEventService) {}

  get isScalable(): boolean {
    return this.mode === AvatarMode.dealList && !!this.clientAvatar;
  }

  get avatarSrc(): string {
    return this.clientAvatar ? this.clientAvatar : './assets/icons/client-avatar.svg';
  }

  get statusSrc(): string {
    return this.isReturnTourist
      ? './assets/icons/deal-view/return-tourist.svg'
      : './assets/icons/deal-view/new-client.svg';
  }

  get isDealViewMode(): boolean {
    return this.mode === AvatarMode.dealView;
  }

  highlightAvatar() {
    this.isHighlighted = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.indicatorEventService.indicatorEventObservable
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((type: IndicatorType) => {
          if (type === IndicatorType.NewClient) {
            this.highlightAvatar();
          }
        }),
        delay(5000),
        tap((type: IndicatorType) => {
          if (type === IndicatorType.NewClient) {
            this.resetAvatarAnimation();
          }
        }),
      )
      .subscribe();
  }

  resetAvatarAnimation() {
    this.isHighlighted = false;
  }
}
