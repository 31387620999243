import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoteCreateComponent } from './components/note-save/note-create.component';
import { NoteCreateService } from './services/note-create.service';

@NgModule({
  declarations: [NoteCreateComponent],
  exports: [NoteCreateComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [NoteCreateService],
})
export class NotesModule {}
