import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum NotifyTypeEnum {
  simple = 'simple',
  success = 'success',
  error = 'error',
}

export interface Notify {
  id?: number;
  title?: string;
  text?: string;
  textList?: string[];
  formErrors?: any;
  type: NotifyTypeEnum;
}

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  public createEvent: Subject<Notify> = new Subject<Notify>();
  public formErrors: any;
  public formErrorsText: string[] = [];

  create(notify: Notify): void {
    if (notify.formErrors) {
      notify.textList = this.flatErrors(notify.formErrors);
    }

    this.createEvent.next(notify);
  }

  flatErrors(formErrors: any): string[] {
    const joinedErrorsText = [];

    formErrors.forEach(formField => {
      joinedErrorsText.push(formField.errors.join(', '));
    });

    return joinedErrorsText;
  }
}
