import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  output,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import toWav from 'audiobuffer-to-wav';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { AudioRecorderService, OutputFormat } from '../../../../../../shared/services/audio-recorder.service';
import { AudioType } from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/interfaces/audio.interface';
import { RecorderState } from './recorder-state.interface';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AudioRecorderService],
})
export class AudioRecorderComponent {
  @Output() public recorderOutput: EventEmitter<Blob> = new EventEmitter();
  @Output() public sending = new EventEmitter();
  @Output() public startRecordingOutput = new EventEmitter();
  @Output() public removeRecordingOutput = new EventEmitter();
  public transcriptionResult = output<string>();

  public RecorderState = RecorderState;
  public recorderState: RecorderState = RecorderState.INITIALIZING;
  public currentBlob: Blob;
  public currentBlobUrl = '';
  public isShowRecordedAudio = true;
  public currentBlobDuration: number;

  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public readonly recordedAudioType = AudioType.recordedAudio;

  constructor(
    private audioRecorderService: AudioRecorderService,
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    this.audioRecorderService.recorderError.subscribe(recorderErrorCase => {
      console.log('error', recorderErrorCase);
    });
  }

  startRecording() {
    this.audioRecorderService.startRecording();
    this.recorderState = RecorderState.RECORDING;
    this.startRecordingOutput.emit();
    this.cdRef.detectChanges();
  }

  stopRecording() {
    this.recorderState = RecorderState.STOPPING;
    this.cdRef.detectChanges();

    this.audioRecorderService
      .stopRecording(OutputFormat.WEBM_BLOB)
      .then((outputBlob: Blob) => {
        if (outputBlob) {
          this.currentBlobUrl = URL.createObjectURL(outputBlob);

          this.processAudio(outputBlob).then(([duration, wavBlob]) => {
            this.currentBlobDuration = duration;

            this.currentBlob = wavBlob;
            this.recorderState = this.audioRecorderService.getRecorderState();
            this.cdRef.detectChanges();
          });
        }
      })
      .catch(errorCase => {
        console.log(errorCase);
      });
  }

  pause() {
    this.stopRecording();
  }

  removeRecord() {
    this.currentBlobUrl = '';
    this.currentBlob = null;
    this.recorderState = RecorderState.INITIALIZED;
    this.removeRecordingOutput.emit();
    this.cdRef.detectChanges();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  sendAudio() {
    this.sending.emit();
    if (!this.currentBlobUrl || !this.currentBlob) {
      this.isShowRecordedAudio = false;
      this.cdRef.detectChanges();
      this.stopRecording();
    }
    this.recorderState = RecorderState.BUFFERING;
    this.cdRef.detectChanges();
    /*
    Тайм-аут нужен для того чтобы записанное аудио успело сбуферизоваться
    Эмит тут отправляет blob объект в родительский компонент, который отправляет его переобразуя в файл
     */
    setTimeout(() => {
      this.recorderOutput.emit(this.currentBlob);
      this.recorderState = RecorderState.INITIALIZED;
      this.currentBlob = null;
      this.currentBlobUrl = '';
      this.isShowRecordedAudio = true;
      this.cdRef.detectChanges();
    }, 1000);
  }

  get isCanRecord() {
    return (
      this.recorderState === RecorderState.INITIALIZING || this.recorderState === RecorderState.INITIALIZED
    );
  }

  get isCanRemoveRecord() {
    return this.recorderState === RecorderState.STOPPED && this.currentBlobUrl.length;
  }

  get isCanSendAudio() {
    return this.recorderState === RecorderState.STOPPED && this.currentBlobUrl.length;
  }

  get isShowOverlay() {
    return (
      this.recorderState === RecorderState.RECORDING ||
      this.recorderState === RecorderState.STOPPING ||
      this.recorderState === RecorderState.STOPPED
    );
  }

  get isShowAudioElement() {
    return (
      this.currentBlobUrl.length && this.recorderState === RecorderState.STOPPED && this.isShowRecordedAudio
    );
  }

  async processAudio(blob: Blob): Promise<[number, Blob]> {
    const audioContext = new window.AudioContext();
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const duration = audioBuffer.duration;

    const wavBuffer = toWav(audioBuffer);
    const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });

    return [duration, wavBlob];
  }
}
