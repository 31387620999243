import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { WhatsappMessage } from '@api-clients/crm-api-client';
import { AudioType } from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/interfaces/audio.interface';
import { ImageGroup, WhatsappMessageTypeList } from '../../../../interfaces/chat-timeline.interface';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { ElectronService } from '../../../../../../core/services';

const MESSAGE_MAX_HEIGHT = 70;

@Component({
  selector: 'app-chat-timeline-item-message',
  templateUrl: './chat-timeline-item-message.component.html',
  styleUrls: ['./chat-timeline-item-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTimelineItemMessageComponent implements AfterViewInit {
  @Input() message: WhatsappMessage;
  @Output() maximized: EventEmitter<string> = new EventEmitter();
  @ViewChild('messageText') messageTextElRef: ElementRef;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public types = WhatsappMessageTypeList;
  public showMore = false;
  public hideToggle = false;
  public audioMessageType = AudioType.audioMessage;

  constructor(private cdRef: ChangeDetectorRef, private electron: ElectronService) {}

  // TODO Других вариантов не видел, чтобы сделать результат без переписывания большого количества кода
  get imageGroup(): ImageGroup {
    return this.message as unknown as ImageGroup;
  }

  ngAfterViewInit(): void {
    if (this.messageTextElRef) {
      this.hideToggle = this.messageTextElRef.nativeElement.offsetHeight < MESSAGE_MAX_HEIGHT;
      this.cdRef.detectChanges();
    }
  }

  maximize() {
    this.maximized.emit(this.message.id);
  }

  openLink(event) {
    if (event.target.tagName === 'A') {
      this.electron.shell.openExternal(event.target.href).then(() => {});
      event.preventDefault();
    }
  }
}
