import { createAction, props } from '@ngrx/store';
import { TourSelectionTabs } from '../../modules/tour-selection/tour-selection.model';

export const openTourSelection = createAction('[Tour Selection] Open Tour Selection');
export const closeTourSelection = createAction('[Tour Selection] Close Tour Selection');

export const setCurrentTab = createAction(
  '[Tour Selection] Set Current Tab',
  props<{ tab: TourSelectionTabs }>(),
);
