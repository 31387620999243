<div class="country-container">
  @if (selectedCountry()) {
    <div class="country-info-container">
      <div class="country-name">
        <img [attr.src]="countryFlagLink()" class="country-flag">
        {{ selectedCountry().name }}
      </div>

      @if (!onlyHotelsPreview()) {
        <div class="count-and-map">
          @if (toursCount()) {
            <div>{{ toursCount() | pluralize:'отель,отеля,отелей' }}</div>
          }
          <div class="show-tours-on-map" (click)="showResultsComponent()">
            <img src="assets/icons/search/search-on-map-marker.svg">
            <div>На карте</div>
          </div>
        </div>
      }
    </div>
  }
  <div class="country-tours">
    @if (hotels().length > 0) {
      <virtual-scroller #scroll [items]="hotels()" [checkResizeInterval]="2000">
        @for (item of scroll.viewPortItems; track item.hotel.id) {
          <app-search-hotel
            [contentCreatorType]="contentCreatorType.ManagerPricesCalendar"
            [tourSelectMode]="tourSelectMode"
            [initSearchRequest]="initSearchRequest()"
            [allowResearch]="!onlyHotelsPreview()"
            [allowReCalculate]="!onlyHotelsPreview()"
            [hotel]="item.hotel"
            [initSearchTours]="item.tours" />
        }
      </virtual-scroller>
    } @else {
      <div class="no-hotels">Нет отелей</div>
    }
  </div>
</div>

