import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  CrmCardListPostSaleTourPackage,
  CrmCardListPostSaleTourPackageStageEnum,
  Payer,
  CrmTaskItem,
} from '@api-clients/crm-api-client/dist/models';
import { AvatarMode } from '../../../../../../deal-list';

@Component({
  selector: 'app-post-sale-card-header',
  templateUrl: './post-sale-card-header.component.html',
  styleUrls: ['./post-sale-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostSaleCardHeaderComponent implements OnInit {
  @Input() tourPackage: CrmCardListPostSaleTourPackage;
  @Input() phone: string;
  @Input() dealId: number;
  @Input() phoneCode: string;
  @Input() payer: Payer;
  @Input() id: number;
  @Input() isReturnTourist: boolean;
  @Input() nextTask: CrmTaskItem;
  @Input() clientAvatar: string;

  public name: string;
  public isGreenBorder = false;
  public avatarMode = AvatarMode.dealList;
  public isHavePayDeadline = false;

  constructor(private cdRef: ChangeDetectorRef) {}
  ngOnInit() {
    this.name = `${this.payer.firstName} ${this.payer.secondName}`;
    if (this.tourPackage?.stage === CrmCardListPostSaleTourPackageStageEnum.OnRest) {
      this.isGreenBorder = true;
      this.cdRef.detectChanges();
    }

    if (
      this.tourPackage.paymentInfo &&
      this.tourPackage.claimNextPaymentInfo?.payDeadline &&
      this.tourPackage.stage === CrmCardListPostSaleTourPackageStageEnum.WaitPayment
    ) {
      this.isHavePayDeadline = true;
      this.cdRef.detectChanges();
    }
  }
}
