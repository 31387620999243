import { Action } from '@ngrx/store';
import { CurrentCallPayload, SearchOpenedPayload } from './call.interface';

export enum CallsActionTypes {
  UpdateCurrentCall = '[Call] Update Call',
  SetSearchOpened = '[Call] Open Search In Call',
  ResetCall = '[Call] Reset call',
}

export class UpdateCurrentCall implements Action {
  readonly type = CallsActionTypes.UpdateCurrentCall;
  constructor(public payload: CurrentCallPayload) {}
}
export class ResetCall implements Action {
  readonly type = CallsActionTypes.ResetCall;
}
export class SetSearchOpened implements Action {
  readonly type = CallsActionTypes.SetSearchOpened;
  constructor(public payload: SearchOpenedPayload) {}
}

export type CallsAction = SetSearchOpened | UpdateCurrentCall | ResetCall;
