<nav class="aside-nav">
  <a [routerLink]="['/deals']" routerLinkActive="active" class="aside-nav-clients">
    {{ 'LAYOUT.ASIDE.DEALS' | translate }}
    <span *ngIf="dealsTotalNumber$ | async as totalDeals" class="counter">{{ totalDeals }}</span>
  </a>
  @if (shouldDisplayChats$ | async) {
    <a [routerLink]="['/chats']" routerLinkActive="active" class="aside-nav-chats">
      {{ 'LAYOUT.ASIDE.CHATS' | translate }}
      <span *ngIf="unreadChatsCount$ | async as unreadChatsCount" class="counter">{{
        unreadChatsCount
      }}</span>
    </a>
    @for (stage of specificStages$ | async; track stage.count) {
      @switch (stage.name) {
        @case (currentStageEnum.missed) {
          <a
            [routerLink]="['/deals']"
            (click)="openMissedAndUrgentDeals(stage)"
            class="deal-shortcut deal-shortcut-missed-and-urgent"
          >
            <span *ngIf="stage.count" class="counter">{{ stage.count }}</span>
          </a>
        }
        @case (currentStageEnum.new) {
          <a [routerLink]="['/deals']" (click)="openNewDeals(stage)" class="deal-shortcut deal-shortcut-new">
            <span *ngIf="stage.count" class="counter">{{ stage.count }}</span>
          </a>
        }
      }
    }
  } @else {
    <!-- TODO: Delete if no need in future-->
    <app-aside-chats *ngIf="(isOnlyZenmode$ | async) === false"> </app-aside-chats>
  }
</nav>
