import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatScrollerService {
  private scrollTopSubject = new Subject<void>();
  private scrollTop: number;
  private scrollHeightBefore: number;
  private scrollContainer: HTMLElement;
  private scrollBottomSubject = new Subject<void>();

  setScrollContainer(element: HTMLElement) {
    this.scrollContainer = element;
  }

  saveScrollPosition(scrollTop: number, scrollHeight: number) {
    this.scrollTop = scrollTop;
    this.scrollHeightBefore = scrollHeight;
  }

  restoreScrollPosition() {
    if (this.scrollContainer) {
      const newScrollTop = this.scrollTop + (this.scrollContainer.scrollHeight - this.scrollHeightBefore);
      this.scrollContainer.scrollTop = newScrollTop;
    }
  }

  public notifyScrollTopReached() {
    this.scrollTopSubject.next();
  }

  public onScrollTopReached() {
    return this.scrollTopSubject.asObservable();
  }

  public notifyScrollBottomNeeded() {
    this.scrollBottomSubject.next();
  }

  public needToScrollBottom() {
    return this.scrollBottomSubject.asObservable();
  }
}
