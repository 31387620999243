<div  class="content rounded-border overflow-y">
  <div class='call-list-header'>
    <h3 class='call-list-title'> {{ 'PAGES.CALL_LIST.TITLE' | translate }}  </h3>
    <p-calendar
      [(ngModel)]='selectedDate'
      (onSelect)='getSelected()'
      dateFormat="yy-mm-dd"
      [firstDayOfWeek]="1">
    </p-calendar>
  </div>

  <ng-container *ngIf='callList' >
      <app-call-item
        *ngFor='let call of callList'
        [callItem]='call'>
      </app-call-item>
  </ng-container>

  <div  class='call-list-item call-list-empty' *ngIf='!callList.length'>
    История звонков пуста. Поговорите с кем-нибудь по телефону, чтобы исправить это :)
  </div>
</div>

<ng-container *ngIf="isLoading">
  <app-preloader></app-preloader>
</ng-container>



