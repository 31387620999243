<div [ngClass]="  {'inside-circle-status' : isOnlyImage}">
  <div
    class="message-status" [ngClass]="isFromMe ? 'from-me' : 'from-client'"
  >
    <p class="date">{{date | date:'shortTime'}}</p>
    <div class="status" *ngIf="isFromMe">
      <img
        *ngIf="isRead"
        src="./assets/icons/whatsapp/message-status/read.svg" alt="Прочитано">
      <img
        *ngIf="isSent"
        src="./assets/icons/whatsapp/message-status/sent.svg" alt="Отправлено">
      <img
        *ngIf="isReceived"
        src="./assets/icons/whatsapp/message-status/received.svg" alt="Доставлено">
      <img
        *ngIf="isInFlight"
        src="./assets/icons/whatsapp/message-status/in-flight.svg" alt="Отправляется">
      <img
        *ngIf="isUnknown"
        src="./assets/icons/whatsapp/message-status/in-flight.svg" alt="Неизвестно">
    </div>
  </div>

</div>


