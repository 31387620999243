import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CrmCardListPostSaleTourPackage } from '@api-clients/crm-api-client/dist/models';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ConfirmTourPackageRequest } from '@api-clients/tour-packages-api-client/dist/models';
import { first, take } from 'rxjs/operators';
import { TourPackagesApiService } from './services/tour-packages-api.service';
import { DealsFacade } from '../../../../../../../../../../+state/deals/deals.facade';
import {
  Notify,
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../../../shared/notify/services/notify.service';

const FLIGHTS_TITLE_LANG_KEY = 'PAGES.DEALS.TOUR_PACKAGE.FLIGHT_INFO';
const TOURISTS_TITLE_LANG_KEY = 'PAGES.DEALS.TOUR_PACKAGE.TOURISTS';
const TOUR_INFO_TITLE_LANG_KEY = 'PAGES.DEALS.TOUR_PACKAGE.TOUR_INFO';
const COMMENT_TITLE_LANG_KEY = 'PAGES.DEALS.TOUR_PACKAGE.COMMENT_FROM_TOUR_PACKAGE';
const SUCCESS_LANG_KEY = 'COMMON.SUCCESS';
const FAIL_LANG_KEY = 'COMMON.FAIL';

export enum TourPackageStageEnum {
  WaitConfirm = 'waitConfirm',
  WaitPayment = 'waitPayment',
  WaitDocs = 'waitDocs',
  WaitFlight = 'waitFlight',
  OnRest = 'onRest',
  Review = 'review',
}

@Component({
  selector: 'app-tour-package-info',
  templateUrl: './tour-package-info.component.html',
  styleUrls: ['./tour-package-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourPackageInfoComponent {
  @Input() tourPackage: CrmCardListPostSaleTourPackage;
  public notificationTitle: string;
  public flightsTitle: string;
  public touristsTitle: string;
  public tourInfoTitle: string;
  public commentTitle: string;
  public success: string;
  public fail: string;
  public tourPackageStageEnum = TourPackageStageEnum;
  public isLoading = false;
  constructor(
    private translateService: TranslateService,
    private translatePipe: TranslatePipe,
    private tourPackagesApiService: TourPackagesApiService,
    private dealsFacade: DealsFacade,
    private cdRef: ChangeDetectorRef,
    private notifyService: NotifyService,
  ) {
    this.translateService
      .get(FLIGHTS_TITLE_LANG_KEY)
      .pipe(first())
      .subscribe((translated: string) => {
        this.flightsTitle = translated;
      });
    this.translateService
      .get(TOURISTS_TITLE_LANG_KEY)
      .pipe(first())
      .subscribe((translated: string) => {
        this.touristsTitle = translated;
      });
    this.translateService
      .get(TOUR_INFO_TITLE_LANG_KEY)
      .pipe(first())
      .subscribe((translated: string) => {
        this.tourInfoTitle = translated;
      });
    this.translateService
      .get(COMMENT_TITLE_LANG_KEY)
      .pipe(first())
      .subscribe((translated: string) => {
        this.commentTitle = translated;
      });
    this.translateService
      .get(SUCCESS_LANG_KEY)
      .pipe(first())
      .subscribe((translated: string) => {
        this.success = translated;
      });
    this.translateService
      .get(FAIL_LANG_KEY)
      .pipe(first())
      .subscribe((translated: string) => {
        this.fail = translated;
      });
  }

  confirmTourpackage() {
    this.isLoading = true;
    const body: ConfirmTourPackageRequest = {
      tourPackageId: this.tourPackage.tourPackage.id,
    };
    this.tourPackagesApiService.confirm(body).subscribe(result => {
      let text;
      if (result.message) {
        text = result.message;
      } else if (result.success) {
        text = this.success;
      } else {
        text = this.fail;
      }
      const notify: Notify = {
        text,
        title: this.translatePipe.transform('PAGES.DEALS.TOUR_PACKAGE.ACTIONS.CONFIRM_TOURPACKAGE'),
        type: result.success ? NotifyTypeEnum.success : NotifyTypeEnum.error,
      };
      this.notifyService.create(notify);
      this.dealsFacade.loadAll();
      this.loaded();
    });
  }

  markClientDocumentsAsReady() {
    this.isLoading = true;
    this.tourPackagesApiService
      .markClientDocumentsAsReady(this.tourPackage.tourPackage.id)
      .pipe(take(1))
      .subscribe(result => {
        let text;
        if (result.message) {
          text = result.message;
        } else if (result.success) {
          text = this.success;
        } else {
          text = this.fail;
        }
        const notify: Notify = {
          text,
          title: this.translatePipe.transform('PAGES.DEALS.TOUR_PACKAGE.ACTIONS.DOCUMENTS_READY'),
          type: result.success ? NotifyTypeEnum.success : NotifyTypeEnum.error,
        };
        this.notifyService.create(notify);
        this.dealsFacade.loadAll();
        this.loaded();
      });
  }

  markClientDocumentsAsReceived() {
    this.isLoading = true;
    this.tourPackagesApiService
      .markClientDocumentsAsReceived(this.tourPackage.tourPackage.id)
      .pipe(take(1))
      .subscribe(result => {
        let text;
        if (result.message) {
          text = result.message;
        } else if (result.success) {
          text = this.success;
        } else {
          text = this.fail;
        }
        const notify: Notify = {
          text,
          title: this.translatePipe.transform('PAGES.DEALS.TOUR_PACKAGE.ACTIONS.DOCUMENTS_RECEIVED'),
          type: result.success ? NotifyTypeEnum.success : NotifyTypeEnum.error,
        };
        this.notifyService.create(notify);
        this.dealsFacade.loadAll();
        this.loaded();
      });
  }

  public loaded() {
    this.isLoading = false;
    this.cdRef.detectChanges();
  }
}
