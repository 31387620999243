import { Component, Input, OnInit } from '@angular/core';
import { AddDealPopupService } from '../../../../../add-deal/services/add-deal-popup.service';

@Component({
  selector: 'app-no-active-deals',
  templateUrl: './no-active-deals.component.html',
  styleUrls: ['./no-active-deals.component.scss'],
})
export class NoActiveDealsComponent implements OnInit {
  @Input() phone?: string;
  @Input() name?: string;

  constructor(private addDealPopup: AddDealPopupService) {}

  ngOnInit(): void {}

  showAddDealPopup() {
    this.addDealPopup.showAddDealPopup(this.phone, this.name);
  }
}
