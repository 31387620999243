<table class="zenmode-table">
  <thead>
  <tr>
    <th>N</th>
    <th scope="col">Название сделки</th>
    <th scope="col">New Message</th>
    <th scope="col">Статус</th>
    <th scope="col">Next Task</th>
    <th scope="col">Возвратность</th>
    <th scope="col">Время вылета</th>
    <th scope="col">Время назначения</th>
    <th scope="col">Shadow task</th>
    <th scope="col">Last message</th>
    <th scope="col">Источник</th>

  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of combinedDataWithSelected$ | async; index as i"
      [class.selected]="item.isSelected">
    <td><a [routerLink]="['/deals/view', item.card.id]">{{i+1}}</a></td>
    <td>{{item.card.name ? item.card.name : item.card.phone}}</td>
    <td *ngIf="item.deal?.conversationStatus as conversationStatus; else emptyField">{{conversationStatus === 1 ? 'Да' : 'Нет'}}</td>
    <td>{{dealStatus[item.deal.status]}}</td>
    <td *ngIf="item.nextTask?.type; else emptyField">{{'PAGES.DEALS.CRM_TASK_TYPES.' + item.nextTask.type | translate}}</td>
    <td>{{item.deal.isReturnTourist ? "Возвратный": "Новый"}}</td>
    <td *ngIf="item.lastSearchRequest; else emptyField">{{item.lastSearchRequest.dateFrom | date: 'dd.MM'}}</td>
    <td *ngIf="item.nextTask?.date; else emptyField">{{item.nextTask.date + ' '+ item.nextTask.time  | date: dateFormat}}</td>
    <td *ngIf="item.shadowNextTask?.date; else emptyField">
      {{item.shadowNextTask.date + ' '+ item.shadowNextTask.time  | date: dateFormat}}</td>
    <td *ngIf="item.lastWhatsappMessage; else emptyField">{{item.lastWhatsappMessage.receivedAt | date: 'dd.MM HH:mm:ss'}}</td>
    <td *ngIf="item.deal.clientSource; else emptyField">{{item.deal.clientSource.title}}</td>

  </tr>
  </tbody>
</table>

<ng-template #emptyField>
  <td>-</td>
</ng-template>
