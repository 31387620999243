import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from '../../../../shared/pipes/filter.pipe';
import { ChatListComponent } from './components/chat-list/chat-list.component';
import { ChatListItemComponent } from './components/chat-list-item/chat-list-item.component';
import { GetInitialsModule } from '../../../../helpers/pipes/getInitials/get-initials.module';

@NgModule({
  declarations: [ChatListComponent, ChatListItemComponent],
  imports: [CommonModule, GetInitialsModule, FormsModule, TranslateModule, FilterPipe],
  exports: [ChatListComponent],
})
export class ChatListModule {}
