import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TourPackageInfoComponent } from './tour-package-info.component';
import { FlightsInfoItemComponent } from './components/flights-info-item/flights-info-item.component';
import { TourPackageFlightsInfoComponent } from './components/tour-package-flights-info/tour-package-flights-info.component';
import { TourPackageInfoCommentComponent } from './components/tour-package-info-comment/tour-package-info-comment.component';
import { TourPackageInfoTitleComponent } from './components/tour-package-info-title/tour-package-info-title.component';
import { TourPackageInfoTouristsComponent } from './components/tour-package-info-tourists/tour-package-info-tourists.component';
import { TourPackageTourInfoComponent } from './components/tour-package-tour-info/tour-package-tour-info.component';
import { TouristInfoItemComponent } from './components/tourist-info-item/tourist-info-item.component';
import { TourPackagesApiService } from './services/tour-packages-api.service';

@NgModule({
  declarations: [
    TourPackageInfoComponent,
    FlightsInfoItemComponent,
    TourPackageFlightsInfoComponent,
    TourPackageInfoCommentComponent,
    TourPackageInfoTitleComponent,
    TourPackageInfoTouristsComponent,
    TourPackageTourInfoComponent,
    TouristInfoItemComponent,
  ],
  imports: [CommonModule, TranslateModule],
  providers: [TourPackagesApiService],
  exports: [TourPackageInfoComponent],
})
export class TourPackageInfoModule {}
