import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientStatusIconComponent } from './client-status-icon.component';

@NgModule({
  declarations: [ClientStatusIconComponent],
  imports: [CommonModule],
  exports: [ClientStatusIconComponent],
})
export class ClientStatusIconModule {}
