import { createReducer, on } from '@ngrx/store';
import { TourSelectionTabs } from '../../modules/tour-selection/tour-selection.model';
import * as TourSelectionActions from './tour-selection.actions';

export const FEATURE_KEY = 'tour-selection';

// TODO: Refactor state interface to contacts, chats, groups, selectedItem, and perform objects mapping to own interfaces
export interface TourSelectionState {
  isTourSelectionOpen: boolean;
  currentTab: TourSelectionTabs;
}

export const initialState: TourSelectionState = {
  isTourSelectionOpen: false,
  currentTab: TourSelectionTabs.HOT_OFFERS,
};

export const tourSelectionReducer = createReducer(
  initialState,
  on(TourSelectionActions.openTourSelection, state => ({
    ...state,
    isTourSelectionOpen: true,
  })),
  on(TourSelectionActions.closeTourSelection, state => ({
    ...state,
    isTourSelectionOpen: false,
  })),
  on(TourSelectionActions.setCurrentTab, (state, { tab }) => ({
    ...state,
    currentTab: tab,
  })),
);
