import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { dealsReducer, dealsInitialState } from '../../+state/deals/deals.reducers';
import { DealsEffects } from '../../+state/deals/deals.effects';
import { DealsFacade } from '../../+state/deals/deals.facade';
import { SharedModule } from '../../shared/shared.module';
import { CrmCardUpdateService } from './modules/deal-view/services/crm-card-update.service';
import { FormComponentsModule } from '../form-components/form-components.module';
import { CallsFacade } from '../../+state/calls/calls.facade';
import { callsReducer } from '../../+state/calls/calls.reducers';
import { callInitialState } from '../../+state/calls/calls.state';
import { GetInitialsModule } from '../../helpers/pipes/getInitials/get-initials.module';
import { AlertOnTopModule } from '../alert-on-top/alert-on-top.module';
import { ManagerStateFacade } from '../../+state/manager-state/manager-state.facade';
import { managerSipInitialState } from '../../+state/manager-state/manager-state.state';
import { managerStateReducer } from '../../+state/manager-state/manager-state.reducers';
import { SharedUtilityModule } from '../../../../../../libs/shared/utility/src';
import { UICheckboxModule } from '../../ui-components/checkbox/checkbox.module';
import { PhoneInputWithCodeModule } from '../../ui-components/phone-input-with-code/phone-input-with-code.module';
import { FormErrorModule } from '../../ui-components/form-error/form-error.module';
import { TooltipModule } from '../../ui-components/tooltip/tooltip.module';
import { ConfirmDeletingPhoneModule } from '../../ui-components/confirm/confirm-deleting-phone.module';
import { NotesModule } from '../notes/notes.module';
import { DealsListFacade } from '../../+state/deals-list/deals-list.facade';
import { dealsListInitialState, dealsListReducer } from '../../+state/deals-list/deals-list.reducers';
import { PopupFacade } from '../../+state/popup/popup.facade';
import { popupInitialState } from '../../+state/popup/popup.state';
import { popupReducer } from '../../+state/popup/popup.reducers';
import {
  recommendationsInitialState,
  recommendationsReducer,
} from '../../+state/recommendations/recommendations.redusers';
import { RecommendationsEffects } from '../../+state/recommendations/recommendations.effects';
import { ChatTimelineModule } from '../chat-timeline/chat-timeline.module';
import { SlideToggleModule } from '../../ui-components/slide-toggle/slide-toggle.module';
import { ChatFooterModule } from '../whatsapp/modules/chat-footer/chat-footer.module';
import { DealsListModule } from './modules/deals-list/deals-list.module';
import { DealViewModule } from './modules/deal-view/deal-view.module';
import { UiDatetimepickerModule } from '../../ui-components/ui-datetimepicker/ui-datetimepicker.module';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    RouterModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormComponentsModule,
    AlertOnTopModule,
    GetInitialsModule,
    SharedUtilityModule,
    UICheckboxModule,
    PhoneInputWithCodeModule,
    FormErrorModule,
    StoreModule.forFeature('deals', dealsReducer, {
      initialState: dealsInitialState,
    }),

    StoreModule.forFeature('recommendations', recommendationsReducer, {
      initialState: recommendationsInitialState,
    }),
    StoreModule.forFeature('popup', popupReducer, {
      initialState: popupInitialState,
    }),
    StoreModule.forFeature('dealsList', dealsListReducer, {
      initialState: dealsListInitialState,
    }),
    StoreModule.forFeature('calls', callsReducer, {
      initialState: callInitialState,
    }),
    StoreModule.forFeature('managerSipState', managerStateReducer, {
      initialState: managerSipInitialState,
    }),
    EffectsModule.forFeature([DealsEffects]),
    EffectsModule.forFeature([RecommendationsEffects]),
    TooltipModule,
    UiDatetimepickerModule,
    ConfirmDeletingPhoneModule,
    NotesModule,
    ClipboardModule,
    ChatFooterModule,
    ChatTimelineModule,
    SlideToggleModule,
    BrowserAnimationsModule,
    DealsListModule,
    DealViewModule,
  ],
  providers: [
    DealsFacade,
    DealsListFacade,
    CallsFacade,
    ManagerStateFacade,
    PopupFacade,
    CrmCardUpdateService,
    DecimalPipe,
    TranslatePipe,
  ],
  exports: [],
})
export class DealsModule {}
