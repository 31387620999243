import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PopupService } from '../../../../../../../../../../shared/services/popup-service.service';
import { SlideToggleModule } from '../../../../../../../../../../ui-components/slide-toggle/slide-toggle.module';
import { InitSearchRequest } from '../../../../../../../../../search/services/search/websocket-tours.model';
import { PostFilterPrices } from '../../../../favorites-hotels.model';
import { SearchResultFiltersService } from '../../services/search-result-filters.service';
import { FavoritesHotelsMapFilterPricesComponent } from './filters/prices/favorite-hotels-map-filter-prices.component';
import { FavoritesHotelsMapFilterRatingComponent } from './filters/rating/favorite-hotels-map-filter-rating.component';
import { FavoritesHotelsMapFilterStarsComponent } from './filters/stars/favorite-hotels-map-filter-stars.component';
import { FavoriteHotelsMapFilterTagsComponent } from './filters/tags/favorite-hotels-map-filter-tags.component';

@Component({
  selector: 'app-search-result-post-filters',
  templateUrl: './search-result-post-filters.component.html',
  styleUrl: './search-result-post-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [PopupService],
  imports: [AsyncPipe, SlideToggleModule],
})
export class SearchResultPostFiltersComponent implements OnInit, OnDestroy {
  minTourPrice = input.required<number>();
  maxTourPrice = input.required<number>();
  starsList = input<string[]>();
  initSearchRequest = input<InitSearchRequest>();

  filterPrices = signal<PostFilterPrices>(undefined);
  filterStarsList = signal<string[]>([]);
  filterRatingList = signal<number[]>([]);
  filterTagIdsList = signal<number[]>([]);

  pricesRef = viewChild<ElementRef>('prices');
  starsListRef = viewChild<ElementRef>('starsList');
  ratingListRef = viewChild<ElementRef>('ratingList');
  tagsListRef = viewChild<ElementRef>('tagsList');

  private pricesComponentRef: ComponentRef<FavoritesHotelsMapFilterPricesComponent>;
  private starsListComponentRef: ComponentRef<FavoritesHotelsMapFilterStarsComponent>;
  private ratingListComponentRef: ComponentRef<FavoritesHotelsMapFilterRatingComponent>;
  private tagsListComponentRef: ComponentRef<FavoriteHotelsMapFilterTagsComponent>;
  private destroy = inject(DestroyRef);

  constructor(
    private readonly searchResultFiltersService: SearchResultFiltersService,
    private readonly popupService: PopupService,
  ) {}

  ngOnInit() {
    this.searchResultFiltersService.filterPrices$
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(filterPrices => {
        this.filterPrices.set(filterPrices);
      });
    this.searchResultFiltersService.filterHotelStarsList$
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(filterStarsList => {
        this.filterStarsList.set(filterStarsList);
      });
    this.searchResultFiltersService.filterHotelRatingList$
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(filterRatingList => {
        this.filterRatingList.set(filterRatingList);
      });
    this.searchResultFiltersService.filterTagIds$
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(filterTagIds => {
        this.filterTagIdsList.set(filterTagIds);
      });
  }

  ngOnDestroy() {
    this.pricesComponentRef?.destroy();
    this.starsListComponentRef?.destroy();
    this.ratingListComponentRef?.destroy();
    this.tagsListComponentRef?.destroy();
  }

  showPrices(): void {
    this.pricesComponentRef?.destroy();

    this.pricesComponentRef = this.popupService.showPopup(
      FavoritesHotelsMapFilterPricesComponent,
      {},
      { anchorElement: this.pricesRef() },
    );
    this.pricesComponentRef.setInput('prices', this.filterPrices());
    this.pricesComponentRef.setInput('minTourPrice', this.minTourPrice());
    this.pricesComponentRef.setInput('maxTourPrice', this.maxTourPrice());
    this.pricesComponentRef.instance.closed.subscribe(() => {
      this.pricesComponentRef?.destroy();
      this.popupService.closeAllModals();
    });
  }

  showStarsList(): void {
    this.starsListComponentRef?.destroy();

    this.starsListComponentRef = this.popupService.showPopup(
      FavoritesHotelsMapFilterStarsComponent,
      {},
      { anchorElement: this.starsListRef() },
    );
    this.starsListComponentRef.instance.starsList = this.starsList();
    this.starsListComponentRef.instance.selectedIds = this.filterStarsList();
  }

  showRatingList(): void {
    this.ratingListComponentRef?.destroy();

    this.ratingListComponentRef = this.popupService.showPopup(
      FavoritesHotelsMapFilterRatingComponent,
      {},
      { anchorElement: this.ratingListRef() },
    );
    this.ratingListComponentRef.instance.selectedIds = this.filterRatingList();
  }

  showTags(): void {
    this.tagsListComponentRef?.destroy();

    this.tagsListComponentRef = this.popupService.showPopup(
      FavoriteHotelsMapFilterTagsComponent,
      {},
      { anchorElement: this.tagsListRef() },
    );
    this.tagsListComponentRef.instance.initSearchRequest = this.initSearchRequest();
    this.tagsListComponentRef.instance.selectedIds = this.filterTagIdsList();
    this.tagsListComponentRef.instance.closed.subscribe(() => {
      this.popupService.closeAllModals();
    });
  }

  toggleOnlyWithPrice(value: boolean): void {
    this.searchResultFiltersService.applyOnlyWithPriceFilter(value);
  }
}
