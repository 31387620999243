import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Worker } from '../../../../models';
import { WorkersListService } from '../../../layout/components/workers-list/services/workers-list.service';
import { WorkerStateService } from '../../../../core/services';

const MANAGER_KEY = 'manager';

@Component({
  selector: 'app-complaint-assign',
  templateUrl: './complaint-assign.component.html',
  styleUrls: ['./complaint-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplaintAssignComponent implements OnInit {
  public managersList: Worker[];

  constructor(
    private workersListService: WorkersListService,
    private workerStateService: WorkerStateService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const currentWorker = this.workerStateService.currentWorkerValue;
    this.getManagersList(currentWorker.cityId).subscribe(workers => {
      this.managersList = workers;
      this.cdRef.detectChanges();
    });
  }

  getManagersList(cityId: number): Observable<Worker[]> {
    return this.workersListService.fetchWorkersList(cityId).pipe(
      map(values => {
        return values.filter(v => v.role === MANAGER_KEY);
      }),
    );
  }
}
