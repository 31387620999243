import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Office, PhoneItem, SmsTemplate } from '@api-clients/crm-api-client';
import { forkJoin } from 'rxjs';
import { SmsSendMessageRequest } from '@api-clients/api-client';
import { first } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigsService, WorkerStateService } from '../../../../core/services';
import { SmsService } from '../../services/sms.service';
import { OfficeService } from '../../services/office.service';
import { Worker } from '../../../../models';
import { SmsSendService } from './services/sms-send.service';
import { Notify, NotifyService, NotifyTypeEnum } from '../../../../shared/notify/services/notify.service';
import { WhatsappApiService } from '../../../whatsapp/services/whatsapp-api.service';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { SMS_MODAL_MESSAGE_SEND } from '../../../../core/services/amplitude/amplitudeEvents';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';

const CALL_CENTER = 'call_operator';
const SMS_ADDRESS = 'smsAddress';
const HOW_TO_GET = 'howToGet';
const HOW_TO_GET_KEY = '{{sms_info}}';
const DEFAULT_TYPE_FIELD = 'default';

export enum MessageType {
  sms = 'sms',
  whatsappMessage = 'whatsappMessage',
}
@UntilDestroy()
@Component({
  selector: 'app-sms-send',
  templateUrl: './sms-send.component.html',
  styleUrls: ['./sms-send.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsSendComponent implements OnInit {
  public placeHolderCharacter = '';
  public smsTemplatesPlaceholder = 'Выберите шаблон сообщения';
  public officesPlaceholder = 'Выберите офис';
  public messagePlaceholder = 'Введите текст';
  public smsTemplates: SmsTemplate[];
  public offices: Office[];
  public sendSmsForm: UntypedFormGroup;
  public smsTemplateText: string;
  public smsAddress: string;
  public smsType: string;
  public selectedOffice: Office;
  public defaultOffice: Office;
  public isCallOperator: boolean;
  public worker: Worker;
  public countryCode = '';
  public phoneValue = '';

  constructor(
    private fb: UntypedFormBuilder,
    private configsService: ConfigsService,
    public dialogRef: MatDialogRef<SmsSendComponent>,
    private smsService: SmsService,
    private officeService: OfficeService,
    private cdRef: ChangeDetectorRef,
    public workerStateService: WorkerStateService,
    public smsSendService: SmsSendService,
    private notifyService: NotifyService,
    private whatsappApiService: WhatsappApiService,
    private amplitudeTrackService: AmplitudeTrackService,
    @Inject(MAT_DIALOG_DATA)
    public data: { phone: PhoneItem | null; source?: string; screenType?: ScreenTypes },
  ) {}

  ngOnInit(): void {
    this.worker = this.workerStateService.currentWorkerValue;

    this.sendSmsForm = this.fb.group({
      mobilePhone: ['', Validators.required],
      text: ['', Validators.required],
      typeAlias: ['', Validators.required],
    });

    this.phoneValue = this.data?.phone?.phone || '';
    this.mobilePhoneControl.patchValue(this.phoneValue);
    if (this.worker.cityId) {
      this.loadDropdownData(this.worker.cityId);
    }
  }

  get mobilePhoneControl(): AbstractControl {
    return this.sendSmsForm.get('mobilePhone');
  }

  loadDropdownData(cityId: number) {
    return forkJoin([this.smsService.getSMSTemplates(cityId), this.officeService.getOfficesByCityId(cityId)])
      .pipe(untilDestroyed(this))
      .subscribe(([templates, offices]) => {
        this.smsTemplates = templates;

        if (offices) {
          this.offices = offices;
          const [firstOffice] = offices;
          this.defaultOffice = firstOffice;
        }
        this.cdRef.detectChanges();
      });
  }

  onSmsTypeChange(type: string): void {
    this.setSmsAddress();
    const smsTemplate = this.getTemplateByType(type);

    if (smsTemplate) {
      this.smsTemplateText = smsTemplate.text;

      if (smsTemplate.type === HOW_TO_GET && this.smsAddress) {
        this.smsTemplateText = smsTemplate.text.replace(HOW_TO_GET_KEY, this.smsAddress);
      }
      this.patchTextField(this.smsTemplateText);
    }
  }

  setSmsAddress(): void {
    this.isCallOperator = this.worker.role === CALL_CENTER;
    if (!this.isCallOperator) {
      this.offices.find(office => {
        if (office.id === this.worker.office.id) {
          this.smsAddress = office[SMS_ADDRESS];
        }
      });

      if (!this.smsAddress) {
        this.smsAddress = this.defaultOffice.smsAddress;
      }
    } else if (this.selectedOffice) {
      this.smsAddress = this.selectedOffice.smsAddress;
    } else {
      this.smsAddress = this.defaultOffice.smsAddress;
    }
  }

  getTemplateByType(type: string): SmsTemplate | null {
    return this.smsTemplates.find(value => {
      if (value.type === type) {
        return value;
      }
      return null;
    });
  }

  onCountryCodeChanged($event: string) {
    this.countryCode = $event;
    this.cdRef.markForCheck();
  }

  onPhoneValueChanged($event: string) {
    this.phoneValue = $event;
    this.cdRef.markForCheck();
  }

  patchTypeField(): void {
    this.sendSmsForm.get('typeAlias').patchValue(DEFAULT_TYPE_FIELD);
  }

  patchTextField(text: string): void {
    this.sendSmsForm.get('text').patchValue(text);
  }

  sendWhatsappMessage(): void {
    this.patchTypeField();
    if (this.sendSmsForm.invalid) {
      return;
    }
    const messageSendRequest = {
      ...this.sendSmsForm.value,
      fromPlace: this.data.screenType,
    };

    const formData = new FormData();
    formData.append('phone', `${this.countryCode}${this.phoneValue}`);
    formData.append('text', messageSendRequest.text);
    formData.append('file', null);
    formData.append('fromPlace', messageSendRequest.fromPlace);
    this.whatsappApiService
      .messageSend(formData)
      .pipe(first())
      .subscribe(
        data => {
          this.showNotify(!!data.id);
        },
        error => {
          // eslint-disable-next-line no-console
          console.error('error: ', error);
        },
      );
  }

  showNotify(isSuccess: boolean) {
    let notify: Notify = {
      title: 'Отправка сообщения',
      text: 'Сообщение отправлено успешно!',
      type: NotifyTypeEnum.success,
    };

    if (isSuccess) {
      this.closePopup();
    } else {
      notify = {
        ...notify,
        text: 'Ошибка при отправке сообщения!',
        type: NotifyTypeEnum.error,
      };
    }
    this.notifyService.create(notify);
  }

  onSubmit(): void {
    this.patchTypeField();
    if (this.sendSmsForm.invalid) {
      return;
    }

    const smsSendRequest: SmsSendMessageRequest = {
      ...this.sendSmsForm.value,
      mobilePhone: `${this.countryCode}${this.phoneValue}`,
    };

    this.smsSendService
      .smsSend(smsSendRequest)
      .pipe(first())
      .subscribe(
        data => {
          this.amplitudeTrackService.trackEvent(
            SMS_MODAL_MESSAGE_SEND,

            {
              templateType: this.smsType,
              messageType: MessageType.sms,
              screenType: this.data.screenType,
            },
          );
          this.showNotify(data.success);
        },
        error => {
          // eslint-disable-next-line no-console
          console.error('error: ', error);
        },
      );
  }

  get textFieldValue(): string {
    return this.sendSmsForm.get('text').value;
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
