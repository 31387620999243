import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeDurationFormatPipe } from './pipes/time-duration-format.pipe';
import { AudioComponent } from './audio/audio.component';
import { AudioService } from './services/audio.service';
import { SharedModule } from '../../../../../../../../shared/shared.module';
import { SharedUtilityModule } from '../../../../../../../../../../../../libs/shared/utility/src';
import { ConfigsService } from '../../../../../../../../core/services';
import { AmplitudeTrackService } from '../../../../../../../../core/services/amplitude/amplitude-track.service';

@NgModule({
  declarations: [TimeDurationFormatPipe, AudioComponent],
  imports: [CommonModule, SharedModule, SharedUtilityModule],
  providers: [AudioService, ConfigsService, AmplitudeTrackService],
  exports: [AudioComponent],
})
export class AudioModule {}
