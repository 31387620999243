@if (isLoading$ | async) {
  <app-preloader />
}
<div class="header">{{ header }}</div>
<button (click)="closeModal()" class="close-button" type="button">
  <img alt="" src="/assets/icons/close-icon.svg" />
</button>
<div
  class="content-creator-wrapper"
  [ngStyle]="{ '--single-message-height': singleMessageHeight() + 'px' }"
  [class.visible]="isContentCreatorWrapperVisible$ | async"
  [class.single-message-input-visible]="isSingleMessageInputVisible"
>
  @if (hotelName$ | async; as hotelName) {
    <app-alert-label [type]="AlertLabelType.warning">
      {{ hotelName }}
    </app-alert-label>
  }
  @if (isSingleContent()) {
    <div #itemWrapperComponentsPlace class="item-wrapper-components-place"></div>
  } @else {
    <div
      #scrollableWrapper
      class="scrollable-wrapper"
      appResizeObserver
      (heightChange)="checkScrollableWrapperVerticalScroll()"
      [ngStyle]="{
        '--content-creator-reserved-height': contentCreatorReservedHeight() + 'px',
      }"
      [class.has-vertical-scroll]="isScrollableWrapperHasVerticalScroll()"
    >
      <div #itemWrapperComponentsPlace class="item-wrapper-components-place"></div>
    </div>
  }
  @if (isSingleMessageInputVisible) {
    <div class="single-message" appResizeObserver (heightChange)="setMessageSendControlsHeight($event)">
      <textarea
        class="single-message-textarea"
        [(ngModel)]="singleMessageText"
        rows="1"
        spellcheck="true"
        appResizableTextarea
        [maxHeightInPx]="250"
        appTextInputControl
        #singleMessageTextareaControl="appTextInputControl"
        matInput
        placeholder="{{ 'PAGES.CONTENT_CREATOR.MESSAGE_PLACEHOLDER' | translate }}"
      ></textarea>
      <button class="btn-send-content" (click)="sendContent()">
        @if (isOpenedFromPlaceholder) {
          Добавить
        } @else {
          <img class="icon-send" src="/assets/icons/content-creator/send-message.svg" />
        }
      </button>
    </div>
  }
  @if (isPlainSendButtonVisible) {
    <div
      class="message-send-controls"
      appResizeObserver
      (heightChange)="setMessageSendControlsHeight($event)"
    >
      <button class="btn-send-content-big" (click)="sendContent()">
        <img src="/assets/icons/content-creator/send-message.svg" class="icon" /> Отправить
      </button>
    </div>
  }
</div>
@if (errorMessage$ | async; as errorMessage) {
  <app-alert-label [type]="AlertLabelType.danger">
    {{ errorMessage }}
  </app-alert-label>
}
