import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfirmTourPackageRequest } from '@api-clients/tour-packages-api-client/dist/models';
import { apiResponsePipe } from '../../../../../../../../../../../api-response.pipe';
import { ApiResponse } from '../../../../../../../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TourPackagesApiService {
  constructor(private http: HttpClient) {}

  confirm(body: ConfirmTourPackageRequest) {
    return this.http
      .post<ApiResponse<any>>(`${AppConfig.apiUrl}/tour-packages/confirm`, body)
      .pipe(apiResponsePipe);
  }

  markClientDocumentsAsReady(tourPackageId: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tourPackageId', `${tourPackageId}`);
    return this.http
      .post<ApiResponse<any>>(
        `${AppConfig.apiUrl}/tour-packages/mark-client-documents-as-ready`,
        {},
        { params: queryParams },
      )
      .pipe(apiResponsePipe);
  }

  markClientDocumentsAsReceived(tourPackageId: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tourPackageId', `${tourPackageId}`);
    return this.http
      .post<ApiResponse<any>>(
        `${AppConfig.apiUrl}/tour-packages/mark-client-documents-as-received`,
        {},
        { params: queryParams },
      )
      .pipe(apiResponsePipe);
  }
}
