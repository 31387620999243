import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';

export const POST_SALE_STATUS = 2;
export interface OnEndConversationData {
  sip: number;
  dealView: CrmCardViewItem;
}
export interface OnEndCallData {
  from: string;
  dealView: CrmCardViewItem;
}
