import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TourPackage, TourPackageReviewItem } from '@api-clients/crm-api-client';
import { AmplitudeTrackService } from 'app/core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from 'app/core/services/amplitude/amplitudeEventData';
import { TOUR_PACKAGE_REVIEW_OPEN } from 'app/core/services/amplitude/amplitudeEvents';
import { LastReviewModalService } from 'app/modules/deals/modules/client-reservations/services/last-review-modal.service';

@Component({
  selector: 'app-last-review-button',
  templateUrl: './last-review-button.component.html',
  styleUrls: ['./last-review-button.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LastReviewButtonComponent {
  @Input() reservation: TourPackage;
  @Input() screenType: ScreenTypes;

  constructor(
    private lastReviewModalService: LastReviewModalService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  get reservationLastReview(): TourPackageReviewItem {
    return this.reservation?.reviewsData?.items[0];
  }

  public onClick(review: TourPackageReviewItem): void {
    this.lastReviewModalService.showModal(review);
    this.amplitudeTrackService.trackEvent(TOUR_PACKAGE_REVIEW_OPEN, { 'Screen type': this.screenType });
  }
}
