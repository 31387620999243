import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY } from './wokrer-config.reducers';
import { WorkerConfigState } from './wokrer-config.state';

export const getWorkerConfigFeature = createFeatureSelector<WorkerConfigState>(USER_FEATURE_KEY);

export const getWhatsappNotificationsEnabled = createSelector(
  getWorkerConfigFeature,
  (payload: WorkerConfigState) => payload.isWhatsappNotificationsEnabled,
);

export const getAsideChatsScrollBarEnabled = createSelector(
  getWorkerConfigFeature,
  (payload: WorkerConfigState) => payload.isAsideChatsScrollBarEnabled,
);
