import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-frontend-basic-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './basic-loader.component.html',
  styleUrls: ['./basic-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicLoaderComponent {
  @Input() width = 16;
  @Input() height = 16;
  @Input() stretch = false;

  @HostBinding('class.stretch') get isStretched() {
    return this.stretch;
  }
}
