<div class="audio-player-container">
  <div
    *ngIf="isRecordedAudio || this.mediaSource; else noAudio"
    class="audio-player"
    [ngClass]="{
      whatsapp: isWhatsappAudio,
      'recorded-audio': isRecordedAudio
    }"
  >
    <button (click)="play()" *ngIf="isPaused" class="audio-btn play">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.5 0C14.771 0 19 4.22903 19 9.5C19 14.771 14.771 19 9.5 19C4.22903 19 0 14.771 0 9.5C0 4.22903 4.22903 0 9.5 0ZM9.5 1.22581C14.0968 1.22581 17.7742 4.90323 17.7742 9.5C17.7742 14.0968 14.0968 17.7742 9.5 17.7742C4.90323 17.7742 1.22581 14.0968 1.22581 9.5C1.22581 4.90323 4.90323 1.22581 9.5 1.22581ZM9.25484 6.19032C8.82581 5.94516 8.27419 5.94516 7.78387 6.19032C7.35484 6.43548 7.04839 6.92581 7.04839 7.41613V11.5839C7.04839 12.0742 7.29355 12.5645 7.78387 12.8097C8.2129 13.0548 8.76452 13.0548 9.25484 12.8097L12.871 10.7258C13.5452 10.3581 13.7903 9.43871 13.4226 8.76452C13.3 8.51935 13.1161 8.33548 12.871 8.2129L9.25484 6.19032Z"
          fill="#007CF0"
        />
      </svg>
    </button>
    <button (click)="pause()" *ngIf="!isPaused" class="audio-btn pause">
      <svg width="19" height="19" viewBox="0 0 23 22" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.864746 10.8165C0.864746 4.84218 5.70648 0 11.6802 0C17.654 0 22.4957 4.84218 22.4957 10.8165C22.4957 16.7908 17.654 21.633 11.6802 21.633C5.70648 21.633 0.864746 16.7908 0.864746 10.8165ZM15.2971 19.3814C16.4039 18.9144 17.3989 18.242 18.2516 17.3884C19.1078 16.5357 19.7775 15.5406 20.2461 14.4337C20.7301 13.2881 20.9744 12.0712 20.9744 10.8165C20.9744 9.56178 20.7301 8.34493 20.2443 7.19748C19.7774 6.09059 19.1051 5.09546 18.2516 4.24186C17.3962 3.38826 16.4039 2.71855 15.2971 2.24983C14.1516 1.76579 12.9348 1.52152 11.6802 1.52152C10.4256 1.52152 9.20888 1.76579 8.06154 2.25163C6.95475 2.71855 5.95974 3.39097 5.10621 4.24457C4.25269 5.09998 3.58301 6.0924 3.11434 7.20019C2.63035 8.34493 2.38611 9.56178 2.38611 10.8165C2.38611 12.0712 2.63036 13.2881 3.11616 14.4355C3.58303 15.5424 4.25539 16.5375 5.10891 17.3911C5.96423 18.2447 6.95655 18.9144 8.06423 19.3832C9.20887 19.8672 10.4256 20.1115 11.6802 20.1115C12.9348 20.1115 14.1516 19.8672 15.2971 19.3814ZM8.40585 6.76031C7.98585 6.76031 7.64516 7.10012 7.64516 7.52106V14.1119C7.64516 14.5329 7.98585 14.8727 8.40585 14.8727C8.82675 14.8727 9.16653 14.5329 9.16653 14.1119V7.52106C9.16653 7.10012 8.82675 6.76031 8.40585 6.76031ZM14.1939 7.52106C14.1939 7.10012 14.5337 6.76031 14.9546 6.76031C15.3746 6.76031 15.7153 7.10012 15.7153 7.52106V14.1119C15.7153 14.5329 15.3746 14.8727 14.9546 14.8727C14.5337 14.8727 14.1939 14.5329 14.1939 14.1119V7.52106Z"
          fill="black"
        />
      </svg>
    </button>
    <div (click)="seek($event)" class="time-line-wrap">
      <div class="duration-wrap">
        <span class="numbers current-time">{{ currentTime | timeDurationFormat }}</span>
        <span class="numbers time-left">{{ duration | timeDurationFormat }}</span>
        <span hidden class="numbers volume-in-percents">{{ volumeInPercents }} %</span>
      </div>
      <div #progressLine class="time-line line">
        <div [ngStyle]="{ width: progress }" class="time-progress progress-line"></div>
        <button #handleTime [ngStyle]="{ left: progress }" class="handle handle-time"></button>
      </div>
    </div>
    <div class="controls">
      <div (click)="changePlaybackRate()" class="playback-speed-control">
        <span *ngIf="playbackRateValue">x{{ playbackRateValue }}</span>
      </div>
      <ng-container *ngIf="!isTranscribeHidden">
        <ng-container *ngIf="audioTranscript || audioTranscriptErrorText; else transcriptionButton">
          <div (click)="toggleResult()" class="transcript-control has-result">
            <ng-container *ngIf="audioTranscriptOpened; else audioTranscriptClosed">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 8L6.70711 4.70711C6.31658 4.31658 5.68342 4.31658 5.29289 4.70711L2 8"
                  stroke="#F4F8FE"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </ng-container>
            <ng-template #audioTranscriptClosed>
              <svg
                [class.rotated]="!audioTranscriptOpened"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 8L6.70711 4.70711C6.31658 4.31658 5.68342 4.31658 5.29289 4.70711L2 8"
                  stroke="#F4F8FE"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </ng-template>
          </div>
        </ng-container>
        <ng-template #transcriptionButton>
          <div (click)="transcribe()" class="transcript-control" [class.loading]="isAudioTranscriptLoading">
            <ng-container *ngIf="!isAudioTranscriptLoading">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
                <path
                  d="M1 5L9 5M9 5L5.88889 1.5M9 5L5.88889 8.5"
                  stroke="#007CF0"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.5693 1.34474C15.5183 1.24166 15.4374 1.15451 15.3361 1.09346C15.2349 1.03242 15.1174 1 14.9974 1C14.8774 1 14.7599 1.03242 14.6587 1.09346C14.5574 1.15451 14.4765 1.24166 14.4255 1.34474L11.055 8.14443C10.9875 8.28783 10.9819 8.45063 11.0395 8.59788C11.097 8.74512 11.2131 8.86507 11.3628 8.93196C11.5125 8.99886 11.6838 9.00736 11.84 8.95566C11.9962 8.90395 12.1249 8.79615 12.1983 8.65541L13.0193 6.99998H16.9755L17.796 8.65541C17.8674 8.79941 17.9961 8.9106 18.1538 8.96452C18.3115 9.01843 18.4853 9.01066 18.637 8.9429C18.7887 8.87514 18.9058 8.75294 18.9626 8.6032C19.0194 8.45346 19.0112 8.28844 18.9398 8.14443L15.5693 1.34474ZM13.6144 5.80004L14.9974 3.00916L16.3809 5.80004H13.6144Z"
                  fill="#007CF0"
                />
              </svg>
            </ng-container>
            <ng-container *ngIf="isAudioTranscriptLoading">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="spinner">
                  <path
                    id="Ellipse 1993"
                    d="M3.11434 7.26005C2.53627 7.1725 2.1298 6.62793 2.31746 6.0742C2.47434 5.61127 2.68764 5.16797 2.95346 4.75463C3.4108 4.04348 4.01327 3.43701 4.72138 2.975C5.4295 2.51298 6.22727 2.20584 7.06239 2.07371C7.89751 1.94159 8.75113 1.98746 9.56729 2.20831C10.3834 2.42917 11.1437 2.82004 11.7982 3.35528C12.4528 3.89052 12.9868 4.55805 13.3653 5.31411C13.7438 6.07018 13.9582 6.8977 13.9945 7.74243C14.0156 8.23342 13.9762 8.72378 13.8782 9.20266C13.761 9.77546 13.1354 10.0407 12.5921 9.82482C12.0487 9.60891 11.7995 8.99066 11.8611 8.40925C11.8813 8.21847 11.8874 8.02597 11.8792 7.83332C11.8557 7.28667 11.7169 6.75116 11.472 6.2619C11.2271 5.77263 10.8815 5.34065 10.4579 4.99428C10.0344 4.64792 9.54238 4.39498 9.01423 4.25206C8.48608 4.10913 7.93368 4.07945 7.39325 4.16495C6.85282 4.25046 6.33657 4.44921 5.87833 4.7482C5.42009 5.04718 5.03021 5.43964 4.73426 5.89984C4.62996 6.06202 4.53816 6.23133 4.45941 6.40628C4.21943 6.93942 3.69241 7.3476 3.11434 7.26005Z"
                    fill="#EAF5FF"
                  />
                  <path
                    id="Ellipse 1992"
                    d="M12.9365 7.7808C13.5206 7.75487 14.0247 8.21053 13.9478 8.7901C13.8323 9.65937 13.527 10.4972 13.0485 11.2423C12.3783 12.2859 11.4011 13.0962 10.2514 13.5616C9.10174 14.027 7.83607 14.1247 6.62859 13.8412C5.42111 13.5577 4.33116 12.9069 3.50873 11.9785C2.6863 11.0501 2.1718 9.88958 2.03605 8.65672C1.90029 7.42385 2.14995 6.17922 2.75066 5.09408C3.35137 4.00894 4.27361 3.13663 5.39047 2.59719C6.18781 2.21208 7.05635 2.01014 7.9332 2.00037C8.51783 1.99385 8.90934 2.5493 8.81313 3.12599C8.71692 3.70268 8.1653 4.07696 7.58401 4.1396C7.14461 4.18695 6.71393 4.30925 6.31131 4.50371C5.58856 4.85279 4.99175 5.41728 4.60302 6.11951C4.21429 6.82173 4.05273 7.62716 4.14058 8.42498C4.22843 9.22279 4.56137 9.97378 5.09359 10.5746C5.6258 11.1754 6.33114 11.5965 7.11253 11.78C7.89392 11.9634 8.71296 11.9002 9.45695 11.599C10.2009 11.2979 10.8333 10.7735 11.267 10.0981C11.5086 9.72193 11.682 9.30915 11.782 8.87867C11.9143 8.30918 12.3524 7.80674 12.9365 7.7808Z"
                    fill="#97C9F9"
                  />
                </g>
              </svg>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>

      <a
        *ngIf="recordingFileLink"
        libExternalLink
        [externalURL]="recordingFileLink"
        class="web-listening"
      >
        <img alt="" src="../../../../../../../../../../assets/icons/audio-player/web.svg"
      /></a>
    </div>
    <audio #audio *ngIf="mediaSource" (canplaythrough)="setActualDuration($event)" class="cdr-player">
      <source [src]="sanitize(mediaSource)" type="audio/webm" />
      Браузер не поддерживает тег audio.
    </audio>
  </div>
  <ng-container *ngIf="audioTranscriptOpened">
    <div class="audio-transcript" [class.as-tooltip]="isTranscriptResultAsTooltip">
      <ng-container *ngIf="audioTranscript; else audioTranscriptError">
        <span>{{ audioTranscript }}</span>
      </ng-container>
      <ng-template #audioTranscriptError>
        <ng-container *ngIf="audioTranscriptErrorText">
          <span>{{ audioTranscriptErrorText }}</span>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #noAudio> Нет аудио данных. </ng-template>
</div>
