import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CrmCardItem, DealItem } from '@api-clients/crm-api-client';
import { CrmCardViewItem, CountryPhoneFormat } from '@api-clients/crm-api-client/dist/models';
import { dateDiff } from '../../../../../../helpers/dateDiff';
import { ReservationsModalService } from '../../../client-reservations/services/reservations-modal.service';
import { ClientActionsModalService } from '../../../client-actions-on-site/services/client-actions-modal.service';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { BOOKING_HISTORY_OPEN } from '../../../../../../core/services/amplitude/amplitudeEvents';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-client-card-info',
  templateUrl: './client-card-info.component.html',
  styleUrls: ['./client-card-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientCardInfoComponent implements OnInit {
  public inWorkSince: string;
  public countryPhoneFormats: CountryPhoneFormat[];
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_LISTING;
  @Input() card: CrmCardItem;
  @Input() crmId: number;
  @Input() isWhatsappClientCard: boolean;
  @Input() isModal = false;
  @Input() dealInfo: DealItem;
  @Input() crmCardViewItem: CrmCardViewItem = null;
  @Output() fadeOutAnimation = new EventEmitter();
  public OnlineClientSourceIdList = [117];
  public isOnlineClient = false;
  constructor(
    private reservationsModalService: ReservationsModalService,
    private clientActionsModalService: ClientActionsModalService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  ngOnInit(): void {
    if (this.dealInfo) {
      const startWorkDate = this.dealInfo.startWorkDate || this.dealInfo.createDate;
      if (startWorkDate) {
        this.inWorkSince = dateDiff(new Date(startWorkDate));
      }
    }

    if (this.crmCardViewItem) {
      this.isOnlineClient = this.OnlineClientSourceIdList.includes(this.crmCardViewItem.deal.clientSourceId);
    }
  }

  showReservationsList(crmCardId: number) {
    this.amplitudeTrackService.trackEvent(BOOKING_HISTORY_OPEN, {
      screenType: this.screenType,
    });
    this.reservationsModalService.showReservationsModal(crmCardId);
  }

  showActionsModal(actionsCount: number, crmCardId: number, name: string) {
    if (actionsCount > 0) {
      this.clientActionsModalService.showModal(crmCardId, name);
    }
  }

  fadeOut(): void {
    this.fadeOutAnimation.emit();
  }

  get isReturnTourist() {
    if (this.dealInfo) {
      return this.dealInfo.isReturnTourist;
    }
    return this.card.isReturnTourist;
  }
}
