<div>
  <form [formGroup]="form">
    <div class="form-group">
      <div class="form-relative">
        <span class="prefix">/</span>
        <input class="form-control with-prefix"
               [placeholder]="'приветсвие'" formControlName="key" id="key" type="text" />
        <app-form-error [parentForm]="form" [assignedFormControl]="form.get('key')"></app-form-error>
      </div>
      <label class="form-label" for="key">{{'PAGES.SETTINGS.WHATSAPP.TEMPLATE_KEY_LABEL' | translate}}</label>

    </div>

    <div class="form-group">
      <div>
        <textarea
          class="form-control --textarea"
          [placeholder]="'PAGES.SETTINGS.WHATSAPP.RESPONSE_MESSAGE_PLACEHOLDER' | translate"
          formControlName="value"
          id="value" type="text">
        </textarea>
        <app-form-error [parentForm]="form" [assignedFormControl]="form.get('value')"></app-form-error>
      </div>
      <label class="form-label" for="value">{{'PAGES.SETTINGS.WHATSAPP.ENTER_TEXT' | translate}}</label>

    </div>

    <div class="buttons">
      <button
        [disabled]="form.invalid"
        (click)="onSubmit()"
        class="btn --active"
      >
        {{'PAGES.SETTINGS.WHATSAPP.SAVE' | translate}}
      </button>

      <button
        *ngIf="id.value"
        [disabled]="form.invalid"
        (click)="resetForm()"
        class="btn --red --active"
      >
        {{'PAGES.SETTINGS.WHATSAPP.CANCEL' | translate}}
      </button>
    </div>


  </form>
</div>
