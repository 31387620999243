import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import {
  Notify,
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../../../../shared/notify/services/notify.service';

@Component({
  selector: 'app-message-phone',
  templateUrl: './message-phone.component.html',
  styleUrls: ['./message-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePhoneComponent implements OnInit {
  @Input() phone: string;
  @Input() phoneCode: string;

  public formattedPhoneCode: string;
  public formattedPhone: string;

  constructor(private notifyService: NotifyService, private clipboardService: ClipboardService) {}

  ngOnInit(): void {
    this.formatPhone();
    this.formatPhoneCode();
  }

  private formatPhone() {
    const lastFourDigits = this.phone.slice(-4);
    this.formattedPhone = `${lastFourDigits.slice(0, 2)}-${lastFourDigits.slice(2)}`;
  }

  private formatPhoneCode() {
    this.formattedPhoneCode = `${this.phoneCode}...`;
  }

  copyPhoneWithCode(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.clipboardService.copy(`${this.phoneCode}${this.phone}`);
    const notify: Notify = {
      title: 'Номер скопирован',
      text: 'Номер успешно скопирован в буфер обмена!',
      type: NotifyTypeEnum.success,
    };
    this.notifyService.create(notify);
  }
}
