import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../backend/src/models/models';

export const apiResponsePipe = pipe(
  map((data: ApiResponse<any>) => {
    switch (data.statusCode) {
      case 200:
        return data.data;
      case 404:
        throw data.data;
      default:
        return data;
    }
  }),
);
