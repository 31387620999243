import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { DefaultResponse, WorkerConfigureRequest } from '@api-clients/api-client';
import { Router } from '@angular/router';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { webFrame } from 'electron';
import { ElectronService, WorkerStateService } from '../../../../core/services';
import { Worker } from '../../../../models';
import { WorkerConfigFacade } from '../../../../+state/worker-config/worker-config.facade';
import { SettingsService } from './services/settings.service';
import { WebsocketHelperService } from '../../../../core/services/websocket/helper/websocket-helper.service';
import { CallNotification, CurrentNotification } from '../../../../models/ipcEvent';
import { ZoiperCallService } from './services/zoiper-call.service';
import { SettingsHelperService } from './services/settings-helper.service';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { ASIDE_CHATS_SCROLL_ENABLED_SET } from '../../../../core/services/amplitude/amplitudeEvents';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';

enum SettingsTabs {
  general = 'general',
  whatsappTemplates = 'whatsappTemplates',
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
  public worker: Worker;
  public permissions: string[];
  public isWhatsappNotificationsEnabled: boolean;

  public activeTab: SettingsTabs = SettingsTabs.general;
  public settingsTabs = SettingsTabs;
  public isShowDevToolsColumn = false;
  public isZoiperCall$ = this.zoiperCallService.isZoiperCall$;
  public isAsideChatsScrollEnabled$ = this.workerConfigFacade.asideChatsScrollBarEnabled$;
  public isZenModeOn$ = this.dealsFacade.zenmodeOn$;
  public labels = {
    WHATSAPP_NOTIFICATIONS_ENABLED: '',
    WHATSAPP_NOTIFICATIONS_DISABLED: '',
    ZOIPER_CALL_ENABLED: '',
    ZOIPER_CALL_DISABLED: '',
    ASIDE_CHATS_SCROLL_ENABLED: '',
    ASIDE_CHATS_SCROLL_DISABLED: '',
    SALES_NOTIFY_ENABLED: '',
    SALES_NOTIFY_DISABLED: '',
  };
  public zoomLevel = 0;
  public salesNotifyDisabled = false;
  constructor(
    private electronService: ElectronService,
    private workerStateService: WorkerStateService,
    private permissionsService: NgxPermissionsService,
    private workerConfigFacade: WorkerConfigFacade,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private websocketHelperService: WebsocketHelperService,
    private zoiperCallService: ZoiperCallService,
    private settingsHelperService: SettingsHelperService,
    private dealsFacade: DealsFacade,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  public changeTab(tab: SettingsTabs) {
    this.activeTab = tab;
  }

  ngOnInit(): void {
    this.initializeZoomLevel();
    this.initializeSalesNotifyDisabled();
    this.electronService.ipcRenderer.on('notifications-window-info', (event, info) => {
      console.log('notifications-window-info', event, info);
    });

    this.worker = this.workerStateService.currentWorkerValue;
    this.permissionsService.permissions$.subscribe(x => {
      const permissions: string[] = [];
      for (const key in x) {
        if (x.hasOwnProperty(key)) {
          permissions.push(key);
        }
      }
      this.permissions = permissions;
    });
    this.workerConfigFacade.whatsappNotificationsEnabled$.subscribe(value => {
      this.isWhatsappNotificationsEnabled = value;
      this.cdRef.detectChanges();
    });

    const languageKeys = {
      'PAGES.SETTINGS.WHATSAPP.WHATSAPP_NOTIFICATIONS_ENABLED': 'WHATSAPP_NOTIFICATIONS_ENABLED',
      'PAGES.SETTINGS.WHATSAPP.WHATSAPP_NOTIFICATIONS_DISABLED': 'WHATSAPP_NOTIFICATIONS_DISABLED',
      'PAGES.SETTINGS.ZOIPER_CALL.ZOIPER_CALL_ENABLED': 'ZOIPER_CALL_ENABLED',
      'PAGES.SETTINGS.ZOIPER_CALL.ZOIPER_CALL_DISABLED': 'ZOIPER_CALL_DISABLED',
      'PAGES.SETTINGS.ASIDE_CHATS_SCROLL.ASIDE_CHATS_SCROLL_ENABLED': 'ASIDE_CHATS_SCROLL_ENABLED',
      'PAGES.SETTINGS.ASIDE_CHATS_SCROLL.ASIDE_CHATS_SCROLL_DISABLED': 'ASIDE_CHATS_SCROLL_DISABLED',
      'PAGES.SETTINGS.SALES_NOTIFY.SALES_NOTIFY_ENABLED': 'SALES_NOTIFY_ENABLED',
      'PAGES.SETTINGS.SALES_NOTIFY.SALES_NOTIFY_DISABLED': 'SALES_NOTIFY_DISABLED',
    };

    for (const langKey in languageKeys) {
      if (languageKeys.hasOwnProperty(langKey)) {
        const label = languageKeys[langKey];
        this.translateService.get(langKey).subscribe(value => {
          this.labels[label] = value;
        });
      }
    }
  }

  initializeZoomLevel() {
    this.zoomLevel = parseFloat(localStorage.getItem('zoomLevel')) || 0;
  }

  initializeSalesNotifyDisabled() {
    const storedValue = localStorage.getItem('salesNotifyDisabled');
    this.salesNotifyDisabled = storedValue === 'true';
  }

  zoomIn() {
    if (this.zoomLevel < 3) {
      this.zoomLevel += 0.5;
      webFrame.setZoomLevel(this.zoomLevel);
      this.saveZoomLevel();
    }
  }

  zoomOut() {
    if (this.zoomLevel > -3) {
      this.zoomLevel -= 0.5;
      webFrame.setZoomLevel(this.zoomLevel);
      this.saveZoomLevel();
    }
  }

  saveZoomLevel() {
    localStorage.setItem('zoomLevel', this.zoomLevel.toString());
  }

  openDevTools() {
    this.electronService.ipcRenderer.send('openMainDevTools');
  }

  openNotificationsDevTools() {
    this.electronService.ipcRenderer.send('openNotificationDevTools');
  }

  openUpdateScreen() {
    this.router.navigate(['update']).then();
  }

  onWhatsappNotificationsSettingChange($event: boolean) {
    const body: WorkerConfigureRequest = {
      isWhatsappNotificationsEnabled: $event,
    };
    this.settingsService.workerConfigure(body).subscribe((data: DefaultResponse) => {
      if (data.success) {
        this.workerConfigFacade.loadConfig();
      }
    });
  }

  sendFakeNotifyAboutTourPackageDocs() {
    const fakeNotify: CurrentNotification =
      this.settingsHelperService.getFakeTourPackageDocsReadyNotification();

    this.websocketHelperService.showFakeNotify(fakeNotify);
  }

  sendFakeNotifyAboutCall() {
    const fakeNotify: CallNotification = this.settingsHelperService.getFakeCallNotification(true, false);

    this.websocketHelperService.showNewInboxCallNotify(fakeNotify);
  }
  sendFakeNotifyAboutCallFromApp() {
    const fakeNotify: CallNotification = this.settingsHelperService.getFakeCallNotification(true, true);

    this.websocketHelperService.showNewInboxCallNotify(fakeNotify);
  }

  sendFakeNotifyAboutCallWithoutDealView() {
    const fakeNotify: CallNotification = this.settingsHelperService.getFakeCallNotification(false);

    this.websocketHelperService.showNewInboxCallNotify(fakeNotify);
  }

  sendFakeOnStartConversation() {
    const fakeCrmCardViewItem: CrmCardViewItem = this.settingsHelperService.getFakeCrmCardViewItem();
    this.websocketHelperService.onStartConversation(fakeCrmCardViewItem);
    setTimeout(() => {
      this.websocketHelperService.onEndCall({
        from: 'test',
        dealView: fakeCrmCardViewItem,
      });
    }, 5000);
  }

  showNotificationsWindow() {
    this.electronService.ipcRenderer.send('showNotificationsWindow');
  }

  closeNotificationsWindow() {
    this.electronService.ipcRenderer.send('closeNotificationsWindow');
  }

  showInConsoleNotificationsWindowInfo() {
    this.electronService.ipcRenderer.send('show-in-console-notifications-window-info');
  }

  changeIsZoiperCall(event: boolean) {
    this.zoiperCallService.changeIsZoiperCall(event);
  }

  changeIsAsideChatScrollEnabled($event: boolean) {
    this.workerConfigFacade.setAsideChatsScrollBar($event);
    localStorage.setItem('asideChatsEnabled', $event.toString());
    this.amplitudeTrackService.trackEvent(ASIDE_CHATS_SCROLL_ENABLED_SET, { value: $event.toString() });
  }

  changeIsSalesNotifyDisabled($event: boolean) {
    localStorage.setItem('salesNotifyDisabled', $event.toString());
  }

  openZenmodeList() {
    this.router.navigate(['zenmode-list/']).then();
  }

  openTestUrl() {
    this.settingsHelperService.openUrlWithDefaultBrowser('https://www.google.com');
  }

  openTestUrlElectron() {
    this.electronService.shell.openExternal('https://www.google.com').then();
  }

  changeToDevVersion() {
    (window as any).location = 'https://stage-gera.ht.kz';
  }
}
