<ng-container  *ngIf="currentCall$ | async as currentOutcomeCall">
  <div class="current-call" *ngIf="currentOutcomeCall.clientPhone">
    <div class="client-name">{{currentOutcomeCall.clientName}}</div>
    <div class="client-phone">{{currentOutcomeCall.clientPhone}}</div>
    <div class="call-state">
      Соединение ...
    </div>
    <div class="call-state">
      {{currentOutcomeCall.message}}
    </div>
  </div>
</ng-container>

