import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatSendMessageRequest } from '@api-clients/crm-api-client';
import { Observable } from 'rxjs';
import { ContentCreatorComponent } from '../content-creator.component';
import { ChatTimeLineItem } from '../../chat-timeline/interfaces/chat-timeline.interface';
import { ContentCreatorContentType } from '../interfaces/content-creator.interface';
import { ContentCreatorSizesService } from './content-creator-sizes.service';

@Injectable({
  providedIn: 'root',
})
export class ContentCreatorModalService {
  constructor(
    public matDialog: MatDialog,
    private contentCreatorSizesService: ContentCreatorSizesService,
  ) {}

  showModal(
    chatTimeLineItem: ChatTimeLineItem,
    initialContentTypes: ContentCreatorContentType[] | null = null,
    initialContentPlaceholderTypes: ContentCreatorContentType[] | null = null,
    isOpenedFromPlaceholder = false,
  ): MatDialogRef<ContentCreatorComponent, Observable<ChatSendMessageRequest[]>> {
    return this.matDialog.open(ContentCreatorComponent, {
      id: 'content-creator-modal' + (isOpenedFromPlaceholder ? '-from-placeholder' : ''),
      // Будем обрабатывать закрытие сами, чтобы правильно отрабатывал escape в галерее изображений
      disableClose: true,
      panelClass: 'modal-panel-rounded',
      width: `${this.contentCreatorSizesService.modalWidth}px`,
      minHeight: `${this.contentCreatorSizesService.modalMinHeight}px`,
      maxHeight: `calc(100vh - ${this.contentCreatorSizesService.modalVerticalMargin * 2}px)`,
      hasBackdrop: true,
      data: {
        chatTimeLineItem,
        initialContentTypes,
        initialContentPlaceholderTypes,
        isOpenedFromPlaceholder,
      },
    });
  }
}
