<ng-container *ngIf="asideChatList$ | async as list">
  <div class="chats-label">
    {{'LAYOUT.ASIDE.CHATS' | translate }}
  </div>
  <div class="scroll-wrap">
    <div class="wrap" >
      <app-aside-chat-item *ngFor="let chat of list"
                           [chat]="chat"
                           (mouseleave)="onMouseLeave()"
      >
      </app-aside-chat-item>

    </div>

  </div>

</ng-container>



