import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrmCardUpdateRequest } from '@api-clients/crm-api-client';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { AppConfig } from '../../../../../../environments/environment';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';

@Injectable({
  providedIn: 'root',
})
export class CrmCardUpdateService {
  constructor(private http: HttpClient) {}

  crmCardUpdate(crmId: number, body: CrmCardUpdateRequest) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('crmId', `${crmId}`);
    return this.http
      .post<ApiResponse<CrmCardUpdateRequest>>(`${AppConfig.apiUrl}/crm-card/update`, body, {
        params: queryParams,
      })
      .pipe(apiResponsePipe);
  }
}
