import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SmsTemplate } from '@api-clients/crm-api-client';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  constructor(private http: HttpClient) {}

  getSMSTemplates(cityId: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('cityId', `${cityId}`);
    return this.http
      .get<ApiResponse<SmsTemplate>>(`${AppConfig.apiUrl}/sms/templates`, { params: queryParams })
      .pipe(apiResponsePipe);
  }
}
