/* eslint-disable no-console,no-return-assign */
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { SipStateListEvent } from '@api-clients/crm-api-client';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import ReconnectingWebSocket, { Options } from 'reconnecting-websocket';
import { Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { CardChangedEvent, DealChangedEvent } from '../../../+state/deals/deals.interface';
import { ManagerStateFacade } from '../../../+state/manager-state/manager-state.facade';
import { ManagerSipStateEnum } from '../../../+state/manager-state/manager-state.state';
import { Worker } from '../../../models';
import SockJS from 'sockjs-client';
import {
  AmplitudeEventKey,
  CallNotification,
  ClientPaidOnlineNotify,
  IpcEventType,
  Notify,
  OpenDealView,
  OpenWhatsappChat,
  SaleNotify,
  SetManagerStateManualBusy,
} from '../../../models/ipcEvent';
import { INewMessages, INewWhatsappCounter, IStatuses } from '../../../models/whatsapp';
import { AmplitudeTrackService } from '../amplitude/amplitude-track.service';
import { ElectronService } from '../../../../../../../libs/shared/utility/src/lib/services/electron.service';
import { LoggingService } from '../logging/logging.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { WorkerStateService } from '../worker/worker-state.service';
import { OnEndCallData, OnEndConversationData } from './helper/websocket-helper.model';
import { WebsocketHelperService } from './helper/websocket-helper.service';
import {
  IOpenCrmEvent,
  IWebsocket,
  OriginateByPhoneNumberCallback,
  WebsocketEventListener,
  WebsocketListener,
  WebsocketLoginData,
} from './interfaces/websocket.interface';
import { AppConfig } from '../../../../environments/environment';

const HEARTBEAT_INTERVAL = 60000;

const WEBSOCKET_URL = 'https://crm.bronix.com/serv';
const SOCK_JS_CDN = 'https://cdn.jsdelivr.net/npm/sockjs-client@1/dist/sockjs.min.js';

@UntilDestroy()
@Injectable()
export class WebsocketService implements IWebsocket, OnDestroy {
  private readonly sockJsCDN = SOCK_JS_CDN;
  private websocket: ReconnectingWebSocket | undefined;
  private readonly listeners: WebsocketListener;

  private alreadySubscribedToEvents = false;

  public constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private scriptLoader: ScriptLoaderService,
    private workerState: WorkerStateService,
    private router: Router,
    private electronService: ElectronService,
    private loggingService: LoggingService,
    private websocketHelperService: WebsocketHelperService,
    private managerStateFacade: ManagerStateFacade,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.listeners = {};
  }

  initWebsocket() {
    if (isPlatformBrowser(this.platformId)) {
      this.scriptLoader
        .load(this.sockJsCDN)
        .pipe(first())
        .subscribe(() => {
          this.workerState.currentWorker$
            .pipe(filter(worker => worker !== null))
            .pipe(first())
            .subscribe((worker: Worker) => {
              this.connect(worker.id, worker.nodeKey);
            });
        });
    }
  }

  protected loginData(id: number, key: string): WebsocketLoginData {
    return {
      id,
      key,
      bkey: '',
      ukey: '',
      nkey: 0,
      site: 0,
      loginParams: {},
      clientCallbackID: '',
      currency: 'kzt',
      location: '',
      isMobile: false,
      nid: 0,
    };
  }

  public send(event: string, data: any = {}): void {
    if (event && this.isOnline()) {
      const route = event.split('.');
      this.websocket.send(
        JSON.stringify({
          c: route[0],
          a: route[1],
          d: data,
        }),
      );
    } else {
      this.loggingService.log('Send error!');
    }
  }

  public on<T>(event: string, id?: string): Observable<T> {
    if (event) {
      return this.addListener<T>(event, id).asObservable();
    }
    this.loggingService.log(`[${Date()}] Can't add EventListener. Type of event is "undefined".`);
  }

  public off(event: string, id: string): void {
    if (this.listeners.hasOwnProperty(event) && this.listeners[event].hasOwnProperty(id)) {
      this.listeners[event][id].unsubscribe();
      delete this.listeners[event][id];
    }
  }

  public isOnline(): boolean {
    return this.websocket?.readyState === ReconnectingWebSocket.OPEN;
  }

  private connect(id: number, key: string): void {
    const options: Options = {
      WebSocket: SockJS,
    };

    this.websocket = new ReconnectingWebSocket(WEBSOCKET_URL, [], options);

    this.websocket.addEventListener('open', () => {
      if (this.alreadySubscribedToEvents) {
        this.loggingService.log(`[${Date()}] WebSocket reconnected!`);
        this.send('app.login', this.loginData(id, key));
        return;
      }

      this.loggingService.log(`[${Date()}] WebSocket connected!`);

      this.send('app.login', this.loginData(id, key));

      this.on('app.login').subscribe(() => {
        this.send('chans.join', this.callsJoinData);
      });

      this.electronService.ipcRenderer.on('send-to-main-window', (event, eventData: IpcEventType) => {
        if (eventData.event === AmplitudeEventKey) {
          const amplitudeEvent = eventData.amplitudeData.event;
          const amplitudeProps = eventData.amplitudeData.props || {};
          this.amplitudeTrackService.trackEvent(amplitudeEvent, amplitudeProps);
        }

        if (eventData.event === SetManagerStateManualBusy) {
          this.managerStateFacade.setManagerSipState(this.currentManagerState(true));
        }

        if (eventData.event === OpenDealView || eventData.event === OpenWhatsappChat) {
          if (eventData) {
            this.router.navigate([`/deals`]).then(() =>
              this.router.navigate([`/deals/view/${eventData.crmCardId}`]).then(() => {
                this.electronService.ipcRenderer.send('focus-on-window');
              }),
            );
          }
        }
      });

      this.managerStateFacade.managerSipState$.pipe(untilDestroyed(this)).subscribe(state => {
        this.send('calls.updateManagerState', this.currentManagerState(state.value));
      });

      this.on('calls.onJoin').subscribe(event => {
        console.log('calls.onJoin: ', event);
        this.send('calls.userConnected');
        this.heartBeatToWebsocket();
        this.send('calls.getCurrentSipStates');

        console.warn({
          apiUrl: AppConfig.apiUrl,
          environment: AppConfig.environment,
          production: AppConfig.production,
        });
      });

      this.on('calls.getCurrentSipStates').subscribe((sipState: SipStateListEvent[]) => {
        this.websocketHelperService.getCurrentSipStates(sipState);
      });

      this.on('calls.newWhatsappMessage').subscribe((newMessages: INewMessages) => {
        this.websocketHelperService.newWhatsappMessage(newMessages);
      });

      this.on('calls.newWhatsappCounter').subscribe((newWhatsappCounter: INewWhatsappCounter) => {
        this.websocketHelperService.newWhatsappCounter(newWhatsappCounter);
      });
      // не используется на данный момент
      // this.on('calls.onNewWhatsAppContact').subscribe(() => {
      // this.websocketHelperService.onNewWhatsAppContact(contacts);
      // });
      this.on('calls.newWhatsappStatus').subscribe((messagesWithNewStatus: IStatuses) => {
        this.websocketHelperService.newWhatsappStatus(messagesWithNewStatus);
      });

      this.on('calls.openWhatsAppWindow').subscribe((message: IOpenCrmEvent) => {
        this.websocketHelperService.openWhatsAppWindow(message);
      });

      this.on('calls.resetManualBusy').subscribe(() => {
        this.websocketHelperService.resetManualBusy();
      });

      this.on('calls.showNewInboxCallNotify').subscribe((notification: CallNotification) => {
        this.websocketHelperService.showNewInboxCallNotify(notification);
      });

      this.on('calls.updateUserData').subscribe((userData: CrmCardViewItem) => {
        this.websocketHelperService.updateUserData(userData);
      });

      this.on('calls.closeInboxNotify').subscribe((userData: any) => {
        this.websocketHelperService.closeInboxNotify(userData);
      });

      this.on('calls.onStartConversation').subscribe((data: CrmCardViewItem) => {
        this.websocketHelperService.onStartConversation(data);
      });

      this.on('calls.onEndConversation').subscribe((event: OnEndConversationData) => {
        this.websocketHelperService.onEndConversation(event);
      });

      this.on('calls.onEndCall').subscribe((data: OnEndCallData) => {
        this.websocketHelperService.onEndCall(data);
      });

      this.on('calls.clientPaidOnline').subscribe((data: ClientPaidOnlineNotify) => {
        this.websocketHelperService.clientPaidOnline(data);
      });

      this.on('calls.saleNotify').subscribe((data: SaleNotify) => {
        this.websocketHelperService.saleNotify(data);
      });

      this.on('calls.notifyById').subscribe((data: Notify) => {
        this.websocketHelperService.notifyById(data);
      });

      this.on('calls.dealChanged').subscribe((data: DealChangedEvent) => {
        this.websocketHelperService.dealChanged(data);
      });

      this.on('calls.cardChanged').subscribe((data: CardChangedEvent) => {
        this.websocketHelperService.cardChanged(data);
      });

      this.on('calls.originateByPhoneNumber').subscribe((data: OriginateByPhoneNumberCallback) => {
        console.log('calls.originateByPhoneNumber: ', data);
        this.websocketHelperService.showOutcomeCallInfo(data);
      });

      this.alreadySubscribedToEvents = true;
    });

    this.websocket.addEventListener('close', () => {
      this.loggingService.log(`[${Date()}] WebSocket close!`);
    });

    this.websocket.addEventListener('error', () => {
      this.loggingService.log(`[${Date()}] WebSocket error!`);
    });

    this.websocket.addEventListener('message', (event: MessageEvent) => {
      this.onMessage(event);
    });
  }

  private addListener<T>(event: string, id?: string): Subject<T> {
    const hash = id ? id : '*';

    if (!this.listeners[event]) {
      this.listeners[event] = {};
    }

    if (this.listeners[event].hasOwnProperty(hash)) {
      return this.listeners[event][hash];
    }

    return (this.listeners[event][hash] = new Subject<T>());
  }

  private onMessage(event: MessageEvent): void {
    const message = JSON.parse(event.data);

    for (const name in this.listeners) {
      if (this.listeners.hasOwnProperty(name)) {
        const lister = this.listeners[name];
        const isMessage = name === message.r;
        if (isMessage) {
          this.callMessage(lister, message.d);
        }
      }
    }
  }

  protected callMessage<T>(event: WebsocketEventListener<T>, data: T): void {
    for (const key in event) {
      if (event.hasOwnProperty(key)) {
        const subject = event[key];

        if (subject) {
          subject.next(data);
        } else {
          this.loggingService.log(`[${Date()}] Subject is "undefined"`);
        }
      }
    }
  }

  protected currentManagerState(state: boolean) {
    return {
      state: ManagerSipStateEnum.manual_busy,
      value: !state,
    };
  }

  public closeWebsocketConnection(): void {
    if (this.websocket) {
      this.unsubscribeFromAllListeners().then(() => {
        this.websocket.close();
        this.websocket = null;
      });
    }
  }

  unsubscribeFromAllListeners(): Promise<void> {
    return new Promise<void>(resolve => {
      for (const listenersKey in this.listeners) {
        if (this.listeners.hasOwnProperty(listenersKey)) {
          for (const hash in this.listeners[listenersKey]) {
            if (this.listeners[listenersKey].hasOwnProperty(hash)) {
              this.off(listenersKey, hash);
            }
          }
        }
      }

      resolve();
    });
  }

  protected get callsJoinData() {
    return {
      id: 'calls',
    };
  }

  protected heartBeatToWebsocket(): void {
    setInterval(() => {
      this.send('calls.heartbeat');
    }, HEARTBEAT_INTERVAL);
  }

  public ngOnDestroy() {
    this.closeWebsocketConnection();
  }
}
