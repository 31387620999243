<div class="bg" (click)="close()"></div>

<div #customComponentContainer class="custom-component-container"></div>

<div *ngIf="showToursDropArea"
     class="tour-drop-area-container">
  <div dndDropzone
       (dndDrop)="onDropTour($event)"
       class="tour-drop-area">
    <span>+ Перенести тур сюда</span>
  </div>
</div>

<div *ngIf="showCloseButton"
     (click)="close()"
     class="close">
  <button type="button" class="btn-close">
    <img src="assets/icons/deal-view/dd-close.svg">
  </button>
</div>
