import { WhatsappMessage, WhatsappScheduledMessage } from '@api-clients/crm-api-client';
import { createReducer, on } from '@ngrx/store';
import * as ChatTimelineActions from './chat-timeline.actions';

export const chatTimelineFeatureKey = 'chatTimeline';

export interface ChatTimelineState {
  currentReplyMessage: WhatsappMessage;
  scheduledMessages: WhatsappScheduledMessage[];
  loading: boolean;
  error: any | null;
  isSchedulingMessage: boolean;
  isScheduleMessageOverlayOpen: boolean;
  isScheduledMessagesListOpen: boolean;
}

export const initialState: ChatTimelineState = {
  currentReplyMessage: null,
  scheduledMessages: [],
  loading: false,
  error: null,
  isSchedulingMessage: false,
  isScheduleMessageOverlayOpen: false,
  isScheduledMessagesListOpen: false,
};

export const reducer = createReducer(
  initialState,

  on(ChatTimelineActions.setCurrentReplyMessage, (state, action) => {
    return {
      ...state,
      currentReplyMessage: action.replyMessage,
    };
  }),
  on(ChatTimelineActions.clearCurrentReplyMessage, state => {
    return {
      ...state,
      currentReplyMessage: null,
    };
  }),
  // Загрузка запланированных сообщений - начало
  on(ChatTimelineActions.loadScheduledMessages, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Загрузка запланированных сообщений - успех
  on(ChatTimelineActions.loadScheduledMessagesSuccess, (state, { messages }) => ({
    ...state,
    scheduledMessages: messages,
    loading: false,
  })),

  // Загрузка запланированных сообщений - ошибка
  on(ChatTimelineActions.loadScheduledMessagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Открытие овереля создания запланированных сообщений
  on(ChatTimelineActions.openScheduleMessageOverlay, state => ({
    ...state,
    isScheduleMessageOverlayOpen: true,
  })),

  // Закрытие овереля создания запланированных сообщений
  on(ChatTimelineActions.closeScheduleMessageOverlay, state => ({
    ...state,
    isScheduleMessageOverlayOpen: false,
  })),

  // Создание запланированного сообщения - начало
  on(ChatTimelineActions.createScheduledMessage, state => ({
    ...state,
    isSchedulingMessage: true,
    error: null,
  })),

  // Создание запланированного сообщения - успех
  on(ChatTimelineActions.createScheduledMessageSuccess, state => ({
    ...state,
    isSchedulingMessage: false,
    isScheduleMessageOverlayOpen: false,
  })),

  // Создание запланированного сообщения - ошибка
  on(ChatTimelineActions.createScheduledMessageFailure, (state, { error }) => ({
    ...state,
    isSchedulingMessage: false,
    error,
  })),

  // Открытие списка запланированных сообщений
  on(ChatTimelineActions.openScheduledMessagesList, state => ({
    ...state,
    isScheduledMessagesListOpen: true,
  })),

  // Закрытие списка запланированных сообщений
  on(ChatTimelineActions.closeScheduledMessagesList, state => ({
    ...state,
    isScheduledMessagesListOpen: false,
  })),

  // Перенос запланированного сообщения - начало
  on(ChatTimelineActions.rescheduleMessage, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Перенос запланированного сообщения - успех
  on(ChatTimelineActions.rescheduleMessageSuccess, state => ({
    ...state,
    loading: false,
  })),

  // Перенос запланированного сообщения - ошибка
  on(ChatTimelineActions.rescheduleMessageFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // Удаление запланированного сообщения - начало
  on(ChatTimelineActions.deleteScheduledMessage, state => ({
    ...state,
    error: null,
  })),

  // Удаление запланированного сообщения - успех
  on(ChatTimelineActions.deleteScheduledMessageSuccess, (state, { messageId }) => ({
    ...state,
    scheduledMessages: state.scheduledMessages.filter(message => message.id !== messageId),
  })),

  // Удаление запланированного сообщения - ошибка
  on(ChatTimelineActions.deleteScheduledMessageFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Сброс состояния таймлайна
  on(ChatTimelineActions.resetChatTimelineState, state => ({
    ...state,
    currentReplyMessage: null,
    scheduledMessages: [],
    loading: false,
    error: null,
    isSchedulingMessage: false,
    isScheduleMessageOverlayOpen: false,
    isScheduledMessagesListOpen: false,
  })),
);
