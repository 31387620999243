import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DealMarkNonConversionRequest, DefaultResponse } from '@api-clients/crm-api-client';
import { ApiResponse } from '../../../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class MarkNonConversionService {
  constructor(private http: HttpClient) {}

  markNonConversion(body: DealMarkNonConversionRequest) {
    return this.http
      .post<ApiResponse<DefaultResponse>>(`${AppConfig.apiUrl}/deal/mark-non-conversion`, body)
      .pipe(apiResponsePipe);
  }
}
