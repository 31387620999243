import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import {
  ZENMODE_EXIT_BEFORE_LOADING_COMPLETE,
  ZENMODE_START_SCREEN_TIPS_DISABLED,
} from '../../../../../../core/services/amplitude/amplitudeEvents';

enum PopupState {
  WELCOME_TIPS = 'WELCOME_TIPS',
  LOADING_TIP = 'LOADING_TIP',
}

@Component({
  selector: 'app-zenmode-welcome-popup-container',
  templateUrl: './zenmode-welcome-popup-container.component.html',
  styleUrls: ['./zenmode-welcome-popup-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZenmodeWelcomePopupContainerComponent implements OnInit {
  @Input() state: PopupState = PopupState.WELCOME_TIPS;
  @Output() close = new EventEmitter<void>();

  PopupState = PopupState;

  private readonly localStorageKey = 'dontShowZenModeTips';

  constructor(
    private dialogRef: MatDialogRef<ZenmodeWelcomePopupContainerComponent>,
    private dealsFacade: DealsFacade,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('dontShowZenModeTips') === 'true') {
      this.state = PopupState.LOADING_TIP;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  manualClose(): void {
    this.dealsFacade.toggleZenmode(false);
    this.onClose();
    this.amplitudeTrackService.trackEvent(ZENMODE_EXIT_BEFORE_LOADING_COMPLETE);
  }

  handleStartEvent(event: boolean): void {
    if (event) {
      localStorage.setItem(this.localStorageKey, 'true');
      this.amplitudeTrackService.trackEvent(ZENMODE_START_SCREEN_TIPS_DISABLED);
    } else {
      localStorage.removeItem(this.localStorageKey);
    }
    this.state = PopupState.LOADING_TIP;
  }

  handleLoadingCompleted(): void {
    this.onClose();
    this.dealsFacade.toggleZenmode(true);
  }
}
