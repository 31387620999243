<div class="datetimepicker-popup">
  <aside class="period-select">
    <ul>
      <li
        (click)="selectTimeOnTimegrid(period)"
        *ngFor="let period of TIME_PERIODS_ARRAYS"
        [ngClass]="{ '--active': selectedTimePeriod === period }"
      >
        {{ 'MODALS.ACTIONS.TIME_PERIOD.' + period | translate }}
      </li>
    </ul>
  </aside>
  <div class="calendars">
    <div class="month-calendar">
      <p-calendar
        (onSelect)="onDaySelect($event)"
        [autoZIndex]="true"
        [inline]="true"
        [firstDayOfWeek]="1"
        [ngModel]="this.taskStartDate"
        class="primeng-datepicker"
       ></p-calendar>
    </div>

    <div
      [ngClass]="{
        'not-empty-slot': selectedNotEmptySlot
      }"
      class="day-timeline-calendar" id="daytimelinecalendar"
    >
      <div class ="calendar-picker-info">
        <img alt="" src="../../../../../../../assets/icons/pointer.svg"/><span>{{CALENDAR_HINT}}</span>
      </div>
      <full-calendar
        #dayCalendar
        [options]="dayCalendarOptions">
      </full-calendar>
    </div>
  </div>
</div>
