import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PopupService } from '../../../../../../../../../../shared/services/popup-service.service';
import { SharedModule } from '../../../../../../../../../../shared/shared.module';
import {
  ChatTimeLineItem,
  ChatTimelineItemTypeEnum,
} from '../../../../../../../../../chat-timeline/interfaces/chat-timeline.interface';
import { ContentCreatorContentType } from '../../../../../../../../../content-creator/interfaces/content-creator.interface';
import { ContentCreatorApiService } from '../../../../../../../../../content-creator/services/content-creator-api.service';
import { ContentCreatorModalService } from '../../../../../../../../../content-creator/services/content-creator-modal.service';
import { SearchRecalcModalComponent } from '../../../../../../../../../search/components/recalc-modal/search-recalc-modal.component';
import { SEARCH_DATE_FORMAT, SearchTouristsRecalc } from '../../../../../../../../../search/search.model';
import { WebsocketToursSearchService } from '../../../../../../../../../search/services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestType,
  SearchResult,
  SearchResultsResponseTour,
} from '../../../../../../../../../search/services/search/websocket-tours.model';
import { HotOffersService } from '../../../../hot-offers.service';
import { HotOffersShortOffersToursNotFoundComponent } from './hot-offers-short-offers-tours-not-found.component';

@Component({
  selector: 'app-hot-offers-short-offers-modal',
  templateUrl: './hot-offers-short-offers-modal.component.html',
  styleUrls: ['./hot-offers-short-offers-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatInputModule, ReactiveFormsModule, SharedModule, HotOffersShortOffersToursNotFoundComponent],
})
export class HotOffersShortOffersModalComponent implements OnInit, OnDestroy {
  departCityId = input.required<number>();
  searchInProgress = signal<boolean>(false);
  modalRef = viewChild<ElementRef>('modal');

  private showRecalcModalSubscription: Subscription;
  private searchToursSubscription: Subscription;
  private createOfferSubscription: Subscription;

  private modalComponentRef: ComponentRef<SearchRecalcModalComponent>;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly datePipe: DatePipe,
    private readonly hotOffersService: HotOffersService,
    private readonly contentCreatorModalService: ContentCreatorModalService,
    private readonly searchService: WebsocketToursSearchService,
    private readonly popupService: PopupService,
    private readonly contentCreatorApi: ContentCreatorApiService,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.modalComponentRef?.destroy();

    this.showRecalcModalSubscription?.unsubscribe();
    this.searchToursSubscription?.unsubscribe();
    this.createOfferSubscription?.unsubscribe();
  }

  sendOffers() {
    const initFormParams: SearchTouristsRecalc = {
      adults: 2,
      childAges: [],
      splitRooms: false,
    };

    this.modalComponentRef = this.popupService.showPopup(
      SearchRecalcModalComponent,
      {},
      { anchorElement: this.modalRef() },
    );
    this.modalComponentRef.setInput('initFormParams', initFormParams);
    this.modalComponentRef.instance.onClose.subscribe(() => {
      this.popupService.closeAllModals();
      this.modalComponentRef.destroy();
    });
    this.modalComponentRef.instance.onSubmit.subscribe(tourists => {
      const tours = this.getTours();
      const initSearchRequests = tours.map(tour => this.getInitSearchRequest(tourists, tour));
      const observables: Observable<SearchResult>[] = [];
      initSearchRequests.forEach(initSearchRequest => {
        const result$ = this.searchService.searchTours(initSearchRequest).pipe(
          catchError(() =>
            of({
              searchIsDone: true,
              total: 0,
              tours: [],
            }),
          ),
        );
        observables.push(result$);
      });

      this.searchInProgress.set(true);
      this.searchToursSubscription = forkJoin(observables)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: combinedResults => {
            this.searchInProgress.set(false);

            let tourIds: string[] = [];
            combinedResults.forEach(result => {
              if (result.tours.length > 0) {
                tourIds = tourIds.concat(result.tours.map(tour => tour.id));
              }
            });
            if (tourIds.length === 0) {
              this.popupService.showPopup(HotOffersShortOffersToursNotFoundComponent);
            } else {
              this.createOfferSubscription = this.contentCreatorApi
                .getTourContentManagerOffer(tourIds)
                .subscribe(content => {
                  const message = content.offer.message;
                  const chatItem: ChatTimeLineItem = {
                    type: ChatTimelineItemTypeEnum.message,
                    data: {
                      text: message,
                    },
                    sortDate: this.datePipe.transform(new Date(), 'YYYY-MM-dd hh:mm:ss'),
                  };
                  this.contentCreatorModalService.showModal(chatItem, [
                    ContentCreatorContentType.CustomMessage,
                  ]);
                });
            }
          },
          error: error => {
            console.error('Error fetching data', error);
          },
        });
    });
  }

  private getTours(): SearchResultsResponseTour[] {
    const departCityId = this.departCityId();
    const tours: SearchResultsResponseTour[] = [];

    const countries = this.hotOffersService.countriesByDepartCityIdValue(departCityId);
    countries.forEach(countryAndHotels => {
      const hotelsAndTours = this.hotOffersService.hotelsByCountryIdValues(
        departCityId,
        countryAndHotels.countryId,
      );
      const hotelAndTours = hotelsAndTours[0];
      const tour = hotelAndTours.tours[0];

      tours.push(tour);
    });

    return tours;
  }

  private getInitSearchRequest(
    tourists: SearchTouristsRecalc,
    tour: SearchResultsResponseTour,
  ): InitSearchRequest {
    const initSearchRequest: InitSearchRequest = {
      id: WebsocketToursSearchService.generateId(),
      method: InitSearchRequestMethod.search,
      params: {
        params: {
          adults: tourists.adults,
          airlines: [],
          childrenAges: tourists.childAges.map(age => Number(age)),
          splitRooms: tourists.splitRooms,
          combined: 0,
          countryId: tour.country.id,
          dateFrom: this.datePipe.transform(tour.date, SEARCH_DATE_FORMAT),
          dateTo: this.datePipe.transform(tour.date, SEARCH_DATE_FORMAT),
          departCityId: tour.departCity.id,
          hotels: [tour.hotel.id],
          nightsFrom: tour.nights,
          nightsTo: tour.nights,
          notGDS: true,
          onlyHotels: false,
          starsList: [],
        },
        options: {
          type: InitSearchRequestType.main,
          groupResults: InitSearchRequestOptionsGroupResult.byHotel,
        },
      },
    };

    if (tour.meal.id) {
      initSearchRequest.params.params.meals = [tour.meal.id];
    }
    if (tour.operator.id) {
      initSearchRequest.params.params.operators = [tour.operator.id];
    }

    return initSearchRequest;
  }
}
