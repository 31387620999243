import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PopupService } from '../../../../../../shared/services/popup-service.service';
import { DatetimepickerComponent } from '../../modules/datetimepicker/datetimepicker.component';
import { VacationReminderPopupComponent } from './сomponents/vacation-reminder-popup/vacation-reminder-popup.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MonthYearFormatPipe } from './helpers/month-year-format.pipe';

export enum VacationReminderModalState {
  CLOSED_NO_REMINDER = 0,
  CLOSED_WITH_REMINDER = 1,
}

@Component({
  selector: 'app-vacation-reminder',
  templateUrl: './vacation-reminder.component.html',
  styleUrls: ['./vacation-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, MonthYearFormatPipe],
})
export class VacationReminderComponent implements AfterViewInit, OnDestroy {
  @Input() savedVacationDateTime: string;
  @Input() cardId: number;
  @ViewChild(DatetimepickerComponent) datetimepicker: DatetimepickerComponent;
  public modalState: VacationReminderModalState = null;
  public VacationReminderModalState = VacationReminderModalState;
  public popupRef: ComponentRef<VacationReminderPopupComponent> = null;
  private destroy$ = new Subject<void>();

  constructor(
    private popupService: PopupService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.initModalState();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initModalState(): void {
    this.modalState = this.savedVacationDateTime
      ? VacationReminderModalState.CLOSED_WITH_REMINDER
      : VacationReminderModalState.CLOSED_NO_REMINDER;
    this.cdr.detectChanges();
  }

  showPopup(): void {
    this.popupRef = this.popupService.showPopup(VacationReminderPopupComponent, {
      savedVacationDateTime: this.savedVacationDateTime,
      cardId: this.cardId,
    });
    this.popupRef.instance.closeClicked.pipe(takeUntil(this.destroy$)).subscribe(savedVacationDateTime => {
      this.savedVacationDateTime = savedVacationDateTime;
      this.closePopup();
    });
  }

  private closePopup(): void {
    this.popupRef.destroy();
    this.popupService.closeAllModals();
    this.initModalState();
  }
}
