<div class="footer" >
  <div class="icon-field">
    <img src="./assets/icons/whatsapp/emoji.svg" alt="Эмодзи" class="icon">
    <img src="./assets/icons/whatsapp/attach.svg" alt="Прикрепить что-то" class="icon">

  </div>
    <textarea class="input"
              [ngClass]="{'modal': isModal}"
              disabled></textarea>
    <app-circle-preloader></app-circle-preloader>

</div>
