import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { PopupService } from '../../../../../../../../../../../shared/services/popup-service.service';
// tslint:disable-next-line:max-line-length
import { FavoriteHotelsSearchFormComponent } from '../../../../search-form/favorite-hotels-search-form.component';
import { SearchFormService } from '../../../../search-form/search-form.service';

@Component({
  selector: 'app-search-result-control-search-form',
  templateUrl: './search-result-control-search-form.component.html',
  styleUrl: './search-result-control-search-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTooltip],
  providers: [PopupService],
})
export class SearchResultControlSearchFormComponent implements OnInit, OnDestroy {
  searchFormRef = viewChild<ElementRef>('searchForm');
  disabled = signal<boolean>(true);

  private formParams = signal<SearchFormParams>(null);
  private destroyRef = inject(DestroyRef);
  private searchFormComponentRef: ComponentRef<FavoriteHotelsSearchFormComponent>;

  constructor(
    private readonly popupService: PopupService,
    private readonly searchFormService: SearchFormService,
  ) {}

  ngOnInit(): void {
    this.searchFormService
      .loadFormParams$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formParams => {
        this.formParams.set(formParams);
        this.disabled.set(false);
      });
  }

  ngOnDestroy(): void {
    this.searchFormComponentRef?.destroy();
  }

  showSearchForm(): void {
    this.searchFormComponentRef = this.popupService.showPopup(
      FavoriteHotelsSearchFormComponent,
      {},
      {
        anchorElement: this.searchFormRef(),
      },
    );
    this.searchFormComponentRef.setInput('formParams', this.formParams());
    this.searchFormComponentRef.instance.close.subscribe(() => {
      this.popupService.closeAllModals();
    });
    this.searchFormComponentRef.instance.submit.subscribe(() => {
      this.popupService.closeAllModals();
    });
  }
}
