
<div [formGroup]="parentForm">
  <ng-container *ngIf="isDealViewMode">
  <div class="phones-label">
    <span>{{ 'PAGES.DEALS.PHONES.LABEL' | translate }}</span>
    <button (click)="addPhone()">
      <img src="assets/icons/deal-view/plus.svg" alt="plus">
      {{'PAGES.DEALS.PHONES.ADD_NUMBER' | translate}}</button>
  </div>

  <div class="form-group selected-phone"
  >
    <app-phone-input-with-code
      [parentForm]="parentForm"
      [concatenateCountryCode]="true"
      [readOnly]="true"
      [phoneItemWithCode]="selectedPhoneItem"
      [formArrayMode]="'phones'"
      [mode]="MODES.dealViewMode"
      [ngClass]="{dealViewMode: true}"
      [isSelectedPhone]="true"
    >
    </app-phone-input-with-code>
  </div>
  </ng-container>
  <ng-container
    *ngIf="phones.length > 1 && isDealViewMode">
    <div class="more-phones">
      <div class="toggle-btn" (click)="toggleMorePhones()">
    <span>
       {{isShowMorePhones ? HIDE_PHONES : SHOW_PHONES}}
      <ng-container *ngIf="!isShowMorePhones"> +{{phones.length - 1}} </ng-container>
    </span>
        <img
          [ngClass]="isShowMorePhones ? 'rotate' : ''"
          src="assets/icons/deal-view/arrow-down.svg" alt="arrow">
      </div>
    </div>

  </ng-container>

  <div class="phones-list"
    [ngClass]="
        {dealViewMode: isDealViewMode,
        open: isDealViewMode ? isShowMorePhones : false}"
        [@openClose]="isDealViewMode ? isShowMorePhones ? 'open' : 'closed' : null"
       formArrayName="phones">
    <div
      *ngFor="let phone of phones.controls;
      let idx = index; let last = last;
      let first = first"
      class="phone-item rounded-border">
        <label>
          <app-phone-input-with-code
            [mode]="mode"
            [parentForm]="parentForm"
            [formControlItem]="phone"
            [concatenateCountryCode]="true"
            [formControlNameValue]="idx"
            [isFirstPhone]="first"
            [initialFirstPhone]="firstPhone"
            [phoneItemWithCode]="setInitialPhoneValuesWithCode(phone)"
            [formArrayMode]="'phones'"
            [readOnly]="mode === MODES.dealViewMode && phone.value &&
            phone.valid && !phone.invalid"
            [ngClass]="
            {
            dealViewMode: mode === MODES.dealViewMode,
            dealCreateMode:mode === MODES.dealCreateMode,
            hidden: getIsHiddenPhoneControl(phone.value),
            'has-error': phone.invalid && phone.touched
            }"
            (removePhoneItem)="removePhone(idx)"
            (phoneValue)="onPhoneValueChange($event, idx)"
            (selectedPhoneChanged)="onPhoneSelected($event)"
            (countryCode)="onCountryCodeChange($event, idx)">
          </app-phone-input-with-code>
        </label>
        <app-form-error
          [parentForm]="parentForm"
          [assignedFormControl]="phone">
        </app-form-error>
      </div>
  </div>
  <button
    *ngIf="phones.length < phoneAmountLimit && mode === MODES.dealCreateMode"
    (click)="addPhone()"
    class="add-phone"
    type="button">
    <img
      alt="Добавить номер"
      src="../../../../../../../assets/icons/plus-outlined-blue.svg" />
    <span>{{'PAGES.DEALS.PHONES.ADD_NUMBER' | translate}}</span>
  </button>

</div>
