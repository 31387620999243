import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinutesSeconds',
  standalone: true,
})
export class SecondsToMinutesSecondsPipe implements PipeTransform {
  transform(duration: number): string {
    const m = Math.floor((+duration % 3600) / 60);
    const s = Math.floor((+duration % 3600) % 60);

    return `${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
  }
}
