import { Component, OnInit } from '@angular/core';
import { RightSideMenuItem } from './right-side-menu-item';
import { PopupService } from '../../../../shared/services/popup-service.service';

@Component({
  selector: 'app-right-side-menu',
  templateUrl: './right-side-menu.component.html',
  styleUrls: ['./right-side-menu.component.scss'],
})
export class RightSideMenuComponent implements OnInit {
  public menuItems: RightSideMenuItem[] = [
    {
      icon: 'call-list',
      label: 'LAYOUT.ASIDE.CALL_LIST',
      link: '/call-list',
    },
    {
      icon: 'recommendations',
      label: 'LAYOUT.ASIDE.RECOMMENDATIONS',
      link: '/recommendations',
    },
    {
      icon: 'settings',
      label: 'LAYOUT.ASIDE.SETTINGS',
      link: '/settings',
    },
    {
      icon: 'logout',
      label: 'LAYOUT.ASIDE.LOGOUT',
      link: '/logout',
    },
  ];

  constructor(private popupService: PopupService) {}

  ngOnInit(): void {}

  close() {
    this.popupService.closeAllModals();
  }
}
