import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, map, tap } from 'rxjs/operators';
import { WorkerViewResult } from '@api-clients/api-client/dist/models';
import { OPERATION_CRM_ACCESS } from '../../../helpers/operations';

import { Worker } from '../../../models';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

const WORKER_PERMISSIONS_EXPAND = 'worker.permissions';

@Injectable({
  providedIn: 'root',
})
export class WorkerStateService {
  public hasAccess$: Observable<boolean>;
  public currentWorker$: Observable<Worker>;
  private currentWorkerSub$: BehaviorSubject<Worker>;
  private hasAccessSub$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private permissionsService: NgxPermissionsService) {
    this.currentWorkerSub$ = new BehaviorSubject<Worker>(null);
    this.hasAccessSub$ = new BehaviorSubject<boolean>(false);
    this.currentWorker$ = this.currentWorkerSub$.asObservable();
    this.hasAccess$ = this.hasAccessSub$.asObservable();
  }

  // TODO переделать именование Subject-ов под новый стайлгайд
  set accessState(state: boolean) {
    this.hasAccessSub$.next(state);
  }

  get accessStateValue() {
    return this.hasAccessSub$.value;
  }

  set currentWorker(worker: Worker) {
    this.currentWorkerSub$.next(worker);
  }

  get currentWorkerValue(): Worker {
    return this.currentWorkerSub$.value;
  }

  get currentCityId(): number {
    return this.currentWorkerSub$.value?.cityId;
  }

  get currentDepartCityId(): number {
    return this.currentWorkerSub$.value?.departCityId;
  }

  get workerId(): number {
    return this.currentWorkerSub$?.value?.id;
  }

  get employeeId(): number {
    return this.currentWorkerSub$?.value?.employeeId;
  }

  getInfo(id: number, token: string, expanded = WORKER_PERMISSIONS_EXPAND) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', `${id}`);
    queryParams = queryParams.append('expand', expanded);

    return this.http
      .get<ApiResponse<WorkerViewResult>>(`${AppConfig.apiUrl}/worker/view`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
        apiResponsePipe,
        tap((workerInfo: WorkerViewResult) => {
          this.checkAccess(workerInfo.permissions);
        }),
        map((workerInfo: WorkerViewResult) => {
          const worker: Worker = { ...workerInfo, token };

          this.accessState = true;
          this.currentWorker = worker;
          return worker;
        }),
      );
  }

  private checkAccess(workerPermissions) {
    const permissions: string[] = workerPermissions
      .filter(permission => {
        return permission.value;
      })
      .map(permission => {
        return permission.name;
      });
    if (permissions.indexOf(OPERATION_CRM_ACCESS) === -1) {
      this.accessState = false;
      throw new Error('Нет доступ в crm');
    }
    this.permissionsService.loadPermissions(permissions);
  }

  clearWorkerState() {
    this.permissionsService.flushPermissions();
    this.accessState = false;
  }
}
