import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { WorkerConfigStatePayload } from './wokrer-config.interface';
import * as WorkerConfigSelectors from './wokrer-config.selectors';
import { LoadConfig, SetAsideChatsScrollBar } from './wokrer-config.actions';

@Injectable()
export class WorkerConfigFacade {
  whatsappNotificationsEnabled$ = this.store.pipe(
    select(WorkerConfigSelectors.getWhatsappNotificationsEnabled),
  );
  asideChatsScrollBarEnabled$ = this.store.pipe(select(WorkerConfigSelectors.getAsideChatsScrollBarEnabled));

  constructor(private store: Store<WorkerConfigStatePayload>) {}

  loadConfig() {
    this.store.dispatch(new LoadConfig());
  }

  setAsideChatsScrollBar(isEnabled: boolean) {
    this.store.dispatch(new SetAsideChatsScrollBar(isEnabled));
  }
}
