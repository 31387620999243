import { Component, Input, OnInit } from '@angular/core';
import { LastActionManagerOfferItem } from '@api-clients/crm-api-client/models/last-action-manager-offer-item';

@Component({
  selector: 'app-last-action-offer',
  templateUrl: './last-action-offer.component.html',
  styleUrls: ['./last-action-offer.component.scss'],
})
export class LastActionOfferComponent implements OnInit {
  @Input() lastActionItem: LastActionManagerOfferItem;
  @Input() actionsDateFormat: string;

  ngOnInit(): void {}
}
