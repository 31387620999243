import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-sticker',
  templateUrl: './message-sticker.component.html',
  styleUrls: ['./message-sticker.component.scss'],
})
export class MessageStickerComponent {
  @Input() mediaSource: string;
}
