import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilityModule } from '../../../../../../../../libs/shared/utility/src';
import { PreloaderModule } from '../../../../ui-components/preloader/preloader.module';
import { ManagerOffersComponent } from './manager-offers.component';
import { ManagerOffersListService } from './services/manager-offers-list.service';
import { ManagerOffersModalService } from './services/manager-offers-modal.service';

@NgModule({
  declarations: [ManagerOffersComponent],
  imports: [CommonModule, TranslateModule, SharedUtilityModule, RouterLink, PreloaderModule],
  providers: [ManagerOffersModalService, ManagerOffersListService],
})
export class ManagerOffersModule {}
