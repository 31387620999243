import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchResultsResponseTour } from './websocket-tours.model';

@Injectable({
  providedIn: 'root',
})
export class ToursStorageService {
  private tourResults: Map<string, BehaviorSubject<SearchResultsResponseTour[]>> = new Map<
    string,
    BehaviorSubject<SearchResultsResponseTour[]>
  >();

  flushSearchResults(requestId: string): void {
    if (!this.tourResults.has(requestId)) {
      return;
    }
    this.tourResults.get(requestId).complete();
    this.tourResults.delete(requestId);
  }

  flushAllSearchResults(): void {
    this.tourResults.forEach((result, key) => {
      if (key === 'main-search') {
        result.next([]);
      } else {
        result.complete();
        this.tourResults.delete(key);
      }
    });
  }

  saveSearchResults(requestId: string, tours: SearchResultsResponseTour[]): void {
    if (!this.tourResults.has(requestId)) {
      this.tourResults.set(requestId, new BehaviorSubject<SearchResultsResponseTour[]>(tours));
    } else {
      const currentToursSubject = this.tourResults.get(requestId);
      const currentTours = currentToursSubject.value;
      const newTours = this.updateTours(currentTours, tours);

      currentToursSubject.next(newTours);
    }
  }

  getSearchResults(requestId: string): SearchResultsResponseTour[] {
    if (!this.tourResults.has(requestId)) {
      this.tourResults.set(requestId, new BehaviorSubject<SearchResultsResponseTour[]>([]));
    }

    return this.tourResults.get(requestId).value;
  }

  getSearchResults$(requestId: string): Observable<SearchResultsResponseTour[]> {
    if (!this.tourResults.has(requestId)) {
      this.tourResults.set(requestId, new BehaviorSubject<SearchResultsResponseTour[]>([]));
    }

    return this.tourResults.get(requestId).asObservable();
  }

  private updateTours(
    currentTours: SearchResultsResponseTour[],
    newTours: SearchResultsResponseTour[],
  ): SearchResultsResponseTour[] {
    const results: { [key: number]: SearchResultsResponseTour } = {};
    const allTours = currentTours.concat(newTours);
    for (const tour of allTours) {
      if (results.hasOwnProperty(tour.hotel.id)) {
        if (results[tour.hotel.id].price.value > tour.price.value) {
          results[tour.hotel.id] = tour;
        }
      } else {
        results[tour.hotel.id] = tour;
      }
    }

    return Object.values(results);
  }
}
