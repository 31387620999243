import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/components/login/login.component';
import { AuthGuard } from './helpers/auth.guard';
import { MainComponent } from './modules/layout/components/main/main.component';
import { DealsListComponent } from './modules/deals/modules/deals-list/deals-list.component';
import { HistoryListComponent } from './modules/history/components/history-list/history-list.component';
import { CallReviewListComponent } from './modules/call-review/components/call-review-list/call-review-list.component';
import { HelpPageComponent } from './modules/help-page/components/help-page/help-page.component';
import { SettingsComponent } from './modules/settings/components/settings/settings.component';
import { AddDealComponent } from './modules/deals/modules/add-deal/add-deal.component';
import { TaskListComponent } from './modules/task-list/task-list.component';
import { DealViewComponent } from './modules/deals/modules/deal-view/deal-view.component';
import { SearchResultsComponent } from './modules/layout/components/search-form/search-results/search-results.component';
import { ComplaintListComponent } from './modules/complaint/components/complaint-list/complaint-list.component';
import { NotifyListComponent } from './modules/notify-list/notify-list.component';
import { CallListComponent } from './modules/call-list/components/call-list/call-list.component';
import { WhatsappComponent } from './modules/whatsapp/components/whatsapp.component';
import { DealViewSkeletonComponent } from './modules/deals/modules/deal-view/components/deal-view-skeleton/deal-view-skeleton.component';
import { RecommendationsComponent } from './modules/recommendations/components/recommendations/recommendations.component';
import { UpdateWindowComponent } from './modules/layout/components/update-window/update-window.component';
import { ZenmodeComponent } from './modules/zenmode/components/zenmode-list/zenmode.component';
import { LogoutComponent } from './modules/login/components/logout/logout.component';
import { WhatsappTemplatesSettingsComponent } from './modules/settings/components/whatsapp-templates-settings/whatsapp-templates-settings.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/deals',
      },
      {
        path: 'deals',
        component: DealsListComponent,
      },
      {
        path: 'deals/add',
        component: AddDealComponent,
      },
      {
        path: 'call-list',
        component: CallListComponent,
      },
      {
        path: 'deals/view/:id',
        component: DealViewComponent,
      },
      {
        path: 'deals/view/:id/:messageTourId',
        component: DealViewComponent,
      },
      {
        path: 'deals/preload',
        component: DealViewSkeletonComponent,
      },
      {
        path: 'deals/view',
        component: DealViewComponent,
      },
      {
        path: 'history',
        component: HistoryListComponent,
      },
      {
        path: 'notify',
        component: NotifyListComponent,
      },
      {
        path: 'complaint',
        component: ComplaintListComponent,
      },
      {
        path: 'call-review',
        component: CallReviewListComponent,
      },
      {
        path: 'task-list',
        component: TaskListComponent,
      },
      {
        path: 'help',
        component: HelpPageComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'search-results',
        component: SearchResultsComponent,
      },
      {
        path: 'whatsapp',
        component: WhatsappComponent,
      },
      {
        path: 'update',
        component: UpdateWindowComponent,
      },
      {
        path: 'recommendations',
        component: RecommendationsComponent,
      },
      {
        path: 'zenmode-list',
        component: ZenmodeComponent,
      },
      {
        path: 'whatsapp/chat/:id',
        component: WhatsappComponent,
      },
      {
        path: 'quick-answers',
        component: WhatsappTemplatesSettingsComponent,
      },
      {
        path: 'chats',
        loadChildren: () => import('./modules/chats/chats.module').then(m => m.ChatsModule),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
