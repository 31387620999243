import { Pipe, PipeTransform } from '@angular/core';
import { FavoriteHotelsSortDirection } from '../../favorites-hotels.model';

@Pipe({
  name: 'favoriteHotelsSort',
  standalone: true,
})
export class FavoriteHotelsSortPipe implements PipeTransform {
  transform(value: FavoriteHotelsSortDirection, ...args: any[]): any {
    switch (value) {
      case FavoriteHotelsSortDirection.salesCount:
        return 'По продажам';
      case FavoriteHotelsSortDirection.priceAsc:
        return 'По цены';
      case FavoriteHotelsSortDirection.discountDesc:
        return 'По скидке';
      case FavoriteHotelsSortDirection.hotelRating:
        return 'По рейтингу';
      case FavoriteHotelsSortDirection.conversion:
        return 'По конверсии';
      default:
        return value;
    }
  }
}
