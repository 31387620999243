<div class="deals-content-wrap">
  <form [formGroup]="form">
    <div class="add-deal-header">
      <h5 class="header-title">{{ 'PAGES.DEALS.ADD_DEAL.TITLE' | translate }}</h5>
      <button (click)="closePopup()" class="close-button" type="button">
        <img alt="" src="../../../../../assets/icons/close-icon.svg" />
      </button>
    </div>

    <div class="deals-personal-info">
      <div class="deals-container-first">
        <div class="form-group" formGroupName="card">
          <label class="form-label --large" for="name">{{ 'PAGES.DEALS.ADD_DEAL.NAME' | translate }}</label>
          <div>
            <input class="form-control --name" formControlName="name" id="name" type="text" />
            <app-form-error [parentForm]="form" [assignedFormControl]="getCard.get('name')"></app-form-error>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label --large">{{ 'PAGES.DEALS.ADD_DEAL.SOURCE' | translate }}</label>
          <div>
            <app-select
              *ngIf="dealSources$ | async as dealSources"
              [options]="dealSources"
              [placeholder]="placeholderSourceField"
              [firstOptionAsDefault]="true"
              [optionValue]="'id'"
              [optionLabel]="'title'"
              [hash]="'sourceId'"
              [defaultValue]="''"
              formControlName="sourceId"
            ></app-select>
            <app-form-error [parentForm]="form" [assignedFormControl]="form.get('sourceId')"></app-form-error>
          </div>
        </div>
      </div>
      <div class="deals-container">
        <div class="phone-list form-group">
          <label class="form-label --large">{{ 'PAGES.DEALS.ADD_DEAL.PHONE' | translate }}</label>
          <app-multiple-phone-input
            [screenType]="screenType"
            [parentForm]="getCard"
            [mode]="MULTIPLE_PHONE_INPUT_MODES.dealCreateMode"
            (phonesWithCountryCode)="phonesWithCountryCodes = $event"
            class="deal-create-mode"
          ></app-multiple-phone-input>
          <ng-container *ngIf="alreadyExistingDeal">
            <div class="alert-danger">
              {{ 'PAGES.DEALS.ADD_DEAL.NUMBER_ALREADY_USE' | translate }}
              <a (click)="redirectToExistingCard()">
                {{ 'PAGES.DEALS.ADD_DEAL.REDIRECT_TO_CARD' | translate }}
              </a>
            </div>
          </ng-container>
          <div id="blink" *ngIf="isSearching">{{ 'COMMON.LOADING' | translate }} ...</div>
        </div>
      </div>
    </div>
    <h5 class="next-action-title">{{ 'PAGES.DEALS.ADD_DEAL.NEXT_TASK.TITLE' | translate }}</h5>
    <div class="deals-intent">
      <div class="deals-container-first">
        <div class="next-action-form">
          <div formGroupName="task" class="form-group">
            <label class="form-label --large">{{
              'PAGES.DEALS.ADD_DEAL.NEXT_TASK.ACTION' | translate
            }}</label>
            <app-select
              *ngIf="actionTypes"
              [optionLabel]="'value'"
              [optionValue]="'key'"
              [options]="actionTypes"
              [placeholder]="placeholderAction"
              [firstOptionAsDefault]="true"
              [hash]="'type'"
              formControlName="type"
            ></app-select>
          </div>
          <div formGroupName="task" class="form-group">
            <label class="form-label --large">{{ 'PAGES.DEALS.ADD_DEAL.CHOOSE_CITY' | translate }}</label>
            <app-select
              *ngIf="citiesList"
              [optionLabel]="'value'"
              [optionValue]="'key'"
              [options]="citiesList"
              [placeholder]="placeholderCity"
              [defaultValue]="currentCityId"
              (change)="onCityIdChange($event)"
              [hash]="'city'"
              formControlName="cityId"
            ></app-select>
          </div>
          <div class="form-group choose-manager">
            <label class="form-label --large">{{
              'PAGES.DEALS.ADD_DEAL.NEXT_TASK.EXECUTOR' | translate
            }}</label>
            <div class="choose-manager-toggle">
              <app-select
                *ngIf="managersList$ | async as managersList; else loadingManagers"
                [optionLabel]="'nameWithSip'"
                [optionValue]="'id'"
                [options]="managersList"
                [placeholder]="placeholderManager"
                [defaultValue]="managerDefaultId"
                [firstOptionAsDefault]="false"
                (change)="onManagerIDChange($event)"
                [hash]="'manager'"
                [isListDirectionUp]="true"
                formControlName="managerId"
              ></app-select>
            </div>
            <ng-template #loadingManagers> Загрузка ... </ng-template>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label --large" for="roistatVisitId">{{
            'PAGES.DEALS.ADD_DEAL.ROISTAT_VISIT_ID' | translate
          }}</label>
          <div>
            <input
              class="form-control --roistat"
              formControlName="roistatVisitId"
              id="roistatVisitId"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="deals-container">
        <div class="form-group intent-select">
          <label class="form-label --large">{{ 'PAGES.DEALS.ADD_DEAL.NEXT_TASK.STAGES' | translate }}</label>
          <div>
            <app-select
              *ngIf="stages"
              [optionLabel]="'value'"
              [optionValue]="'key'"
              [options]="stages"
              [placeholder]="stagesPlaceholder"
              [hash]="'stage'"
              [firstOptionAsDefault]="true"
              formControlName="stage"
              class="full-width-control"
            ></app-select>
          </div>
        </div>
        <div formGroupName="task" class="form-group intent-select" [hidden]="true">
          <label class="form-label --large">{{ 'PAGES.DEALS.ADD_DEAL.NEXT_TASK.INTENT' | translate }}</label>
          <div>
            <app-select
              *ngIf="intentTypes"
              [optionLabel]="'value'"
              [optionValue]="'key'"
              [options]="intentTypes"
              [placeholder]="placeholderIntent"
              [firstOptionAsDefault]="true"
              (change)="onIntentChange($event)"
              [hash]="'intent'"
              formControlName="intent"
            ></app-select>
            <input
              *ngIf="showOwnIntent"
              [placeholder]="placeholderOwnIntent"
              class="own-intent-input form-control"
              formControlName="ownIntent"
              id="ownIntent"
              type="text"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="form-label --large">{{ 'PAGES.DEALS.ADD_DEAL.NEXT_TASK.DATE' | translate }}</label>
          <div formGroupName="task" class="date-time-form">
            <label (click)="openDatetimepickerPopup()" class="date-input">
              <input
                [readOnly]="true"
                formControlName="meetingDate"
                class="form-control --date"
                type="text"
              />
              <app-form-error
                [parentForm]="form"
                [assignedFormControl]="form.get('task.meetingDate')"
              ></app-form-error>
            </label>
            <label (click)="openDatetimepickerPopup()" class="time-input">
              <input
                [readOnly]="true"
                formControlName="meetingTime"
                class="form-control --time"
                type="text"
              />
              <app-form-error
                [parentForm]="form"
                [assignedFormControl]="form.get('task.meetingTime')"
              ></app-form-error>
            </label>
            <app-datetimepicker
              *ngIf="datetimePopup"
              [taskTitle]="taskTitle"
              [meetingDateTime]="meetingDateTime"
              (taskDateEvent)="setMeetingDateTime($event)"
              (manualClose)="closeDatetimepickerPopup()"
              (clickOutside)="closeDatetimepickerPopup()"
              [delayClickOutsideInit]="true"
              [exclude]="'label.date-input, label.time-input'"
              class="datetimepicker"
            ></app-datetimepicker>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label --large">{{ 'PAGES.DEALS.ADD_DEAL.NEXT_TASK.COMMENT' | translate }}</label>
          <label formGroupName="task" class="date-input">
            <textarea formControlName="managerComment" class="form-control --comment" type="text"> </textarea>
            <app-form-error
              [parentForm]="form"
              [assignedFormControl]="form.get('task.managerComment')"
            ></app-form-error>
          </label>
        </div>
      </div>
    </div>
    <div class="add-deal-footer">
      <button
        [disabled]="isLoading || form.invalid || alreadyExistingDeal"
        (click)="onSubmit()"
        class="btn --active"
      >
        {{ 'PAGES.DEALS.ADD_DEAL.ADD_REQUEST.APPOINT_A_MANAGER' | translate }}
      </button>
      <button class="btn --transparent" type="button" (click)="closePopup()">
        {{ 'MODALS.ACTIONS.CANCEL' | translate }}
      </button>
    </div>
  </form>
</div>
<ng-container *ngIf="isLoading">
  <app-preloader></app-preloader>
</ng-container>
