import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { SalesFunnelTooltipComponent } from './components/hint/hint.component';
import { SharedModule } from '../../../../../../shared/shared.module';

@NgModule({
  declarations: [SalesFunnelTooltipComponent],
  imports: [CommonModule, SharedModule],
  exports: [SalesFunnelTooltipComponent],
  providers: [CookieService],
})
export class SalesFunnelTooltipPopupModule {}
