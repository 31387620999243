<div class="zenmode-welcome-popup">
  <div class="header">
    <div class="logo"><img src="assets/img/logo.svg" alt="logo"></div>
    <button (click)="manualClose()">
      <img src="assets/icons/zenmode/exit.svg" alt="exit">
    </button>
  </div>

  <div class="body">
    <ng-container [ngSwitch]="state">
      <div *ngSwitchCase="PopupState.WELCOME_TIPS">
        <app-zenmode-welcome-tips
          (startEvent)="handleStartEvent($event)"
        ></app-zenmode-welcome-tips>
      </div>

      <div *ngSwitchCase="PopupState.LOADING_TIP">
       <app-zenmode-welcome-loading
       (loadingCompleted)="handleLoadingCompleted()"
       >
       </app-zenmode-welcome-loading>
      </div>
    </ng-container>
  </div>
</div>
