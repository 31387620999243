<div class="direction">
  <div class="col">
    <div class="airline-logo">
      @if (direction().segments[0].hasAirlineLogo) {
        <img class="logo" [attr.src]="airlineLogo" [matTooltip]="direction().segments[0].airline">
      }
    </div>
    <div class="airport">{{ departureAirport }}</div>
    <div class="time">{{ departureDate | date:'HH:mm' }}</div>
    <div class="line"></div>
    <div class="time">{{ arrivalDate | date:'HH:mm' }}</div>
    <div class="airport">{{ arrivalAirport }}</div>
  </div>
  <div class="col">
    <div class="airline-code">{{ direction().segments[0].number }}</div>
    <div class="transfers">
      {{ transferLabel }}
    </div>
  </div>
</div>
