import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CrmCardListPostSaleTourPackage } from '@api-clients/crm-api-client';
import { CrmCardListItem, CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { CrmCardListPostSaleItem } from '@api-clients/crm-api-client/models/crm-card-list-post-sale-item';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { DealsListFacade } from '../../../../+state/deals-list/deals-list.facade';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { ZenmodeAbTestService } from '../../../layout/services/zenmode-ab-test.service';
import { CurrentStageEnum } from '../deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import { DefaultCategories, NeedConnectType, SalesFunnelCategory, SalesFunnelStage } from './deal-list';
import { DealFlowService } from './services/deal-flow.service';
import { DealListService } from './services/deal-list.service';

const POST_SALE_STATUS = 2;
const NEW_CLIENT_STATUS = 0;
const HAS_NEW_MESSAGES_STATUS = 1;

@Component({
  selector: 'app-deals-list',
  templateUrl: './deals-list.component.html',
  styleUrls: ['./deals-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsListComponent implements OnInit, OnDestroy {
  public currentStageEnum = CurrentStageEnum;
  public categories: SalesFunnelCategory[] = [];
  public combinedDeals$: Observable<[CrmCardViewItem[], CrmCardListPostSaleItem[]]>;
  public currentStage$: Observable<SalesFunnelStage> = this.dealsListFacade.currentStage$;
  public currentStage: SalesFunnelStage | null;
  public dealsSub: Subscription;
  public dealsListSub: Subscription;
  public stages: { [x: string]: string };
  public defaultStage: SalesFunnelStage = DefaultCategories[0].stages[0];
  public selectedStage: SalesFunnelStage;
  public isLoading = true;
  public isOnlyZenMode$ = this.zenmodeAbTestService.isOnlyZenmodeForWorker$;
  public selectedCategory: SalesFunnelCategory | null = null;
  public currentCategory$: Observable<SalesFunnelCategory> = this.dealsListFacade.currentCategory$;
  public currentCategory: SalesFunnelCategory | null;
  public dealsListCategorySub: Subscription;
  public selectedDeals: CrmCardViewItem[] = [];
  public get activeStage(): SalesFunnelStage | undefined {
    if (this.selectedCategory && !this.selectedStage) {
      return null;
    }
    return this.selectedStage || this.defaultStage;
  }

  get hasDeals(): boolean {
    return (this.selectedDeals.length > 0 ? this.selectedDeals : this.activeStage?.deals || []).length > 0;
  }

  POST_SALE_STATUS = POST_SALE_STATUS;

  public isSelectedPostSale$: Observable<boolean>;
  public selected$: Observable<SalesFunnelCategory | SalesFunnelStage>;
  public selectedStage$: Observable<SalesFunnelStage>;
  public selectedCategory$: Observable<SalesFunnelCategory>;
  public categories$: Observable<SalesFunnelCategory[]>;
  public deals$: Observable<CrmCardViewItem[]>;
  public postSaleDeals$: Observable<CrmCardListPostSaleItem[]>;

  constructor(
    private dealsFacade: DealsFacade,
    private dealsListFacade: DealsListFacade,
    private dealList: DealListService,
    private cdRef: ChangeDetectorRef,
    private zenmodeAbTestService: ZenmodeAbTestService,
    private dealFlowService: DealFlowService,
  ) {
    this.combinedDeals$ = combineLatest([this.dealsFacade.dealsList$, this.dealsFacade.dealsListPostSale$]);
  }

  // REFACTOR HTML TS!!!
  ngOnInit(): void {
    this.dealsFacade.loadAll();
    this.categories$ = this.dealFlowService.categories$;
    this.deals$ = this.dealFlowService.regularDeals$;
    this.postSaleDeals$ = this.dealFlowService.postSaleDeals$;
    this.selectedCategory$ = this.dealFlowService.selectedCategory$;
    this.selectedStage$ = this.dealFlowService.selectedStage$;
    this.isSelectedPostSale$ = this.dealFlowService.isSelectedPostSale$;
    // this.isLoading = true;
    // this.dealsFacade.loadAll();
    // // подписываемся на observable и получаем последний открытый этап сделки
    // this.dealsListSub = this.currentStage$.subscribe(currentStage => {
    //   this.currentStage = currentStage;
    // });
    // // подписываемся на observable и получаем последнюю открытую категорию сделок
    // this.dealsListCategorySub = this.currentCategory$.subscribe(currentCategory => {
    //   this.currentCategory = currentCategory;
    // });
    // this.dealsSub = this.combinedDeals$.subscribe(([deals, postSalesDeals]) => {
    //   this.categories = this.generateDealCategories(deals, postSalesDeals);
    //   this.initializeSelection();
    //   this.isLoading = false;
    //   this.cdRef.detectChanges();
    // });
  }

  private initializeSelection(): void {
    if (this.currentCategory) {
      this.selectedStage = null;
      this.selectedCategory = this.currentCategory;
      this.selectedDeals = this.getUniqueDeals(this.selectedCategory);
    } else if (this.currentStage?.name) {
      let stage = null;
      for (const category of this.categories) {
        const stageIndex = category.stages.findIndex(s => s.name === this.currentStage.name);
        if (stageIndex !== -1) {
          stage = category.stages[stageIndex];
          break;
        }
      }
      this.selectedStage = stage || this.categories[0].stages[0];
      this.selectedCategory = null;
    } else {
      // Если ничего не выбрано, устанавливаем дефолтное значение
      this.selectedStage = this.categories[0].stages[0];
      this.selectedCategory = null;
    }
  }

  isTodayDeal(deal: CrmCardListItem | CrmCardViewItem): boolean {
    return this.dealList.isTodayDeal(deal);
  }

  isTodayDealPostSale(deal: CrmCardListPostSaleTourPackage): boolean {
    if (deal) {
      return this.dealList.isTodayDealPostSale(deal);
    } else return false;
  }

  hasNewMessages(deal) {
    return deal.deal?.conversationStatus === HAS_NEW_MESSAGES_STATUS;
  }

  generateDealCategories(
    deals: CrmCardViewItem[],
    postSalesDeals: CrmCardListPostSaleItem[],
  ): SalesFunnelCategory[] {
    let updatedCategories: SalesFunnelCategory[] = JSON.parse(JSON.stringify(DefaultCategories));
    updatedCategories.forEach(category => {
      category.count_all = 0;
      category.count_forgotten = 0;
      category.stages.forEach(stage => {
        stage.count = 0;
        stage.count_deals_with_expired_tasks = 0;
        stage.count_today_forgotten_no_task = 0;
        stage.count_today = 0;
        stage.deals = [];
      });
    });

    deals.forEach(deal => {
      const categoryIndex = updatedCategories.findIndex(category =>
        category.stages.some(
          stage => stage.name === deal.deal.stage && deal.deal.status !== NEW_CLIENT_STATUS,
        ),
      );

      const hasNewMessages = this.hasNewMessages(deal);
      const isDealNextTaskMissing = this.getIsDealNextTaskMissing(deal);
      const isDealNextTaskExpired = this.getIsDealNextTaskExpired(deal);
      const isDealForgotten = this.getIsDealForgotten(deal);
      const isDealToday = this.getIsTodayDeal(deal);

      if (categoryIndex !== -1) {
        updatedCategories[categoryIndex].count_all += 1;
        if (isDealForgotten) {
          updatedCategories[categoryIndex].count_forgotten += 1;
        }

        const stageIndex = updatedCategories[categoryIndex].stages.findIndex(
          stage => stage.name === deal.deal.stage,
        );

        const experimentalStageIndex = updatedCategories[categoryIndex].stages.findIndex(
          stage => stage.name === deal.experimentalData?.daysStage,
        );

        if (hasNewMessages) {
          updatedCategories[categoryIndex].stages[stageIndex].hasNewMessages = true;
        }

        if (isDealToday || isDealForgotten || isDealNextTaskMissing) {
          updatedCategories[categoryIndex].stages[stageIndex].count_today_forgotten_no_task =
            updatedCategories[categoryIndex].stages[stageIndex].count_today_forgotten_no_task + 1 || 1;
          if (experimentalStageIndex !== -1) {
            updatedCategories[categoryIndex].stages[experimentalStageIndex].count_today_forgotten_no_task =
              updatedCategories[categoryIndex].stages[experimentalStageIndex].count_today_forgotten_no_task +
                1 || 1;
          }
        }

        if (isDealNextTaskExpired || isDealNextTaskMissing) {
          updatedCategories[categoryIndex].stages[stageIndex].count_deals_with_expired_tasks =
            updatedCategories[categoryIndex].stages[stageIndex].count_deals_with_expired_tasks + 1 || 1;
        }

        updatedCategories[categoryIndex].stages[stageIndex].count =
          updatedCategories[categoryIndex].stages[stageIndex].count + 1 || 1;
        updatedCategories[categoryIndex].stages[stageIndex].deals.push(deal);
        if (experimentalStageIndex !== -1) {
          updatedCategories[categoryIndex].stages[experimentalStageIndex].count =
            updatedCategories[categoryIndex].stages[experimentalStageIndex].count + 1 || 1;
          updatedCategories[categoryIndex].stages[experimentalStageIndex].deals.push(deal);
        }
      }

      if (this.getIsMissedOrUrgent(deal)) {
        updatedCategories[0].stages[0].count += 1;
        updatedCategories[0].stages[0].deals.push(deal);
      }

      if (this.getIsColdTouch(deal)) {
        updatedCategories[0].stages[1].count += 1;
        updatedCategories[0].stages[1].deals.push(deal);
      }

      if (this.getIsNewClient(deal)) {
        updatedCategories[0].stages[2].count += 1;
        updatedCategories[0].stages[2].deals.push(deal);
      }
    });

    postSalesDeals.forEach(deal => {
      deal.tourPackages.forEach(tourPackage => {
        const categoryIndex = updatedCategories.findIndex(category =>
          category.stages.some(stage => (stage?.name as string) === (tourPackage.stage as string)),
        );
        if (categoryIndex !== -1) {
          const stageIndex = updatedCategories[categoryIndex].stages.findIndex(
            stage => (stage?.name as string) === (tourPackage.stage as string),
          );
          if (stageIndex !== -1) {
            const [firstTourPackage] = deal.tourPackages || null;
            const isTodayDeal = this.isTodayDealPostSale(firstTourPackage);
            if (isTodayDeal) {
              updatedCategories[categoryIndex].stages[stageIndex].count_today =
                updatedCategories[categoryIndex].stages[stageIndex].count_today + 1 || 1;
            }
            updatedCategories[categoryIndex].stages[stageIndex].count =
              updatedCategories[categoryIndex].stages[stageIndex].count + 1 || 1;
            updatedCategories[categoryIndex].stages[stageIndex].dealsPostSale.push(deal);
          }
        }
      });
    });
    return updatedCategories;
  }

  ngOnDestroy(): void {
    if (this.dealsSub) {
      this.dealsSub.unsubscribe();
    }
    if (this.dealsListSub) {
      this.dealsListSub.unsubscribe();
    }
    if (this.dealsListCategorySub) {
      this.dealsListCategorySub.unsubscribe();
    }
  }

  private getIsTodayDeal(deal: CrmCardListItem): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const taskDate = deal.nextTask?.date ? new Date(deal.nextTask.date) : null;
    taskDate?.setHours(0, 0, 0, 0);

    return today.getTime() >= taskDate?.getTime();
  }

  private getIsDealForgotten(deal: CrmCardViewItem): boolean {
    return !!deal?.isForgotten;
  }

  private getIsDealNextTaskMissing(deal: CrmCardViewItem): boolean {
    return !deal.nextTask;
  }

  private getIsDealNextTaskExpired(deal: CrmCardViewItem) {
    return deal?.nextTask?.isExpired;
  }

  public getIsMissedOrUrgent(crmCardViewItem: CrmCardListItem): boolean {
    if (crmCardViewItem.deal.status === NEW_CLIENT_STATUS) {
      return false;
    }
    return crmCardViewItem.needConnectType === NeedConnectType.urgentOrMissed;
  }

  public getIsColdTouch(crmCardViewItem: CrmCardListItem): boolean {
    return crmCardViewItem.isColdConnectFolder;
  }

  public getIsNewClient(crmCardViewItem: CrmCardListItem): boolean {
    if (crmCardViewItem.needConnectType === NeedConnectType.coldTouch) {
      return false;
    }
    if (this.getIsColdTouch(crmCardViewItem)) {
      return false;
    }
    return crmCardViewItem.deal.status === NEW_CLIENT_STATUS;
  }

  public filterByStage(stage: SalesFunnelStage) {
    // this.selectedCategory = null;
    // this.selectedDeals = [];
    // this.selectedStage = stage;
    this.dealsListFacade.setCurrentStage(stage);
    // this.cdRef.detectChanges();
  }

  public onCategoryChanged(category: SalesFunnelCategory): void {
    // this.selectedStage = null;
    // this.selectedCategory = category;
    this.dealsListFacade.setCurrentCategory(category);
    // this.selectedDeals = this.getUniqueDeals(category);
    // this.cdRef.detectChanges();
  }

  private getUniqueDeals(category: SalesFunnelCategory): CrmCardViewItem[] {
    const allDeals = category.stages.flatMap(stage => stage.deals);
    return Array.from(new Set(allDeals.map(deal => deal.deal.id))).map(id =>
      allDeals.find(deal => deal.deal.id === id),
    );
  }
}
