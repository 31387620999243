import { Injectable } from '@angular/core';
import { PhoneItem } from '@api-clients/crm-api-client';
import { Observable, map } from 'rxjs';
import { CurrentOutcomeCallFacade } from '../../../../../../../+state/current-outcome-call/current-outcome-call.facade';
import { WebsocketService } from '../../../../../../../core/services';
import { AmplitudeTrackService } from '../../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../../core/services/amplitude/amplitudeEventData';
import { CLICK_TO_CALL } from '../../../../../../../core/services/amplitude/amplitudeEvents';
import { ZoiperCallService } from '../../../../../../settings/components/settings/services/zoiper-call.service';
import { Click2CallEvent, SipType, SipTypes } from '../click2call.model';

@Injectable({
  providedIn: 'root',
})
export class ClickToCallService {
  public sipType$: Observable<SipType> = this.zoiperCallService.isZoiperCall$.pipe(
    map(isZoiperCall => (isZoiperCall ? SipTypes.Additional : SipTypes.Main)),
  );
  private readonly channel = 'calls.originateByPhoneNumber';
  constructor(
    private websocketService: WebsocketService,
    private currentOutcomeCallFacade: CurrentOutcomeCallFacade,
    private zoiperCallService: ZoiperCallService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  callToPhone(phoneItem: PhoneItem, clientName: string, sipType: SipType) {
    const phoneNumber = this.getPhoneNumber(phoneItem);
    const eventData: Click2CallEvent = {
      phoneNumber,
      sipType,
    };
    this.websocketService.send(this.channel, eventData);

    this.currentOutcomeCallFacade.setCurrentOutcomeCallState({
      clientPhone: phoneNumber,
      clientName,
    });

    this.amplitudeTrackService.trackEvent(CLICK_TO_CALL, { screenType: ScreenTypes.DEAL_VIEW });
  }

  getPhoneNumber(phoneItem: PhoneItem) {
    if (!phoneItem) {
      return '';
    }

    return `${phoneItem.code}${phoneItem.phone}`;
  }
}
