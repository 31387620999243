import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { PhoneInputWithCodeComponent } from './components/phone-input-with-code.component';
import { SharedUtilityModule } from '../../../../../../libs/shared/utility/src';
import { FormErrorModule } from '../form-error/form-error.module';
import { PhoneInputOptionsPopupComponent } from './components/phone-input-options-popup/phone-input-options-popup.component';

@NgModule({
  declarations: [PhoneInputWithCodeComponent, PhoneInputOptionsPopupComponent],
  imports: [
    CommonModule,
    NgxMaskModule,
    NgClickOutsideDirective,
    SharedUtilityModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorModule,
    TranslateModule,
    MatTooltipModule,
    ClipboardModule,
  ],
  exports: [PhoneInputWithCodeComponent],
})
export class PhoneInputWithCodeModule {}
