import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EndWithCheckmarkBtnComponent } from './components/end-with-checkmark-btn/end-with-checkmark-btn.component';
import { TaskDeadlineComponent } from './components/task-deadline/task-deadline.component';
import { TaskAlertComponent } from './components/task-alert/task-alert.component';
import { TaskTypeComponent } from './components/task-type/task-type.component';
import { TaskCommentComponent } from './components/task-comment/task-comment.component';
import { NoActiveDealsComponent } from './components/no-active-deals/no-active-deals.component';
import { ExcludedFromConversionComponent } from './components/excluded-from-conversion/excluded-from-conversion.component';
import { NoActiveTasksComponent } from './components/no-active-tasks/no-active-tasks.component';
import { TaskAlertListComponent } from './task-alert-list/task-alert-list.component';
import { CreatePostSaleTaskComponent } from './components/create-post-sale-task/create-post-sale-task.component';
import { TaskCloseUrgentComponent } from './components/task-close-urgent/task-close-urgent.component';

@NgModule({
  declarations: [
    EndWithCheckmarkBtnComponent,
    TaskDeadlineComponent,
    TaskAlertComponent,
    TaskTypeComponent,
    NoActiveDealsComponent,
    ExcludedFromConversionComponent,
    NoActiveTasksComponent,
    TaskAlertListComponent,
    CreatePostSaleTaskComponent,
    TaskCloseUrgentComponent,
  ],
  exports: [
    EndWithCheckmarkBtnComponent,
    TaskDeadlineComponent,
    TaskDeadlineComponent,
    TaskAlertComponent,
    ExcludedFromConversionComponent,
    NoActiveDealsComponent,
    NoActiveTasksComponent,
    TaskAlertListComponent,
    CreatePostSaleTaskComponent,
    TaskCloseUrgentComponent,
  ],
  imports: [CommonModule, TranslateModule, MatTooltipModule, TaskCommentComponent],
})
export class TaskAlertModule {}
