import { Component, OnInit } from '@angular/core';
import { DealsFacade } from '../../../../+state/deals/deals.facade';

@Component({
  selector: 'app-skipped-cards',
  templateUrl: './skipped-cards.component.html',
  styleUrls: ['./skipped-cards.component.scss'],
})
export class SkippedCardsComponent implements OnInit {
  public skippedCards$ = this.dealsFacade.skippedCards$;

  constructor(private dealsFacade: DealsFacade) {}

  ngOnInit(): void {}
}
