import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AudioModule,
} from '../../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import { ItemInViewDirective } from '../../../../directives/item-in-view.directive';
import { MessageStatusModule } from '../message-status/message-status/message-status.module';
import { MessageAudioComponent } from './components/message-audio/message-audio.component';
import { MessageDocumentComponent } from './components/message-document/message-document.component';
import { MessageImageComponent } from './components/message-image/message-image.component';
import { MessageItemComponent } from './components/message-item.component';
import {
  MessageNotificationComponent,
} from './components/message-notification/message-notification.component';
import { MessagePendingComponent } from './components/message-pending/message-pending.component';
import { MessageQuoteComponent } from './components/message-quote/message-quote.component';
import { MessageStickerComponent } from './components/message-sticker/message-sticker.component';
import { MessageTextComponent } from './components/message-text/message-text.component';
import { MessageUnsupportedComponent } from './components/message-unsupported/message-unsupported.component';
import { MessageVideoComponent } from './components/message-video/message-video.component';

@NgModule({
  declarations: [
    MessageItemComponent,
    MessageImageComponent,
    MessagePendingComponent,
    MessageDocumentComponent,
    MessageQuoteComponent,
    MessageTextComponent,
    MessageVideoComponent,
    MessageUnsupportedComponent,
    MessageStickerComponent,
    MessageNotificationComponent,
    MessageAudioComponent,
  ],
  imports: [
    CommonModule,
    MessageStatusModule,
    AudioModule,
    ItemInViewDirective,
  ],
  exports: [MessageItemComponent],
})
export class MessageItemModule {
}
