import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  HotOffersApplyFilterDepartDateRangeEvent,
  HotOffersFilterType,
  HotOffersFilterValue,
} from '../../../../hot-offers.model';
import { HotOffersService } from '../../../../hot-offers.service';

interface MonthCalendar {
  monthName: string;
  rows: Array<MonthCalendarDay[]>;
}

interface MonthCalendarDay {
  index: number;
  dayNumber: number;
  isEmpty: boolean;
  count: number;
  filterValue: string;
  isChecked: boolean;
}

@Component({
  selector: 'app-hot-offers-depart-dates',
  templateUrl: './hot-offers-depart-dates.component.html',
  styleUrls: ['./hot-offers-depart-dates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class HotOffersDepartDatesComponent implements OnInit, OnDestroy {
  @Input() departCityId!: number;

  @Input() set values(values: HotOffersFilterValue[]) {
    this.createCalendars(values);
  }

  monthCalendars: MonthCalendar[] = [];

  startDragIndex: number;
  stopDragIndex: number;
  selectedDateFromIndex: number;
  selectedDateToIndex: number;

  private departDatesFilterFlushSubscription: Subscription;
  private activeFiltersByTypeSubscription: Subscription;

  private readonly CALENDAR_CELLS_COUNT = 35;

  constructor(
    private readonly pipeDate: DatePipe,
    private readonly cdRef: ChangeDetectorRef,
    private readonly hotOffersService: HotOffersService,
  ) {
  }

  private createCalendars(departDatesFilterValue: HotOffersFilterValue[]) {
    const fromMonth = this.hotOffersService.departDateFromMonth;
    const toMonth = this.hotOffersService.departDateToMonth;

    this.monthCalendars = [];

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    let cellIndex = 0;
    for (let month = fromMonth; month <= toMonth; month++) {
      const tmpDate = new Date(currentYear, month, 0);
      const monthDaysCount = tmpDate.getDate();
      const monthName = this.pipeDate.transform(tmpDate, 'LLLL');

      const filterValues = departDatesFilterValue.filter(value => {
        const departMonth = new Date(value.value).getMonth() + 1;
        return departMonth === month;
      });

      let dates = [];
      const rows = [];
      for (let index = 1; index < this.CALENDAR_CELLS_COUNT; index++) {
        cellIndex++;

        const cellYear = month === 1 && currentMonth !== 1 ? currentYear + 1 : currentYear;
        const cellDate = new Date(cellYear, month - 1, index);
        const dateYMD = this.pipeDate.transform(cellDate, 'YYYY-MM-dd');

        const dateValues = filterValues.filter(value => {
          return new Date(value.value).getDate() === index;
        });

        let toursCount = 0;
        dateValues.forEach(v => {
          toursCount += v.count;
        });

        const filterValue = dateValues.find(v => v.value === dateYMD);

        dates.push(
          {
            index: cellIndex,
            dayNumber: index,
            isEmpty: index > monthDaysCount,
            count: toursCount,
            filterValue: filterValue?.value,
            isChecked: filterValue?.isChecked,
          },
        );

        if (index % 7 === 0) {
          rows.push(dates);
          dates = [];
        }
      }

      const monthCalendar = {
        monthName: monthName.charAt(0).toLocaleUpperCase() + monthName.slice(1),
        rows,
      };
      this.monthCalendars.push(monthCalendar);
    }

    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.departDatesFilterFlushSubscription = this.hotOffersService.departDatesFilterFlush$
      .subscribe(() => {
        this.startDragIndex = undefined;
        this.stopDragIndex = undefined;

        this.selectedDateFromIndex = undefined;
        this.selectedDateToIndex = undefined;
      });

    this.activeFiltersByTypeSubscription = this.hotOffersService
      .activeFiltersByType$(this.departCityId, HotOffersFilterType.DEPART_DATE)
      .pipe(
        filter(v => v.length !== 0 && !this.selectedDateFromIndex && !this.selectedDateToIndex),
        take(1),
      )
      .subscribe(v => {
        const dateFrom = v[0].value;
        const dateTo = v[v.length - 1].value;

        this.monthCalendars.forEach(calendar => {
          calendar.rows.forEach(row => {
            row.forEach(day => {
              if (day.filterValue === dateFrom) {
                this.startDragIndex = day.index;
                this.selectedDateFromIndex = day.index;
              }
              if (day.filterValue === dateTo) {
                this.stopDragIndex = day.index;
                this.selectedDateToIndex = day.index;
              }
            });
          });
        });
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.departDatesFilterFlushSubscription?.unsubscribe();
    this.activeFiltersByTypeSubscription.unsubscribe();
  }

  selectDate(day: MonthCalendarDay) {
    if (!day.filterValue) {
      return;
    }

    if (this.selectedDateFromIndex && this.selectedDateToIndex) {
      this.startDragIndex = undefined;
      this.stopDragIndex = undefined;
      this.selectedDateFromIndex = undefined;
      this.selectedDateToIndex = undefined;
    }

    if (!this.startDragIndex) {
      this.startDragIndex = day.index;
      this.selectedDateFromIndex = day.index;
    } else {
      this.selectedDateToIndex = day.index;

      this.startDragIndex = undefined;
      this.stopDragIndex = undefined;
    }

    if (this.selectedDateFromIndex > this.selectedDateToIndex) {
      const tmp = this.selectedDateFromIndex;
      this.selectedDateFromIndex = this.selectedDateToIndex;
      this.selectedDateToIndex = tmp;
    }

    if (this.selectedDateFromIndex && this.selectedDateToIndex) {
      const selectedDates = [];
      this.monthCalendars.forEach(calendar => {
        calendar.rows.forEach(row => {
          row.forEach(day => {
            if (this.selectedDateFromIndex <= day.index
              && day.index <= this.selectedDateToIndex
              && day.filterValue
            ) {
              selectedDates.push(day.filterValue);
            }
          });
        });
      });

      if (selectedDates.length) {
        const event: HotOffersApplyFilterDepartDateRangeEvent = {
          eventType: 'HotOffersApplyFilterDepartDateRangeEvent',
          departCityId: this.departCityId,
          values: selectedDates,
        };
        this.hotOffersService.applyFilter(event);
        this.cdRef.detectChanges();
      }
    }
  }

  mouseenter(index: number) {
    if (!this.startDragIndex || (this.selectedDateFromIndex && this.selectedDateToIndex)) {
      return;
    }
    this.stopDragIndex = index;
  }
}
