import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'libTruncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, ellipsis = '...') {
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
