import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../../../../../../ui-components/alert-label/alert-label.component';

@Component({
  selector: 'app-hot-offers-short-offers-tours-not-found',
  template: `
    <app-alert-label [type]="AlertLabelType.danger">Не нашли туры при пересчете</app-alert-label>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AlertLabelComponent,
    AlertLabelComponent,
  ],
})
export class HotOffersShortOffersToursNotFoundComponent {
  protected readonly AlertLabelType = AlertLabelType;
}
