<div itemInView (inView)="showAudio()">
  <div *ngIf="isShow; else audioContainer">
    <app-audio
      *ngIf="mediaSource.length; else noAudio"
      [screenType]="screenType"
      [mediaSource]="mediaSource"
      [type]="audioMessageType"
      [isFromMe]="isFromMe"
    />
    <ng-template #noAudio>
      <div class="no-audio">
        Аудио сообщение не загружено
      </div>

    </ng-template>
  </div>

</div>
<ng-template #audioContainer>
  <div class="audio-container"></div>
</ng-template>

