import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ZenmodeWelcomePopupContainerComponent } from './components/zenmode-welcome-popup-container/zenmode-welcome-popup-container.component';
import { ZenmodeWelcomeTipsComponent } from './components/zenmode-welcome-tips/zenmode-welcome-tips.component';
import { ZenmodeWelcomeLoadingComponent } from './components/zenmode-welcome-loading/zenmode-welcome-loading.component';
import { ZenmodeToggleComponent } from './components/zenmode-toggle/zenmode-toggle.component';

@NgModule({
  declarations: [
    ZenmodeWelcomePopupContainerComponent,
    ZenmodeWelcomeTipsComponent,
    ZenmodeWelcomeLoadingComponent,
    ZenmodeToggleComponent,
  ],
  imports: [CommonModule, TranslateModule, FormsModule],
  exports: [ZenmodeToggleComponent],
})
export class ZenmodeWelcomePopupContainerModule {}
