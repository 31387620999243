import { Component } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';
import { CountResponse } from '@api-clients/crm-api-client/models';
import { NotificationsCenterApiService } from '../../../notifications-center/services/notifications-center-api.service';

const TIMER_DUE_TIME = 60 * 1000;

@Component({
  selector: 'app-notifications-center-count',
  templateUrl: './notifications-center-count.component.html',
  styleUrls: ['./notifications-center-count.component.scss'],
})
export class NotificationsCenterCountComponent {
  public count$: Observable<CountResponse>;
  public timer: Subscription;
  event$ = new BehaviorSubject(true);
  constructor(private notificationsCenterApi: NotificationsCenterApiService) {
    this.count$ = this.event$.pipe(switchMapTo(this.notificationsCenterApi.getCountNew()));
    this.timer = timer(0, TIMER_DUE_TIME)
      .pipe()
      .subscribe(() => this.manualRefresh());

    this.notificationsCenterApi.getCountNewEvent$.subscribe(() => this.manualRefresh());
  }

  public manualRefresh() {
    this.event$.next(true);
  }
}
