import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DealListFilterKeys } from '../../../../deal-list';
import { DealListFilterService, DealListFilters } from '../../../../services/deal-list-filters.service';

@Component({
  selector: 'app-deal-list-filters',
  templateUrl: './deal-list-filters.component.html',
  styleUrls: ['./deal-list-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealListFiltersComponent implements OnInit {
  @Output() filterChanged = new EventEmitter<DealListFilters>();

  public currentFilters$: Observable<DealListFilters>;
  public isAnyFilterActive$: Observable<boolean>;

  constructor(private dealListFilterService: DealListFilterService) {}

  ngOnInit(): void {
    this.currentFilters$ = this.dealListFilterService.currentFilters$;
    this.isAnyFilterActive$ = this.dealListFilterService.anyActiveFilters$;
  }

  public toggleFilter(filterKey: string, value: boolean): void {
    this.dealListFilterService.updateFilter(filterKey as DealListFilterKeys, !value);
  }

  public resetFilters(): void {
    this.dealListFilterService.resetFilters();
  }
}
