import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  exports: [CommonModule, CheckboxModule, MultiSelectModule, RadioButtonModule, CalendarModule],
})
export class PrimengComponentsModule {}
