import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CreateWhatsappMessageTemplateRequest,
  DefaultResponse,
  UpdateWhatsappMessageTemplateRequest,
  WhatsappMessageTemplate,
} from '@api-clients/crm-api-client';
import { WhatsappTemplatesApiService } from '../../services/whatsapp-templates-api.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import {
  NEW_QUICK_MESSAGE,
  QUICK_MESSAGE_EDIT,
} from '../../../../../../core/services/amplitude/amplitudeEvents';

@Component({
  selector: 'app-whatsapp-template-form',
  templateUrl: './whatsapp-template-form.component.html',
  styleUrls: ['./whatsapp-template-form.component.scss'],
})
export class WhatsappTemplateFormComponent implements OnInit, OnChanges {
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @Input() template: WhatsappMessageTemplate = null;
  public form: UntypedFormGroup;
  public readonly screenType: ScreenTypes = ScreenTypes.QUICK_ANSWERS;

  constructor(
    private fb: UntypedFormBuilder,
    private whatsappTemplatesApiService: WhatsappTemplatesApiService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.form = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
      id: [null],
    });
  }

  ngOnInit(): void {}

  get id() {
    return this.form.get('id');
  }
  get key() {
    return this.form.get('key');
  }

  get value() {
    return this.form.get('value');
  }

  onSubmit() {
    if (this.id.value) {
      this.updateTemplate(this.id.value, this.value.value, this.key.value);
    } else {
      this.createTemplate(this.key.value, this.value.value);
    }
  }

  updateTemplate(id: number, value: string, key: string) {
    const body: UpdateWhatsappMessageTemplateRequest = {
      id,
      value,
      key,
    };
    this.whatsappTemplatesApiService.update(body).subscribe((result: DefaultResponse) => {
      if (result.success) {
        this.form.reset();
        this.saved.emit();
        this.amplitudeTrackService.trackEvent(QUICK_MESSAGE_EDIT, { screenType: this.screenType });
      }
    });
  }

  createTemplate(key: string, value: string) {
    const body: CreateWhatsappMessageTemplateRequest = {
      key,
      value,
    };
    this.whatsappTemplatesApiService.create(body).subscribe((result: DefaultResponse) => {
      if (result.success) {
        this.form.reset();
        this.saved.emit();
        this.amplitudeTrackService.trackEvent(NEW_QUICK_MESSAGE, { screenType: this.screenType });
      }
    });
  }

  populateForm(template: WhatsappMessageTemplate) {
    this.key.setValue(template.key);
    this.value.setValue(template.value);
    this.id.setValue(template.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes?.template?.isFirstChange() &&
      changes?.template?.currentValue !== changes?.template?.previousValue
    ) {
      this.populateForm(changes.template.currentValue);
    }
  }

  resetForm() {
    this.form.reset();
  }
}
