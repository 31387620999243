import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TourPackage } from '@api-clients/crm-api-client/models/tour-package';
import { ConfigsService } from '../../../../../../core/services';

@Component({
  selector: 'app-tour-package-item',
  templateUrl: './tour-package-item.component.html',
  styleUrls: ['./tour-package-item.component.scss'],
})
export class TourPackageItemComponent implements OnInit {
  @Input() tourPackage: TourPackage;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() tourPackageId = new EventEmitter<number>();
  public secretMask: string;
  public isPhoneHidden = true;
  public hotels: string;
  public phoneCodes: string[];
  public readonly dateFormat = 'dd.MM.YY';
  constructor(public configsService: ConfigsService) {}

  ngOnInit(): void {
    this.secretMask = this.configsService.getPhoneHiddenMaskByCode(this.tourPackage.payer.phone.code);
    this.hotels = this.tourPackage.tours.map(hotel => hotel.hotel.name).join(', ');
  }

  setTourPackageId(): void {
    this.tourPackageId.emit(this.tourPackage.id);
  }

  closeTourPackagesList(): void {
    this.closeModal.emit();
  }
}
