import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealsListComponent } from './deals-list.component';
import { DealListService } from './services/deal-list.service';
import { DealsListContentModule } from './modules/deals-list-content/deals-list-content.module';
import { DealsMenuModule } from './modules/deals-menu/deals-menu.module';
import { AlertOnTopModule } from '../../../alert-on-top/alert-on-top.module';
import { SharedModule } from '../../../../shared/shared.module';
import { ClientActionsOnSiteModule } from '../client-actions-on-site/client-actions-on-site.module';
import { ClientReservationsModule } from '../client-reservations/client-reservations.module';
import { DealsListPostSaleContentModule } from './modules/deals-list-post-sale-content/deals-list-post-sale-content.module';
import { ManagerOffersModule } from '../manager-offers/manager-offers.module';

@NgModule({
  declarations: [DealsListComponent],

  imports: [
    CommonModule,
    DealsListContentModule,
    DealsMenuModule,
    AlertOnTopModule,
    SharedModule,
    ClientActionsOnSiteModule,
    ClientReservationsModule,
    DealsListPostSaleContentModule,
    ManagerOffersModule,
  ],
  providers: [DealListService],
})
export class DealsListModule {}
