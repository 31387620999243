import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutoFocusDirective } from '../../../../../../../../../shared/directives/auto-focus.directive';
import {
  TOURISTS_MAX_ADULTS_COUNT,
  TOURISTS_MAX_CHILD_COUNT,
  TOURISTS_MIN_ADULTS_COUNT,
  TOURISTS_MIN_CHILD_COUNT,
} from '../../../../../../../../search/search.model';
import { childAgesValidator } from '../../../../../../../../search/validators/child-ages.validator';

interface TouristsFormValue {
  adults: number;
  childAges: number[];
  splitRooms: boolean;
}

@Component({
  selector: 'app-favorite-hotels-search-form-tourists',
  templateUrl: './favorite-hotels-search-form-tourists.component.html',
  styleUrls: ['./favorite-hotels-search-form-tourists.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FavoriteHotelsSearchFormTouristComponent),
    },
  ],
  imports: [ReactiveFormsModule, AutoFocusDirective],
})
export class FavoriteHotelsSearchFormTouristComponent implements OnInit, OnDestroy, ControlValueAccessor {
  touristsForm: FormGroup;

  private valueChangesSubscription: Subscription;
  private splitRoomsChangedManually = false;

  constructor(private readonly fb: FormBuilder, private readonly cdRef: ChangeDetectorRef) {}

  onTouched: any = () => {};
  onChange = (value: any) => {};

  ngOnInit() {
    this.touristsForm = this.fb.group({
      adults: new FormControl<number>(2, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      childAges: new FormArray([]),
      splitRooms: [false],
    });

    this.valueChangesSubscription = this.touristsForm.valueChanges.subscribe(value => {
      if (this.canSearchSplitRooms && !this.splitRoomsChangedManually) {
        this.touristsForm.get('splitRooms').patchValue(true, { emitEvent: false });
        value.splitRooms = true;
      }

      this.onChange(value);
      this.onTouched();
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.valueChangesSubscription?.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.touristsForm.disable();
    } else {
      this.touristsForm.enable();
    }
  }

  writeValue(value: TouristsFormValue): void {
    if (value) {
      this.adultsControl.setValue(value.adults);
      if (value.childAges.length) {
        value.childAges.forEach(age => {
          this.childControl.push(new FormControl(age, this.getChildAgeValidators()));
        });
      }
    }
  }

  changeSplitRooms(): void {
    this.splitRoomsChangedManually = true;
  }

  get adults(): number {
    return this.adultsControl.value;
  }

  get child(): number {
    return this.childControl.value.length;
  }

  get adultsControl(): FormControl {
    return this.touristsForm.get('adults') as FormControl;
  }

  get childControl(): FormArray {
    return this.touristsForm.get('childAges') as FormArray;
  }

  incAdults(): void {
    const currenAdults = this.adults;
    const newAdults = currenAdults + 1;
    if (newAdults >= TOURISTS_MAX_ADULTS_COUNT) {
      return;
    }

    this.adultsControl.setValue(newAdults);
  }

  decAdults(): void {
    const currentAdults = this.adults;
    const newAdults = currentAdults - 1;
    if (currentAdults < TOURISTS_MIN_ADULTS_COUNT) {
      return;
    }

    this.adultsControl.setValue(newAdults);
  }

  incChild(): void {
    const currentChild = this.child;
    const newChild = currentChild + 1;
    if (newChild > TOURISTS_MAX_CHILD_COUNT) {
      return;
    }

    this.childControl.push(new FormControl('', this.getChildAgeValidators()));
  }

  decChild(): void {
    const currentChild = this.child;
    const newChild = currentChild + 1;
    if (newChild < TOURISTS_MIN_CHILD_COUNT) {
      return;
    }

    this.childControl.removeAt(-1);
  }

  get canSearchSplitRooms(): boolean {
    //  3+0 || 2+2 || 3+1
    return this.adults > 3 || (this.adults > 1 && this.adults + this.child > 3);
  }

  private getChildAgeValidators() {
    return { validators: [Validators.required, childAgesValidator()] };
  }
}
