import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseAudioDirective } from '../base-audio.component';
import { AudioType } from '../interfaces/audio.interface';
import { AudioService } from '../services/audio.service';
import { AmplitudeTrackService } from '../../../../../../../../../core/services/amplitude/amplitude-track.service';
import { AudioTranscriptService } from '../services/audio-transcript.service';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioComponent extends BaseAudioDirective implements OnInit, AfterViewInit {
  public constructor(
    audioService: AudioService,
    cdRef: ChangeDetectorRef,
    sanitizer: DomSanitizer,
    amplitudeTrackService: AmplitudeTrackService,
    audioTranscriptService: AudioTranscriptService,
  ) {
    super(audioService, audioTranscriptService, cdRef, sanitizer, amplitudeTrackService);
  }

  get isWhatsappAudio(): boolean {
    return this.type === AudioType.audioMessage;
  }

  get isRecordedAudio(): boolean {
    return this.type === AudioType.recordedAudio;
  }
}
