@if (message.isDeleted) {
<div class="message-deleted">
  <img src="assets/icons/chat/slash.svg" alt="" srcset="" />
  <span>Сообщение было удалено.</span>
</div>
} @else {
<ng-container *ngIf="types && message?.type">
  <ng-container [ngSwitch]="message.type">
    <app-quoted-message *ngIf="message.quotedMessage" [message]="message"> </app-quoted-message>
    <ng-container *ngSwitchCase="types.Chat">
      <div
        #messageText
        class="message-text"
        [ngClass]="{ show: showMore }"
        [innerHTML]="message.text | formatChatMessage | safeHtml"
        (click)="openLink($event)"
      ></div>
      <a *ngIf="!hideToggle" (click)="showMore = !showMore" class="show-more">
        <span class="text-span" *ngIf="showMore">{{ 'COMMON.HIDE' | translate }}</span>
        <span class="text-span" *ngIf="!showMore">{{ 'COMMON.SHOW_MORE' | translate }}</span>
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="types.Ptt">
      <app-audio
        *ngIf="message?.media?.contentLink as mediaSource; else noAudio"
        [screenType]="screenType"
        [mediaSource]="mediaSource"
        [itemId]="message.id"
        [type]="audioMessageType"
        [isFromMe]="message?.isFromMe"
      />
      <ng-template #noAudio>
        <div class="no-audio">Аудио сообщение не загружено</div>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="types.Image">
      <div class="image-wrapper" *ngIf="message?.media?.contentLink; else noImage">
        <div (click)="maximize()" class="image-container">
          <img
            [src]="message.media?.contentLink"
            alt="image"
            class="image-message"
            [ngClass]="{ isFromMe: message?.isFromMe }"
          />
          <!-- TODO: delete these blocks if no need when group media content will be implementing -->
          <!-- <a [href]="message.media?.contentLink" class="download">
              <img src="./assets/icons/whatsapp/download-light.svg" alt="Скачать">
            </a>
            <button (click)="maximize()" class="maximize">
              <img src="./assets/icons/whatsapp/maximize.svg" alt="Скачать">
            </button> -->
        </div>
        <div class="image-text-message" *ngIf="message.text as text">
          {{ text | formatChatMessage }}
        </div>
      </div>
      <ng-template #noImage> Загрузка ... </ng-template>
    </ng-container>
    <!-- TODO: extract into Type -->
    <ng-container *ngSwitchCase="'imageGroup'">
      <div
        *ngIf="imageGroup"
        class="image-group"
        [ngClass]="{
          'less-than-4': imageGroup.images.length < 4,
          'four-images': imageGroup.images.length === 4,
          'more-than-4': imageGroup.images.length > 4
        }"
      >
        <ng-container *ngFor="let img of imageGroup.images; let i = index">
          <div class="image-wrapper" *ngIf="i < 4" (click)="maximize()">
            <div *ngIf="img?.media?.contentLink; else noImage" class="image-container">
              <img
                [src]="img.media?.contentLink"
                alt="image"
                class="image-message"
                [ngClass]="{ isFromMe: img?.isFromMe }"
              />
            </div>
            <ng-template #noImage> Загрузка ... </ng-template>
            <ng-container *ngIf="i === 3 && imageGroup.images.length > 4">
              <div class="overlay">+{{ imageGroup.images.length - 4 }}</div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="types.Unsupported">
      <ng-container *ngIf="message.text as text; else unsupported">
        {{ text }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="types.Document">
      <div class="message-document">
        <ng-container *ngIf="message.text as text">
          <div class="message-text">
            {{ text }}
          </div>
        </ng-container>
        <ng-container *ngIf="message?.media?.fileName">
          <div class="message-text">
            {{ message?.media?.fileName }}
          </div>
        </ng-container>
        <a *ngIf="message?.media?.contentLink" [href]="message?.media?.contentLink"> Скачать документ </a>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="types.Video">
      <div class="message-video" [ngClass]="{ isFromMe: message?.isFromMe }">
        <video controls preload="metadata">
          <source [src]="message.media?.contentLink" type="video/mp4" />
          <source [src]="message.media?.contentLink" type="video/ogg" />
          Ваш браузер не поддерживает тег video.
        </video>
      </div>
      <div class="image-text-message" *ngIf="message.text as text">
        {{ text | formatChatMessage }}
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="types.CallLog">
      <div class="whatsapp-call">
        <img src="assets/icons/timeline/phone.svg" class="caller" alt="call" />
        <div class="call-info">
          <span class="call-type-text">
            {{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.CALLS.whatsapp_call' | translate }}
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="message-text loader">
        <img src="assets/icons/loading.gif" alt="loading" />
      </div>
    </ng-container>
  </ng-container>
  <ng-template #unsupported> Сообщение не поддерживается </ng-template>
</ng-container>
}
