import { Component, Inject, OnInit, Optional } from '@angular/core';
import { CrmTaskItem } from '@api-clients/crm-api-client';
import { CreateTaskService } from '../services/create-task.service';
import { Actions, StepsId } from './create-task-modal';
import { POPUP_DATA, PopupService } from '../../../../../../../shared/services/popup-service.service';
import { PopupFacade } from '../../../../../../../+state/popup/popup.facade';
import { CreateTaskModalPayload } from '../../../models/create-task-modal.model';
import { ScreenTypes } from '../../../../../../../core/services/amplitude/amplitudeEventData';

@Component({
  selector: 'app-create-task-modal',
  templateUrl: './create-task-modal.component.html',
  styleUrls: ['./create-task-modal.component.scss'],
})
export class CreateTaskModalComponent implements OnInit {
  public dealStage: string;
  public stepsId = StepsId;
  public actions = Actions;
  public task: CrmTaskItem;
  public dealId: number;
  public dealStatus: number;
  public crmCardId: number;
  public phone: string;
  public isAfterCall: boolean;
  public isNewClient: boolean;
  public currentStep$ = this.createTaskService.currentStepValue;
  public checkedStage$ = this.createTaskService.checkedStageValue;
  public checkedTaskType$ = this.createTaskService.checkedTaskTypeValue;
  public completeType$ = this.createTaskService.selectedCompleteTypeValue;
  public isLoaded = false;
  public POST_SALE_STATUS = 2;
  public place: ScreenTypes;
  constructor(
    private createTaskService: CreateTaskService,
    private popupService: PopupService,
    private popupFacade: PopupFacade,
    @Optional() @Inject(POPUP_DATA) private data: CreateTaskModalPayload & { place?: ScreenTypes },
  ) {
    this.task = data.task;
    this.dealStage = data.dealStage;
    this.dealId = data.dealId;
    this.crmCardId = data.crmCardId;
    this.phone = data.phone;
    this.dealStatus = data.dealStatus;
    this.isAfterCall = data.isAfterCall;
    this.isNewClient = data.isNewClient;
    this.place = data.place;
  }

  ngOnInit(): void {
    if (this.dealStatus === this.POST_SALE_STATUS) {
      this.createTaskService.setStep(StepsId.RECALL);
    }
  }

  closeModal() {
    this.popupService.closeAllModals();
    this.popupFacade.setPopupStatus({
      popupShow: false,
      isBlocked: false,
    });
    this.createTaskService.reset();
  }
}
