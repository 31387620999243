import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ComplaintListComponent } from './components/complaint-list/complaint-list.component';
import { ComplaintItemComponent } from './components/complaint-item/complaint-item.component';
import { ComplaintCompletionComponent } from './components/complaint-completion/complaint-completion.component';
import { UICheckboxModule } from '../../ui-components/checkbox/checkbox.module';
import { ComplaintAssignComponent } from './components/complaint-assign/complaint-assign.component';
import { FormComponentsModule } from '../form-components/form-components.module';
import { ComplaintDiscussComponent } from './components/complaint-discuss/complaint-discuss.component';

@NgModule({
  declarations: [
    ComplaintListComponent,
    ComplaintItemComponent,
    ComplaintCompletionComponent,
    ComplaintAssignComponent,
    ComplaintDiscussComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UICheckboxModule,
    TranslateModule,
    FormComponentsModule,
  ],
})
export class ComplaintModule {}
