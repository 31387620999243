<div class="info-card"
     (mouseenter)="showClientCard()"
     (mouseleave)="hideClientCard()">
  <div class="info-card-header">
    <div class="client-name-info">
      <div class="client-name">
        <app-circle-preloader>
        </app-circle-preloader>
      </div>
    </div>
  </div>
  <div class="card-initial-preloader" *ngIf="isShowCardInfo">
    <img class="spin" src="../../../assets/icons/preloader.svg" alt="" />
  </div>
</div>
